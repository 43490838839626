import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';

const readFile = (file: any) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = function () {
      resolve(fr.result);
    };
    fr.readAsBinaryString(file);
  });
};

const FrontendSettingsImportUDI: React.FC<any> = ({ toggleImportImageDialog, setValue }) => {
  const { t } = useTranslation('Import');
  const [udi, setUdi] = useState<any>();
  const [uppy] = useState(
    () =>
      new Uppy({
        restrictions: { maxNumberOfFiles: 1 },
        onBeforeFileAdded: (file) => {
          readFile(file.data).then((result) => {
            setUdi(String(result));
          });
          return false;
        },
      }),
  );

  const onCloseDialog = () => {
    toggleImportImageDialog(false);
  };

  return (
    <>
      <Typography component="div" id="scrollToForm">
        <Paper elevation={0}>
          <Dialog
            open={true}
            onClose={() => {
              toggleImportImageDialog(false);
            }}
            aria-labelledby="form-dialog-title"
            maxWidth="xl"
            fullWidth={true}
          >
            <DialogTitle id="form-dialog-title" sx={{ pt: 0, pb: 0 }}>
              <Grid
                container={true}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {t('uppy.importUDIFile')}
                <IconButton aria-label="close" onClick={onCloseDialog} size="large">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent sx={{ p: 2, pt: 0 }} id="form-dialog-content">
              <Dashboard
                uppy={uppy}
                height={150}
                width="auto"
                fileManagerSelectionType="files"
                proudlyDisplayPoweredByUppy={false}
                showSelectedFiles={false}
                hideUploadButton={true}
                locale={{
                  strings: {
                    browseFiles: t('uppy.browseFileUDI'),
                    dropPasteFiles: t('uppy.dropPasteFileUDI'),
                  },
                }}
              />
              {udi && (
                <div style={{ marginTop: '20px' }}>
                  <b>Přečtená hodnota ze souboru:</b> {udi}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="inherit"
                sx={{ mr: 1 }}
                onClick={() => {
                  onCloseDialog();
                }}
              >
                {t('close')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setValue('udi', udi);
                  onCloseDialog();
                }}
              >
                {t('confirm')}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Typography>
    </>
  );
};

export default FrontendSettingsImportUDI;

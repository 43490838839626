import { sortBy } from 'lodash';
import { IRegisterGroup, IRegisterItem } from '../_types';

export const useDefaultTagForm = () => {
  //Create options for autocomplete fields
  const createRegisterItemsOptions = (tag: IRegisterGroup) => {
    const registerItemsOptions =
      tag.registerItems?.map((tagItem: IRegisterItem) => ({
        value: tagItem.id || '',
        label: tagItem.name || '',
        labelLowerCase: tagItem.name.toLowerCase() || '',
        tagItem: tagItem,
      })) || [];
    return sortBy(registerItemsOptions, ['labelLowerCase']);
  };

  return {
    customHandleOnChange: undefined,
    customFilterOption: undefined,
    onSelectShowChildren: undefined,
    updatedOptions: undefined,
    createRegisterItemsOptions,
    expandableStructure: {
      isExpandableStructure: false,
      showNested: false,
    },
  };
};

import { useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import Header from 'components/Header/Header';
import { WhiteBox } from 'components/WhiteBox/WhiteBox';
import { format } from 'date-fns';
import {
  each,
  filter,
  find,
  get,
  head,
  isArray,
  isEmpty,
  isEqual,
  isString,
  keys,
  omit,
  pick,
  pickBy,
} from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStudy } from 'utils/hooks/useStudy';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { joinParams, generateIID } from 'utils/study';
import {
  IDicomData,
  IPatientInSplitFormTargetStudy,
  IStudiesSplitDetail,
  IStudiesSplitForm,
} from './_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { getSeriesAndInstances } from 'components/SeriesViewer/_api';
import { getValuesFromDicomFile } from '../StudyDetail/_api';
import { NewStudyForm } from './NewStudyForm';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useValidationSchema from './_form';
import { SeriesViewer } from 'components/SeriesViewer/SeriesViewer';
import { useSeriesViewer } from 'components/SeriesViewer/useSeriesViewer';
import { IStudyWithStates } from 'components/SeriesViewer/_types';
import RequestAdditionalForm from '../Form/RequestAdditionalForm';
import FormCheckbox from 'components/Form/Checkbox/Checkbox';
import { createRequestForSplit, editRequestForSplit, splitStudy } from './_api';
import useAlerts from 'components/Alerts/useAlerts';
import { IPatient } from 'modules/Search/_types';
import { useNavigate } from 'react-router';
import { RequestDialog } from 'components/RequestDialog/RequestDialog';
import { SELECT_ORDER_TO_NEW_STUDY_FORM } from 'constants/constants';
import { IComment } from '../StudyDetail/_types';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import useSizesValidation from '../hooks/useSizesValidation';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourStudiesSplit } from './TourStudiesSplit';
import { Papeer } from 'components/Papeer/Papeer';
import Button from 'components/Buttons/Button';

const apiDateFormat = 'yyyy-MM-dd';

export const StudiesSplitDetail: React.FC<IStudiesSplitDetail> = ({
  isRequest,
  isRequestEdit = false,
  request,
}) => {
  const { t } = useTranslation('Studies');
  useWithTitle(); // sets title to document
  const { splitStore } = useStudyInfo();
  const studyFromStore = head(splitStore);
  const { backToStudyIID, getPatientSexSlug, getStudyCommentsByStudyID } = useStudy();
  const [dicomData, setDicomData] = useState<null | IDicomData>(null);
  const [comments, setComments] = useState<IComment[]>([]);
  const { portalSetting } = useAppInfo();
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [orderDialog, setOrderDialog] = useState<boolean>(false);
  const {
    validateSizes,
    confirmationTexts,
    confirmationData,
    setConfirmationData,
    clearConfirmationData,
  } = useSizesValidation({
    isRequestEdit,
    maxNameLength: 60,
    prefixForDataAccession: 'targetStudy.',
  });

  const { StudiesSplitFormSchema } = useValidationSchema();
  const navigate = useNavigate();

  const defaultValues = {
    targetStudy: {
      patient: {
        name: {
          lastName: '',
          firstName: '',
          middleName: '',
          prefix: '',
          suffix: '',
        },
        identificationNumber: '',
        sex: null,
        dateBirth: null,
        dateCreated: null,
        timeCreated: '',
        accessionNumber: '',
      },
      patientDispositions: {
        weight: '',
        size: '',
      },
    },
    includeComments: false,
  };

  const methods = useForm<IStudiesSplitForm>({
    resolver: yupResolver(StudiesSplitFormSchema),
    defaultValues,
  });

  const watchAllFields = methods.watch();

  const study = isRequestEdit ? request?.data.sourceStudy : studyFromStore;
  const sourceStudy = isRequestEdit ? request?.data.sourceStudy : studyFromStore;
  const targetStudy = isRequestEdit ? request?.data.targetStudy : study;
  const initialValuesStudyForm = isRequestEdit ? { targetStudy } : study;

  let linkBack: string;
  if (backToStudyIID !== null) {
    linkBack = `/study/${backToStudyIID}`;
  } else {
    linkBack = `/${isRequest ? 'requests' : 'studies'}`;
  }

  const loadStart = async () => {
    if (study) {
      try {
        // Load series and comments
        const archiveID: number = study.archive.id!;
        const studyID = get(study, 'studyInstanceUid');
        if (archiveID && studyID) {
          toggleLoader();
          const [series, comments] = await Promise.all([
            getSeriesAndInstances(archiveID, studyID),
            getStudyCommentsByStudyID(archiveID, studyID),
          ]);
          if (isArray(comments)) {
            setComments(comments);
          }
          toggleLoader(false);
          if (isArray(series)) {
            const studies = [
              {
                ...study,
                iid: generateIID(study),
                studyIsSelected: false,
                studyIsOpened: false,
                loadedSeries: series.map((serie) => ({
                  ...serie,
                  serieIsSelected: false,
                  serieIsOpened: false,
                  image: isArray(serie.image)
                    ? serie.image.map((image) => ({ ...image, imageIsSelected: false }))
                    : [],
                })),
              } as unknown as IStudyWithStates,
            ];

            if (!isRequest) {
              setStudiesForViewer(studies);
            }

            // Set checked series when loading from request
            if (isRequest) {
              // These series and it's images are in request, so they should be checked
              const seriesThatArePresentInRequest = request?.data.targetStudy.listOfSeries;

              if (!isArray(seriesThatArePresentInRequest)) {
                setStudiesForViewer(studies);
              }

              if (isArray(seriesThatArePresentInRequest)) {
                const seriesThatAreChecked = studies.map((study) => ({
                  ...study,
                  loadedSeries: study.loadedSeries.map((serie) => {
                    const uid = serie.uid;

                    // Find if serie is selected in request
                    const serieInRequestThatIsSelectedOrHasImagesSelected = find(
                      seriesThatArePresentInRequest,
                      {
                        uid,
                      },
                    );

                    // Nothing selected => return serie untouched
                    if (!serieInRequestThatIsSelectedOrHasImagesSelected) {
                      return serie;
                    }

                    // Something selected => proceed
                    let serieShouldBeChecked = false;
                    let serieShouldBeIndeterminate = false;

                    const allSeriesImages = serie.image;
                    const checkedImagesInSerie = allSeriesImages.map((image) => {
                      // Find if image is present in serie in request => it should be selected
                      const imageIsSelectedInRequest = find(
                        serieInRequestThatIsSelectedOrHasImagesSelected.image,
                        { sopinstanceUid: image.sopinstanceUid },
                      );

                      return { ...image, imageIsSelected: imageIsSelectedInRequest ? true : false };
                    });

                    const numberOfImagesInSeries = allSeriesImages.length;
                    const numberOfSelectedImagesInRequest = filter(checkedImagesInSerie, {
                      imageIsSelected: true,
                    }).length;

                    // Number of images is equal to number of selected images => serie should be checked
                    if (numberOfImagesInSeries === numberOfSelectedImagesInRequest) {
                      serieShouldBeChecked = true;
                    }

                    // Some images selected, but not all => serie should be indeterminate
                    if (
                      numberOfSelectedImagesInRequest > 0 &&
                      numberOfImagesInSeries !== numberOfSelectedImagesInRequest
                    ) {
                      serieShouldBeIndeterminate = true;
                    }

                    return {
                      ...serie,
                      serieIsSelected: serieShouldBeChecked,
                      serieIsIndeterminate: serieShouldBeIndeterminate,
                      image: checkedImagesInSerie,
                    };
                  }),
                }));

                setStudiesForViewer(seriesThatAreChecked);
              }
            }
          }
          // }

          // Load dicom data
          if (!isRequestEdit) {
            const loadedDicomData = await getValuesFromDicomFile(archiveID, studyID, {
              REFERRING_PHYSICIAN_NAME: 'NAME',
              REQUESTING_PHYSICIAN_NAME: 'NAME',
              PERFORMING_PHYSICIAN_NAME: 'NAME',
              PATIENT_WEIGHT: 'STRING',
              PATIENT_HEIGHT: 'STRING',
            });

            const physician = {
              firstName: '',
              lastName: '',
              middleName: '',
              prefix: '',
              suffix: '',
            };

            const performingPhysician = get(loadedDicomData, 'PERFORMING_PHYSICIAN_NAME')
              ? JSON.parse(get(loadedDicomData, 'PERFORMING_PHYSICIAN_NAME', ''))
              : null;
            const referringPhysician = get(loadedDicomData, 'REFERRING_PHYSICIAN_NAME')
              ? JSON.parse(get(loadedDicomData, 'REFERRING_PHYSICIAN_NAME', ''))
              : null;
            const requestingPhysician = get(loadedDicomData, 'REQUESTING_PHYSICIAN_NAME')
              ? JSON.parse(get(loadedDicomData, 'REQUESTING_PHYSICIAN_NAME', ''))
              : null;

            const patientWeightAndSize = {
              height: `${get(loadedDicomData, 'PATIENT_HEIGHT', '-')} m`,
              weight: `${get(loadedDicomData, 'PATIENT_WEIGHT', '-')} kg`,
              patientWeightAndSize: {
                size: get(loadedDicomData, 'PATIENT_HEIGHT', null),
                weight: get(loadedDicomData, 'PATIENT_WEIGHT', null),
              },
              performingPhysician: { ...physician, ...performingPhysician },
              referringPhysician: { ...physician, ...referringPhysician },
              requestingPhysician: { ...physician, ...requestingPhysician },
            };

            setDicomData(patientWeightAndSize);
          }
        }
      } catch (e) {}
      toggleLoader(false);
    } else {
      navigate(linkBack);
    }
  };

  useEffect(() => {
    loadFromExisting(isRequestEdit ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dicomData]);

  useEffect(() => {
    loadStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = isRequestEdit
    ? t('studySplitTitleRequestEdit')
    : isRequest
    ? t('studySplitTitleRequest')
    : t('studySplitTitle');

  const existingStudy = {
    lastName: get(study, 'patient.name.lastName', ''),
    firstName: get(study, 'patient.name.firstName', ''),
    middleName: get(study, 'patient.name.middleName', ''),
    prefix: get(study, 'patient.name.prefix', ''),
    suffix: get(study, 'patient.name.suffix', ''),
    patientID: get(study, 'patient.identificationNumber', ''),
    sex: t(getPatientSexSlug(get(study, 'patient.sex', ''))),
    dateOfBirth: get(study, 'patient.dateBirth')
      ? format(new Date(get(study, 'patient.dateBirth')!), 'dd. MM. yyyy')
      : '',
    dateCreated: get(study, 'dateTime')
      ? format(new Date(get(study, 'dateTime')!), 'dd. MM. yyyy')
      : '',
    timeCreated: get(study, 'dateTime') ? format(new Date(get(study, 'dateTime')!), 'HH:mm') : '',
    accessionNumber: get(study, 'accessionNumber', ''),
    patientSize: get(dicomData, 'height')
      ? get(dicomData, 'height')
      : get(study, 'patientDispositions.size', null)
      ? `${get(study, 'patientDispositions.size')} m`
      : '',
    patientWeight: get(dicomData, 'weight')
      ? get(dicomData, 'weight')
      : get(study, 'patientDispositions.weight', null)
      ? `${get(study, 'patientDispositions.weight')} kg`
      : '',
    ...pickBy({
      requestingPhysician: get(portalSetting, 'seeRequestingPhysician', null)
        ? get(dicomData, 'requestingPhysician')
          ? joinParams([
              get(dicomData, 'requestingPhysician.lastName', ''),
              get(dicomData, 'requestingPhysician.firstName', '\u00A0'),
            ])
          : get(study, 'requestingPhysician')
          ? joinParams([
              get(study, 'requestingPhysician.name.lastName', ''),
              get(study, 'requestingPhysician.name.firstName', '\u00A0'),
            ])
          : ''
        : null,
      referringPhysician: get(portalSetting, 'seeReferringPhysician', null)
        ? get(dicomData, 'referringPhysician')
          ? joinParams([
              get(dicomData, 'referringPhysician.lastName', ''),
              get(dicomData, 'referringPhysician.firstName', '\u00A0'),
            ])
          : get(study, 'referringPhysician')
          ? joinParams([
              get(study, 'referringPhysician.name.lastName', ''),
              get(study, 'referringPhysician.name.firstName', '\u00A0'),
            ])
          : ''
        : null,
      performingPhysician: get(portalSetting, 'seePerformingPhysician', null)
        ? get(dicomData, 'performingPhysician')
          ? joinParams([
              get(dicomData, 'performingPhysician.lastName', ''),
              get(dicomData, 'performingPhysician.firstName', '\u00A0'),
            ])
          : get(study, 'performingPhysician')
          ? joinParams([
              get(study, 'performingPhysician.name.lastName', ''),
              get(study, 'performingPhysician.name.firstName', '\u00A0'),
            ])
          : ''
        : null,
    }),
    studyDescription: get(study, 'studyDescription', ''),
  };

  const {
    studiesForViewer: seriesOfExistingStudy,
    setStudiesForViewer,
    operations,
  } = useSeriesViewer();

  const {
    studiesForViewer: seriesOfNewStudy,
    setStudiesForViewer: setStudiesForNewStudy,
    operations: operationsForNewStudy,
    numberOfSelectedSeries: numberOfSelectedSeriesNew,
  } = useSeriesViewer();

  let numberOfImagesInExistingStudy = 0;
  if (isArray(seriesOfExistingStudy)) {
    seriesOfExistingStudy.forEach((study) => {
      if (isArray(study.loadedSeries)) {
        study.loadedSeries.forEach((serie) => {
          if (isArray(serie.image)) {
            numberOfImagesInExistingStudy += serie.image.length;
          }
        });
      }
    });
  }

  let numberOfImagesInNewStudy = 0;
  if (isArray(seriesOfNewStudy)) {
    seriesOfNewStudy.forEach((study) => {
      if (isArray(study.loadedSeries)) {
        study.loadedSeries.forEach((serie) => {
          if (isArray(serie.image)) {
            numberOfImagesInNewStudy += serie.image.length;
          }
        });
      }
    });
  }

  const allInstancesSelected =
    numberOfImagesInExistingStudy > 0 &&
    numberOfImagesInNewStudy > 0 &&
    numberOfImagesInExistingStudy === numberOfImagesInNewStudy;

  const canSend = numberOfSelectedSeriesNew > 0 && !allInstancesSelected;

  const prepareValuesBeforeSend = (values: IStudiesSplitForm) => {
    const usedSourceStudy = sourceStudy;

    return {
      // Only ID's are required for source study
      sourceStudy: pick(usedSourceStudy, ['studyInstanceUid', 'archive.id']),

      // Omit FE generated attributes - dateCreate, timeCreated (not neccessary)
      targetStudy: omit(
        {
          ...values.targetStudy,
          patient: {
            ...values.targetStudy.patient,
            dateBirth: values.targetStudy.patient.dateBirth
              ? format(values.targetStudy.patient.dateBirth, apiDateFormat)
              : null,
          },
          patientDispositions: get(values.targetStudy, 'patientDispositions') || {
            size: null,
            weight: null,
          },
          listOfSeries: seriesOfNewStudy[0].loadedSeries,
          dateTime: `${format(values.targetStudy.dateCreated!, apiDateFormat)}T${
            values.targetStudy.timeCreated
          }:00`,
        },
        ['dateCreated', 'timeCreated'],
      ),
      includeComments: values.includeComments || false,
      requestComment: values.requestComment || null,
    };
  };

  const handleRequestEdit = methods.handleSubmit(async (values) => {
    const preparedValues = prepareValuesBeforeSend(values);

    const editedRequest = {
      ...request,
      data: preparedValues,
      requestComment: values.requestComment || null,
    };

    const atts = [
      'accessionNumber',
      'dateTime',
      'patient.name',
      'patient.identificationNumber',
      'patient.sex',
      'patient.dateBirth',
      'referringPhysician.name',
      'requestingPhysician.name',
      'performingPhysician.name',
      'studyDescription',
      'patientDispositions',
    ];

    let numberOfChanges = 0;
    atts.forEach((att) => {
      const sourceObject = get(initialValuesStudyForm, `targetStudy.${att}`, null);
      const targetObject = get(editedRequest, `data.targetStudy.${att}`, null);
      if (!isEqual(sourceObject, targetObject)) {
        ++numberOfChanges;
      }
    });
    if (!isEmpty(editedRequest.requestComment)) {
      ++numberOfChanges;
    }

    const sourceListOfSeries = get(initialValuesStudyForm, 'targetStudy.listOfSeries', []).map(
      (serie: any) => {
        return pick(serie, ['uid', 'image']);
      },
    );
    const targetListOfSeries = get(editedRequest, `data.targetStudy.listOfSeries`, []).map(
      (serie: any) => {
        serie.image = serie.image.map((image: any) => omit(image, ['imageIsSelected']));
        return pick(serie, ['uid', 'image']);
      },
    );
    if (!isEqual(sourceListOfSeries, targetListOfSeries)) {
      ++numberOfChanges;
    }
    if (numberOfChanges === 0) {
      addErrorAlert(t('errorNoChange'));
    } else {
      otherChecks(editedRequest, true);
    }
  });

  const handleFormSubmit = methods.handleSubmit(async (values) => {
    const attributesAreSame = patientAttributesAreSame(
      sourceStudy?.patient!,
      values.targetStudy.patient,
    );

    const preparedValues = prepareValuesBeforeSend(values);

    const newValues = {
      ...preparedValues,
      sourceStudy: {
        ...preparedValues.sourceStudy,
        patientDispositions:
          dicomData === null
            ? { size: null, weight: null }
            : get(dicomData, 'patientWeightAndSize', {}),
      },
    };

    otherChecks(newValues, attributesAreSame);
  });

  const patientAttributesAreSame = (
    patientFromSourceStudy: Partial<IPatient>,
    patientFromTargetStudy: IPatientInSplitFormTargetStudy,
  ) => {
    const pathsToCompare = ['name.firstName', 'name.lastName', 'identificationNumber'];
    let allAttributesSame = true;
    each(pathsToCompare, (path: string) => {
      if (get(patientFromSourceStudy, path) !== get(patientFromTargetStudy, path)) {
        allAttributesSame = false;
      }
    });
    return allAttributesSame;
  };

  const submitAction = async (newValues: unknown) => {
    toggleLoader();
    const fn = isRequest ? createRequestForSplit : splitStudy;
    await fn(newValues).then(
      (response) => {
        addSuccessAlert(t(isRequest ? 'requestForSplitAdded' : 'successfullySplit'));
        navigate(linkBack);
      },
      (error) => {
        if (get(error, 'response.data') === 'error.realisator') {
          addErrorAlert(t('errorRealisator'));
        } else {
          addErrorAlert(t(isRequest ? 'errorCreatingRequest' : 'errorSplitting'));
        }
      },
    );
    toggleLoader(false);
  };

  useEffect(() => {
    setStudiesForNewStudy(
      seriesOfExistingStudy.map((study) => ({
        ...study,
        loadedSeries: filter(study.loadedSeries, { serieIsSelected: true }).map((serie) => ({
          ...serie,
          image: filter(serie.image, { imageIsSelected: true }),
        })),
      })),
    );
  }, [seriesOfExistingStudy, setStudiesForNewStudy]);

  const submitButtonText = t(
    isRequestEdit ? 'editRequestForSplit' : isRequest ? 'setRequestForSplit' : 'split',
  );

  const convertStringToDateObject = (dateString: string | undefined) => {
    try {
      if (isString(dateString)) {
        return new Date(dateString);
      }
      return null;
    } catch {
      return null;
    }
  };

  const loadFromExisting = (loadFromTargetStudy: boolean = false) => {
    const studyToBeFilledFrom = loadFromTargetStudy ? targetStudy : sourceStudy;
    const dateCreated = convertStringToDateObject(get(studyToBeFilledFrom, 'dateTime') as string);
    const physiciansSource = isRequestEdit ? studyToBeFilledFrom : dicomData;
    methods.reset({
      targetStudy: {
        patient: {
          name: pickBy(
            pick(studyToBeFilledFrom?.patient.name, [
              'firstName',
              'lastName',
              'middleName',
              'prefix',
              'suffix',
            ]),
          ),
          identificationNumber: studyToBeFilledFrom?.patient.identificationNumber,
          sex: studyToBeFilledFrom?.patient.sex || null,
          dateBirth: convertStringToDateObject(
            get(studyToBeFilledFrom, 'patient.dateBirth') as string,
          ),
        },
        dateCreated: dateCreated,
        timeCreated: dateCreated ? format(dateCreated, 'HH:mm') : '',
        patientDispositions: isRequestEdit
          ? get(studyToBeFilledFrom, 'patientDispositions', {})
          : get(dicomData, 'patientWeightAndSize', {}),
        accessionNumber: get(studyToBeFilledFrom, 'accessionNumber', '') as string,
        studyDescription: get(studyToBeFilledFrom, 'studyDescription', '') as string,
        requestingPhysician: {
          name: isRequestEdit
            ? get(pick(physiciansSource?.requestingPhysician, ['name']), 'name', {})
            : pickBy(
                pick(physiciansSource?.requestingPhysician, [
                  'firstName',
                  'lastName',
                  'middleName',
                  'prefix',
                  'suffix',
                ]),
              ),
        },
        referringPhysician: {
          name: isRequestEdit
            ? get(pick(physiciansSource?.referringPhysician, ['name']), 'name', {})
            : pickBy(
                pick(physiciansSource?.referringPhysician, [
                  'firstName',
                  'lastName',
                  'middleName',
                  'prefix',
                  'suffix',
                ]),
              ),
        },
        performingPhysician: {
          name: isRequestEdit
            ? get(pick(physiciansSource?.performingPhysician, ['name']), 'name', {})
            : pickBy(
                pick(physiciansSource?.performingPhysician, [
                  'firstName',
                  'lastName',
                  'middleName',
                  'prefix',
                  'suffix',
                ]),
              ),
        },
      },
    });
  };

  const resetForm = () => {
    methods.reset(defaultValues);
  };

  const submitActionEditRequest = async (values: unknown) => {
    toggleLoader();
    await editRequestForSplit(values).then(
      (response) => {
        addSuccessAlert(t('requestEdited'));
        navigate(linkBack);
      },
      (error) => addErrorAlert(t('errorEditingRequest')),
    );
    toggleLoader(false);
  };

  const otherChecks = (newValues: any, patientAttributesSame: boolean) => {
    const { areSizesValidated } = validateSizes(newValues);

    if (!patientAttributesSame) {
      confirmationTexts.push(t('patientAttributesChangedConfirm'));
    }

    if (confirmationTexts.length > 0) {
      setConfirmationData({
        texts: confirmationTexts,
        values: newValues,
      });
    } else {
      if (areSizesValidated)
        isRequestEdit ? submitActionEditRequest(newValues) : submitAction(newValues);
    }
  };

  // Called when dialog confirmed - editing
  const handleConfirmationForRequestEdit = () => submitActionEditRequest(confirmationData?.values);

  // Called when dialog confirmed - creating
  const handleConfirmationForEdit = () => submitAction(confirmationData?.values);

  const renderedSteps = () => {
    return <TourStudiesSplit />;
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={isRequestEdit ? handleRequestEdit : handleFormSubmit}>
          <Header
            title={t(title)}
            backUrl={`${linkBack}?action=back`}
            TourComponent={renderedSteps()}
          />
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} lg={6} data-tour="splitStudyExistingStudyForm">
              <WhiteBox title={t('existingStudy')}>
                <Grid container={true} spacing={1}>
                  {keys(existingStudy).map((fieldName) => (
                    <Grid item={true} xs={12} md={6} lg={4} key={fieldName}>
                      <div>
                        <strong>{t(fieldName)}</strong>
                      </div>
                      <div>{get(existingStudy, fieldName, '')}</div>
                    </Grid>
                  ))}
                </Grid>
              </WhiteBox>
            </Grid>
            <Grid item={true} xs={12} lg={6} data-tour="splitStudyNewStudyForm">
              <WhiteBox
                title={t('newStudy')}
                button={
                  <Box data-tour="splitStudyNewStudyButton">
                    <Button
                      variant="contained"
                      color="inherit"
                      sx={{ ml: 1 }}
                      onClick={() => setOrderDialog(true)}
                    >
                      {t('loadFromOrder')}
                    </Button>
                    <Button
                      variant="contained"
                      color="inherit"
                      sx={{ ml: 1 }}
                      onClick={() => loadFromExisting()}
                    >
                      {t('loadFromExisting')}
                    </Button>
                    <Button variant="contained" color="inherit" sx={{ ml: 1 }} onClick={resetForm}>
                      {t('reset')}
                    </Button>
                  </Box>
                }
              >
                <NewStudyForm
                  dicomData={dicomData}
                  sourceStudy={sourceStudy}
                  targetStudy={targetStudy}
                  initialValues={{ sourceStudy: initialValuesStudyForm }}
                  isRequest={isRequest}
                  isRequestEdit={isRequestEdit}
                />
              </WhiteBox>
            </Grid>

            <Grid item={true} xs={12} lg={6} data-tour="splitStudyExistingStudySeries">
              <WhiteBox>
                <SeriesViewer
                  studies={seriesOfExistingStudy}
                  showStudiesCheckboxes={true}
                  showSeriesCheckboxes={true}
                  showInstancesCheckboxes={true}
                  allowOpenSeries={true}
                  renderStudy={false}
                  operations={operations}
                  title={t('seriesOfExistingStudy')}
                />
              </WhiteBox>
            </Grid>

            <Grid item={true} xs={12} lg={6} data-tour="splitStudyNewStudySeries">
              <WhiteBox>
                <SeriesViewer
                  studies={seriesOfNewStudy}
                  showStudiesCheckboxes={false}
                  showSeriesCheckboxes={false}
                  showInstancesCheckboxes={false}
                  allowOpenSeries={true}
                  renderStudy={false}
                  operations={operationsForNewStudy}
                  title={t('seriesOfNewStudy')}
                />
                {allInstancesSelected ? (
                  <Typography sx={{ color: 'red', display: 'block', mt: 1 }} variant="subtitle2">
                    {t('cannotSplitWithAllSelected')}
                  </Typography>
                ) : null}
              </WhiteBox>
            </Grid>

            <Grid item={true} xs={12}>
              <RequestAdditionalForm isRequest={isRequest} />
            </Grid>

            <Grid item={true} xs={12} data-tour="splitStudyOtherChoices">
              <WhiteBox title={t('otherOptions')}>
                <FormCheckbox
                  name="includeComments"
                  label={t('includeComments')}
                  disabled={!comments.length}
                />
              </WhiteBox>
            </Grid>
          </Grid>
          <Papeer>
            <Grid container={true} justifyContent="flex-end">
              <Grid item={true} justifyContent="flex-end" data-tour="splitStudySplitButton">
                <Box>
                  <Button variant="contained" color="primary" disabled={!canSend} type="submit">
                    {submitButtonText}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>

      {confirmationData !== null ? (
        <ConfirmationDialog
          title={t('splitSend')}
          open={true}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
          cancelAction={clearConfirmationData}
          confirmAction={
            isRequestEdit ? handleConfirmationForRequestEdit : handleConfirmationForEdit
          }
        >
          <Typography variant="body1">
            {confirmationData.texts.map((title, index) =>
              index === 0 ? <div key={index}>{title}</div> : <p key={index}>{title}</p>,
            )}
          </Typography>
        </ConfirmationDialog>
      ) : null}

      {orderDialog ? (
        <RequestDialog
          toggleDialog={setOrderDialog}
          actions={[SELECT_ORDER_TO_NEW_STUDY_FORM]}
          isActionFirstColumn={true}
          reset={methods.reset}
          watchAllFields={watchAllFields}
        />
      ) : null}
    </>
  );
};

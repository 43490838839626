import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, isArray, isObject } from 'lodash';
import { Tooltip } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getAllTemplates, getLists, removeNotificationTemplate } from './_apiNotificationTemplates';
import NotificationTemplateForm from './NotificationTemplateForm';
import MissingEvents from './MissingEvents';
import Header from 'components/Header/Header';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'notificationTemplatesMUI';

const NotificationTemplates: React.FC = () => {
  const { t } = useTranslation('NotificationTemplates');
  useWithTitle();
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();
  const [missingEvents, setMissingEvents] = useState<any[]>([]);
  const [dialogState, setDialogState] = useState<boolean>(false);
  const [templates, setTemplates] = useState<any[]>([]);
  const [eventTypeId, setEventTypeId] = useState<any>(null);

  const loadEntity = async () => {
    toggleLoader();
    try {
      const lists = await getLists();
      if (isArray(get(lists, 'missingEvents'))) {
        setMissingEvents(get(lists, 'missingEvents'));
      }
      const response = await getAllTemplates();
      if (isArray(response)) {
        setTemplates(response);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const toggleDialog = (state: boolean = false) => {
    setDialogState(state);
    if (!state) setEventTypeId(null);
  };

  const addItemInDialog = (eventTypeId: any = null) => {
    toggleDialog(true);
    setEventTypeId(eventTypeId);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const id = 'create';

  const renderedDialog = (
    <OverflowedDialog
      open={dialogState}
      onClose={() => {
        toggleDialog(false);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <NotificationTemplateForm
        toggleDialog={toggleDialog}
        id={id}
        loadEntity={loadEntity}
        eventTypeId={eventTypeId}
      />
    </OverflowedDialog>
  );

  const { onEntityRemove } = useEntityRemove(removeNotificationTemplate, t, loadEntity);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 100,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                }}
                disabled={
                  get(row, 'state', null) === 'NEW' ||
                  !get(row, 'canDelete', true) ||
                  get(row, 'system', false)
                }
              />
            </>
          );
        },
      },
      {
        field: 'languageEventType',
        headerName: t('eventType'),
        width: 300,
        valueGetter: (value: any, row: any) => {
          const key = get(row, 'eventType.languageKey', '').toLowerCase();
          const tkey = t(key, { returnObjects: true });
          return isObject(tkey) ? t(`${key}.default`) : t(key);
        },
      },
      {
        field: 'reactionTypeName',
        headerName: t('reactionType'),
        width: 300,
        valueGetter: (value: any, row: any) => t(get(row, 'reactionType.name', '').toLowerCase()),
      },
    ]),
  );

  return (
    <>
      <Header
        title=""
        addItemInDialog={addItemInDialog}
        toggleDialogText={t('add')}
        topMargin={true}
      />
      {missingEvents.length ? (
        <MissingEvents events={missingEvents} addItemInDialog={addItemInDialog} />
      ) : null}
      <MuiGrid
        gridKey={muiGridKey}
        rows={templates}
        columns={columns}
        initialSortMode={[{ field: 'languageEventType', sort: 'asc' }]}
      />
      {renderedDialog}
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

export default NotificationTemplates;

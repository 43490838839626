import { get } from 'lodash';

export const useMwl = () => {
  const setPhysician2 = (entity: any, whatPhysician: string) => {
    const physician2 = get(entity, `${whatPhysician}.name`, null)
      ? {
          value: `${get(entity, whatPhysician + '.name.lastName', null)}|${get(
            entity,
            whatPhysician + '.name.firstName',
            null,
          )}|${get(entity, whatPhysician + '.name.middleName', null)}|${get(
            entity,
            whatPhysician + '.name.prefix',
            null,
          )}|${get(entity, whatPhysician + '.name.suffix', null)}`,
          label: `${
            get(entity, whatPhysician + '.name.lastName') === null
              ? ''
              : get(entity, whatPhysician + '.name.lastName', '')
          } ${
            get(entity, whatPhysician + '.name.firstName') === null
              ? ''
              : get(entity, whatPhysician + '.name.firstName', '')
          } ${
            get(entity, whatPhysician + '.name.middleName') === null
              ? ''
              : get(entity, whatPhysician + '.name.middleName', '')
          } ${
            get(entity, whatPhysician + '.name.prefix') === null
              ? ''
              : get(entity, whatPhysician + '.name.prefix', '')
          } ${
            get(entity, whatPhysician + '.name.suffix') === null
              ? ''
              : get(entity, whatPhysician + '.name.suffix', '')
          }`,
        }
      : null;
    return physician2;
  };

  return {
    setPhysician2,
  };
};

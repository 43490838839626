import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, FormControlLabel, Grid, Typography } from '@mui/material';
import { Switch } from '@mui/material';
import { get, isEmpty, isUndefined, size, sortBy } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getRegisterGroup, createRegisterGroup, setActiveRegisterGroup } from '../_api';
import useValidationSchema from './_form';

import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useActions } from 'utils/hooks/useActions';
import { useAppInfo } from 'utils/hooks/useAppInfo';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import { Box } from '@mui/system';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { IRegisterGroup } from 'components/TagForm/_types';
import { RegisterGroupGroups } from './RegisterGroupGroups';
import { RegisterGroupItems } from './RegisterGroupItems';
import { TourTags } from './TourTags';

const linkBack = '/tags/registerGroups';
const getParent = (values: any) => get(values, 'parent', null);

export const RegisterGroupDetail: React.FC = () => {
  const { t } = useTranslation('RegisterGroups');
  const { toggleLoader } = useAppGlobals();
  const { storeConfirmationData } = useActions();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { id } = useEntityInfo();
  const { confirmationData } = useAppInfo();

  const [entity, fetchEntity] = useState<IRegisterGroup>();
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
  const [oldId, setOldId] = useState<string>('');
  const navigate = useNavigate();
  const { title } = useWithEntityTitle(false, entity, t); // false not creating new register group detail

  const { RegisterGroupDetailSchema } = useValidationSchema();

  const methods = useForm<IRegisterGroup>({
    resolver: yupResolver(RegisterGroupDetailSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    await createRegisterGroup(values).then(
      (response) => {
        addSuccessAlert(t('saved'));
        navigate(linkBack);
      },
      (error) => {
        let tError = 'errorSaving';
        if (get(error, 'response.data', '').includes('RegisterGroup with code')) {
          tError = 'errorRegisterGroupExists';
        }
        addErrorAlert(t(tError));
      },
    );
    toggleLoader(false);
  });

  const getBreadCrumbs = (entity: IRegisterGroup) => {
    let parent = entity;
    let pos = 999;
    const breadcrumbs = [];
    breadcrumbs.push({
      name: get(parent, 'name'),
      id: get(parent, 'id'),
      url: `${linkBack}/${get(parent, 'id')}`,
      disabled: true,
      pos,
    });
    parent = getParent(parent);
    while (!isEmpty(parent)) {
      pos--;
      breadcrumbs.push({
        name: get(parent, 'name'),
        id: get(parent, 'id'),
        url: `${linkBack}/${get(parent, 'id')}`,
        disabled: false,
        pos,
      });
      parent = getParent(parent);
    }
    setIsFetchingEntity(false);
    setBreadcrumbs(breadcrumbs);
  };

  const loadEntity = async () => {
    toggleLoader();
    try {
      let registerGroup: IRegisterGroup = { id: 0, active: true, name: '' };
      if (id) {
        const entity = await getRegisterGroup(id.toString());
        registerGroup = { ...entity };
        if (entity) {
          fetchEntity(entity);
        }
        getBreadCrumbs(entity);
      }
      reset({ ...registerGroup });
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  if (id.toString() !== oldId.toString()) {
    setOldId(id.toString());
    loadEntity();
  }
  const onChangeActive = async (id: string, isActive: boolean) => {
    toggleLoader();
    const changed = await setActiveRegisterGroup(id, isActive);
    if (changed) {
      loadEntity();
    }
    toggleLoader(false);
  };

  const clearAction = () => {
    storeConfirmationData(null);
  };

  const cancelAction = () => {
    clearAction();
  };
  const confirmAction = async () => {
    onChangeActive(get(confirmationData, 'id'), get(confirmationData, 'state'));

    clearAction();
  };

  const renderedSteps = () => {
    return <TourTags type="detail" />;
  };

  return (
    <>
      {!isFetchingEntity && (
        <>
          {!isEmpty(entity) ? (
            <>
              <Header title={title} backUrl={linkBack} TourComponent={renderedSteps()} />
              <Grid container={true} spacing={2}>
                <Grid item={true} sx={{ mb: 1 }}>
                  {sortBy(breadcrumbs, ['pos']).map((button, index) => (
                    <React.Fragment key={index}>
                      <Button
                        key={`button${index}`}
                        color="inherit"
                        size="small"
                        component={Link}
                        {...({ to: button.url } as any)}
                        disabled={button.disabled}
                      >
                        {button.name}
                      </Button>
                      {index + 1 < size(breadcrumbs) && <> / </>}
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
              <Papeer bottomMargin={true}>
                <FormProvider {...methods}>
                  <form onSubmit={onSubmit} data-tour="registerGroupsDetailForm">
                    <input {...register('id')} type="hidden" />
                    <Grid container={true} alignItems="center" spacing={2}>
                      <Grid item={true} xs={12} md={4} lg={3}>
                        <FormInput name="name" label={t('name')} required={true} />
                      </Grid>
                      <Grid item={true} xs={12} md={4} lg={3}>
                        <FormControlLabel
                          control={<Switch checked={get(entity, 'active', true)} />}
                          onChange={(e, state) => {
                            storeConfirmationData({ id: get(entity, 'id'), state });
                          }}
                          label={t('active') as string}
                        />
                      </Grid>
                    </Grid>
                    <EntityButtons otherStyles={{ marginTop: 8 }} />
                  </form>
                </FormProvider>
              </Papeer>

              {!isEmpty(entity) && (
                <Box sx={{ mb: 3 }} data-tour="registerGroupsDetailGrid">
                  <Box data-tour="registerGroupsDetailAddChildren">
                    <Header
                      title={t('subgroups')}
                      addUrl={`${linkBack}/${get(entity, 'id')}/subgroups/create`}
                    />
                  </Box>

                  <RegisterGroupGroups
                    loadEntity={loadEntity}
                    items={get(entity, 'children', [])}
                  />
                </Box>
              )}
              {!isEmpty(entity) && (
                <Box data-tour="registerGroupsTagsGrid">
                  <Box data-tour="registerGroupsTagsAdd">
                    <Header
                      title={t('registerItems')}
                      addUrl={`${linkBack}/${get(entity, 'id')}/registerItems/create`}
                    />
                  </Box>
                  <RegisterGroupItems
                    loadEntity={loadEntity}
                    items={sortBy(get(entity, 'registerItems'), ['name'])}
                  />
                </Box>
              )}
            </>
          ) : (
            <Papeer>
              <Typography>{t('registerGroupNotFound')}</Typography>
            </Papeer>
          )}
          {confirmationData &&
            confirmationData.id &&
            isUndefined(confirmationData.confirmAction) && (
              <ConfirmationDialog
                title={t('confirmChangeState')}
                open={true}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                cancelAction={cancelAction}
                confirmAction={confirmAction}
              />
            )}
        </>
      )}
    </>
  );
};

import { get, head, isArray } from 'lodash';
import { useAppSelector } from 'store/hooks';

export const useStudyInfo = () => {
  const sendStore = useAppSelector(({ study }) => study.send);
  const editStore = useAppSelector(({ study }) => study.edit);
  const splitStore = useAppSelector(({ study }) => study.split);
  const reorderStore = useAppSelector(({ study }) => study.reorder);
  const moveStore = useAppSelector(({ study }) => study.move);
  const copyStore = useAppSelector(({ study }) => study.copy);
  const exportStore = useAppSelector(({ study }) => study.export);
  const importStore = useAppSelector(({ study }) => study.import);
  const moveToFolderStore = useAppSelector(({ study }) => study.moveToFolder);
  const listForFolderStore = useAppSelector(({ study }) => study.listForFolder);
  const moveToTrahStore = useAppSelector(({ study }) => study.moveToTrash);
  const shreddingPrintStore = useAppSelector(({ study }) => study.shreddingPrint);
  const doseMonitoringStore = useAppSelector(({ study }) => study.dose_monitoring);
  const basketStore = useAppSelector(({ study }) => study.basket);

  const sourceStudyForReorderIsInStore = isArray(reorderStore) && reorderStore.length === 1;
  const sourceStudyForReorderIID = sourceStudyForReorderIsInStore
    ? get(head(reorderStore), 'iid')
    : null;

  const splitValidators = get(
    useAppSelector((state) => state.app.validators),
    'splitForm',
    {},
  );

  return {
    sendStore,
    editStore,
    splitStore,
    splitValidators,
    moveStore,
    copyStore,
    reorderStore,
    exportStore,
    importStore,
    moveToFolderStore,
    listForFolderStore,
    moveToTrahStore,
    shreddingPrintStore,
    sourceStudyForReorderIID,
    sourceStudyForReorderIsInStore,
    doseMonitoringStore,
    basketStore,
  };
};

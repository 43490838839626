import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import WebcamCapture from 'components/Import/WebcamCapture';
import { useState, useEffect, useCallback } from 'react';
import { getWebCamImportConfig } from 'components/Import/_api';
// import PatientAndStudyEditForm from '../Form/PatientAndStudyEditForm';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { get, pick } from 'lodash';

import FormSelect from 'components/Form/Select/Select';
import { SEARCH_FUNCTION_IMPORT } from 'constants/constants';
import { useUser } from 'utils/hooks/useUser';
import { sendMetadata } from 'modules/Import/_api';
import useAlerts from 'components/Alerts/useAlerts';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Papeer } from 'components/Papeer/Papeer';
import Button from 'components/Buttons/Button';

const StudyDetailImportWebcam: React.FC<any> = ({
  study,
  importWebcamDialog,
  toggleImportWebcamDialog,
}: any) => {
  const { t } = useTranslation('Import');
  const { getArchivesForFunctions /*modalities,*/ } = useAppInfo();
  const { user } = useUser();
  const { addSuccessAlert, addErrorAlert } = useAlerts();
  const { toggleLoader } = useAppGlobals();
  const [webcamConfig, setWebcamConfig] = useState<any>(null);
  const [base64Image, setBase64Image] = useState<any>('');
  const methods = useForm<any>();

  const sources: { label: string; id: number }[] = getArchivesForFunctions(
    SEARCH_FUNCTION_IMPORT,
  ).map((archive) => ({ id: archive.id, label: archive.name }));

  const {
    handleSubmit,
    setValue,
    // formState: { errors },
  } = methods;

  const onSubmit = async (values: any) => {
    const timestamp = Date.now();

    const valuesToSubmit = {
      typeOfUpload: 'multimedia',
      timestamp,
      path: `${timestamp}_${user?.sub}`,
      base64Image,
      archiveId: values.archiveId,
      study, //neřešíme, co je ve formuláři, protože celej formulář je disabled kromě archivu, pokud by se někdy v budoucnu něco mohlo změnit, tak je potřeba to zohlednit v hodnotách study
      additionalDicomAttributes: get(webcamConfig, 'importDicomTags', []).map((dicomAttr: any) => {
        const tag = get(dicomAttr, 'dicomTag');
        const value = get(values, tag, null);
        return { tag, vr: get(dicomAttr, 'vr'), value };
      }),
    };
    toggleLoader();

    const resp = await sendMetadata(valuesToSubmit);
    if (resp) {
      addSuccessAlert(t('notifications.success'));
      toggleLoader(false);
      toggleImportWebcamDialog(false);
    } else {
      addErrorAlert(t('notifications.error'));
      toggleLoader(false);
    }
  };

  const loadWebcamConfig = async () => {
    const config: any = await getWebCamImportConfig();
    if (config) {
      setWebcamConfig(config);
    }
  };

  useEffect(() => {
    loadWebcamConfig();
    setBase64Image('');
  }, []);

  const createDefaultValues = useCallback((entity: any) => {
    if (entity) {
      return {
        patient: {
          ...pick(entity.patient, ['name', 'identificationNumber', 'sex']),
          dateBirth: entity?.patient?.dateBirth ? new Date(entity.patient.dateBirth) : null,
        },
        study: {
          accessionNumber: get(entity, 'accessionNumber'),
          studyDescription: get(entity, 'studyDescription'),
          studyDate: entity?.dateTime ? new Date(entity.dateTime) : null,
          studyTime: entity?.dateTime ? new Date(entity.dateTime) : null,
          studyInstanceUid: get(entity, 'studyInstanceUid'),
        },
        patienDisposition: get(entity, 'patienDisposition'),
        performingPhysician: get(entity, 'performingPhysician'),
        requestingPhysician: get(entity, 'requestingPhysician'),
        referringPhysician: get(entity, 'referringPhysician'),

        modality: get(entity, 'modalitiesInStudy', []).map((modality: any) => ({
          value: modality,
          label: modality,
        })),
        archiveId: get(entity, 'archive.id', null),
      };
    }
  }, []);

  useEffect(() => {
    const entity = createDefaultValues(study);
    if (entity) {
      setValue('patient', get(entity, 'patient', {}));
      setValue('study', get(entity, 'study', {}));
      setValue('archiveId', get(study, 'archiveId', null));
      setValue('modality', get(entity, 'modality', []));
      setValue('requestingPhysician', get(entity, 'requestingPhysician', {}) || {});
      setValue('referringPhysician', get(entity, 'referringPhysician', {}) || {});
      setValue('performingPhysician', get(entity, 'performingPhysician', {}) || {});
      setValue('archiveId', get(entity, 'archiveId', null));
    }
  }, [study, createDefaultValues, setValue]);

  return (
    <FormProvider {...methods}>
      <form>
        <OverflowedDialog
          title={t('importWebcam')}
          open={importWebcamDialog}
          onClose={() => {
            toggleImportWebcamDialog(false);
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth={true}
          actions={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Button
                onClick={() => toggleImportWebcamDialog(false)}
                variant="contained"
                color="inherit"
                sx={{ mr: 1 }}
              >
                {t('close')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                type="submit"
                disabled={!base64Image}
              >
                {t('confirm')}
              </Button>
            </Box>
          }
        >
          <Papeer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <WebcamCapture
                  webcamConfig={webcamConfig}
                  setValue={setValue}
                  setBase64Image={setBase64Image}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <PatientAndStudyEditForm
                  isRequest={false}
                  errors={errors}
                  isImport={true}
                  validatorFormName="importForm"
                  collapseForm={true}
                  modalities={modalities.map((modality) => ({
                    value: modality.name,
                    label: modality.name,
                  }))}
                  withStudyUID={true}
                  allFormsDisabled={true} // pokud import z detailu studie tak vše disabled
                  study={study}
                  showPatientName={true}
                />
              </Grid> */}

              <Grid item xs={12} md={4} lg={3}>
                <FormSelect name="archiveId" label={t('archive')} items={sources} required={true} />
              </Grid>
            </Grid>
          </Papeer>
        </OverflowedDialog>
      </form>
    </FormProvider>
  );
};

export default StudyDetailImportWebcam;

import { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  IAddConnectedModalitiesForm,
  IConnectedModalitiesFormElement,
  IConnectedModality,
} from './_types.';
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from 'components/Form/Input/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import useValidationSchema from './_form';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { createConnectedModality, editConnectedModality } from './_api';
import useAlerts from 'components/Alerts/useAlerts';
import { get } from 'lodash';

export const ConnectedModalitiesForm: React.FC<IConnectedModalitiesFormElement> = ({
  onClose,
  entityForEdit,
}) => {
  const { t } = useTranslation('ConnectedModalities');

  const { AddConnectedModalitiesFormSchema, formItems } = useValidationSchema();
  const { toggleLoader } = useAppGlobals();
  const [entity, setEntity] = useState<any>({});
  const [originalAet, setOriginalAet] = useState<string>('');
  const { addSuccessAlert, addErrorAlert } = useAlerts();

  const isCreating = entityForEdit === null;

  const methods = useForm<IAddConnectedModalitiesForm>({
    defaultValues: {},
    resolver: yupResolver(AddConnectedModalitiesFormSchema),
  });
  const { handleSubmit } = methods;

  useEffect(() => {
    const entity: IConnectedModality =
      entityForEdit !== null
        ? entityForEdit
        : {
            ip: '',
            port: 0,
            aet: '',
            storageDirectory: '',
            manufacturer: '',
            comment: '',
          };

    // May be used in the future
    // const productId = get(entity, 'productId', null);
    // if (productId !== null) {
    //   setArchiveForLinkBack('?selectedArchive=' + productId);
    // }
    setOriginalAet(get(entity, 'aet', ''));
    setEntity(entity);
    methods.reset(entity as unknown as IAddConnectedModalitiesForm);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formSubmit = handleSubmit(async (values: any) => {
    toggleLoader();
    const val = isCreating
      ? { ...values, productId: entity.productId }
      : { ...values, productId: entity.productId, originalAet };

    const fn = isCreating ? createConnectedModality : editConnectedModality;
    await fn(val).then(
      (response) => {
        addSuccessAlert(t(isCreating ? 'createSuccessfully' : 'editSuccessfully'));
        onClose();
      },
      (error) => {
        console.log('response.data', get(error, 'response.data', ''));
        let tError = isCreating ? 'createError' : 'editError';
        if (get(error, 'response.data', '') === 'error.same.name') {
          tError = 'errorFolderExists';
        }
        addErrorAlert(t(tError));
      },
    );

    toggleLoader(false);
  });

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">{t('create')}</DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={formSubmit}>
            <Grid container={true} spacing={2}>
              {formItems.map((field) => (
                <Grid item={true} xs={12} key={field.name}>
                  <FormInput name={field.name} label={field.label} required={true} />
                </Grid>
              ))}
              <Grid item={true} xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
                <Button type="submit" variant="contained" color="primary">
                  {t('save')}
                </Button>
                <Button variant="contained" color="inherit" sx={{ ml: 1 }} onClick={onClose}>
                  {t('back')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

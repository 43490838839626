import React from 'react';
import { Button, Tooltip } from '@mui/material';

import { IBatchButton } from './_types';

export const BatchButton: React.FC<IBatchButton> = ({
  onClick,
  numberOfSelectedStudies,
  label,
  style,
  size,
  maxCount,
  tooltipText,
}) => {
  return tooltipText ? (
    <Tooltip title={tooltipText}>
      {maxCount && numberOfSelectedStudies && numberOfSelectedStudies > maxCount ? (
        <span>
          <Button
            size={size || 'medium'}
            variant="contained"
            disabled={true}
            style={style ? style : {}}
          >
            {label}
            {numberOfSelectedStudies ? ` (${numberOfSelectedStudies})` : ''}
          </Button>
        </span>
      ) : (
        <Button
          size={size || 'medium'}
          variant="contained"
          color="primary"
          onClick={onClick}
          disabled={!numberOfSelectedStudies}
          style={style ? style : {}}
        >
          {label}
          {numberOfSelectedStudies ? ` (${numberOfSelectedStudies})` : ''}
        </Button>
      )}
    </Tooltip>
  ) : (
    <Button
      size={size || 'medium'}
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={!numberOfSelectedStudies}
      style={style ? style : {}}
    >
      {label}
      {numberOfSelectedStudies ? ` (${numberOfSelectedStudies})` : ''}
    </Button>
  );
};

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { get } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getLanguage, editLanguage } from './_apiLanguages';
import { ILanguage, ILanguageForm, ILanguageFormContent } from './_types';
import useValidationSchema from './_formLanguage';

import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSwitch from 'components/Form/Switch/Switch';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';
import { Box } from '@mui/system';

const LanguageForm: React.FC<ILanguageFormContent> = ({ toggleDialog, id, loadEntity }) => {
  const { t } = useTranslation('Languages');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const [entity, fetchEntity] = useState<ILanguage>();
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const { title } = useWithEntityTitle(false, entity, t);

  const { LanguageFormSchema, formItems } = useValidationSchema(t);

  const methods = useForm<ILanguageForm>({
    resolver: yupResolver(LanguageFormSchema),
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const language: ILanguage = {
      ...values,
      id: get(entity, 'id', 0),
    };
    const response = await editLanguage(language);
    if (response) {
      addSuccessAlert(t('saved'));
      loadEntity();
      toggleDialog(false);
    } else {
      addErrorAlert(t('errorSaving'));
    }
    toggleLoader(false);
  });

  const getEntities = async () => {
    toggleLoader();
    try {
      let language: ILanguage = { id: 0 };

      if (id) {
        const entity = await getLanguage(id.toString());
        language = { ...entity };
        if (entity) {
          fetchEntity(entity);
        }
      }
      reset({ ...language });
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isFetchingEntity && (
        <>
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <Grid container={true} alignItems="flex-end">
                  <Grid item={true} xs={12} md={6}>
                    {formItems.map((formItem, index) => {
                      const type = get(formItem, 'type', 'text');
                      const name = get(formItem, 'name', '');
                      const isDisabled =
                        name === 'default'
                          ? get(entity, 'default', false)
                          : get(formItem, 'disabled', false);
                      const label = get(formItem, 'label', '');

                      return (
                        <React.Fragment key={`formItem-${index}`}>
                          {type === 'switch' ? (
                            <Box sx={{ mb: 1 }}>
                              <FormSwitch name={name} label={label} disabled={isDisabled} />
                            </Box>
                          ) : (
                            <FormInput
                              {...formItem}
                              rows={get(formItem, 'rows', 1)}
                              disabled={isDisabled}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                    <EntityButtons toggleDialog={toggleDialog} />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
        </>
      )}
    </>
  );
};

export default LanguageForm;

import { jwtDecode as decode } from 'jwt-decode';
import { isObject } from 'lodash';
import { isAfter } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { useUser } from './useUser';
import { useStorage } from './useStorage';
import { IDecodedToken } from 'modules/Login/_types';
import { refreshJwtToken, getRoles } from 'modules/Login/_api';
import { setUse2FA } from 'store/reducers/appReducer';
import { useAppDispatch } from 'store/hooks';

export const useToken = () => {
  const navigate = useNavigate();
  const { getItem, putItem, removeItem } = useStorage();
  const { loadUserToStore, loadRolesToStore, unloadUserFromStore, roles } = useUser();
  const dispatch = useAppDispatch();

  const storageToken: string | null = getItem('token');

  const tokenDecoder = (token: string | null = storageToken): Partial<IDecodedToken> => {
    const invalidToken = { expired: true };

    try {
      // Return null when token null
      if (token === null) {
        return invalidToken;
      }

      // Decode token
      const decodedToken: Partial<IDecodedToken> = decode(token) as Partial<IDecodedToken>;

      // Return decoded token or null if it's not valid
      if (isObject(decodedToken) && decodedToken.exp) {
        decodedToken.expired = isAfter(decodedToken.exp * 1000, new Date()) ? false : true;
        return decodedToken;
      } else {
        return invalidToken;
      }
    } catch (e) {
      return invalidToken;
    }
  };

  const logOutUser = () => {
    removeItem('token');
    navigate('/login');
    unloadUserFromStore();
  };

  const refreshTokenAndStore = async (hasLoadRoles: boolean = false) => {
    const token = await refreshJwtToken();
    if (token) {
      try {
        const decodedToken = tokenDecoder(token);
        if (!decodedToken.expired) {
          putItem('token', token);
          loadUserToStore({ ...decodedToken, auth: roles });
          if (hasLoadRoles) {
            const roles = await getRoles();
            loadRolesToStore(roles);
          }
          dispatch(setUse2FA(decodedToken.requireTOTPAuth as boolean));
        }
      } catch (e) {
        logOutUser();
      }
    } else {
      logOutUser();
    }
  };

  return { tokenDecoder, storageToken, refreshTokenAndStore };
};

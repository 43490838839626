import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = () => {
  const { required, integer, number, positive } = useValidators();

  const StudyTestTypeDefinitionsFormSchema = yup.object().shape({
    group: required(yup.mixed()),
    item: required(yup.mixed()),
    questionsMin: integer(positive(number(yup.number(), 'positiveWithoutZero'))),
    questionsMax: integer(positive(number(yup.number(), 'positiveWithoutZero'))),
  });

  return {
    StudyTestTypeDefinitionsFormSchema,
  };
};

export default useValidationSchema;

import { get } from 'lodash';
import { resources } from 'constants/translations/languages';

export const findTranslation = (language: string, translationModule: string, key: string) => {
  const sourceValue = `${language}.${translationModule}.${key}`;
  const value = get(resources, sourceValue, null) || sourceValue;
  try {
    return value;
  } catch (e) {
    return sourceValue;
  }
};

import { IGridSettingsStudyMUI } from 'components/SearchResultsMUI/_types';
import { api } from 'utils/hooks/useApi';

// export const exportECG = (productId: string, studyUid: string): Promise<unknown> =>
//   api()
//     .get(`/portal-api/studyExportECG/download?productId=${productId}&studyUid=${studyUid}`)
//     .then((response) => response)
//     .catch((thrown) => {
//       return false;
//     });

export const setGridSettings = async (settings: {
  key: string;
  gridSettingsForAPI: IGridSettingsStudyMUI | any; // doplnit další typy - např. mwl, auditLogs,  preview, atd.
}) => {
  await api()
    .post(`/portal-api/userSetting/saveGridSettings`, {
      category: settings.key,
      gridSettings: JSON.stringify(settings.gridSettingsForAPI),
    })
    .then((response) => response)
    .catch((thrown) => {
      return false;
    });
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { compact, filter, get, isArray, sortBy } from 'lodash';
import { Button, Typography } from '@mui/material';
import {
  getAllExpertness,
  getAllHealthInsuranceCompany,
  getAllPriority,
  getAllRequestedProcedure,
} from './_api';
import { getAllUnits } from 'modules/Administration/ModalitiesUnitsStations/_apiUnits';
import { getAllStations } from 'modules/Administration/ModalitiesUnitsStations/_apiStations';
import { getAllModalities } from 'modules/Administration/ModalitiesUnitsStations/_apiModalities';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Papeer } from 'components/Papeer/Papeer';
import { joinParams } from 'utils/study';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { getActiveFacility } from 'modules/Administration/Facilities/_api';
import { getAllByPhysicianInclusion } from 'modules/Administration/Dictionaries/_api';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import OrderFormComponent from './OrderFormComponent';
import { useMwl } from 'utils/hooks/useMwl';

const OrderForm: React.FC<any> = ({ entity }) => {
  const { t } = useTranslation('Orders');
  let [searchParams] = useSearchParams();
  const { toggleLoader } = useAppGlobals();
  const { compactMode, portalProductSettings, validators } = useAppInfo();
  const { isCreating, paramsPatientId, paramsAccessionNumber } = useEntityInfo();
  const { setPhysician2 } = useMwl();

  const [dictionaryLists, setDictionaryLists] = useState<any>({
    expertness: null,
    healthInsuranceCompany: null,
    requestedProcedure: null,
    getAllPriority: null,
  });
  const [dictionarySelect, setDictionarySelect] = useState<any>({
    expertnessSelect: null,
    healthInsuranceSelect: null,
    requestedProcedureSelect: null,
    referringPhysicianSelect: null,
    requestingPhysicianSelect: null,
  });
  const [isFacilityCode, setFacilityCode] = useState<boolean | null>(null);
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [linkBack2, setLinkBack2] = useState<string>('');

  const [defaultValues, setDefaultValues] = useState({});

  const { currentLocale } = useLanguage();

  const parseJsonText = (data: any) => {
    return data.map((item: any) => {
      const text = get(item, 'text', false);
      if (text) {
        item.text = JSON.parse(text);
      }
      return item;
    });
  };

  const convertJsonToArray = (data: any) => {
    return data && isArray(data)
      ? parseJsonText(data).map((item: any) => ({
          id: item.id,
          label: `${joinParams([
            get(item, 'code', ''),
            get(item, `text.${currentLocale}`, get(item, 'value')),
          ])}`,
        }))
      : null;
  };

  const getArrayFromList = (data: any) => {
    return data && isArray(data)
      ? sortBy(
          compact(
            data.map((item: any) => {
              const value = item.id;
              const label = item.label;
              return { value, label };
            }),
          ),
          ['label'],
        )
      : [];
  };

  const loadPhysicians = async (
    dictionaryLists: any,
    isReferringPhysician: boolean,
    isRequestingPhysician: boolean,
  ) => {
    const allByPhysicianInclusion: any = await getAllByPhysicianInclusion(
      isReferringPhysician,
      isRequestingPhysician,
    );
    if (allByPhysicianInclusion) {
      const referringPhysician = filter(
        get(allByPhysicianInclusion, 'referringPhysicians', []),
        (item) => item.name,
      ).map((item: any) => ({
        id: `${item.name.lastName}|${item.name.firstName}|${item.name.middleName}|${item.name.prefix}|${item.name.suffix}`,
        label: `${joinParams([
          item.name.lastName || '',
          item.name.firstName || '',
          item.name.middleName || '',
          item.name.prefix || '',
          item.name.suffix || '',
        ])}`,
      }));
      const requestingPhysician = filter(
        get(allByPhysicianInclusion, 'requestingPhysicians', []),
        (item) => item.name,
      ).map((item: any) => ({
        id: `${item.name.lastName}|${item.name.firstName}|${item.name.middleName}|${item.name.prefix}|${item.name.suffix}`,
        label: `${joinParams([
          item.name.lastName || '',
          item.name.firstName || '',
          item.name.middleName || '',
          item.name.prefix || '',
          item.name.suffix || '',
        ])}`,
      }));
      setDictionaryLists({ ...dictionaryLists, referringPhysician, requestingPhysician });
      const dictionarySelect = {
        expertnessSelect: getArrayFromList(get(dictionaryLists, 'expertness', [])),
        healthInsuranceSelect: getArrayFromList(get(dictionaryLists, 'healthInsuranceCompany', [])),
        requestedProcedureSelect: getArrayFromList(get(dictionaryLists, 'requestedProcedure', [])),
        requestingPhysicianSelect: getArrayFromList(requestingPhysician),
        referringPhysicianSelect: getArrayFromList(referringPhysician),
      };
      setDictionarySelect(dictionarySelect);
      setIsFetchingEntity(false);
    }
  };
  const getEntity = async () => {
    toggleLoader();
    const from = searchParams.get('from') || null;
    const backTo = searchParams.get('backTo') || null;
    const linkBack2 =
      from === 'detail'
        ? `/orders/${paramsPatientId}/${paramsAccessionNumber}/detail${
            backTo ? '?backTo=' + backTo : ''
          }`
        : '/orders?action=back';
    setLinkBack2(linkBack2);

    const [
      allExpertness,
      healthCompanies,
      priority,
      requestedProcedures,
      units,
      stations,
      modalities,
      activeFacility,
    ] = await Promise.all([
      getAllExpertness(),
      getAllHealthInsuranceCompany(),
      getAllPriority(),
      getAllRequestedProcedure(),
      getAllUnits(),
      getAllStations(),
      getAllModalities(),
      getActiveFacility(),
    ]);
    const dictionaryLists = {
      expertness: convertJsonToArray(allExpertness),
      healthInsuranceCompany: convertJsonToArray(healthCompanies),
      requestedProcedure: (parseJsonText(requestedProcedures) || []).map((item: any) => ({
        id: get(item, 'code', ''),
        label: get(item, 'code', ''),
      })),

      priority: (parseJsonText(priority) || []).map((item: any) => ({
        id: item.id,
        label: get(item, `text.${currentLocale}`, get(item, 'value')),
      })),
      units: (units || []).map((item: any) => ({ id: item.id, label: item.name })),
      stations: (stations || []).map((item: any) => ({ id: item.id, label: item.aetitle })),
      modalities: (modalities || []).map((item: any) => ({ id: item.id, label: item.name })),
    };
    loadPhysicians(dictionaryLists, true, true);
    if (activeFacility) {
      setFacilityCode(true);
    } else {
      setFacilityCode(false);
    }

    let defaultValues = { ...entity };
    defaultValues = isCreating
      ? {
          ...defaultValues,
          priority: { id: 3 },
          patient: {
            dateBirth2: '',
          },
          originalDateRequested: new Date(),
          originalTimeRequested: format(new Date(), 'HH:mm'),
          referringPhysician2:
            get(dictionarySelect, 'referringPhysicianSelect', null) &&
            get(dictionarySelect, 'referringPhysicianSelect').length === 1
              ? get(dictionarySelect, 'referringPhysicianSelect')[0]
              : null,
          requestingPhysician2:
            get(dictionarySelect, 'requestingPhysicianSelect', null) &&
            get(dictionarySelect, 'requestingPhysicianSelect').length === 1
              ? get(dictionarySelect, 'requestingPhysicianSelect')[0]
              : null,
        }
      : {
          ...defaultValues,
          patient: {
            ...entity.patient,
            dateBirth2: new Date(get(entity, 'patient.dateBirth', '')),
          },
          originalDateRequested: new Date(get(entity, 'originalDatetimeRequested', '')),
          originalTimeRequested: format(
            new Date(get(entity, 'originalDatetimeRequested', '')),
            'HH:mm',
          ),
          patientDispositions: {
            size:
              get(entity, 'patientDispositions', null) &&
              get(entity, 'patientDispositions.size', null) &&
              get(entity, 'patientDispositions.size') === 'null'
                ? ''
                : get(entity, 'patientDispositions.size'),
            weight:
              get(entity, 'patientDispositions', null) &&
              get(entity, 'patientDispositions.weight', null) &&
              get(entity, 'patientDispositions.weight') === 'null'
                ? ''
                : get(entity, 'patientDispositions.weight'),
          },
          healthInsuranceCompany2: get(entity, 'healthInsuranceCompany.id', null)
            ? {
                value: get(entity, 'healthInsuranceCompany.id', null),
                label: get(entity, 'healthInsuranceCompany.label', null),
              }
            : null,
          expertness2: get(entity, 'expertness.id', null)
            ? {
                value: get(entity, 'expertness.id', null),
                label: get(entity, 'expertness.label', null),
              }
            : null,
          requestedProcedure2: get(entity, 'requestedProcedure', null)
            ? {
                value: get(entity, 'requestedProcedure'),
                label: get(entity, 'requestedProcedure'),
              }
            : null,
          requestingPhysician2: setPhysician2(entity, 'requestingPhysician'),
          referringPhysician2: setPhysician2(entity, 'referringPhysician'),
        };
    setDefaultValues(defaultValues);
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {entity || isCreating ? (
        isFacilityCode === null || isFetchingEntity ? null : isFacilityCode ? (
          <OrderFormComponent
            isCreating={isCreating}
            entity={entity}
            dictionaryLists={dictionaryLists}
            dictionarySelect={dictionarySelect}
            defaultValues={defaultValues}
            linkBack2={linkBack2}
            compactMode={compactMode}
            portalProductSettings={portalProductSettings}
            validators={get(validators, 'studyOrderForm')}
            loadPhysicians={loadPhysicians}
          />
        ) : (
          <>
            <Papeer>
              <Typography>{t('isNotFacilityCode')}</Typography>
            </Papeer>
            <Papeer>
              <Button
                variant="contained"
                size="large"
                component={Link}
                sx={{ mr: 1 }}
                {...({ to: linkBack2 } as any)}
              >
                {t('back')}
              </Button>
            </Papeer>
          </>
        )
      ) : (
        <Papeer>
          <Typography>{t('orderNotFound')}</Typography>
        </Papeer>
      )}
    </>
  );
};

export default OrderForm;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { filter, get, isEmpty, isString, omit, pick, sortBy } from 'lodash';
import {
  createDictionary,
  editDictionary,
  getAllDecubits,
  getAllDecubitsDictionaries,
  getDictionary,
} from './_api';
import { IDictionaryForm } from './_types';

import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';

import useValidationSchema from './_form';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useForm2 } from 'utils/hooks/useForm2';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormAutocomplete from 'components/Form/Autocomplete/Autocomplete';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';

import useAlerts from 'components/Alerts/useAlerts';
import { DECUBITS_CODE } from 'constants/constants';

const DictionaryFormDictionary: React.FC<any> = ({ dictionary, id, languages }) => {
  const { t } = useTranslation('Dictionaries');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { compactMode } = useAppInfo();
  const { formatLabelForRequired } = useForm2();

  const isCreating = id === 'create';
  const linkBack = `/administration/generalSettings/${dictionary}`;

  const [entity, fetchEntity] = useState<IDictionaryForm>();
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [allDecubits, setAllDecubits] = useState<any[]>([]);
  const [decubitsField, setDecubitsField] = useState<any[]>([]);
  const navigate = useNavigate();
  const { title } = useWithEntityTitle(isCreating, entity, t);

  const decubitsCode = DECUBITS_CODE.map((item) => ({
    id: item.value,
    label: t(`decubitsCode.${item.label}`),
  }));

  const { DictionaryFormSchema } = useValidationSchema(t, languages, dictionary, isCreating);

  const methods = useForm<IDictionaryForm>({
    resolver: yupResolver(DictionaryFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const prepareValues = (values: any) => {
    let canSubmit = true;
    if (dictionary === 'decubits') {
      if (isEmpty(get(values, 'bodyPartExaminated', ''))) {
        addErrorAlert(t('bodyPartExaminatedRequired'));
        canSubmit = false;
      } else if (isEmpty(get(values, 'patientPosition', ''))) {
        addErrorAlert(t('patientPositionRequired'));
        canSubmit = false;
      }
      if (canSubmit) {
        const duplicatesDecubits = filter(allDecubits, {
          bodyPartExaminated: { id: get(values, 'bodyPartExaminated.value') },
          imageLaterality: { id: get(values, 'imageLaterality') * 1 },
          patientPosition: { id: get(values, 'patientPosition.value') },
        });
        if (duplicatesDecubits.length > 0) {
          if (get(duplicatesDecubits[0], 'id') !== get(entity, 'id')) {
            addErrorAlert(t('duplicateDecubit'));
            canSubmit = false;
          }
        }
      }
    }

    let text = {};
    const keys: any[] = [];
    (languages || []).forEach((lang: any) => {
      const abbr = get(lang, 'abbreviation');
      const key = `text_${abbr}`;
      const value = get(values, key, null);
      text = { ...text, [abbr]: value };
      keys.push(key);
    });
    let preparedValues: any = null;
    if (canSubmit) {
      preparedValues =
        dictionary === 'dose'
          ? {
              ...pick(values, ['id', 'dicomTag', 'unit']),
              dictionary,
              name: JSON.stringify(text),
            }
          : dictionary === 'studyDetailBookmarks'
          ? {
              ...omit(values, keys),
              order: get(values, 'order') * 1,
              dictionary,
              name: JSON.stringify(text),
            }
          : dictionary === 'decubits'
          ? {
              ...omit(values, keys),
              dictionary: dictionary,
              translateJson: JSON.stringify(text),
              bodyPartExaminated: { id: get(values, 'bodyPartExaminated.value') },
              imageLaterality: { id: get(values, 'imageLaterality') },
              patientPosition: { id: get(values, 'patientPosition.value') },
            }
          : {
              ...pick(values, ['id', 'code', 'value']),
              dictionary,
              text: JSON.stringify(text),
            };
    }
    return preparedValues;
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const preparedValues = prepareValues(values);
    if (preparedValues) {
      const fn = isCreating ? createDictionary : editDictionary;
      const response = await fn(preparedValues);
      if (response === true) {
        addSuccessAlert(t('saved'));
        navigate(linkBack);
      } else {
        addErrorAlert(t(isString(response) ? response : 'errorSaving'));
      }
    }
    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      let init: IDictionaryForm = {
        id: 0,
        code: '',
        dicomTag: '',
      };

      if (!isCreating && id && dictionary) {
        const entity = await getDictionary({ dictionary, id });
        if (entity) {
          let defaultValues = {};
          const keyPrefix =
            dictionary === 'dose' || dictionary === 'studyDetailBookmarks'
              ? 'name'
              : dictionary === 'decubits'
              ? 'translateJson'
              : 'text';
          (languages || []).forEach((lang: any) => {
            const key = `text_${get(lang, 'abbreviation')}`;
            const jsonValue = JSON.parse(get(entity, keyPrefix));
            defaultValues = { ...defaultValues, [key]: get(jsonValue, lang.abbreviation, '') };
          });
          init =
            dictionary === 'dose' || dictionary === 'studyDetailBookmarks'
              ? {
                  ...omit(entity, ['name']),
                  ...defaultValues,
                }
              : dictionary === 'decubits'
              ? {
                  ...entity,
                  ...defaultValues,
                  bodyPartExaminated: {
                    value: get(entity, 'bodyPartExaminated.id', null),
                    label: get(entity, 'bodyPartExaminated.dicomValue', null),
                  },
                  imageLaterality: get(entity, 'imageLaterality.id', null),
                  patientPosition: {
                    value: get(entity, 'patientPosition.id', null),
                    label: get(entity, 'patientPosition.dicomValue', null),
                  },
                }
              : { ...entity, ...defaultValues };
        }

        if (entity) {
          fetchEntity(init);
        }
      }
      if (id && dictionary === 'decubits') {
        const dictionariesFromApi = await getAllDecubitsDictionaries();
        const dictionaries = {
          bodyPartExaminated: sortBy(
            get(dictionariesFromApi, 'bodyPartExaminated', []).map((item: any) => ({
              value: item.id,
              label: item.dicomValue,
            })),
            ['label'],
          ),
          imageLaterality: sortBy(
            get(dictionariesFromApi, 'imageLaterality', []).map((item: any) => ({
              id: item.id,
              label: item.dicomValue,
            })),
            ['label'],
          ),
          patientPosition: sortBy(
            get(dictionariesFromApi, 'patientPosition', []).map((item: any) => ({
              value: item.id,
              label: item.dicomValue,
            })),
            ['label'],
          ),
        };
        setDecubitsField([
          {
            type: 'select2',
            name: 'bodyPartExaminated',
            items: get(dictionaries, 'bodyPartExaminated', []),
            required: true,
          },
          {
            type: 'select',
            name: 'imageLaterality',
            items: get(dictionaries, 'imageLaterality', []),
            required: true,
          },
          {
            type: 'select2',
            name: 'patientPosition',
            items: get(dictionaries, 'patientPosition', []),
            required: true,
          },
        ]);
        const allDecubits = await getAllDecubits();
        setAllDecubits(allDecubits);
      }

      reset({ ...init });
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isFetchingEntity && (
        <>
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} md={6} lg={4}>
                    {dictionary === 'decubits' ? (
                      <FormSelect
                        name="code"
                        label={t('code')}
                        required={true}
                        items={decubitsCode}
                      />
                    ) : (
                      <FormInput
                        name={
                          dictionary === 'dose'
                            ? 'dicomTag'
                            : dictionary === 'studyDetailBookmarks'
                            ? 'type'
                            : 'code'
                        }
                        label={t(
                          dictionary === 'dose'
                            ? 'dicomTag'
                            : dictionary === 'studyDetailBookmarks'
                            ? 'type'
                            : 'code',
                        )}
                        placeholder={dictionary === 'dose' ? '(xxxx,xxxx)' : ''}
                        disabled={dictionary === 'studyDetailBookmarks'}
                        required={true}
                      />
                    )}
                  </Grid>
                  {dictionary === 'dose' && (
                    <Grid item={true} xs={12} md={6} lg={4}>
                      <FormInput name="unit" label={t('unit')} />
                    </Grid>
                  )}
                  <Grid item={true} xs={12} />
                  {filter(languages, { active: true }).map((lang: any) => (
                    <Grid item={true} xs={12} md={6} lg={4} key={lang.id}>
                      <FormInput
                        key={lang.id}
                        name={`text_${lang.abbreviation}`}
                        label={`${t('name')} - ${lang.name}`}
                        required={lang.default}
                      />
                    </Grid>
                  ))}
                  <Grid item={true} xs={12} />
                  {dictionary !== 'dose' && dictionary !== 'decubits' && (
                    <>
                      <Grid item={true} xs={12} md={6} lg={4}>
                        <FormInput
                          name={dictionary === 'studyDetailBookmarks' ? 'order' : 'value'}
                          label={t(dictionary === 'studyDetailBookmarks' ? 'order' : 'value')}
                          type={dictionary === 'studyDetailBookmarks' ? 'number' : ''}
                          required={true}
                        />
                      </Grid>
                      <Grid item={true} xs={12} />
                    </>
                  )}
                  {dictionary === 'decubits' && (
                    <>
                      {decubitsField.map((field: any) =>
                        get(field, 'type') === 'select2' ? (
                          <Grid item={true} xs={12} md={4} key={`grid_${get(field, 'name')}`}>
                            <Typography
                              component="div"
                              paragraph={true}
                              style={{ marginRight: '11px' }}
                            >
                              <div style={{ marginTop: compactMode ? 8 : 2 }}>
                                <FormAutocomplete
                                  name={get(field, 'name')}
                                  label={t(get(field, 'name'))}
                                  options={get(field, 'items')}
                                  placeholder={
                                    compactMode
                                      ? formatLabelForRequired(
                                          t(get(field, 'name')),
                                          get(field, 'required'),
                                        )
                                      : t('select')
                                  }
                                  required={get(field, 'required')}
                                />
                              </div>
                            </Typography>
                          </Grid>
                        ) : get(field, 'type') === 'select' ? (
                          <Grid item={true} xs={12} md={4}>
                            <FormSelect
                              name={get(field, 'name')}
                              label={t(get(field, 'name'))}
                              required={get(field, 'required')}
                              items={get(field, 'items')}
                            />
                          </Grid>
                        ) : null,
                      )}
                    </>
                  )}
                  <Grid item={true} xs={12}>
                    <EntityButtons linkBack={linkBack} />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
        </>
      )}
    </>
  );
};

export default DictionaryFormDictionary;

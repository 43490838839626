import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { get, isNumber } from 'lodash';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-pro';
import GridDetailIcon from '@mui/icons-material/Description';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { encryptId } from 'utils/hooks/useApp';
import { generateIID } from 'utils/study';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'globalStatsErrorRateMui';

const GlobalStatsErrorRate: React.FC<any> = ({ studyStatistics, dataTour }) => {
  const { t } = useTranslation('Tests');

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const navigate = useNavigate();

  const onCustomEntityDetail = (row: any) => {
    navigate(`/study/${encryptId(generateIID(get(row, 'study')))}?from=globalStats`);
  };

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 100,
        headerAlign: 'left',
        align: 'left',
        getActions: ({ row }: GridRowParams) => [
          <GridActionsCellItem
            icon={
              <Tooltip title={t('Grid:testResultDetail')}>
                <GridDetailIcon />
              </Tooltip>
            }
            label="Detail"
            onClick={() => onCustomEntityDetail(row)}
          />,
        ],
      },
      {
        field: 'errorRate',
        headerName: t('errorRate'),
        valueGetter: (value: any, row: any) => {
          const errorRate = get(row, 'errorRate');
          return isNumber(errorRate) ? `${get(row, 'errorRate').toFixed(0)} %` : errorRate;
        },
      },
      {
        field: 'errorUsageCount',
        headerName: t('errorUsageCount'),
        valueGetter: (value: any, row: any) =>
          `${get(row, 'errorUsageCount')}/${get(row, 'usageCount')}`,
      },
      {
        field: 'patientID',
        headerName: t('patientID'),
        valueGetter: (value: any, row: any) => get(row, 'study.patient.identificationNumber'),
      },
    ]),
  );

  return (
    <MuiGrid gridKey={muiGridKey} rows={studyStatistics} columns={columns} dataTour={dataTour} />
  );
};

export default GlobalStatsErrorRate;

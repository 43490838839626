import React, { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { find, get, isEmpty, isUndefined } from 'lodash';
import { Box, Button, Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowModel,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { Delete as DeleteIcon, Description as GridDetailIcon } from '@mui/icons-material/';
import { removeDocument } from './_api';
import { IFolderDetailDocuments, IFunctionItem } from './_types';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useActions } from 'utils/hooks/useActions';
import { joinParams } from 'utils/study';
import { useEntityRemove3 } from 'utils/hooks/useEntityRemove3';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import {
  DELETE_DOCUMENTS_FROM_FOLDER,
  DELETE_DOCUMENT_FROM_FOLDER,
  SHOW_DOCUMENT,
} from 'constants/constants';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const GRID_SETTINGS_KEY: string = 'folderDetailDocumentsMUI';
const selecting = true;

export const FolderDetailDocuments: React.FC<IFolderDetailDocuments> = ({
  documents,
  loadEntity,
  entity,
  maxCounts,
  dataTour,
}) => {
  const { t } = useTranslation('Folders');
  const dispatch = useAppDispatch();

  const { confirmationData } = useAppInfo();
  const navigate = useNavigate();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } =
    useMuiGrid(GRID_SETTINGS_KEY);

  const [selection, setSelection] = useState<any[]>([]);

  const { storeConfirmationData, storeSelection } = useActions();

  const { onEntityRemove3 } = useEntityRemove3(removeDocument, t, loadEntity);

  const confirmActionRemoveDocumentFromFolder = async () => {
    onEntityRemove3(get(confirmationData, 'folderId'), get(confirmationData, 'id'));
    clearAction();
    const index = selection.indexOf(get(confirmationData, 'id'));
    if (index > -1) {
      selection.splice(index, 1);
      setSelection(selection);
    }
  };
  const confirmActionRemoveDocumentsFromFolder = async () => {
    selection.forEach((id) => {
      const patientDocument = find(documents, { id });
      if (patientDocument) {
        onEntityRemove3(get(patientDocument, 'folderId'), get(patientDocument, 'uuid'));
      }
    });
    clearAction();
    setSelection([]);
  };

  const showDocuments = useCallback(
    (documents: any) => {
      storeSelection(documents);
      const pathnameForLinkBack = window.location.pathname.replace('portal/', '');
      navigate(`/clinicPortal/documents/${get(entity, 'id')}?pathname=${pathnameForLinkBack}`);
    },
    [entity, navigate, storeSelection],
  );

  const handleClickOpenDocumentsFromFolder = useCallback(() => {
    const selectedDocuments: any[] = [];
    selection.forEach((id) => {
      const patientDocument = find(documents, { id });
      if (patientDocument) {
        selectedDocuments.push(patientDocument.uuid);
      }
    });
    if (!isEmpty(selectedDocuments)) {
      showDocuments(selectedDocuments);
    }
  }, [selection, documents, showDocuments]);

  const handleClickRemoveDocumentsFromFolder = useCallback(() => {
    storeConfirmationData({
      title: t('confirmRemoveDocumentsFromFolder'),
      id: 'studiesdocuments',
      confirmAction: DELETE_DOCUMENTS_FROM_FOLDER,
    });
  }, [storeConfirmationData, t]);

  const clearAction = () => storeConfirmationData(null);
  const cancelAction = () => {
    clearAction();
  };

  const onEntityAction = async (row: any, action: string) => {
    showDocuments([row.uuid]);
  };

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          width: 250,
          renderCell: ({ row }: GridRenderCellParams) => (
            <>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:showDocument')}>
                    <GridDetailIcon />
                  </Tooltip>
                }
                label={t('Grid:showDocument')}
                onClick={() => {
                  onEntityAction(row, SHOW_DOCUMENT);
                }}
                disabled={!get(row, 'canDelete', true)}
              />
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:removeDocumentFromFolder')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:removeDocumentFromFolder')}
                onClick={() => {
                  dispatch(
                    storeConfirmationDataToStore({
                      folderId: get(row, 'folderId'),
                      id: get(row, 'uuid'),
                      title: 'confirmRemoveDocumentFromFolder',
                      confirmAction: DELETE_DOCUMENT_FROM_FOLDER,
                    }),
                  );
                }}
              />
            </>
          ),
        },
        {
          field: 'fullname',
          headerName: t('patientFullname'),
          valueGetter: (value: any, row: any) =>
            `${joinParams([
              get(row, 'patient.patientLastName'),
              get(row, 'patient.patientFirstName'),
            ])}`,
        },
        { field: 'typeOfDocumentName', headerName: t('typeOfDocument') },
        {
          field: 'createdDateTime',
          headerName: t('createdDateTime'),
          type: 'date',
          valueGetter: (value: any) => value && new Date(value),
        },
        { field: 'author', headerName: t('author') },
        { field: 'referenceID', headerName: t('referenceID') },
        { field: 'externalId', headerName: t('externalId') },
      ],
      200,
    ),
  );

  const functionList: IFunctionItem[] = useMemo(
    () => [
      {
        key: SHOW_DOCUMENT,
        label: t('multipleShowDocumentsFromFolder'),
        show: true,
        maxAllowedItem: 16,
        onClick: (row: GridRowModel | null) => {
          handleClickOpenDocumentsFromFolder();
        },
        icon: <GridDetailIcon fontSize="small" />,
      },
      {
        key: DELETE_DOCUMENTS_FROM_FOLDER,
        label: t('multipleRemoveDocumentsFromFolder'),
        show: true,
        onClick: (row: GridRowModel | null) => {
          handleClickRemoveDocumentsFromFolder();
        },
        icon: <DeleteIcon fontSize="small" />,
      },
    ],
    [t, handleClickRemoveDocumentsFromFolder, handleClickOpenDocumentsFromFolder],
  );

  const QuickSearchToolbar = useCallback(
    (props: any) => {
      return (
        <div>
          <Box style={{ padding: '5px', display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
            </Box>
          </Box>
          <Box
            sx={{
              px: 1,
              py: 0.5,
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {functionList.map((item: IFunctionItem, index: number) => {
              const showCount = isUndefined(item.showCount) ? true : item.showCount;
              const count = selection.length;
              const xcount = showCount && count ? ` (${count})` : '';
              const maxAllowedItem = isUndefined(item.maxAllowedItem) ? 0 : item.maxAllowedItem;
              return (
                <Tooltip key={index} title={item.label + xcount}>
                  <span>
                    <Button
                      key={item.key}
                      size={'medium'}
                      variant="contained"
                      color="primary"
                      onClick={() => item.onClick(null)}
                      disabled={
                        selection.length === 0 ||
                        (maxAllowedItem > 0 && maxAllowedItem < selection.length)
                      }
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {item.icon}
                    </Button>
                  </span>
                </Tooltip>
              );
            })}
          </Box>
        </div>
      );
    },
    [selection, functionList],
  );

  return (
    <>
      <MuiGrid
        rows={documents}
        gridKey={GRID_SETTINGS_KEY}
        columns={columns}
        rowSelecting={{ selecting, selection, setSelection }}
        toolbar={QuickSearchToolbar}
        initialSortMode={[{ field: 'createdDateTime', sort: 'asc' }]}
        dataTour={dataTour}
      />
      {confirmationData && confirmationData.id && (
        <>
          <ConfirmationDialog
            title={
              confirmationData.title ? t(`Grid:${confirmationData.title}`) : t('confirmDelete')
            }
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={cancelAction}
            confirmAction={
              confirmationData.confirmAction === DELETE_DOCUMENT_FROM_FOLDER
                ? confirmActionRemoveDocumentFromFolder
                : confirmationData.confirmAction === DELETE_DOCUMENTS_FROM_FOLDER
                ? confirmActionRemoveDocumentsFromFolder
                : confirmActionRemoveDocumentsFromFolder
            }
          />
        </>
      )}
    </>
  );
};

import React, { useMemo, useState } from 'react';
import { get, isFunction, isNumber } from 'lodash';
import { AppBar, Button, IconButton, Toolbar, Tooltip } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import OpenInBrowserIcon from '@mui/icons-material/Image';
import NextIcon from '@mui/icons-material/NavigateNext';
import BeforeIcon from '@mui/icons-material/NavigateBefore';

import { IImg } from './_types';
import {
  getPreview,
  getPreviewWithSize,
  getUrlForOpenViewer,
} from 'modules/Studies/StudyDetail/_api';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';

import { useAppInfo } from 'utils/hooks/useAppInfo';

import {
  DIALOG_PREVIEW_SIZE,
  PREVIEW_URL_SIZE,
  PREVIEW_URL_SIZE_COMPACT_MODE,
} from 'constants/constants';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

export const Img: React.FC<IImg> = ({
  patientId,
  productId,
  studyIUID,
  seriesIUID,
  imageIUID,
  series = null,
  topIndex = 0,
  width,
  dialogPreview = false,
  padding = false,
  responsive = false,
  preview,
  setNewPreviews,
  canLoadImgs = true,
  onlyText,
  disabled = false,
}) => {
  const { toggleLoader } = useAppGlobals();
  const { defViewer, compactMode } = useAppInfo();
  const { t } = useTranslation('Studies');

  const [imgSrc, setImgSrc] = useState<string>('');
  const [bigImgSrc, setBigImgSrc] = useState<string>('');
  const [itemsForPreview, setItemsForPreview] = useState<any>(null);
  const [index, setIndex] = useState<any>(null);
  const showBigPreview = async (itemsForPreview: any) => {
    if (itemsForPreview) {
      const productId = get(itemsForPreview, 'productId');
      const studyIUID = get(itemsForPreview, 'studyIUID');
      const seriesIUID = get(itemsForPreview, 'seriesIUID');
      const imageIUID = get(itemsForPreview, 'imageIUID', null);
      const width = get(itemsForPreview, 'width');
      const ind = get(itemsForPreview, 'ind', null);
      if (isNumber(ind)) {
        setIndex(ind);
      }
      if (productId !== undefined && studyIUID !== undefined && seriesIUID !== undefined) {
        toggleLoader();
        const base64Img = await getPreviewWithSize(
          productId,
          studyIUID,
          seriesIUID,
          imageIUID,
          width,
        );
        setBigImgSrc(base64Img);
        setItemsForPreview(itemsForPreview);
        toggleLoader(false);
      }
    }
  };

  const onViewer = (
    studyID: string,
    patientId: string,
    archiveID: string,
    seriesUID: any,
    sopUid: any,
  ) => {
    getUrlForOpenViewer(studyID, patientId, archiveID, seriesUID, sopUid).then(
      (response) => window.open(response, '_blank'),
      (error) => console.log(error),
    );
  };
  const closeDialogWithPreview = () => {
    setItemsForPreview(null);
    setIndex(null);
  };

  const numberOf = series ? series.length : 0;
  let i = index;
  if (index === null && isNumber(topIndex)) {
    setIndex(topIndex);
    i = topIndex;
  }
  const showPrevNext = series && numberOf > 1;
  const RenderedDialogWithPreview =
    bigImgSrc && itemsForPreview ? (
      <OverflowedDialog
        key={'imagePreview'}
        fullScreen={true}
        open={itemsForPreview}
        onClose={closeDialogWithPreview}
        aria-labelledby="form-dialog-title"
        withScrolling={true}
        contentStyle={{ padding: 0 }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            {patientId && defViewer && (
              <Tooltip title={t('openInBrowser')}>
                <IconButton
                  aria-label="Report"
                  onClick={() => {
                    onViewer(
                      get(itemsForPreview, 'studyIUID'),
                      patientId,
                      get(itemsForPreview, 'productId', ''),
                      get(itemsForPreview, 'seriesIUID', ''),
                      get(itemsForPreview, 'imageIUID', null),
                    );
                  }}
                  size="large"
                >
                  <OpenInBrowserIcon />
                </IconButton>
              </Tooltip>
            )}
            {showPrevNext && (
              // prev img for series
              <IconButton
                onClick={() => {
                  showBigPreview({
                    productId: get(itemsForPreview, 'productId', ''),
                    studyIUID: get(itemsForPreview, 'studyIUID'),
                    seriesIUID: get(series[i - 1], 'uid', ''),
                    // imageIUID,
                    width: DIALOG_PREVIEW_SIZE,
                    ind: i - 1,
                  });
                }}
                // title={t('showPreviewTitle')}
                disabled={i === 0}
                size="large"
              >
                <BeforeIcon />
              </IconButton>
            )}
            {showPrevNext && (
              // next img for series
              <IconButton
                onClick={() => {
                  showBigPreview({
                    productId: get(itemsForPreview, 'productId', ''),
                    studyIUID: get(itemsForPreview, 'studyIUID'),
                    seriesIUID: get(series[i + 1], 'uid', ''),
                    // imageIUID,
                    width: DIALOG_PREVIEW_SIZE,
                    ind: i + 1,
                  });
                }}
                // title={t('showPreviewTitle')}
                disabled={i + 1 === numberOf}
                size="large"
              >
                <NextIcon />
              </IconButton>
            )}
            <Box sx={{ flex: 1 }} />
            <Tooltip title={t('closePreview')}>
              <IconButton onClick={closeDialogWithPreview} size="large">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            margin: 'auto',
            justifyContent: 'center',
            maxWidth: '100%',
            height: 'auto',
            maxHeight: '90vh',
            marginTop: '1rem',
          }}
        >
          <img
            src={bigImgSrc}
            width={DIALOG_PREVIEW_SIZE}
            alt="preview"
            style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
          />
        </Box>
      </OverflowedDialog>
    ) : null;

  useMemo(async () => {
    if (productId !== undefined && studyIUID !== undefined && seriesIUID !== undefined) {
      if (!preview && canLoadImgs) {
        const base64Img = isNumber(width)
          ? await getPreviewWithSize(productId, studyIUID, seriesIUID, imageIUID, width)
          : await getPreview(productId, studyIUID, seriesIUID, imageIUID);
        setImgSrc(base64Img);
        if (isFunction(setNewPreviews)) {
          setNewPreviews({ iid: studyIUID, uid: seriesIUID, img: base64Img });
        }
      } else {
        setImgSrc(preview ? preview.img : '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, studyIUID, seriesIUID, imageIUID, compactMode]);

  const { RenderedImageIcon, RenderedImageWithWidth } = useMemo(() => {
    const RenderedImageWithWidth = (
      <Box
        sx={{
          margin: '0 auto',
          border: '2px solid #9e9e9e',
          padding: '2px',
          background: 'black',
          lineHeight: 0,
          width:
            (width ? width : compactMode ? PREVIEW_URL_SIZE_COMPACT_MODE : PREVIEW_URL_SIZE) + 8,
        }}
      >
        <img src={imgSrc} alt="preview2" style={{ maxWidth: '100%' }} />
      </Box>
    );

    const RenderedImageIcon = (
      <Box sx={{ display: 'flex', margin: 'auto', justifyContent: 'center' }}>
        {imgSrc ? RenderedImageWithWidth : <ImageIcon />}
      </Box>
    );
    return { RenderedImageIcon, RenderedImageWithWidth };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgSrc, width, compactMode]);

  return (
    <>
      {imgSrc ? (
        dialogPreview ? (
          <Button
            color="inherit"
            onClick={() => {
              showBigPreview({
                productId,
                studyIUID,
                seriesIUID,
                imageIUID,
                width: DIALOG_PREVIEW_SIZE,
              });
            }}
            disabled={disabled}
          >
            {onlyText ? onlyText : RenderedImageIcon || ''}
          </Button>
        ) : (
          <Box
            sx={{
              display: 'flex',
              margin: 'auto',
              justifyContent: 'center',
              maxWidth: '100%',
              height: 'auto',
            }}
          >
            {responsive ? (
              onlyText ? (
                onlyText
              ) : (
                <img src={imgSrc} style={{ maxWidth: '100%', height: 'auto' }} alt="preview" />
              )
            ) : onlyText ? (
              onlyText
            ) : (
              RenderedImageWithWidth
            )}
          </Box>
        )
      ) : dialogPreview ? (
        <Button
          color="inherit"
          onClick={() => {
            showBigPreview({
              productId,
              studyIUID,
              seriesIUID,
              imageIUID,
              width: DIALOG_PREVIEW_SIZE,
            });
          }}
          disabled={disabled}
        >
          {onlyText ? onlyText : RenderedImageIcon || ''}
        </Button>
      ) : (
        <>{onlyText ? onlyText : RenderedImageIcon || ''}</>
      )}
      {RenderedDialogWithPreview}
    </>
  );
};

import { Box } from '@mui/material';
import Autocomplete from 'components/Form/Autocomplete/Autocomplete';
import { useTranslation } from 'react-i18next';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { FormType, IRecursiveTagFields, IRegisterGroup, IUseTagFormProps } from './_types';
import { Line } from 'components/Line/Line';
import { useAddTagForm } from './hooks/useAddTagForm';
import { useDefaultTagForm } from './hooks/useDefaultTagForm';

export const RecursiveTagFields: React.FC<IRecursiveTagFields> = ({
  formType,
  data,
  isRootLevel = true,
  showArrow = false,
  appliedStyle = { width: { xs: '100%', md: '50%', lg: '20%' } },
  parentSelectedItem,
}) => {
  const { t } = useTranslation('SearchForm');

  const tagFormHooks = {
    [FormType.AddTagForm]: useAddTagForm,
    [FormType.Default]: useDefaultTagForm,
  };

  const tagFormHookProps = { parentSelectedItem, isRootLevel };

  const useTagFormHook = tagFormHooks[formType];

  const {
    customHandleOnChange,
    customFilterOption,
    onSelectShowChildren,
    createRegisterItemsOptions,
    selectedItem,
    expandableStructure,
  } = useTagFormHook(tagFormHookProps) as IUseTagFormProps;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap', mt: 0, mb: 0 }}>
      {data.map((tag: IRegisterGroup) => {
        const { isExpandableStructure } = expandableStructure;
        return (
          <Box key={tag.name} sx={{ mb: 0, mt: 0 }}>
            <Box display="flex" alignItems="center" sx={{ flexGrow: 1, whiteSpace: 'nowrap' }}>
              {showArrow && <SubdirectoryArrowRightIcon />}
              <Box sx={appliedStyle}>
                <Autocomplete
                  key={tag.name}
                  name={`registerItems.${tag.name}`}
                  label={tag.name || ''}
                  noOptionsText={t('noOptions')}
                  options={createRegisterItemsOptions(tag)}
                  placeholder={`${tag.name}`}
                  parentSelectedItem={parentSelectedItem}
                  // When select/deselect option show/hide children
                  {...(onSelectShowChildren
                    ? { onSelectShowChildren: onSelectShowChildren(tag) }
                    : {})}
                  //Custom on change handler or default
                  {...(customHandleOnChange
                    ? { customOnChangeHandler: (_, data) => customHandleOnChange(data, tag) }
                    : {})}
                  // Custom filter option or default
                  {...(customFilterOption ? { customFilterOptions: customFilterOption } : {})}
                  customFilterOptions={customFilterOption}
                />
              </Box>
            </Box>

            {/* Show children tags */}
            {(isExpandableStructure ? expandableStructure.showNested[tag.name] : true) &&
              tag.children && (
                <Box sx={{ pl: 3, whiteSpace: 'nowrap', minWidth: 250, mb: 0, mt: 0 }}>
                  <RecursiveTagFields
                    formType={formType}
                    data={tag.children}
                    showArrow={true}
                    appliedStyle={appliedStyle}
                    parentSelectedItem={selectedItem}
                    isRootLevel={false}
                    isExpandableStructure={isExpandableStructure}
                  />
                </Box>
              )}
            {/* Separates root level group tags with line */}
            {isExpandableStructure && isRootLevel && <Line topMargin={0.5} bottomMargin={0} />}
          </Box>
        );
      })}
    </Box>
  );
};

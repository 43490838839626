import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';
import { find, get } from 'lodash';

const useValidationSchema = (t: TFunction, languages: any[] = [], maxLength: number = 15) => {
  const { required, hexaColor, integer, positive, number, max } = useValidators();

  const language = find(languages, { default: true });

  const FormatFormSchema = yup.object().shape({
    name: required(yup.string()),
  });

  const TypeOfDocumentFormSchema = yup.object().shape({
    name: required(yup.string()),
    [`translateJson_${get(language, 'abbreviation')}`]: required(yup.string().trim()),
    iconName: required(yup.string()),
    iconColor: hexaColor(required(yup.string())),
  });

  const BookmarkFormSchema = yup.object().shape({
    [`name_${get(language, 'abbreviation')}`]: required(yup.string().trim()),
    position: integer(positive(number(yup.number(), 'positiveWithoutZero'))),
  });

  const FormItemFormSchema = yup.object().shape({
    name: required(yup.string()),
    [`nameTranslate_${get(language, 'abbreviation')}`]: required(yup.string().trim()),
    position: max(
      integer(positive(number(yup.number(), 'positiveWithoutZero'))),
      maxLength,
      'maxLengthNumber',
    ),
  });

  return {
    FormatFormSchema,
    TypeOfDocumentFormSchema,
    BookmarkFormSchema,
    FormItemFormSchema,
  };
};

export default useValidationSchema;

import React from 'react';
import clsx from 'clsx';
import { get, isArray, isEmpty, isNumber, isUndefined } from 'lodash';
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { Box, Theme } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { useTheme } from '@mui/styles';

import { IMuiGrid } from './_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { Papeer } from 'components/Papeer/Papeer';
import {
  DATA_GRID_DEFAULT_SORTING,
  DATA_GRID_ROW_HEIGHT,
  NOTIFICATIONS_STATE_NEW,
} from 'constants/constants';
import { useFilterableColumns } from 'utils/hooks/useFilterableColumns';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import useGridLocalization from 'components/SearchResultsMUI/useGridLocalization';

const dangerBackground = '#efc1c1';
const dangerBackgroundHover = '#d7adad';
const dangerBackgroundSelected = '#bf9a9a';
const dangerBackgroundSelectedHover = '#ab8a8a';
const darkDangerBackground = '#873336';
const darkDangerBackgroundHover = '#792d30';
const darkDangerBackgroundSelected = '#6c282b';
const darkDangerBackgroundSelectedHover = '#5e2325';
const greenRow = '#5FB3D3';

export const MuiGrid: React.FC<IMuiGrid> = ({
  gridKey,
  rows,
  columns,
  getRowId,
  rowDetail,
  autoHeight,
  rowHeight, // for multiple columns value
  defaultHiddenColumnNames,
  toolbar, // own toolbar
  rowSelecting,
  dataTour,
  disableColumnMenu,
  hideGridToolbarColumnsButton,
  withoutMaxHight,
  hideFooter,
  flex,
  hideHeaderFilters,
  sxPapeer,
  initialSortMode,
}) => {
  const { compactMode } = useAppInfo();
  const theme = useTheme() as Theme;
  const isDarkMode = theme.palette.mode === 'dark';

  const {
    columnVisibilityModel,
    setColumnVisibilityModel,
    getTogglableColumns,
    gridSortModel,
    changeWidthColumn,
    sortByColumn,
    updateColumnOrder,
  } = useMuiGrid(gridKey);

  const gridLocalization = useGridLocalization();

  const columnVisibilityModelWithDefaultHidden = {
    ...(isUndefined(columnVisibilityModel) ? defaultHiddenColumnNames : columnVisibilityModel),
  };

  const QuickSearchToolbar = (props: any) => {
    return (
      <Box sx={{ p: 0.5 }}>
        {!props.hideGridToolbarColumnsButton && (
          <GridToolbarColumnsButton data-tour="common-gridColumns" />
        )}
        <GridToolbarFilterButton data-tour="common-gridFilters" />
        {/* <GridToolbarDensitySelector /> */}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        flex: isNumber(flex) ? flex : 1,
        display: 'flex',
        ...(!withoutMaxHight ? { maxHeight: '100%', overflow: 'hidden' } : {}),
      }}
    >
      <Papeer
        id={gridKey}
        sx={{
          flex: 1,
          ...(!withoutMaxHight ? { maxHeight: '100%' } : {}),
          maxWidth: '100%',
          ...(compactMode ? { p: 0 } : {}),
          ...sxPapeer,
        }}
      >
        <Box
          data-tour={dataTour || gridKey}
          sx={{
            height: '100% !important',
            width: '100% !important',
            '& .css.boldRow': { fontWeight: 'bold' },
            '& .css.greenRow': {
              backgroundColor: greenRow,
              hover: { backgroundColor: greenRow },
            },
            '& .css.dangerRow': {
              backgroundColor: isDarkMode ? darkDangerBackground : dangerBackground,
              '&:hover': {
                backgroundColor: isDarkMode ? darkDangerBackgroundHover : dangerBackgroundHover,
              },
              '&.Mui-selected': {
                backgroundColor: isDarkMode
                  ? darkDangerBackgroundSelected
                  : dangerBackgroundSelected,
                '&:hover': {
                  backgroundColor: isDarkMode
                    ? darkDangerBackgroundSelectedHover
                    : dangerBackgroundSelectedHover,
                },
              },
            },
          }}
        >
          <DataGridPro
            sortingOrder={DATA_GRID_DEFAULT_SORTING}
            headerFilters={!hideHeaderFilters}
            disableColumnMenu={disableColumnMenu}
            getRowId={getRowId}
            autoHeight={isEmpty(rows)}
            // Commented out 4. 1. 2024 - to all grids look same - stretched vertically to 100% height
            //{...(rows?.length === 0 && autoHeight && { autoHeight: true })} // 30.1.2024 - Make grid autoHeight if there are no rows. To display no records/zadne zaznamy
            sx={{
              ...(rowHeight
                ? {
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: 0 },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: 0.5 },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '25.2px' },
                  }
                : {}),
            }}
            getRowClassName={(params) =>
              clsx('css', {
                boldRow: get(params, 'row.state') === NOTIFICATIONS_STATE_NEW,
                greenRow: get(params, 'row.testIsUnfinished', false),
                dangerRow:
                  (get(params, 'row.requestId.status', '') != null &&
                    get(params, 'row.requestId.status', '').toLowerCase() === 'failed') ||
                  (get(params, 'row.status', '') !== null &&
                    get(params, 'row.status', '').toLowerCase() === 'nok'),
              })
            }
            //getRowHeight={() => 'auto'}
            rowHeight={DATA_GRID_ROW_HEIGHT}
            density={compactMode ? 'compact' : 'standard'}
            rows={rows}
            columns={useFilterableColumns(gridKey, columns)}
            localeText={gridLocalization}
            disableRowSelectionOnClick={true}
            slots={{
              toolbar: toolbar
                ? toolbar
                : disableColumnMenu
                ? undefined
                : () =>
                    QuickSearchToolbar({
                      hideGridToolbarColumnsButton: hideGridToolbarColumnsButton || false,
                    }),
              detailPanelExpandIcon: ChevronRight,
              detailPanelCollapseIcon: ExpandMore,
            }}
            slotProps={{
              columnsPanel: {
                className: 'react-tour-column-menu',
              },
              columnsManagement: {
                getTogglableColumns,
              },
              filterPanel: {
                className: 'react-tour-filter-panel',
              },
              footer: {
                ...(compactMode
                  ? {
                      sx: {
                        minHeight: 30,
                      },
                    }
                  : {}),
              },
              headerFilterCell: {
                InputComponentProps: {
                  size: 'small',
                },
              },
              baseCheckbox: rowHeight
                ? {
                    sx: {
                      p: 0,
                    },
                  }
                : undefined,
            }}
            {...(!rowDetail?.showRowDetail
              ? {}
              : {
                  ...(rowDetail?.DetailPanel && {
                    getDetailPanelContent: rowDetail.DetailPanel,
                  }),
                  ...(rowDetail?.getDetailPanelHeight && {
                    getDetailPanelHeight: rowDetail.getDetailPanelHeight,
                  }),
                  ...(rowDetail?.detailPanelExpandedRowIds && {
                    detailPanelExpandedRowIds: rowDetail.detailPanelExpandedRowIds,
                  }),
                  ...(rowDetail?.handleDetailPanelExpandedRowIdsChange && {
                    onDetailPanelExpandedRowIdsChange:
                      rowDetail.handleDetailPanelExpandedRowIdsChange,
                  }),
                })}
            {...(!rowSelecting?.selecting
              ? {}
              : {
                  checkboxSelection: rowSelecting?.selecting,
                  ...(rowSelecting?.setSelection && {
                    onRowSelectionModelChange: (newSelectionModel: any) =>
                      rowSelecting?.setSelection(newSelectionModel),
                  }),
                  ...(rowSelecting?.selection && {
                    rowSelectionModel: rowSelecting?.selection,
                  }),
                })}
            // used for initial selection of rows) https://github.com/mui/mui-x/issues/5807
            keepNonExistentRowsSelected={rowSelecting?.loadInitialSelection || false}
            columnVisibilityModel={columnVisibilityModelWithDefaultHidden}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            onColumnWidthChange={(changedColumn: any) => changeWidthColumn(columns, changedColumn)}
            onColumnOrderChange={(params: any) =>
              updateColumnOrder(columns, params, rowSelecting?.selecting ? 1 : 0)
            }
            sortModel={
              isArray(gridSortModel) && !isEmpty(gridSortModel)
                ? gridSortModel
                : initialSortMode
                ? initialSortMode
                : []
            }
            onSortModelChange={sortByColumn}
            hideFooter={hideFooter}
          />
        </Box>
      </Papeer>
    </Box>
  );
};

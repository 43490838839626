import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';

import { getOrder } from './_api';

import { decryptId } from 'utils/hooks/useApp';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { useAllowedType } from 'utils/hooks/useAllowedType';
import OrderDetailContent from './OrderDetailContent';
import { useWithTitle } from 'utils/hooks/useWithTitle';

const OrderDetail: React.FC = () => {
  const { t } = useTranslation('Orders');
  useWithTitle(); // sets title to document
  const params = useParams();
  const { toggleLoader } = useAppGlobals();
  const { hasRole, user } = useUser();
  const { allowedType } = useAllowedType();
  const patientId = decryptId(get(params, 'patientId', null));
  const accessionNumber = decryptId(get(params, 'accessionNumber', null));
  const validEntity = !(patientId === 'null' || accessionNumber === 'null');

  let [searchParams] = useSearchParams();
  const detailIID = searchParams.get('backTo') || null;
  const linkBack = detailIID ? `/study/${detailIID}` : `/orders/?action=back`;

  const [order, setOrder] = useState<any>({});
  const [attachments, setAttachments] = useState<any[]>([]);

  const loadOrder = async () => {
    const order = await getOrder(
      decodeURIComponent(patientId),
      decodeURIComponent(accessionNumber),
    );
    const hic = get(order, 'healthInsuranceCompany.text', false);
    if (hic) {
      order.healthInsuranceCompany.text = JSON.parse(hic);
    }
    const exp = get(order, 'expertness.text', false);
    if (exp) {
      order.expertness.text = JSON.parse(exp);
    }
    const pri = get(order, 'priority.text', false);
    if (pri) {
      order.priority.text = JSON.parse(pri);
    }
    order.canUpdate = hasRole('ROLE_MWL_UPDATE');
    setOrder(order);

    let attachs = get(order, 'attachments', []);
    if (attachs) {
      attachs = attachs.map((item: any) => {
        return {
          ...item,
          canDelete:
            hasRole('ROLE_MWL_ATTACHMENT_ALL') ||
            (get(user, 'sub', 'aktualni uzivatel') ===
              get(item, 'user.username', 'autor prilohy') &&
              hasRole('ROLE_MWL_ATTACHMENT_MINE')),
          canShowInBrowser: get(allowedType(get(item, 'name')), 'allowed', false),
        };
      });
    }
    setAttachments(isArray(attachs) ? attachs : []);
  };
  const getEntity = async () => {
    toggleLoader();
    await loadOrder();
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {validEntity ? (
        <OrderDetailContent
          order={order}
          loadOrder={loadOrder}
          attachments={attachments}
          user={user}
          linkBack={linkBack}
          detailIID={detailIID}
          patientId={patientId}
          accessionNumber={accessionNumber}
          hasRole={hasRole}
        />
      ) : (
        t('orderNotFound')
      )}
    </>
  );
};

export default OrderDetail;

import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormAutocompleteMultiple from 'components/Form/Autocomplete/AutocompleteMultiple';
import CheckboxForm from 'components/Form/Checkbox/Checkbox';
import { getAllItems } from 'modules/Tags/_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { filter, get, isEmpty, sortBy } from 'lodash';
import { IRegisterItem } from 'components/TagForm/_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';

export const SearchFormTags = () => {
  const { t } = useTranslation('SearchForm');
  const { compactMode } = useAppInfo();
  const [activeTags, setActiveTags] = useState<IRegisterItem[]>([]);
  const [inactiveTags, setInactiveTags] = useState<IRegisterItem[]>([]);
  const { toggleLoader } = useAppGlobals();

  const { setValue, watch } = useFormContext();
  const withoutTags = watch('withoutTags');

  const getRegistryItems = async () => {
    toggleLoader();
    try {
      const allItems = await getAllItems();
      if (!isEmpty(allItems)) {
        const activeTags: IRegisterItem[] = sortBy(filter(allItems, { active: true }), [
          (item) =>
            `${get(item, 'registerGroupName', '')}_${get(item, 'label', '')} || ''`.toLowerCase(),
        ]);
        const inactiveTags: IRegisterItem[] = sortBy(filter(allItems, { active: false }), [
          (item) =>
            `${get(item, 'registerGroupName', '')}_${get(item, 'label', '')} || ''`.toLowerCase(),
        ]);
        setActiveTags(activeTags);
        setInactiveTags(inactiveTags);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };
  useEffect(() => {
    getRegistryItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (withoutTags) {
      setValue('tags.active', []);
      setValue('tags.inactive', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withoutTags]);

  return (
    <Box sx={{ pt: compactMode ? 1 : 2 }}>
      <Box sx={{ display: 'flex', flexWrap: { xs: 'wrap' } }}>
        <Box sx={{ width: { xs: '100%', md: '40%' }, pr: { lg: 1 } }}>
          <FormAutocompleteMultiple
            name="tags.active"
            label={t('tagsActive')}
            options={
              activeTags?.map((item) => ({
                value: item.value || '',
                label: item.label || '',
              })) || []
            }
            placeholder=""
            disabled={withoutTags}
          />
        </Box>
        <Box sx={{ width: { xs: '100%', md: '40%' }, pr: { lg: 1 } }}>
          <FormAutocompleteMultiple
            name="tags.inactive"
            label={t('tagsInactive')}
            options={
              inactiveTags?.map((item) => ({
                value: item.value || '',
                label: item.label || '',
              })) || []
            }
            placeholder=""
            disabled={withoutTags}
          />
        </Box>
        <Box sx={{ width: { xs: '100%', md: '20%' }, pr: { lg: 1 } }}>
          <CheckboxForm name="withoutTags" label={t('withoutTags')} />
        </Box>
      </Box>
    </Box>
  );
};

interface IGetDropDownStep {
  isDropDownOpen: any;
  t: any;
  selector?: 'select' | 'multiSelect' | 'autocomplete' | 'multiAutocomplete';
  content?: any;
  highlightedSelectors?: string[];
}
/**
 * Dynamically generates a step object for a dropdown UI element in a guided tour. This function is utilized when the dropdown is open, enabling customization of the tour's content, highlighted elements, and the dropdown selection mechanism (e.g., select, multiSelect, autocomplete, multiAutocomplete).
 *
 * @param {any} isDropDownOpen - Indicates whether the dropdown is currently open. This determines if the step should be included in the tour.
 * @param {Function} t - Localization function, typically provided by a library like `i18next`, used to translate the text content.
 * @param {('select' | 'multiSelect' | 'autocomplete' | 'multiAutocomplete')} [selector] - Specifies the type of dropdown being targeted. Based on this selector, a corresponding CSS class is chosen to target the dropdown element and its PaperComponent.
 * @param {any} [content] - The content to display for this step in the tour. If not provided, a default message encouraging item selection and how to exit selection mode is used.
 * @param {string[]} [highlightedSelectors] - An array of CSS selectors to be highlighted (by union) during this step of the tour. Can be used for dynamic highlighting. If not provided, no additional elements are highlighted.
 * @returns An object (step) representing a step in the tour for dropdowns.
 */
export const getDropDownStep = ({
  isDropDownOpen,
  selector,
  content,
  highlightedSelectors,
  t,
}: IGetDropDownStep) => {
  let selectorToUse: string;
  if (selector === 'select') selectorToUse = '.select-react-tour-class';
  else if (selector === 'multiSelect') selectorToUse = '.multiSelect-react-tour-class';
  else if (selector === 'autocomplete') selectorToUse = '.autocomplete-react-tour-class';
  else if (selector === 'multiAutocomplete')
    selectorToUse = '.autocomplete-multiple-react-tour-class';
  else selectorToUse = '.select-react-tour-class';

  const steps = {
    ...(isDropDownOpen && {
      [selectorToUse as string]: {
        content: t(content) || (
          <>
            {t('common.selectItems')}
            <br />
            <em>{t('common.quitSelectItems')}</em>
          </>
        ),
        highlightedSelectors: highlightedSelectors || [],
      },
    }),
  };
  return steps;
};

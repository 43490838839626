import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import {
  IFrontendSettings,
  IFrontendSettingsResponse,
  IOpenIdConfig,
  IPasswordPolicyConfig,
} from './_types';

export const getOpenIdProfileConfig = (): Promise<IOpenIdConfig> =>
  api()
    .get(`/portal-api/config/getOpenIdConfig`)
    .then((data) => get(data, 'data'))
    .catch((error) => Promise.reject());

export const getProfile = async () =>
  api()
    .get(`/portal-api/about/getProfile`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const parseToken = (token: any): Promise<any> =>
  api()
    .get(`/portal-api/accessApi/parseToken`, { params: { token } })
    .then((response) => get(response, 'data', false))
    .catch((thrown) => {
      return false;
    });

export const getFrontendConfigFromApi = (): Promise<IFrontendSettingsResponse> =>
  api()
    .get(`/portal-api/config/getFeConfig`)
    .then((response) => get(response, 'data', false))
    .catch((thrown) => {
      return false;
    });

export const updateFeConfig = (data: IFrontendSettings): Promise<IFrontendSettingsResponse> =>
  api()
    .post(`/portal-api/config/updateFeConfig`, { config: data })
    .then((response) => get(response, 'data', false))
    .catch((thrown) => {
      return false;
    });

export const getPasswordPolicyConfig = (): Promise<IPasswordPolicyConfig> =>
  api()
    .get(`/portal-api/config/getPasswordPolicyConfig`)
    .then((response) => get(response, 'data', false))
    .catch((thrown) => {
      return false;
    });

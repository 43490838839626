import { useTranslation } from 'react-i18next';
import { useReactTour } from 'components/Tour/useReactTour';
import HelperComponent from 'components/Tour/HelperComponent';

export const TourTransmissions = ({ activeTab }: any) => {
  const { t } = useTranslation('Reactour');

  const incoming = () => {
    const steps = {
      '[data-tour="mdex-transmission-gridIncoming"]': t('mdex.transmission.gridIncoming'),
    };
    return steps;
  };

  const outgoing = () => {
    const steps = {
      '[data-tour="mdex-transmission-gridOutgoing"]': t('mdex.transmission.gridOutgoing'),
    };
    return steps;
  };
  const stepsDefinition = activeTab === 'incoming' ? incoming : outgoing;
  useReactTour({ stepsDefinition });
  return <HelperComponent />;
};

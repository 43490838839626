import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IClinicalPortalSetting, IFormat, ITypeOfDocument, IBookmark } from './_types';

export const getClinicalPortalSetting = (): Promise<IClinicalPortalSetting> =>
  api()
    .get(`/portal-api/clinicalPortal/get`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const updateClinicalPortalSetting = (values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/clinicalPortal/update`, values)
    .then((response) => true)
    .catch((error) => false);

export const getAvailableViewers = (): any =>
  api()
    .get(`/portal-api/userSetting/getAvailableViewers`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getFormats = (): Promise<IFormat[]> =>
  api()
    .get(`/portal-api/format/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getFormat = (id: string): Promise<IFormat> =>
  api()
    .get(`/portal-api/format/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const createFormat = (values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/format/create`, values)
    .then((response) => true)
    .catch((error) => false);

export const updateFormat = (values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/format/update`, values)
    .then((response) => true)
    .catch((error) => false);

export const removeFormat = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/format/delete`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);

export const getTypesOfDocument = (): Promise<ITypeOfDocument[]> =>
  api()
    .get(`/portal-api/typeOfDocument/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getTypeOfDocument = (id: string): Promise<ITypeOfDocument> =>
  api()
    .get(`/portal-api/typeOfDocument/get`, { params: { id } })
    .then((response) => {
      let data = get(response, 'data');
      const translateJson = get(data, 'translateJson', null);
      if (translateJson) {
        data = { ...data, translateJson: JSON.parse(translateJson) };
      }
      return data;
    })
    .catch((error) => false);

export const createTypeOfDocument = (values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/typeOfDocument/create`, values)
    .then((response) => true)
    .catch((error) => false);

export const updateTypeOfDocument = (values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/typeOfDocument/update`, values)
    .then((response) => true)
    .catch((error) => false);

export const removeTypeOfDocument = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/typeOfDocument/delete`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);

export const getBookmarks = (): Promise<IBookmark[]> =>
  api()
    .get(`/portal-api/bookmark/getAll`)
    .then((response) => {
      let data = get(response, 'data');
      const name = get(data, 'name', null);
      if (name) {
        data = { ...data, name: JSON.parse(name) };
      }
      return data;
    })
    .catch((error) => false);

export const getBookmark = (id: string): Promise<IBookmark> =>
  api()
    .get(`/portal-api/bookmark/get`, { params: { id } })
    .then((response) => {
      let data = get(response, 'data');
      const name = get(data, 'name', null);
      if (name) {
        data = { ...data, name: JSON.parse(name) };
      }
      return data;
    })
    .catch((error) => false);

export const createBookmark = (values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/bookmark/create`, values)
    .then((response) => true)
    .catch((error) => false);

export const updateBookmark = (values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/bookmark/update`, values)
    .then((response) => true)
    .catch((error) => false);

export const removeBookmark = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/bookmark/delete`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);

export const getFormItems = (): Promise<IFormat[]> =>
  api()
    .get(`/portal-api/searchPatientForm/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getFormItem = (id: string): Promise<IFormat> =>
  api()
    .get(`/portal-api/searchPatientForm/get`, { params: { id } })
    .then((response) => {
      let data = get(response, 'data');
      const nameTranslate = get(data, 'nameTranslate', null);
      if (nameTranslate) {
        data = { ...data, nameTranslate: JSON.parse(nameTranslate) };
      }
      return data;
    })
    .catch((error) => false);

export const updateFormItem = (values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/searchPatientForm/update`, values)
    .then((response) => true)
    .catch((error) => false);

export const setActiveFormItem = (id: string, isActive: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/searchPatientForm/setActive`, { params: { id, isActive } })
    .then((response) => true)
    .catch((error) => false);

export const setBasicFormItem = (id: string, isBasic: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/searchPatientForm/setAsBasicCriteria`, { params: { id, isBasic } })
    .then((response) => true)
    .catch((error) => false);

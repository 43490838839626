import { find, get, isEmpty, omit, sortBy, split } from 'lodash';
import { format } from 'date-fns';

export const formatAnonymizedValues = (values: any, profile: any) => {
  let formatedValues = {};
  let deidentifyProfile = {};
  const fieldNames = [
    'anonymized_lastName',
    'anonymized_firstName',
    'anonymized_middleName',
    'anonymized_prefix',
    'anonymized_suffix',
  ];
  const ommitedFields = ['anonymization', 'anonymized_PatientID', ...fieldNames];
  formatedValues = { ...values };
  if (!isEmpty(profile)) {
    const profileValues = get(profile, 'values', []);
    deidentifyProfile = { name: get(profile, 'name') };
    const deidentifyValues: any[] = [];
    if (profileValues != null) {
      profileValues.forEach((profileValue: any) => {
        const type = get(profileValue, 'type');
        switch (type) {
          case 'name':
            const name = `${get(values, fieldNames[0], '')}^${get(values, fieldNames[1], '')}^${get(
              values,
              fieldNames[2],
              '',
            )}^${get(values, fieldNames[3], '')}^${get(values, fieldNames[4], '')}`;
            deidentifyValues.push({ name: get(profileValue, 'name'), value: name });
            break;
          /* case 'date':
            const dateName = `anonymized_${get(profileValue, 'name')}`;
            ommitedFields.push(dateName);
            const dateValue = get(values, dateName);
            deidentifyValues.push({
              name: get(profileValue, 'name'),
              value: format(dateValue, 'yyyyMMddHHmmss'), // 'YYYYMMDDHHMMSS'
            });
            break; */

          default:
            const actualName = `anonymized_${get(profileValue, 'name')}`;
            ommitedFields.push(actualName);
            const actualValue = get(values, actualName);
            deidentifyValues.push({
              name: get(profileValue, 'name'),
              value: type === 'date' ? format(actualValue, 'yyyyMMddHHmmss') : actualValue,
            });
            break;
        }
      });
    }
    deidentifyProfile = { ...deidentifyProfile, values: sortBy(deidentifyValues, ['name']) };
    formatedValues = { ...formatedValues, deidentifyProfile };
  } else {
    formatedValues = omit(formatedValues, ['deidentifyProfile']);
  }
  formatedValues = omit(formatedValues, ommitedFields);
  return formatedValues;
};

export const identifyValues = (values: any, profile: any) => {
  let anonymized: any = null;
  if (!isEmpty(profile)) {
    const profileValues = get(profile, 'values', []);
    if (!isEmpty(values)) {
      if (profileValues != null) {
        profileValues.forEach((profileValue: any) => {
          const type = get(profileValue, 'type');
          const name = get(profileValue, 'name');
          let value = null;
          let actualValue = null;
          switch (type) {
            case 'name':
              value = find(values, ['name', get(profileValue, 'name')]);
              actualValue = get(value, 'value', null);
              const [
                anonymized_lastName,
                anonymized_firstName,
                anonymized_middleName,
                anonymized_prefix,
                anonymized_suffix,
              ] = split(actualValue, '^');
              anonymized = {
                ...anonymized,
                anonymized_lastName,
                anonymized_firstName,
                anonymized_middleName,
                anonymized_prefix,
                anonymized_suffix,
              };
              break;
            /*  
            // TODO
            case 'date':
              const dateValue = get(anonymizedValues, get(profileValue, 'name'));
              deidentifyValues.push({
                name: get(profileValue, 'name'),
                value: format(dateValue, 'YYYYMMDDHHMMSS'),
              });
              break; 
            */
            default:
              value = find(values, ['name', name]);
              const actualName = `anonymized_${name}`;
              actualValue = get(value, 'value', null);
              anonymized = { ...anonymized, [actualName]: actualValue };
              break;
          }
        });
      }
    }
  }
  return anonymized;
};

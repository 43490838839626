import { useTranslation } from 'react-i18next';
import { getUrlForOpenViewer } from 'modules/Studies/StudyDetail/_api';
import useAlerts from 'components/Alerts/useAlerts';

export const useViewer = () => {
  const { t } = useTranslation('Errors');
  const { addErrorAlert } = useAlerts();
  const onViewer = (
    studyID: string,
    patientId: string,
    archiveID: string,
    seriesUID: any = null,
    sopUid: any = null,
  ) => {
    getUrlForOpenViewer(studyID, patientId, archiveID, seriesUID, sopUid).then(
      (response) =>
        response ? window.open(response, '_blank') : addErrorAlert(t('cannotOpenLink')),
      (error) => addErrorAlert(t('cannotOpenLink')),
    );
  };

  return {
    onViewer,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { filter, get, isArray, pick, reverse, sortBy } from 'lodash';
import { format } from 'date-fns';

import { setActiveComment } from './_api';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useStudy } from 'utils/hooks/useStudy';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useActions } from 'utils/hooks/useActions';
import { Line } from 'components/Line/Line';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import OrderDetailCommentForm from './OrderDetailCommentForm';
import { Box } from '@mui/system';

const formatDateTime = 'dd. MM. yyyy, HH:mm:ss';

const OrderDetailComments: React.FC<any> = ({
  commentItem,
  setCommentItem,
  comments,
  dialogState,
  handleDialog,
  getComments,
  user,
  order,
  canEditDelete,
  hasRole,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { storeConfirmationData } = useActions();
  const { toggleLoader } = useAppGlobals();
  const { confirmationData } = useAppInfo();
  const { getCommentNameWithLogin } = useStudy();

  const renderedComments = isArray(comments)
    ? reverse(sortBy(comments, ['id'])).map((comment, index) => {
        const createdByAuthor = getCommentNameWithLogin(get(comment, 'createdBy'));
        const modifiedByAuthor = getCommentNameWithLogin(get(comment, 'modifiedBy'));
        const commentCreatedByLoggedUser = get(comment, 'createdBy.username') === get(user, 'sub');
        return (
          <div key={comment.id}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {get(comment, 'modifiedBy') && (
                <Tooltip
                  title={
                    <>
                      <div>{t('modified')}:</div>
                      <div>
                        {modifiedByAuthor} -{' '}
                        {format(new Date(comment.modifiedWhen), formatDateTime)}
                      </div>
                    </>
                  }
                >
                  <HistoryIcon sx={{ m: 1, color: 'inherit' }} />
                </Tooltip>
              )}
              <Tooltip title={t('commentCreateTitle') as string}>
                <div>
                  {get(comment, 'createdBy.email') ? (
                    <Link
                      href={`mailto: ${comment.createdBy.email}`}
                      underline="none"
                      sx={{ color: (theme) => theme.palette.success.main }}
                    >
                      {createdByAuthor}
                    </Link>
                  ) : (
                    createdByAuthor
                  )}{' '}
                  - {format(new Date(comment.createdWhen), formatDateTime)}
                </div>
              </Tooltip>

              {canEditDelete && (
                <>
                  {filter(
                    [
                      {
                        title: 'commentUpdate',
                        children: <EditIcon />,
                        onClick: () => {
                          handleDialog('comments');
                          setCommentItem(comment);
                        },
                        show:
                          hasRole('ROLE_MWL_COMMENTARY_ALL') ||
                          (commentCreatedByLoggedUser && hasRole('ROLE_MWL_COMMENTARY_MINE')),
                      },
                      {
                        title: 'commentDelete',
                        children: <DeleteIcon />,
                        onClick: () => {
                          storeConfirmationData({
                            id: comment.id,
                          });
                        },
                        show:
                          hasRole('ROLE_MWL_COMMENTARY_ALL') ||
                          (commentCreatedByLoggedUser && hasRole('ROLE_MWL_COMMENTARY_MINE')),
                      },
                    ],
                    'show',
                  ).map((button) => (
                    <Tooltip key={button.title} title={t(button.title) as string}>
                      <IconButton
                        {...pick(button, ['children', 'onClick'])}
                        sx={{ p: (theme) => theme.spacing(1 / 4), ml: 1 }}
                        size="large"
                      />
                    </Tooltip>
                  ))}
                </>
              )}
            </Box>
            <Box sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', mt: 1, lineHeight: 1 }}>
              {comment.text}
            </Box>
            {++index < comments.length ? <Line topMargin={2} bottomMargin={2} /> : null}
          </div>
        );
      })
    : null;

  const onChangeActive = async (id: string) => {
    toggleLoader();
    const changed = await setActiveComment(id, false);
    if (changed) {
      await getComments();
    }
    toggleLoader(false);
  };
  const clearAction = () => {
    storeConfirmationData(null);
  };
  const cancelAction = () => {
    clearAction();
  };
  const confirmAction = async () => {
    onChangeActive(get(confirmationData, 'id'));
    clearAction();
  };
  return (
    <>
      <Typography component="div">
        <Paper elevation={0}>
          {renderedComments}
          <Dialog
            open={dialogState}
            onClose={() => handleDialog('comments', false)}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            fullWidth={true}
          >
            <DialogTitle id="form-dialog-title">{t('dialog_comment')}</DialogTitle>
            <DialogContent>
              <>
                <OrderDetailCommentForm
                  order={order}
                  dialogState={dialogState}
                  handleDialog={handleDialog}
                  commentItem={commentItem}
                  getComments={getComments}
                  user={user}
                />
              </>
            </DialogContent>
          </Dialog>
        </Paper>
      </Typography>
      {confirmationData && confirmationData.id && (
        <ConfirmationDialog
          title={t('confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={cancelAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

export default OrderDetailComments;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compact, get, isArray, isEmpty } from 'lodash';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { getHospitals, removeHospital } from './_apiHospital';
import { getAll } from '../Facilities/_api';
import HospitalForm from './HospitalForm';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import Header from 'components/Header/Header';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useUser } from 'utils/hooks/useUser';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { IHospital } from './_types';

const emptyHospitalRow = null;

const Hospitals: React.FC = () => {
  const { t } = useTranslation('Hospitals');
  useWithTitle();
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();
  const { hasRole } = useUser();
  const [dialogState, setDialogState] = useState<boolean>(false);
  const [hospitalRow, setHospitalRow] = useState<IHospital | null>(emptyHospitalRow);
  const [allHospitals, setAllHospitals] = useState<IHospital[]>([]);
  const [identifiers, setIdentifiers] = useState<string[]>([]);
  const [names, setNames] = useState<string[]>([]);
  const [facilitiesSelect, setFacilitiesSelect] = useState<string[]>([]);

  const loadEntity = async () => {
    toggleLoader();
    try {
      setHospitalRow(emptyHospitalRow);
      const allHospitals = await getHospitals();
      setAllHospitals(allHospitals);

      const identifiers: string[] = [];
      const names: string[] = [];
      allHospitals.forEach((hospital) => {
        identifiers.push(get(hospital, 'identifier'));
        names.push(get(hospital, 'name'));
      });
      setIdentifiers(identifiers);
      setNames(names);
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };
  const loadFacilities = async () => {
    toggleLoader();
    try {
      const facilities = await getAll();
      if (facilities && isArray(facilities)) {
        const facilitiesSelect = facilities.map((item) => item.name);
        setFacilitiesSelect(facilitiesSelect);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const { onEntityRemove } = useEntityRemove(removeHospital, t, loadEntity);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const isEditor = hasRole('ROLE_FINDING_STATISTICS');

  const toggleDialog = (state: boolean = false) => {
    setDialogState(state);
  };

  const onEntityEdit = async (row: IHospital) => {
    setHospitalRow(row);
    toggleDialog(!isEmpty(row) ? true : false);
  };

  const addItemInDialog = () => {
    setHospitalRow(emptyHospitalRow);
    toggleDialog(true);
  };

  useEffect(() => {
    loadEntity();
    loadFacilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const id = !isEmpty(hospitalRow) ? hospitalRow.id : dialogState ? 'create' : null;

  const renderedDialog = (
    <OverflowedDialog
      title={t('dialog.hospital')}
      open={dialogState}
      onClose={() => {
        toggleDialog(false);
        setHospitalRow(emptyHospitalRow);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <HospitalForm
        toggleDialog={toggleDialog}
        keyValues={{ identifiers, names }}
        id={id}
        row={hospitalRow}
        loadEntity={loadEntity}
        facilities={facilitiesSelect}
      />
    </OverflowedDialog>
  );

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings, MuiGrid } =
    useMuiGrid('hospitalsMUI2');

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      compact([
        isEditor
          ? {
              field: 'actions',
              headerName: t('Grid:actions'),
              type: 'actions',
              hideable: false,
              width: 100,
              renderCell: ({ row }: GridRenderCellParams) => {
                return (
                  <>
                    <GridActionsCellItem
                      icon={
                        <Tooltip title={t('Grid:edit')}>
                          <EditIcon />
                        </Tooltip>
                      }
                      label={t('Grid:edit')}
                      onClick={() => onEntityEdit(row)}
                    />
                    <GridActionsCellItem
                      icon={
                        <Tooltip title={t('Grid:delete')}>
                          <DeleteIcon />
                        </Tooltip>
                      }
                      label={t('Grid:delete')}
                      onClick={() => {
                        dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                      }}
                    />
                  </>
                );
              },
            }
          : null,
        { field: 'identifier', headerName: t('identifier') },
        { field: 'alternativeIdentifier', headerName: t('alternativeIdentifier') },
        { field: 'name', headerName: t('name') },
      ]),
    ),
  );

  const RenderedGrid = () =>
    MuiGrid({
      rows: allHospitals,
      columns,
      initialSortMode: [{ field: 'identifier', sort: 'asc' }],
    });

  return (
    <>
      <Header
        title=""
        {...(isEditor ? { addItemInDialog } : {})}
        toggleDialogText={t('add')}
        topMargin={true}
      />
      <RenderedGrid />
      {renderedDialog}
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

export default Hospitals;

import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';
import HelperComponent from 'components/Tour/HelperComponent';

interface ExportStepsProps {
  type: 'detail' | 'list';
}

export const TourExport = ({ type }: ExportStepsProps) => {
  const { t } = useTranslation('Reactour');

  const exportListStepsDefinition = () => {
    const steps = {
      '[data-tour="export-list-appBar"]': t('exportList.appBar'),
      '[data-tour="export-list-grid"]': t('exportList.grid'),
    };
    return steps;
  };

  const exportDetailStepsDefinition = () => {
    const steps = {
      '[data-tour="backButton"]': t('study.back'),
      '[data-tour="studyExportType"]': t('study.export.type'),
      '[data-tour="studyAnonymization"]': t('study.export.anonymization'),
      '[data-tour="studyExportListOfStudy"]': t('study.export.listOfStudy'),
      '[data-tour="studyExportExportButton"]': t('study.export.exportButton'),
    };
    return steps;
  };

  const stepsDefinition = type === 'list' ? exportListStepsDefinition : exportDetailStepsDefinition;

  useReactTour({ stepsDefinition: stepsDefinition });

  return (
    <>
      <HelperComponent />
    </>
  );
};

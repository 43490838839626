import React from 'react';
import { Button } from '@mui/material';

import { ICheckedButton } from './_types';
import DoneIcon from '@mui/icons-material/Done';
import { useAppInfo } from 'utils/hooks/useAppInfo';

export const CheckedButton: React.FC<ICheckedButton> = ({
  id,
  active,
  children,
  leftMargin,
  rightMargin,
  onClick,
  onDoubleClick,
  sx = {},
  topMargin = true,
}) => {
  let timer = 0;
  const delay = 250;
  let prevent = false;

  const { compactMode } = useAppInfo();

  const onSingleClickHandler = () => {
    setTimeout(() => {
      if (!prevent) {
        onClick();
      }
    }, delay);
  };
  const onDoubleClickHandler = () => {
    clearTimeout(timer);
    prevent = true;
    onClick();
    setTimeout(() => {
      prevent = false;
      onDoubleClick();
    }, delay);
  };

  return (
    <Button
      size={compactMode ? 'small' : 'medium'}
      variant="contained"
      color={active ? 'primary' : 'inherit'}
      sx={{
        ml: leftMargin ? 1 : 0,
        mr: rightMargin ? 1 : 0,
        mt: topMargin ? 1 : 0,
        ...sx,
      }}
      onClick={onSingleClickHandler}
      onDoubleClick={onDoubleClickHandler}
      data-selenium-selector="searchform-date-button"
      data-selenium-selector-type={id}
    >
      {children}
      {active ? <DoneIcon sx={{ ml: 1 }} /> : null}
    </Button>
  );
};

import { get } from 'lodash';
import { TFunction } from 'i18next';

export const useValidation = (t: TFunction, validators: any) => {
  const isEnabled = (name: string, isEnabled: boolean = false) => {
    return get(validators, `${name}`, isEnabled);
  };
  const allRequired = (name: string, isRequired: boolean = false) => {
    return get(validators, `${name}.required`, isRequired);
  };
  const patientRequired = (name: string, isRequired: boolean = false) => {
    return allRequired(`patient.name.${name}`, isRequired);
  };

  const allMin = (name: string, n: number = 1) => {
    return get(validators, `${name}.min`, n);
  };
  const patientMin = (name: string, n: number = 1) => {
    return allMin(`patient.name.${name}`, n);
  };

  const allAllowedChars = (name: string, chars: string = '.') => {
    return get(validators, `${name}.allowedChars`, chars);
  };
  const patientAllowedChars = (name: string, chars: string = '[A-Za-z ]') => {
    return allAllowedChars(`patient.name.${name}`, chars);
  };

  const allMessage = (name: string, message: string = 'minLengthAndAllowedChars') => {
    return t(`Errors:${message}`, {
      n: allMin(name),
      par1: allAllowedChars(name),
    });
  };
  const patientMessage = (name: string, message: string = 'minLengthAndAllowedChars') => {
    return t(message, {
      n: patientMin(name),
      par1: patientAllowedChars(name),
    });
  };
  const allMatches = (name: string, withRepeat: boolean = false) => {
    const allMat = new RegExp(`^${allAllowedChars(name)}${withRepeat ? '+' : ''}$`, 'i');
    return allMat;
  };
  const patientMatches = (name: string) => {
    const patMat = new RegExp(`^${patientAllowedChars(name)}+$`, 'i');
    return patMat;
  };

  return {
    isEnabled,
    allRequired,
    patientRequired,
    allMin,
    patientMin,
    allAllowedChars,
    patientAllowedChars,
    allMessage,
    patientMessage,
    allMatches,
    patientMatches,
  };
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { format } from 'date-fns';
import { Box, Button, Grid, Typography } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import { compact, get, isArray, sortBy } from 'lodash';

const fieldNames = [
  'studyDate',
  'patientName',
  'patientID',
  'confirmationDate',
  'userProposal',
  'userConfirmation',
];
const sxTd = { border: '1px solid #ddd', p: 1, textAlign: 'left', verticalAlign: 'top' };

const HistoryDetail: React.FC = () => {
  const { t } = useTranslation('Studies');
  const { shreddingPrintStore } = useStudyInfo();
  const navigate = useNavigate();

  const [studiesCount, setStudiesCount] = useState<number>(0);
  const [renderedTable, setRenderedTable] = useState<JSX.Element>();

  const onPrint = () => window.print();

  const getRenderedTable = () => {
    const renderedTable = (
      <Box
        component="table"
        sx={{
          'border-collapse': 'collapse',
          width: '100%',
          border: '1px solid #ddd',
          padding: 1,
          textAlign: 'left',
          verticalAlign: 'top',
        }}
      >
        <Box component="thead">
          <Box component="tr">
            {fieldNames.map((fieldName) => (
              <Box component="td" sx={sxTd}>
                {t(fieldName)}
              </Box>
            ))}
          </Box>
        </Box>
        <Box component="tbody">
          {sortBy(shreddingPrintStore, ['dateTime']).map((study, index) => {
            const studyDateTime = get(study, 'dateTime');
            const studyDate = studyDateTime ? format(new Date(studyDateTime), 'dd.MM.yyyy') : '';

            const confirmationDateTime = get(study, 'proposal.confirmationDate');
            const confirmationDate = confirmationDateTime
              ? format(new Date(confirmationDateTime), 'dd.MM.yyyy')
              : '';

            const patientName = compact([
              get(study, 'patient.name.lastName', ''),
              get(study, 'patient.name.firstName', ''),
              get(study, 'patient.name.middleName', ''),
              get(study, 'patient.name.prefix'),
              get(study, 'patient.name.suffix'),
            ])
              .join(' ')
              .trim();
            const patientID = get(study, 'patient.identificationNumber', '');
            const userProposal =
              get(study, 'proposal.sourceProposal') === 'User'
                ? compact([
                    get(study, 'proposal.user.lastName', ''),
                    get(study, 'proposal.user.firstName', ''),
                  ])
                    .join(' ')
                    .trim()
                : get(study, 'proposal.sourceProposal', '');
            const userConfirmation = get(study, 'proposal.userConfirmation', '');

            const fieldValues = [
              studyDate,
              patientName,
              patientID,
              confirmationDate,
              userProposal,
              userConfirmation,
            ];
            return (
              <Box component="tr" key={index}>
                {fieldValues.map((fieldValue) => (
                  <Box component="td" sx={sxTd}>
                    {fieldValue}
                  </Box>
                ))}
              </Box>
            );
          })}
        </Box>
      </Box>
    );
    return renderedTable;
  };
  useEffect(() => {
    const studiesCount =
      shreddingPrintStore && isArray(shreddingPrintStore) ? shreddingPrintStore.length : 0;

    if (studiesCount < 1) {
      navigate('/shreddings/histories');
    } else {
      setStudiesCount(studiesCount);
      setRenderedTable(getRenderedTable());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (renderedTable) {
      onPrint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderedTable]);

  return (
    <>
      <Header title={t('shreddingPrintTitle')} backUrl={`/shreddings/histories?action=back`} />

      <Typography
        sx={{ display: 'none', '@media print': { display: 'block' } }}
        align="center"
        variant="h4"
      >
        <Papeer>{t('shreddingPrintTitle')}</Papeer>
      </Typography>

      <Typography component="div">
        <Papeer>{renderedTable}</Papeer>
      </Typography>

      <Typography component="div">
        <Papeer>
          {t(studiesCount === 1 ? 'shreddingPrintContent1' : 'shreddingPrintContent', {
            par1: studiesCount,
          })}
        </Papeer>
      </Typography>

      <Grid container={true} justifyContent="flex-end">
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '@media print': {
              display: 'none',
            },
          }}
        >
          <Button variant="contained" color="primary" size={'medium'} onClick={onPrint}>
            <PrintIcon />
            {t('action.print')}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default HistoryDetail;

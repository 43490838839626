// import { createStore, compose } from "redux";
// // import thunk from 'redux-thunk';

// import { rootReducer } from "./reducers/index";

// export default function configureStore() {
//   // const middlewares = [thunk];
//   let composeEnhancers = compose;

//   if (
//     process.env.NODE_ENV !== "production" &&
//     (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//   ) {
//     composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
//   }

//   const finalCreateStore = composeEnhancers(/*applyMiddleware(...middlewares)*/)(
//     createStore
//   );

//   const store = finalCreateStore(rootReducer);

//   return store;
// }

import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';

import appReducer from './reducers/appReducer';
import userReducer from './reducers/userReducer';
import studyReducer from './reducers/studyReducer';

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  study: studyReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

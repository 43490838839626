import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compact, each, find, get, includes, isArray, isEmpty, keys, pick } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { parseISO } from 'date-fns';
import { Box, Grid } from '@mui/material';
import { searchRequests } from './_api';
import { IRequestSearch, IRequestSearchForm } from './_types';
import { useActions } from 'utils/hooks/useActions';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormDatePicker from 'components/Form/Date/Date';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import {
  DATE_BTN_CUSTOM_DATE,
  DATE_BTN_LAST_MONTH,
  DATE_BTN_LAST_THREE_DAYS,
  DATE_BTN_LAST_WEEK,
  DATE_BTN_TODAY,
  DATE_BTN_YESTERDAY,
  SEARCH_FUNCTION_COPY,
  SEARCH_FUNCTION_EDIT_PATIENT,
  SEARCH_FUNCTION_EDIT_STUDY,
  SEARCH_FUNCTION_MOVE,
  SEARCH_FUNCTION_MOVE_TO_FOLDER,
  SEARCH_FUNCTION_MOVE_TO_TRASH,
  SEARCH_FUNCTION_REORDER,
  SEARCH_FUNCTION_SEND,
  SEARCH_FUNCTION_SPLIT,
  STATE_NEW,
} from 'constants/constants';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';
import { convertSearchDates } from 'utils/search';
import { useStudy } from 'utils/hooks/useStudy';
import { useSearchParams } from 'react-router-dom';
import { useUser } from 'utils/hooks/useUser';
import { ISelectItem } from 'components/Form/Select/_types';
import { Papeer } from 'components/Papeer/Papeer';
import Button from 'components/Buttons/Button';
import { useStorage } from 'utils/hooks/useStorage';

const apiDateFormatWithDash = 'yyyy-MM-dd';
const apiDateFormat = 'yyyyMMdd';
const FORM_DATA_KEY = 'requestsSearchForm';

const RequestsSearchForm: React.FC<IRequestSearch> = ({ operation }) => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('Request');
  const { toggleLoader } = useAppGlobals();
  const { storeRequestSearchResults } = useActions();
  const { requestStates, compactMode } = useAppInfo();
  const { linkBack } = useStudy();
  const { hasRole } = useUser();
  const { getItem, putItem, removeItem } = useStorage();

  const [states, setStates] = useState<any[]>();
  const [statesFetched, setStatesFetched] = useState<boolean>(false);
  const [requestTypeItems, setRequestTypeItems] = useState<ISelectItem[]>([]);
  const [allowedOperationType, setAllowedOperationType] = useState<string[]>([]);
  const [hasCustomDate, setHasCustomDate] = useState(false);

  const backFromDetail = linkBack();

  const dateSelectItems = [
    DATE_BTN_TODAY,
    DATE_BTN_YESTERDAY,
    DATE_BTN_LAST_THREE_DAYS,
    DATE_BTN_LAST_WEEK,
    DATE_BTN_LAST_MONTH,
    DATE_BTN_CUSTOM_DATE,
  ].map((id) => ({ id, label: t(`SearchForm:${id}`) }));

  const methods = useForm<IRequestSearchForm>();
  const { handleSubmit, reset, setValue, watch } = methods;
  const formValues = watch();

  const selectedDate = watch('date');
  const selectedStates = watch('stateOfRequest');
  const canSubmit = !isEmpty(selectedStates);

  useEffect(() => {
    if (selectedDate === DATE_BTN_CUSTOM_DATE) {
      setHasCustomDate(true);
    } else {
      setHasCustomDate(false);

      // Set dates if selected date is not custom
      if (selectedDate) {
        const dateAttributes = convertSearchDates(
          selectedDate,
          get(formValues, 'dateFrom'),
          get(formValues, 'dateTo'),
          apiDateFormat,
        );
        setValue('dateFrom', new Date(parseISO(dateAttributes.dateFrom)));
        setValue('dateTo', new Date(parseISO(dateAttributes.dateTo)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const onSubmit = handleSubmit(async (formValues) => {
    toggleLoader();
    const formDate = get(formValues, 'date', '');
    putItem(FORM_DATA_KEY, JSON.stringify(formValues));
    let values: any = {
      operation: get(formValues, 'operation', operation) || operation,
      ...pick(formValues, ['username']),
    };
    // Some request state is selected
    if (isArray(get(formValues, 'stateOfRequest')) && statesFetched) {
      values.stateOfRequest = compact(get(formValues, 'stateOfRequest')).map(
        (state: any) => state.id,
      );
      // When states are not fetched yet, use the "NEW" one
    } else if (!statesFetched) {
      values.stateOfRequest = [STATE_NEW];
    }

    if (formDate) {
      const dateAttributes = convertSearchDates(
        formDate,
        get(formValues, 'dateFrom', null),
        get(formValues, 'dateTo', null),
        apiDateFormatWithDash,
      );
      values = { ...values, ...dateAttributes };
    }
    const results = await searchRequests(values);
    const allowedRequests: any[] = [];
    if (results && results.length) {
      results.forEach((result: any) => {
        if (includes(allowedOperationType, get(result, 'operationType', ''))) {
          allowedRequests.push(result);
        }
      });
    }
    storeRequestSearchResults(
      isArray(allowedRequests) && allowedRequests.length ? allowedRequests : [],
    );

    toggleLoader(false);
  });

  const resetForm = () => {
    reset({
      username: '',
      stateOfRequest: [],
      date: null,
    });
  };

  const triggerSubmit = () => {
    refSubmitButtom?.current?.click();
  };

  if (searchParams.has('refresh')) {
    const refreshParams = searchParams.get('refresh');
    if (refreshParams) {
      searchParams.delete('refresh');
      setSearchParams(searchParams);
      triggerSubmit();
    }
  }

  const getEntity = async () => {
    toggleLoader();

    try {
      const states = requestStates.map((state) => ({ id: state, name: t(state.toLowerCase()) }));
      const statesFetched = isArray(states) && compact(states).length ? true : false;
      setStates(states);
      setStatesFetched(statesFetched);
      if (!backFromDetail) {
        removeItem(FORM_DATA_KEY);
        const stateOfRequest = [find(states, { id: STATE_NEW })];

        let init: IRequestSearchForm = {
          username: '',
          stateOfRequest,
          date: DATE_BTN_TODAY,
          dateFrom: null,
          dateTo: null,
        };
        reset({ ...init });
        storeRequestSearchResults([]);
        triggerSubmit();
      } else if (backFromDetail) {
        const data = getItem(FORM_DATA_KEY);
        if (data) {
          // Parse it to a javaScript object
          const values = JSON.parse(data);
          const transformedValues = {
            ...values,
            dateFrom: values.dateFrom ? new Date(values.dateFrom) : null,
            dateTo: values.dateTo ? new Date(values.dateTo) : null,
          };
          each(keys(transformedValues), (key: any) => {
            setValue(key, get(transformedValues, key));
          });
        }
      }
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    if (!isEmpty(allowedOperationType)) {
      getEntity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedOperationType]);

  const customT = (action: string) => t(`StudyFunctions:${action.toLowerCase()}`);

  useEffect(() => {
    const requestTypeItems = [{ id: '', label: t('Studies:select') }];
    const allowedOperationType = [];

    if (
      hasRole('ROLE_REQUEST_SEND_DR_SEJF') ||
      hasRole('ROLE_REQUEST_SEND_EPACS') ||
      hasRole('ROLE_REQUEST_SEND_REDIMED')
    ) {
      requestTypeItems.push({
        id: SEARCH_FUNCTION_SEND,
        label: customT(SEARCH_FUNCTION_SEND),
      });
      allowedOperationType.push(SEARCH_FUNCTION_SEND);
    }
    if (hasRole('ROLE_REQUEST_REORDER')) {
      requestTypeItems.push({
        id: SEARCH_FUNCTION_REORDER,
        label: customT(SEARCH_FUNCTION_REORDER),
      });
      allowedOperationType.push(SEARCH_FUNCTION_REORDER);
    }
    if (hasRole('ROLE_REQUEST_SPLIT')) {
      requestTypeItems.push({
        id: SEARCH_FUNCTION_SPLIT,
        label: customT(SEARCH_FUNCTION_SPLIT),
      });
      allowedOperationType.push(SEARCH_FUNCTION_SPLIT);
    }
    if (hasRole('ROLE_REQUEST_UPDATE_STUDY')) {
      requestTypeItems.push({
        id: SEARCH_FUNCTION_EDIT_STUDY,
        label: customT(SEARCH_FUNCTION_EDIT_STUDY),
      });
      allowedOperationType.push(SEARCH_FUNCTION_EDIT_STUDY);
    }
    if (hasRole('ROLE_REQUEST_UPDATE_PATIENT')) {
      requestTypeItems.push({
        id: SEARCH_FUNCTION_EDIT_PATIENT,
        label: customT(SEARCH_FUNCTION_EDIT_PATIENT),
      });
      allowedOperationType.push(SEARCH_FUNCTION_EDIT_PATIENT);
    }
    if (hasRole('ROLE_REQUEST_COPY')) {
      requestTypeItems.push({
        id: SEARCH_FUNCTION_COPY,
        label: customT(SEARCH_FUNCTION_COPY),
      });
      allowedOperationType.push(SEARCH_FUNCTION_COPY);
    }
    if (hasRole('ROLE_REQUEST_MOVE')) {
      requestTypeItems.push({
        id: SEARCH_FUNCTION_MOVE,
        label: customT(SEARCH_FUNCTION_MOVE),
      });
      allowedOperationType.push(SEARCH_FUNCTION_MOVE);
    }
    if (hasRole('ROLE_REQUEST_MOVE_TO_FOLDER')) {
      requestTypeItems.push({
        id: SEARCH_FUNCTION_MOVE_TO_FOLDER,
        label: customT(SEARCH_FUNCTION_MOVE_TO_FOLDER),
      });
      allowedOperationType.push(SEARCH_FUNCTION_MOVE_TO_FOLDER);
    }
    if (hasRole('ROLE_REQUEST_MOVE_TO_TRASH')) {
      requestTypeItems.push({
        id: SEARCH_FUNCTION_MOVE_TO_TRASH,
        label: customT(SEARCH_FUNCTION_MOVE_TO_TRASH),
      });
      allowedOperationType.push(SEARCH_FUNCTION_MOVE_TO_TRASH);
    }

    setRequestTypeItems(requestTypeItems);
    setAllowedOperationType(allowedOperationType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainSx = { width: { xs: '100%', md: '50%', lg: '20%' } };

  return (
    <Papeer bottomMargin={true} sx={{ pt: compactMode ? 0 : 1 }}>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <button hidden={true} ref={refSubmitButtom} type={'submit'} />
          <Box
            sx={{
              display: 'flex',
              flexWrap: {
                xs: 'wrap',
                lg: 'nowrap',
              },
              paddingRight: { xs: '0px', sm: 0 },
            }}
            data-tour="search-header-searchForm-searchFormFields"
          >
            <Box sx={mainSx} data-tour="request-operationType">
              <FormSelect
                name="operation"
                label={t('typeOfOperation')}
                items={requestTypeItems}
                sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
            </Box>
            <Box sx={mainSx} data-tour="request-requestingUser">
              <FormInput name="username" label={t('requestingUser')} />
            </Box>
            <Box sx={mainSx}>
              <FormSelect
                name="date"
                label={t('studyDate')}
                items={dateSelectItems}
                sx={{ borderRadius: 0 }}
                clearable={true}
                clearCallback={() => {
                  setValue('dateFrom', null);
                  setValue('dateTo', null);
                }}
              />
            </Box>
            <Box sx={mainSx}>
              <FormDatePicker
                name="dateFrom"
                label={t('SearchForm:from')}
                sx={{ borderRadius: 0 }}
                disabled={!hasCustomDate}
              />
            </Box>
            <Box sx={mainSx}>
              <FormDatePicker
                name="dateTo"
                label={t('SearchForm:to')}
                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                disabled={!hasCustomDate}
              />
            </Box>
          </Box>
          <Grid container={true} spacing={compactMode ? 1 : 2}>
            <Grid item={true} xs={12} md={10} data-tour="request-requestStatus">
              <SimpleCheckboxArray
                name="stateOfRequest"
                label={t('state')}
                items={states}
                required={true}
                grid={{}}
              />
            </Grid>
            <Grid item={true} xs={12} md={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="medium"
                sx={{ float: 'right' }}
                disabled={!canSubmit}
                data-tour="request-search"
              >
                {t('search')}
              </Button>
              <Button
                variant="contained"
                color="inherit"
                size="medium"
                sx={{ mr: 1, float: 'right' }}
                onClick={resetForm}
                data-tour="request-clear"
              >
                {t('reset')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Papeer>
  );
};

export default RequestsSearchForm;

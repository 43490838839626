import { useEffect, useState } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import AceEditor from 'react-ace';
import { setCompleters } from 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/snippets/python';
import 'ace-builds/src-noconflict/theme-github';
import { IDictionaryInputForm } from './_types';

const DictionaryInputForm = ({
  name,
  items,
  required = false,
  maxLines = 10,
  minLines = 8,
  texts,
  setTexts,
  index,
}: IDictionaryInputForm) => {
  const [newValue, setNewValue] = useState<any>(null);

  const {
    field: { ref, value, ...inputProps },
  } = useController({ name });
  const {
    formState: { errors },
  } = useFormContext();
  let isError = false;
  let errorMessage = '';
  if (!isEmpty(errors) && get(errors, name) !== undefined) {
    isError = true;
    errorMessage = get(errors, `${name}.message`, '') as string;
  }

  useEffect(() => {
    setCompleters([
      {
        getCompletions: (editor: any, session: any, pos: any, prefix: any, callback: any) => {
          callback(null, [...items]);
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (newValue: any) => {
    setNewValue(newValue);
    const aTexts = [...texts];
    aTexts[index] = newValue;
    setTexts([...aTexts]);
  };

  return (
    <FormControl size="small" fullWidth={true} margin="dense" error={isError}>
      <AceEditor
        {...inputProps}
        mode="text"
        theme="github"
        showPrintMargin={false}
        showGutter={false}
        wrapEnabled={true}
        width={'100%'}
        maxLines={maxLines}
        minLines={minLines}
        fontSize="1rem"
        onChange={onChange}
        value={newValue}
        style={{
          border: `1px solid ${isError ? '#d32f2f' : '#bdbdbd'}`,
          borderRadius: '4px',
        }}
        highlightActiveLine={true}
        scrollMargin={[8]}
        editorProps={{ $blockScrolling: Infinity }}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: false,
          enableSnippets: true,
          showLineNumbers: false,
          tabSize: 1,
        }}
      />
      {required && isError && <FormHelperText error={true}>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default DictionaryInputForm;

import React from 'react';
import StudiesEditDetail from 'modules/Studies/StudiesEdit/StudiesEditDetail';
import { IRequestsEditPatientDetail } from './_types';
import { EDIT_TYPE_PATIENT_SLUG } from 'constants/constants';

const RequestsEditPatientDetail: React.FC<IRequestsEditPatientDetail> = () => (
  <StudiesEditDetail isRequest={true} operationType={EDIT_TYPE_PATIENT_SLUG} />
);

export default RequestsEditPatientDetail;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Tooltip, Typography } from '@mui/material';
import PlayIcon from '@mui/icons-material/PlayCircleFilled';

import ManageTestsSearchForm from './ManageTestsSearchForm';

import Header from 'components/Header/Header';

import { findTranslation } from 'utils/grid/translate';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useTests } from 'utils/hooks/useTests';

import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { encryptId } from 'utils/hooks/useApp';
import { formatTime } from 'utils/hooks/useDate';
import { TourTests } from './TourTests';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'startTestResultsMui';

const StartTest: React.FC = () => {
  const { t } = useTranslation('Tests');
  const { currentLocale } = useLanguage();
  const { testResults } = useAppInfo();
  const navigate = useNavigate();
  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const { triggerSubmit } = useTests(t);

  useWithTitle(); // sets title to document

  const onCustomEntityStart = (row: any) =>
    navigate(
      `/tests/startTest/${encryptId(get(row, 'id'))}${
        get(row, 'testIsUnfinished', false) ? '/1' : ''
      }`,
    );

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 185,
        headerAlign: 'left',
        align: 'left',
        getActions: ({ row }: GridRowParams) => [
          <GridActionsCellItem
            icon={
              <Tooltip
                title={
                  get(row, 'testIsUnfinished', false) ? t('Grid:continueTest') : t('Grid:testStart')
                }
              >
                <PlayIcon />
              </Tooltip>
            }
            label="Start"
            onClick={() => onCustomEntityStart(row)}
          />,
        ],
      },

      {
        field: 'name',
        headerName: t('name'),
      },
      {
        field: 'description',
        headerName: t('description'),
      },
      {
        field: 'type',
        headerName: t('type'),
        valueGetter: (value: any, row: any) =>
          findTranslation(currentLocale, 'Tests', get(row, 'type', '').toLowerCase()),
      },
      {
        field: 'maxTestLength',
        headerName: t('maxTestLength'),
        valueGetter: (value: any, row: any) => {
          const testLengthParts = get(row, 'maxTestLength').split(':');
          const sec =
            testLengthParts[0] * 1 * 60 * 60 + testLengthParts[1] * 1 * 60 + testLengthParts[2] * 1;
          return formatTime(sec, t);
        },
      },
    ]),
  );

  const renderedSteps = () => {
    return <TourTests type="start" />;
  };

  return (
    <>
      <Typography component="div">
        <Header title={t('startTest')} TourComponent={renderedSteps()} />
        <ManageTestsSearchForm
          forStartTest={true}
          shouldShowStateField={false}
          triggerSubmit={triggerSubmit}
        />
      </Typography>
      {testResults && (
        <MuiGrid
          gridKey={muiGridKey}
          rows={testResults}
          columns={columns}
          dataTour="testsStartGrid"
        />
      )}
    </>
  );
};

export default StartTest;

import React from 'react';
import StudiesEditDetail from 'modules/Studies/StudiesEdit/StudiesEditDetail';
import { IRequestsEditStudyDetail } from './_types';
import { EDIT_TYPE_STUDY_SLUG } from 'constants/constants';

const RequestsEditStudyDetail: React.FC<IRequestsEditStudyDetail> = () => (
  <StudiesEditDetail isRequest={true} operationType={EDIT_TYPE_STUDY_SLUG} />
);

export default RequestsEditStudyDetail;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { compact, get, isArray, isEmpty } from 'lodash';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';

import { addStudy } from 'modules/Administration/Folders/_api';
import { IStudiesAddToFolderForm } from './_types';
import RequestAdditionalForm from '../Form/RequestAdditionalForm';
import DialogFoldersForm from 'modules/Administration/Folders/DialogFoldersForm';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';

import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { SeriesViewer } from 'components/SeriesViewer/SeriesViewer';
import { useSeriesViewer } from 'components/SeriesViewer/useSeriesViewer';
import { Papeer } from 'components/Papeer/Papeer';
import { TourStudiesList } from './TourStudiesList';
import Button from 'components/Buttons/Button';

const linkBack = `/studies`;

const StudiesListAddToFolder: React.FC = () => {
  const { t } = useTranslation('Studies');
  const { toggleLoader } = useAppGlobals();
  const { user } = useUser();

  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { moveToFolderStore } = useStudyInfo();

  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [selection, setSelection] = useState<any[]>([]);
  const [errorIds, setErrorIds] = useState<any[]>([]);
  if (!isEmpty(errorIds)) {
    console.log({ errorIds });
  }
  const navigate = useNavigate();

  const { studiesForViewer, setStudiesForViewer, operations, somethingSelected } =
    useSeriesViewer();

  const callReset = () => {
    reset({
      requestingUser: {
        name: {
          lastName: get(user, 'last_name'),
          firstName: get(user, 'first_name'),
        },
      },
    });
  };

  const methods = useForm<IStudiesAddToFolderForm>();
  const { handleSubmit, reset } = methods;

  const handleFormSubmit = async (values: any, fullSelectedStudies: any) => {
    toggleLoader();
    const requestComment = get(values, 'requestComment', '');
    await addStudy({ folderId: selection, studies: fullSelectedStudies, requestComment }).then(
      (response) => {
        if (response) {
          addSuccessAlert(t('studySelectedToFolder'));
          navigate(linkBack);
        } else {
          addErrorAlert(t('errorSavingBookmark'));
        }
      },
      (error) => {
        let tError = t('errorSelectingStudyToFolder');
        let ids = [];
        const errorData = get(error, 'response.data', '');
        const str = 'conflicting patientIDs:';
        const n = errorData.indexOf(str);
        if (n > -1) {
          const patientsId = errorData.substring(n + str.length + 1);
          tError = `${t('errorConflictingStudies')} ${patientsId}`;
          ids = patientsId.split(', ');
          setErrorIds(ids);
        }
        addErrorAlert(t(tError));
      },
    );
    toggleLoader(false);
  };

  const onSubmit = handleSubmit(async (values) => {
    const fullSelectedStudies = compact(
      studiesForViewer.map((study) => {
        if (!study.studyIsSelected) {
          return false;
        }
        return {
          ...study,
          listOfSeries: compact(
            study.loadedSeries.map((serie) => {
              if (!serie.serieIsSelected) {
                return false;
              }
              return serie;
            }),
          ),
        };
      }),
    );

    handleFormSubmit(values, fullSelectedStudies);
  });

  const loadStart = () => {
    const studies = (isArray(moveToFolderStore) ? moveToFolderStore : []).map((study: any) => ({
      ...study,
      studyIsSelected: true,
      studyIsOpened: false,
      loadedSeries: [],
    }));
    setStudiesForViewer(studies);

    if (isArray(studies) && studies.length < 1) {
      return navigate(linkBack);
    } else {
      callReset();
    }
  };

  useEffect(() => {
    loadStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCanSubmit(selection.length > 0 && somethingSelected > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection.length, somethingSelected]);

  const renderedSteps = () => {
    return <TourStudiesList />;
  };

  return (
    <>
      <Header
        title={t('studyListAddToFolderTitle')}
        backUrl={`${linkBack}?action=back`}
        TourComponent={renderedSteps()}
      />
      <Papeer>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <DialogFoldersForm
              user={user}
              showFoldersByUserAndStudy={false}
              showGridWithFolders={true}
              refreshGridAfterAddNew={true}
              selection={selection}
              setSelection={setSelection}
              sxPapeer={{ p: 0 }}
            />
          </Grid>
        </Grid>
      </Papeer>

      <Papeer sx={{ mt: 2 }}>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Box data-tour="addToFolder-ListOfStudy">
              <SeriesViewer
                studies={studiesForViewer}
                showStudiesCheckboxes={true}
                showSeriesCheckboxes={true}
                operations={operations}
              />
            </Box>

            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <RequestAdditionalForm isRequest={false} />
              </Grid>
            </Grid>

            <Grid container={true} justifyContent="flex-end">
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!canSubmit}
                  type="submit"
                  data-tour="addToFolder-AddToFolderButton"
                >
                  {t('addToFolder')}
                </Button>
              </Box>
            </Grid>
          </form>
        </FormProvider>
      </Papeer>
    </>
  );
};

export default StudiesListAddToFolder;

import { get, includes } from 'lodash';
import { TFunction } from 'i18next';
import { downloadFromEldax } from './_api';
import { IPatientDocument } from './_types';
import { downloadFile } from 'utils/hooks/useApp';

export const hookDownloadFromEldax = async (
  masterPatientID: any,
  patientdoc: IPatientDocument | undefined,
  uuid: any,
  t: TFunction<'ClinicalPortal', undefined>,
  addErrorAlert: (
    text: string,
    hideDuration?: number,
  ) => {
    payload: any;
    type: 'app/alertAdd';
  },
  setIsDownloadingFromEldax: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const fileName = masterPatientID + '.pdf';
  const resp = await downloadFromEldax(
    masterPatientID,
    uuid,
    get(patientdoc, 'typeOfDocument.name', ''),
  );

  const data = get(resp, 'data', null);
  if (data) {
    downloadFile(data, fileName);
  } else {
    let txt = 'downloadFromEldaxError';
    const code = +get(resp, 'errorStatus', 0);
    if (includes([1, 2, 3, 4, 5, 6, 7, 99], code)) {
      txt = `errorCodes.${code}`;
    }
    addErrorAlert(t(txt));
  }
  setIsDownloadingFromEldax(false);
};

import React, { useEffect, useState } from 'react';
import { get, find, remove } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { IDocumentDetailLayoutDialog } from './_types';
import { green as customGreen } from 'utils/variables';

export const DocumentDetailLayoutDialog: React.FC<IDocumentDetailLayoutDialog> = ({
  openLayoutDialog,
  setOpenLayoutDialog,
  setGridLayout,
  gridLayoutParams,
}) => {
  const { t } = useTranslation('ClinicalPortal');

  const [rows, setRows] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const onMouseClick = () => {
    let maxX = 0;
    let maxY = 0;
    selectedRows.forEach((selectedLayout) => {
      const x = get(selectedLayout, 'x', 0);
      const y = get(selectedLayout, 'y', 0);
      if (x > maxX) {
        maxX = x;
      }
      if (y > maxY) {
        maxY = y;
      }
    });
    setGridLayout(`${maxX + 1}x${maxY + 1}`);
    setOpenLayoutDialog(false);
  };

  const mouseEnter = (x: any, y: any) => {
    const addSelected = [];

    for (let yy = y; yy >= 0; yy--) {
      for (let xx = x; xx >= 0; xx--) {
        addSelected.push({ x: xx, y: yy });
      }
    }

    setSelectedRows([...selectedRows, ...addSelected]);
  };
  const mouseLeave = (x: any, y: any) => {
    if (x > 0) {
      for (let i = y; i >= 0; i--) {
        remove(selectedRows, (item) => {
          return get(item, 'x', null) === x && get(item, 'y', null) === i;
        });
      }
    }
    if (y > 0) {
      for (let i = x; i >= 0; i--) {
        remove(selectedRows, (item) => {
          return get(item, 'x', null) === i && get(item, 'y', null) === y;
        });
      }
    }
    remove(selectedRows, (item) => {
      return get(item, 'x', null) === x && get(item, 'y', null) === y;
    });
    setSelectedRows([...selectedRows]);
  };

  const mouseLeaveTable = () => setSelectedRows([]);

  const generateRows = () => {
    const generatedRows = [];

    for (let x = 0; x < 4; x++) {
      const row = [];
      for (let y = 0; y < 4; y++) {
        const selectedRow = find(selectedRows, { x, y });

        const bgColor = x < gridLayoutParams.x && y < gridLayoutParams.y ? '#dbdbdb' : 'white';

        row.push(
          <TableCell
            key={y}
            onClick={() => onMouseClick()}
            onMouseEnter={() => mouseEnter(x, y)}
            onMouseLeave={() => mouseLeave(x, y)}
            sx={
              selectedRow === undefined
                ? { border: `1px ${customGreen} solid`, backgroundColor: bgColor }
                : {
                    border: `1px ${customGreen} solid`,
                    backgroundColor: customGreen,
                  }
            }
            style={{ padding: 0 }}
          >
            <div style={{ width: '100%', paddingBottom: '100%' }} />
          </TableCell>,
        );
      }
      generatedRows.push(
        <TableRow key={x} onMouseLeave={() => mouseLeaveTable()}>
          {row}
        </TableRow>,
      );
    }
    setRows(generatedRows);
  };

  useEffect(() => {
    setSelectedRows([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    generateRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  return (
    <>
      <Dialog
        open={openLayoutDialog}
        onClose={() => {
          setOpenLayoutDialog(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">{t('layoutChooser')}</DialogTitle>
        <DialogContent>
          <Box sx={{ maxWidth: 300, m: '0 auto' }}>
            <Table
              aria-label="simple table"
              sx={{ 'border-collapse': 'separate', 'border-spacing': '5px' }}
            >
              <TableBody>{rows}</TableBody>
            </Table>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

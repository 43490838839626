import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import useValidationSchema from './_formCSTORE';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { ICSTOREForm } from './_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { getRules } from 'modules/Import/_api';
import { echo, getDefaultAet, cstoreMetadata } from './_api';
import { find, forEach, get, isArray } from 'lodash';
import { Button, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { GridDataType, InputType } from 'constants/constants';
import { ISelectItem } from 'components/Form/Select/_types';
import FormSelect from 'components/Form/Select/Select';
import FormInput from 'components/Form/Input/Input';
import { Announcement } from 'components/Announcement/Announcement';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import NearMeIcon from '@mui/icons-material/NearMe';
import useAlerts from 'components/Alerts/useAlerts';
import { Box } from '@mui/system';
import { checkUploadingStatus, cleanUppy, createUppy } from 'utils/import';
import { useDicomParser } from 'components/Import/useFileParser';
import { IPatient, ISeries } from 'components/Import/_types';
import Import from 'components/Import/Import';
import DicomGrid from 'components/Import/DicomGrid';
import CustomUppyFolderInput from 'modules/Import/CustomUppyFolderInput';
import CustomUppyFileInput from 'modules/Import/CustomUppyFileInput';
import { useUser } from 'utils/hooks/useUser';
import { useAppDispatch } from 'store/hooks';
import { setMdexDicomPatientToStore } from 'store/reducers/appReducer';

let uppyInstance: any;
let intervalUploading: any;

const TabCSTORE: React.FC = () => {
  const { t } = useTranslation('Cstore');

  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [noFilesError, setNoFilesError] = useState<boolean>(false);
  const { archivesForFunctions } = useAppInfo();
  const archivesFromStore = get(archivesForFunctions, 'IMPORT');
  const custom = { id: 0, name: 'Vlastní archiv' };
  const archives = archivesFromStore ? [custom, ...archivesFromStore] : custom;

  const compactMode = false;

  const [patients, setPatients] = useState<IPatient[]>([]);
  const [completed, setCompleted] = useState<number>(0);
  const [aet, setAet] = useState<string>('');
  const [previewArchiveId, setPreviewArchiveId] = useState(0);
  const [previewTarget, setPreviewTarget] = useState({ aet: '', ip: '', port: '' });
  const [timestamp, setTimestamp] = useState(new Date().getTime().toString());
  const [seriesDetailOpened, setSeriesDetailOpened] = useState<{ [id: string]: boolean }>({});
  const [selectedSeries, setSelectedSeries] = useState<Map<string, ISeries>>(new Map());
  const [rules, setRules] = useState<string>();
  const [echoDisabled, setEchoDisabled] = useState(true);
  const [cstoreDisabled, setCstoreDisabled] = useState(true);
  const { user } = useUser();
  const dispatch = useAppDispatch();

  const { CSTOREFormSchema, formItems } = useValidationSchema(t);

  const methods = useForm<ICSTOREForm>({
    resolver: yupResolver(CSTOREFormSchema),
  });
  const { handleSubmit, reset, setValue, watch } = methods;

  const { parseDicomFiles } = useDicomParser({ setPatients });

  const watchedArchiveId = watch('archiveId', 0);
  const watchedTargetAet = watch('targetAet', '');
  const watchedTargetIp = watch('targetIp', '');
  const watchedTargetPort = watch('targetPort', '');

  useEffect(() => {
    const files = patients.flatMap((patient) => get(patient, 'files', []));
    let filesIds: string[] = [];
    files.forEach((file: any) => {
      filesIds.push(get(file, 'id'));
    });
    dispatch(setMdexDicomPatientToStore(filesIds));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patients]);

  const getEntities = async () => {
    toggleLoader();
    try {
      let aet = '';
      const rules: any = await getRules();
      const defaultAetResp = await getDefaultAet();
      if (defaultAetResp) {
        aet = get(defaultAetResp, 'aet', '');
        setAet(aet);
      }
      reset({
        archiveId: 0,
        targetAet: '',
        targetIp: '',
        targetPort: '',
        sourceAet: aet,
      });
      if (rules) {
        setRules(rules);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useMemo(() => {
    // componentWillMount events
    createUppy('uppyCstore', parseDicomFiles, timestamp, [], true);
    uppyInstance = (window as any).UppyGlobalInstances[
      (window as any).UppyGlobalInstances.length - 1
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEntities();

    intervalUploading = setInterval(() => {
      checkUploadingStatus(setCompleted);
    }, 500);

    return () => {
      clearInterval(intervalUploading);
      cleanUppy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isButtonsDisabled = (disabled: boolean) => {
    setEchoDisabled(disabled);
    setCstoreDisabled(disabled);
  };
  const changeValues = (archiveId: any) => {
    let targetAet = '',
      targetIp = '',
      targetPort = '';
    if (archiveId !== 0) {
      const archive = find(archivesFromStore, ['id', archiveId]);
      const archiveSettings = get(archive, 'systemSetting', []);
      const aetSetting = find(archiveSettings, ['variable', 'aeTitle']);
      const portSetting = find(archiveSettings, ['variable', 'dicomPort']);
      const ipSetting = find(archiveSettings, ['variable', 'ip']);
      targetAet = get(aetSetting, 'value', '');
      targetIp = get(ipSetting, 'value', '');
      targetPort = get(portSetting, 'value', '');
    }
    setValue('archiveId', archiveId);
    setValue('targetAet', targetAet);
    setValue('targetIp', targetIp);
    setValue('targetPort', targetPort);

    isButtonsDisabled(archiveId === 0 || archiveId === '');
  };

  if (previewArchiveId !== watchedArchiveId) {
    const archiveId = watchedArchiveId;
    setPreviewArchiveId(archiveId);
    changeValues(archiveId);
  }
  if (
    previewTarget.aet !== watchedTargetAet ||
    previewTarget.ip !== watchedTargetIp ||
    previewTarget.port !== watchedTargetPort
  ) {
    setPreviewTarget({
      aet: watchedTargetAet,
      ip: watchedTargetIp,
      port: watchedTargetPort,
    });
    isButtonsDisabled(
      watchedTargetAet === '' || watchedTargetIp === '' || watchedTargetPort === '',
    );
  }

  const resetValue = () => {
    changeValues(0);
    setValue('sourceAet', aet);
    setNoFilesError(false);
  };

  const removePatient = (patient: IPatient) => {
    const files = get(patient, 'files', []);
    forEach(files, (file) => {
      uppyInstance?.removeFile(get(file, 'id', ''));
    });
    setPatients((prevPatients) => [
      ...prevPatients.filter((pat: IPatient) => pat.id !== patient.id),
    ]);
  };

  const removeAllPatients = () => {
    forEach(patients, (patient: any) => {
      const files = get(patient, 'files', []);
      forEach(files, (file) => {
        uppyInstance.removeFile(get(file, 'id'));
      });
    });
    setPatients([]);
  };

  const echoSubmit = async (values: any) => {
    toggleLoader();
    const targetAet = get(values, 'targetAet', '');
    const targetIp = get(values, 'targetIp', '');
    const targetPort = get(values, 'targetPort', '');
    const sourceAet = get(values, 'sourceAet', '');
    const echoResponse = await echo({ targetAet, targetIp, targetPort, sourceAet });
    if (echoResponse) {
      if (targetAet !== null && targetIp !== null && targetPort !== null) {
        setCstoreDisabled(false);
      }
      addSuccessAlert(t('echoSuccessfully'));
    } else {
      setCstoreDisabled(true);
      addErrorAlert(t('echoFailed'));
    }
    toggleLoader(false);
  };

  const cstoreSubmit = (values: any) => {
    const data = { ...values };
    toggleLoader();
    setNoFilesError(true);

    if (patients.length > 0) {
      setNoFilesError(false);
      uppyInstance.upload().then(async () => {
        const resp = await cstoreMetadata({
          targetIp: get(data, 'targetIp'),
          targetAet: get(data, 'targetAet'),
          sourceAet: get(data, 'sourceAet'),
          targetPort: get(data, 'targetPort'),
          path: `${timestamp}_${user?.sub}`,
          timestamp,
          typeOfUpload: 'cstore',
        });
        if (resp) {
          addSuccessAlert(t('successfullyCstore'));
        } else {
          addErrorAlert(t('errorCstore'));
        }
      });
      setTimeout(() => {
        toggleLoader(false);
      }, 3000);
      setPatients([]);
      resetValue();
      const newTimestamp = new Date().getTime().toString();
      createUppy('uppyCstore', parseDicomFiles, newTimestamp, [], true);
      uppyInstance = (window as any).UppyGlobalInstances[
        (window as any).UppyGlobalInstances.length - 1
      ];
      setTimestamp(newTimestamp);
    } else {
      setNoFilesError(true);
    }
    toggleLoader(false);
  };
  const submitByAction = (action: string, values: any) => {
    if (action === 'echo') {
      echoSubmit(values);
    } else if (action === 'cstore') {
      cstoreSubmit(values);
    }
  };

  const defaultItems = {
    archiveId: isArray(archives)
      ? archives.map((archive) => ({
          id: archive.id,
          label: archive.name,
        }))
      : [],
  };

  const defaultGrid: GridDataType = { xs: 12, md: 4, lg: 3, xl: 3 };

  return (
    <>
      <Typography component="div">
        <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
          {rules && archives && (
            <div id="scrollToForm">
              {completed > 0 && (
                <Grid item={true} xs={12} md={12} lg={12}>
                  <LinearProgress variant="determinate" value={completed} />
                  <div
                    style={{
                      marginTop: 5,
                      marginBottom: 7,
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: '0.875rem',
                    }}
                  >
                    {t('uploadingFiles')}
                  </div>
                </Grid>
              )}
              <FormProvider {...methods}>
                <form>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} key={'announcement'}>
                      <Announcement
                        label={t('allowedFilesDicom')}
                        type={'info'}
                        spaced={true}
                        component={'div'}
                      />
                    </Grid>
                    {!patients.length && (
                      <Grid item xs={12} data-tour="cstore-dicom-uppySelector">
                        {uppyInstance && <Import uppy={uppyInstance} />}
                      </Grid>
                    )}
                    {patients && patients.length > 0 && (
                      <>
                        <Grid item xs={12} mt={2} data-tour="cstore-dicom-grid">
                          <DicomGrid
                            patients={patients}
                            removePatient={removePatient}
                            hideEditPatient={true}
                            enableMasterDetail={true}
                            uppy={uppyInstance}
                            selectedSeries={selectedSeries}
                            setSelectedSeries={setSelectedSeries}
                            seriesDetailOpened={seriesDetailOpened}
                            setSeriesDetailOpened={setSeriesDetailOpened}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-end"
                          sx={{ mt: 1, gap: 1 }}
                        >
                          <Button
                            variant="contained"
                            color="inherit"
                            onClick={removeAllPatients}
                            data-tour="cstore-dicom-deleteAll"
                          >
                            {t('Import:removeAll')}
                          </Button>
                          <Box data-tour="cstore-dicom-uploadFolder">
                            <CustomUppyFolderInput uppy={uppyInstance} />
                          </Box>
                          <Box data-tour="cstore-dicom-uploadFiles">
                            <CustomUppyFileInput uppy={uppyInstance} />
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid container={true} spacing={2} sx={{ mt: 2 }}>
                    {formItems.map((formItem, index) => {
                      const customGrid = get(formItem, 'grid', false)
                        ? (get(formItem, 'grid') as unknown as GridDataType)
                        : defaultGrid;

                      const type = get(formItem, 'type', 'text');
                      const name = get(formItem, 'name', '');
                      const items: ISelectItem[] = get(defaultItems, name, []);
                      const isRequired = get(formItem, 'required', false);
                      const isDisabled = get(formItem, 'disabled', false);
                      const label = get(formItem, 'label', '');
                      const dataTour = get(formItem, 'dataTour', '');

                      return (
                        <Grid key={`formItem-${index}`} item={true} {...customGrid}>
                          {type === 'select' ? (
                            <Box data-tour={dataTour}>
                              <FormSelect
                                name={name}
                                label={label}
                                required={isRequired}
                                items={items}
                                disabled={isDisabled}
                              />
                            </Box>
                          ) : (
                            <Box data-tour={dataTour}>
                              <FormInput
                                name={name}
                                label={label}
                                type={type as InputType}
                                required={isRequired}
                                disabled={isDisabled}
                                {...{
                                  autoComplete: get(formItem, 'autoComplete'),
                                  inputProps: get(formItem, 'inputProps'),
                                }}
                              />
                            </Box>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid container={true} spacing={2}>
                    {noFilesError && (
                      <Grid item={true} xs={12} md={12} lg={12}>
                        <Typography style={{ color: 'red' }}>{t('noFiles')}</Typography>
                      </Grid>
                    )}
                    <Grid item={true} xs={12} md={12} lg={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 2 }}>
                        <Button
                          variant="contained"
                          size={compactMode ? 'small' : 'large'}
                          style={{ marginRight: compactMode ? 6 : 10 }}
                          onClick={() => {
                            removeAllPatients();
                            resetValue();
                          }}
                          data-tour="cstore-save-clear"
                        >
                          {t('reset')}
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          size={compactMode ? 'small' : 'large'}
                          style={{ marginRight: compactMode ? 6 : 10 }}
                          disabled={echoDisabled}
                          onClick={() => handleSubmit((values) => submitByAction('echo', values))()}
                          data-tour="cstore-save-echo"
                        >
                          <SettingsInputAntennaIcon sx={{ mr: 1 }} />
                          {t('echo')}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          size={compactMode ? 'small' : 'large'}
                          disabled={!patients.length || cstoreDisabled}
                          onClick={() =>
                            handleSubmit((values) => submitByAction('cstore', values))()
                          }
                          data-tour="cstore-save-button"
                        >
                          <NearMeIcon sx={{ mr: 1 }} />
                          {t('cstore')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </div>
          )}
        </Paper>
      </Typography>
    </>
  );
};

export default TabCSTORE;

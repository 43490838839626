import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { IEventType, IMissingEvents } from './_types';
import { SmallButton } from 'components/Form/SmallButton/SmallButton';

const MissingEvents: React.FC<IMissingEvents> = ({ events, addItemInDialog }) => {
  const { t } = useTranslation('NotificationTemplates');

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {t('missingTemplates')}
      </Typography>
      <Table>
        <TableBody>
          {events.map((event: IEventType) => (
            <TableRow key={event.id}>
              <TableCell>
                <Typography component="span">{t(event.languageKey)}</Typography>
              </TableCell>
              <TableCell>
                <SmallButton
                  component={Link}
                  sx={{ ml: 1 }}
                  variant="contained"
                  onClick={() => addItemInDialog(event.id)}
                >
                  {t('add')}
                </SmallButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default MissingEvents;

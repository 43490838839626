import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, sortBy, uniq } from 'lodash';
import { format } from 'date-fns';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { getAllExportList, sign } from './_api';
import { IExportListGrid } from './_types';
import { joinParams } from 'utils/study';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Tooltip } from '@mui/material';
import useAlerts from 'components/Alerts/useAlerts';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const formatDateTime = 'dd.MM.yyyy HH:mm:ss';
const formatDate = 'dd.MM.yyyy';

export const ExportListGrid: React.FC<IExportListGrid> = ({ type }) => {
  const { t } = useTranslation('StudyExport');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert } = useAlerts();

  const muiGridKey = `exportList${type}Grid`;

  const [exportList, setExportList] = useState<any[]>([]);

  const getEntities = async () => {
    toggleLoader();
    setExportList([]);
    try {
      const exportList = await getAllExportList(type === 'Valid');
      setExportList(exportList);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDownload = async (row: any) => {
    toggleLoader();
    const resp = await sign(row.id);
    const hash = get(resp, 'data');
    if (hash) {
      window.location.href = `/portal-api/studyExport/download?signature=${hash}`;
    } else {
      addErrorAlert(t('downloadError'));
    }
    toggleLoader(false);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 160,
        headerAlign: 'left',
        align: 'left',
        renderCell: ({ row }: GridRenderCellParams) => (
          <GridActionsCellItem
            icon={
              <Tooltip title={t('Grid:download')}>
                <DownloadIcon />
              </Tooltip>
            }
            label={t('Grid:download')}
            onClick={() => onDownload(row)}
            disabled={
              !(
                get(row, 'status', null) === 'FINISHED' &&
                new Date(get(row, 'downloadableTo', null)).getTime() > Date.now()
              )
            }
          />
        ),
      },
      {
        field: 'patientName',
        headerName: t('patientName'),
        valueGetter: (value: any, row: any) => {
          const patientName = sortBy(get(row, 'studyRequestStudies'), [
            'requested.patient.name.lastName',
            'requested.patient.name.firstName',
          ])
            .map((item: any) => {
              return `${joinParams([
                get(item, 'requested.patient.name.lastName'),
                get(item, 'requested.patient.name.firstName'),
              ])}`;
            })
            .join(', ');
          return patientName;
        },
      },
      {
        field: 'modality',
        headerName: t('modality'),
        renderCell: ({ row }: GridRenderCellParams) => {
          const modalities = uniq(
            row.studyRequestStudies
              .map((item: any) => {
                return get(item, 'requested.modalitiesInStudy', '');
              })
              .join(', ')
              .split(', '),
          ).join(', ');
          return modalities;
        },
      },
      {
        field: 'dateTime',
        headerName: t('dateTime'),
        valueGetter: (value: any, row: any) => {
          const dateTime = get(row, 'studyRequestStudies', [])
            .map((item: any) => {
              const requestedDateTime = get(item, 'requested.dateTime', '');
              return requestedDateTime ? format(new Date(requestedDateTime), formatDateTime) : '';
            })
            .join(', ');
          return dateTime;
        },
      },
      {
        field: 'modifiedWhen',
        headerName: t('exportDateTime'),
        // type: 'dateTime',
        renderCell: ({ row }: GridRenderCellParams) =>
          format(new Date(get(row, 'modifiedWhen', '')), formatDateTime),
        // valueGetter: ({ value }: any) => value && new Date(value),
      },
      {
        field: 'status',
        headerName: t('exportState'),
        renderCell: ({ row }: GridRenderCellParams) => t(get(row, 'status', '')),
        valueGetter: (value: any, row: any) => get(row, 'status'),
      },
      {
        field: 'type',
        headerName: t('exportType'),
        renderCell: ({ row }: GridRenderCellParams) => t(get(row, 'type', '')),
        valueGetter: (value: any, row: any) => get(row, 'type'),
      },
      {
        field: 'downloadableTo',
        headerName: t('downloadableTo'),
        //  type: 'date',
        renderCell: ({ row }: GridRenderCellParams) => {
          const downloadableTo = get(row, 'downloadableTo', '');
          return downloadableTo ? format(new Date(downloadableTo), formatDate) : '';
        },
        // valueGetter: ({ value }: any) => value && new Date(value),
      },
      {
        field: 'size',
        headerName: t('size'),
        renderCell: ({ row }: GridRenderCellParams) => {
          let size = row.size;
          let sizeWithUnit = '';
          const units = ['B', 'kB', 'MB', 'GB'];
          let i;
          for (i = 0; i < units.length; i++) {
            sizeWithUnit = size + ' ' + units[i];
            if (size < 1024) {
              break;
            }
            size = (size / 1024).toFixed(1);
          }
          return sizeWithUnit;
        },
      },
    ]),
  );

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={exportList}
        columns={columns}
        dataTour="export-list-grid"
        initialSortMode={[{ field: 'modifiedWhen', sort: 'asc' }]}
      />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Papeer } from 'components/Papeer/Papeer';
import { Button, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { scrollToTopForm } from 'utils/scrollTo';

import { generateKey, setUse2FAForLoginAPI } from './_api';
import useAlerts from 'components/Alerts/useAlerts';

const UserProfile2FASettings: React.FC<any> = ({ userSetting }: any) => {
  const { t } = useTranslation('Users');
  const { addErrorAlert } = useAlerts();

  const [use2FAForLogin, setUse2FAForLogin] = useState<boolean>(false);
  const [userHasGeneratedSecredKey, setUserHasGeneratedSecredKey] = useState<boolean>(false);
  const [use2FAForLoginSwitchDisabled, setUse2FAForLoginSwitchDisabled] = useState<boolean>(false);
  const [generatedKey, setGeneratedKey] = useState<any>(undefined);

  useEffect(() => {
    setUse2FAForLogin(get(userSetting, 'use2FA', false));
    setUse2FAForLoginSwitchDisabled(!get(userSetting, 'userHasGeneratedSecretKey', false));
    setUserHasGeneratedSecredKey(get(userSetting, 'userHasGeneratedSecretKey', false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateSecretCodeAndQrCode = async () => {
    const response = await generateKey();
    setGeneratedKey(response);
    if (response) {
      setUse2FAForLoginSwitchDisabled(false);
    }
    scrollToTopForm('secret_key', null);
  };

  const toggleSwitchChange = async (change: any) => {
    const response = await setUse2FAForLoginAPI(change);
    if (response) {
      setUse2FAForLogin(change);
    } else {
      addErrorAlert(t('cantDisallowTotp'));
    }
  };

  return (
    <>
      <Papeer>
        <Grid container={true} spacing={16} justifyContent="center" alignItems="center">
          <Grid item={true} xs={12} md={6} lg={3} xl={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                generateSecretCodeAndQrCode();
              }}
              style={{ marginRight: 8 }}
            >
              {userHasGeneratedSecredKey ? t('regenerateNew') : t('generateNew')}
            </Button>
          </Grid>
          <Grid item={true} xs={12} md={6} lg={3} xl={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={use2FAForLogin}
                  onChange={(e, state) => {
                    toggleSwitchChange(state);
                  }}
                  disabled={use2FAForLoginSwitchDisabled}
                />
              }
              label={t('use2FAForLogin')}
            />
          </Grid>
        </Grid>
        <Grid container={true} spacing={16} justifyContent="center" alignItems="center">
          <Grid
            item={true}
            xs={12}
            md={6}
            lg={3}
            xl={2}
            justifyContent="center"
            alignItems="center"
          >
            {generatedKey !== undefined && (
              <img
                src={`data:${get(generatedKey, 'imgType')};base64, ${get(generatedKey, 'qrCode')}`}
                alt="QR code"
                width="100%"
              />
            )}
            <Typography id="secret_key" align="center">
              {get(generatedKey, 'secretKey')}
            </Typography>
          </Grid>
        </Grid>
      </Papeer>
      <Typography paragraph={true}>
        <br />
        {`* ${t('explainPage')}`}
        <br />
        {`* ${t('explainPage2')}`}
        <br />
        {`* ${t('explainQRCodeApps')}`}
        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=cs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Authenticator(GP)
        </a>
        ,&nbsp;
        <a
          href="https://play.google.com/store/apps/details?id=com.azure.authenticator&amp;hl=cs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Microsoft Authenticator(GP)
        </a>
        ,&nbsp;
        <a
          href="https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp&amp;hl=cs"
          target="_blank"
          rel="noopener noreferrer"
        >
          FreeOTP(GP)
        </a>
        ,&nbsp;
        <a
          href="https://apps.apple.com/cz/app/google-authenticator/id388497605?l=cs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Authenticator(AS)
        </a>
        ,&nbsp;
        <a
          href="https://apps.apple.com/cz/app/microsoft-authenticator/id983156458?l=cs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Microsoft Authenticator(AS)
        </a>
        {t('explainQRCodeAppsETC')}
        <br />
        {`* ${t('explainSettings')}`}
      </Typography>
    </>
  );
};

export default UserProfile2FASettings;

import { FilterOptionsState, SxProps, Theme } from '@mui/material';
import { IAutocompleteOption } from 'components/Form/Autocomplete/_types';
import { IAddGroupTagRequest } from 'modules/Tags/_types';

export interface ITagForm {
  formType: FormType;
  showCommentField?: boolean;
  isModalBoxStyling?: boolean;
  showOnlyActiveTags?: boolean;
  hideSearchAndClearButton?: boolean;
  isExpandableStructure?: boolean;
  isForAddTagForm?: boolean;
}

export interface IRecursiveTagFields {
  formType: FormType;
  data: IRegisterGroup[];
  isRootLevel?: boolean;
  showArrow?: boolean;
  isExpandableStructure?: boolean;
  appliedStyle?: SxProps<Theme>;
  nestedStructure?: any;
  parentSelectedItem?: IRegisterItem | undefined;
  isForAddTagForm?: boolean;
}

export interface IRegisterGroup {
  id?: number;
  active: boolean;
  name: string;
  parent?: number;
  children?: IRegisterGroup[];
  registerItems?: IRegisterItem[];
}

export interface ICreateRegisterGroupRequest {
  name: string;
  parent?: {
    id: string;
  };
  active?: boolean;
}

export interface IRegisterItem {
  id?: number;
  active: boolean;
  name: string;
  registerGroupId?: number;
  registerGroupName?: string;
  registerItems?: any;
  parentRegisterItems?: any;
  languageJsonText?: string;
  value?: string | number;
  label?: string;
}

export interface ITagAutocomplete {
  value: number;
  label: string;
  tagItem: IRegisterItem;
}

export interface INewTagAutocomplete {
  newValue: string;
  label: string;
  isNewItem: boolean;
}

export interface ITagFormAutocomplete {
  registerItems: ITagFormAutocompleteValues;
}

export interface ITagFormAutocompleteValues {
  [key: string]: ITagAutocomplete;
}

export type IGroupTagsHierarchicalStructure = IAddGroupTagRequest[];

export interface IUseAddTagForm {
  handleItemSelect: (data: any) => void;
  tag: IRegisterGroup;
  data: any;
  parentSelectedItem: IRegisterItem;
  setValue: (name: any, value: any, shouldValidate?: boolean) => void;
}

export enum FormType {
  Default = 'DEFAULT',
  AddTagForm = 'ADD_TAG_FORM',
}

export interface IUseTagFormProps {
  customHandleOnChange: (data: IAutocompleteOption, tag: IRegisterGroup) => Promise<void>;

  customFilterOption: (
    options: IAutocompleteOption[],
    params: FilterOptionsState<IAutocompleteOption>,
  ) => IAutocompleteOption[];

  createRegisterItemsOptions: (tag: IRegisterGroup) => {
    value: number;
    label: string;
    tagItem: IRegisterItem;
  }[];

  onSelectShowChildren: (tag: IRegisterGroup) => () => void;

  updatedOptions: any;
  selectedItem?: IRegisterItem;
  expandableStructure: {
    isExpandableStructure: boolean;
    showNested: { [key: string]: boolean };
    toggleNested?: (tag: IRegisterGroup) => void;
  };
}

export interface ITagFormHookProps {
  isRootLevel?: boolean;
  parentSelectedItem: IRegisterItem | undefined;
}

import React, { useCallback, useState } from 'react';
import { difference, filter, find, get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { IFindingReportsResults } from './_types';
import { GridRowId, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { SearchResultsMUI } from 'components/SearchResultsMUI/SearchResultsMUI';
import { getStudiesByStudyIdentity } from 'modules/Tags/_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { generateIID } from 'utils/study';
import { IStudyResultForGrid } from 'modules/Search/_types';
import { encryptId } from 'utils/hooks/useApp';

const FindingReportsResults: React.FC<IFindingReportsResults> = ({ findingReports }) => {
  const { t } = useTranslation('FindingReports');
  const { toggleLoader } = useAppGlobals();
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings, MuiGrid } =
    useMuiGrid('requestResultsMui');

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        { field: 'physicianName', headerName: t('physicianName') },
        {
          field: 'hospitalName',
          headerName: t('hospitalName'),
          valueGetter: (value: any, row: any) => get(row, 'hospital.name', '').trim(),
        },
        {
          field: 'findingCount',
          headerName: t('findingCount'),
          type: 'number',
          width: 100,
        },
      ],
      250,
    ),
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    async (newIds: GridRowId[]) => {
      const id = get(difference(newIds, detailPanelExpandedRowIds), '[0]', '');
      if (newIds.length > detailPanelExpandedRowIds.length) {
        const rowsForReport = find(rows, { id });
        if (!rowsForReport) {
          toggleLoader();
          const findingReport = find(findingReports, { id });
          const studies = await getStudiesByStudyIdentity({
            studyIdentities: get(findingReport, 'studies', []),
          });
          setRows((rows) => [
            ...rows,
            ...(isArray(studies)
              ? studies.map((study: any) => {
                  const iid = generateIID(study);
                  return {
                    ...study,
                    id,
                    iid,
                    hashIID: encryptId(iid),
                    pathnameForLinkBack: '/reports/findingReports',
                  };
                })
              : []),
          ]);
          toggleLoader(false);
        }
      }
      setDetailPanelExpandedRowIds([id]);
    },
    [findingReports, detailPanelExpandedRowIds, toggleLoader, setRows, rows],
  );

  const DetailPanel = ({ row }: GridRowParams) => {
    const studiesToShow = get(find(findingReports, { id: row.id }), 'studies', []);
    const studies = filter(rows, { id: row.id }) as IStudyResultForGrid[];
    return (
      isArray(studiesToShow) &&
      studiesToShow.length > 0 && (
        <SearchResultsMUI
          rows={studies}
          setRows={setRows}
          selecting={false}
          selection={selection}
          setSelection={setSelection}
          showDetailPanel={false}
          hideTopPanel={true}
          forcedAutoHeight={true}
        />
      )
    );
  };

  return (
    <>
      <MuiGrid
        rows={findingReports}
        columns={columns}
        rowHeight={true}
        dataTour={'finding-report-grid'}
        initialSortMode={[{ field: 'physicianName', sort: 'asc' }]}
        showGridToolbarExport={true}
        rowDetail={{
          showRowDetail: true,
          DetailPanel,
          getDetailPanelHeight: getDetailPanelHeight,
          detailPanelExpandedRowIds,
          handleDetailPanelExpandedRowIdsChange: handleDetailPanelExpandedRowIdsChange,
        }}
      />
    </>
  );
};

export default FindingReportsResults;

import React, { useMemo } from 'react';
import { get } from 'lodash';
import { Grid } from '@mui/material';
import { Papeer } from 'components/Papeer/Papeer';
import { stateIsSame } from 'utils/componentOptimizatons';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { IStudyDetailPatientInfo } from './_types';
import { joinParams } from 'utils/study';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useStudy } from 'utils/hooks/useStudy';

const formatDate = 'dd. MM. yyyy';

const PlainStudyDetailPatientInfo: React.FC<IStudyDetailPatientInfo> = ({ study }) => {
  const { spacing } = useAppInfo();
  const { t } = useTranslation('StudyDetail');

  const { getFullName } = useStudy();

  const fieldsForPatient = useMemo(
    () => `${getFullName(get(study, 'patient.name'))} 
      (${joinParams(
        [
          joinParams(
            [t('patient.identificationNumber'), get(study, 'patient.identificationNumber', '-')],
            ': ',
          ),
          get(study, 'patient.sex', 'O')
            ? joinParams(
                [t('patient_sex'), t(`patient_sex_${get(study, 'patient.sex', 'O')}`)],
                ': ',
              )
            : null,
          get(study, 'patient.dateBirth')
            ? joinParams(
                [
                  t('patient.dateBirth'),
                  format(new Date(get(study, 'patient.dateBirth')), formatDate),
                ],
                ': ',
              )
            : null,
        ],
        ' | ',
      )})`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [study],
  );

  return (
    <Papeer>
      <div id="patient">
        <Grid container={true} spacing={spacing === 2 ? 2 : 1}>
          <Grid item={true} xs={12}>
            {fieldsForPatient}
          </Grid>
        </Grid>
      </div>
    </Papeer>
  );
};

export const StudyDetailPatientInfo = React.memo(PlainStudyDetailPatientInfo, stateIsSame);

import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ICustomUppyFileInputProps } from 'components/Import/_types';

const CustomUppyFileInput: React.FC<ICustomUppyFileInputProps> = ({ uppy, typeOfFiles }) => {
  const { t } = useTranslation('Import');

  const handleFileChange = (event: any) => {
    const files = Array.from(event.target.files);

    const fileObjects = files.map((file: any) => ({
      name: file.webkitRelativePath || file.name,
      type: file.type,
      data: file,
      source: 'Local',
      isRemote: false,
    }));

    uppy.addFiles(fileObjects);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => document.getElementById('fileInput')?.click()}
      >
        {t('uploadFiles')}
      </Button>
      <input
        type="file"
        id="fileInput"
        {...(typeOfFiles ? { accept: typeOfFiles } : {})}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        multiple
      />
    </>
  );
};

export default CustomUppyFileInput;

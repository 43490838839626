import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { get } from 'lodash';
import { IExistingSplitRequest, IRequestsSplitDetailEdit } from './_types';
import { getRequest } from '../_api';
import { StudiesSplitDetail } from 'modules/Studies/StudiesSplit/StudiesSplitDetail';
import { IStudy } from 'modules/Studies/StudyDetail/_types';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';

const RequestsSplitDetailEdit: React.FC<IRequestsSplitDetailEdit> = () => {
  const { id } = useEntityInfo();
  const { toggleLoader } = useAppGlobals();
  const navigate = useNavigate();

  const [request, fetchRequest] = useState<IExistingSplitRequest>();
  const [studies, fetchStudies] = useState<IStudy[]>();

  const loadEntity = async () => {
    if (id) {
      toggleLoader();
      const request = (await getRequest(parseInt(id.toString(), 10))) as
        | IExistingSplitRequest
        | false;
      if (request) {
        fetchRequest(request);
        fetchStudies(get(request, 'data.studies'));
      } else {
        navigate('/requests');
      }
      toggleLoader(false);
    }
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {request ? (
        <StudiesSplitDetail
          isRequest={true}
          isRequestEdit={true}
          studiesFromRequest={studies}
          request={request}
        />
      ) : null}
    </>
  );
};

export default RequestsSplitDetailEdit;

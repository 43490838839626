import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DialogContentText, Link } from '@mui/material';
import { Box } from '@mui/system';
import { ICheckUserPasswordPolicyDialog } from './_types';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import Button from 'components/Buttons/Button';

const CheckUserPasswordPolicyDialog: React.FC<ICheckUserPasswordPolicyDialog> = ({
  checkUserPasswordDialog,
  setCheckUserPasswordDialog,
}) => {
  const { t } = useTranslation('Users');
  const navigate = useNavigate();

  const closeDialog = () => {
    setCheckUserPasswordDialog({
      open: false,
      status: checkUserPasswordDialog.status,
      inDays: checkUserPasswordDialog.inDays,
    });
  };

  const openUserProfile = () => {
    closeDialog();
    navigate('/userProfile');
  };

  return (
    <>
      <OverflowedDialog
        title={t('checkUserPassword')}
        open={checkUserPasswordDialog.open}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        actions={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              '@media print': {
                display: 'none',
              },
            }}
          >
            {checkUserPasswordDialog.status === 'WILL_EXPIRED' && (
              <Button onClick={closeDialog} variant="contained" color="inherit" sx={{ mr: 1 }}>
                {t('Form:close')}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={openUserProfile}
              data-selenium-selector="open-user-profile-button"
            >
              {t('openUserProfile')}
            </Button>
          </Box>
        }
      >
        <DialogContentText id="alert-dialog-description">
          {checkUserPasswordDialog.status === 'WILL_EXPIRED'
            ? t('passwordWillExpired', {
                inDays:
                  checkUserPasswordDialog.inDays === 1
                    ? t(`SearchResults:day1`, { days: checkUserPasswordDialog.inDays })
                    : checkUserPasswordDialog.inDays === 2 ||
                      checkUserPasswordDialog.inDays === 3 ||
                      checkUserPasswordDialog.inDays === 4
                    ? t(`SearchResults:day234`, { days: checkUserPasswordDialog.inDays })
                    : t(`SearchResults:days`, { days: checkUserPasswordDialog.inDays }),
              })
            : t('passwordExpired')}
          <Link
            color="primary"
            underline="always"
            sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={openUserProfile}
          >
            {t('passwordExpiredLink')}.
          </Link>
        </DialogContentText>
      </OverflowedDialog>
    </>
  );
};

export default CheckUserPasswordPolicyDialog;

import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';
import { compact, get, isEmpty } from 'lodash';
import { ARCHIVE } from 'constants/constants';

const useValidationSchema = (t: TFunction, isCreating: boolean, productType: any) => {
  const { required } = useValidators();

  const ProductFormSchema =
    productType && !isEmpty(get(productType, 'subtypes'))
      ? yup.object().shape({
          code: required(yup.string()),
          name: required(yup.string()),
          description: required(yup.string()),
          type: yup.object({
            id: required(yup.mixed()),
          }),
          type_subtype_id: required(yup.mixed()),
        })
      : yup.object().shape({
          code: required(yup.string()),
          name: required(yup.string()),
          description: required(yup.string()),
          type: yup.object({
            id: required(yup.mixed()),
          }),
        });

  const formItems = compact([
    {
      name: 'name',
      label: t('name'),
      required: true,
    },
    {
      name: 'code',
      label: t('code'),
      required: true,
      disabled: !isCreating,
    },
    {
      name: 'description',
      label: t('description'),
      required: true,
    },
    {
      type: 'select',
      name: 'type.id',
      label: t('type'),
      required: true,
      disabled: !isCreating,
    },
    productType && !isEmpty(get(productType, 'subtypes'))
      ? {
          type: 'select',
          name: 'type_subtype_id',
          label: t('subtype'),
          required: true,
          disabled: !isCreating,
        }
      : null,
    productType && get(productType, 'name') === ARCHIVE
      ? {
          type: 'select',
          name: 'default_id',
          label: t('default'),
        }
      : null,
  ]);

  return { ProductFormSchema, formItems };
};

export default useValidationSchema;

import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { get, isEmpty, isFunction } from 'lodash';
import { Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { createFormat, getFormat, updateFormat } from './_api';
import { IClinicalPortalSetting, IFormat } from './_types';
import useValidationSchema from './_form';

import { Papeer } from 'components/Papeer/Papeer';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import FormInput from 'components/Form/Input/Input';

import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useAppInfo } from 'utils/hooks/useAppInfo';

import { storeConfirmationDataToStore } from 'store/reducers/appReducer';

export const FormatForm: React.FC<any> = ({ formatId, toggleDialog, loadEntity, dispatch }) => {
  const { t } = useTranslation('ClinicalPortalSettings');
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { compactMode } = useAppInfo();
  const { id } = useEntityInfo();
  const [entity, fetchEntity] = useState<IClinicalPortalSetting>();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const { title } = useWithEntityTitle(isCreating, entity, t);
  const { FormatFormSchema } = useValidationSchema(t);

  const methods = useForm<any>({
    resolver: yupResolver(FormatFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const { toggleLoader } = useAppGlobals();

  const clearAction = () => {
    toggleDialog(false);
    if (isFunction(dispatch)) {
      dispatch(storeConfirmationDataToStore(null));
    }
  };

  const prepareValues = (values: any) => {
    const printable = get(values, 'printable', true);
    const exportable = get(values, 'exportable', true);
    const preparedValues: any = {
      ...values,
      printable: printable === 'true' || printable === true,
      exportable: exportable === 'true' || exportable === true,
    };
    return preparedValues;
  };
  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createFormat : updateFormat;
    await fn(prepareValues(values)).then(
      (response) => {
        addSuccessAlert(t('saved'));

        clearAction();
        loadEntity();
      },
      (error) => {
        let tError = 'errorSaving';
        if (get(error, 'response.data', '') === 'error.administration.format.exists') {
          tError = 'errorFormatExists';
        }
        addErrorAlert(t(tError));
      },
    );

    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      let format: IFormat = { id: 0, name: '', printable: true, exportable: true };
      const entityId = formatId ? formatId : id;
      const isCreating = entityId === 'create';
      setIsCreating(isCreating);

      if (!isCreating && entityId) {
        const entity: any = await getFormat(entityId);
        if (entity) {
          format = { ...entity };
          fetchEntity(entity);
        }
      }
      reset({ ...format });
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isEmpty(entity) || isCreating ? (
        <Typography component="div">
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <input {...register('printable')} type="hidden" />
                <input {...register('exportable')} type="hidden" />
                <Grid container={true} alignItems="flex-end">
                  <Grid item={true} xs={12}>
                    <FormInput name="name" label={t('formatName')} required={true} />
                  </Grid>
                </Grid>
                <EntityButtons
                  toggleDialog={clearAction}
                  otherStyles={{ marginTop: compactMode ? 8 : 16 }}
                />
              </form>
            </FormProvider>
          </Papeer>
        </Typography>
      ) : (
        <Typography>{t('formatNotFound')}</Typography>
      )}
    </>
  );
};

import { IFilter } from 'components/SearchHeader/_types';
import {
  ASC,
  DESC,
  ENABLED_PROFILE_EDIT_PROFILES,
  SEARCH_FUNCTION_ALL,
  THEME_MODE_DARK,
} from 'constants/constants';
import {
  filter,
  get,
  includes,
  isArray,
  isEmpty,
  isNumber,
  isObject,
  pick,
  toNumber,
} from 'lodash';
import { IModality } from 'modules/Administration/ModalitiesUnitsStations/_types';
import { IPatientDocument } from 'modules/ClinicalPortal/Patient/_types';
import { IValidators } from 'modules/Login/_types';
import { IArchive, IRealisator } from 'modules/Search/_types';
import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { ITimeline } from 'utils/config/_types';
import { getSettingValueForVariable, settingHasValue } from 'utils/products';

export const useAppInfo = () => {
  const validators: IValidators = useAppSelector(({ app }) => app.validators);
  const appProfile = useAppSelector(({ app }) => app.appProfile);
  const lastPageState = useAppSelector(({ app }) => app.lastPageState);
  const archivesForFunctions = useAppSelector(({ app }) => app.archivesForFunctions);
  const isImportUploading = useAppSelector(({ app }) => app.isImportUploading);
  const isImportMdexUploading = useAppSelector(({ app }) => app.isImportMdexUploading);
  const isCstoreUploading = useAppSelector(({ app }) => app.isCstoreUploading);
  const confirmationData = useAppSelector(({ app }) => app.confirmationData);
  const localGridSettings = useAppSelector(({ app }) => app.localGridSettings);
  const gridSettings = useAppSelector(({ app }) => app.gridSettings);
  const hasMiniMenu = useAppSelector(({ app }) => app.hasMiniMenu);
  const compactMode = useAppSelector(({ app }) => app.compactMode);
  const lists = useAppSelector(({ app }) => app.lists);
  const viewer = useAppSelector(({ app }) => app.viewer);
  const orderResults = useAppSelector(({ app }) => app.orderResults);
  const queueResults = useAppSelector(({ app }) => app.queueResults);
  const testResults = useAppSelector(({ app }) => app.testResults);
  const logResults = useAppSelector(({ app }) => app.logResults);
  const searchResults = useAppSelector(({ app }) => app.searchResults);
  const findingReportResults = useAppSelector(({ app }) => app.findingReportResults);
  const trash = useAppSelector(({ app }) => get(app, 'trash', null));
  const structureResults = useAppSelector(({ app }) => app.structureResults);
  const mode = useAppSelector(({ app }) => app.mode);
  const theme = useAppSelector(({ app }) => app.theme);
  const feConfig = useAppSelector(({ app }) => app.feConfig);
  const feConfigFromApi = useAppSelector(({ app }) => app.feConfigFromApi);
  const passwordPolicyConfig = useAppSelector(({ app }) => app.passwordPolicyConfig);
  const searchFilter = useAppSelector(({ app }) => app.searchFilter);
  const tags = useAppSelector(({ app }) => app.tags);
  const newNotifications = useAppSelector(({ app }) => app.newNotifications);
  const isCktch = useAppSelector(({ app }) => app.feConfig?.usedBranding === 'cktch');

  const vocabularyIdentityResults = useAppSelector(({ app }) => app.vocabularyIdentityResults);
  const vocabularyRowResults = useAppSelector(({ app }) => app.vocabularyRowResults);
  const vocabularyKeys = useAppSelector(({ app }) => app.vocabularyKeys);
  const patientDocument = useAppSelector(({ app }) => app.patientDocument);
  const patients = useAppSelector(({ app }) => app.patientResults);
  const requestForFindDocuments = useAppSelector(({ app }) => app.requestSearchResults);
  const searchRequestResults = useAppSelector(({ app }) => app.requestSearchResults);

  const canEditProfile = includes(ENABLED_PROFILE_EDIT_PROFILES, appProfile);

  const realisator: IRealisator[] = get(lists, 'products.realisator', null);
  const modalities: IModality[] = get(lists, 'modalities', []);
  const modNoShow = get(lists, 'modNoShow');
  const statuses: any[] = get(lists, 'statuses', []);
  const filters: IFilter[] = get(lists, 'filters', []);
  const portalProduct = get(lists, 'products.portal', null);
  const portalProductSettings = portalProduct ? get(portalProduct, '[0].systemSetting', []) : [];
  const requestStates: string[] = useMemo(() => get(lists, 'states', []), [lists]);
  const wasRedirected = useAppSelector(({ app }) => app.wasRedirected);
  const getFacilitiesManualLoad = useAppSelector(({ app }) => app.facilitiesManualLoad);
  const documentsFromStore: IPatientDocument[] = useAppSelector(({ app }) => app.patientDocuments);
  const selectedDocuments = useAppSelector(({ app }) => app.selectedDocuments);
  const selectedDocumentsForDocumentDetail = useAppSelector(({ app }) => app.dataForDocumentDetail);
  const columnsInSearchGridWithAdditionalInfoBeingLoaded = useAppSelector(
    ({ app }) => app.columnsInSearchGridWithAdditionalInfoBeingLoaded,
  );
  const mdexDicomPatient = useAppSelector(({ app }) => app.mdexDicomPatient);
  const mdexFiles = useAppSelector(({ app }) => app.mdexFiles);
  const tourControlPropsStore = useAppSelector(({ app }) => app.tourControlPropsStore);
  const use2FA = useAppSelector(({ app }) => app.use2FA);

  let portalSetting = null;

  if (portalProduct) {
    portalSetting = {
      seePerformingPhysician: settingHasValue(
        portalProductSettings,
        'seePerformingPhysician',
        'true',
      ),
      seeReferringPhysician: settingHasValue(
        portalProductSettings,
        'seeReferringPhysician',
        'true',
      ),
      seeRequestingPhysician: settingHasValue(
        portalProductSettings,
        'seeRequestingPhysician',
        'true',
      ),
    };
  }

  const getArchivesForFunctions = (desiredFunction: string): IArchive[] => {
    const archives: IArchive[] = get(archivesForFunctions, desiredFunction);
    return isArray(archives) ? archives : [];
  };

  const getDefaultSources = (desiredFunction: string = SEARCH_FUNCTION_ALL): number[] => {
    try {
      let defaultSources: number[] =
        get(searchFilter, 'sources', []) || get(JSON.parse(searchFilter), 'sources', []);
      if (isEmpty(defaultSources)) {
        // Pokud ještě není předvyplněno z předchozího hledání,
        // přednastaví se defaultní archiv z nastavení archivů
        const sourcesWithDefautAttribut: { name: string; id: number; default: boolean }[] = filter(
          getArchivesForFunctions(desiredFunction).map((archive) =>
            pick(archive, ['name', 'id', 'default']),
          ),
          ['default', true],
        );
        sourcesWithDefautAttribut.forEach((source) => {
          defaultSources.push(source.id);
        });
      }
      return defaultSources;
    } catch (e) {
      return [];
    }
  };

  const hidePreview = get(gridSettings, 'preview.hidePreview', false);

  const timelineOrder: ITimeline =
    useAppSelector(({ app }) => get(app, 'timeline', ASC)) === ASC ? ASC : DESC;

  // mamo portal settings
  const timeToEndWhileAnimatingStr = getSettingValueForVariable(
    portalProductSettings,
    'testSignalizeApproachingEnd',
  );
  const mamoMonitorConfigLink = getSettingValueForVariable(
    portalProductSettings,
    'mamoMonitorConfigLink',
  );
  const mamoMonitorConfigStudyIUID = getSettingValueForVariable(
    portalProductSettings,
    'mamoMonitorConfigStudyIUID',
  );
  const mamoMonitorConfigStudyArchive = getSettingValueForVariable(
    portalProductSettings,
    'mamoMonitorConfigStudyArchive',
  );
  const mamoMonitorConfigPatientID = getSettingValueForVariable(
    portalProductSettings,
    'mamoMonitorConfigPatientID',
  );
  const timeAsNumber = toNumber(timeToEndWhileAnimatingStr);

  return {
    archives: getArchivesForFunctions(SEARCH_FUNCTION_ALL),
    canEditProfile,
    defViewer: viewer,
    realisator,
    modNoShow,
    portalProduct,
    portalProductSettings,
    portalSetting,
    portalSettingChanged: isObject(portalSetting),
    spacing: compactMode ? 1 : 2,
    validators,
    appProfile,
    lastPageState,
    archivesForFunctions,
    isImportUploading,
    isImportMdexUploading,
    isCstoreUploading,
    confirmationData,
    localGridSettings,
    gridSettings,
    hasMiniMenu,
    compactMode,
    hidePreview,
    lists,
    filters,
    isCktch,
    mode,
    feConfig,
    feConfigFromApi,
    passwordPolicyConfig,
    isDarkMode: mode === THEME_MODE_DARK,
    theme,
    getArchivesForFunctions,
    getDefaultSources,
    viewer,
    orderResults,
    queueResults,
    modalities,
    statuses,
    testResults,
    logResults,
    findingReportResults,
    searchResults,
    structureResults,
    vocabularyIdentityResults,
    vocabularyRowResults,
    vocabularyKeys,
    trash,
    requestStates,
    searchRequestResults,
    patientDocument,
    patients,
    requestForFindDocuments,
    tags,
    wasRedirected,
    getFacilitiesManualLoad,
    documentsFromStore,
    selectedDocuments,
    selectedDocumentsForDocumentDetail,
    newNotifications,
    columnsInSearchGridWithAdditionalInfoBeingLoaded,
    timelineOrder,
    mamoMonitorConfig: {
      mamoMonitorConfigLink,
      studyID: mamoMonitorConfigStudyIUID,
      archiveID: mamoMonitorConfigStudyArchive,
      patientId: mamoMonitorConfigPatientID,
      timeToEndWhileAnimating: isNumber(timeAsNumber) ? Number(timeAsNumber) : 30,
    },
    mdexDicomPatient,
    mdexFiles,
    tourControlPropsStore,
    use2FA,
  };
};

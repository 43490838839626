import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { each, get, isArray, keys } from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import { FormProvider, useForm } from 'react-hook-form';
import { parseISO } from 'date-fns';
import { Box, Grid } from '@mui/material';
import { getFindingReport } from './_api';
import {
  IFindingReport,
  IFindingReportSearch,
  IFindingReportsSearch,
  IFindingReportsSearchForm,
} from './_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import FormAutocomplete from 'components/Form/Autocomplete/Autocomplete';
import FormSelect from 'components/Form/Select/Select';
import FormDatePicker from 'components/Form/Date/Date';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import {
  DATE_BTN_CUSTOM_DATE,
  DATE_BTN_LAST_MONTH,
  DATE_BTN_LAST_THREE_DAYS,
  DATE_BTN_LAST_WEEK,
  DATE_BTN_TODAY,
  DATE_BTN_YESTERDAY,
} from 'constants/constants';
import { convertSearchDates } from 'utils/search';
import { Papeer } from 'components/Papeer/Papeer';
import Button from 'components/Buttons/Button';
import { findUser } from 'modules/Administration/Users/_api';
import { getHospitals } from 'modules/Administration/GeneralSettings/_apiHospital';
import { IAutocompleteOption } from 'components/Form/Autocomplete/_types';
import { getItem, putItem } from 'utils/hooks/useStorage';
import { useSearchParams } from 'react-router-dom';
import { useActions } from 'utils/hooks/useActions';

const apiDateFormatWithDash = 'yyyy-MM-dd';
const apiDateFormat = 'yyyyMMdd';

const FindingReportsSearchForm: React.FC<IFindingReportsSearch> = ({ setFindingReports }) => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('FindingReports');
  const { toggleLoader } = useAppGlobals();
  const { compactMode } = useAppInfo();
  let [searchParams] = useSearchParams();
  const { storeFindingReports } = useActions();

  const [hasCustomDate, setHasCustomDate] = useState(false);
  const [users, setUsers] = useState<IAutocompleteOption[]>([]);
  const [hospitals, setHospitals] = useState<IAutocompleteOption[]>([]);

  const dateSelectItems = [
    DATE_BTN_TODAY,
    DATE_BTN_YESTERDAY,
    DATE_BTN_LAST_THREE_DAYS,
    DATE_BTN_LAST_WEEK,
    DATE_BTN_LAST_MONTH,
    DATE_BTN_CUSTOM_DATE,
  ].map((id) => ({ id, label: t(`SearchForm:${id}`) }));

  const methods = useForm<IFindingReportsSearchForm>();
  const { handleSubmit, setValue, watch } = methods;
  const formValues = watch();

  const selectedDate = watch('date');
  const canSubmit = true;

  useEffect(() => {
    if (selectedDate === DATE_BTN_CUSTOM_DATE) {
      setHasCustomDate(true);
    } else {
      setHasCustomDate(false);

      // Set dates if selected date is not custom
      if (selectedDate) {
        const dateAttributes = convertSearchDates(
          selectedDate,
          get(formValues, 'dateFrom'),
          get(formValues, 'dateTo'),
          apiDateFormat,
        );
        setValue('dateFrom', new Date(parseISO(dateAttributes.dateFrom)));
        setValue('dateTo', new Date(parseISO(dateAttributes.dateTo)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const onSubmit = handleSubmit(async (formValues) => {
    toggleLoader();
    setFindingReports([]);
    const formDate = get(formValues, 'date', '');
    const userId = get(formValues, 'user.value', 0);
    const hospitalId = get(formValues, 'hospital.value', 0);
    let values: IFindingReportSearch = {
      ...(userId ? { userId } : {}),
      ...(hospitalId ? { hospitalIds: [hospitalId] } : {}),
    };

    putItem('findingReportsFormValues', JSON.stringify(formValues));

    if (formDate) {
      const dateAttributes = convertSearchDates(
        formDate,
        get(formValues, 'dateFrom', null),
        get(formValues, 'dateTo', null),
        apiDateFormatWithDash,
      );
      values = { ...values, ...dateAttributes };
    }
    const results = await getFindingReport(values);
    if (isArray(results) && results.length) {
      const items = results.map((item: IFindingReport) => ({ ...item, id: uuidv1() }));
      setFindingReports(items);
      storeFindingReports(items);
    }
    toggleLoader(false);
  });

  const loadEntities = async () => {
    toggleLoader();
    try {
      const [users, hospitals] = await Promise.all([findUser({ retired: false }), getHospitals()]);

      if (isArray(users)) {
        setUsers(
          users
            .map((user) => ({
              value: user.id,
              label: `${user.lastName} ${user.firstName}`,
            }))
            .sort((f1: IAutocompleteOption, f2: IAutocompleteOption) =>
              f1.label.toLowerCase().localeCompare(f2.label.toLowerCase()),
            ),
        );
      }
      if (isArray(hospitals)) {
        setHospitals(
          hospitals
            .map((hospital) => ({
              value: hospital.id,
              label: hospital.name,
            }))
            .sort((f1: IAutocompleteOption, f2: IAutocompleteOption) =>
              f1.label.toLowerCase().localeCompare(f2.label.toLowerCase()),
            ),
        );
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    const isBack = (searchParams.get('action') || null) === 'back';
    if (isBack) {
      const formValues = getItem('findingReportsFormValues');
      const values = formValues ? JSON.parse(formValues) : {};
      each(keys(values), (key: any) => {
        setValue(key, get(values, key));
      });
    }
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainSx = { width: { xs: '100%', md: '50%', lg: '20%' } };

  return (
    <Papeer bottomMargin={true} sx={{ pt: compactMode ? 0 : 1 }}>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <button hidden={true} ref={refSubmitButtom} type={'submit'} />
          <Box
            sx={{
              display: 'flex',
              flexWrap: {
                xs: 'wrap',
                lg: 'nowrap',
              },
              paddingRight: { xs: '0px', sm: 0 },
            }}
            data-tour="search-header-searchForm-searchFormFields"
          >
            <Box sx={mainSx} data-tour="request-requestingUser">
              <FormAutocomplete
                name="user"
                label={t('user')}
                options={users}
                placeholder={t('user')}
                sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
            </Box>
            <Box sx={mainSx} data-tour="request-operationType">
              <FormAutocomplete
                name="hospital"
                label={t('hospital')}
                options={hospitals}
                placeholder={t('hospital')}
                sx={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              />
            </Box>
            <Box sx={mainSx}>
              <FormSelect
                name="date"
                label={t('studyDate')}
                items={dateSelectItems}
                sx={{ borderRadius: 0 }}
                clearable={true}
                clearCallback={() => {
                  setValue('dateFrom', null);
                  setValue('dateTo', null);
                }}
              />
            </Box>
            <Box sx={mainSx}>
              <FormDatePicker
                name="dateFrom"
                label={t('SearchForm:from')}
                sx={{ borderRadius: 0 }}
                disabled={!hasCustomDate}
              />
            </Box>
            <Box sx={mainSx}>
              <FormDatePicker
                name="dateTo"
                label={t('SearchForm:to')}
                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                disabled={!hasCustomDate}
              />
            </Box>
          </Box>
          <Grid container={true} spacing={compactMode ? 1 : 2}>
            <Grid item={true} xs={12} md={9} />
            <Grid item={true} xs={12} md={3}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="medium"
                sx={{ float: 'right' }}
                disabled={!canSubmit}
                data-tour="finding-report-search"
              >
                {t('search')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Papeer>
  );
};

export default FindingReportsSearchForm;

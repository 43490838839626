import { get, includes } from 'lodash';
import { IRequest } from 'modules/Requests/_types';
import { IUser } from 'modules/Login/_types';
import {
  OPERATION_TYPE_SEND,
  OPERATION_TYPE_REORDER,
  OPERATION_TYPE_SPLIT,
  OPERATION_TYPE_MOVE,
  OPERATION_TYPE_COPY,
  OPERATION_TYPE_EDIT_STUDY,
  OPERATION_TYPE_EDIT_PATIENT,
  OPERATION_TYPE_MOVE_TO_FOLDER,
  OPERATION_TYPE_MOVE_TO_TRASH,
} from 'constants/constants';

export const requestHasStatus = (request: IRequest, status: string) =>
  get(request, 'status') === status;
export const requestStatusIncludes = (request: IRequest, statuses: string[]) =>
  includes(statuses, get(request, 'status'));
export const userHasIssuedRequest = (request: IRequest, user: IUser) =>
  get(request, 'requestedBy.username') === get(user, 'sub');
export const userHasRightForRequestOperationType = (request: IRequest, hasRole: any) => {
  const operationType = get(request, 'operationType');
  return (
    (operationType === OPERATION_TYPE_SEND && hasRole('ROLE_REQUEST_SEND_PROCESS')) ||
    (operationType === OPERATION_TYPE_REORDER && hasRole('ROLE_REQUEST_REORDER_PROCESS')) ||
    (operationType === OPERATION_TYPE_SPLIT && hasRole('ROLE_REQUEST_SPLIT_PROCESS')) ||
    (operationType === OPERATION_TYPE_EDIT_STUDY && hasRole('ROLE_REQUEST_UPDATE_PROCESS_STUDY')) ||
    (operationType === OPERATION_TYPE_EDIT_PATIENT &&
      hasRole('ROLE_REQUEST_UPDATE_PROCESS_PATIENT')) ||
    (operationType === OPERATION_TYPE_MOVE && hasRole('ROLE_REQUEST_MOVE_PROCESS')) ||
    (operationType === OPERATION_TYPE_COPY && hasRole('ROLE_REQUEST_COPY_PROCESS')) ||
    (operationType === OPERATION_TYPE_MOVE_TO_FOLDER &&
      hasRole('ROLE_REQUEST_PROCESS_MOVE_TO_FOLDER')) ||
    (operationType === OPERATION_TYPE_MOVE_TO_TRASH &&
      hasRole('ROLE_REQUEST_PROCESS_MOVE_TO_TRASH'))
  );
};
export const hasNameRoleForRequestOperationType = (operationType: any) => {
  switch (operationType) {
    case OPERATION_TYPE_SEND:
      return ['ROLE_REQUEST_SEND_EPACS', 'ROLE_REQUEST_SEND_REDIMED'];
    case OPERATION_TYPE_REORDER:
      return 'ROLE_REQUEST_REORDER';
    case OPERATION_TYPE_SPLIT:
      return 'ROLE_REQUEST_SPLIT';
    case OPERATION_TYPE_EDIT_STUDY:
      return 'ROLE_REQUEST_UPDATE_STUDY';
    case OPERATION_TYPE_EDIT_PATIENT:
      return 'ROLE_REQUEST_UPDATE_PATIENT';
    case OPERATION_TYPE_MOVE:
      return 'ROLE_REQUEST_MOVE';
    case OPERATION_TYPE_COPY:
      return 'ROLE_REQUEST_COPY';
    case OPERATION_TYPE_MOVE_TO_FOLDER:
      return 'ROLE_REQUEST_MOVE_TO_FOLDER';
    case OPERATION_TYPE_MOVE_TO_TRASH:
      return 'ROLE_REQUEST_MOVE_TO_TRASH';
  }
};

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import {
  filter,
  find,
  get,
  includes,
  isArray,
  isEmpty,
  isString,
  join,
  omit,
  pick,
  sortBy,
} from 'lodash';
import { AppBar, Button, Grid, Tab, Tabs } from '@mui/material';

import { IUserFormResponse, IUser } from './_types';
import { ILanguage } from '../GeneralSettings/_types';
import { IProduct } from '../Products/_types';
import { IWorkplace } from '../Workplaces/_types';
import { getAvailableViewers, getUserSetting, editUserAndUserSettings } from './_api';
import { getAllLanguages } from '../GeneralSettings/_apiLanguages';
import { getAuthConfig } from 'modules/Login/_api';
import { Papeer } from 'components/Papeer/Papeer';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { useToken } from 'utils/hooks/useToken';
import SaveIcon from '@mui/icons-material/Save';
import { useLanguage } from 'utils/hooks/useLanguage';

import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import { ISelectItem } from 'components/Form/Select/_types';
import FormSwitch from 'components/Form/Switch/Switch';

import useValidationSchema from './_form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridDataType } from 'constants/constants';
import Header from 'components/Header/Header';
import UserProfileButtonsComponent from './UserProfileButtons';
import UserProfileNotificationsComponent from './UserProfileNotifications';
import UserProfileFacilitiesComponent from './UserProfileFacilities';
import FormDatePicker from 'components/Form/Date/Date';
import useAlerts from 'components/Alerts/useAlerts';
import { Box } from '@mui/system';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { Announcement } from 'components/Announcement/Announcement';
import { useRulesForPassword } from 'utils/hooks/useRulesForPassword';
import UserProfile2FASettings from './UserProfile2FASettings';

export const UserProfile: React.FC = () => {
  const { t } = useTranslation('Users');
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const navigate = useNavigate();
  const { user, authType } = useUser();
  const username = get(user, 'sub', null);
  const { currentLocale } = useLanguage();
  const [entity, fetchEntity] = useState<IUser>();
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>('buttons');
  const [canEditProfile, setCanEditProfile] = useState<boolean>(false);
  const { refreshTokenAndStore } = useToken();

  const { UserProfileFormSchema, formItems } = useValidationSchema(t, canEditProfile);

  const methods = useForm<any>({
    resolver: yupResolver(UserProfileFormSchema),
  });
  const { handleSubmit, reset } = methods;

  const { toggleLoader, toggleDarkMode } = useAppGlobals();

  const [viewerItems, setViewerItems] = useState<ISelectItem[]>([]);
  const [languageItems, setLanguageItems] = useState<ISelectItem[]>([]);

  //TODO načíst všechny služby přes Promise.all
  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const user = {
      ...pick(values, [
        'id',
        'username',
        'lastName',
        'password',
        'confirmPassword',
        'middleName',
        'prefix',
        'suffix',
        'darkMode',
        'rememberLastLogin',
        'enabled',
      ]),
      id: get(entity, 'id', null),
      firstName: get(values, 'firstName', ''),
      email: get(values, 'email', ''),
      icp: get(values, 'icp', ''),
      language: { id: get(values, 'language') || null },
      viewer: { id: get(values, 'viewer') || null },
    };
    if (user.password !== get(user, 'confirmPassword')) {
      addErrorAlert(t('error.invalidPassword'));
    } else {
      const response: IUserFormResponse = await editUserAndUserSettings(
        {
          ...omit(
            user,
            user.password === '' ? ['password', 'confirmPassword'] : ['confirmPassword'],
          ),
        },
        authType,
      );
      if (response.success) {
        toggleDarkMode(get(values, 'darkMode', false));
        addSuccessAlert(t('saved'));
        await refreshTokenAndStore();
      } else {
        const errorCode = get(response, 'msg.errorCode', '');
        const tError = includes(
          [
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
          ],
          errorCode,
        )
          ? `Login:error.passwordRecovery.${errorCode}`
          : response.msg && isString(response.msg)
          ? response.msg
          : 'error.generalError';
        addErrorAlert(t(tError));
      }
    }

    toggleLoader(false);
  });

  const getEntities = async () => {
    toggleLoader();
    try {
      if (!username) {
        navigate('/');
      }
      const viewersLists: IProduct[] = await getAvailableViewers();
      if (isArray(viewersLists)) {
        const items: any = viewersLists.map((item: IProduct) => ({
          id: get(item, 'id'),
          label: item.name,
        }));
        setViewerItems(items);
      }

      const languagesLists: ILanguage[] = await getAllLanguages();
      if (isArray(languagesLists)) {
        const items: any = filter(languagesLists, { active: true }).map((item: ILanguage) => ({
          id: get(item, 'id'),
          label: item.name,
        }));
        setLanguageItems(items);
      }

      const authConfig = await getAuthConfig();
      if (authConfig) {
        const allowedAuthMethods = get(authConfig, 'allowedAuthMethods', []);
        const activetabAllowedMethod = find(allowedAuthMethods, (config) => {
          return get(config, 'id') === authType;
        });
        setCanEditProfile(get(activetabAllowedMethod, 'canManage', false));
      }

      const entity: any = await getUserSetting(username as string);
      if (!isEmpty(entity)) {
        fetchEntity(entity);
        reset({
          username: get(entity, 'username', null),
          lastName: get(entity, 'lastName', null),
          firstName: get(entity, 'firstName', null),
          middleName: get(entity, 'middleName', null),
          prefix: get(entity, 'prefix', null),
          suffix: get(entity, 'suffix', null),
          email: get(entity, 'email', null),
          icp: get(entity, 'icp', null),
          viewer: get(entity, 'viewer.id', null),
          language: get(entity, 'language.id', null),
          darkMode: get(entity, 'darkMode', false),
          pomWorkPlaces: isArray(get(entity, 'workPlaces', null))
            ? sortBy(
                get(entity, 'workPlaces', null).map((workPlace: IWorkplace) =>
                  get(
                    get(workPlace, 'text', false) ? JSON.parse(get(workPlace, 'text', '')) : {},
                    currentLocale,
                    workPlace.code,
                  ),
                ),
              ).join(', ')
            : null,
        });
      }
    } catch (e) {
      console.debug(e);
    }
    setIsFetchingEntity(false);
    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultItems = {
    viewer: viewerItems,
    language: languageItems,
  };

  const defaultGrid: GridDataType = { xs: 12, md: 6, lg: 3, xl: 2 };
  const { compactMode, use2FA } = useAppInfo();
  const { minimalPasswordLength, passwordMustContain, passwordPolicyEnabled } =
    useRulesForPassword();

  return (
    <>
      <div>
        <Header title={t('userProfile')} />
        {passwordPolicyEnabled && (
          <Box sx={{ pb: compactMode ? 1 : 2 }}>
            <Announcement
              label=""
              type="info"
              spaced={true}
              component={'ul'}
              sx={{ listStyle: 'none' }}
            >
              <li>
                {t('Login:ruleForPasswordRecovery.minimalPasswordLength', {
                  par1: minimalPasswordLength,
                })}
              </li>
              {passwordMustContain.length > 0 && (
                <li>
                  {t('Login:ruleForPasswordRecovery.mustContain', {
                    par1: join(passwordMustContain, ', '),
                  })}
                </li>
              )}
            </Announcement>
          </Box>
        )}
        <Papeer loading={isFetchingEntity}>
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <Grid container={true} spacing={compactMode ? 1 : 2}>
                {formItems.map((formItem, index) => {
                  const customGrid = get(formItem, 'grid', false)
                    ? (get(formItem, 'grid') as GridDataType)
                    : defaultGrid;
                  const type = get(formItem, 'type');
                  const name = get(formItem, 'name', '');
                  const items: ISelectItem[] = get(defaultItems, name, []);
                  const isRequired = get(formItem, 'required', false);
                  const isDisabled = get(formItem, 'disabled', false);
                  const label = get(formItem, 'label', '');
                  return (
                    <Grid key={`formItem-${index}`} item={true} {...customGrid}>
                      {type === 'input' ? (
                        <FormInput
                          name={name}
                          label={label}
                          type={get(formItem, 'inputType', 'text')}
                          required={isRequired}
                          disabled={isDisabled}
                          {...{
                            autoComplete: get(formItem, 'autoComplete'),
                          }}
                        />
                      ) : null}
                      {type === 'select' ? (
                        <FormSelect
                          name={name}
                          label={label}
                          required={isRequired}
                          items={items}
                          disabled={isDisabled}
                        />
                      ) : null}
                      {type === 'switch' ? (
                        <FormSwitch name={name} label={label} disabled={isDisabled} />
                      ) : null}
                      {type === 'date' ? (
                        <FormDatePicker name={name} label={label} disabled={isDisabled} />
                      ) : null}
                    </Grid>
                  );
                })}
              </Grid>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  data-selenium-selector="user-profile-button"
                  sx={{ mt: 1 }}
                >
                  <SaveIcon sx={{ mr: 1 }} />
                  {t('save')}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Papeer>
      </div>
      <AppBar position="static" color="default" sx={{ mt: 2, mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={false}
        >
          <Tab value="buttons" label={t('userProfileButtons')} />
          <Tab value="notifications" label={t('userProfileNotifications')} />
          <Tab value="facilities" label={t('userProfileFacilities')} />
          {use2FA && <Tab value="2faSetting" label={t('userProfile2FASetting')} />}
        </Tabs>
      </AppBar>

      {activeTab === 'buttons' && <UserProfileButtonsComponent />}
      {activeTab === 'notifications' && <UserProfileNotificationsComponent />}
      {activeTab === 'facilities' && <UserProfileFacilitiesComponent />}
      {use2FA && entity && activeTab === '2faSetting' && (
        <UserProfile2FASettings userSetting={entity} />
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  compact,
  filter,
  find,
  get,
  includes,
  isArray,
  isEmpty,
  isEqual,
  omit,
  pick,
  sortBy,
  sum,
} from 'lodash';
import { Box } from '@mui/system';
import { DialogContentText, Grid } from '@mui/material';

import { IStudiesDistributeDetail, IStudiesDistributeForm } from './_types';
import useValidationSchema from './_form';
import {
  createRequestForDistribute,
  distributeStudy,
  editRequestForDistribute,
  getPatientMatching,
} from './_api';
import { getValuesFromDicomFile } from '../StudyDetail/_api';
import { getProductsByTypeAndFunction } from 'modules/Administration/Products/_api';

import RequestAdditionalForm from '../Form/RequestAdditionalForm';
import AnonymizationForm from '../Anonymization/AnonymizationForm';
import PatientAndStudyEditForm from '../Form/PatientAndStudyEditForm';

import Header from 'components/Header/Header';
import FormSelect from 'components/Form/Select/Select';
import FormSwitch from 'components/Form/Switch/Switch';
import useAlerts from 'components/Alerts/useAlerts';
import { RequestDialog } from 'components/RequestDialog/RequestDialog';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { Papeer } from 'components/Papeer/Papeer';
import { useSeriesViewer } from 'components/SeriesViewer/useSeriesViewer';
import { SeriesViewer } from 'components/SeriesViewer/SeriesViewer';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { generateIID, isPatientMatching, sortSeriesBySeriesNumber } from 'utils/study';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { settingHasValue } from 'utils/products';
import { formatDateForSearch } from 'utils/search';
import { formatAnonymizedValues, identifyValues } from 'utils/anonymization';

import {
  MOVE,
  COPY,
  SEARCH_FUNCTION_MOVE,
  SEARCH_FUNCTION_COPY,
  ARCHIVE_WITH_DATA_INPUT_ALLOWED,
  SELECT_ORDER_TO_EDIT_STUDY_IN_DISTRIBUTE,
} from 'constants/constants';
import { IArchive } from 'modules/Search/_types';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourStudyDistribute } from './TourStudyDistribute';
import { getAreAllSeriesSelected } from 'utils/getAreAllSeriesSelected';
import Button from 'components/Buttons/Button';

const apiDateFormat = 'yyyy-MM-dd';

const StudiesDistributeDetail: React.FC<IStudiesDistributeDetail> = ({
  isRequest = false,
  isRequestEdit = false,
  request,
  requestAction,
}) => {
  const { t } = useTranslation('Studies');
  useWithTitle(); // sets title to document
  const { toggleLoader } = useAppGlobals();
  const { hasRole, user } = useUser();

  const location = useLocation();
  const action = requestAction
    ? requestAction
    : includes(get(location, 'pathname', ''), MOVE)
    ? MOVE
    : COPY;

  let [searchParams] = useSearchParams();
  const detailIID = searchParams.get('backTo') || null;
  const linkBack = detailIID ? `/study/${detailIID}` : `/${isRequest ? 'requests' : 'studies'}`;

  const { archives: products, validators, portalSetting } = useAppInfo();

  const reduxArchives: IArchive[] = products || [];

  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { moveStore, copyStore } = useStudyInfo();
  const studiesFromStore = action === MOVE ? moveStore : copyStore;

  const [canMove, setCanMove] = useState<boolean>(false);
  const [isSourceForUpdate, setIsSourceForUpdate] = useState<boolean>(false);
  const [isTargetForUpdate, setIsTargetForUpdate] = useState<boolean>(false);
  const [archives, setArchives] = useState<IArchive[]>([]);
  const [initVal, setInitVal] = useState<any>(null);
  const [anonymizationProfile, setAnonymizationProfile] = useState<any>({});
  const [anonymizationProfiles, setAnonymizationProfiles] = useState<any[]>([]);
  const [anonymization, setAnonymization] = useState<string>('0');
  const [titleText, setTitleText] = useState<string>('');
  const [dicomData, setDicomData] = useState<any>(null);
  const [fullSelectedStudies, setFullSelectedStudies] = useState<any>(false);
  const [numberOfSelectedStudies, setNumberOfSelectedStudies] = useState<number>(0);
  const [numberOfSelectedSeries, setNumberOfSelectedSeries] = useState<number>(0);
  const [orderDialog, setOrderDialog] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [disableAnonymization, setDisableAnonymization] = useState<boolean>(false);
  const [anonymizationFields, setAnonymizationFields] = useState<any[]>([]);

  const navigate = useNavigate();

  const { studiesForViewer, setStudiesForViewer, operations, somethingSelected } =
    useSeriesViewer();

  const { StudiesDistributeFormSchema } = useValidationSchema(
    t,
    anonymizationFields,
    get(validators, 'editForm', {}),
    portalSetting,
    showEditForm,
  );

  const prepareInitVal = (initVal: any) => {
    const prepareInitialValues = {
      ...(initVal
        ? {
            ...initVal,
            ...get(initVal, 'anonymized', {}),
            requestComment: get(watchAllFields, 'requestComment', ''),
          }
        : {
            ...watchAllFields,
            ...(isRequestEdit && anonymizationWatch === 'update'
              ? get(initVal, 'anonymized', {})
              : {
                  anonymized_lastName: '',
                  anonymized_firstName: '',
                  anonymized_middleName: '',
                  anonymized_prefix: '',
                  anonymized_suffix: '',
                  anonymized_PatientID: '',
                }),
          }),

      requestingUser: {
        name: {
          lastName: get(user, 'last_name'),
          firstName: get(user, 'first_name'),
        },
      },
    };
    return prepareInitialValues;
  };
  const callReset = (initVal: any) => {
    reset(initVal);
  };

  const methods = useForm<IStudiesDistributeForm>({
    resolver: yupResolver(StudiesDistributeFormSchema),
    mode: 'onChange',
  });
  const { handleSubmit, reset, watch, register, setValue, trigger } = methods;

  const watchAllFields = watch();

  const anonymizationWatch = get(
    watchAllFields,
    'anonymization',
    isRequestEdit ? get(request, 'data.deidentifyProfile.name', '0') : '0',
  );

  const productIdTo = get(watchAllFields, 'productIdTo', null);

  const handleFormSubmit = async (formValues: any) => {
    toggleLoader();
    const requestComment = get(formValues, 'requestComment', null) || null;
    const editStudy = isSourceForUpdate && isTargetForUpdate ? true : false;
    const preparedStudies = compact(fullSelectedStudies).map((study: any) => ({
      ...study,
      listOfSeries: compact(get(study, 'listOfSeries', null)),
    }));

    let values = { ...formValues, studies: preparedStudies };

    if (editStudy && get(formValues, 'showEditForm', false) && studiesForViewer.length === 1) {
      const date = get(formValues, 'patient.dateBirth', null);
      values = {
        ...formValues,
        studyDate: formatDateForSearch(
          get(formValues, `${isRequest ? 'study.' : ''}studyDate`, undefined),
          'yyyyMMdd',
        ),
        studyTime: formatDateForSearch(
          get(formValues, `${isRequest ? 'study.' : ''}studyTime`, undefined),
          'HH:mm:ss',
        ),
        patient: {
          ...get(formValues, 'patient', {}),
          dateBirth: date ? formatDateForSearch(date, apiDateFormat) : '',
        },
        patientDispositions: get(formValues, 'patientDispositions', {})
          ? get(formValues, 'patientDispositions', {})
          : {},
        updating: get(formValues, 'showEditForm', false),
      };
    }
    values =
      editStudy && get(values, 'showEditForm', false) && studiesForViewer.length === 1
        ? omit(values, ['showEditForm'])
        : omit(values, [
            'showEditForm',
            'accessionNumber',
            'patient',
            'referringPhysician',
            'requestingPhysician',
            'performingPhysician',
            'studyDate',
            'studyTime',
            'studyDescription',
            'patientDispositions',
          ]);
    const fn = isRequest ? createRequestForDistribute : distributeStudy;
    try {
      const resp = await fn({ ...values, requestComment });
      if (resp) {
        addSuccessAlert(t(isRequest ? `requestFor.${action}.added` : `submit.${action}.ok`));
        navigate(`/${isRequest ? 'requests' : 'studies'}`);
      } else {
        addErrorAlert(t(isRequest ? `errorCreatingRequest` : `submit.${action}.error`));
      }
    } catch (e) {
      addErrorAlert(t(isRequest ? `errorCreatingRequest` : `submit.${action}.error`));
    }
    toggleLoader(false);
  };

  const handleRequestEdit = async (values: any) => {
    toggleLoader();
    const requestComment = get(values, 'requestComment', null) || null;
    const preparedStudies = compact(fullSelectedStudies).map((study: any) => ({
      ...study,
      listOfSeries: compact(get(study, 'listOfSeries', [])),
    }));
    const deidentifyProfile = get(values, 'deidentifyProfile', null);
    const newRequest = {
      ...request,
      data: {
        ...omit(request.data, ['productTo']),
        ...values,
        studyDate: formatDateForSearch(get(values, `studyDate`, undefined), 'yyyyMMdd'),
        studyTime: formatDateForSearch(get(values, `studyTime`, undefined), 'HH:mm:ss'),
        studies: fullSelectedStudies,
        deidentifyProfile,
      },
      requestComment,
      updating: get(values, 'showEditForm', false),
    };
    let numberOfChanges = 0;
    let atts = ['productIdTo', 'deidentifyProfile'];
    if (get(values, 'showEditForm', false)) {
      atts = [
        ...atts,
        'patient.name',
        'patient.dateBirth',
        'patient.identificationNumber',
        'patient.sex',
        'requestingPhysician',
        'referringPhysician',
        'performingPhysician',
        'accessionNumber',
        'studyDescription',
        'studyDate',
        'studyTime',
        'patientDispositions',
      ];
    }
    atts.forEach((att) => {
      if (!isEqual(get(initVal, att, null), get(values, att, null))) {
        ++numberOfChanges;
      }
    });
    if (!isEmpty(requestComment)) {
      ++numberOfChanges;
    }

    const sourceListOfStudies = get(initVal, 'studies', []).map((study: any) => {
      const series = get(study, 'listOfSeries', null);
      const listOfSeries = isArray(series)
        ? sortBy(
            series.map((serie: any) => pick(serie, ['uid'])),
            ['uid'],
          )
        : null;
      return { ...pick(study, ['studyInstanceUid', 'archive']), listOfSeries };
    });

    const targetListOfStudies = preparedStudies.map((study: any) => {
      const listOfSeries = sortBy(
        get(study, 'listOfSeries', []).map((serie: any) => pick(serie, ['uid'])),
        ['uid'],
      );
      return { ...pick(study, ['studyInstanceUid', 'archive']), listOfSeries };
    });

    if (!isEqual(sourceListOfStudies, targetListOfStudies)) {
      ++numberOfChanges;
    }
    if (numberOfChanges === 0) {
      addErrorAlert(t('errorNoChange'));
    } else {
      await editRequestForDistribute(newRequest).then(
        (response) => {
          addSuccessAlert(t(`requestFor.${action}.updated`));
          navigate(`/requests`);
        },
        (error) => {
          addErrorAlert(t(`submit.${action}.error`));
        },
      );
    }
    toggleLoader(false);
  };

  const callHandleSubmit = async (values: any) => {
    const preparedValues = formatAnonymizedValues(values, anonymizationProfile);
    isRequestEdit ? handleRequestEdit(preparedValues) : handleFormSubmit(preparedValues);
  };

  const onSubmit = handleSubmit(async (values: IStudiesDistributeForm) => {
    callHandleSubmit(values);
  });

  const getInitialValues = (studies: any, archives: any) => {
    let anonymization = '0';
    let anonymized = null;
    let deidentifyProfile = null;
    if (isRequestEdit) {
      deidentifyProfile = get(request, 'data.deidentifyProfile');
      const deidentifyProfileName = get(deidentifyProfile, 'name', null);
      if (
        (deidentifyProfileName === 'update' ||
          includes(deidentifyProfileName, 'cleanDescriptions')) &&
        !isEmpty(anonymizationProfiles)
      ) {
        anonymization = deidentifyProfileName;
        setAnonymization(anonymization);
        const profile = find(anonymizationProfiles, ['name', deidentifyProfileName]);
        if (profile) {
          setAnonymizationProfile(profile);
        }
        if (deidentifyProfileName === 'update') {
          anonymized = identifyValues(get(deidentifyProfile, 'values', null), profile);
        }
      }
    }
    const archivesLength = isArray(archives) ? archives.length : 0;
    let study = get(studies, '[0]');
    if (isRequestEdit) {
      study = {
        ...study,
        patient: get(request, 'data.patient'),
        ...pick(request.data, [
          'requestingPhysician',
          'referringPhysician',
          'performingPhysician',
          'accessionNumber',
          'studyDescription',
          'studyDate',
          'studyTime',
          'patientDispositions',
        ]),
        updating: get(request, 'updating'),
      };
    }
    const dateTime = get(study, 'dateTime', null);
    const dateBirth = get(study, 'patient.dateBirth', null);
    const initVal = {
      distributeMethod: action.toUpperCase(),
      productIdTo: isRequestEdit
        ? get(request, 'data.productIdTo')
        : archivesLength === 1
        ? get(archives, '[0].id', null)
        : null,
      studies,
      patient: {
        ...get(study, 'patient', {}),
        dateBirth: dateBirth ? new Date(dateBirth) : null,
      },
      studyDescription: get(study, 'studyDescription', ''),
      study: {
        studyDate: dateTime ? new Date(dateTime) : null,
        studyTime: dateTime ? new Date(dateTime) : null,
      },
      studyDate: isRequestEdit
        ? get(request, 'data.studyDate', null)
          ? new Date(get(request, 'data.studyDate', null))
          : null
        : null,
      studyTime: isRequestEdit
        ? get(request, 'data.studyDate', null) && get(request, 'data.studyTime', null)
          ? new Date(
              `${get(request, 'data.studyDate', null)}T${get(request, 'data.studyTime', null)}`,
            )
          : null
        : null,
      accessionNumber: get(study, 'accessionNumber', ''),
      referringPhysician: get(study, 'referringPhysician', ''),
      requestingPhysician: get(study, 'requestingPhysician', ''),
      performingPhysician: get(study, 'performingPhysician', ''),
      patientDispositions: get(study, 'patientDispositions', ''),
      showEditForm: get(study, 'updating', false),
      ...(isRequestEdit ? { deidentifyProfile, anonymized } : {}),
      anonymization,
    };
    return initVal;
  };

  const loadStart = async () => {
    const studies = studiesForViewer;
    const studyArchiveId = get(studies, '[0].archive.id', null);
    const studyID = get(studies, '[0].studyInstanceUid', null);
    if (studyArchiveId && studyID && !isEmpty(reduxArchives)) {
      let sourceArchive = null;
      let isSourceForUpdate = false;
      if (hasRole('ROLE_UPDATE_IN_DISTRIBUTE') && studyArchiveId > 0 && isArray(reduxArchives)) {
        sourceArchive = find(reduxArchives, ['id', parseInt(studyArchiveId, 10)]);
        if (sourceArchive !== undefined) {
          if (
            settingHasValue(
              get(sourceArchive, 'systemSetting', []),
              action === COPY ? 'canBeSourceForUpdateInCopy' : 'canBeSourceForUpdateInMove',
              'true',
            )
          ) {
            if (studies.length === 1) {
              isSourceForUpdate = true;
            } else {
              // Pokud je vice vysetreni, zkontrolovat, zda se shoduji (patientMatching)
              const patientMatching = await getPatientMatching();
              isSourceForUpdate = isPatientMatching(studies, patientMatching);
            }
          }
        }
      }
      setIsSourceForUpdate(isSourceForUpdate);

      const archives: IArchive[] | false = await getProductsByTypeAndFunction(
        'archive',
        action === MOVE ? SEARCH_FUNCTION_MOVE : SEARCH_FUNCTION_COPY,
      );
      if (archives && isArray(archives)) {
        const unusedArchives = filter(
          // First filter archives with dataInputAllowed 'true' value
          filter(archives, (product: IArchive) =>
            settingHasValue(
              get(product, 'systemSetting', []),
              ARCHIVE_WITH_DATA_INPUT_ALLOWED,
              'true',
            ),
          ),
          // Then filter archives not used in current study
          (allowedArchive) => allowedArchive.id !== studyArchiveId,
        );
        setArchives(unusedArchives);
      }
      toggleLoader(false);
      if (isSourceForUpdate) {
        setDicomData(null);
        getValuesFromDicomFile(parseInt(studyArchiveId, 10), studyID, {
          REFERRING_PHYSICIAN_NAME: 'NAME',
          REQUESTING_PHYSICIAN_NAME: 'NAME',
          PERFORMING_PHYSICIAN_NAME: 'NAME',
          PATIENT_WEIGHT: 'STRING',
          PATIENT_HEIGHT: 'STRING',
        }).then((dicomData: any) => {
          if (dicomData) {
            const physician = {
              firstName: '',
              lastName: '',
              middleName: '',
              prefix: '',
              suffix: '',
            };
            const performingPhysician = get(dicomData, 'PERFORMING_PHYSICIAN_NAME')
              ? JSON.parse(get(dicomData, 'PERFORMING_PHYSICIAN_NAME'))
              : null;
            const referringPhysician = get(dicomData, 'REFERRING_PHYSICIAN_NAME')
              ? JSON.parse(get(dicomData, 'REFERRING_PHYSICIAN_NAME'))
              : null;
            const requestingPhysician = get(dicomData, 'REQUESTING_PHYSICIAN_NAME')
              ? JSON.parse(get(dicomData, 'REQUESTING_PHYSICIAN_NAME'))
              : null;
            const dicomData2 = {
              patientWeightAndSize: {
                size: get(dicomData, 'PATIENT_HEIGHT', null),
                weight: get(dicomData, 'PATIENT_WEIGHT', null),
              },
              performingPhysician: { ...physician, ...performingPhysician },
              referringPhysician: { ...physician, ...referringPhysician },
              requestingPhysician: { ...physician, ...requestingPhysician },
            };
            setDicomData(dicomData2);
          }
        });
      }
    }
  };

  useEffect(() => {
    const titleText = t(
      isRequestEdit
        ? `studyAction.requestEdit.${action}`
        : isRequest
        ? `studyAction.request.${action}`
        : `study_${action}`,
    );
    setTitleText(titleText);
    const studies: any[] = isRequestEdit
      ? get(request, 'data.studies', []).map((study: any) => {
          return {
            ...study,
            iid: generateIID(study),
            listOfSeries: sortSeriesBySeriesNumber(get(study, 'listOfSeries', null)),
          };
        })
      : studiesFromStore;
    if (!isRequestEdit && studies.length < 1) {
      return navigate(linkBack);
    }

    setStudiesForViewer(
      studies.map((study: any) => ({
        ...study,
        studyIsSelected: true,
        studyIsOpened: false,
        loadedSeries: [],
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(reduxArchives) && !isEmpty(studiesForViewer)) {
      loadStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxArchives, studiesForViewer.length]);

  useEffect(() => {
    callReset(prepareInitVal(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anonymizationWatch]);

  useEffect(() => {
    const canMove: boolean = somethingSelected > 0 && productIdTo ? true : false;
    setCanMove(canMove);
    let isTargetForUpdate = false;
    if (productIdTo !== null) {
      if (hasRole('ROLE_UPDATE_IN_DISTRIBUTE') && productIdTo > 0 && isArray(reduxArchives)) {
        const targetArchive = find(reduxArchives, ['id', parseInt(productIdTo, 10)]);
        if (targetArchive !== undefined) {
          if (
            settingHasValue(
              get(targetArchive, 'systemSetting', []),
              action === COPY
                ? 'canBeDestinationForUpdateInCopy'
                : 'canBeDestinationForUpdateInMove',
              'true',
            )
          ) {
            isTargetForUpdate = true;
          }
        }
      }
    }
    setIsTargetForUpdate(isTargetForUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIdTo, somethingSelected]);

  useEffect(() => {
    const fullSelectedStudies = compact(
      studiesForViewer.map((study: any) => {
        if (!study.studyIsSelected) {
          return false;
        }
        const areAllSeriesSelected = getAreAllSeriesSelected({ study });
        let studyWithSeries = { ...study }; // Create a shallow copy of study

        if (study?.listOfSeries?.length > 0) {
          studyWithSeries.listOfSeries = study.listOfSeries;
        }
        if (study?.loadedSeries?.length > 0) {
          studyWithSeries.listOfSeries = areAllSeriesSelected
            ? []
            : compact(
                study.loadedSeries.map((serie: any) => {
                  if (!serie.serieIsSelected) {
                    return false;
                  }
                  return serie;
                }),
              );
        }
        return studyWithSeries;
      }),
    );

    const numberOfSelectedSeries = fullSelectedStudies.map((study) => {
      let numberOfSelectedSeriesSum = 0;
      const selectedSeriesByStudy = compact(get(study, 'listOfSeries', [])).length;
      const seriesByStudy =
        selectedSeriesByStudy > 0
          ? selectedSeriesByStudy
          : get(study, 'numberOfStudyRelatedSeries', 0);
      numberOfSelectedSeriesSum += seriesByStudy * 1;
      return numberOfSelectedSeriesSum;
    });
    setNumberOfSelectedStudies(fullSelectedStudies.length);
    setNumberOfSelectedSeries(sum(numberOfSelectedSeries));
    setFullSelectedStudies(fullSelectedStudies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [somethingSelected, studiesForViewer]);

  useEffect(() => {
    if (!isRequestEdit || isRequestEdit === undefined) {
      setValue('patientDispositions', get(dicomData, 'patientWeightAndSize', null));
      setValue('performingPhysician.name', get(dicomData, 'performingPhysician', null));
      setValue('referringPhysician.name', get(dicomData, 'referringPhysician', null));
      setValue('requestingPhysician.name', get(dicomData, 'requestingPhysician', null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dicomData]);

  useEffect(() => {
    const showEditForm = get(watchAllFields, 'showEditForm', false);
    setShowEditForm(showEditForm);
    setDisableAnonymization(showEditForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields]);

  useEffect(() => {
    if (anonymizationProfiles && !isEmpty(archives) && !isEmpty(studiesForViewer)) {
      if (archives.length > 0 && studiesForViewer.length > 0) {
        const initialValues = prepareInitVal(getInitialValues(studiesForViewer, archives));
        setInitVal(initialValues);
        callReset(initialValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anonymizationProfiles.length, archives.length, studiesForViewer.length]);

  const submitButtonText = t(
    isRequestEdit ? `editRequestFor.${action}` : isRequest ? `setRequestFor.${action}` : action,
  );

  const handleClickOpen = async () => {
    const result = await trigger();
    if (result) {
      setConfirmDialog(true);
    }
  };

  const sendingConfirmed = () => {
    setConfirmDialog(false);
    callHandleSubmit(watchAllFields);
  };

  const renderedDialog = (
    <OverflowedDialog
      title={t(
        `${
          isRequest ? (isRequestEdit ? 'requestEdit' : 'requestCreate') : 'studyAction'
        }.${action}.confirmation`,
      )}
      open={confirmDialog}
      onClose={() => {
        setConfirmDialog(false);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
      actions={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '@media print': {
              display: 'none',
            },
          }}
        >
          <Button
            onClick={() => setConfirmDialog(false)}
            variant="contained"
            color="inherit"
            sx={{ mr: 1 }}
          >
            {t('close')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={sendingConfirmed}
            data-selenium-selector="edit-confirm-button"
          >
            {t('confirm')}
          </Button>
        </Box>
      }
    >
      <DialogContentText id="alert-dialog-description">
        {t('selectedStudiesAndSeries')}
        {`${numberOfSelectedStudies} / ${numberOfSelectedSeries}`}
      </DialogContentText>
    </OverflowedDialog>
  );

  const renderedSteps = () => {
    return (
      <TourStudyDistribute
        type={action === COPY ? 'copyStudy' : 'moveStudy'}
        showEditFormSteps={isSourceForUpdate && isTargetForUpdate}
      />
    );
  };

  return (
    <>
      <Header
        title={titleText}
        backUrl={`${linkBack}?action=back`}
        TourComponent={renderedSteps()}
      />
      {isEmpty(archives) ? (
        <Papeer>{`${t(`${action}NoRights`)}`}</Papeer>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <input {...register('distributeMethod')} type="hidden" />
            <input {...register('studies')} type="hidden" />
            <Papeer bottomMargin={true}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} md={6} lg={3} data-tour="distributeStudyTargetArchive">
                  <FormSelect
                    name="productIdTo"
                    label={t('archiveTo')}
                    items={archives.map((item: IArchive) => ({
                      id: item.id,
                      label: item.name,
                    }))}
                    required={true}
                  />
                </Grid>
                {isSourceForUpdate && isTargetForUpdate && (
                  <Grid
                    item={true}
                    xs={12}
                    md={6}
                    lg={3}
                    sx={{ mt: 1 }}
                    data-tour="distributeStudyShowEditForm"
                  >
                    <FormSwitch name="showEditForm" label={t('distributeAndUpdateStudy')} />
                  </Grid>
                )}
                {hasRole('ROLE_ANONYMIZATION_IN_OPERATION') && (
                  <AnonymizationForm
                    anonymizationWatch={anonymizationWatch}
                    setAnonymizationProfile={setAnonymizationProfile}
                    setAnonymizationProfiles={setAnonymizationProfiles}
                    anonymization={anonymization}
                    fields={anonymizationFields}
                    setFields={setAnonymizationFields}
                    disabled={disableAnonymization}
                  />
                )}
              </Grid>
            </Papeer>
            {showEditForm && isSourceForUpdate && isTargetForUpdate && (
              <PatientAndStudyEditForm
                isRequstDistribute={isRequest}
                isRequestEdit={isRequestEdit}
                register={register}
                isStudyEditType={true}
                setOrderDialog={setOrderDialog}
              />
            )}
            <Papeer sx={{ mt: 2 }}>
              <Box data-tour="distributeStudyListOfStudy">
                <SeriesViewer
                  studies={studiesForViewer}
                  showStudiesCheckboxes={true}
                  showSeriesCheckboxes={true}
                  operations={operations}
                />
              </Box>

              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <RequestAdditionalForm isRequest={isRequest} initialValues={initVal} />
                </Grid>
              </Grid>

              <Grid container={true} justifyContent="flex-end">
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                    disabled={!canMove}
                    data-selenium-selector="copy-submit-button"
                    data-tour="distributeSubmitButton"
                  >
                    {submitButtonText}
                  </Button>
                </Box>
              </Grid>
            </Papeer>
          </form>
        </FormProvider>
      )}
      {isSourceForUpdate && isTargetForUpdate && orderDialog && (
        <RequestDialog
          toggleDialog={setOrderDialog}
          actions={[SELECT_ORDER_TO_EDIT_STUDY_IN_DISTRIBUTE]}
          isActionFirstColumn={true}
          reset={reset}
          watchAllFields={watchAllFields}
        />
      )}
      {renderedDialog}
    </>
  );
};

export default StudiesDistributeDetail;

import { LicenseInfo } from '@mui/x-license';

const licenseKey =
  'c779b9dbefdd6afdea0abc536bfa807dTz04MDQ5MSxFPTE3MzQwODkzMzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

LicenseInfo.setLicenseKey(licenseKey);

export default function MuiXLicense() {
  return null;
}

import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IExchangeNetwork, IStudySendAddressBook } from './_types';

export const getAll = (): Promise<IExchangeNetwork[]> =>
  api()
    .get(`/portal-api/exchangeNetwork/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getExchangeNetwork = (id: string): Promise<IExchangeNetwork> =>
  api()
    .get(`/portal-api/exchangeNetwork/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editExchangeNetwork = (exchangeNetwork: IExchangeNetwork): Promise<boolean> =>
  api()
    .post(`/portal-api/exchangeNetwork/edit`, exchangeNetwork)
    .then((response) => true)
    .catch((error) => false);

export const setActiveExchangeNetwork = (
  exchangeNetworkId: string,
  active: boolean,
): Promise<boolean> =>
  api()
    .get(`/portal-api/exchangeNetwork/setActiveState`, {
      params: { exchangeNetworkId, active },
    })
    .then((response) => true)
    .catch((error) => false);

export const getStudySendAddressBook = (): Partial<Promise<IStudySendAddressBook[]> | boolean> =>
  api()
    .get(`/portal-api/exchangeNetwork/getStudySendAddressBook`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

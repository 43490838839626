import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IUnit } from './_types';

export const getAllUnits = (): Promise<IUnit[]> =>
  api()
    .get(`/portal-api/unit/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getUnit = (id: string): Promise<IUnit> =>
  api()
    .get(`/portal-api/unit/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editUnit = (unit: IUnit): Promise<any | boolean> =>
  api().post(`/portal-api/unit/edit`, unit);

export const createUnit = (unit: IUnit): Promise<any | boolean> =>
  api().post(`/portal-api/unit/create`, unit);

export const removeUnit = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/unit/remove`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { IStudiesExportConfirmDialog } from './_types';

const StudiesExportConfirmDialog: React.FC<IStudiesExportConfirmDialog> = ({
  sendingConfirmed,
  openDialog,
  setOpenDialog,
  isMorePatientIds,
  isMissingReport,
  isCalculationError,
}) => {
  const { t } = useTranslation('Studies');

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="folder-dialog-title"
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
      >
        <DialogTitle id="folder-dialog-title">{t('exportConfirmTitle')}</DialogTitle>
        <DialogContent>
          {isMorePatientIds && (
            <DialogContentText id="alert-dialog-description">
              {`— ${t('exportConfirmMorePatientId')} `}
            </DialogContentText>
          )}
          {isMissingReport && (
            <DialogContentText id="alert-dialog-description2">
              {`— ${t('exportConfirmMissingReport')} `}
            </DialogContentText>
          )}
          {isCalculationError && (
            <DialogContentText id="alert-dialog-description3">
              {`— ${t('exportConfirmCalculationError')} `}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              '@media print': {
                display: 'none',
              },
            }}
          >
            <Button
              onClick={() => setOpenDialog(false)}
              variant="contained"
              size="large"
              color="inherit"
              sx={{ mr: 1 }}
            >
              {t('close')}
            </Button>
            <Button variant="contained" size="large" color="primary" onClick={sendingConfirmed}>
              {t('confirm')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StudiesExportConfirmDialog;

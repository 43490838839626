import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

import { getAllPhysician, removePhysician } from './_api';

import Header from 'components/Header/Header';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';

import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'physiciansItemMUI';

const Physicians: React.FC<any> = ({ dictionary }) => {
  const { t } = useTranslation('Dictionaries');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();

  const { toggleLoader } = useAppGlobals();

  const loadEntity = async () => {
    toggleLoader();
    try {
      const physicians = await getAllPhysician();
      if (physicians && isArray(physicians)) {
        setPhysicians(physicians);
      } else {
        setPhysicians([]);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCustomEntityDetail = (row: any) => {
    const editLink = `${get(row, 'id')}`;
    navigate(editLink);
  };
  const { onEntityRemove } = useEntityRemove(removePhysician, t, loadEntity);
  const [physicians, setPhysicians] = useState<any[]>([]);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 100,
        renderCell: ({ row }: GridRenderCellParams) => (
          <>
            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:edit')}>
                  <EditIcon />
                </Tooltip>
              }
              label={t('Grid:edit')}
              onClick={() => onCustomEntityDetail(row)}
            />
            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:delete')}>
                  <DeleteIcon />
                </Tooltip>
              }
              label={t('Grid:delete')}
              onClick={() => {
                dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
              }}
              disabled={
                get(row, 'state', null) === 'NEW' ||
                !get(row, 'canDelete', true) ||
                get(row, 'system', false)
              }
            />
          </>
        ),
      },
      {
        field: 'name.lastName',
        headerName: t('lastName'),
        valueGetter: (value: any, row: any) => get(row, 'name.lastName'),
      },
      {
        field: 'name.firstName',
        headerName: t('firstName'),
        valueGetter: (value: any, row: any) => get(row, 'name.firstName'),
      },
      {
        field: 'name.middleName',
        headerName: t('middleName'),
        valueGetter: (value: any, row: any) => get(row, 'name.middleName'),
      },
      {
        field: 'name.prefix',
        headerName: t('prefix'),
        valueGetter: (value: any, row: any) => get(row, 'name.prefix'),
      },
      {
        field: 'name.suffix',
        headerName: t('suffix'),
        valueGetter: (value: any, row: any) => get(row, 'name.suffix'),
      },

      {
        field: 'requestingPhysician',
        headerName: t('requestingPhysician'),
        valueGetter: (value: any, row: any) => t(get(row, 'requestingPhysician') ? 'yes' : 'no'),
      },
      {
        field: 'referringPhysician',
        headerName: t('referringPhysician'),
        valueGetter: (value: any, row: any) => t(get(row, 'referringPhysician') ? 'yes' : 'no'),
      },
    ]),
  );

  return (
    <>
      <Header
        title={t('physicians')}
        addUrl={`/administration/generalSettings/physicians/create`}
        backUrl="/administration/generalSettings"
      />
      <MuiGrid
        gridKey={muiGridKey}
        rows={physicians}
        columns={columns}
        initialSortMode={[{ field: 'name.lastName', sort: 'asc' }]}
      />
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

export default Physicians;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { compact, filter, get, includes, isEmpty, pull, uniq, find, isString, take } from 'lodash';
import { useTranslation } from 'react-i18next';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { downloadPdfDocument, getDocumentDetail, getDocuments, getPdfDocument } from './_api';
import useAlerts from 'components/Alerts/useAlerts';
import { useLanguage } from 'utils/hooks/useLanguage';
import { getClinicalPortalSetting } from 'modules/Administration/ClinicalPortal/_api';
import { joinParams } from 'utils/study';
import { format } from 'date-fns';
import { IFieldForPatient, IGridLayoutParams, IPatientDocument } from './_types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import { DocumentDetailTimeline } from './DocumentDetailTimeline';
import { DocumentDetailLayoutDialog } from './DocumentDetailLayoutDialog';
import { DocumentDetailGrids } from './DocumentDetailGrids';
import { apiDataToDownloadFile, printOrDownloadBase64String } from 'utils/hooks/useApp';
import { hookDownloadFromEldax } from './hooks';

const formatDate = 'dd. MM. yyyy';

const sxDisplayPrintNone = {
  '@media print': {
    display: 'none',
  },
};

export const ClinicalPortalByPatientDocumentsDetail: React.FC = () => {
  const { t } = useTranslation('ClinicalPortal');
  const { toggleLoader } = useAppGlobals();
  const { currentLocale } = useLanguage();
  const navigate = useNavigate();
  const { addErrorAlert } = useAlerts();
  const { selectedDocumentsForDocumentDetail, getFacilitiesManualLoad, selectedDocuments } =
    useAppInfo();
  const linkBack =
    window.location.pathname.replace('portal/', '').replace('/documents', '') + '?action=back';

  const [entity, setEntity] = useState<any>(null);
  const [gridLayout, setGridLayout] = useState<string>('');
  const [requestedDocuments, setRequestedDocuments] = useState<any[]>([]);
  const [patientDocuments, setPatientDocuments] = useState<IPatientDocument[]>([]);
  const [documentUids, setDocumentUids] = useState<any[]>([]);
  const [facilitiesManualLoad, setFacilitiesManualLoad] = useState<any[]>([]);
  const [fieldsForPatientName, setFieldsForPatientName] = useState<IFieldForPatient>();
  const [fieldsForPatientInfo, setFieldsForPatientInfo] = useState<IFieldForPatient[]>([]);
  const [loadingFacility, setLoadingFacility] = useState<string>('');
  const [openLayoutDialog, setOpenLayoutDialog] = useState<boolean>(false);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [standardizedPrint, setStandardizedPrint] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [isDownloadingFromEldax, setIsDownloadingFromEldax] = useState<boolean>(false);

  const entityJsonString = JSON.stringify(entity);
  // const patientDocumentsJsonString = JSON.stringify(patientDocuments);

  const toggleExpanded = () => setExpanded(!expanded);

  const findDocumentsForFacility = async (
    masterPatientID: string,
    activeTab: string,
    facility: string,
  ) => {
    setLoadingFacility(facility);
    let allDocuments: IPatientDocument[] = [];
    const documents: IPatientDocument[] = await getDocuments(masterPatientID, activeTab, facility);
    if (documents) {
      const preparedDocuments = documents.map((item, index: number) => ({
        ...item,
        typeOfDocumentName: get(item, 'typeOfDocument.translateJson')
          ? get(
              JSON.parse(get(item, 'typeOfDocument.translateJson')),
              currentLocale,
              get(item, 'typeOfDocument.name'),
            )
          : '?',
        patientEntity: entity,
        patient: entity,
      }));
      allDocuments = [...preparedDocuments];
    }
    setLoadingFacility('');
    return allDocuments;
  };

  const findDocuments = async (facility: string) => {
    const masterPatientID = get(entity, 'masterPatientID', '');
    const newFacitiesManualLoad = facilitiesManualLoad.map((facilityItem) => ({
      facility: facilityItem.facility,
      label: facilityItem.label,
      disabled: get(facilityItem, 'disabled', false)
        ? true
        : facilityItem.facility === facility
        ? true
        : false,
    }));
    setFacilitiesManualLoad(newFacitiesManualLoad);

    const allDocuments = await findDocumentsForFacility(masterPatientID, '1', facility);
    setPatientDocuments(uniq(compact([...(patientDocuments || []), ...(allDocuments || [])])));
  };

  const loadDocuments = async () => {
    toggleLoader();
    const selectedDocumentsCount = get(selectedDocumentsForDocumentDetail, 'documents', []).length;
    if (selectedDocumentsCount > 0) {
      setGridLayout(
        `${Math.ceil(selectedDocumentsCount / 4)}x${
          selectedDocumentsCount > 4 ? 4 : selectedDocumentsCount
        }`,
      );
    }
    const documentDetail = await getDocumentDetail(
      selectedDocumentsForDocumentDetail.masterPatientID,
      selectedDocumentsForDocumentDetail.facilities,
      selectedDocumentsForDocumentDetail.documents,
    );
    const patient = get(documentDetail, 'patient', null);
    const requestedDocuments = get(documentDetail, 'requestedDocuments', []);
    const documents = get(documentDetail, 'timelineDocuments', []);
    if (!patient || !documents || !requestedDocuments) {
      addErrorAlert(t('noFindPatient'));
      navigate(linkBack);
    }

    setEntity(patient);
    setRequestedDocuments(requestedDocuments);

    let allDocuments: any[] = [];
    const preparedDocuments = documents.map((item: any) => ({
      ...item,
      typeOfDocumentName: get(item, 'typeOfDocument.translateJson')
        ? get(
            JSON.parse(get(item, 'typeOfDocument.translateJson')),
            currentLocale,
            get(item, 'typeOfDocument.name'),
          )
        : '?',
      patientEntity: patient,
      patient,
    }));
    allDocuments = [...preparedDocuments];
    setPatientDocuments(allDocuments);
    setDocumentUids(selectedDocuments);
    const clinicalPortalSetting = await getClinicalPortalSetting();
    let facilitiesManualLoad = [];
    if (clinicalPortalSetting) {
      facilitiesManualLoad = filter(get(clinicalPortalSetting, 'facilities', []), {
        autoLoad: false,
      });
      const newFacitiesManualLoad = facilitiesManualLoad.map((facilityItem) => ({
        facility: get(facilityItem, 'facility', ''),
        label: get(facilityItem, 'label', ''),
        disabled: includes(getFacilitiesManualLoad, get(facilityItem, 'facility', ''))
          ? true
          : false,
      }));
      setFacilitiesManualLoad(newFacitiesManualLoad);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let fieldsForPatientName = {};
    let fieldsForPatientInfo: any[] = [];
    if (entity) {
      fieldsForPatientName = {
        name: 'name',
        value: `${joinParams([
          get(entity, 'patientPrefix'),
          get(entity, 'patientLastName'),
          get(entity, 'patientFirstName'),
          get(entity, 'patientMiddleName'),
          get(entity, 'patientSuffix'),
          `(${t('patientIdentificationNumber')}: ${
            get(entity, 'patientIdentificationNumber', '-') != null
              ? get(entity, 'patientIdentificationNumber', '-')
              : '-'
          } | ${t('patientBirthDate')}: ${
            get(entity, 'patientBirthDate', '')
              ? format(new Date(get(entity, 'patientBirthDate', '')), formatDate)
              : '-'
          })`,
        ])}
        `,
        lg: 6,
      };
      fieldsForPatientInfo = [
        {
          name: 'patientID',
          label: t('patientInsuranceNumber'),
          value: `${get(entity, 'patientID', '-') || '-'}`,
        },
        {
          name: 'patientSex',
          label: t('patientSex'),
          value: t(`patient_sex_${get(entity, 'patientSex', 'O')}`),
        },
        {
          name: 'address',
          label: t('address'),
          value: joinParams(
            [
              get(entity, 'address', ''),
              joinParams([get(entity, 'postcode', ''), get(entity, 'town', '')]),
            ],
            ', ',
          ),
        },
        {
          name: 'telephone',
          label: t('telephone'),
          value: `${get(entity, 'telephone', '') || ''}`,
        },
        {
          name: 'motherMaidenName',
          label: t('motherMaidenName'),
          value: `${get(entity, 'motherMaidenName', '') || ''}`,
        },
        {
          name: 'masterPatientID',
          label: t('masterPatientID'),
          value: `${get(entity, 'masterPatientID', '') || ''}`,
        },
      ];
    }
    setFieldsForPatientName(fieldsForPatientName);
    setFieldsForPatientInfo(fieldsForPatientInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityJsonString]);

  const loadDocumentForPrint = async (
    masterPatientID: any,
    documentUiid: any,
    typeOfDocument: any,
    isPrinting: boolean,
    isExporting: boolean,
    standardizedPrint: boolean,
  ) => {
    if (masterPatientID !== null && documentUiid !== null && typeOfDocument !== null) {
      const documentPrintInfoResponse = await getPdfDocument(
        masterPatientID,
        documentUiid,
        typeOfDocument,
        standardizedPrint,
      );
      if (documentPrintInfoResponse) {
        return documentPrintInfoResponse;
      } else {
        if (isPrinting) {
          addErrorAlert(t('printDocumentError'));
        }
        if (isExporting) {
          addErrorAlert(t('exportDocumentError'));
        }
      }
    }
    return undefined;
  };

  const printOrExport = async () => {
    toggleLoader();
    for await (const uuid of selectedDocuments) {
      const patientdoc = find(patientDocuments, { uuid });
      const masterPatientID = get(entity, 'masterPatientID', null);
      const fileName = masterPatientID + '.pdf';
      if (isPrinting) {
        const base64PdfString = await loadDocumentForPrint(
          masterPatientID,
          uuid,
          get(patientdoc, 'typeOfDocument.name', null),
          isPrinting,
          isExporting,
          standardizedPrint,
        );
        const contentType = 'application/pdf';
        printOrDownloadBase64String(base64PdfString, contentType, fileName);
        setStandardizedPrint(false);
        setIsPrinting(false);
      } else if (isExporting) {
        const base64PdfString = await downloadPdfDocument(
          masterPatientID,
          uuid,
          get(patientdoc, 'typeOfDocument.name', ''),
          standardizedPrint,
        );
        apiDataToDownloadFile(base64PdfString, fileName);
        setStandardizedPrint(false);
        setIsExporting(false);
      }
    }
    toggleLoader(false);
  };
  useEffect(() => {
    if (isPrinting || isExporting) {
      printOrExport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrinting, isExporting]);

  const downloadFromEldaxFunction = async () => {
    toggleLoader();
    for await (const uuid of selectedDocuments) {
      const patientdoc = find(patientDocuments, { uuid });
      const masterPatientID = get(entity, 'masterPatientID', null);
      hookDownloadFromEldax(
        masterPatientID,
        patientdoc,
        uuid,
        t,
        addErrorAlert,
        setIsDownloadingFromEldax,
      );
    }
    toggleLoader(false);
  };
  useEffect(() => {
    if (isDownloadingFromEldax) {
      downloadFromEldaxFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadingFromEldax]);

  const unselectDocumentUuid = (uuid: string) => {
    const reducedDocumentUids = [...documentUids];
    pull(reducedDocumentUids, uuid);

    setDocumentUids(reducedDocumentUids);
  };
  const selectDocumentUuid = (uuid: string) => {
    const updatedDocumentUids = [...documentUids];
    updatedDocumentUids.push(uuid);

    setDocumentUids(updatedDocumentUids);
  };

  const replaceLastDocumentUuid = (uuid: string) => {
    const currentUids = [...documentUids];
    currentUids[currentUids.length - 1] = uuid;
    setDocumentUids(currentUids);
  };

  const replaceDocumentAtIndex = (uuid: string, index: number) => {
    const currentUids = [...documentUids];
    currentUids[index] = uuid;
    console.debug({ documentUids, currentUids });
    setDocumentUids(currentUids);
  };

  const [gridLayoutParams, setGridLayoutParams] = useState<IGridLayoutParams>({
    x: 1,
    y: 1,
    totalAvailableFields: 1,
    lastDocumentPositionIndex: 1,
  });

  useEffect(() => {
    if (isString(gridLayout)) {
      const dimesions = gridLayout.split('x');
      if (dimesions.length === 2) {
        const x = Number(dimesions[0]);
        const y = Number(dimesions[1]);
        const max = x * y;
        const newUids = take(documentUids, max);
        setDocumentUids(newUids);
        setGridLayoutParams({
          x,
          y,
          totalAvailableFields: x * y,
          lastDocumentPositionIndex: documentUids.length - 1,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridLayout, documentUids.length]);

  return (
    <>
      {entity && (
        <>
          <Typography component="div" sx={sxDisplayPrintNone}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Header
                title={t('documentsDetail')}
                backUrl={linkBack}
                loading={loadingFacility}
                loadingDescription={t('loadingDataForFacility', { par1: loadingFacility })}
              />
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => setOpenLayoutDialog(true)}
                style={{ marginBottom: '8px', marginInlineStart: 'auto' }}
              >
                <ViewColumnIcon />
              </IconButton>
            </Box>

            <Papeer>
              <Box id="patient" sx={sxDisplayPrintNone}>
                <Accordion elevation={0} defaultExpanded={false} onChange={() => toggleExpanded()}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-patient"
                    id="panel-patient"
                  >
                    <Typography>{`${get(fieldsForPatientName, 'value', '?')}`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container={true} spacing={2}>
                      {fieldsForPatientInfo.map((field) => (
                        <Grid item={true} xs={6} md={4} lg={3} {...field.grid} key={field.name}>
                          <Box component="label" sx={{ fontWeight: 'bold' }}>
                            {field.label}
                          </Box>
                          <div>{field.value}</div>
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {!isEmpty(facilitiesManualLoad) && (
                <div>
                  {facilitiesManualLoad.map((facilityItem) => (
                    <Button
                      key={facilityItem.facility}
                      variant="contained"
                      color="primary"
                      size="medium"
                      sx={{ mr: 1 }}
                      disabled={get(facilityItem, 'disabled', false)}
                      onClick={() => findDocuments(facilityItem.facility)}
                    >
                      {facilityItem.label}
                    </Button>
                  ))}
                </div>
              )}
            </Papeer>
          </Typography>

          {!isEmpty(patientDocuments) && gridLayout && (
            <Box sx={sxDisplayPrintNone}>
              <DocumentDetailTimeline
                patientDocuments={patientDocuments}
                gridLayout={gridLayout}
                documentUids={documentUids}
                setDocumentUids={setDocumentUids}
                selectDocumentUuid={selectDocumentUuid}
                unselectDocumentUuid={unselectDocumentUuid}
                replaceLastDocumentUuid={replaceLastDocumentUuid}
                gridLayoutParams={gridLayoutParams}
              />

              <DocumentDetailGrids
                gridLayout={gridLayout}
                documentUids={documentUids}
                selectDocumentUuid={selectDocumentUuid}
                unselectDocumentUuid={unselectDocumentUuid}
                patientDocuments={patientDocuments}
                setIsPrinting={setIsPrinting}
                setStandardizedPrint={setStandardizedPrint}
                setIsExporting={setIsExporting}
                requestedDocuments={requestedDocuments}
                gridLayoutParams={gridLayoutParams}
                replaceDocumentAtIndex={replaceDocumentAtIndex}
                setIsDownloadingFromEldax={setIsDownloadingFromEldax}
              />
            </Box>
          )}
        </>
      )}

      {openLayoutDialog && (
        <DocumentDetailLayoutDialog
          openLayoutDialog={openLayoutDialog}
          setOpenLayoutDialog={setOpenLayoutDialog}
          setGridLayout={setGridLayout}
          gridLayoutParams={gridLayoutParams}
        />
      )}
    </>
  );
};

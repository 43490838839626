import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { find, get, isArray } from 'lodash';
import { Box } from '@mui/system';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormControlLabel, Grid, Switch, Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import {
  checkFacilityCode,
  getAll,
  setActiveFacility,
  setActiveState,
  updateFacilitiesByRemoteSource,
} from './_api';
import Header from 'components/Header/Header';
import { Announcement } from 'components/Announcement/Announcement';
import useAlerts from 'components/Alerts/useAlerts';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { EXCHANGE_NETWORK_EPACS, EXCHANGE_NETWORK_REDIMED } from 'constants/constants';
import Button from 'components/Buttons/Button';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'facilitiesMUI';

export const Facilities: React.FC = () => {
  const { t } = useTranslation('Facilities');
  useWithTitle();
  const navigate = useNavigate();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [facilities, setFacilities] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();

  const { toggleLoader } = useAppGlobals();

  const loadEntity = async () => {
    toggleLoader();
    try {
      const facilities = await getAll();
      if (facilities && isArray(facilities)) {
        setFacilities(facilities);
      } else {
        setFacilities([]);
      }
      const isValid = await checkFacilityCode(null);
      setIsValid(isValid);
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const onCustomEntityDetail = (row: any) => {
    navigate(`/administration/facilitiesAndExchangeNetworks/${get(row, 'id')}`);
  };

  const clearAction = () => dispatch(storeConfirmationDataToStore(null));

  const onChangeActive = async (id: string, isActive: boolean) => {
    toggleLoader();
    const changed = await setActiveState(id, isActive);
    if (changed) {
      loadEntity();
    }
    toggleLoader(false);
  };

  const confirmActionChangeActive = () => {
    onChangeActive(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  const onChangeActiveFacility = async (id: string) => {
    toggleLoader();
    const changed = await setActiveFacility(id);
    if (changed) {
      loadEntity();
    }
    toggleLoader(false);
  };

  const confirmActionChangeActiveFacility = () => {
    onChangeActiveFacility(get(confirmationData, 'id'));
    clearAction();
  };

  const updateFacilities = async () => {
    toggleLoader();
    const response = await updateFacilitiesByRemoteSource();
    if (response) {
      addSuccessAlert(t('updateFacilitiesByRemoteSourceSuccessfully'));
      loadEntity();
    } else {
      addErrorAlert(t('updateFacilitiesByRemoteSourceError'));
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const computedValue = (row: any, exchangeNetworkName: string) => {
    const out = find(
      row.facilityExchangeNetworkModels,
      (item) => get(item, 'exchangeNetwork.name', null) === exchangeNetworkName,
    );
    return out ? `${exchangeNetworkName} (${get(out, 'identifier', '')})` : null;
  };
  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 250,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.activeFacility}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeActiveFacility',
                            id: get(row, 'id'),
                            confirmAction: 'confirmActionChangeActiveFacility',
                          }),
                        );
                      }}
                    />
                  }
                  label={
                    t(
                      row.activeFacility ? 'Grid:activeFacility' : 'Grid:inactiveFacility',
                    ) as string
                  }
                  disabled={row.activeFacility || !row.active}
                />
              </Box>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.active}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeState',
                            id: get(row, 'id'),
                            state,
                            confirmAction: 'confirmActionChangeActive',
                          }),
                        );
                      }}
                    />
                  }
                  label={t(row.active ? 'Grid:active' : 'Grid:inactive') as string}
                  disabled={get(row, 'activeFacility', false) || get(row, 'disabledRow', false)}
                />
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onCustomEntityDetail(row)}
              />
            </>
          );
        },
      },
      {
        field: 'name',
        headerName: t('facility'),
        renderCell: ({ row }: GridRenderCellParams) => get(row, 'name', ''),
        valueGetter: (value: any, row: any) =>
          get(row, 'name', '').replace('(CZ) ', '').replace('(SK) ', '').toLowerCase(),
      },
      { field: 'code', headerName: t('code') },
      { field: 'city', headerName: t('city') },
      { field: 'country', headerName: t('country') },
      {
        field: 'epacs',
        headerName: t('epacs'),
        valueGetter: (value: any, row: any) => computedValue(row, EXCHANGE_NETWORK_EPACS),
      },
      {
        field: 'redimed',
        headerName: t('redimed'),
        valueGetter: (value: any, row: any) => computedValue(row, EXCHANGE_NETWORK_REDIMED),
      },
    ]),
  );

  return (
    <>
      <Header
        title=""
        addUrl="/administration/facilitiesAndExchangeNetworks/create"
        button={
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 1, mb: 0.5 }}
            onClick={() => {
              updateFacilities();
            }}
          >
            {t('updateFacilitiesByRemoteSource')}
          </Button>
        }
      />
      {!isValid && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <Announcement label={t('isNotValid')} type="danger" spaced={true} />
            </Grid>
          </Grid>
        </Box>
      )}
      <MuiGrid
        gridKey={muiGridKey}
        rows={facilities || []}
        columns={columns}
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={
            confirmationData.confirmAction === 'confirmActionChangeActiveFacility'
              ? confirmActionChangeActiveFacility
              : confirmActionChangeActive
          }
        />
      )}
    </>
  );
};

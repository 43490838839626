import * as yup from 'yup';

const StudiesSplitFormSchema = () => {
  const StudiesSplitFormSchema = yup.object().shape({});
  return {
    StudiesSplitFormSchema,
  };
};

export default StudiesSplitFormSchema;

import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { each, get, isArray, isDate, keys } from 'lodash';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { Box, Grid } from '@mui/material';
import { getProposals } from '../_api';
import { IProposalSearch, IProposalSearchForm } from '../_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { generateIID, joinParams } from 'utils/study';
import { encryptId } from 'utils/hooks/useApp';
import { convertSearchDates } from 'utils/search';
import { Papeer } from 'components/Papeer/Papeer';
import Button from 'components/Buttons/Button';
import FormSelect from 'components/Form/Select/Select';
import FormDatePicker from 'components/Form/Date/Date';
import {
  DATE_BTN_CUSTOM_DATE,
  DATE_BTN_LAST_MONTH,
  DATE_BTN_LAST_THREE_DAYS,
  DATE_BTN_LAST_WEEK,
  DATE_BTN_TODAY,
  DATE_BTN_YESTERDAY,
} from 'constants/constants';
import { useStudy } from 'utils/hooks/useStudy';
import { useStorage } from 'utils/hooks/useStorage';

const apiDateFormatWithDash = 'yyyy-MM-dd';
const apiDateFormat = 'yyyyMMdd';

const FORM_DATA_KEY = 'proposalSearchForm';

const mainSx = { width: { xs: '100%', md: '50%', lg: '20%' } };

const ProposalSearchForm: React.FC<IProposalSearchForm> = ({
  setRows,
  refreshForm,
  setRefreshForm,
}) => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('SearchForm');

  const { linkBack } = useStudy();
  const { getItem, putItem, removeItem } = useStorage();

  const backFromDetail = linkBack();

  const [hasCustomDate, setHasCustomDate] = useState(false);

  const methods = useForm<IProposalSearch>({
    defaultValues: {
      date: DATE_BTN_CUSTOM_DATE,
    },
  });

  const { handleSubmit, reset, setValue, watch } = methods;
  const selectedDate = watch('date');
  const dateFrom = watch('dateFrom');
  const dateTo = watch('dateTo');
  const canSearch =
    (selectedDate && selectedDate !== 'customDate') ||
    (selectedDate === 'customDate' && (isDate(dateFrom) || isDate(dateTo)));

  const { toggleLoader } = useAppGlobals();

  const dateSelectItems = [
    DATE_BTN_TODAY,
    DATE_BTN_YESTERDAY,
    DATE_BTN_LAST_THREE_DAYS,
    DATE_BTN_LAST_WEEK,
    DATE_BTN_LAST_MONTH,
    DATE_BTN_CUSTOM_DATE,
  ].map((id) => ({ id, label: t(`SearchForm:${id}`) }));

  useEffect(() => {
    if (selectedDate === DATE_BTN_CUSTOM_DATE) {
      setHasCustomDate(true);
    } else {
      setHasCustomDate(false);

      // Set dates if selected date is not custom
      if (selectedDate) {
        const dateAttributes = convertSearchDates(selectedDate, dateFrom, dateTo, apiDateFormat);
        setValue('dateFrom', new Date(parseISO(dateAttributes.dateFrom)));
        setValue('dateTo', new Date(parseISO(dateAttributes.dateTo)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const submitHandler = async (formValues: any) => {
    toggleLoader();
    const selectedDate = get(formValues, 'date', null);
    callPersistForm(formValues);
    let values = { ...formValues };
    if (selectedDate) {
      const dateAttributes = convertSearchDates(
        selectedDate,
        get(values, 'dateFrom', ''),
        get(values, 'dateTo', ''),
        apiDateFormatWithDash,
      );
      values = { ...values, from: dateAttributes.dateFrom, to: dateAttributes.dateTo };
    }
    await getProposals(get(values, 'from', ''), get(values, 'to', '')).then((response) => {
      if (isArray(response)) {
        const proposalRecords = response.map((result) => {
          const iid = generateIID(result.study);
          const hashIID = encryptId(iid);
          const proposalUser = get(result, 'user', null);
          const source = result.sourceProposal;
          const user =
            source === 'User'
              ? ' ' +
                joinParams([get(proposalUser, 'lastName', ''), get(proposalUser, 'firstName', '')])
              : '';
          return {
            ...result.study,
            iid,
            hashIID,
            proposal: {
              createdDate: result.createdDate,
              id: result.id,
              requestId: result.requestId,
              source: t(`proposal.${source}`) + user,
              state: result.state,
              user: result.user,
            },
          };
        });
        setRows(proposalRecords);
      }
    });
    toggleLoader(false);
  };

  const triggerSubmit = () => {
    refSubmitButtom?.current?.click();
  };

  const resetForm = () => {
    reset({
      date: null,
    });
  };

  useEffect(() => {
    if (refreshForm) {
      triggerSubmit();
      setRefreshForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshForm]);

  useEffect(() => {
    if (backFromDetail) {
      const data = getItem(FORM_DATA_KEY);
      if (data) {
        // Parse it to a javaScript object
        try {
          const values = JSON.parse(data);
          const transformedValues = {
            ...values,
            dateFrom: values.dateFrom ? new Date(values.dateFrom) : null,
            dateTo: values.dateTo ? new Date(values.dateTo) : null,
          };
          each(keys(transformedValues), (key: any) => {
            setValue(key, get(transformedValues, key));
          });
          triggerSubmit();
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      removeItem(FORM_DATA_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callPersistForm = (value: any) => {
    putItem(FORM_DATA_KEY, JSON.stringify(value));
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <button hidden={true} ref={refSubmitButtom} type={'submit'} />
          <Papeer bottomMargin={true}>
            <Box
              data-tour="proposalSearchForm"
              sx={{
                display: 'flex',
                flexWrap: {
                  xs: 'wrap',
                  lg: 'nowrap',
                },
                paddingRight: { xs: '0px', sm: 0 },
              }}
            >
              <Box sx={mainSx}>
                <FormSelect
                  name="date"
                  label={t('proposalDate')}
                  items={dateSelectItems}
                  sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                  clearable={true}
                  clearCallback={() => {
                    setValue('dateFrom', null);
                    setValue('dateTo', null);
                  }}
                />
              </Box>
              <Box sx={mainSx}>
                <FormDatePicker
                  name="dateFrom"
                  label={t('from')}
                  sx={{ borderRadius: 0 }}
                  disabled={!hasCustomDate}
                />
              </Box>
              <Box sx={mainSx}>
                <FormDatePicker
                  name="dateTo"
                  label={t('to')}
                  sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  disabled={!hasCustomDate}
                />
              </Box>
            </Box>

            <Grid container={true} spacing={2} alignItems="flex-end">
              <Grid item={true} xs={12}>
                <Box
                  id="search-results"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    '@media print': {
                      display: 'none',
                    },
                  }}
                >
                  <Box sx={{ mr: 1 }} data-tour="shreddings-proposals-clear">
                    <Button variant="contained" color="inherit" onClick={resetForm}>
                      {t('reset')}
                    </Button>
                  </Box>
                  <Box sx={{ mr: 1 }} data-tour="shreddings-proposals-search">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!canSearch}
                      className="search-form-button"
                    >
                      {t('search')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>
    </>
  );
};

export default ProposalSearchForm;

import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  chunk,
  compact,
  filter,
  find,
  get,
  indexOf,
  isArray,
  isEmpty,
  isFunction,
  isNull,
  keys,
  omit,
  sortBy,
  toNumber,
} from 'lodash';
import { AppBar, Box, Button, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

import { extendStudyExpiration, getFolder, removeFolder, removeStudy } from './_api';
import { IFolderForm, IMaxCounts } from './_types';

import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { decodeIID, joinParams } from 'utils/study';
import { encryptId } from 'utils/hooks/useApp';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import {
  BASKET,
  DELETE_DOCUMENTS_FROM_FOLDER,
  DELETE_DOCUMENT_FROM_FOLDER,
  DELETE_USER_FROM_FOLDER,
  EXTEND_EXPIRATION,
  LIST_FOR_FOLDER,
  REMOVE_ALL_STUDIES_FROM_FOLDER,
  REMOVE_STUDY_FROM_FOLDER,
  TAB_DOCUMENTS,
  TAB_STUDIES,
  TAB_USERS,
  VIEWER,
} from 'constants/constants';
import useRefetchGrid from 'utils/grid/useRefetchGrid';
import { useUser } from 'utils/hooks/useUser';
import { useActions } from 'utils/hooks/useActions';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import useAlerts from 'components/Alerts/useAlerts';
import { getProductByType } from '../Products/_api';
import { getFirstActiveProduct, getSystemSetting } from 'utils/products';
import { useLanguage } from 'utils/hooks/useLanguage';
import { SearchResultsMUI } from 'components/SearchResultsMUI/SearchResultsMUI';
import { IStudyResultForGrid } from 'modules/Search/_types';
import { useSubmitSearchForm } from 'utils/hooks/useSubmitSearchForm';
import { FolderDetailUsers } from './FolderDetailUsers';
import { FolderDetailDocuments } from './FolderDetailDocuments';
import { TourFolders } from './TourFolders';

const hashedId = true;
const chunkSize = Infinity; // moc na to nečum, je to tak správně

export const FolderDetail: React.FC = () => {
  const { t } = useTranslation('Folders');
  const { toggleLoader } = useAppGlobals();
  const { refetchGrid } = useRefetchGrid();
  const { confirmationData, defViewer, gridSettings } = useAppInfo();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { currentLocale } = useLanguage();
  const { id, isCreating } = useEntityInfo(true);

  const [entity, setEntity] = useState<IFolderForm | null>(null);

  const [documents, setDocuments] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>(TAB_STUDIES);
  const [loadingStudies, setLoadingStudies] = useState<boolean>(true);
  const [loadedPercents, setLoadedPercents] = useState<any>(0);
  const [preResult, setPreResult] = useState<any[]>([]);
  const [apiCalledCount, setApiCalledCount] = useState<number>(0);
  const [totalCountCall, setTotalCountCall] = useState<number>(0);
  const [countOfLoadedStudies, setCountOfLoadedStudies] = useState<number>(0);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const [items, setItems] = useState<GridRowSelectionModel>([]);

  const [maxCounts, setMaxCounts] = useState<IMaxCounts>({
    maxExportDocumentsCount: 16,
    maxViewDocumentsCount: 16,
  });
  const [previousGridSettings, setPreviousGridSettings] = useState<any>(null);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [RenderedStudies, setRenderedStudies] = useState<JSX.Element | null>(null);
  const [RenderedDocuments, setRenderedDocuments] = useState<JSX.Element | null>(null);
  const [RenderedUsersGrid, setRenderedUsersGrid] = useState<JSX.Element | null>(null);
  const [fields, setFields] = useState<{ name: string; value: any }[]>([]);
  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);
  const { onStudiesInFolder } = useSubmitSearchForm(rows, setRows, LIST_FOR_FOLDER);

  const { hasRole, user } = useUser();
  const { resetStudies, storeConfirmationData } = useActions();

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const from = searchParams.get('from') || null;
  const linkBack = `${from === 'view' ? '' : '/administration'}/folders?action=back`;

  const username = get(user, 'sub', '');

  const { title } = useWithEntityTitle(isCreating, entity, t, 'detail', 'detail');

  const resetStateValues = () => {
    setCountOfLoadedStudies(0);
    setLoadedPercents(0);
    setApiCalledCount(0);
    setTotalCountCall(0);
    setPreResult([]);
    setLoadingStudies(false);
  };

  const fetchStudies = (searchCriteriaObject: any[]) => {
    if (!isEmpty(searchCriteriaObject)) {
      setPreviousGridSettings(gridSettings);
      onStudiesInFolder(
        searchCriteriaObject,
        id,
        apiCalledCount,
        setApiCalledCount,
        setCountOfLoadedStudies,
      );
    }
  };

  useEffect(() => {
    if (isArray(preResult) && !isEmpty(preResult)) {
      setLoadingStudies(true);
      const searchCriteriaObject = chunk(preResult, chunkSize);
      const searchCriteriaObjectKeys = keys(searchCriteriaObject);
      setTotalCountCall(searchCriteriaObjectKeys.length);
      fetchStudies(searchCriteriaObject);
    } else {
      setLoadingStudies(false);
      toggleLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preResult.length]);

  useEffect(() => {
    if (preResult.length > 0 && countOfLoadedStudies > 0) {
      const loadedPercents = Number((countOfLoadedStudies * 100) / preResult.length);
      setLoadedPercents(loadedPercents.toFixed(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countOfLoadedStudies]);

  useEffect(() => {
    if (totalCountCall > 0 && totalCountCall === apiCalledCount) {
      setLoadingStudies(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCalledCount]);

  const loadEntity = async (folderId: string) => {
    toggleLoader();
    try {
      setLoadedPercents(0);
      setTotalCountCall(0);

      resetStudies(LIST_FOR_FOLDER);
      const entity = await getFolder(folderId);
      if (!isEmpty(entity)) {
        setDocuments([]);
        setRows([]);
        setEntity(entity);
        const users = sortBy(
          get(entity, 'users', []).map((item: any) => ({
            ...item,
            folderId: get(entity, 'id'),
          })),
          ['name'],
        );
        setUsers(users);
        let extendedDocuments: any[] = [];
        const documents = get(entity, 'documents', []);
        if (!isEmpty(documents)) {
          extendedDocuments = documents.map((item: any, index: any) => ({
            ...item,
            folderId: get(entity, 'id'),
            index,
            typeOfDocumentName: get(item, 'typeOfDocument.translateJson')
              ? get(
                  JSON.parse(get(item, 'typeOfDocument.translateJson')),
                  currentLocale,
                  get(item, 'typeOfDocument.name'),
                )
              : '?',
          }));
          setDocuments(extendedDocuments);
        }
      }
      if (!isEmpty(entity) && isArray(get(entity, 'studyIdentities'))) {
        setPreResult(get(entity, 'studyIdentities'));
      }
      refetchGrid();
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  const deleteSelectedStudies = (items: GridRowSelectionModel) => {
    storeConfirmationData({
      title:
        isArray(items) && items.length === 1
          ? 'confirmRemoveStudyFromFolder'
          : 'confirmRemoveStudiesFromFolder',
      id: 'studies',
      confirmAction: 'confirmActionRemoveStudies',
    });
  };
  const deleteAllStudies = () => {
    storeConfirmationData({
      title: 'confirmRemoveAllStudiesFromFolder',
      id: 'studies',
      confirmAction: 'confirmActionRemoveAllStudies',
    });
  };
  const extendExpirationSelectedStudies = () => {
    storeConfirmationData({
      title: 'confirmExtendExpiration',
      id: 'studies',
      confirmAction: 'confirmActionExtendExpiration',
    });
  };

  const callFunctionByName = (nameFunction: string, item: string | null = null) => {
    const items = item ? [item] : selection;
    setItems(items);
    if (nameFunction === 'deleteSelectedStudies') {
      deleteSelectedStudies(items);
    }
    if (nameFunction === 'deleteAllStudies') {
      deleteAllStudies();
    }
    if (nameFunction === 'extendExpirationSelectedStudies') {
      extendExpirationSelectedStudies();
    }
  };

  useEffect(() => {
    if (!isNull(previousGridSettings)) {
      ['sendInfos', 'folderInfos', 'hasAttachment', 'folderHistory'].some((fieldName) => {
        let breakExcecution = false;
        let isLoadedPreview = false;
        const previousGridSettingsHiddenColumnNames = get(
          previousGridSettings,
          'study.defaultHiddenColumnNames',
        );
        if (isArray(previousGridSettingsHiddenColumnNames)) {
          const valueOfPrevious = indexOf(previousGridSettingsHiddenColumnNames, fieldName);
          if (valueOfPrevious === -1) {
            isLoadedPreview = true;
          }
        }
        if (!isLoadedPreview) {
          const gridSettingsHiddenColumnNames = get(gridSettings, 'study.defaultHiddenColumnNames');
          if (isArray(gridSettingsHiddenColumnNames)) {
            const gridSettingsValue = indexOf(gridSettingsHiddenColumnNames, fieldName);
            if (!isLoadedPreview && gridSettingsValue === -1) {
              resetStateValues();
              loadEntity(id.toString());
              breakExcecution = true;
            }
          }
        }
        return breakExcecution;
      });
    }
    setPreviousGridSettings(gridSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridSettings]);

  const getEntities = async () => {
    toggleLoader();
    try {
      await loadEntity(id.toString());

      const productsByType = await getProductByType('clinical_portal');
      if (isArray(productsByType)) {
        const product = getFirstActiveProduct(productsByType);

        if (product) {
          const systemSettings = get(product, 'systemSetting', []);
          const maxExportDocumentsCount = get(
            getSystemSetting(systemSettings, 'variable', 'maxExportDocumentsCount'),
            'value',
          );
          setMaxCounts({
            ...maxCounts,
            maxExportDocumentsCount: maxExportDocumentsCount
              ? toNumber(maxExportDocumentsCount)
              : 5,
          });
        }
      }
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeStudyFromFolder = async ({ folderId, studyID, archiveID, callback }: any) => {
    toggleLoader();
    const removed = await removeStudy(folderId, archiveID, studyID);
    if (removed) {
      addSuccessAlert(t('submitRemovingStudyOk'));
      if (isFunction(callback)) {
        callback(folderId);
      }
    } else {
      addErrorAlert(t('submitRemovingStudyError'));
    }
    toggleLoader(false);
  };
  const removeStudyWithCallback = (studyID: string, archiveID: string) => {
    resetStateValues();
    removeStudyFromFolder({
      folderId: get(entity, 'id'),
      studyID,
      archiveID,
      callback: loadEntity,
    });
  };
  const removeStudyWithoutCallback = (studyID: string, archiveID: string) => {
    removeStudyFromFolder({
      folderId: get(entity, 'id'),
      studyID,
      archiveID,
    });
  };
  const onFolderRemove = async (id: any) => {
    toggleLoader();
    const removed = await removeFolder(id);
    if (removed) {
      addSuccessAlert(t('deleted'));
    } else {
      addErrorAlert(t('errorDeleting'));
    }
    toggleLoader(false);
    return removed ? true : false;
  };
  const extendExpiration = async (studyIdentities: any) => {
    const folderId = get(entity, 'id', 0);
    const response = await extendStudyExpiration({ folderId, studyIdentities });
    if (response) {
      addSuccessAlert(t('extendedExpiration'));
      resetStateValues();
      await loadEntity(folderId.toString());
    } else {
      addErrorAlert(t('errorExtendedExpiration'));
    }
  };

  const callAction = (action: string, url: string) => {
    navigate(url);
  };

  const confirmAction = async () => {
    const removed = await onFolderRemove(get(confirmationData, 'id'));
    if (removed) {
      callAction('delete', linkBack);
    }
    clearAction();
  };

  const confirmActionRemoveStudies = async () => {
    resetStudies(LIST_FOR_FOLDER);
    items.forEach((select, index) => {
      const study = decodeIID(select as string);
      const studyID = get(study, 'studyID', '');
      const archiveID = get(study, 'archiveID', '');
      items.length === index + 1
        ? removeStudyWithCallback(studyID, archiveID)
        : removeStudyWithoutCallback(studyID, archiveID);
    });
    clearAction();
  };
  const confirmActionRemoveAllStudies = async () => {
    resetStudies(LIST_FOR_FOLDER);
    rows.forEach((study: any, index: number) => {
      const studyID = get(study, 'studyInstanceUid', '');
      const archiveID = get(study, 'archive.id', '');
      rows.length === index + 1
        ? removeStudyWithCallback(studyID, archiveID)
        : removeStudyWithoutCallback(studyID, archiveID);
    });
    clearAction();
  };
  const confirmActionExtendExpiration = async () => {
    resetStudies(LIST_FOR_FOLDER);
    const studyIdentities = items.map((select, index) => {
      const study = decodeIID(select as string);
      const studyID = get(study, 'studyID', '');
      const archiveID = get(study, 'archiveID', '');
      return {
        studyInstanceUid: studyID,
        archive: { id: archiveID },
      };
    });
    extendExpiration(studyIdentities);
    clearAction();
  };

  const clearAction = () => storeConfirmationData(null);
  const cancelAction = () => {
    clearAction();
  };

  useEffect(() => {
    let canUpdate = false;
    let canDelete = false;
    let canRemoveStudyFromFolder = false;
    let canRemoveUser = false;
    let permission = '';
    let canExtendExpiration = false;
    if (!isEmpty(entity)) {
      const owner = get(entity, 'user.username') === username;
      if (!owner) {
        const getUser = find(get(entity, 'users', []), { username });
        permission = get(getUser, 'permission', '');
      }
      canRemoveStudyFromFolder =
        owner || permission === 'READ_PLUS_WRITE' || permission === 'WRITE_PLUS_UPDATE';
      canRemoveUser = owner || permission === 'WRITE_PLUS_UPDATE';
      canUpdate = owner || permission === 'WRITE_PLUS_UPDATE';
      canDelete = owner;
      canExtendExpiration =
        get(entity, 'expiration', null) && canRemoveStudyFromFolder ? true : false;

      const actions: any[] = [BASKET];
      if (canRemoveStudyFromFolder) {
        actions.push(REMOVE_STUDY_FROM_FOLDER, REMOVE_ALL_STUDIES_FROM_FOLDER);
      }
      if (defViewer) {
        actions.push(VIEWER);
      }
      if (canExtendExpiration) {
        actions.push(EXTEND_EXPIRATION);
      }
      const RenderedStudies = (
        <>
          {loadingStudies && (
            <Papeer>
              <Typography component="div">
                {`${t('loadingData')} ${loadedPercents}% `}
                <CircularProgress size={16} />
              </Typography>
            </Papeer>
          )}
          <SearchResultsMUI
            rows={rows}
            setRows={setRows}
            actions={actions}
            action={LIST_FOR_FOLDER}
            selecting={canRemoveStudyFromFolder}
            selection={selection}
            setSelection={setSelection}
            operations={{ canRemoveStudyFromFolder, canExtendExpiration, callFunctionByName }}
            folderId={id.toString()}
            dataTour="foldersDetailGrids"
          />
        </>
      );

      const fields = [
        {
          name: 'name',
          value: get(entity, 'name', ''),
        },
        {
          name: 'owner',
          value: `${joinParams([get(entity, 'user.lastName'), get(entity, 'user.firstName')])}`,
        },
      ];

      setCanUpdate(canUpdate);
      setCanDelete(canDelete);
      setRenderedStudies(RenderedStudies);
      setRenderedUsersGrid(
        <FolderDetailUsers
          loadEntity={loadEntity}
          users={users}
          canRemoveUser={canRemoveUser}
          dataTour="foldersDetailGrids"
        />,
      );
      setRenderedDocuments(
        <FolderDetailDocuments
          loadEntity={loadEntity}
          entity={entity}
          documents={documents}
          maxCounts={maxCounts}
          dataTour="foldersDetailGrids"
        />,
      );
      setFields(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, entity, loadingStudies, loadedPercents, selection.length]);

  const tabs = compact([
    hasRole('ROLE_CLINICAL_DOCUMENTS_IN_FOLDER')
      ? { value: TAB_DOCUMENTS, label: 'documents' }
      : null,
    { value: TAB_STUDIES, label: 'studies' },
    { value: TAB_USERS, label: 'users' },
  ]);
  const buttons = filter(
    [
      {
        show: canUpdate,
        onClick: () => {
          callAction(
            'edit',
            `${from === 'view' ? '' : '/administration'}/folders/${hashedId ? encryptId(id) : id}${
              from === 'view' ? '?from=view' : ''
            }`,
          );
        },
        disabled: !canUpdate,
        children: (
          <>
            <EditIcon sx={{ fontSize: 20, mr: 1 }} />
            {t('action.edit')}
          </>
        ),
      },
      {
        show: canDelete,
        onClick: () => {
          storeConfirmationData({ id });
        },
        disabled: !canDelete,
        children: (
          <>
            <DeleteIcon sx={{ fontSize: 20, mr: 1 }} />
            {t('action.delete')}
          </>
        ),
      },
    ],
    (button) => button.show !== false,
  ).map((button) => ({
    ...omit(button, ['show']),
  }));

  const RenderedTab = [];
  switch (activeTab) {
    case TAB_STUDIES:
      RenderedTab.push(<Fragment key={TAB_STUDIES}>{RenderedStudies}</Fragment>);
      break;
    case TAB_DOCUMENTS:
      RenderedTab.push(<Fragment key={TAB_DOCUMENTS}>{RenderedDocuments}</Fragment>);
      break;
    case TAB_USERS:
      RenderedTab.push(<Fragment key={TAB_USERS}>{RenderedUsersGrid}</Fragment>);
  }

  const renderedSteps = () => {
    return <TourFolders type="details" />;
  };

  return (
    <>
      <Header title={title} backUrl={linkBack} TourComponent={renderedSteps()} />
      {isEmpty(entity) && entity !== null ? (
        <Papeer>{`${t('folderIsNotAvailable')}`}</Papeer>
      ) : (
        entity !== null && (
          <>
            <Papeer>
              <Box data-tour="foldersDetailForm">
                <Typography component="div">
                  <table>
                    <tbody>
                      {fields.map((field: any) => (
                        <tr key={get(field, 'name')}>
                          <th style={{ textAlign: 'left', paddingRight: 16 }}>
                            <strong>{t(field.name)}</strong>:
                          </th>
                          <td>{field.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Typography>

                <Grid container={true} spacing={2}>
                  <Grid item={true}>
                    {buttons.map((button, index) => (
                      <Button
                        key={`button${index}`}
                        size="small"
                        color="inherit"
                        sx={{ mr: 1, mt: 1, border: '1px solid #ccc' }}
                        {...button}
                      />
                    ))}
                  </Grid>
                </Grid>
              </Box>
            </Papeer>

            <AppBar
              position="static"
              color="default"
              sx={{ mt: 2, mb: 2 }}
              data-tour="foldersDetailTabs"
            >
              <Tabs
                value={activeTab}
                onChange={(event, value) => setActiveTab(value)}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons={false}
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} value={tab.value} label={t(tab.label)} />
                ))}
              </Tabs>
            </AppBar>
            {RenderedTab}
          </>
        )
      )}

      {confirmationData &&
        confirmationData.id &&
        confirmationData.confirmAction !== DELETE_USER_FROM_FOLDER &&
        confirmationData.confirmAction !== DELETE_DOCUMENT_FROM_FOLDER &&
        confirmationData.confirmAction !== DELETE_DOCUMENTS_FROM_FOLDER && (
          <>
            <ConfirmationDialog
              title={confirmationData.title ? t(confirmationData.title) : t('confirmDelete')}
              open={true}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              cancelAction={cancelAction}
              confirmAction={
                confirmationData.confirmAction === 'confirmActionRemoveStudies'
                  ? confirmActionRemoveStudies
                  : confirmationData.confirmAction === 'confirmActionRemoveAllStudies'
                  ? confirmActionRemoveAllStudies
                  : confirmationData.confirmAction === 'confirmActionExtendExpiration'
                  ? confirmActionExtendExpiration
                  : confirmAction
              }
            />
          </>
        )}
    </>
  );
};

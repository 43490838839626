import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { each, filter, get, isArray, isEmpty, keys, omit, omitBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { endOfDay, parseISO, startOfDay, subDays } from 'date-fns';

import useValidationSchema from './_formSearch';
import { findOrderWithCancel } from './_api';
import { IOrdersSearch } from './_types';
import { getAllUnits } from 'modules/Administration/ModalitiesUnitsStations/_apiUnits';
import { getAllStations } from 'modules/Administration/ModalitiesUnitsStations/_apiStations';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';

import useAlerts from 'components/Alerts/useAlerts';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormDatePicker from 'components/Form/Date/Date';
import { useUser } from 'utils/hooks/useUser';
import { convertSearchDates } from 'utils/search';
import { DATE_BTN_CUSTOM_DATE } from 'constants/constants';
import { Box } from '@mui/system';
import { useStudy } from 'utils/hooks/useStudy';
import FormMultiSelect from 'components/Form/MultiSelect/MultiSelect';
import Button from 'components/Buttons/Button';
import { useStorage } from 'utils/hooks/useStorage';

const apiDateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss";
const apiDateFormat = 'yyyyMMdd';
const smallScreenButtonWidth = { minWidth: { xs: 120, sm: 'auto' } };
const FORM_DATA_KEY = 'orderSearchForm';

const OrderSearchForm: React.FC<any> = ({ searchAutomatically, setRows }) => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('SearchForm');
  const { hasRole } = useUser();

  const { addErrorAlert } = useAlerts();

  const { getItem, putItem, removeItem } = useStorage();

  const [units, setUnits] = useState<any[]>([]);
  const [stations, setStations] = useState<any[]>([]);
  const [hasCustomDate, setHasCustomDate] = useState(false);

  const { validators, modalities: availableModalities } = useAppInfo();
  const validators2 = get(validators, 'searchStudyOrderForm');

  const filterByUnit = hasRole('ROLE_MWL_FILTER_BY_UNIT');
  const filterByStation = hasRole('ROLE_MWL_FILTER_BY_STATION');

  const { SearchFormSchema, fields, dateSelectItems } = useValidationSchema(t, validators2);

  const methods = useForm<IOrdersSearch>({
    resolver: yupResolver(SearchFormSchema),
    defaultValues: { dateFrom: undefined, dateTo: undefined, date: DATE_BTN_CUSTOM_DATE },
  });

  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    register,
    trigger,
    formState: { isSubmitting },
  } = methods;
  const selectedDate = watch('date');

  const formValues = getValues();
  const canSearch = !isEmpty(omitBy(formValues, isEmpty));
  const { linkBack } = useStudy();

  const { toggleLoader } = useAppGlobals();

  const backFromDetail = linkBack();

  useEffect(() => {
    if (selectedDate === DATE_BTN_CUSTOM_DATE) {
      setHasCustomDate(true);
    } else {
      setHasCustomDate(false);

      // Set dates if selected date is not custom
      if (selectedDate) {
        const dateAttributes = convertSearchDates(
          selectedDate,
          get(formValues, 'dateFrom'),
          get(formValues, 'dateTo'),
          apiDateFormat,
        );
        setValue('dateFrom', new Date(parseISO(dateAttributes.dateFrom)));
        setValue('dateTo', new Date(parseISO(dateAttributes.dateTo)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const submitHandler = async (values: any) => {
    toggleLoader();
    callPersistForm(values);
    if (values.date) {
      const dateAttributes = convertSearchDates(
        values.date,
        get(values, 'dateFrom', ''),
        get(values, 'dateTo', ''),
        apiDateTimeFormat,
      );
      values = { ...values, from: dateAttributes.dateFrom, to: dateAttributes.dateTo };
    }

    let transformedValues = omitBy(
      omit(values, ['date', 'dateFrom', 'dateTo', 'extraModality']),
      isEmpty,
    );

    if (isArray(values.modalities) && values.modalities.length > 0) {
      transformedValues = {
        ...transformedValues,
        modalities: filter(availableModalities, (item) => values.modalities.includes(item.name)),
      };
    }

    if (isArray(values.stations) && values.stations.length > 0) {
      transformedValues = {
        ...transformedValues,
        stations: filter(stations, (item) => values.stations.includes(item.id)),
      };
    }

    if (isArray(values.units) && values.units.length > 0) {
      transformedValues = {
        ...transformedValues,
        units: filter(units, (item) => values.units.includes(item.id)),
      };
    }

    await findOrderWithCancel(transformedValues).then(
      (response) => {
        if (isArray(response)) {
          const orders = response.map((item, index) => ({
            ...item,
            uniqId: `${item.accessionNumber}_${get(item, 'patient.identificationNumber')}_${index}`,
            canUpdate: hasRole('ROLE_MWL_UPDATE'),
          }));
          setRows([...orders]);
        } else {
          setRows([]);
          addErrorAlert(t(response ? 'cancelSubmit' : 'submitError'));
        }
      },
      () => {
        setRows([]);
      },
    );
    toggleLoader(false);
  };

  const triggerSubmit = () => {
    refSubmitButtom?.current?.click();
  };

  const loadEntities = async () => {
    toggleLoader();
    const [units, stations] = await Promise.all([getAllUnits(), getAllStations()]);
    if (isArray(units)) {
      setUnits(units);
    }
    if (isArray(stations)) {
      setStations(stations.map((item) => ({ ...item, name: item.aetitle })));
    }

    try {
      if (backFromDetail) {
        const data = getItem(FORM_DATA_KEY);
        if (data) {
          // Parse it to a javaScript object
          const values = JSON.parse(data);
          const transformedValues = {
            ...values,
            dateFrom: values.dateFrom ? new Date(values.dateFrom) : null,
            dateTo: values.dateTo ? new Date(values.dateTo) : null,
          };
          each(keys(transformedValues), (key: any) => {
            setValue(key, get(transformedValues, key));
          });
          triggerSubmit();
        }
      } else {
        removeItem(FORM_DATA_KEY);
        if (searchAutomatically) {
          reset({
            date: 'customDate',
            dateFrom: startOfDay(subDays(new Date(), 1)),
            dateTo: endOfDay(new Date()),
          });
          triggerSubmit();
        }
      }
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    const keyboardListener = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        triggerSubmit();
      }
    };
    document.body.addEventListener('keydown', keyboardListener);

    loadEntities();

    return () => {
      document.body.removeEventListener('keydown', keyboardListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callPersistForm = (value: any) => {
    putItem(FORM_DATA_KEY, JSON.stringify(value));
  };

  const mainSx = { width: { xs: '100%', md: '50%', lg: '20%' } };

  const canFilterByUnits = filterByUnit && units.length > 0;
  const canFilterByStations = filterByStation && stations.length > 0;

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <button hidden={true} ref={refSubmitButtom} type={'submit'} disabled={isSubmitting} />
          <input {...register('date')} type="hidden" />

          <Box sx={{ position: 'relative', boxShadow: 'none' }}>
            <Box sx={{ position: 'relative' }} data-tour="fieldsTours">
              <Papeer bottomMargin={true} sx={{ pt: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: {
                      xs: 'wrap',
                      lg: 'nowrap',
                    },
                    paddingRight: { xs: '0px', sm: 0 },
                    position: { xs: 'static', sm: 'relative' },
                  }}
                >
                  {fields.map((field) => (
                    <Box sx={mainSx} key={field.name}>
                      {field.type === 'input' && <FormInput {...field} type="text" />}
                      {field.type === 'select' && (
                        <FormMultiSelect
                          name="modalities"
                          label={t('modality')}
                          items={availableModalities.map((modality) => ({
                            id: modality.name,
                            label: modality.name,
                          }))}
                          sx={{ borderRadius: 0 }}
                          trigger={trigger}
                          includeAllOption={true}
                        />
                      )}
                    </Box>
                  ))}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: {
                      xs: 'wrap',
                      lg: 'nowrap',
                    },
                    paddingRight: { xs: '0px', sm: 0 },
                    position: { xs: 'static', sm: 'relative' },
                  }}
                >
                  <Box sx={mainSx}>
                    <FormSelect
                      name="date"
                      label={t('orderDate')}
                      items={dateSelectItems}
                      sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                      clearable={true}
                      clearCallback={() => {
                        setValue('dateFrom', null);
                        setValue('dateTo', null);
                      }}
                    />
                  </Box>
                  <Box sx={mainSx}>
                    <FormDatePicker
                      name="dateFrom"
                      label={t('from')}
                      sx={{ borderRadius: 0 }}
                      disabled={!hasCustomDate}
                    />
                  </Box>
                  <Box sx={mainSx}>
                    <FormDatePicker
                      name="dateTo"
                      label={t('to')}
                      sx={{ borderRadius: 0 }}
                      disabled={!hasCustomDate}
                    />
                  </Box>
                  {canFilterByUnits && (
                    <Box sx={mainSx}>
                      <FormMultiSelect
                        name="units"
                        label={t('units')}
                        items={units.map((unit) => ({
                          id: unit.id,
                          label: unit.name,
                        }))}
                        sx={
                          canFilterByStations
                            ? { borderRadius: 0 }
                            : { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
                        }
                        trigger={trigger}
                        includeAllOption={true}
                      />
                    </Box>
                  )}
                  {canFilterByStations && (
                    <Box sx={mainSx}>
                      <FormMultiSelect
                        name="stations"
                        label={t('stations')}
                        items={stations.map((station) => ({
                          id: station.id,
                          label: station.name,
                        }))}
                        sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        trigger={trigger}
                        includeAllOption={true}
                      />
                    </Box>
                  )}
                </Box>

                <Grid container={true} justifyContent="flex-end">
                  <Grid item={true}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 1,
                        flexDirection: {
                          xs: 'column',
                          sm: 'row',
                        },
                      }}
                    >
                      <Button
                        sx={smallScreenButtonWidth}
                        variant="contained"
                        color="inherit"
                        onClick={() => {
                          reset();
                        }}
                        data-tour="orders-clearButton"
                      >
                        {t('reset')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!canSearch}
                        className="search-form-button"
                        data-tour="searchButtonTours"
                      >
                        {t('search')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Papeer>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default OrderSearchForm;

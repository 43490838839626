import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

export const TourStudiesList = () => {
  const { t } = useTranslation('Reactour');

  const allSteps = () => {
    const steps = {
      '[data-tour="backButton"]': t('study.back'),
      '[data-tour="addToFolder-NewFolder"]': t('study.addToFolder.newFolder'),
      '[data-tour="addToFolder-GridExistingFolders"]': t('study.addToFolder.gridExistingFolders'),
      '[data-tour="addToFolder-ListOfStudy"]': t('study.addToFolder.listOfStudy'),
      '[data-tour="addToFolder-AddToFolderButton"]': t('study.addToFolder.addToFolderButton'),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });

  return <HelperComponent />;
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { IRequestDetailFolders } from './_types';
import { joinParams } from 'utils/study';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'requestDetailFolders';

export const RequestDetailFolders: React.FC<IRequestDetailFolders> = ({ rows }) => {
  const { t } = useTranslation('Request');

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      { field: 'name', headerName: t('name') },
      {
        field: 'owner',
        headerName: t('owner'),
        valueGetter: (value: any, row: any) =>
          joinParams([get(row, 'user.lastName'), get(row, 'user.firstName')]),
      },
    ]),
  );

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={rows}
        columns={columns}
        withoutMaxHight={true}
        hideFooter={true}
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />
    </>
  );
};

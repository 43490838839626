import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compact, filter, find, get, includes, isUndefined } from 'lodash';
import { format } from 'date-fns';
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowModel,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { Box, Button, Tooltip } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { getAllStudies, studyUpdate } from './_apiStudy';
import { IStudy, IStudyDatabaseAdministrationRequests } from './_types';
import { IFunctionItem } from '../Folders/_types';
import Header from 'components/Header/Header';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useActions } from 'utils/hooks/useActions';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { SEXES } from 'constants/constants';

const selecting = true;
const DELETE_STUDIES_FROM_DB = 'delete_studies_from_db';

const Studies: React.FC = () => {
  const { t } = useTranslation('Studies');
  useWithTitle();
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();
  const [allStudies, setAllStudies] = useState<IStudy[]>([]);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);

  const { storeConfirmationData } = useActions();

  const loadEntity = async () => {
    toggleLoader();
    try {
      const allStudies = await getAllStudies();
      setAllStudies(allStudies);
    } catch (e) {
      console.debug(e);
    } finally {
      toggleLoader(false);
    }
  };

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const { onEntityRemove } = useEntityRemove(studyUpdate, t, loadEntity);

  const deleteStudies = async (
    studyDatabaseAdministrationRequests: IStudyDatabaseAdministrationRequests,
  ) => {
    await onEntityRemove(studyDatabaseAdministrationRequests);
    clearAction();
  };

  const confirmAction = () => {
    const studyDatabaseAdministrationRequests = {
      studyDatabaseAdministrationRequests: [
        { uid: confirmationData.uid, archiveId: confirmationData.archiveId, isDeleted: true },
      ],
    };
    deleteStudies(studyDatabaseAdministrationRequests);
  };

  const confirmActionDeleteSelected = () => {
    const selectedStudies = filter(allStudies, (item) => includes(selection, item.id));
    const studyDatabaseAdministrationRequests = selectedStudies.map((study) => ({
      uid: study.uid,
      archiveId: get(study, 'sourceArchive.id'),
      isDeleted: true,
    }));
    deleteStudies({ studyDatabaseAdministrationRequests });
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings, MuiGrid } =
    useMuiGrid('studiesMUI');

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      compact([
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          width: 100,
          renderCell: ({ row }: GridRenderCellParams) => {
            return (
              <>
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t('Grid:delete')}>
                      <DeleteIcon />
                    </Tooltip>
                  }
                  label={t('Grid:delete')}
                  onClick={() => {
                    dispatch(
                      storeConfirmationDataToStore({
                        uid: get(row, 'uid'),
                        archiveId: get(row, 'sourceArchive.id'),
                      }),
                    );
                  }}
                />
              </>
            );
          },
        },
        {
          field: 'isDeleted',
          headerName: t('isDeleted'),
          valueGetter: (value: any) => (value ? t('Languages:yes') : t('Languages:no')),
        },
        {
          field: 'name',
          headerName: t('name'),
          valueGetter: (value: any, row: any) => {
            const patientName = [
              get(row, 'patient.lastName', ''),
              get(row, 'patient.firstName', ''),
              get(row, 'patient.prefix'),
              get(row, 'patient.suffix'),
            ];
            return compact(patientName).join(', ').trim();
          },
          hideable: false,
        },
        {
          field: 'patientId',
          headerName: t('patientId'),
          valueGetter: (value: any, row: any) => get(row, 'patient.identificationNumber', ''),
        },
        { field: 'accessionNumber', headerName: t('accessionNumber') },
        {
          field: 'patientSex',
          headerName: t('patientSex'),
          width: 70,
          valueGetter: (value: any, row: any) => {
            const patientSex = get(row, 'patient.sex', '');
            return find(SEXES, { value: patientSex }) !== undefined
              ? t(`patient_sex_${patientSex}`)
              : patientSex;
          },
        },
        {
          field: 'patientBirthDate',
          headerName: t('patientBirthDate'),
          width: 125,
          type: 'date',
          valueGetter: (value: any, row: any) => {
            const date = get(row, 'patient.dateOfBirth', '');
            return date && new Date(date);
          },
          valueFormatter: (value: any) => value && format(value, 'dd. MM. yyyy'),
        },
        {
          field: 'dateTime',
          headerName: t('dateTime'),
          width: 150,
          type: 'dateTime',
          valueGetter: (value: any) => value && new Date(value),
          valueFormatter: (value: any) => value && format(value, 'dd. MM. yyyy HH:mm'),
        },
        {
          field: 'modality',
          headerName: t('modalities'),
          width: 80,
        },
        {
          field: 'noOfInstances',
          headerName: t('noOfInstances'),
          width: 70,
          valueGetter: (value: any, row: any) =>
            `${get(row, 'numberOfSeries', '')}/${get(row, 'numberOfImages', '')}`,
        },
        {
          field: 'description',
          headerName: t('studyDescription'),
          width: 350,
        },
        {
          field: 'archiveName',
          headerName: t('archive'),
          width: 110,
          valueGetter: (value: any, row: any) => get(row, 'sourceArchive.name', ''),
        },
      ]),
    ),
  );

  const handleClickRemoveDocumentsFromFolder = useCallback(() => {
    storeConfirmationData({
      title: t('confirmRemoveStudiesFromDB'),
      id: 'studiesdocuments',
      confirmAction: DELETE_STUDIES_FROM_DB,
    });
  }, [storeConfirmationData, t]);

  const functionList: IFunctionItem[] = useMemo(
    () => [
      {
        key: DELETE_STUDIES_FROM_DB,
        label: t('multipleRemoveStudiesFromDB'),
        show: true,
        onClick: (row: GridRowModel | null) => {
          handleClickRemoveDocumentsFromFolder();
        },
        icon: <DeleteIcon fontSize="small" />,
      },
    ],
    [t, handleClickRemoveDocumentsFromFolder],
  );

  const QuickSearchToolbar = useCallback(
    (props: any) => {
      return (
        <div>
          <Box style={{ padding: '5px', display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
            </Box>
          </Box>
          <Box
            sx={{
              px: 1,
              py: 0.5,
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {functionList.map((item: IFunctionItem, index: number) => {
              const showCount = isUndefined(item.showCount) ? true : item.showCount;
              const count = selection.length;
              const xcount = showCount && count ? ` (${count})` : '';
              const maxAllowedItem = isUndefined(item.maxAllowedItem) ? 0 : item.maxAllowedItem;
              return (
                <Tooltip key={index} title={item.label + xcount}>
                  <span>
                    <Button
                      key={item.key}
                      size={'medium'}
                      variant="contained"
                      color="primary"
                      onClick={() => item.onClick(null)}
                      disabled={
                        selection.length === 0 ||
                        (maxAllowedItem > 0 && maxAllowedItem < selection.length)
                      }
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {item.icon}
                    </Button>
                  </span>
                </Tooltip>
              );
            })}
          </Box>
        </div>
      );
    },
    [selection, functionList],
  );

  const RenderedGrid = () =>
    MuiGrid({
      rows: allStudies,
      columns,
      rowSelecting: { selecting, selection, setSelection },
      toolbar: QuickSearchToolbar,
      initialSortMode: [{ field: 'name', sort: 'asc' }],
    });

  return (
    <>
      <Header title="" topMargin={true} />
      <RenderedGrid />
      {confirmationData &&
        ((confirmationData.uid && confirmationData.archiveId) ||
          (confirmationData.confirmAction === DELETE_STUDIES_FROM_DB && confirmationData.id)) &&
        !confirmationData.editDialog && (
          <ConfirmationDialog
            title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={clearAction}
            confirmAction={
              confirmationData.confirmAction === DELETE_STUDIES_FROM_DB
                ? confirmActionDeleteSelected
                : confirmAction
            }
          />
        )}
    </>
  );
};

export default Studies;

import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IConnectedModalitiesForm, IConnectedModality } from './_types.';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import FormSelect from 'components/Form/Select/Select';
import { Papeer } from 'components/Papeer/Papeer';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useUser } from 'utils/hooks/useUser';
import { find, get, isArray, isEmpty, isNumber } from 'lodash';
import { IArchive } from 'modules/Search/_types';
import { useLocation } from 'react-router';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { getDPGWUrl } from './_api';
import { ConnectedModalitiesForm } from './ConnectedModalitiesForm';

const archiveIsTypeOf = (archive: IArchive, type: string) => {
  const typeName = get(archive, 'type.name', '');
  return typeName === type;
};

export const ConnectedModalities: React.FC = () => {
  const { archives: products } = useAppInfo();
  const { hasRole } = useUser();
  const location = useLocation();
  const { t } = useTranslation('ConnectedModalities');
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [selectedArchive, setSelectedArchive] = useState<any>({});
  const { toggleLoader } = useAppGlobals();
  const [redirectObject, setRedirectObject] = useState<any>({ url: '', canExecute: false });
  const [loginObject, setLoginObject] = useState<any>({ url: '', canExecute: false });
  const methods = useForm<IConnectedModalitiesForm>({ defaultValues: {} });
  const [dpgwTab, setDpgwTab] = useState<any>(null);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [entityForEdit, setEntityForEdit] = useState<IConnectedModality | null>(null);

  const { handleSubmit, watch } = methods;
  const archives = watch('archives');

  useEffect(() => {
    if (isNumber(archives)) {
      handleSelectedArchive(archives);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archives]);

  const formSubmit = handleSubmit(async (values) => {
    console.debug({ values });
  });

  useEffect(() => {
    if (redirectObject.canExecute) {
      if (dpgwTab !== null) {
        dpgwTab.location.replace(get(redirectObject, 'url', '')); // If this block is executed in IE11, there is undefined error
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectObject]);

  useEffect(() => {
    if (loginObject.canExecute) {
      const dpgwTab = window.open('', '_blank');
      setDpgwTab(dpgwTab);
      if (dpgwTab !== null) {
        dpgwTab.location.replace(get(loginObject, 'url', ''));
        setTimeout(() => {
          setRedirectObject({ ...redirectObject, canExecute: true });
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginObject]);

  const openDPGW = async (selectedArchive: IArchive) => {
    toggleLoader(true);

    const urls = await getDPGWUrl(selectedArchive.id);
    const loginUrl = get(urls, 'loginUrl', '');
    setRedirectObject({ url: get(urls, 'redirectUrl', ''), canExecute: false });
    setLoginObject({ url: loginUrl, canExecute: loginUrl === '' ? false : true });

    toggleLoader(false);
  };

  const handleSelectedArchive = (archiveID: number) => {
    const selectedArchive = find(products, ['id', archiveID]);
    setSelectedArchive({});
    if (!selectedArchive) {
      return;
    }
    if (archiveIsTypeOf(selectedArchive, 'DPGW')) {
      openDPGW(selectedArchive);
    }
  };

  useEffect(() => {
    if (isArray(products)) {
      const hasDpgwRole = hasRole('ROLE_ADMINISTRATE_MODALITIES_IN_DPGW');
      const hasMarieRole = hasRole('ROLE_ADMINISTRATE_MODALITIES_IN_MARIE');
      setFilteredProducts(
        products
          .filter((product) => {
            if (hasDpgwRole && hasMarieRole) {
              return archiveIsTypeOf(product, 'MS') || archiveIsTypeOf(product, 'DPGW');
            } else if (hasDpgwRole) {
              return archiveIsTypeOf(product, 'DPGW');
            } else if (hasMarieRole) {
              return archiveIsTypeOf(product, 'MS');
            } else {
              return false;
            }
          })
          .map((archive) => ({ id: archive.id, label: archive.name })),
      );

      const selectedArchiveFromForm = get(location, 'search', '');
      if (selectedArchiveFromForm.includes('?selectedArchive=')) {
        const id = selectedArchiveFromForm.replace('?selectedArchive=', '');
        if (id !== '') {
          const archiveID = parseInt(id, 0);
          methods.setValue('archives', archiveID);
          handleSelectedArchive(archiveID);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <>
      <Papeer>
        {isEmpty(filteredProducts) ? (
          <>{t('missingArchives')}</>
        ) : (
          <FormProvider {...methods}>
            <form onSubmit={formSubmit}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} md={6} lg={4} xl={2}>
                  <FormSelect
                    name="archives"
                    label={t('archives')}
                    items={filteredProducts || []}
                  />
                </Grid>

                {selectedArchive.id ? (
                  <>
                    <Grid item={true} xs={12} md={4} lg={3}>
                      <Button
                        onClick={() => setShowAddModal(true)}
                        variant="contained"
                        sx={{ mt: 1 }}
                      >
                        {t('add')}
                      </Button>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </form>
          </FormProvider>
        )}
      </Papeer>

      {showAddModal ? (
        <ConnectedModalitiesForm
          entityForEdit={entityForEdit}
          onClose={() => {
            setEntityForEdit(null);
            setShowAddModal(false);
          }}
        />
      ) : null}
    </>
  );
};

import {
  drop,
  find,
  get,
  isArray,
  isEmpty,
  isNull,
  isString,
  keys,
  omitBy,
  sortBy,
  split,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { getNotification, getNotificationByRequest, setNotificationState } from './_api';
import {
  ARCHIVED,
  NOTIFICATIONS_STATE_NEW,
  SEARCH_FUNCTION_COPY,
  SEARCH_FUNCTION_CREATE_SR,
  SEARCH_FUNCTION_EDIT_PATIENT,
  SEARCH_FUNCTION_EDIT_STUDY,
  SEARCH_FUNCTION_EXPORT,
  SEARCH_FUNCTION_IMPORT,
  SEARCH_FUNCTION_MOVE,
  SEARCH_FUNCTION_REOPEN,
  SEARCH_FUNCTION_REORDER,
  SEARCH_FUNCTION_SEND,
  SEARCH_FUNCTION_SPLIT,
  STATE_FAILED,
  STATE_FINISHED,
  STATE_NEW,
  STATE_WAITING,
  TAB_OPERATIONS,
} from 'constants/constants';
import { getRequest } from 'modules/Requests/_api';
import { format } from 'date-fns';
import useAlerts from 'components/Alerts/useAlerts';
import { useStudy } from 'utils/hooks/useStudy';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useUser } from 'utils/hooks/useUser';
import { sign } from 'modules/Studies/StudyExport/_api';
import { generateIID, joinParams } from 'utils/study';
import { paletteColorDark, paletteColorLightest } from 'utils/variables';
import { encryptId } from 'utils/hooks/useApp';
import Header from 'components/Header/Header';
import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import DownloadIcon from '@mui/icons-material/Download';
import SignatureIcon from '@mui/icons-material/AssignmentInd';
import HelpIcon from '@mui/icons-material/Help';
import { Announcement } from 'components/Announcement/Announcement';
import { Papeer } from 'components/Papeer/Papeer';
import {
  IEditPatientData,
  IEditPatientRequest,
  IOperationData,
  IWebsocketNotification,
  IWebsocketNotificationDetail,
} from './_types';
import { IRequestStudy } from 'modules/Requests/_types';
import { IStudy } from 'modules/Studies/StudyDetail/_types';
import { getTranslation } from 'utils/translationUtils';
import i18n from 'constants/translations/i18n';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const textLowercase = (text: string) => (isString(text) ? text.toLowerCase() : '');
const muiGridKey = 'websocketNotification';

export const WebsocketNotificationDetail = () => {
  const { archives: products, isDarkMode } = useAppInfo();
  const { t } = useTranslation('WebsocketNotifications');
  useWithEntityTitle(false, null, t);
  const { toggleLoader } = useAppGlobals();
  const { addSuccessAlert, addErrorAlert } = useAlerts();
  const { getPatientSexSlug } = useStudy();
  const { hasRole } = useUser();
  const { isCreating } = useEntityInfo();
  const { id } = useParams();
  const navigate = useNavigate();

  const [entity, setEntity] = useState<IWebsocketNotification>();
  const [linkBack, setLinkBack] = useState<string>('/notifications');
  const [operationData, setOperationData] = useState<IOperationData>();
  const [notificationData, setNotificationData] = useState<IWebsocketNotificationDetail>();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  useEffect(() => {
    const initialize = async () => {
      if (!isCreating) {
        toggleLoader();
        const arrayFromId = split(id, '=');
        const isFromRequest = arrayFromId[0] === 'requestId';
        if (isFromRequest) {
          const detailIID = arrayFromId.length > 2 ? arrayFromId[2] : '';
          setLinkBack(
            detailIID
              ? `/study/${detailIID}?tab=${TAB_OPERATIONS}`
              : '/reports/auditLogs/?action=back',
          );
        }
        const idFromArray = isFromRequest ? arrayFromId[1] : arrayFromId[0];
        const fn = isFromRequest ? getNotificationByRequest : getNotification;
        const entity: IWebsocketNotification = await fn(+idFromArray);
        if (entity) {
          const operationType = get(entity, 'requestId.operationType', '').toLowerCase();
          const isEditPatient = operationType === SEARCH_FUNCTION_EDIT_PATIENT.toLowerCase();
          let newPatient = {};
          let oldPatient = {};
          let std = {};
          if (isEditPatient) {
            const requestId = get(entity, 'requestId.id');
            const request = (await getRequest(requestId)) as IEditPatientRequest;
            if (request) {
              const data: IEditPatientData = get(request, 'data');
              const studyRequestStudies = sortBy(get(request, 'studyRequestStudies'), 'id');
              const reqId = get(request, 'id', 0);

              const providedStudies: IStudy[] = studyRequestStudies.map((study: IRequestStudy) => {
                return (
                  get(study, 'provided', null) && {
                    operationType: get(request, 'operationType', ''),
                    studyType: 'provided',
                    state: get(study, 'state', ''),
                    reqId,
                    ...get(study, 'provided', null),
                  }
                );
              }) as IStudy[];
              const patientName = {
                ...(get(data, 'patient.name.lastName')
                  ? { patientLastName: get(data, 'patient.name.lastName') }
                  : {}),
                ...(get(data, 'patient.name.firstName')
                  ? { patientFirstName: get(data, 'patient.name.firstName') }
                  : {}),
                ...(get(data, 'patient.name.middleName')
                  ? { patientMiddleName: get(data, 'patient.name.middleName') }
                  : {}),
                ...(get(data, 'patient.name.prefix')
                  ? { patientPrefix: get(data, 'patient.name.prefix') }
                  : {}),
                ...(get(data, 'patient.name.suffix')
                  ? { patientSuffix: get(data, 'patient.name.suffix') }
                  : {}),
              };
              const referringPhysician = {
                ...(get(data, 'referringPhysician.name.lastName')
                  ? { referringPhysicianLastName: get(data, 'referringPhysician.name.lastName') }
                  : {}),
                ...(get(data, 'referringPhysician.name.firstName')
                  ? { referringPhysicianFirstName: get(data, 'referringPhysician.name.firstName') }
                  : {}),
                ...(get(data, 'referringPhysician.name.middleName')
                  ? {
                      referringPhysicianMiddleName: get(data, 'referringPhysician.name.middleName'),
                    }
                  : {}),
                ...(get(data, 'referringPhysician.name.prefix')
                  ? { referringPhysicianPrefix: get(data, 'referringPhysician.name.prefix') }
                  : {}),
                ...(get(data, 'referringPhysician.name.suffix')
                  ? { referringPhysicianSuffix: get(data, 'referringPhysician.name.suffix') }
                  : {}),
              };
              const requestingPhysician = {
                ...(get(data, 'requestingPhysician.name.lastName')
                  ? { requestingPhysicianLastName: get(data, 'requestingPhysician.name.lastName') }
                  : {}),
                ...(get(data, 'requestingPhysician.name.firstName')
                  ? {
                      requestingPhysicianFirstName: get(data, 'requestingPhysician.name.firstName'),
                    }
                  : {}),
                ...(get(data, 'requestingPhysician.name.middleName')
                  ? {
                      requestingPhysicianMiddleName: get(
                        data,
                        'requestingPhysician.name.middleName',
                      ),
                    }
                  : {}),
                ...(get(data, 'requestingPhysician.name.prefix')
                  ? { requestingPhysicianPrefix: get(data, 'requestingPhysician.name.prefix') }
                  : {}),
                ...(get(data, 'requestingPhysician.name.suffix')
                  ? { requestingPhysicianSuffix: get(data, 'requestingPhysician.name.suffix') }
                  : {}),
              };

              const studyData = {
                ...(get(data, 'studyDescription')
                  ? { studyDescription: get(data, 'studyDescription') }
                  : {}),
                ...(data?.studyDate ? { studyDate: format(data.studyDate, 'DD. MM. YYYY') } : {}),
                ...(get(data, 'studyTime') ? { studyTime: get(data, 'studyTime') } : {}),
                ...(get(data, 'accessionNumber')
                  ? { accessionNumber: get(data, 'accessionNumber') }
                  : {}),
              };
              const newPatientSexValue = get(data, 'patient.sex');
              const newPatientDateBirth = get(data, 'patient.dateBirth');
              newPatient = {
                ...patientName,
                ...(get(data, 'patient.identificationNumber')
                  ? { patientId: get(data, 'patient.identificationNumber') }
                  : {}),
                patientDateBirth: newPatientDateBirth
                  ? format(new Date(newPatientDateBirth), 'dd. MM. yyyy')
                  : '',
                patientSex:
                  newPatientSexValue && typeof newPatientSexValue === 'string'
                    ? t(getPatientSexSlug(newPatientSexValue))
                    : '',
                ...studyData,
                ...referringPhysician,
                ...requestingPhysician,
              };

              const oldPatientSexValue = get(data, 'oldPatient.sex');
              const oldPatientDateBirth = get(data, 'oldPatient.dateBirth');
              oldPatient = omitBy(
                {
                  patientLastName: get(data, 'oldPatient.name.lastName'),
                  patientFirstName: get(data, 'oldPatient.name.firstName'),
                  patientMiddleName: get(data, 'oldPatient.name.middleName'),
                  patientPrefix: get(data, 'oldPatient.name.prefix'),
                  patientSuffix: get(data, 'oldPatient.name.suffix'),
                  patientId: get(data, 'oldPatient.identificationNumber'),
                  patientDateBirth: oldPatientDateBirth
                    ? format(new Date(oldPatientDateBirth), 'dd. MM. yyyy')
                    : '',
                  patientSex:
                    oldPatientSexValue && typeof oldPatientSexValue === 'string'
                      ? t(getPatientSexSlug(oldPatientSexValue))
                      : '',
                },
                isNull,
              );
              std = {
                ...(get(request, 'status') === STATE_FINISHED ? { providedStudies } : {}),
              };
            }
          }
          setEntity(
            isEditPatient
              ? { ...entity, data: { newPatient, std, oldPatient, operationType } }
              : entity,
          );
        } else {
          addErrorAlert(t(isFromRequest ? 'noNotificationForRequestId' : 'noNotification'));
        }
        toggleLoader(false);
      }
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const operationType = get(entity, 'requestId.operationType');
    const eventTypeLanguageKey: string = get(entity, 'templateId.eventType.languageKey', '');
    const isEditStudy = operationType === SEARCH_FUNCTION_EDIT_STUDY;
    const isEditPatient = operationType === SEARCH_FUNCTION_EDIT_PATIENT;
    const isReorder = operationType === SEARCH_FUNCTION_REORDER;
    const isSplit = operationType === SEARCH_FUNCTION_SPLIT;
    const isExport = operationType === SEARCH_FUNCTION_EXPORT;
    const isReopen = operationType === SEARCH_FUNCTION_REOPEN;
    const isShredding =
      eventTypeLanguageKey === 'event.type.shredding.study.ok' ||
      eventTypeLanguageKey === 'event.type.shredding.study.nok';
    const canDownloadExport = isExport && get(entity, 'requestId.status') === STATE_FINISHED;
    const canSign =
      hasRole('ROLE_CAN_SIGN_STUDY') &&
      hasRole('ROLE_LIST_UNSIGNED_STUDIES') &&
      (isEditStudy || isEditPatient || isReorder || isSplit) &&
      get(entity, 'requestId.status') === STATE_FINISHED;
    setOperationData({
      isEditStudy,
      isEditPatient,
      isReopen,
      isCopy: operationType === SEARCH_FUNCTION_COPY,
      isMove: operationType === SEARCH_FUNCTION_MOVE,
      isSend: operationType === SEARCH_FUNCTION_SEND,
      isReorder,
      isSplit,
      isImport: operationType === SEARCH_FUNCTION_IMPORT,
      isExport,
      canDownloadExport,
      canSign,
      isCreateSr: operationType === SEARCH_FUNCTION_CREATE_SR,
      isShredding,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  useEffect(() => {
    if (get(entity, 'state')) {
      const studies = sortBy(get(entity, 'requestId.studyRequestStudies'), 'id');
      const notifyId = get(entity, 'id', 0);
      const operationType = get(entity, 'requestId.operationType');
      const eventTypeLanguageKey: string = get(entity, 'templateId.eventType.languageKey', '');
      const isShredding =
        eventTypeLanguageKey === 'event.type.shredding.study.ok' ||
        eventTypeLanguageKey === 'event.type.shredding.study.nok';

      const requestedStudies: IStudy[] = studies.map((study: IRequestStudy) => {
        return {
          operationType,
          isShredding,
          studyType: 'requested',
          state: get(study, 'state', ''),
          notifyId,
          ...get(study, 'requested', null),
        };
      }) as IStudy[];

      const providedStudies: IStudy[] = studies.map((study: IRequestStudy) => {
        return {
          operationType,
          studyType: 'provided',
          state: get(study, 'state', ''),
          notifyId,
          ...get(study, 'provided', null),
        };
      }) as IStudy[];

      const errorType: string = get(entity, 'requestId.responseData.errorType', '');
      let errorDescription = '';
      let inArchive = '';
      let tooltip = '';
      if (
        eventTypeLanguageKey === 'event.type.upload.study.warn' &&
        (errorType === '1' || errorType === '2' || errorType === '12')
      ) {
        errorDescription = get(entity, 'requestId.responseData.errorDescription', '');
        const processedFiles = get(entity, 'requestId.responseData.processedFiles', '?');
        const countFiles = get(entity, 'requestId.responseData.countFiles', '?');
        const notifyType = eventTypeLanguageKey === 'event.type.upload.study.warn' ? 'warn' : 'nok';
        inArchive =
          ' ' +
          t(`event.type.upload.study.${notifyType}.${errorType}`, {
            par1: processedFiles,
            par2: countFiles,
          });
      }
      if (
        eventTypeLanguageKey === 'event.type.update.patient.ok' ||
        eventTypeLanguageKey === 'event.type.update.patient.nok'
      ) {
        inArchive = get(entity, 'sourceProductCode', '');
        const product = find(products, ['code', inArchive]);
        if (!isEmpty(product)) {
          inArchive = get(product, 'name', inArchive);
        }
      }
      if (eventTypeLanguageKey === 'event.type.study.request.created') {
        inArchive = operationType ? t(textLowercase(operationType)) : '';
      }

      if (operationType === SEARCH_FUNCTION_SEND) {
        const isSejf = get(entity, 'requestId.studySendRequestInfo.sejf', false);
        const exchangeNetworkName = get(
          entity,
          'requestId.studySendRequestInfo.exchangeNetworkName',
          '',
        );
        const facilityName = get(entity, 'requestId.studySendRequestInfo.facilityName', null);
        inArchive = ` ${t('targetNode')} ${isSejf ? t('drSejf') : exchangeNetworkName}${
          facilityName ? `, ${facilityName}` : ''
        } `;
      }
      const isNok =
        eventTypeLanguageKey.substring(
          eventTypeLanguageKey.length - 3,
          eventTypeLanguageKey.length,
        ) === 'nok';
      if (isNok) {
        errorDescription = get(entity, 'requestId.responseData.errorDescription', '');
        inArchive +=
          ' ' +
          t(
            `nok.${
              errorType === '1' || errorType === '2' || errorType === '4' || errorType === '12'
                ? errorType
                : 'unknown'
            }`,
          );
        if (errorType === '1' || errorType === '2' || errorType === '4') {
          tooltip = errorType;
        }
      }

      const operation =
        eventTypeLanguageKey === 'event.type.shredding.study.ok' ||
        eventTypeLanguageKey === 'event.type.shredding.study.nok'
          ? t('shredding')
          : t(textLowercase(get(entity, 'requestId.operationType', '')));

      setNotificationData({
        title: getTranslation(
          eventTypeLanguageKey,
          i18n.getResourceBundle(i18n.language, 'WebsocketNotifications'),
        ),
        notification: {
          created: format(
            new Date(get(entity, 'requestId.modifiedWhen', '')),
            'dd. MM. yyyy HH:mm',
          ),
          operation,
          state: t(textLowercase(get(entity, 'state', ''))),
          status: t(textLowercase(get(entity, 'requestId.status', ''))),
          subject:
            getTranslation(
              eventTypeLanguageKey,
              i18n.getResourceBundle(i18n.language, 'WebsocketNotifications'),
            ) + inArchive,
          ...(errorDescription ? { errorDescription } : {}),
        },
        tooltip,
        requestedStudies,
        providedStudies,
        statusKey: get(entity, 'requestId.status', ''),
      });
    } else {
      setNotificationData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, products]);

  const columnsStudies = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'state',
        headerName: t('studyState'),
        renderCell: ({ row }: any) => {
          const value = row.state;
          return (
            <Box>
              {value && value.toUpperCase() === NOTIFICATIONS_STATE_NEW ? (
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    bgcolor: 'primary.main',
                    borderRadius: 8,
                    display: 'inline-block',
                    mr: 1,
                  }}
                />
              ) : null}
              {value && t(`Request:${value.toLowerCase()}`)}
            </Box>
          );
        },
      },
      {
        field: 'name',
        headerName: t('grid.study.patientName'),
        renderCell: ({ row }: any) => {
          const state = get(row, 'state', '');
          const notifyId = get(row, 'notifyId', 0);
          const studyType = get(row, 'studyType', '');
          const operationType = get(row, 'operationType', '');
          const isShredding = get(row, 'isShredding', false);
          const patientName = joinParams([
            get(row, 'patient.name.lastName'),
            get(row, 'patient.name.firstName'),
          ]);
          if (
            operationType === SEARCH_FUNCTION_SEND ||
            operationType === SEARCH_FUNCTION_EXPORT ||
            operationType === SEARCH_FUNCTION_REOPEN ||
            ((state === STATE_FINISHED || state === STATE_NEW) &&
              (studyType === 'provided' ||
                (studyType === 'requested' &&
                  operationType === SEARCH_FUNCTION_COPY &&
                  !isShredding))) ||
            (state === STATE_FAILED &&
              studyType === 'requested' &&
              operationType === SEARCH_FUNCTION_MOVE) ||
            ((operationType === SEARCH_FUNCTION_REORDER ||
              operationType === SEARCH_FUNCTION_SPLIT) &&
              studyType === 'requested' &&
              state === STATE_WAITING)
          ) {
            return (
              <Link
                to={`/study/${encryptId(generateIID(row))}?backToNotifications=${notifyId}`}
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                  color: isDarkMode ? paletteColorLightest : paletteColorDark,
                  fontWeight: 'bold',
                }}
              >
                {patientName}
              </Link>
            );
          } else {
            return (
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {patientName}
              </div>
            );
          }
        },
        valueGetter: (value: any, row: any) =>
          joinParams([get(row, 'patient.name.lastName'), get(row, 'patient.name.firstName')]),
      },
      {
        field: 'patientId',
        headerName: t('grid.study.patientId'),
        valueGetter: (value: any, row: any) => get(row, 'patient.identificationNumber', ''),
      },
      {
        field: 'accessionNumber',
        headerName: t('grid.study.accessionNumber'),
      },
      {
        field: 'patientBirthDate',
        headerName: t('grid.study.patientBirthDate'),
        type: 'date',
        valueGetter: (value: any, row: any) => {
          try {
            return new Date(get(row, 'patient.dateBirth', ''));
          } catch (e) {
            return null;
          }
        },
        renderCell: ({ row }: any) => {
          try {
            const dateToFormat = get(row, 'patient.dateBirth', '');
            return dateToFormat ? format(new Date(dateToFormat), 'dd.MM.yyyy') : '';
          } catch (e) {
            return '';
          }
        },
      },
      {
        field: 'patientSex',
        headerName: t('grid.study.patientSex'),
        valueGetter: (value: any, row: any) => t(getPatientSexSlug(get(row, 'patient.sex', ''))),
      },
      {
        field: 'dateTime',
        headerName: t('grid.study.studyDate'),
        type: 'date',
        valueGetter: (value: any, row: any) => {
          try {
            const dateTime = get(row, 'dateTime', '');
            return dateTime ? new Date(dateTime) : '';
          } catch (e) {
            return null;
          }
        },
        renderCell: ({ row }: any) => {
          try {
            const dateTime = get(row, 'dateTime', '');
            return dateTime ? format(new Date(dateTime), 'dd.MM.yyyy HH:mm') : '';
          } catch (e) {
            return '';
          }
        },
      },
      {
        field: 'modality',
        headerName: t('grid.study.modalities'),
        valueGetter: (value: any, row: any) =>
          isArray(get(row, 'modalitiesInStudy')) ? get(row, 'modalitiesInStudy').join(', ') : '',
      },
      {
        field: 'noOfInstances',
        headerName: t('grid.study.noOfInstances'),
        valueGetter: (value: any, row: any) =>
          `${get(row, 'numberOfStudyRelatedSeries', '')}/${get(
            row,
            'numberOfStudyRelatedInstances',
            '',
          )}`,
      },
      { field: 'studyDescription', headerName: t('grid.study.studyDescription') },
      {
        field: 'archiveName',
        headerName: t('grid.study.archiveName'),
        valueGetter: (value: any, row: any) => get(row, 'archive.name', ''),
      },
    ]),
  );

  const handleArchiveNotification = async () => {
    entity &&
      (await setNotificationState(entity.id, ARCHIVED).then(
        (r) => {
          addSuccessAlert(t('archivedSuccessfully'));
          navigate('/notifications');
        },
        (e) => addErrorAlert(t('errorArchiving')),
      ));
  };

  const handleDownloadAttachment = async () => {
    toggleLoader();
    const resp = await sign(get(entity, 'requestId.id', -1));
    const hash = get(resp, 'data');
    if (hash) {
      window.open(`/portal-api/studyExport/download?signature=${hash}`);
    } else {
      addErrorAlert(t('downloadError'));
    }
    toggleLoader(false);
  };

  const handleRenderStudies = (title: string, studies: (IStudy | undefined)[]) => {
    const withoutFirst =
      isArray(studies) &&
      studies[0] !== undefined &&
      get(studies[0], 'operationType') === 'REORDER' &&
      get(studies[0], 'studyInstanceUid') === undefined;
    let prepareStudies =
      !isArray(studies) || studies[0] === undefined ? null : withoutFirst ? drop(studies) : studies;
    if (
      !isEmpty(prepareStudies) &&
      isArray(prepareStudies) &&
      !get(prepareStudies[0], 'id', null)
    ) {
      prepareStudies = [];
    }
    return !isArray(prepareStudies) ? null : (
      <>
        <Header title={title} topMargin={true} />
        <MuiGrid
          gridKey={muiGridKey}
          columns={columnsStudies}
          rows={prepareStudies}
          hideHeaderFilters={true}
          hideFooter={true}
          autoHeight={true}
          initialSortMode={[{ field: 'name', sort: 'asc' }]}
        />
      </>
    );
  };

  // Logic for displaying old and new patient data
  const entityToDisplay = entity || {};
  const oldPatientToDisplay: any = get(entityToDisplay, 'data.oldPatient', {});
  const newPatientToDisplay: any = get(entityToDisplay, 'data.newPatient', {});
  // These fields always be displayed in the notification detail
  const mandatoryKeys = ['patientLastName', 'patientFirstName', 'patientId'];
  // Not always are the old and new patient objects the same, so we need to get all keys from both
  const uniqueKeys = new Set([
    ...Object.keys(oldPatientToDisplay),
    ...Object.keys(newPatientToDisplay),
    ...mandatoryKeys,
  ]);
  // Include fields with non-empty values from both old and new patient objects
  const patientKeysToDisplay: string[] = Array.from(uniqueKeys).reduce((acc: string[], key) => {
    if (!!newPatientToDisplay[key] || !!oldPatientToDisplay[key] || mandatoryKeys.includes(key)) {
      if (!acc.includes(key)) {
        acc.push(key);
      }
    }
    return acc;
  }, mandatoryKeys.slice());

  return notificationData?.notification ? (
    <>
      <Header
        title={`${t('detail')} / ${notificationData?.title}`}
        backUrl={linkBack}
        button={
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ ml: 1 }}
              // className={classes.button}
              // classes={{ label: classes.label, root: classes.root }}
              component={Link}
              {...{
                to: `/reports/auditLogs/?requestId=${get(entity, 'requestId.id', '')}&date=${format(
                  new Date(get(entity, 'requestId.createdWhen', '')),
                  'yyyy-MM-dd',
                )}`,
              }}
            >
              {t('showAuditLog')}
            </Button>
            {operationData && !operationData.isImport && !operationData.isShredding && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                // className={classes.button}
                // classes={{ label: classes.label, root: classes.root }}
                sx={{ ml: 1 }}
                component={Link}
                {...{
                  to: `/requests/${get(entity, 'requestId.id', '')}`,
                }}
              >
                {t('showRequest')}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ ml: 1 }}
              // className={classes.button}
              // classes={{ label: classes.label, root: classes.root }}
              onClick={async () => {
                await handleArchiveNotification();
              }}
            >
              <ArchiveIcon /> {/*className={classes.icon} */}
              {t('archiveNotification')}
            </Button>
            {operationData?.canDownloadExport && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ ml: 1 }}
                // className={classes.button}
                // classes={{ label: classes.label, root: classes.root }}
                onClick={() => {
                  handleDownloadAttachment();
                }}
              >
                <DownloadIcon /> {/*className={classes.icon} */}
                {t('downloadAttachment')}
              </Button>
            )}
          </>
        }
      />
      {operationData?.canSign && (
        <div>
          {' '}
          {/* className={classes.announcements}*/}
          <Grid container={true} spacing={16}>
            <Grid item={true} xs={12}>
              <Announcement type="danger" spaced={true} label={t('canSing')}>
                {t('canSign')}
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  // className={classes.button}
                  // classes={{ label: classes.label, root: classes.root }}
                  component={Link}
                  {...{
                    to: `/signature/unsignedStudies?rid=${get(entity, 'requestId.id', 0)}`,
                  }}
                >
                  <SignatureIcon /> {/* className={classes.icon} */}
                  {t('sign')}
                </Button>
              </Announcement>
            </Grid>
          </Grid>
        </div>
      )}
      <Papeer>
        <Grid container={true} alignItems="flex-end">
          {keys(notificationData.notification).map((key) => (
            <React.Fragment key={key}>
              <Grid item={true} xs={12} sm={6} md={3} lg={2}>
                <Typography key={key} component="p">
                  {t(key)}:
                </Typography>
              </Grid>
              <Grid item={true} xs={12} sm={6} md={9} lg={10}>
                <Typography key={key} component="p">
                  {get(notificationData.notification, key)}
                  {key === 'subject' && notificationData.tooltip && (
                    <Tooltip title={t(`errorType.${notificationData.tooltip}`)} placement="right">
                      <IconButton>
                        {' '}
                        {/*className={classes.detailIcon} */}
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Papeer>
      {(operationData?.isCopy ||
        operationData?.isMove ||
        operationData?.isEditStudy ||
        operationData?.isSend ||
        operationData?.isReorder ||
        operationData?.isImport ||
        operationData?.isExport ||
        operationData?.isReopen ||
        operationData?.isCreateSr) && (
        <Grid container={true} spacing={8}>
          {!operationData.isImport && !operationData.isCreateSr && (
            <Grid item={true} xs={12} lg={true}>
              {handleRenderStudies(
                t(
                  operationData.isSend
                    ? 'selectedStudy'
                    : operationData.isExport
                    ? 'exportedStudy'
                    : 'sourceStudy',
                ),
                notificationData.requestedStudies,
              )}
            </Grid>
          )}
          {!operationData.isSend && !operationData.isExport && !operationData.isReopen && (
            <Grid item={true} xs={12} lg={true}>
              {handleRenderStudies(
                t(
                  operationData.isEditStudy || operationData.isEditPatient
                    ? 'updateStudy'
                    : operationData.isCreateSr
                    ? 'selectedStudy'
                    : 'targetStudy',
                ),
                notificationData.providedStudies,
              )}
            </Grid>
          )}
        </Grid>
      )}
      {operationData?.isEditPatient && !isEmpty(get(entity, 'data.std.providedStudies', null)) && (
        <Grid container={true} spacing={8}>
          <Grid item={true} xs={12} lg={true}>
            {handleRenderStudies(t('updateStudy'), get(entity, 'data.std.providedStudies', []))}
          </Grid>
        </Grid>
      )}
      {operationData?.isSplit && (
        <Grid container={true} spacing={8}>
          <Grid item={true} xs={12} lg={true}>
            {notificationData.statusKey === 'NEW' ||
            notificationData.statusKey === 'APPROVED' ||
            notificationData.statusKey === 'REJECTED'
              ? handleRenderStudies(t('sourceStudy'), notificationData.requestedStudies)
              : handleRenderStudies(t('sourceStudy'), [
                  find(notificationData?.requestedStudies, ['state', STATE_FINISHED]),
                ])}
          </Grid>
          <Grid item={true} xs={12} lg={true}>
            {handleRenderStudies(t('sourceStudyNew'), [
              find(notificationData.providedStudies, ['state', STATE_FINISHED]),
            ])}
          </Grid>
          <Grid item={true} xs={12} lg={true}>
            {handleRenderStudies(t('targetStudy'), [
              find(notificationData.providedStudies, ['state', STATE_NEW]),
            ])}
          </Grid>
        </Grid>
      )}
      {operationData?.isEditPatient && (
        <Grid container={true} spacing={16}>
          {!isEmpty(get(entity, 'data.oldPatient')) && (
            <Grid item={true} xs={12} md={6} lg={6} key="oldPatient">
              <Header
                title={t(`operationType.${get(entity, 'data.operationType', 'other')}_old`)}
                topMargin={true}
              />
              <Papeer>
                <Typography component="div">
                  <table>
                    <tbody>
                      {patientKeysToDisplay.map((key) => (
                        <tr key={key}>
                          <th>
                            {' '}
                            {/* className={classes.th}*/}
                            <strong>{t(`oldPatient.${key}`)}</strong>:
                          </th>
                          <td>{get(entity, `data.oldPatient.${key}`)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Typography>
              </Papeer>
            </Grid>
          )}

          <Grid item={true} xs={12} md={6} key="data">
            <Header
              title={t(`operationType.${get(entity, 'data.operationType', 'other')}`)}
              topMargin={true}
            />
            <Papeer>
              <Typography component="div">
                {get(entity, 'data.newPatient') && (
                  <table>
                    <tbody>
                      {patientKeysToDisplay.map((key) => (
                        <tr key={key}>
                          <th>
                            {' '}
                            {/* className={classes.th}*/}
                            <strong>{t(`data.${key}`)}</strong>:
                          </th>
                          <td>{get(entity, `data.newPatient.${key}`)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Typography>
            </Papeer>
          </Grid>
        </Grid>
      )}
    </>
  ) : null;
};

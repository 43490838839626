import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getRequest } from '../_api';
import { IExistingRequest } from '../_types';
import RequestsMoveToTrashDetail from './RequestsMoveToTrashDetail';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';

const RequestsMoveToTrashDetailEdit: React.FC = () => {
  const { id } = useEntityInfo();
  const navigate = useNavigate();

  const [request, fetchRequest] = useState<IExistingRequest>();

  const loadEntity = async () => {
    if (id) {
      const request = await getRequest(parseInt(id.toString(), 10));
      if (request) {
        fetchRequest(request);
      } else {
        navigate(`/requests/moveToTrash`);
      }
    }
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>{request ? <RequestsMoveToTrashDetail isRequestEdit={true} request={request} /> : null}</>
  );
};

export default RequestsMoveToTrashDetailEdit;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { get, keys, lowerCase } from 'lodash';
import { format } from 'date-fns';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

import { IConfirmDialog } from './_types';

const ConfirmDialog: React.FC<IConfirmDialog> = ({ closeDialog, action, entity, dialogTitle }) => {
  const { t } = useTranslation('Tests');

  const booleanRow = (value: boolean) =>
    value ? <CheckIcon sx={{ fontSize: 16 }} /> : <CancelIcon sx={{ fontSize: 16 }} />;

  const test = {
    name: get(entity, 'name'),
    description: get(entity, 'description'),
    validFrom: format(new Date(get(entity, 'validFrom')), 'dd. MM. yyyy'),
    validTo: get(entity, 'validTo')
      ? format(new Date(get(entity, 'validTo')), 'dd. MM. yyyy')
      : '-',
    maxWrongAnswersAllowed: get(entity, 'maxWrongAnswersAllowed'),
    public: booleanRow(get(entity, 'public')),
    state: t(lowerCase(get(entity, 'state'))),
    type: t(lowerCase(get(entity, 'type'))),
    maxTestLength: get(entity, 'maxTestLength'),
    showResults: booleanRow(get(entity, 'showResults')),
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={() => closeDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <Typography component="div">
            <table>
              <tbody>
                {keys(test).map((key) => (
                  <tr key={key}>
                    <th style={{ textAlign: 'left' }}>
                      <strong>{t(key)}</strong>:
                    </th>
                    <td>{get(test, key)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Typography>
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={closeDialog} variant="contained" size="large" sx={{ mr: '5px' }}>
              {t('close')}
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => action(get(entity, 'id'))}
            >
              {t('confirm')}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;

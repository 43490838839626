import React, { useEffect, useState, useMemo, useCallback, MouseEvent } from 'react';
import { Box, Stack, Paper, Tooltip, Button, IconButton, useMediaQuery } from '@mui/material';
import {
  DataGridPro,
  // GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridRowId,
  GridColumnVisibilityModel,
  GridRowModel,
  GridRowParams,
  GridPinnedColumnFields,
  GridColDef,
  // GridColDef,
} from '@mui/x-data-grid-pro';
import { get, find, compact, isArray, difference, pickBy, isUndefined, keys } from 'lodash';
import { useSelector } from 'react-redux';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRight from '@mui/icons-material/ChevronRight';

import { Papeer } from 'components/Papeer/Papeer';
import { GridSeriesMUI } from './GridSeriesMUI';
import { decodeIID } from 'utils/study';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import {
  getPreviewWithSize,
  getSeries,
  getSeriesForFolder,
} from 'modules/Studies/StudyDetail/_api';
import { useGridColumns } from './useGridColumns';
import { useGridSettings } from './useGridSettings';
import { useGridExtendedInfo } from './useGridExtendedInfo';
import { useFunctionList } from './useFunctionList';
import {
  ISearchResults,
  IPreview,
  ISerieDetail,
  IDecubitDetail,
  IFunctionItem,
  // IStudyResultForGridFixed,
} from './_types';
import { IStudyResultForGrid } from 'modules/Search/_types';
import type { RootState } from 'store/store';
import {
  DATAGRID_SETTINGS_COLUMN_ORDER_MODEL,
  DATAGRID_SETTINGS_COLUMN_PINNED_COLUMNS,
  DATAGRID_SETTINGS_COLUMN_SORT_MODEL,
  DATAGRID_SETTINGS_COLUMN_WIDTHS,
  DATA_GRID_DEFAULT_SORTING,
  // DATA_GRID_FILTER_HEADER_ROW_HEIGHT,
  DATA_GRID_ROW_HEIGHT,
  GRID_SEARCH_SETTINGS_KEY,
  GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY,
  LIST,
  LIST_FOR_FOLDER,
  MEDIA_QUERY_MIN_HEIGHT_FOR_DATA_GRID,
  MEDIA_QUERY_MIN_WIDTH_FOR_DATA_GRID,
  PREVIEW_URL_SIZE,
} from 'constants/constants';
import { ISerie } from 'modules/Studies/StudyDetail/_types';
import { useStudy } from 'utils/hooks/useStudy';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { useGrid } from 'utils/hooks/useGrid';
import { useTranslation } from 'react-i18next';
import { useSearch } from 'utils/hooks/useSearch';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { IContextMenu } from 'components/SearchResults/_types';
import { SearchContextMenu } from 'components/SearchResults/SearchContextMenu';
import clsx from 'clsx';
import useGridLocalization from './useGridLocalization';

/* const escapeRegExp = (value: string) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}; */

let searchReloadInterval: any = null;
let searchRealoadStatus: any = null;

const greenRowSelectedDarkMode = '#439027';
const greenRowSelectedLightMode = '#b2ff96';

export const SearchResultsMUI: React.FC<ISearchResults> = ({
  rows,
  setRows,
  isShredding = false,
  isShreddingPrint = false,
  isArchive = false,
  action = '',
  actions = [],
  selecting = true,
  selection,
  setSelection,
  operations = {},
  isRequest = false,
  showDetailPanel = true,
  nameAsLink = true,
  handleCloseDialog,
  importProps,
  disableStudyAndPatientForm,
  isActionFirstColumn,
  dataTour,
  disableContextMenu = false,
  sx = {},
  folderId = null,
  hideTopPanel = false,
  forcedAutoHeight = false,
}) => {
  const { toggleLoader } = useAppGlobals();
  const { columnsInSearchGridWithAdditionalInfoBeingLoaded, compactMode, isDarkMode } =
    useAppInfo();
  const { columns } = useGridColumns({
    action,
    actions,
    isShredding,
    isShreddingPrint,
    isArchive,
    operations,
    isRequest,
    setSelection,
    nameAsLink,
    handleCloseDialog,
    importProps,
    disableStudyAndPatientForm,
    isActionFirstColumn,
  });
  const {
    storeGridSettings,
    gridSettings,
    getUpdatedColumnWidthsAfterResize,
    getGridColumnsSort,
    getUpdatedColumnOrderAfterReordering,
    getPinnedColumnsFromGridSettings,
  } = useGridSettings();
  const { getExtendedInfoForColumn } = useGridExtendedInfo({ rows, setRows });
  const { t } = useTranslation('Studies');

  const gridLocalization = useGridLocalization();

  const datagridShouldNotHaveAutoheight = useMediaQuery(
    `(min-height: ${MEDIA_QUERY_MIN_HEIGHT_FOR_DATA_GRID}px) AND (min-width: ${MEDIA_QUERY_MIN_WIDTH_FOR_DATA_GRID}px)`,
  );

  // const [searchText, setSearchText] = useState('');
  const [rowsNew, setRowsNew] = useState<IStudyResultForGrid[]>([]);
  const [series, setSeries] = useState<ISerieDetail[]>([]);
  const [previews, setPreviews] = useState<IPreview[]>([]);
  const [decubits, setDecubits] = useState<IDecubitDetail[]>([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const initialContextMenu: IContextMenu = {
    mouseX: null,
    mouseY: null,
    row: null,
  };
  const [contextMenu, setContextMenu] = useState<any>(initialContextMenu);

  const closeContextMenu = () => {
    setContextMenu(initialContextMenu);
    setIsContextMenuOpen(false);
  };

  const handleContextMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const row: any = e.currentTarget.getAttribute('data-id');
    const selectedRow: any = rows.filter((item: any) => item?.iid === row);
    setContextMenu({
      mouseX: e.clientX,
      mouseY: e.clientY,
      row: selectedRow,
    });
  };

  // const [gridHeight, setGridHeight] = useState<number>(500);
  const { functionList } = useFunctionList({
    actions,
    rows,
    selection: selection,
    setSelection,
    operations,
    isRequest,
  });

  const { getSearchReloadTime } = useSearch();

  const searchReloadTime = getSearchReloadTime();

  // create empty useState for searchReload

  const [searchReload, setSearchReload] = useState<boolean>(false);
  const [searchReloadProgress, setSearchReloadProgress] = useState<number>(100);

  const recalculateSearchReloadProgress = () => {
    setSearchReloadProgress((previousSearchReloadProgress) =>
      previousSearchReloadProgress >= 100
        ? 0
        : Math.floor(previousSearchReloadProgress + 100 / searchReloadTime),
    );
  };

  const { setGridSettingsAndStore } = useGrid();

  const { checkAllowedArchives } = useStudy();
  const { sourceStudyForReorderIID, sourceStudyForReorderIsInStore, basketStore } = useStudyInfo();

  const defaultHiddenColumns = useMemo(
    () => ({
      comments: false,
      folderInfos: false,
      hasAttachment: false,
      modalitiesNotShown: false,
      referringPhysician: false,
      sendInfos: false,
      shredding: false,
      stationAet: false,
      studyStatus: false,
      tags: false,
    }),
    [],
  );

  const columnVisibilityModel = useSelector((state: RootState) =>
    get(
      state,
      `app.gridSettings.${GRID_SEARCH_SETTINGS_KEY}.columnVisibilityModel`,
      defaultHiddenColumns,
    ),
  );

  const seriesPreviewShown = useSelector((state: RootState) =>
    get(
      state,
      `app.gridSettings.${GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY}.columnVisibilityModel.previewUrl`,
      true,
    ),
  );

  const pinnedColumns = getPinnedColumnsFromGridSettings(GRID_SEARCH_SETTINGS_KEY);

  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  const columnsWithDisabledFiltering = [
    'comments',
    'hasAttachment',
    'tags',
    'sendInfos',
    'folderInfos',
    'shredding',
    'studyStatus',
  ];

  // Filtered columns will be stored here. If the column is hideen, it cannot be filtered by
  const [filterableColumns, setFilterableColumns] = useState(columns);

  // Compute hidden columns not to be shown in filters
  useEffect(() => {
    const hiddenColumns = compact(
      keys(columnVisibilityModel).map((columnName) =>
        get(columnVisibilityModel, columnName) === false ? columnName : undefined,
      ),
    );
    setHiddenColumns(hiddenColumns);
  }, [columnVisibilityModel]);

  useEffect(() => {
    const updatedColumns = columns.map((column) => ({
      ...column,
      filterable:
        column.type !== 'actions' &&
        !hiddenColumns.includes(column.field) &&
        !columnsWithDisabledFiltering.includes(column.field),
    }));
    setFilterableColumns(updatedColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hiddenColumns,
    columnsInSearchGridWithAdditionalInfoBeingLoaded,
    rows,
    actions,
    basketStore?.length,
  ]);

  const setNewPreviews = useCallback(
    (newPreview: IPreview) => {
      setPreviews((prevPreviews: IPreview[]) => [...prevPreviews, newPreview]);
    },
    [setPreviews],
  );

  const setNewDecubits = useCallback(
    (newDecubit: IDecubitDetail) => {
      setDecubits((prevDecubits: IDecubitDetail[]) => [...prevDecubits, newDecubit]);
    },
    [setDecubits],
  );

  const rowsFixed = useMemo(
    () =>
      rows.map((row: IStudyResultForGrid) => ({
        ...row,
        iid: row.iid,
        name: compact([
          get(row, 'patient.name.lastName', ''),
          get(row, 'patient.name.firstName', ''),
          get(row, 'patient.name.prefix', ''),
          get(row, 'patient.name.suffix', ''),
        ])
          .join(', ')
          .trim(),
        patientId: get(row, 'patient.identificationNumber', '') || '',
        patientSex: get(row, 'patient.sex', '') || '',
        patientBirthDate: get(row, 'patient.dateBirth', '') || '',
        modality: isArray(get(row, 'modalitiesInStudy'))
          ? get(row, 'modalitiesInStudy', ['']).join(', ')
          : '',
        noOfInstances: `${get(row, 'numberOfStudyRelatedSeries', '')}/${get(
          row,
          'numberOfStudyRelatedInstances',
          '',
        )}`,
        archiveName: get(row, 'archive.name', ''),
        archiveId: get(row, 'archive.id', ''),
      })),
    [rows],
  );

  /* const columnsInQuickSearch = useMemo(
    () => filter(columns, (column) => !includes(['actions', 'tags'], column.field)),
    [columns],
  );

  const requestSearch = useCallback(
    (searchValue: string) => {
      // setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = rowsFixed.filter((row: IStudyResultForGridFixed) => {
        return columnsInQuickSearch
          .map((item: GridColDef) => item.field)
          .some((field: string) => {
            return searchRegex.test((get(row, field) || '').toString());
          });
      });
      setRowsNew(filteredRows);
    },
    [rowsFixed, columnsInQuickSearch],
  ); */

  // handle funkce na rozbalení sérií u studie
  const handleDetailPanelExpandedRowIdsChange = useCallback(
    async (newIds: GridRowId[]) => {
      if (newIds.length > detailPanelExpandedRowIds.length) {
        const searchForStudySeries = get(difference(newIds, detailPanelExpandedRowIds), '[0]', '');
        const isSerieLoaded = find(series, { iid: searchForStudySeries });
        let sers: ISerie[] = get(isSerieLoaded, 'items', []);
        const { archiveID, studyID } = decodeIID(searchForStudySeries);
        if (!isSerieLoaded) {
          toggleLoader(true);
          let seriesLoad: false | ISerie[];
          if (folderId) {
            seriesLoad = await getSeriesForFolder(archiveID, studyID, folderId);
          } else {
            seriesLoad = await getSeries(Number(archiveID), studyID);
          }
          sers = seriesLoad || [];
          toggleLoader(false);
          if (seriesLoad) {
            setSeries((prevSeries: ISerieDetail[]) => [
              ...prevSeries,
              {
                iid: searchForStudySeries,
                items: sers,
                study: { uid: studyID },
                previewsLoaded: seriesPreviewShown,
              },
            ]);
          }
        }
        if (!get(isSerieLoaded, 'previewsLoaded', false) && seriesPreviewShown) {
          if (isArray(sers)) {
            sers.forEach(async (serie: ISerie) => {
              const img = await getPreviewWithSize(
                archiveID,
                studyID,
                get(serie, 'uid'),
                undefined,
                PREVIEW_URL_SIZE,
              );
              setNewPreviews({ iid: studyID, uid: get(serie, 'uid'), img });
            });
          }
        }
      }
      setDetailPanelExpandedRowIds(newIds);
    },
    [detailPanelExpandedRowIds, series, toggleLoader, setNewPreviews, seriesPreviewShown, folderId],
  );

  const setColumnVisibilityModel = useCallback(
    (newModel: GridColumnVisibilityModel) => {
      getExtendedInfoForColumn(newModel, columnVisibilityModel ? columnVisibilityModel : {});
      storeGridSettings(GRID_SEARCH_SETTINGS_KEY, {
        columnVisibilityModel: pickBy(newModel, (item: Boolean) => item === false), // ukládáme pouze sloupce, které jsou false (= schované)
      });
    },
    [columnVisibilityModel, getExtendedInfoForColumn, storeGridSettings],
  );

  const DetailPanel = useCallback(
    (row: GridRowParams) => {
      const seriesToShow = find(series, { iid: row.id }) as ISerieDetail;
      const seriesRows = (get(seriesToShow, 'items') || []).map((serie: ISerie) => ({
        ...serie,
        study: {
          ...seriesToShow?.study,
          archiveId: get(row, 'row.archiveId'),
          patientId: get(row, 'row.patientId'),
        },
      }));
      return (
        <Stack
          sx={{ py: compactMode ? 0.5 : 2, height: '100%', boxSizing: 'border-box' }}
          direction="column"
        >
          <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
            <Stack direction="column" spacing={1} sx={{ height: 1 }}>
              <GridSeriesMUI
                rows={seriesRows}
                previews={previews}
                setNewPreviews={setNewPreviews}
                decubits={decubits}
                setNewDecubits={setNewDecubits}
              />
            </Stack>
          </Paper>
        </Stack>
      );
    },
    [series, previews, decubits, setNewPreviews, setNewDecubits, compactMode],
  );

  const onColumnWidthChange = useCallback(
    async (changedColumn: any) => {
      const columnNewWidth = changedColumn.width;
      const columnName = changedColumn.colDef.field;

      const gridSettingsForAPI = {
        ...get(gridSettings, GRID_SEARCH_SETTINGS_KEY, {}),
        [DATAGRID_SETTINGS_COLUMN_WIDTHS]: getUpdatedColumnWidthsAfterResize(
          columns,
          columnName,
          columnNewWidth,
        ),
      };
      const gridSettingsForRedux = {
        ...gridSettings,
        [GRID_SEARCH_SETTINGS_KEY]: gridSettingsForAPI,
      };

      setGridSettingsAndStore({
        key: GRID_SEARCH_SETTINGS_KEY,
        gridSettingsForAPI,
        gridSettingsForRedux,
      });
    },
    [gridSettings, setGridSettingsAndStore, columns, getUpdatedColumnWidthsAfterResize],
  );

  // If detail panel is enabled, indexes are one higher than they should be
  // If selecting is enabled, indexes are one higher than they should be
  let indexShiftForReordering = 1 + (selecting ? 1 : 0);

  const onColumnOrderChange = useCallback(
    async (params: any) => {
      const newColumnOrder = getUpdatedColumnOrderAfterReordering(
        columns,
        params.column.field,
        params.oldIndex - indexShiftForReordering,
        params.targetIndex - indexShiftForReordering,
      );

      const gridSettingsForAPI = {
        ...get(gridSettings, GRID_SEARCH_SETTINGS_KEY, {}),
        [DATAGRID_SETTINGS_COLUMN_ORDER_MODEL]: newColumnOrder.map((column) => column.field),
      };
      const gridSettingsForRedux = {
        ...gridSettings,
        [GRID_SEARCH_SETTINGS_KEY]: gridSettingsForAPI,
      };

      await setGridSettingsAndStore({
        key: GRID_SEARCH_SETTINGS_KEY,
        gridSettingsForAPI,
        gridSettingsForRedux,
      });
    },
    [
      columns,
      getUpdatedColumnOrderAfterReordering,
      gridSettings,
      indexShiftForReordering,
      setGridSettingsAndStore,
    ],
  );

  const onRowSelectionModelChange = useCallback(
    (newSelectionModel: any) => {
      setSelection(newSelectionModel);
    },
    [setSelection],
  );

  const isRowSelectable = useCallback(
    (params: GridRowParams) =>
      !sourceStudyForReorderIsInStore ||
      (sourceStudyForReorderIsInStore && params.row.iid !== sourceStudyForReorderIID),
    [sourceStudyForReorderIsInStore, sourceStudyForReorderIID],
  );

  const onSortModelChange = useCallback(
    async (newSortModel: any) => {
      const gridSettingsForAPI = {
        ...get(gridSettings, GRID_SEARCH_SETTINGS_KEY, {}),
        [DATAGRID_SETTINGS_COLUMN_SORT_MODEL]: newSortModel,
      };
      const gridSettingsForRedux = {
        ...gridSettings,
        [GRID_SEARCH_SETTINGS_KEY]: gridSettingsForAPI,
      };

      setGridSettingsAndStore({
        key: GRID_SEARCH_SETTINGS_KEY,
        gridSettingsForAPI,
        gridSettingsForRedux,
      });
    },
    [gridSettings, setGridSettingsAndStore],
  );

  const onPinnedColumnsChange = useCallback(
    async (updatedPinnedColumns: GridPinnedColumnFields) => {
      const gridSettingsForAPI = {
        ...get(gridSettings, GRID_SEARCH_SETTINGS_KEY, {}),
        [DATAGRID_SETTINGS_COLUMN_PINNED_COLUMNS]: updatedPinnedColumns,
      };
      const gridSettingsForRedux = {
        ...gridSettings,
        [GRID_SEARCH_SETTINGS_KEY]: gridSettingsForAPI,
      };

      setGridSettingsAndStore({
        key: GRID_SEARCH_SETTINGS_KEY,
        gridSettingsForAPI,
        gridSettingsForRedux,
      });
    },
    [gridSettings, setGridSettingsAndStore],
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);
  const getRowId = useCallback((row: GridRowModel) => row.iid, []);

  const gridSortModel = getGridColumnsSort(GRID_SEARCH_SETTINGS_KEY);

  useEffect(() => {
    setRowsNew(rowsFixed);
  }, [rows, rowsFixed]);

  useEffect(() => {
    return () => {
      clearInterval(searchReloadInterval);
      clearInterval(searchRealoadStatus);
    };
  }, []);

  const handleSearchReload = () => {
    setSearchReload(!searchReload);
    if (!searchReload) {
      setSearchReloadProgress(0);
      searchRealoadStatus = setInterval(recalculateSearchReloadProgress, 1000);
      searchReloadInterval = setInterval(() => {
        setSearchReloadProgress(0);
        const submitButton: HTMLElement | null = document.querySelector('.search-form-button');
        if (submitButton) {
          submitButton.focus();
          submitButton.click();
        }
      }, searchReloadTime * 1000);
    } else {
      setSearchReloadProgress(100);
      clearInterval(searchReloadInterval);
      clearInterval(searchRealoadStatus);
    }
  };

  const QuickSearchToolbar = (props: any) => {
    const selectionLength = selection.length;
    const selectedStudies: any[] = [];
    (selection || []).forEach((item: any) => {
      const selStudy = find(rowsNew, { iid: item });
      if (selStudy) {
        selectedStudies.push(selStudy);
      }
    });

    return (
      <div>
        {!hideTopPanel && (
          <Box sx={{ padding: '5px', display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'inline-flex', mx: 1, alignSelf: 'flex-start' }}>
                <Tooltip title={`${t('automaticRefresh')} (${searchReloadTime} s)`}>
                  <IconButton
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => {
                      handleSearchReload();
                    }}
                    sx={{ p: 0 }}
                  >
                    <Box sx={{ width: 20, height: 20 }} data-tour="common-gridReFetching">
                      <CircularProgressbar
                        value={searchReloadProgress}
                        strokeWidth={50}
                        styles={buildStyles({
                          strokeLinecap: 'butt',
                        })}
                      />
                    </Box>
                  </IconButton>
                </Tooltip>
              </Box>
              <GridToolbarColumnsButton data-tour="common-gridColumns" />
              <GridToolbarFilterButton data-tour="common-gridFilters" />
              {/* <GridToolbarDensitySelector /> */}
            </Box>
          </Box>
        )}
        {selecting && (
          <Box
            sx={{
              px: 1,
              py: 0.5,
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
            data-tour="common-gridActions"
          >
            {functionList.map((item: IFunctionItem, index: number) => {
              const showCount = isUndefined(item.showCount) ? true : item.showCount;
              const count = get(item, 'allStudies', false) ? rowsNew.length : selectionLength;
              const xcount = showCount && count ? ` (${count})` : '';
              let canProcess = true;
              if (isArray(selectedStudies)) {
                canProcess = checkAllowedArchives(item.key, selectedStudies);
              }
              return (
                <Tooltip key={index} title={item.label + xcount}>
                  <span>
                    <Button
                      key={item.key}
                      size={'medium'}
                      variant="contained"
                      color="primary"
                      onClick={() => item.onClick(null)}
                      disabled={
                        (item.noSelection && selection.length > 1) ||
                        !canProcess ||
                        item.disabled ||
                        (selectionLength < 1 && showCount)
                      }
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {item.icon}
                    </Button>
                  </span>
                </Tooltip>
              );
            })}
          </Box>
        )}
      </div>
    );
  };

  const hiddenFields = ['__detail_panel_toggle__', '__check__', 'actions'];

  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };

  return (
    <Box
      sx={{ flex: 1, display: 'flex', maxHeight: '100%', overflow: 'hidden' }}
      data-tour={dataTour || 'search-header-searchForm-searchResultGrid'}
    >
      <Papeer
        id="search-results"
        sx={{
          flex: 1,
          maxHeight: '100%',
          maxWidth: '100%',
          ...(compactMode ? { p: 0 } : {}),
          ...sx,
        }}
      >
        <Box sx={{ height: '100% !important', width: '100% !important' }}>
          <DataGridPro
            headerFilters={true}
            autoHeight={forcedAutoHeight ? true : datagridShouldNotHaveAutoheight ? false : true}
            sortingOrder={DATA_GRID_DEFAULT_SORTING}
            sx={{
              '--DataGrid-overlayHeight': '150px',
              height: '100% !important',
              width: 'auto',
              '& .css.greenRowSelectedDarkMode': {
                backgroundColor: greenRowSelectedDarkMode,
                hover: { backgroundColor: greenRowSelectedDarkMode },
              },
              '& .css.greenRowSelectedLightMode': {
                backgroundColor: greenRowSelectedLightMode,
                hover: { backgroundColor: greenRowSelectedLightMode },
              },
            }}
            getRowId={getRowId}
            rows={rowsNew}
            columns={filterableColumns}
            slots={{
              toolbar: QuickSearchToolbar,
              detailPanelExpandIcon: ChevronRight,
              detailPanelCollapseIcon: ExpandMore,
            }}
            density={compactMode ? 'compact' : 'standard'} //MAPO-3319
            rowHeight={DATA_GRID_ROW_HEIGHT} //MAPO-3319
            slotProps={{
              columnsPanel: {
                className: 'react-tour-column-menu',
              },
              columnsManagement: {
                getTogglableColumns,
              },
              row: {
                onContextMenu: (e: any) => {
                  setIsContextMenuOpen(true);
                  handleContextMenu(e);
                },
                style: { cursor: 'context-menu' },
              },
              filterPanel: {
                className: 'react-tour-filter-panel',
              } as any,
              footer: {
                ...(compactMode
                  ? {
                      sx: {
                        minHeight: 30,
                      },
                    }
                  : {}),
              },
              headerFilterCell: {
                // height: DATA_GRID_FILTER_HEADER_ROW_HEIGHT,
                InputComponentProps: {
                  size: 'small',
                },
              },
              // toolbar: {
              //   value: searchText,
              //   onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
              //     requestSearch(event.target.value),
              //   clearSearch: () => requestSearch(''),
              //   quickFilterProps: { debounceMs: 300 },
              // },
            }}
            disableRowSelectionOnClick={true}
            localeText={gridLocalization}
            getDetailPanelContent={showDetailPanel ? DetailPanel : undefined}
            getDetailPanelHeight={getDetailPanelHeight} // Height based on the content.
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            checkboxSelection={selecting}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            onColumnWidthChange={onColumnWidthChange}
            onColumnOrderChange={onColumnOrderChange}
            onRowSelectionModelChange={onRowSelectionModelChange}
            rowSelectionModel={selection}
            isRowSelectable={isRowSelectable}
            sortModel={isArray(gridSortModel) ? gridSortModel : []}
            onSortModelChange={onSortModelChange}
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={onPinnedColumnsChange}
            getRowClassName={(params) => {
              let selectedId;
              if (contextMenu?.row) {
                selectedId = contextMenu?.row[0].iid;
              }
              return clsx('css', {
                greenRowSelectedDarkMode:
                  selectedId &&
                  isContextMenuOpen &&
                  !disableContextMenu &&
                  isDarkMode &&
                  params.id === selectedId,
                greenRowSelectedLightMode:
                  selectedId &&
                  isContextMenuOpen &&
                  !disableContextMenu &&
                  !isDarkMode &&
                  params.id === selectedId,
              });
            }}
          />
        </Box>
      </Papeer>
      {isContextMenuOpen && !disableContextMenu && (
        <SearchContextMenu
          contextMenu={contextMenu}
          closeContextMenu={closeContextMenu}
          isRequest={isRequest}
          action={action === LIST_FOR_FOLDER ? action : LIST}
          selection={contextMenu.row}
          setSelection={setSelection}
          rows={rows}
          actions={actions}
          operations={operations}
          setIsContextMenuOpen={setIsContextMenuOpen}
        />
      )}
    </Box>
  );
};

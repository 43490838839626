import { isEqual } from 'lodash';

export const getObjectDiff = (obj1: any, obj2: any) =>
  Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

export const stateIsSame = (
  oldProps: any,
  newProps: any,
  componentName: string = 'Anonymous component',
  debug: boolean = false,
) => {
  if (debug) {
    console.group(componentName);
    console.debug({
      isEqual: isEqual(oldProps, newProps),
      objectDiff: getObjectDiff(oldProps, newProps),
      stateIsSame: isEqual(oldProps, newProps) && !getObjectDiff(oldProps, newProps).length,
      oldProps,
      newProps,
    });
    console.groupEnd();
  }
  return isEqual(oldProps, newProps) && !getObjectDiff(oldProps, newProps).length;
};

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Box, Tooltip } from '@mui/material';
import { IPortalSearchResults } from './_types';
import { format } from 'date-fns';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { joinParams } from 'utils/study';
import { useStudy } from 'utils/hooks/useStudy';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'patientsMUI';

const PatientSearchResults: React.FC<IPortalSearchResults> = ({ items }) => {
  const { t } = useTranslation('ClinicalPortal');
  const { getPatientSexSlug } = useStudy();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const defaultHiddenColumnNames = {
    telephone: false,
    motherMaidenName: false,
    masterPatientID: false,
  };

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'name',
        headerName: t('name'),
        renderCell: ({ row }: GridRenderCellParams) => (
          <Tooltip title={t('showDetail')}>
            <Box
              sx={{
                cursor: 'move',
                background: 'none',
                color: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.primary.contrastText
                    : theme.palette.primary.main,
              }}
            >
              <Link
                style={{ cursor: 'pointer', fontWeight: 'bold', color: 'inherit' }}
                to={`/clinicPortal/${get(row, 'internalID')}`}
              >
                {joinParams([get(row, 'patientLastName'), get(row, 'patientFirstName')])}
              </Link>
            </Box>
          </Tooltip>
        ),
        valueGetter: (value: any, row: any) =>
          `${joinParams([get(row, 'patientLastName'), get(row, 'patientFirstName')])}`,
        width: 220,
      },
      { field: 'patientIdentificationNumber', headerName: t('patientIdentificationNumber') },
      { field: 'patientID', headerName: t('patientInsuranceNumber') },
      {
        field: 'patientBirthDate',
        headerName: t('patientBirthDate'),
        type: 'date',
        renderCell: ({ row }: GridRenderCellParams) =>
          format(new Date(get(row, 'patientBirthDate', '')), 'dd.MM.yyyy'),
        valueGetter: (value: any) => value && new Date(value),
      },
      {
        field: 'patientSex',
        headerName: t('patientSex'),
        valueGetter: (value: any, row: any) =>
          get(row, 'patientSex', '') ? t(getPatientSexSlug(row.patientSex)) : null,
      },
      {
        field: 'address',
        headerName: t('address'),
        valueGetter: (value: any, row: any) =>
          joinParams(
            [get(row, 'address', ''), joinParams([get(row, 'postcode', ''), get(row, 'town', '')])],
            ', ',
          ),
        width: 320,
      },

      { field: 'telephone', headerName: t('telephone') },
      { field: 'motherMaidenName', headerName: t('motherMaidenName') },
      { field: 'masterPatientID', headerName: t('masterPatientID') },
    ]),
  );

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={items}
        columns={columns}
        defaultHiddenColumnNames={defaultHiddenColumnNames}
        dataTour="patientGrid"
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />
    </>
  );
};

export default PatientSearchResults;

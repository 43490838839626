import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  compact,
  filter,
  find,
  first,
  forIn,
  get,
  includes,
  isArray,
  isEmpty,
  isEqual,
  last,
  orderBy,
  pick,
  size,
  sortBy,
  upperCase,
  remove as removeFromLodash,
} from 'lodash';
import { Box } from '@mui/system';
import { Fab, Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import useValidationSchema from './_form';
import { createForSend, editRequestForSend, sendStudy } from './_api';
import { send } from 'modules/Mdex/Send/_api';
import { getAll as getAllFacilities } from 'modules/Administration/Facilities/_api';
import { getProductByType } from 'modules/Administration/Products/_api';
import {
  getAll,
  getStudySendAddressBook,
} from 'modules/Administration/FacilitiesAndExchangeNetworks/_apiExchangeNetworks';
import {
  ICommon,
  IEditedFacility,
  INetworkForm,
  IPdfOptions,
  IStudiesSendDetail,
  IStudiesSendForm,
} from './_types';
import {
  IExchangeNetwork,
  IStudySendAddressBook,
  IStudySendAddressBookNetwork,
} from 'modules/Administration/FacilitiesAndExchangeNetworks/_types';
import { IAnonymizationField, IAnonymizationProfileWithFields } from '../Anonymization/_types';
import { IFacility } from 'modules/Administration/Facilities/_types';
import { IAutocompleteOption } from 'components/Form/Autocomplete/_types';
import { ISelectItem } from 'components/Form/Select/_types';

import RequestAdditionalForm from '../Form/RequestAdditionalForm';
import AnonymizationForm from '../Anonymization/AnonymizationForm';

import Header from 'components/Header/Header';
import { WhiteBox } from 'components/WhiteBox/WhiteBox';
import { CheckedButton } from 'components/Buttons/CheckedButton';
import FormSelect from 'components/Form/Select/Select';
import FormSwitch from 'components/Form/Switch/Switch';
import FormAutocomplete from 'components/Form/Autocomplete/Autocomplete';
import useAlerts from 'components/Alerts/useAlerts';
import { SeriesViewer } from 'components/SeriesViewer/SeriesViewer';
import { useSeriesViewer } from 'components/SeriesViewer/useSeriesViewer';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { generateIID, getParam, sortSeriesBySeriesNumber } from 'utils/study';
import { getSettingValueForVariable, settingHasValue } from 'utils/products';
import { formatAnonymizedValues, identifyValues } from 'utils/anonymization';
import { getFirstActiveProduct } from 'utils/products';
import { useActions } from 'utils/hooks/useActions';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useToken } from 'utils/hooks/useToken';

import {
  SEND_CONFIRM,
  PRODUCT_DR_SEJF,
  PRODUCT_CLOUD_PACS,
  SEJF_SENDFORM_DICOM,
  PRODUCT_EXCHANGE_NETWORK,
  SEJF_SENDFORM_PDF,
} from 'constants/constants';
import { ISend } from 'modules/Mdex/Send/_types';
import { Line } from 'components/Line/Line';
import { useAnonymizationProfiles } from 'utils/hooks/useAnonymizationProfiles';
import { primaryColor } from 'utils/variables';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourStudiesSend } from './TourStudiesSend';
import { getAreAllSeriesSelected } from 'utils/getAreAllSeriesSelected';
import { v1 as uuidv1 } from 'uuid';
import { Papeer } from 'components/Papeer/Papeer';
import Button from 'components/Buttons/Button';

const mapStudySendAddressBookItem = (studySendAddressBook: IStudySendAddressBook) => {
  const networks = get(studySendAddressBook, 'networks', null);
  let icons = '';
  'REM'.split('').forEach((type) => {
    icons += find(networks, { type }) ? type : '';
  });
  return {
    value: studySendAddressBook.id,
    label: `${studySendAddressBook.institutionName} (${studySendAddressBook.city})`,
    id: get(studySendAddressBook, 'id', null),
    networks,
    icons,
  };
};

const emptyNetwork: INetworkForm = { fac: '', type: '' };

const prepareNetworks = (networks: any, t: any) => {
  return isArray(networks)
    ? sortBy(networks, ['priority']).map((item: any) => ({
        id: item.type,
        label: t(item.type),
      }))
    : [];
};

const ConditionalSelect = ({ control, index, t }: any) => {
  const value = useWatch({
    name: 'networks',
    control,
  });
  const networks = prepareNetworks(value?.[index]?.fac?.networks, t);
  return (
    <Controller
      control={control}
      name={`networks.${index}.type`}
      render={({ field }) => (
        <FormSelect
          {...field}
          label={t('selectExchangeNetwork')}
          items={networks}
          disabled={size(networks) < 2}
        />
      )}
    />
  );
};

const StudiesSendDetail: React.FC<IStudiesSendDetail> = ({
  isRequest = false,
  isRequestEdit = false,
  request,
}) => {
  const { t } = useTranslation('Studies');
  useWithTitle(); // sets title to document
  const { refreshTokenAndStore } = useToken();
  const { toggleLoader } = useAppGlobals();
  const { hasRole, user } = useUser();

  const { spacing } = useAppInfo();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { sendStore } = useStudyInfo();
  const { setBulkStudies } = useActions();

  const { loadProfiles } = useAnonymizationProfiles();

  const [linkBack, setLinkBack] = useState<string>('');
  const [sendType, setSendType] = useState<string>('');
  const [facilitiesSelect, setFacilitiesSelect] = useState<any | IAutocompleteOption[]>([]);
  const [showCheckboxViewer, setShowCheckboxViewer] = useState<boolean>(false);
  const [showCheckboxStudyReport, setShowCheckboxStudyReport] = useState<boolean>(false);
  const [studySendCheckStudyReport, setStudySendCheckStudyReport] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>(null);
  const [pdfOptions, setPdfOptions] = useState<IPdfOptions>({
    printDetail: true,
    printComments: true,
    printSeries: true,
  });
  const [buttons, setButtons] = useState<ISelectItem[]>([]);
  const [facilities, setFacilities] = useState<IFacility[]>([]);
  const [studySendAddressBook, setStudySendAddressBook] = useState<IStudySendAddressBook[]>([]);
  const [exchangeNetworks, setExchangeNetworks] = useState<IExchangeNetwork[]>([]);
  const [editedFacility, setEditedFacility] = useState<IEditedFacility | null>();
  const [anonymizationProfile, setAnonymizationProfile] = useState<
    IAnonymizationProfileWithFields | undefined | null
  >(undefined);
  const [anonymizationFields, setAnonymizationFields] = useState<IAnonymizationField[]>([]);
  const [common, setCommon] = useState<ICommon>({
    isDrSejf: false,
    isNetwork: false,
    showAddFacilityButton: false,
    canSend: false,
  });
  const [fullSelectedStudies, setFullSelectedStudies] = useState<any>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { studiesForViewer, setStudiesForViewer, operations, somethingSelected } =
    useSeriesViewer();

  const isExchangeNetwork =
    sendType === 'ExchangeNetwork' && hasRole('ROLE_CAN_SEND_SERIES_THROUGH_NETWORK')
      ? true
      : false;

  const { StudiesSendFormSchema } = useValidationSchema(anonymizationFields);

  const methods = useForm<IStudiesSendForm>({
    resolver: yupResolver(StudiesSendFormSchema),
    defaultValues: {},
  });
  const { control, handleSubmit, register, reset, watch, setValue } = methods;
  const { fields, append, remove } = useFieldArray({ name: 'networks', control });
  const watchNetworks = useWatch({
    name: 'networks',
    control,
  });
  const watchAllFields = watch();

  const getFacilitiesSelect = (
    studySendAddressBook: Partial<boolean | Promise<IStudySendAddressBook[]>> & any[],
  ) => {
    if (isArray(studySendAddressBook)) {
      const networks = get(watchAllFields, 'networks', []);
      const facsIds: number[] = [];
      networks.forEach((item) => {
        if (get(item, 'fac.id')) {
          facsIds.push(item.fac.id);
        }
      });

      const favouriteLength = filter(studySendAddressBook, ['favourite', true]).length;
      const othersLength = filter(studySendAddressBook, ['favourite', false]).length;
      const facilitiesSelect = studySendAddressBook
        .sort((f1, f2) =>
          f1.institutionName
            .replace('(CZ) ', '')
            .replace('(SK) ', '')
            .localeCompare(f2.institutionName.replace('(CZ) ', '').replace('(SK) ', '')),
        )
        .map((item: IStudySendAddressBook) => {
          const isFavourite = get(item, 'favourite', false);
          const id = get(item, 'id');
          return {
            ...mapStudySendAddressBookItem(item),
            sorted: isFavourite
              ? `${t('favourite')} (${favouriteLength})`
              : `${t('others')} (${othersLength})`,
            disabled: includes(facsIds, id),
            id,
            favourite: isFavourite,
          };
        });
      setFacilitiesSelect(facilitiesSelect);
    }
  };

  const callReset = (id: string) => {
    if (id) {
      reset({
        sendType: id,
        networks: [emptyNetwork],
        viewer: false,
        includeStudyReport: studySendCheckStudyReport,
        sejfSendForm: SEJF_SENDFORM_DICOM,
        printDetail: id === PRODUCT_DR_SEJF ? true : null,
        printComments: id === PRODUCT_DR_SEJF ? pdfOptions.printComments : null,
        printSeries: id === PRODUCT_DR_SEJF ? pdfOptions.printSeries : null,
        anonymization: '0',
        requestComment: get(watchAllFields, 'requestComment', ''),
      });
      getFacilitiesSelect(studySendAddressBook);
    } else {
      (watchNetworks || []).forEach((item, index) => {
        setValue(`networks.${index}.fac`, item.fac);
        setValue(`networks.${index}.type`, item.type);
      });
    }

    setValue('anonymized_lastName', '');
    setValue('anonymized_firstName', '');
    setValue('anonymized_middleName', '');
    setValue('anonymized_prefix', '');
    setValue('anonymized_suffix', '');
    setValue('anonymized_PatientID', '');
    setValue('requestingUser.name.lastName', get(user, 'last_name'));
    setValue('requestingUser.name.firstName', get(user, 'first_name'));
    setValue('includeStudyReport', studySendCheckStudyReport);
  };

  const getExchangeNetworksFromFacility = (data: any, index: number, selectedType = '') => {
    const networks = prepareNetworks(get(data, 'networks', null), t);
    const defaultType = selectedType ? selectedType : isArray(networks) ? networks[0].id : '';
    setValue(`networks.${index}.type`, defaultType);
    getFacilitiesSelect(studySendAddressBook);
  };

  const anonymizationWatch = get(
    watchAllFields,
    'anonymization',
    isRequestEdit ? get(request, 'data.deidentifyProfile.name', '0') : '0',
  );
  const sejfSendForm = get(watchAllFields, 'sejfSendForm', SEJF_SENDFORM_DICOM);

  const historyPushWithTimeOut = () => {
    setTimeout(() => {
      navigate(linkBack);
    }, 1500);
  };

  const callApiForSend = async (data: any, studies: any) => {
    const fn = isRequest ? createForSend : sendStudy;
    if (common.isDrSejf && !isRequest) {
      await refreshTokenAndStore();
    }
    const resp = await fn(data, studies);
    if (resp) {
      addSuccessAlert(t(isRequest ? 'requestForSendAdded' : 'submitSendOk'));
      if (common.isDrSejf) {
        navigate(linkBack);
        if (!isRequest && includes(resp, 'http')) {
          window.open(resp, '_blank');
        }
      }
    } else {
      addErrorAlert(t(isRequest ? 'errorCreatingRequest' : 'submitSendError'));
    }
  };

  const callApiForMdex = async (transformedValues: ISend) => {
    const response = await send(transformedValues);
    if (response) {
      addSuccessAlert(t('submitSendOk'));
    } else {
      addErrorAlert(t('submitSendError'));
    }
  };

  const handleFormSubmit = async (values: any) => {
    toggleLoader();
    const deidentifyProfile = get(values, 'deidentifyProfile', null);
    const requestComment = get(values, 'requestComment', '');
    if (common.isDrSejf) {
      const sendForm = get(values, 'sejfSendForm', SEJF_SENDFORM_DICOM);
      const isDicom = sendForm === SEJF_SENDFORM_DICOM;
      const data = {
        sendType: PRODUCT_DR_SEJF,
        sendForm,
        pdfOptions: {
          printDetail: isDicom ? null : get(values, 'printDetail', null),
          printComments: isDicom ? null : get(values, 'printComments', null),
          printSeries: isDicom ? null : get(values, 'printSeries', null),
        },
        includeStudyReport: isDicom ? get(values, 'includeStudyReport', false) : false,
        viewer: isDicom ? get(values, 'viewer', false) : false,
        requestComment,
        deidentifyProfile,
      };
      callApiForSend(data, fullSelectedStudies);
    } else {
      const studiesLight = fullSelectedStudies.map((item: any) => ({
        studyInstanceUid: get(item, 'studyInstanceUid', null),
        archive: { id: get(item, 'archive.id', null) },
        ...(item.listOfSeries && { listOfSeries: item.listOfSeries }),
      }));
      let facilityId = null;
      let type = '';
      compact(get(values, 'networks', [])).forEach((item: any, i: number) => {
        facilityId = get(item, 'fac.id', null);
        type = upperCase(get(item, 'type', ''));
        if (type === 'M') {
          // mdex
          let recipient = '';
          (item.fac.networks || []).forEach((network: any) => {
            if (upperCase(get(network, 'type', '')[0]) === type) {
              recipient = network.uuid;
            }
          });
          const transformedValues = {
            includeStudyReport: get(values, 'includeStudyReport', false),
            recipient,
            type: 'DICOM_ARCHIVE',
            studies: studiesLight,
          };
          callApiForMdex(transformedValues);
        } else if (type === 'R' || type === 'E') {
          // redimed/epacs
          const facility = facilityId ? find(facilities, ['id', facilityId]) : null;
          let exchangeNetwork = {};
          exchangeNetworks.forEach((network) => {
            if (upperCase(get(network, 'name', ' ')[0]) === type) {
              exchangeNetwork = network;
            }
          });
          const data = {
            sendType: PRODUCT_EXCHANGE_NETWORK,
            includeStudyReport: get(values, 'includeStudyReport', false),
            ...{
              facility,
              exchangeNetwork: { ...exchangeNetwork, facility: [] },
            },
            requestComment,
            deidentifyProfile,
          };
          callApiForSend(data, studiesLight);
        }
      });
    }
    historyPushWithTimeOut();
    toggleLoader(false);
  };

  const handleRequestEdit = async (values: any) => {
    toggleLoader();
    // redimed/epacs
    const facilityId = get(values, 'networks[0].fac.id', null);
    const type = upperCase(get(values, 'networks[0].type', ''));
    const facility = facilityId ? find(facilities, ['id', facilityId]) : null;
    let exchangeNetwork = {};
    exchangeNetworks.forEach((network) => {
      if (upperCase(get(network, 'name', ' ')[0]) === type) {
        exchangeNetwork = network;
      }
    });
    const deidentifyProfile = get(values, 'deidentifyProfile', null);
    const requestComment = get(values, 'requestComment', '');

    const sourceListOfStudies = get(initialValues, 'studies', []).map((study: any) => {
      const listOfSeries = sortBy(
        get(study, 'listOfSeries', [])?.map((serie: any) => pick(serie, ['uid'])),
        ['uid'],
      );

      return { ...pick(study, ['studyInstanceUid']), listOfSeries };
    });
    const preparedStudies = compact(fullSelectedStudies).map((study: any, index: number) => {
      const isEmptySelectedSourceSeries = isEmpty(
        get(sourceListOfStudies[index], 'listOfSeries', []),
      );
      return {
        ...study,
        listOfSeries: common.isDrSejf
          ? null
          : isEmptySelectedSourceSeries && isEmpty(compact(get(study, 'loadedSeries', null)))
          ? null
          : !isEmpty(compact(get(study, 'listOfSeries', null)))
          ? compact(get(study, 'listOfSeries', null))
          : !isEmptySelectedSourceSeries &&
            isEmpty(compact(get(study, 'listOfSeries', null))) &&
            !isEmpty(compact(get(study, 'loadedSeries', null)))
          ? compact(get(study, 'loadedSeries', null))
          : null,
      };
    });

    const data = {
      sendType: PRODUCT_EXCHANGE_NETWORK,
      includeStudyReport: get(values, 'includeStudyReport', false),
      studies: preparedStudies,
      ...(common.isDrSejf
        ? { viewer: get(values, 'viewer', false) }
        : { facility, exchangeNetwork: { ...exchangeNetwork, facility: [] }, deidentifyProfile }),
    };

    const editedRequest = {
      ...request,
      data: { ...request.data, ...data },
      requestComment,
    };
    let numberOfChanges = 0;
    if (
      get(initialValues, 'networks[0].type', '') !== type ||
      get(initialValues, 'networks[0].fac.id', '') !== facilityId ||
      get(initialValues, 'includeStudyReport', '') !==
        get(editedRequest, 'data.includeStudyReport', '') ||
      !isEmpty(get(editedRequest, 'requestComment', null)) ||
      !isEqual(
        get(initialValues, 'deidentifyProfile', null),
        get(editedRequest, 'data.deidentifyProfile', null),
      )
    ) {
      numberOfChanges++;
    }
    const targetListOfStudies = preparedStudies.map((study: any) => {
      const listOfSeries = sortBy(
        get(study, 'listOfSeries', [])?.map((serie: any) => pick(serie, ['uid'])),
        ['uid'],
      );
      return { ...pick(study, ['studyInstanceUid']), listOfSeries };
    });
    if (!isEqual(sourceListOfStudies, targetListOfStudies)) {
      ++numberOfChanges;
    }
    if (numberOfChanges === 0) {
      addErrorAlert(t('errorNoChange'));
    } else {
      await editRequestForSend(editedRequest).then(
        (response) => {
          addSuccessAlert(t('requestEdited'));
          historyPushWithTimeOut();
        },
        (error) => addErrorAlert(t('errorEditingRequest')),
      );
    }
    toggleLoader(false);
  };

  const onSubmit = handleSubmit(async (values) => {
    const formattedValues = formatAnonymizedValues(values, anonymizationProfile);

    isRequestEdit ? handleRequestEdit(formattedValues) : handleFormSubmit(formattedValues);
  });

  const getFacility = (facilityID: number, studySendAddressBook: IStudySendAddressBook[]) => {
    return find(studySendAddressBook, { id: facilityID });
  };

  const resetRequestEdited = async (studySendAddressBook: IStudySendAddressBook[]) => {
    setSendType(get(request, 'data.sendType'));
    const facilityId = get(request, 'data.facility.id');
    const selectedFacility = getFacility(facilityId, studySendAddressBook);
    const editedType = upperCase(get(request, 'data.exchangeNetwork.name[0]', ' '));
    const editedFacility = selectedFacility ? mapStudySendAddressBookItem(selectedFacility) : null;
    setEditedFacility(editedFacility);

    let anonymization = '0';
    let anonymized = null;

    const deidentifyProfile = get(request, 'data.deidentifyProfile');
    const deidentifyProfileName = get(deidentifyProfile, 'name', null);
    if (deidentifyProfileName === 'update' || deidentifyProfileName === 'cleanDescriptions') {
      anonymization = deidentifyProfileName;
      if (deidentifyProfileName === 'update') {
        anonymized = identifyValues(get(deidentifyProfile, 'values', null), anonymizationProfile);
      }
    }

    let transferAnonymized = {};
    forIn(anonymized, (value, key) => {
      transferAnonymized = { ...transferAnonymized, [`${key}`]: value };
    });

    const initialValues = {
      ...watchAllFields,
      anonymization,
      deidentifyProfile: deidentifyProfile
        ? {
            ...deidentifyProfile,
            values: sortBy(get(deidentifyProfile, 'values', []), ['name']),
          }
        : deidentifyProfile,
      ...transferAnonymized,
      networks: [{ fac: editedFacility, type: editedType }],
      includeStudyReport: get(request, 'data.includeStudyReport'),
      studies: studiesForViewer,
    };
    setInitialValues(initialValues);
  };

  const getEntities = async () => {
    toggleLoader();
    const [facilities, studySendAddressBookResponse, exchangeNetworks, productsByType] =
      await Promise.all([
        getAllFacilities(),
        getStudySendAddressBook(),
        getAll(),
        getProductByType('portal'),
      ]);

    let ex: IExchangeNetwork[] = [];
    if (isArray(exchangeNetworks)) {
      const activeExchangeNetworks = orderBy(
        removeFromLodash(exchangeNetworks, (obj) => obj.active === true),
        ['item.name'],
        ['asc'],
      );
      ex = activeExchangeNetworks.map((exchangeNetwork) => {
        const name = get(exchangeNetwork, 'name', '');
        if (name.toLowerCase() === 'epacs') {
          return { ...exchangeNetwork, nameType: 'E' };
        } else if (name.toLowerCase() === 'redimed') {
          return { ...exchangeNetwork, nameType: 'R' };
        } else {
          return { ...exchangeNetwork };
        }
      });
      setExchangeNetworks(ex);
    }

    let studySendAddressBook: IStudySendAddressBook[] = [];
    if (isArray(studySendAddressBookResponse)) {
      const avalaibleType: string[] = [];
      if (!isRequest) avalaibleType.push('M');
      if (hasRole('ROLE_SEND_EPACS') && find(ex, { nameType: 'E' })) avalaibleType.push('E');
      if (hasRole('ROLE_SEND_REDIMED') && find(ex, { nameType: 'R' })) avalaibleType.push('R');
      studySendAddressBookResponse.forEach((item: IStudySendAddressBook) => {
        const networks: IStudySendAddressBookNetwork[] = [];
        get(item, 'networks', []).forEach((network: IStudySendAddressBookNetwork) => {
          if (includes(avalaibleType, get(network, 'type'))) {
            networks.push(network);
          }
        });
        if (!isEmpty(networks)) {
          let id: any = get(item, 'id', 0);
          if (id === 0) {
            id = uuidv1();
          }
          studySendAddressBook.push({ ...item, networks, id });
        }
      });
      setStudySendAddressBook(studySendAddressBook);
      getFacilitiesSelect(studySendAddressBook);
    }

    let buttons: ISelectItem[] = [];
    if (isArray(facilities)) {
      setFacilities(facilities);

      buttons = [
        ...(isArray(facilities)
          ? [{ id: PRODUCT_EXCHANGE_NETWORK, label: t('sendTypeExchangeNetworks') }]
          : []),
        ...(hasRole('ROLE_SEND_DR_SEJF') && !isRequest
          ? [{ id: PRODUCT_DR_SEJF, label: t('sendTypeDrSejf') }]
          : []),
      ];
      setButtons(buttons);
    }

    if (isArray(productsByType)) {
      const product = getFirstActiveProduct(productsByType);

      if (product) {
        const systemSettings = get(product, 'systemSetting', []);
        const showCheckboxViewer = settingHasValue(systemSettings, 'studySendShowViewer', 'true');
        setShowCheckboxViewer(showCheckboxViewer);
        const showCheckboxStudyReport = settingHasValue(
          systemSettings,
          'studySendShowStudyReport',
          'true',
        );
        setShowCheckboxStudyReport(showCheckboxStudyReport);
        setShowCheckboxViewer(showCheckboxViewer);
        const studySendCheckStudyReport = settingHasValue(
          systemSettings,
          'studySendCheckStudyReport',
          'true',
        );
        setStudySendCheckStudyReport(
          studySendCheckStudyReport && showCheckboxStudyReport ? true : false,
        );
        const pdfOptions = {
          printDetail: true,
          printComments: settingHasValue(systemSettings, 'printComments', 'true'),
          printSeries: settingHasValue(systemSettings, 'printSeries', 'true'),
        };
        setPdfOptions(pdfOptions);

        if (buttons.length > 0) {
          if (!isRequestEdit) {
            if (buttons.length === 1) {
              setSendType(buttons[0].id);
            } else {
              const defaultSentMethod =
                getSettingValueForVariable(systemSettings, 'defaultSentMethod') || '';
              const methodIsInArray = find(buttons, { id: defaultSentMethod });
              if (!isEmpty(methodIsInArray)) {
                setSendType(defaultSentMethod);
              }
            }
          }
        }
      }
    }

    // Initialize buttons when it is request edit
    if (isRequestEdit) {
      const deidentifyProfile = get(request, 'data.deidentifyProfile');
      const deidentifyProfileName = get(deidentifyProfile, 'name', null);
      if (deidentifyProfileName === 'update' || deidentifyProfileName === 'cleanDescriptions') {
        const selectItemsAndProfiles = await loadProfiles();
        const profile = find(get(selectItemsAndProfiles, 'profiles', []), [
          'name',
          deidentifyProfileName,
        ]);
        setAnonymizationProfile(profile);
      } else {
        setAnonymizationProfile(null);
      }
    } else {
      setBulkStudies({
        type: SEND_CONFIRM,
        items: studiesForViewer,
      });
    }
    toggleLoader(false);
  };

  const loadStart = () => {
    const studies = isRequestEdit
      ? get(request, 'data.studies').map((study: any) => ({
          ...study,
          iid: generateIID(study),
          listOfSeries: sortSeriesBySeriesNumber(get(study, 'listOfSeries', null)),
        }))
      : sendStore;
    setStudiesForViewer(
      studies.map((study: any) => ({
        ...study,
        studyIsSelected: true,
        studyIsOpened: false,
        loadedSeries: [],
      })),
    );
    const detailIID = getParam(get(location, 'search'), 'backTo');
    let linkBack;
    if (detailIID) {
      linkBack = `/study/${detailIID}`;
    } else {
      linkBack = `/${isRequest ? 'requests' : 'studies'}`;
    }
    setLinkBack(linkBack);

    if (!isRequestEdit && isArray(studies) && studies.length < 1) {
      toggleLoader(false);
      return navigate(linkBack);
    } else {
      getEntities();
    }
  };

  useEffect(() => {
    loadStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sendType !== null) {
      callReset(sendType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendType]);

  useEffect(() => {
    if (isRequestEdit && initialValues) {
      reset(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    if (isRequestEdit || (!isEmpty(watchNetworks) && !isEmpty(studySendAddressBook))) {
      getFacilitiesSelect(studySendAddressBook);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchNetworks]);

  useEffect(() => {
    if (anonymizationProfile !== undefined) {
      if (isRequestEdit && !isEmpty(studySendAddressBook) && isEmpty(initialValues)) {
        resetRequestEdited(studySendAddressBook);
      } else {
        callReset('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anonymizationProfile?.name, studySendAddressBook.length]);

  useEffect(() => {
    const isDrSejf = sendType === PRODUCT_DR_SEJF;
    const isNetwork =
      sendType && sendType !== PRODUCT_DR_SEJF && sendType !== PRODUCT_CLOUD_PACS ? true : false;
    const lastWatchNetworks =
      watchNetworks !== undefined && isArray(watchNetworks) ? last(watchNetworks) : {};
    const lastType = get(lastWatchNetworks, 'type', '');
    const showAddFacilityButton =
      hasRole('ROLE_MULTIPLE_SEND_STUDY') && isNetwork && lastType ? true : false;
    const firstWatchNetworks =
      watchNetworks !== undefined && isArray(watchNetworks) ? first(watchNetworks) : {};
    const firstType = get(firstWatchNetworks, 'type', '');
    const canSend =
      sendType && somethingSelected && (isDrSejf || (isNetwork && firstType)) ? true : false;
    setCommon({ isDrSejf, isNetwork, canSend, showAddFacilityButton });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendType, watchNetworks, somethingSelected]);

  useEffect(() => {
    const fullSelectedStudies = compact(
      studiesForViewer.map((study: any) => {
        if (!study.studyIsSelected) {
          return false;
        }
        const areAllSeriesSelected = getAreAllSeriesSelected({ study });
        let studyWithSeries = { ...study }; // Create a shallow copy of study

        if (study?.listOfSeries?.length > 0) {
          studyWithSeries.listOfSeries = study.listOfSeries;
        }
        if (study?.loadedSeries?.length > 0) {
          studyWithSeries.listOfSeries = areAllSeriesSelected
            ? []
            : compact(
                study.loadedSeries.map((serie: any) => {
                  if (!serie.serieIsSelected) {
                    return false;
                  }
                  return serie;
                }),
              );
        }
        if (studyWithSeries?.listOfSeries === null) {
          studyWithSeries.listOfSeries = [];
        }
        return studyWithSeries;
      }),
    );
    setFullSelectedStudies(fullSelectedStudies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [somethingSelected]);

  const renderedSteps = () => {
    return <TourStudiesSend />;
  };

  return (
    <>
      <Header
        title={t(
          isRequestEdit
            ? 'studySendTitleRequestEdit'
            : isRequest
            ? 'studySendTitleRequest'
            : 'studySend',
        )}
        {...(linkBack ? { backUrl: `${linkBack}?action=back` } : {})}
        TourComponent={renderedSteps()}
      />
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <input {...register(`networks`)} type="hidden" />
          <WhiteBox title={t('studySendType')}>
            <Grid container={true} spacing={0} data-tour="studySendBy">
              <Grid item={true} xs={12}>
                {buttons.map((button: ISelectItem) => (
                  <CheckedButton
                    sx={{ minWidth: 16 * 8, mr: 1 }}
                    key={button.id}
                    active={sendType === button.id}
                    rightMargin={true}
                    onClick={() => setSendType(sendType === button.id ? '' : button.id)}
                  >
                    {button.label}
                  </CheckedButton>
                ))}
                {common.isDrSejf && (
                  <Box sx={{ mt: 1, position: 'relative' }}>
                    {hasRole('ROLE_SEND_FILES_DR_SEJF') && (
                      <Grid container={true} spacing={spacing}>
                        <Grid item={true} xs={12} md={6} lg={3} key={'sejfSendForm'}>
                          <FormSelect
                            name="sejfSendForm"
                            label={t('sejfSendForm')}
                            required={true}
                            items={[
                              { id: SEJF_SENDFORM_DICOM, label: t('dicom') },
                              { id: SEJF_SENDFORM_PDF, label: t('pdf') },
                            ]}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {sejfSendForm === SEJF_SENDFORM_DICOM && showCheckboxViewer && (
                      <FormSwitch name="viewer" label={t('viewer')} />
                    )}
                    {sejfSendForm === SEJF_SENDFORM_DICOM && showCheckboxStudyReport && (
                      <FormSwitch name="includeStudyReport" label={t('includeStudyReport')} />
                    )}
                    {sejfSendForm === SEJF_SENDFORM_PDF && (
                      <>
                        <FormSwitch
                          name="printDetail"
                          label={t('print_setting_detail')}
                          disabled={true}
                        />
                        <FormSwitch name="printComments" label={t('print_setting_comments')} />
                        <FormSwitch name="printSeries" label={t('print_setting_series')} />
                      </>
                    )}
                  </Box>
                )}
                {common.isNetwork &&
                  !isEmpty(studySendAddressBook) &&
                  (!isRequestEdit || (isRequestEdit && editedFacility)) && (
                    <Box sx={{ mt: 1, position: 'relative' }}>
                      <Grid container={true} spacing={spacing}>
                        {fields.map((field, index) => {
                          return (
                            <React.Fragment key={field.id}>
                              <Grid item={true} xs={4}>
                                <FormAutocomplete
                                  name={`networks[${index}].fac`}
                                  label={t('facility')}
                                  options={facilitiesSelect.sort(
                                    (a: any, b: any) =>
                                      -get(b, 'sorted', '').localeCompare(get(a, 'sorted', '')),
                                  )}
                                  groupBy={(option: any) => option.sorted}
                                  getOptionDisabled={(option: any) => option.disabled}
                                  renderOption={(props: any, option: any) => {
                                    return (
                                      <Box
                                        component="li"
                                        sx={{ '& > div': { ml: -1, mr: 1, flexShrink: 0 } }}
                                        {...props}
                                      >
                                        <Box
                                          sx={{
                                            borderRadius: '25px',
                                            width: 40,
                                            height: 16,
                                            backgroundColor: primaryColor,
                                            position: 'relative',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              fontSize: 11,
                                              color: 'white',
                                              position: 'absolute',
                                              top: '50%',
                                              left: '50%',
                                              transform: 'translate(-50%, -50%)',
                                            }}
                                          >
                                            {option.icons}
                                          </Box>
                                        </Box>
                                        {option.label}
                                      </Box>
                                    );
                                  }}
                                  disableClearable={true}
                                  placeholder={t('selectFacility')}
                                  required={index === 0}
                                  ownerOnChange={getExchangeNetworksFromFacility}
                                  index={index}
                                  defaultValue={editedFacility}
                                />
                              </Grid>
                              <Grid item={true} xs={4}>
                                <ConditionalSelect
                                  {...{
                                    control,
                                    index,
                                    t,
                                  }}
                                />
                              </Grid>
                              <Grid item={true} xs={2}>
                                {fields.length > 1 && (
                                  <Fab
                                    sx={{ mt: 1 }}
                                    aria-label="delete"
                                    size="small"
                                    color="primary"
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteIcon sx={{ fontSize: 18, color: '#fff' }} />
                                  </Fab>
                                )}
                              </Grid>
                            </React.Fragment>
                          );
                        })}
                      </Grid>
                      {!isRequest && common.showAddFacilityButton && (
                        <Box sx={{ mb: 1 }}>
                          <Fab
                            sx={{ mt: 1 }}
                            aria-label="add"
                            size="small"
                            color="primary"
                            onClick={() => append(emptyNetwork)}
                          >
                            <AddIcon sx={{ fontSize: 18, color: '#fff' }} />
                          </Fab>
                        </Box>
                      )}
                    </Box>
                  )}
                {common.isNetwork && showCheckboxStudyReport && (
                  <Box sx={{ mt: 1, position: 'relative' }}>
                    <Grid container={true} spacing={spacing}>
                      <Grid item={true} xs={12} md={6} lg={4}>
                        <FormSwitch name="includeStudyReport" label={t('includeStudyReport')} />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Grid>
              {common.isNetwork && common.canSend && hasRole('ROLE_ANONYMIZATION_IN_OPERATION') && (
                <>
                  <Grid item={true} xs={12}>
                    <Line topMargin={1} bottomMargin={2} />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Box sx={{ mr: 3 }}>
                      <AnonymizationForm
                        anonymizationWatch={anonymizationWatch}
                        setAnonymizationProfile={setAnonymizationProfile}
                        fields={anonymizationFields}
                        setFields={setAnonymizationFields}
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </WhiteBox>
          <Papeer sx={{ mt: 2 }}>
            <Box data-tour="studySendListOfStudies">
              <SeriesViewer
                studies={studiesForViewer}
                showStudiesCheckboxes={!isEmpty(sendType)}
                showSeriesCheckboxes={isExchangeNetwork}
                operations={operations}
              />
            </Box>

            <Grid container={true} spacing={1} sx={{ mt: 1 }}>
              <Grid item={true} xs={12}>
                <RequestAdditionalForm isRequest={isRequest} />
              </Grid>
            </Grid>

            <Grid container={true} justifyContent="flex-end">
              <Box data-tour="studySendButton">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!common.canSend}
                  type="submit"
                >
                  <SendIcon sx={{ mr: 1 }} />
                  {t(
                    isRequestEdit ? 'editRequestForSend' : isRequest ? 'setRequestForSend' : 'send',
                  )}
                </Button>
              </Box>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>
    </>
  );
};

export default StudiesSendDetail;

import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

export const TourNCPeH = ({ hasPatient }: any) => {
  const { t } = useTranslation('Reactour');

  const allSteps = () => {
    const steps = {
      '[data-tour="ncpehSearchForm"]': t('clinicalPortal.ncpeh.searchForm'),
      ...(hasPatient && {
        '[data-tour="ncpehSearchButton"]': t('clinicalPortal.ncpeh.searchButton'),
      }),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });

  return <HelperComponent />;
};

import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ICustomUppyFolderInputProps } from 'components/Import/_types';

export const CustomUppyFolderInput: React.FC<ICustomUppyFolderInputProps> = ({ uppy }) => {
  const { t } = useTranslation('Import');

  const handleFolderChange = (event: any) => {
    const files = Array.from(event.target.files);

    const fileObjects = files.map((file: any) => ({
      name: file.webkitRelativePath || file.name,
      type: file.type,
      data: file,
      source: 'Local',
      isRemote: false,
    }));

    uppy.addFiles(fileObjects);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => document.getElementById('folderInput')?.click()}
      >
        {t('uploadFolder')}
      </Button>

      <input
        type="file"
        id="folderInput"
        onChange={handleFolderChange}
        style={{ display: 'none' }}
        {...({ webkitdirectory: 'true' } as React.HTMLProps<HTMLInputElement>)}
        {...({ directory: 'true' } as React.HTMLProps<HTMLInputElement>)}
      />
    </>
  );
};

export default CustomUppyFolderInput;

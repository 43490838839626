import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isArray, isEmpty } from 'lodash';
import { format } from 'date-fns';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IRequestDetailStudies } from './_types';
import { generateIID, joinParams } from 'utils/study';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { findTranslation } from 'utils/grid/translate';
import { useLanguage } from 'utils/hooks/useLanguage';
import { encryptId } from 'utils/hooks/useApp';
import { paletteColorDark } from 'utils/variables';
import Header from 'components/Header/Header';
import {
  SEARCH_FUNCTION_COPY,
  SEARCH_FUNCTION_EXPORT,
  SEARCH_FUNCTION_MOVE,
  SEARCH_FUNCTION_SEND,
  STATE_FAILED,
  STATE_FINISHED,
  STATE_NEW,
  STATE_WAITING,
} from 'constants/constants';
import { useStudy } from 'utils/hooks/useStudy';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'requestDetailStudies';

export const RequestDetailStudies: React.FC<IRequestDetailStudies> = ({ title, rows }) => {
  const { t } = useTranslation('Request');
  const { currentLocale } = useLanguage();
  const { getPatientSexSlug } = useStudy();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'state',
        headerName: t('studyState'),
        valueGetter: (value: any, row: any) =>
          findTranslation(currentLocale, 'Request', get(row, 'state', '').toLowerCase()),
      },
      {
        field: 'name',
        headerName: t('grid.study.patientName'),
        renderCell: ({ row }: GridRenderCellParams) => {
          const state = get(row, 'state', '');
          const requestId = get(row, 'reqId', 0);
          const studyType = get(row, 'studyType', '');
          const operationType = get(row, 'operationType', '');
          const patientName = joinParams([
            get(row, 'patient.name.lastName'),
            get(row, 'patient.name.firstName'),
          ]);
          if (
            operationType === SEARCH_FUNCTION_SEND ||
            operationType === SEARCH_FUNCTION_EXPORT ||
            (state === STATE_WAITING && studyType === 'requested') ||
            ((state === STATE_FINISHED || state === STATE_NEW) &&
              (studyType === 'provided' ||
                (studyType === 'requested' && operationType === SEARCH_FUNCTION_COPY))) ||
            (state === STATE_FAILED &&
              studyType === 'requested' &&
              operationType === SEARCH_FUNCTION_MOVE)
          ) {
            return (
              <Link
                to={`/study/${encryptId(generateIID(row))}?backToRequest=${requestId}`}
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                  color: paletteColorDark,
                  fontWeight: 'bold',
                }}
              >
                {patientName}
              </Link>
            );
          } else {
            return (
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {patientName}
              </div>
            );
          }
        },
      },
      {
        field: 'patientId',
        headerName: t('grid.study.patientId'),
        valueGetter: (value: any, row: any) => get(row, 'patient.identificationNumber', ''),
      },
      {
        field: 'accessionNumber',
        headerName: t('grid.study.accessionNumber'),
      },
      {
        field: 'patientBirthDate',
        headerName: t('grid.study.patientBirthDate'),
        // type: 'date',
        renderCell: ({ row }: GridRenderCellParams) =>
          get(row, 'patient.dateBirth', '')
            ? format(new Date(get(row, 'patient.dateBirth', '')), 'dd.MM.yyyy')
            : '',
        valueGetter: (value: any, row: any) => get(row, 'patient.dateBirth', ''),
      },
      {
        field: 'patientSex',
        headerName: t('grid.study.patientSex'),
        valueGetter: (value: any, row: any) =>
          get(row, 'patient.sex', '') ? t(getPatientSexSlug(row.patient.sex)) : null,
      },
      {
        field: 'dateTime',
        headerName: t('grid.study.studyDate'),
        type: 'dateTime',
        valueGetter: (value: any, row: any) => {
          try {
            const dateTime = get(row, 'dateTime', '');
            return dateTime ? new Date(dateTime) : '';
          } catch (e) {
            return null;
          }
        },
        renderCell: ({ row }: GridRenderCellParams) => {
          return get(row, 'dateTime', '')
            ? format(new Date(get(row, 'dateTime', '')), 'dd.MM.yyyy HH:mm:ss')
            : '';
        },
      },
      {
        field: 'modality',
        headerName: t('grid.study.modalities'),
        valueGetter: (value: any, row: any) =>
          isArray(get(row, 'modalitiesInStudy')) ? get(row, 'modalitiesInStudy').join(', ') : '',
      },
      // HIDDEN until right number of sr/sn is returned from BE - http://10.0.0.201:8080/browse/MAPO-1147
      // {
      //   name: 'noOfInstances',
      //   title: 'grid.study.noOfInstances',
      //   getCellValue: (row: any): string =>
      //     `${get(row, 'numberOfStudyRelatedSeries', '')}/${get(
      //       row,
      //       'numberOfStudyRelatedInstances',
      //       '',
      //     )}`,
      // },
      { field: 'studyDescription', headerName: t('grid.study.studyDescription') },
      {
        field: 'archiveName',
        headerName: t('grid.study.archiveName'),
        valueGetter: (value: any, row: any) => get(row, 'archive.name', ''),
      },
    ]),
  );

  return (
    <>
      <Header title={title} topMargin={true} />
      <MuiGrid
        gridKey={muiGridKey}
        rows={rows}
        columns={columns}
        withoutMaxHight={true}
        hideFooter={true}
        flex={0}
        disableColumnMenu={isEmpty(rows)}
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />
    </>
  );
};

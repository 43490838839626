import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { find, get, isArray, isEmpty } from 'lodash';
import { AppBar, Grid, Tab, Tabs } from '@mui/material';

import { getAvailableViewers, getClinicalPortalSetting, updateClinicalPortalSetting } from './_api';
import { IClinicalPortalSetting } from './_types';
import { IProduct } from '../Products/_types';

import { useLanguage } from 'utils/hooks/useLanguage';
import { useLanguages } from 'utils/hooks/useLanguages';

import { Papeer } from 'components/Papeer/Papeer';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';

import FormSelect from 'components/Form/Select/Select';
import { ISelectItem } from 'components/Form/Select/_types';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { Line } from 'components/Line/Line';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';

import ClinicalPortalFormatsComponent from './ClinicalPortalFormats';
import ClinicalPortalTypesOfDocumentComponent from './ClinicalPortalTypesOfDocument';
import ClinicalPortalBookmarksComponent from './ClinicalPortalBookmarks';
import ClinicalPortalFormItemsComponent from './ClinicalPortalFormItems';
import { useWithTitle } from 'utils/hooks/useWithTitle';

export const ClinicalPortalAdministration: React.FC = () => {
  const { t } = useTranslation('ClinicalPortalSettings');
  useWithTitle(); // sets title to document
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { currentLocale } = useLanguage();
  const { languages } = useLanguages();
  const [entity, fetchEntity] = useState<IClinicalPortalSetting>();
  const [formats, setFormats] = useState<any[]>([]);
  const [typesOfDocument, setTypesOfDocument] = useState<any[]>([]);
  const [bookmarks, setBookmarks] = useState<any[]>([]);
  const [formItems, setFormItems] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>('formats');

  const methods = useForm<any>();
  const { handleSubmit, reset } = methods;

  const { toggleLoader } = useAppGlobals();

  const [viewerItems, setViewerItems] = useState<ISelectItem[]>([]);

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const viewer = get(values, 'viewer', null)
      ? find(viewerItems, { id: get(values, 'viewer') })
      : null;
    values.viewer = viewer !== undefined ? viewer : null;

    const response = await updateClinicalPortalSetting(values);

    if (response) {
      addSuccessAlert(t('saved'));
    } else {
      addErrorAlert(t('errorSaving'));
    }
    toggleLoader(false);
  });

  const getEntities = async () => {
    toggleLoader();
    try {
      const entity: any = await getClinicalPortalSetting();
      if (entity) {
        fetchEntity(entity);
        reset({
          viewer: get(entity, 'viewer.id', null),
          searchAttributes: null,
          formats: null,
          typesOfDocument: null,
          bookmarks: null,
        });

        setFormats(get(entity, 'formats', []));
        const typeOfDocuments = get(entity, 'typeOfDocuments', []).map((item: any) => ({
          ...item,
          currentLocale,
        }));
        setTypesOfDocument(typeOfDocuments);
      }
      const viewersLists: IProduct[] = await getAvailableViewers();
      if (isArray(viewersLists)) {
        const items: any = viewersLists.map((item: IProduct) => ({
          id: get(item, 'id'),
          code: item.code,
          name: item.name,
          label: item.name,
        }));
        setViewerItems(items);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultItems = {
    viewer: viewerItems,
  };

  return (
    <>
      {!isEmpty(entity) && languages ? (
        <>
          <Header title={t('clinicalPortal')} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} md={6} lg={3} xl={2}>
                    <FormSelect
                      name="viewer"
                      label={t('viewer')}
                      items={get(defaultItems, 'viewer', [])}
                    />
                  </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                  <Line />
                </Grid>
                <EntityButtons />
              </form>
            </FormProvider>
          </Papeer>
          <AppBar position="static" color="default" sx={{ mt: 2, mb: 2 }}>
            <Tabs
              value={activeTab}
              onChange={(event, value) => setActiveTab(value)}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons={false}
            >
              <Tab value="formats" label={t('formats')} />
              <Tab value="typesOfDocument" label={t('typesOfDocument')} />
              <Tab value="bookmarks" label={t('bookmarks')} />
              <Tab value="formItems" label={t('formItems')} />
            </Tabs>
          </AppBar>
          <>
            {activeTab === 'formats' && (
              <ClinicalPortalFormatsComponent formats={formats} setFormats={setFormats} />
            )}
            {activeTab === 'typesOfDocument' && (
              <ClinicalPortalTypesOfDocumentComponent
                typesOfDocument={typesOfDocument}
                setTypesOfDocument={setTypesOfDocument}
                formats={formats}
                currentLocale={currentLocale}
                languages={languages}
              />
            )}
            {activeTab === 'bookmarks' && (
              <ClinicalPortalBookmarksComponent
                bookmarks={bookmarks}
                setBookmarks={setBookmarks}
                typesOfDocument={typesOfDocument}
                languages={languages}
              />
            )}
            {activeTab === 'formItems' && (
              <ClinicalPortalFormItemsComponent
                formItems={formItems}
                setFormItems={setFormItems}
                languages={languages}
              />
            )}
          </>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

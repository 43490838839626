import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';

export const cstoreMetadata = (data: any): Promise<boolean> =>
  api()
    .post(`/portal-api/tools/cstore/metadata`, data)
    .then((response) => true)
    .catch((error) => false);

export const echo = (values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/tools/echo`, values)
    .then((response) => true)
    .catch((error) => false);

export const getDefaultAet = (): Promise<string | boolean> =>
  api()
    .get(`/portal-api/tools/cstore/getDefaultAet`, {})
    .then((response) => get(response, 'data'))
    .catch((error) => false);

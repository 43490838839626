import { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Button, Box, Tooltip, Typography } from '@mui/material';
import { get, filter, isArray, join, pick, isEmpty, keys, omitBy, isNil, isObject } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { DevTool } from '@hookform/devtools';

import Import from 'components/Import/Import';
import { useDicomParser } from 'components/Import/useFileParser';
import FormSelect from 'components/Form/Select/Select';
import useAlerts from 'components/Alerts/useAlerts';
import Header from 'components/Header/Header';
import { Announcement } from 'components/Announcement/Announcement';
import PatientAndStudyEditForm from 'modules/Studies/Form/PatientAndStudyEditForm';
import FilesGrid from 'components/Import/FilesGrid';
import { Papeer } from 'components/Papeer/Papeer';

import {
  SEARCH_FUNCTION_IMPORT,
  SELECT_ORDER_TO_IMPORT,
  SELECT_PATIENT_MANAGEMENT_TO_IMPORT,
  SELECT_STUDY_TO_IMPORT,
} from 'constants/constants';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { cleanObject } from 'utils/api';
import { checkUploadingStatus, cleanUppy, createUppy } from 'utils/import';
import { useStudy } from 'utils/hooks/useStudy';
import { useActions } from 'utils/hooks/useActions';

import { getRules } from 'modules/Import/_api';

import useValidationSchema from './_form';
import { sendMetadata, getPatientInformation } from './_api';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import ModalityForm from 'modules/Administration/ModalitiesUnitsStations/ModalityForm';
import { useAppLoader } from 'utils/hooks/useAppLoader';
import { RequestDialog } from 'components/RequestDialog/RequestDialog';
import PatientManagementDialog from './NonDicom/PatientManagementDialog';
import { FindStudyDialog } from './NonDicom/FindStudyDialog';
// import CustomUppyFileInput from './CustomUppyFileInput';
// import { CustomUppyFolderInput } from './CustomUppyFolderInput';
import { HumanBody } from 'components/HumanBody/HumanBody';
import { getAllDecubits } from 'modules/Administration/Dictionaries/_api';
import SelectForm from 'components/Form/Select/Select';
import { useLanguage } from 'utils/hooks/useLanguage';
import { IDecubitSelect } from './_types';
import { IDecubit } from 'modules/Administration/Dictionaries/_types';
import { UppyFile } from '@uppy/core';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import AddIcon from '@mui/icons-material/Add';
import AutocompleteMultipleForm from 'components/Form/Autocomplete/AutocompleteMultiple';
import { TourImportNonDicom } from './TourImportNonDicom';
import LinearProgressWithLabel from 'components/Import/LinearProgressWithLabel';

let uppyInstance: any;
let intervalUploading: any;

const ImportNonDicom: React.FC<any> = ({
  order,
  onOrderCloseDialog,
  c = false,
  study,
  isImageImportForStudy,
}) => {
  const { t } = useTranslation('Import');

  const [files, setFiles] = useState<UppyFile[]>([]);
  const { parseNonDicomFiles } = useDicomParser({ setFiles });
  const [timestamp, setTimestamp] = useState(new Date().getTime().toString());
  const { loadAllModalitiesToStore } = useAppLoader();
  const [completed, setCompleted] = useState(0);
  const [rules, setRules] = useState<any[]>([]);
  const [newModality, setNewModality] = useState<any>(null);
  const [modalityDialogState, setModalityDialogState] = useState<boolean>(false);
  const [orderDialog, setOrderDialog] = useState<boolean>(false);
  const [isPatientManagementDialogOpen, setIsPatientManagementDialogOpen] =
    useState<boolean>(false);
  const [isFindStudyDialogOpen, setIsFindStudyDialogOpen] = useState<boolean>(false);
  const [studyIsSelectedForImport, setStudyIsSelectedForImport] = useState<boolean>(false);
  const [showBodyPartForm, setBodyPartForm] = useState<boolean>(false);
  const [allDecubits, setAllDecubits] = useState<IDecubit[]>([]);
  const [selectedBodyPart, setSelectedBodyPart] = useState<string>('');
  const [decubitsPartById, setDecubitsPartById] = useState<IDecubit[]>([]);
  const [isDecubitFieldVisible, setIsDecubitFieldVisible] = useState<boolean>(false);
  const [selectedStudyByToken, setSelectedStudyByToken] = useState<any>({});
  const [canUseDataFromToken, setCanUseDataFromToken] = useState<boolean>(false);

  const { user, hasRole } = useUser();
  const { getArchivesForFunctions, validators, portalSetting, modalities, compactMode } =
    useAppInfo();
  const { backToStudyIID } = useStudy();
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert, addInfoAlert } = useAlerts();
  const { resetStudies } = useActions();
  const { currentLocale } = useLanguage();
  useWithTitle(); // sets title to document

  const setDisableAllFormFieldsAndSetStudySelected = (state: boolean) => {
    setStudyIsSelectedForImport(state);
  };

  const modalitiesForAutoComplete = useMemo(() => {
    return modalities.map((modality) => ({
      value: modality.name,
      label: modality.name,
    }));
  }, [modalities]);

  useEffect(() => {
    if (isObject(order) && !isEmpty(order)) {
      setOrderSelected(true);
    }
    return () => {
      resetStudies('import');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [orderSelected, setOrderSelected] = useState<boolean>(false);
  const [editAllowedForOrderImport, setEditAllowedForOrderImport] = useState<boolean>(false);
  const [editAllowedForImportWithStudy, setEditAllowedForImportWithStudy] =
    useState<boolean>(false);

  const datafromToken = get(user, 'fromToken.data', {}) as object;
  const addToStudy = get(datafromToken, 'addToStudy', false);

  const sources: { label: string; id: number }[] = getArchivesForFunctions(
    SEARCH_FUNCTION_IMPORT,
  ).map((archive) => ({ id: archive.id, label: archive.name }));

  const defaultModality = useMemo(() => {
    const modality = filter(modalities, { default: true }).map((mod) => ({
      value: mod.name,
      label: mod.name,
    }));
    return isArray(modality) && modality.length ? modality[0] : null;
  }, [modalities]);

  const multipleModality = !!studyIsSelectedForImport && !order;
  const { ImportNonDicomValidationScheme, ImportNonDicomValidationSchemeForImageAddingOnly } =
    useValidationSchema(
      t,
      get(validators, 'importForm', {}),
      portalSetting,
      isDecubitFieldVisible,
      multipleModality,
    );

  const methods = useForm<any>({
    resolver: yupResolver(
      isImageImportForStudy
        ? ImportNonDicomValidationSchemeForImageAddingOnly
        : ImportNonDicomValidationScheme,
    ),
    defaultValues: {
      study: { studyDate: new Date(), studyTime: new Date() },
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
    getValues,
  } = methods;

  const selectedSource = getValues('archiveId');

  //pokud se refreshne stranka, tak se nevybere archiv, tento useEffect to napravi
  useEffect(() => {
    if (!selectedSource && sources.length === 1) {
      setValue('archiveId', get(sources, '[0].id'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sources, selectedSource]);

  const studyInstanceUidFilled = getValues('study.studyInstanceUid');
  //pokud byla přidána nová modalita
  useEffect(() => {
    if (newModality) {
      loadAllModalitiesToStore();
      setValue('modality', { value: newModality.name, label: newModality.name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newModality]);

  useEffect(() => {
    if (!newModality && !order && !studyIsSelectedForImport) {
      setValue('modality', defaultModality);
    }
  }, [defaultModality, newModality, order, studyIsSelectedForImport, setValue]);

  const callApiForGetPatientInformation = async (patientId: string) => {
    toggleLoader();
    const patientInformations = await getPatientInformation(patientId);
    toggleLoader(false);
    return patientInformations;
  };

  const createDefaultValues = useCallback(async () => {
    //import v modality worklistu
    if (order) {
      return {
        patient: {
          ...pick(order.patient, ['name', 'identificationNumber', 'sex']),
          dateBirth: order?.patient?.dateBirth ? new Date(order.patient.dateBirth) : null,
        },
        study: {
          accessionNumber: get(order, 'accessionNumber'),
          studyDescription: get(order, 'requestedProcedure'),
          studyDate: order?.originalDatetimeRequested
            ? new Date(order.originalDatetimeRequested)
            : null,
          studyTime: order?.originalDatetimeRequested
            ? new Date(order.originalDatetimeRequested)
            : null,
          studyInstanceUid: get(order, 'studyInstanceUid'),
          patientDispositions: get(order, 'patientDispositions', {}),
        },
        modality: order?.modality
          ? { value: get(order, 'modality.name'), label: get(order, 'modality.name') }
          : defaultModality,
        requestingPhysician: get(order, 'requestingPhysician', {}),
        referringPhysician: get(order, 'referringPhysician', {}),
        performingPhysician: get(order, 'performingPhysician', {}),
        archiveId: sources.length === 1 ? get(sources, '[0].id') : null,
      };
    }

    if (!isEmpty(datafromToken)) {
      const patientID = get(datafromToken, 'patientID');
      let patientInfoFromApi = {};
      //MAPO-2642 getPatientInformation pouze v případě, že token obsahoval pouze patientID
      if (patientID && keys(omitBy(datafromToken, isNil) || {}).length === 1) {
        patientInfoFromApi = await callApiForGetPatientInformation(patientID);
      }
      const studyDate = get(datafromToken, 'studyDate', null);
      const birthDate = get(datafromToken, 'patientBirthDate', null);
      const initValues = {
        patient: {
          name: {
            firstName: get(datafromToken, 'patientName', ''),
            lastName: get(datafromToken, 'patientSurname', ''),
            middleName: get(datafromToken, 'patientMiddleName', ''),
            prefix: get(datafromToken, 'patientPrefix', ''),
            suffix: get(datafromToken, 'patientSuffix', ''),
          },
          identificationNumber: patientID,
          sex: get(datafromToken, 'sex', ''),
          dateBirth: birthDate ? new Date(birthDate) : '',
          ...(patientInfoFromApi ? patientInfoFromApi : {}),
        },
        study: {
          studyDate: studyDate ? new Date(studyDate) : new Date(),
          studyTime: new Date(),
          accessionNumber: get(datafromToken, 'accessionNumber', ''),
          studyDescription: get(datafromToken, 'studyDescription', ''),
          patientDispositions: {
            size: get(datafromToken, 'size', null),
            weight: get(datafromToken, 'weight', null),
          },
        },
        requestingPhysician: {
          name: {
            firstName: get(datafromToken, 'rqName', ''),
            lastName: get(datafromToken, 'rqSurname', ''),
            middleName: get(datafromToken, 'rqMiddleName', ''),
            prefix: get(datafromToken, 'rqPrefix', ''),
            suffix: get(datafromToken, 'rqSuffix', ''),
          },
        },
        referringPhysician: {
          name: {
            firstName: get(datafromToken, 'rfName', ''),
            lastName: get(datafromToken, 'rfSurname', ''),
            middleName: get(datafromToken, 'rfMiddleName', ''),
            prefix: get(datafromToken, 'rfPrefix', ''),
            suffix: get(datafromToken, 'rfSuffix', ''),
          },
        },
        performingPhysician: {
          name: {
            firstName: get(datafromToken, 'perFirstName', ''),
            lastName: get(datafromToken, 'perLastName', ''),
            middleName: get(datafromToken, 'perMiddleName', ''),
            prefix: get(datafromToken, 'perPrefix', ''),
            suffix: get(datafromToken, 'perSuffix', ''),
          },
        },
        archiveId: sources.length === 1 ? get(sources, '[0].id') : null,
      };
      return initValues;
    }

    // klasický nondicom import
    return {
      study: {
        studyDate: new Date(),
        studyTime: new Date(),
      },
      modality: defaultModality,
      archiveId: sources.length === 1 ? get(sources, '[0].id') : null,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, sources, defaultModality, datafromToken]);

  useEffect(() => {
    if (order) {
      const values = createDefaultValues();
      setValue('patient', get(values, 'patient', {}));
      setValue('study', get(values, 'study', {}));
      setValue('requestingPhysician', get(values, 'requestingPhysician', {}) || {});
      setValue('referringPhysician', get(values, 'referringPhysician', {}) || {});
      setValue('performingPhysician', get(values, 'performingPhysician', {}) || {});
      setValue('archiveId', get(values, 'archiveId', null));
      setValue('modality', get(values, 'modality', null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (datafromToken) {
      createDefaultValues().then((values) => {
        reset(values);
      });
      setValue('modality', defaultModality);
      if (addToStudy) {
        setCanUseDataFromToken(true);
        setIsFindStudyDialogOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedStudyByToken)) {
      // Je-li v tokenu vyplněn parametr addToStudy = true, pak se otevře dialog FindStudyDialog a
      // v SearchForm se pokusí dohledat vyšetření buď dle studyIUID nebo dle patientId a accessionNumber.
      // Pokud se dohledá:
      // - jedno vyšetření, pak se dialog zavře a zde se předvyplní dohledané údaje z vyšetření
      // - více vyšetření, pak dialog zůstane otevřený a uživatel si může vybrat vyšetření
      setIsFindStudyDialogOpen(false);
      const studyData = { ...selectedStudyByToken };
      const studyDate = get(studyData, 'dateTime', null);
      const birthDate = get(studyData, 'patient.dateBirth', null);
      const patientID = get(studyData, 'patient.identificationNumber', '');
      const modalitiesInStudy = get(studyData, 'modalitiesInStudy', null);
      let modality = {};
      if (modalitiesInStudy) {
        modalitiesInStudy.forEach((mod: string) => {
          modality = { value: mod, label: mod };
        });
      } else {
        modality = { defaultModality };
      }

      const initValues = {
        patient: {
          name: {
            firstName: get(studyData, 'patient.name.firstName', ''),
            lastName: get(studyData, 'patient.name.lastName', ''),
            middleName: get(studyData, 'patient.name.middleName', ''),
            prefix: get(studyData, 'patient.name.prefix', ''),
            suffix: get(studyData, 'patient.name.suffix', ''),
          },
          identificationNumber: patientID,
          sex: get(studyData, 'patient.sex', ''),
          dateBirth: birthDate ? new Date(birthDate) : '',
        },
        study: {
          studyDate: studyDate ? new Date(studyDate) : new Date(),
          studyTime: new Date(),
          accessionNumber: get(studyData, 'accessionNumber', ''),
          studyDescription: get(studyData, 'studyDescription', ''),
          patientDispositions: {
            size: get(studyData, 'size', null),
            weight: get(studyData, 'weight', null),
          },
          studyInstanceUid: get(studyData, 'studyInstanceUid', null),
        },
        requestingPhysician: {
          name: {
            firstName: get(studyData, 'requestingPhysician.name.firstName', ''),
            lastName: get(studyData, 'requestingPhysician.name.lastName', ''),
            middleName: get(studyData, 'requestingPhysician.name.middleName', ''),
            prefix: get(studyData, 'requestingPhysician.name.prefix', ''),
            suffix: get(studyData, 'requestingPhysician.name.suffix', ''),
          },
        },
        referringPhysician: {
          name: {
            firstName: get(studyData, 'referringPhysician.name.firstName', ''),
            lastName: get(studyData, 'referringPhysician.name.lastName', ''),
            middleName: get(studyData, 'referringPhysician.name.middleName', ''),
            prefix: get(studyData, 'referringPhysician.name.prefix', ''),
            suffix: get(studyData, 'referringPhysician.name.suffix', ''),
          },
        },
        performingPhysician: {
          name: {
            firstName: get(studyData, 'performingPhysician.name.firstName', ''),
            lastName: get(studyData, 'performingPhysician.name.lastName', ''),
            middleName: get(studyData, 'performingPhysician.name.middleName', ''),
            prefix: get(studyData, 'performingPhysician.name.prefix', ''),
            suffix: get(studyData, 'performingPhysician.name.suffix', ''),
          },
        },
        modality,
        archiveId: sources.length === 1 ? get(sources, '[0].id') : null,
      };
      reset(initValues);
      // setDisableAllFormFieldsAndSetStudySelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStudyByToken]);

  const removeFile = (id: string) => {
    uppyInstance.removeFile(id);
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const removeAllFiles = () => {
    uppyInstance.getFiles().forEach((file: any) => uppyInstance.removeFile(file.id));
    setFiles([]);
  };

  const onSubmit = async (values: any) => {
    const selectedDecubit = allDecubits.filter(
      (decubit) => decubit.id === parseInt(values.decubits),
    );

    let valuesToSubmit = values;
    if (isImageImportForStudy) {
      valuesToSubmit = {
        ...values,
        study,
      };
    }

    const clearValues = cleanObject(valuesToSubmit);

    toggleLoader();
    //TODO pořešit chybové stavy
    uppyInstance.upload().then(async () => {
      // Upload complete
      const modalities = get(clearValues, 'modality');
      const resp = await sendMetadata({
        archiveId: clearValues.archiveId,
        path: `${timestamp}_${user?.sub}`,
        timestamp,
        typeOfUpload: order ? 'mwl' : 'multimedia',
        decubits: selectedDecubit[0],
        study: {
          patient: {
            ...(clearValues.patient || {}),
            dateBirth: clearValues.patient?.dateBirth
              ? format(clearValues.patient.dateBirth, 'yyyy-MM-dd')
              : null,
          },
          ...clearValues.study,
          modalitiesInStudy: isArray(modalities)
            ? modalities.map((modality: any) => modality.value)
            : [modalities.value],
          dateTime:
            clearValues.study.studyDate && clearValues.study.studyTime
              ? `${format(clearValues.study.studyDate, 'yyyy-MM-dd')}T${format(
                  clearValues.study.studyTime,
                  'HH:mm',
                )}`
              : null,
          requestingPhysician: get(clearValues, 'requestingPhysician'),
          referringPhysician: get(clearValues, 'referringPhysician'),
          performingPhysician: get(clearValues, 'performingPhysician'),
          studyDate: undefined,
          studyTime: undefined,
          modality: undefined,
        },
      });
      if (resp) {
        addSuccessAlert(t('notifications.success'));

        if (order) {
          onOrderCloseDialog();
        }

        if (isImageImportForStudy) {
          onOrderCloseDialog();
        }
      } else {
        addErrorAlert(t('notifications.error'));
      }
    });
    setTimeout(() => {
      toggleLoader(false);
    }, 3000);
    const newTimestamp = new Date().getTime().toString();
    createUppy(
      'uppy',
      parseNonDicomFiles,
      newTimestamp,
      get(rules, 'multimedia', []),
      true,
      true,
      t,
    );
    uppyInstance = (window as any).UppyGlobalInstances[
      (window as any).UppyGlobalInstances.length - 1
    ];
    setTimestamp(newTimestamp);
    if (studyIsSelectedForImport) {
      resetStudies('import');
    }
    setStudyIsSelectedForImport(false);
    setFiles([]);
    const newValues = await createDefaultValues();
    reset(newValues);
  };

  const loadRules = async () => {
    toggleLoader(true);
    const rules = await getRules();
    if (rules) {
      setRules(rules);
      createUppy(
        'uppy',
        parseNonDicomFiles,
        timestamp,
        get(rules, 'multimedia', []),
        true,
        true,
        t,
      );
      uppyInstance = (window as any).UppyGlobalInstances[
        (window as any).UppyGlobalInstances.length - 1
      ];
    }
    toggleLoader(false);
  };

  const loadAllDecubits = async () => {
    const decubits = await getAllDecubits();
    if (decubits) {
      setAllDecubits(decubits);
    }
  };

  const prepareDecubitsForSelect = (allDecubits: IDecubit[]) => {
    const decubitsForSelect: IDecubitSelect[] = [];
    allDecubits.map((decubit: IDecubit) => {
      const translations = JSON.parse(decubit.translateJson);
      const translatedValue = translations[currentLocale] || '';
      const decubitItem = {
        id: decubit.id,
        label: `${translatedValue} (${decubit.bodyPartExaminated.dicomValue} | ${decubit.imageLaterality.dicomValue} | ${decubit.patientPosition.dicomValue})`,
      };
      return decubitsForSelect.push(decubitItem);
    });
    return decubitsForSelect;
  };

  //Conditional validation on decubits field based on hidden/show + erasing value when decubit field is hidden
  useEffect(() => {
    if (showBodyPartForm && !!selectedBodyPart && decubitsPartById.length > 0) {
      setIsDecubitFieldVisible(true);
    } else {
      setIsDecubitFieldVisible(false);
      setValue('decubits', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBodyPartForm, selectedBodyPart, decubitsPartById]);

  useEffect(() => {
    loadRules();
    loadAllDecubits();

    const isHttps = window.location.protocol === 'https:';
    if (!isHttps) {
      addInfoAlert(t('noHttps'));
    }

    intervalUploading = setInterval(() => {
      checkUploadingStatus(setCompleted);
    }, 500);
    return () => {
      clearInterval(intervalUploading);
      cleanUppy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedSteps = () => {
    return <TourImportNonDicom files={files} />;
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Header
            title={t('nonDicom.title')}
            backUrl={backToStudyIID ? `/study/${backToStudyIID}` : ''}
            TourComponent={renderedSteps()}
            scrollIntoView={true}
          />
          {completed ? (
            <div>
              <LinearProgressWithLabel value={completed} />
              <Typography component="div" sx={{ marginTop: '4px', marginBottom: '8px' }}>
                {t('uploadingFiles')}
              </Typography>
            </div>
          ) : null}
          <Papeer>
            <Box data-tour="import-nonDicom-allowedFiles">
              <Announcement
                label={t('allowedExtensions', {
                  rules: join(get(rules, 'multimedia', []), ', '),
                })}
                type={'info'}
                spaced={true}
                component={'div'}
              />
            </Box>

            <Grid container spacing={1} mt={2} data-tour="import-nonDicom-uppySelector">
              <Grid item xs={12}>
                {uppyInstance && <Import uppy={uppyInstance} addWebcam={true} />}
              </Grid>

              {!!files.length && (
                <>
                  <Grid item xs={12} data-tour="import-nonDicom-grid">
                    <FilesGrid files={files} removeFile={removeFile} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    sx={{ mt: 1, gap: 1 }}
                  >
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={removeAllFiles}
                      data-tour="import-nonDicom-deleteAll"
                    >
                      {t('Import:removeAll')}
                    </Button>
                    {/* <Box data-tour="import-nonDicom-uploadFolder">
                      <CustomUppyFolderInput uppy={uppyInstance} />
                    </Box>
                    <Box data-tour="import-nonDicom-uploadFiles">
                      <CustomUppyFileInput uppy={uppyInstance} />
              </Box> */}
                  </Grid>
                </>
              )}
            </Grid>
          </Papeer>

          {/* Do not show in modality worklist order detail import */}
          {!order && !isImageImportForStudy && (
            <Grid item xs={12}>
              {(hasRole('ROLE_IMPORT_MWL') || hasRole('ROLE_IMPORT_MPM')) && (
                // <Grid container={true} spacing={2} style={{ marginTop: 30 }}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={4}
                  mb={1}
                  gap={1}
                  data-tour="import-nonDicom-findButtons"
                >
                  {hasRole('ROLE_IMPORT_MWL') && (
                    <Button
                      variant="contained"
                      color="inherit"
                      size={'small'}
                      onClick={() => setOrderDialog(true)}
                    >
                      {/* <SearchIcon /> */}
                      {t('orderSearch')}
                    </Button>
                  )}
                  {hasRole('ROLE_IMPORT_MPM') && (
                    <Button
                      variant="contained"
                      color="inherit"
                      size={'small'}
                      onClick={() => setIsPatientManagementDialogOpen(true)}
                    >
                      {/* <SearchIcon /> */}
                      {t('patientManagementSearch')}
                    </Button>
                  )}
                  {hasRole('ROLE_IMPORT_STUDY') && (
                    <Button
                      variant="contained"
                      color="inherit"
                      size={'small'}
                      onClick={() => setIsFindStudyDialogOpen(true)}
                    >
                      {/* <SearchIcon /> */}
                      {t('findStudy')}
                    </Button>
                  )}
                </Box>
                // </Grid>
              )}
            </Grid>
          )}
          {isImageImportForStudy ? (
            <Grid item xs={12}>
              <Papeer>
                <Grid container={true} alignItems="flex-start">
                  <Grid item xs={10} md={3} lg={2}>
                    <AutocompleteMultipleForm
                      type="multipleAutocomplete"
                      name="modality"
                      label={t('modality')}
                      grid={{ xs: 12, md: 12, lg: 12 }}
                      options={modalitiesForAutoComplete}
                      required={true}
                      disabled={false}
                      placeholder=""
                      multiple={false}
                      errors={errors}
                      trigger={methods.trigger}
                    />
                  </Grid>

                  {hasRole('ROLE_CAN_ADD_MODALITY') && (
                    <Grid item={true} xs={2} md={2} lg={2}>
                      <Tooltip title={t('addModality')} sx={{ ml: 2 }}>
                        <Button
                          variant="contained"
                          size={compactMode ? 'small' : 'medium'}
                          color="primary"
                          onClick={() => setModalityDialogState(true)}
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Papeer>
            </Grid>
          ) : null}

          {!isImageImportForStudy && (
            <Grid item xs={12} data-tour="import-nonDicom-patientAndStudy" sx={{ mt: 1 }}>
              <PatientAndStudyEditForm
                multipleModality={multipleModality}
                isRequest={false}
                errors={errors}
                isImport={true}
                validatorFormName="importForm"
                collapseForm={!!order}
                modalities={modalities.map((modality) => ({
                  value: modality.name,
                  label: modality.name,
                }))}
                setModalityDialogState={setModalityDialogState}
                withStudyUID={studyInstanceUidFilled || !!order || !!studyIsSelectedForImport}
                setOrderDialog={setOrderDialog}
                orderSelected={orderSelected}
                studySelected={studyIsSelectedForImport}
                editAllowedForOrderImport={editAllowedForOrderImport}
                editAllowedForImportWithStudy={editAllowedForImportWithStudy}
                setEditAllowedForOrderImport={setEditAllowedForOrderImport}
                setEditAllowedForImportWithStudy={setEditAllowedForImportWithStudy}
              />
            </Grid>
          )}

          <Papeer sx={{ mt: 2, mb: 4 }}>
            {hasRole('ROLE_DECUBITS_USAGE') && (
              <Grid container spacing={0} alignItems="center" data-tour="import-nonDicom-decubits">
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => setBodyPartForm(!showBodyPartForm)}
                    sx={{ mb: 3 }}
                  >
                    {!showBodyPartForm ? t('Studies:addImageType') : t('Studies:doNotAddImageType')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={2}>
                  <div style={{ width: '100%' }}>
                    <Box sx={{ width: '100%', maxWidth: '200px', px: 2 }}>
                      {showBodyPartForm && (
                        <HumanBody
                          t={t}
                          allDecubits={allDecubits}
                          selectedBodypart={selectedBodyPart}
                          setSelectedBodypart={setSelectedBodyPart}
                          setDecubitsByPartId={setDecubitsPartById}
                        />
                      )}
                    </Box>
                  </div>
                </Grid>
                {showBodyPartForm &&
                  selectedBodyPart &&
                  (decubitsPartById.length > 0 ? (
                    <Grid item xs={12} sm={12} md={5} lg={3} sx={{ mt: { xs: 2, md: 0 } }}>
                      <SelectForm
                        name="decubits"
                        label={t('Studies:selectBodyPart')}
                        items={prepareDecubitsForSelect(decubitsPartById)}
                        fullWidth={false}
                        required={true}
                      />
                    </Grid>
                  ) : (
                    t('noDecubits')
                  ))}
              </Grid>
            )}
            <Grid container spacing={0} alignItems="center" gap={{ xs: 1, md: 0 }} mt={3}>
              <Grid item xs={12} md={4} lg={3} data-tour="import-nonDicom-targetArchive">
                <FormSelect
                  name="archiveId"
                  label={t('archive')}
                  items={sources}
                  errors={errors}
                  required={true}
                  disabled={!!studyIsSelectedForImport}
                />
              </Grid>
              <Grid item xs={12} md={8} lg={9} display="flex" justifyContent="flex-end" gap={1}>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={async () => {
                    const newValues = await createDefaultValues();
                    reset(newValues);
                    removeAllFiles();
                    setSelectedBodyPart('');
                    setBodyPartForm(false);
                  }}
                  data-tour="import-nonDicom-resetButton"
                >
                  {t('reset')}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!files.length}
                  data-tour="import-nonDicom-saveButton"
                >
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>
      <OverflowedDialog
        open={modalityDialogState}
        onClose={() => setModalityDialogState(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <ModalityForm
          toggleDialog={(value: any) => setModalityDialogState(value)}
          id="create"
          loadEntity={() => {}}
          isCreatingFromImport={true}
          setNewModality={setNewModality}
        />
      </OverflowedDialog>
      <DevTool control={control} />
      {orderDialog && (
        <RequestDialog
          toggleDialog={setOrderDialog}
          actions={[SELECT_ORDER_TO_IMPORT]}
          isActionFirstColumn={true}
          // reset={reset}
          setValue={setValue}
          setOrderSelected={setOrderSelected}
        />
      )}
      <PatientManagementDialog
        isOpen={isPatientManagementDialogOpen}
        setIsOpen={setIsPatientManagementDialogOpen}
        actions={[SELECT_PATIENT_MANAGEMENT_TO_IMPORT]}
        isActionFirstColumn={true}
        setValueStudyAndPatientForm={setValue}
      />
      <FindStudyDialog
        isOpen={isFindStudyDialogOpen}
        setIsOpen={setIsFindStudyDialogOpen}
        importProps={{ setFormValues: setValue }}
        actions={[SELECT_STUDY_TO_IMPORT]}
        isActionFirstColumn={true}
        disableStudyAndPatientForm={setDisableAllFormFieldsAndSetStudySelected}
        {...(addToStudy
          ? { datafromToken, setSelectedStudyByToken, canUseDataFromToken, setCanUseDataFromToken }
          : {})}
      />
    </>
  );
};

export default ImportNonDicom;

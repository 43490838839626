import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IFolder, IFolderForm } from './_types';

export const getAll = (): Promise<IFolder[]> =>
  api()
    .get(`/portal-api/folder/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllByUser = (): Promise<IFolder[]> =>
  api()
    .get(`/portal-api/folder/getAllByUser`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllByUserAndStudy = (archiveId: string, studyUID: string): Promise<IFolder[]> =>
  api()
    .get(`/portal-api/folder/getAllByUserAndStudy`, { params: { archiveId, studyUID } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getStudiesByStudyIdentity = (searchData: any, settings: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/folder/getStudiesByStudyIdentity`, searchData, settings)
    .then((response) => get(response, 'data'))
    .catch((thrown) => {
      return false;
    });

export const getFolder = (id: string): Promise<IFolderForm> =>
  api()
    .get(`/portal-api/folder/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editFolder = (folder: IFolderForm): Promise<any | boolean> =>
  api().post(`/portal-api/folder/update`, folder);

export const createFolder = (folder: IFolderForm): Promise<any | boolean> =>
  api().post(`/portal-api/folder/create`, folder);

export const removeFolder = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/folder/delete`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);

export const removeUser = (folderId: number, userId: number): Promise<boolean> =>
  api()
    .get(`/portal-api/folder/removeUser?folderId=${folderId}&userId=${userId}`, {})
    .then((response) => true)
    .catch((error) => false);

export const removeDocument = (folderId: number, documentUuid: number): Promise<boolean> =>
  api()
    .get(`/portal-api/folder/removeDocument?folderId=${folderId}&documentUuid=${documentUuid}`, {})
    .then((response) => true)
    .catch((error) => false);

export const addUser = (folderId: number, user: any): Promise<any | boolean> =>
  api().post(`/portal-api/folder/addUser?folderId=${folderId}`, user);

export const addUsers = (folderId: number, users: any): Promise<any | boolean> =>
  api().post(`/portal-api/folder/addUsers?folderId=${folderId}`, users);

export const updateUser = (folderId: number, user: any): Promise<any | boolean> =>
  api().post(`/portal-api/folder/updateUser?folderId=${folderId}`, user);

export const removeStudy = (
  folderId: number,
  archiveId: number,
  studyUID: string,
): Promise<boolean> =>
  api()
    .get(
      `/portal-api/folder/removeStudy?folderId=${folderId}&archiveId=${archiveId}&studyUID=${studyUID}`,
      {},
    )
    .then((response) => true)
    .catch((error) => false);

export const addStudy = (values: any): Promise<any | boolean> =>
  api().post(`/portal-api/folder/addStudiesToFolders`, values);

export const addSeries = (values: any): Promise<any | boolean> =>
  api().post(`/portal-api/folder/addSeries`, values);

export const removeSeries = (values: any): Promise<any | boolean> =>
  api().post(`/portal-api/folder/removeSeries`, values);

export const addDocuments = (values: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/folder/addDocumentsToFolders`, values)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const extendStudyExpiration = (values: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/folder/extendStudyExpiration`, values)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const userOpenStudy = (values: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/folder/userOpenStudy`, values)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

import HelperComponent from 'components/Tour/HelperComponent';
import { getDropDownStep } from 'components/Tour/_dropDownStep';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

interface ITourOrders {
  canAddUrl?: boolean;
  type: 'main' | 'create' | 'edit' | 'details';
}

export const TourOrders = ({ canAddUrl, type }: ITourOrders) => {
  const { t } = useTranslation('Reactour');

  const allSteps = ({ isDropDownOpen }: any) => {
    const steps = {
      ...(canAddUrl ? { '[data-tour="addButton"]': t('orders.addButton') } : {}),
      '[data-tour="fieldsTours"]': t('orders.fillInTheFields'),
      ...getDropDownStep({ isDropDownOpen, t }),
      '[data-tour="orders-clearButton"]': t('common.clearButton'),
      '[data-tour="searchButtonTours"]': (
        <>
          {t('common.searchButtonTours')}
          <br />
          <em>{t('common.searchButtonDisabledTours')}</em>
        </>
      ),

      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
      '.MuiDataGrid-columnHeaders': t('common.headTableWithSortAndFilter'), // Not happy with the class targeting, but did not find any other way to target the header of the table
      '[data-tour="orders-grid"]': (
        <>
          {t('common.bodyTable')} {t('orders.bodyTable')}
        </>
      ),
    };
    return steps;
  };

  const allStepsCreate = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="ordersCreate"]': t('orders.create'),
      ...getDropDownStep({
        isDropDownOpen,
        t,
        highlightedSelectors: ['.select-react-tour-class', '.autocomplete-react-tour-class'],
      }),
      '[data-tour="saveButton"]': t('orders.save'),
      '[data-tour="backButton"]': t('orders.back'),
    };
    return steps;
  };

  const allStepsEdit = () => {
    const steps = {
      '[data-tour="ordersEdit"]': t('orders.edit'),
      '[data-tour="saveButton"]': t('orders.save'),
      '[data-tour="backButton"]': t('orders.back'),
    };
    return steps;
  };

  const allStepsDetails = () => {
    const steps = {
      '[data-tour="backButton"]': t('orders.back'),
      '[data-tour="orderDetailMain"]': t('orders.detail.mainInfo'),
      '[data-tour="orderDetailAdditionalInfo"]': t('orders.detail.additionalInfo'),
      '[data-tour="orderDetailActionButtons"]': t('orders.detail.actionButtons'),
      '[data-tour="orderDetailNavBar"]': t('orders.detail.navBar'),
    };
    return steps;
  };

  let stepsDefinition: any;
  if (type === 'main') stepsDefinition = allSteps;
  if (type === 'create') stepsDefinition = allStepsCreate;
  if (type === 'edit') stepsDefinition = allStepsEdit;
  if (type === 'details') stepsDefinition = allStepsDetails;

  useReactTour({ stepsDefinition });
  return <HelperComponent />;
};

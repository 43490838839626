type AnyObject = { [key: string]: any };

export const cleanObject = (obj: AnyObject): AnyObject => {
  const result: AnyObject = {};

  for (const key in obj) {
    const value = obj[key];

    if (value === null || value === undefined || value === '') {
      continue;
    } else if (value instanceof Date) {
      // Přidáno pro zachování datových hodnot
      result[key] = value;
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      const cleanedSubObject = cleanObject(value);

      if (Object.keys(cleanedSubObject).length > 0) {
        result[key] = cleanedSubObject;
      }
    } else {
      result[key] = value;
    }
  }

  return result;
};

import { TFunction } from 'i18next';
import {
  DATE_BTN_TODAY,
  DATE_BTN_YESTERDAY,
  DATE_BTN_LAST_THREE_DAYS,
  DATE_BTN_LAST_WEEK,
  DATE_BTN_LAST_MONTH,
  DATE_BTN_CUSTOM_DATE,
} from 'constants/constants';

const useValidationSchema = (t: TFunction) => {
  const fields = [
    {
      name: 'user',
      label: t('userName'),
    },
    {
      name: 'patientId',
      label: t('patientID'),
    },
  ];

  const dateSelectItems = [
    DATE_BTN_TODAY,
    DATE_BTN_YESTERDAY,
    DATE_BTN_LAST_THREE_DAYS,
    DATE_BTN_LAST_WEEK,
    DATE_BTN_LAST_MONTH,
    DATE_BTN_CUSTOM_DATE,
  ].map((id) => ({ id, label: t(id) }));

  return { dateSelectItems, fields };
};

export default useValidationSchema;

import React from 'react';
import StudiesDistributeDetail from 'modules/Studies/StudiesDistribute/StudiesDistributeDetail';
import { IRequestsDistributeDetail } from './_types';
import { COPY } from 'constants/constants';

const RequestsCopyDetail: React.FC<IRequestsDistributeDetail> = () => (
  <StudiesDistributeDetail isRequest={true} requestAction={COPY} />
);

export default RequestsCopyDetail;

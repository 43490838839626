import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

interface ITourStudiesEdit {
  type: 'editStudyPatient' | 'editPatient';
}

export const TourStudiesEdit = ({ type }: ITourStudiesEdit) => {
  const { t } = useTranslation('Reactour');

  const allStepsEditStudyPatient = () => {
    const steps = {
      '[data-tour="backButton"]': t('study.back'),
      '[data-tour="editPatientStudyForm"]': t('study.edit.patientStudyForm'),
      '[data-tour="loadFromOrder"]': t('study.edit.loadFromOrder'),
      '[data-tour="saveButton"]': t('study.edit.saveButton'),
    };
    return steps;
  };

  const allStepsEditPatient = () => {
    const steps = {
      '[data-tour="backButton"]': t('study.back'),
      '[data-tour="editPatientForm"]': t('study.edit.patientForm'),
      '[data-tour="editPatientGrid"]': t('study.edit.patientFormGrid'),
      '[data-tour="saveButton"]': t('study.edit.saveButton'),
    };
    return steps;
  };
  let stepsDefinition;
  if (type === 'editStudyPatient') stepsDefinition = allStepsEditStudyPatient;
  if (type === 'editPatient') stepsDefinition = allStepsEditPatient;

  useReactTour({ stepsDefinition });

  return <HelperComponent />;
};

import { useState } from 'react';

export const useStatePreviewsAndDecubits = () => {
  const [previews, setPreviews] = useState<any[]>([]);
  const [saveDecubits, setSaveDecubits] = useState<any[]>([]);

  const setNewPreviews = (newPreview: any) => {
    return setPreviews([...previews, newPreview]);
  };
  const setNewSaveDecubits = (newSaveDecubit: any) => {
    return setSaveDecubits([...saveDecubits, newSaveDecubit]);
  };
  return {
    previews,
    setPreviews,
    saveDecubits,
    setSaveDecubits,
    setNewPreviews,
    setNewSaveDecubits,
  };
};

import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';
import { compact } from 'lodash';

const useValidationSchema = (t: TFunction, valueType: any) => {
  const { required } = useValidators();

  const ProductSettingFormSchema = yup.object().shape({
    variable: required(yup.string()),
  });

  const formItems = compact([
    {
      name: 'variable',
      label: t('variable'),
      required: true,
      disabled: true,
    },
    {
      name: 'description',
      label: t('description'),
    },
    {
      type: 'select',
      name: 'type_id',
      label: t('type'),
      disabled: true,
    },
    {
      type:
        valueType === 'BOOLEAN' || valueType === 'ENUM'
          ? 'select'
          : valueType === 'NUMBER'
          ? 'number'
          : '',
      name: 'value',
      label: t('value'),
    },
  ]);

  return { ProductSettingFormSchema, formItems };
};

export default useValidationSchema;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filter, get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { items } from '../Dictionaries/_items';
import { IDictionary, IItem } from '../Dictionaries/_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { settingHasValue } from 'utils/products';
import { useUser } from 'utils/hooks/useUser';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'dictionariesMUI';

const Dictionaries: React.FC = () => {
  const { t } = useTranslation('Dictionaries');
  useWithTitle();
  const navigate = useNavigate();

  const { hasRole } = useUser();
  const { portalProductSettings } = useAppInfo();

  const [rows, setRows] = useState<IDictionary[]>([]);

  useEffect(() => {
    const rows = filter(
      items.map((item: IItem) => ({
        name: t(item.name),
        id: item.name,
        canUse: item.canBeUsedBySetting
          ? settingHasValue(portalProductSettings, item.canBeUsedBySetting, 'true') &&
            hasRole(item.role)
          : hasRole(item.role),
      })),
      { canUse: true },
    );
    setRows(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCustomEntityDetail = (row: any) => {
    const editLink = `${get(row, 'id')}`;
    navigate(editLink);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 100,
        renderCell: ({ row }: GridRenderCellParams) => (
          <GridActionsCellItem
            icon={
              <Tooltip title={t('Grid:edit')}>
                <EditIcon />
              </Tooltip>
            }
            label={t('Grid:edit')}
            onClick={() => onCustomEntityDetail(row)}
          />
        ),
      },
      { field: 'name', headerName: t('name'), width: 320 },
    ]),
  );

  return (
    <MuiGrid
      gridKey={muiGridKey}
      rows={rows}
      columns={columns}
      initialSortMode={[{ field: 'name', sort: 'asc' }]}
    />
  );
};

export default Dictionaries;

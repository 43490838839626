import { IStudyResultForGrid } from 'modules/Search/_types';
import { useAppGlobals } from './useAppGlobals';
import { get, isArray } from 'lodash';
import { getDocumentsByTags } from 'modules/ClinicalPortal/Patient/_api';
import { useLanguage } from './useLanguage';

export const useSubmitDocumentsByTagForm = (
  rows: IStudyResultForGrid[],
  setRows: React.Dispatch<React.SetStateAction<IStudyResultForGrid[]>>,
) => {
  const { toggleLoader } = useAppGlobals();
  const { currentLocale } = useLanguage();

  const onSubmitDocumentsByTagForm = async (values: any) => {
    setRows([]);
    toggleLoader();
    await getDocumentsByTags(values).then((resp) => {
      if (isArray(resp)) {
        const rows: IStudyResultForGrid[] = [
          ...resp.map((item) => {
            return {
              ...item,
              typeOfDocumentName: get(item, 'typeOfDocument.translateJson')
                ? get(
                    JSON.parse(get(item, 'typeOfDocument.translateJson')),
                    currentLocale,
                    get(item, 'typeOfDocument.name'),
                  )
                : '?',
            };
          }),
        ];
        setRows(rows);
      }
    });
    toggleLoader(false);
  };
  return { onSubmitDocumentsByTagForm };
};

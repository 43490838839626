import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Description as GridDetailIcon,
} from '@mui/icons-material';
import { getAll, getAllByUser, removeFolder } from './_api';
import { IFolders } from './_types';
import { TourFolders } from './TourFolders';
import styles from 'components/SearchResultsMUI/_styles';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Header from 'components/Header/Header';
import { joinParams } from 'utils/study';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useUser } from 'utils/hooks/useUser';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { encryptId } from 'utils/hooks/useApp';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { useAppDispatch } from 'store/hooks';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'foldersMUI';

export const Folders: React.FC<IFolders> = ({ action }) => {
  const { t } = useTranslation('Folders');
  const dispatch = useAppDispatch();
  const { title } = useWithTitle();
  const navigate = useNavigate();

  const { confirmationData } = useAppInfo();
  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const [folders, setFolders] = useState<any[]>([]);

  const { toggleLoader } = useAppGlobals();

  const { hasRole, user } = useUser();
  const username = get(user, 'sub', '');
  const canAdministrateFolders = hasRole('ROLE_CAN_ADMINISTRATE_FOLDERS');

  const loadEntity = async () => {
    toggleLoader();
    try {
      const fn = action === 'administration' ? getAll : getAllByUser;
      const folders = await fn();
      if (folders && isArray(folders)) {
        setFolders(
          folders.map((item) => {
            const owner = get(item, 'user.username') === username;
            const permission = owner ? '' : (get(item, 'users[0].permission') as unknown as any);
            const canUpdate = owner || permission === 'WRITE_PLUS_UPDATE';
            const canDelete = owner;
            const backToByAction = action;
            return {
              ...item,
              canUpdate,
              canDelete,
              backToByAction,
            };
          }),
        );
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCustomEntityDetail = (row: any) => {
    navigate(
      `${
        action === 'view' || action === 'studyDetail' ? '' : '/administration'
      }/folders/${encryptId(get(row, 'id'))}/detail?from=${action}`,
    );
  };

  const onCustomEntityEdit = (row: any) => {
    navigate(`/folders/${encryptId(get(row, 'id'))}?from=${get(row, 'backToByAction', '')}`);
  };

  const { onEntityRemove } = useEntityRemove(removeFolder, t, loadEntity);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };
  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          renderCell: ({ row }: GridRenderCellParams) => (
            <>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('showDetail')}>
                    <GridDetailIcon />
                  </Tooltip>
                }
                label="Detail"
                onClick={() => onCustomEntityDetail(row)}
              />
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('action.edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('action.edit')}
                onClick={() => onCustomEntityEdit(row)}
                disabled={!get(row, 'canUpdate', true)}
              />
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('action.delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('action.delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                }}
                disabled={!get(row, 'canDelete', true)}
              />
            </>
          ),
        },
        {
          field: 'name',
          headerName: t('name'),
          hideable: false,
          renderCell: ({ row }: GridRenderCellParams) => (
            <Tooltip title={get(row, 'name')}>
              <Box onClick={() => onCustomEntityDetail(row)} sx={styles.gridLink}>
                {get(row, 'name')}
              </Box>
            </Tooltip>
          ),
        },
        {
          field: 'owner',
          headerName: t('owner'),
          valueGetter: (value: any, row: any) =>
            `${joinParams([get(row, 'user.lastName'), get(row, 'user.firstName')])}`,
        },
        {
          field: 'expiration',
          headerName: t('expiration'),
          renderCell: ({ row }: GridRenderCellParams) =>
            get(row, 'expiration') ? t(get(row, 'expiration')) : '',
        },
      ],
      200,
    ),
  );

  const renderedSteps = () => {
    return <TourFolders type="main" canAdministrateFolders={canAdministrateFolders} />;
  };

  return (
    <>
      <Header
        title={title}
        addUrl={
          canAdministrateFolders
            ? `${action === 'view' ? '' : '/administration'}/folders/create?from=${action}`
            : ''
        }
        TourComponent={renderedSteps()}
      />
      <MuiGrid
        gridKey={muiGridKey}
        rows={folders}
        columns={columns}
        dataTour="foldersGrid"
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />

      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={t('confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

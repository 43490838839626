import Box from '@mui/material/Box/Box';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import FormInput from 'components/Form/Input/Input';
import FormAutocompleteMultiple from 'components/Form/Autocomplete/AutocompleteMultiple';
import { Papeer } from 'components/Papeer/Papeer';
import { getAllItems } from 'modules/Tags/_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { FormType, IRegisterItem, ITagForm } from './_types';
import { filter, get, isEmpty, isString, sortBy } from 'lodash';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import Button from 'components/Buttons/Button';

const mainSx = { width: { xs: '100%', md: '50%' } };
const modalSx = { width: { xs: '100%', lg: '35%' } };

export const TagFormSearch: React.FC<ITagForm> = ({
  formType,
  showCommentField = false,
  isModalBoxStyling = false,
  showOnlyActiveTags = false,
  hideSearchAndClearButton = false,
}) => {
  const { t } = useTranslation('SearchForm');
  const { compactMode } = useAppInfo();
  const [activeTags, setActiveTags] = useState<IRegisterItem[]>([]);
  const [inactiveTags, setInactiveTags] = useState<IRegisterItem[]>([]);
  const { toggleLoader } = useAppGlobals();
  const [disableSearch, setDisableSearch] = useState<boolean>(true);

  const appliedStyle = isModalBoxStyling ? modalSx : mainSx;

  const { reset, watch } = useFormContext();

  const getRegistryItems = async () => {
    toggleLoader();
    try {
      const allItems = await getAllItems();
      if (!isEmpty(allItems)) {
        const activeTags: IRegisterItem[] = sortBy(filter(allItems, { active: true }), [
          (item) => (get(item, 'label', '') || '').toLowerCase(),
        ]);
        const inactiveTags: IRegisterItem[] = sortBy(filter(allItems, { active: false }), [
          (item) => (get(item, 'label', '') || '').toLowerCase(),
        ]);
        setActiveTags(activeTags);
        setInactiveTags(inactiveTags);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getRegistryItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allValues = watch();

  useEffect(() => {
    const activeTags = allValues.registerItems ? Object.values(allValues.registerItems.active) : [];
    const inactiveTags = allValues.registerItems
      ? Object.values(allValues.registerItems.inactive)
      : [];

    const canSearch =
      !isEmpty(activeTags) ||
      !isEmpty(inactiveTags) ||
      (isString(allValues.comment) && allValues.comment.length > 0);
    setDisableSearch(!canSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues]);

  const handleReset = () => {
    reset();
  };

  return (
    <>
      <Papeer
        sx={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          pt: compactMode ? 0.8 : 1,
          mb: compactMode ? 1 : 2,
        }}
      >
        {/* Comment */}
        {showCommentField && (
          <Box sx={appliedStyle} data-tour="tagsArchiveAndIntegrationPlatform-comment">
            <FormInput
              name="comment"
              label={t('comment')}
              InputProps={{ sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
            />
          </Box>
        )}
        <Box
          sx={{
            pt: compactMode ? (showCommentField ? 1 : 0.8) : showCommentField ? 2 : 1,
            pb: compactMode ? 1 : 2,
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: { xs: 'wrap' } }}>
            <Box
              sx={{ width: { xs: '100%', md: '50%' } }}
              data-tour="tagsArchiveAndIntegrationPlatform-multipleActiveTags"
            >
              <FormAutocompleteMultiple
                name="registerItems.active"
                label={t('tagsActive')}
                options={
                  activeTags?.map((item) => ({
                    value: item.value || '',
                    label: item.label || '',
                  })) || []
                }
                placeholder=""
              />
            </Box>
            {!showOnlyActiveTags && (
              <Box
                sx={{ width: { xs: '100%', md: '50%' }, pl: { lg: 1 } }}
                data-tour="tagsArchiveAndIntegrationPlatform-multipleInActiveTags"
              >
                <FormAutocompleteMultiple
                  name="registerItems.inactive"
                  label={t('tagsInactive')}
                  options={
                    inactiveTags?.map((item) => ({
                      value: item.value || '',
                      label: item.label || '',
                    })) || []
                  }
                  placeholder=""
                />
              </Box>
            )}
          </Box>
        </Box>

        {/* Buttons */}
        {formType !== FormType.AddTagForm && (
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={disableSearch}
              data-tour="tagsArchiveAndIntegrationPlatform-search"
            >
              {t('search')}
            </Button>
            <Button
              variant="contained"
              color="inherit"
              type="button"
              onClick={handleReset}
              data-tour="tagsArchiveAndIntegrationPlatform-clear"
            >
              {t('reset')}
            </Button>
          </Box>
        )}
      </Papeer>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { find, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { IAnonymizationForm, IAnonymizationProfileWithFields } from './_types';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormDatePicker from 'components/Form/Date/Date';
import { IField } from 'components/SeachForm/_types';
import { ISelectItem } from 'components/Form/Select/_types';
import { useAnonymizationProfiles } from 'utils/hooks/useAnonymizationProfiles';

const AnonymizationForm: React.FC<IAnonymizationForm> = ({
  anonymizationWatch,
  setAnonymizationProfile,
  setAnonymizationProfiles,
  anonymization,
  fields,
  setFields,
  disabled = false,
}) => {
  const { t } = useTranslation('StudyAnonymization');
  const { setValue } = useFormContext();

  const { loadProfiles } = useAnonymizationProfiles();

  const [selectItems, setSelectItems] = useState<ISelectItem[]>([]);
  const [profiles, setProfiles] = useState<IAnonymizationProfileWithFields[]>([]);

  const loadEntity = async () => {
    const defaultSelectItem = { id: '0', label: t('noAnonymization') };
    setSelectItems([defaultSelectItem]);
    const selectItemsAndProfiles = await loadProfiles();

    setProfiles(get(selectItemsAndProfiles, 'profiles', []));
    setSelectItems(get(selectItemsAndProfiles, 'selectItems', [defaultSelectItem]));
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let fields: any[] = [];
    if (profiles.length > 0 && anonymizationWatch) {
      const profile = find(profiles, ['name', anonymizationWatch]);
      setAnonymizationProfile(profile);
      if (anonymizationWatch !== '0') {
        fields = get(profile, 'fields', []);
      }
      setFields(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anonymizationWatch, profiles.length]);

  useEffect(() => {
    if (disabled) {
      setValue('anonymization', '0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);
  useEffect(() => {
    if (anonymization) {
      setValue('anonymization', anonymization);
      loadEntity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anonymization]);

  return (
    <>
      {selectItems.length > 0 && (
        <Grid item={true} xs={12} md={6} lg={4} data-tour="studyAnonymization">
          <FormSelect
            name="anonymization"
            label={t('anonymization')}
            required={true}
            items={selectItems}
            disabled={disabled}
          />
        </Grid>
      )}
      {fields.length > 0 && (
        <Grid item={true} xs={12} md={12} lg={12}>
          <div style={{ width: '100%' }}>
            <Typography component="title">{t('parameters')}</Typography>
            <Grid container={true} spacing={2} key={'anonymization-container'}>
              {fields.map((field: IField, id: any) => (
                <React.Fragment key={id}>
                  {get(field, 'type') === 'select' && (
                    <Grid item={true} xs={12} md={6} lg={2} key={field.name}>
                      {' '}
                      <FormSelect {...field} />
                    </Grid>
                  )}
                  {get(field, 'type') === 'datePicker' && (
                    <Grid item={true} xs={12} md={6} lg={2} key={field.name}>
                      {' '}
                      <FormDatePicker {...field} isRequired={get(field, 'required', false)} />
                    </Grid>
                  )}
                  {!get(field, 'type') && (
                    <Grid item={true} xs={12} md={6} lg={2} key={field.name}>
                      {' '}
                      <FormInput {...field} />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </div>
        </Grid>
      )}
    </>
  );
};

export default AnonymizationForm;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { get, isNumber, omit } from 'lodash';
import { Divider, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { createTest, editTest, getTest } from './_api';
import { ITestForm } from './_types';
import useValidationSchema from './_form';

import { Papeer } from 'components/Papeer/Papeer';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormSwitch from 'components/Form/Switch/Switch';
import FormDatePicker from 'components/Form/Date/Date';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useTestLists } from 'utils/hooks/useTestLists';
import { TourTests } from './TourTests';

const hashedId = true;
const apiDateFormat = 'yyyy-MM-dd';

export const TestForm: React.FC = () => {
  const { t } = useTranslation('Tests');
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const navigate = useNavigate();
  const { mappedTypes } = useTestLists(t);
  const { id, isCreating, paramsId } = useEntityInfo(hashedId);
  const [entity, fetchEntity] = useState<ITestForm>();
  const { title } = useWithEntityTitle(isCreating, entity, t);

  const linkBack =
    window.location.href.indexOf('?backTo=detail') > -1
      ? `/tests/manageTests/${paramsId}/detail`
      : '/tests/manageTests';

  const { TestFormSchema } = useValidationSchema();

  const methods = useForm<any>({
    resolver: yupResolver(TestFormSchema),
    defaultValues: {
      type: null,
      validFrom: null,
      validTo: null,
    },
  });
  const { handleSubmit, reset, register, getValues } = methods;

  const { toggleLoader } = useAppGlobals();

  const prepareValues = (values: any) => {
    const preparedValues: any = {
      ...omit(values, ['validFrom', 'validTo']),
      validFrom: `${format(get(values, 'validFrom'), apiDateFormat)}`,
      ...(values.validTo === null || values.validTo === ''
        ? { validTo: '' }
        : values.validTo
        ? { validTo: `${format(get(values, 'validTo'), apiDateFormat)}` }
        : {}),
    };
    return preparedValues;
  };
  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createTest : editTest;
    const preparedValues = prepareValues(values);
    const validFrom = get(preparedValues, 'validFrom', null);
    const validTo = get(preparedValues, 'validTo', null);
    if (validFrom && validTo && validFrom > validTo) {
      addErrorAlert(t('dateToCanNotBeBeforeDateFrom'));
    } else {
      await fn(preparedValues).then(
        (response) => {
          addSuccessAlert(t(isCreating ? 'saved' : 'edited'));
          navigate(linkBack);
        },
        (error) => {
          addErrorAlert(t('errorSaving'));
        },
      );
    }
    toggleLoader(false);
  });

  const type = getValues('type');

  const getEntity = async () => {
    toggleLoader();
    try {
      let test: ITestForm = {
        validFrom: null,
        validTo: null,
        type: '',
        showResults: false,
        repeatedly: true,
        allowPreview: true,
        public: false,
        name: '',
        maxWrongAnswersAllowed: null,
        maxTestLength: '',
        description: '',
      };

      if (!isCreating && isNumber(id)) {
        const entity: ITestForm = await getTest(id);
        if (entity) {
          test = {
            ...entity,
            validFrom: entity.validFrom ? new Date(entity.validFrom) : null,
            validTo: entity.validTo ? new Date(entity.validTo) : null,
          };
          fetchEntity(entity);
        }
      }
      reset({ ...test });
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GridDivider = () => (
    <Grid item={true} xs={12}>
      <Divider sx={{ mb: 0.5 }} />
    </Grid>
  );

  const renderedSteps = () => {
    return <TourTests type={isCreating ? 'create' : 'edit'} />;
  };

  return (
    <>
      <Header title={title} TourComponent={renderedSteps()} />
      <Papeer>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} data-tour={isCreating ? 'testCreate' : 'testEdit'}>
            <input {...register('id')} type="hidden" />
            <Grid container={true} spacing={2} alignItems="flex-start">
              <Grid item={true} xs={12} md={6} lg={3} xl={2}>
                <FormInput name="name" label={t('name')} required={true} />
              </Grid>
              <Grid item={true} xs={12} md={6} lg={3} xl={2}>
                <FormSelect name="type" label={t('type')} required={true} items={mappedTypes} />
              </Grid>
              <Grid item={true} xs={12} style={{ padding: 0 }} />
              <Grid item={true} xs={12} md={6}>
                <FormInput name="description" label={t('description')} multiline={true} rows={2} />
              </Grid>
              <GridDivider />
              <Grid item={true} xs={12} md={6} lg={3} xl={2}>
                <FormDatePicker name="validFrom" label={t('validFrom')} isRequired={true} />
              </Grid>
              <Grid item={true} xs={12} md={6} lg={3} xl={2}>
                <FormDatePicker name="validTo" label={t('validTo')} />
              </Grid>
              <GridDivider />
              <Grid item={true} xs={12} md={6} lg={3} xl={2}>
                <FormInput
                  name="maxWrongAnswersAllowed"
                  label={t('maxWrongAnswersAllowed')}
                  type="number"
                  inputProps={{ min: 0 }}
                  required={true}
                />
              </Grid>
              <Grid item={true} xs={12} md={6} lg={3} xl={2}>
                <FormInput
                  name="maxTestLength"
                  label={t('maxTestLength')}
                  required={true}
                  placeholder="hh:mm:ss"
                />
              </Grid>
              <GridDivider />
              <Grid item={true} xs={12} md={6} lg={4}>
                <FormSwitch name="public" label={t('public')} />
              </Grid>
              <Grid item={true} xs={12} style={{ padding: 0 }} />
              <Grid item={true} xs={12} md={6} lg={4}>
                <FormSwitch name="showResults" label={t('showResults')} />
              </Grid>
              <Grid item={true} xs={12} style={{ padding: 0 }} />
              <Grid item={true} xs={12} md={6} lg={4}>
                <FormSwitch name="repeatedly" label={t('repeatedly')} />
              </Grid>
              <Grid item={true} xs={12} style={{ padding: 0 }} />
              {type === 'STUDY' && (
                <Grid item={true} xs={12} md={6} lg={4}>
                  <FormSwitch name="allowPreview" label={t('allowPreview')} />
                </Grid>
              )}
              <GridDivider />
              <Grid item={true} xs={12}>
                <EntityButtons linkBack={linkBack} />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Papeer>
    </>
  );
};

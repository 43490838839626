import React, { useEffect, useState } from 'react';
import { find, forIn, get, isArray, join } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Edit as EditIcon } from '@mui/icons-material';
import { getProductByType } from '../Products/_api';
import { WorkplaceArchiveActionsForm } from './WorkplaceArchiveActionsForm';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'workPlaceArchiveActionsMUI';

const WorkplaceArchiveActionsComponent: React.FC<any> = ({
  code,
  originalArchives,
  workplaceId,
}) => {
  const { t } = useTranslation('Workplaces');
  const { compactMode } = useAppInfo();

  const [archivesByWorkplace, setArchivesByWorkplace] = useState<any[]>([]);
  const [archiveId, setArchiveId] = useState<any>(null);
  const [allowedFunctionsForDialog, setAllowedFunctionsForDialog] = useState<any>(null);
  const [renderedDialog, setRenderedDialog] = useState<any>(null);
  const [dialogState, toggleDialog] = useState<boolean>(false);

  const { toggleLoader } = useAppGlobals();

  const onEntityEdit = async (row: any) => {
    const archiveId = get(row, 'id', null);
    setArchiveId(archiveId);
    setAllowedFunctionsForDialog(get(row, 'allowedFunctions', null));
    toggleDialog(archiveId ? true : false);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 80,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:edit')}>
                  <EditIcon />
                </Tooltip>
              }
              label={t('Grid:edit')}
              onClick={() => onEntityEdit(row)}
            />
          );
        },
      },
      { field: 'name', headerName: t('name') },
      { field: 'stringFunctions', headerName: t('allowedFunctions') },
    ]),
  );

  const loadArchiveActions = async () => {
    toggleLoader();
    const archivesByWorkplace: any[] = [];
    const archives = await getProductByType('archive');
    if (isArray(archives)) {
      archives.forEach((archive) => {
        const isSelected = find(originalArchives, { id: archive.id }) ? true : false;
        if (isSelected) {
          const allowedFunctions = get(archive, `allowedFunctionByWorkplace.${code}`, null);
          let isAll = true;
          const selectedFunctions: any[] = [];
          forIn(allowedFunctions, (value, key) => {
            if (value) {
              selectedFunctions.push(t(key));
            } else {
              isAll = false;
            }
          });
          const stringFunctions = join(selectedFunctions, ', ');
          archivesByWorkplace.push({
            id: archive.id,
            name: archive.name,
            code: archive.code,
            allowedFunctions,
            isAll: isAll ? t('isAll') : '',
            stringFunctions,
          });
        }
      });
    }
    setArchivesByWorkplace(archivesByWorkplace);
    toggleLoader(false);
  };

  useEffect(() => {
    loadArchiveActions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const renderedDialogFunction = (dialogState: boolean) => {
    const id = archiveId ? archiveId : null;
    const renderedDialog = (
      <OverflowedDialog
        title={t('archiveAction')}
        open={dialogState}
        onClose={() => {
          toggleDialog(false);
          setArchiveId(null);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <WorkplaceArchiveActionsForm
          toggleDialog={toggleDialog}
          archiveId={id}
          workPlaceId={workplaceId}
          allowedFunctionsForDialog={allowedFunctionsForDialog}
          loadEntity={loadArchiveActions}
        />
      </OverflowedDialog>
    );
    setRenderedDialog(renderedDialog);
  };

  useEffect(() => {
    renderedDialogFunction(dialogState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogState]);

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={archivesByWorkplace}
        columns={columns}
        sxPapeer={{ mt: compactMode ? 1 : 2 }}
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />
      {renderedDialog}
    </>
  );
};

export default WorkplaceArchiveActionsComponent;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getRequest } from '../_api';
import { IRequestsDistributeDetailEdit } from './_types';
import { IExistingRequest } from '../_types';
import StudiesDistributeDetail from 'modules/Studies/StudiesDistribute/StudiesDistributeDetail';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';

const RequestsDistributeDetailEdit: React.FC<IRequestsDistributeDetailEdit> = ({ action }) => {
  const { id } = useEntityInfo();
  const navigate = useNavigate();

  const [request, fetchRequest] = useState<IExistingRequest>();

  const loadEntity = async () => {
    if (id) {
      const request = await getRequest(parseInt(id.toString(), 10));
      if (request) {
        fetchRequest(request);
      } else {
        navigate(`/requests/${action}`);
      }
    }
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {request ? (
        <StudiesDistributeDetail
          isRequest={true}
          isRequestEdit={true}
          request={request}
          requestAction={action}
        />
      ) : null}
    </>
  );
};

export default RequestsDistributeDetailEdit;

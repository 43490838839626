import React, { useEffect, useMemo, useState } from 'react';
import { compact, find, get, head, isArray, isEmpty, omitBy, orderBy, set, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { yupResolver } from '@hookform/resolvers/yup';
import { createSR, getAllSRDataTypes, getAllSRLanguages, getSrMacrodictionary } from './_api';
import { ISrForm, ISrMacroDictionary, IStudyDetailStructuredReport } from './_types';
import useValidationSchema from './_formSR';
import { useSrDictionary } from './useSrDictionary';
import { ISelectItem } from 'components/Form/Select/_types';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormDictionaryInput from 'components/Form/DictionaryInput/DictionaryInput';
import useAlerts from 'components/Alerts/useAlerts';
import { joinParams } from 'utils/study';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useUser } from 'utils/hooks/useUser';
import { Papeer } from 'components/Papeer/Papeer';
import Button from 'components/Buttons/Button';

const StudyDetailStructuredReport: React.FC<IStudyDetailStructuredReport> = ({
  srDialogState,
  setSrDialogState,
  study,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { toggleLoader } = useAppGlobals();
  const { SrFormSchema } = useValidationSchema(t, null);
  const { currentLocale } = useLanguage();
  const { user } = useUser();
  const { evalReplType, evalExprType } = useSrDictionary();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const [languages, setLanguages] = useState<ISelectItem[]>([]);
  const [languageId, setLanguageId] = useState<number>(2);
  const [firstDataTypes, setFirstDataTypes] = useState<any>(null);
  const [dataTypes, setDataTypes] = useState<ISelectItem[]>([]);
  const [srMacrodictionary, setSrMacroDictionary] = useState<ISrMacroDictionary[]>([]);
  const [texts, setTexts] = useState<any[]>(['']);

  const [expanded, setExpanded] = useState<string | false>(false);

  const methods = useForm<ISrForm>({
    resolver: yupResolver(SrFormSchema),
  });
  const { control, handleSubmit, reset, setValue, watch } = methods;
  const { fields, append, remove } = useFieldArray({ name: 'arrayItems', control });

  const physicianName = watch('physician');
  const arrayItems = watch('arrayItems');
  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const items = get(values, 'arrayItems', []).map((item, index) => {
      return {
        ...JSON.parse(item.item),
        text: texts[index],
      };
    });
    const newValues = {
      items,
      language: values.language,
      seriesDescription: values.seriesDescription,
      ...omitBy({ physician: omitBy(values.physician, isEmpty) }, isEmpty),
      study: {
        archive: get(study, 'archive', null),
        studyInstanceUid: get(study, 'studyInstanceUid', null),
      },
    };
    const response = await createSR(newValues);
    if (response) {
      setValue('seriesDescription', '');
      addSuccessAlert(t('createSrSubmitOk'));
      setSrDialogState(false);
    } else {
      addErrorAlert(t('createSrSubmitNok'));
    }
    toggleLoader(false);
  });

  const loadEntities = async () => {
    toggleLoader();
    const languages = await getAllSRLanguages();
    const dataTypes = await getAllSRDataTypes();
    if (isArray(languages)) {
      setLanguages(
        compact(
          languages.map((language) => {
            const json = JSON.parse(get(language, 'name', ''));
            const enLabel = get(json, 'en', '');
            const id = get(language, 'id');
            const label = get(json, currentLocale, '') || enLabel;
            let row = null;
            if (id) {
              row = { id, label };
            }
            return row;
          }),
        ),
      );

      const languagesEn = compact(
        languages.map((language) => {
          const json = JSON.parse(get(language, 'name', ''));
          const label = get(json, 'en', '');
          const id = get(language, 'id');
          let row = null;
          if (id) {
            row = { label, id };
          }
          return row;
        }),
      );
      const languageWithId = find(languagesEn, [
        'label',
        currentLocale === 'cs' ? 'Czech' : currentLocale === 'ru' ? 'Russian' : 'English',
      ]);
      const languageId = get(languageWithId, 'id') || 2;
      setLanguageId(languageId);
    }
    if (isArray(dataTypes)) {
      const sortDataTypes = sortBy(
        compact(
          dataTypes.map((dataType) => {
            const json = JSON.parse(get(dataType, 'name', ''));
            const id = get(dataType, 'configuration', '');
            if (id.includes('"Finding"')) {
              setFirstDataTypes(id);
            }
            const label = get(json, currentLocale, '') || get(json, 'en', '');
            let row = null;
            if (id) {
              row = { id, label };
            }
            return row;
          }),
        ),
        ['label'],
      );
      setDataTypes(sortDataTypes);
    }
    const srMacrodictionaryResponse = await getSrMacrodictionary();
    if (srMacrodictionaryResponse) {
      const sortedSrMacros = orderBy(srMacrodictionaryResponse, ['keyword'], ['desc']);
      const srMacroDictionary = sortedSrMacros.map((value, index) => {
        const type = get(value, 'type', undefined);
        if (type === 'repl') {
          set(value, 'content', evalReplType(get(value, 'content', undefined)));
        }
        if (type === 'expr') {
          set(value, 'content', evalExprType(get(value, 'content', undefined)));
        }
        return {
          name: get(value, 'keyword', 'keyword'),
          value: get(value, 'content', 'value'),
          caption: get(value, 'keyword', 'caption'),
          meta: get(value, 'content', 'content'),
          score: index,
        };
      });
      setSrMacroDictionary(srMacroDictionary);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newTypes = useMemo(() => {
    let newTypes: ISelectItem[] = [];
    if (!isEmpty(dataTypes) && isArray(arrayItems)) {
      newTypes = compact(
        dataTypes.map((type) => {
          let i = 0;
          arrayItems.forEach((item) => {
            if (type.id === item.item) {
              i++;
            }
          });
          if (i === 0) {
            return type;
          } else {
            return null;
          }
        }),
      );
    }
    return newTypes;
  }, [arrayItems, dataTypes]);

  const getNextFirstArrayItem = () => {
    return { item: get(head(newTypes), 'id', null), text: '' };
  };

  useMemo(() => {
    reset({
      language: languageId,
      physician: {
        lastName: get(user, 'last_name', ''),
        firstName: get(user, 'first_name', ''),
        middleName: get(user, 'middle_name', ''),
        prefix: get(user, 'prefix', ''),
        suffix: get(user, 'suffix', ''),
      },
      arrayItems: [{ item: firstDataTypes, text: '' }],
    });
  }, [firstDataTypes, languageId, reset, user]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const removeItem = (index: number) => {
    const newTexts = [...texts];
    newTexts[index] = null;
    setTexts(compact(newTexts));
  };

  useEffect(() => {
    (texts || []).forEach((item, index) => {
      setValue(`arrayItems.${index}.text`, item, { shouldValidate: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [texts]);

  return (
    <Dialog
      open={srDialogState}
      onClose={() => setSrDialogState(false)}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">{t('action.createSR')}</DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Papeer>
              <Grid container={true} alignItems="flex-end">
                <Grid item={true} xs={12}>
                  <FormInput
                    name="seriesDescription"
                    label={t('seriesDescription')}
                    required={true}
                    inputProps={{ maxLength: 64 }}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <FormSelect name="language" label={t('language')} items={languages} />
                </Grid>
                <Grid item={true} xs={12}>
                  <Accordion
                    expanded={expanded === 'physician'}
                    onChange={handleChange('physician')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel-physician-content"
                      id="panel-physician-header"
                    >
                      <Typography sx={{ width: '22%', flexShrink: 0 }}>{t('physician')}</Typography>
                      {expanded !== 'physician' && (
                        <Typography sx={{ color: 'text.secondary' }}>
                          {`${joinParams([
                            get(physicianName, 'prefix'),
                            get(physicianName, 'lastName', '').toUpperCase(),
                            get(physicianName, 'firstName'),
                            get(physicianName, 'middleName'),
                            get(physicianName, 'suffix'),
                          ])}`}
                        </Typography>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container={true} spacing={2}>
                        <Grid item={true} xs={6}>
                          <FormInput
                            name={'physician.lastName'}
                            label={t('lastName')}
                            inputProps={{ maxLength: 60 }}
                          />
                        </Grid>
                        <Grid item={true} xs={6}>
                          <FormInput
                            name={'physician.firstName'}
                            label={t('firstName')}
                            inputProps={{ maxLength: 60 }}
                          />
                        </Grid>
                        <Grid item={true} xs={6}>
                          <FormInput
                            name={'physician.middleName'}
                            label={t('middleName')}
                            inputProps={{ maxLength: 60 }}
                          />
                        </Grid>
                        <Grid item={true} xs={3}>
                          <FormInput
                            name={'physician.prefix'}
                            label={t('prefix')}
                            inputProps={{ maxLength: 60 }}
                          />
                        </Grid>
                        <Grid item={true} xs={3}>
                          <FormInput
                            name={'physician.suffix'}
                            label={t('suffix')}
                            inputProps={{ maxLength: 60 }}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item={true} xs={12}>
                  <Box sx={{ mt: 1, position: 'relative' }}>
                    <Grid container={true} spacing={2}>
                      {fields.map((field, index) => {
                        return (
                          <React.Fragment key={field.id}>
                            <Grid item={true} xs={2}>
                              <FormSelect
                                name={`arrayItems[${index}].item`}
                                label={t('dataTypes')}
                                items={[
                                  ...newTypes,
                                  find(dataTypes, { id: get(arrayItems, `[${index}].item`, null) }),
                                ]}
                              />
                            </Grid>
                            <Grid item={true} xs={9}>
                              <FormDictionaryInput
                                name={`arrayItems[${index}].text`}
                                items={srMacrodictionary}
                                required={true}
                                texts={texts}
                                setTexts={setTexts}
                                index={index}
                              />
                            </Grid>
                            <Grid item={true} xs={1}>
                              <Fab
                                sx={{ mt: 1 }}
                                aria-label="delete"
                                size="small"
                                color="primary"
                                onClick={() => {
                                  remove(index);
                                  removeItem(index);
                                }}
                                disabled={fields.length === 1}
                              >
                                <DeleteIcon sx={{ fontSize: 18, color: '#fff' }} />
                              </Fab>
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item={true} xs={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      displayPrint: 'none',
                    }}
                  >
                    <Button
                      onClick={() => setSrDialogState(false)}
                      variant="contained"
                      color="inherit"
                      sx={{ mr: 1 }}
                    >
                      {t('close')}
                    </Button>
                    {!isEmpty(newTypes) && (
                      <Box sx={{ mb: 1 }}>
                        <Fab
                          sx={{ mt: 1 }}
                          aria-label="add"
                          size="small"
                          color="primary"
                          onClick={() => append(getNextFirstArrayItem())}
                        >
                          <AddIcon sx={{ fontSize: 18, color: '#fff' }} />
                        </Fab>
                      </Box>
                    )}
                    <Button variant="contained" color="primary" type="submit">
                      {t('confirm')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Papeer>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default StudyDetailStructuredReport;

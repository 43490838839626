import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { ICheckbox } from './_types';

const CheckboxForm: React.FC<ICheckbox> = ({
  name,
  label = '',
  disabled,
  indeterminate = false,
  onChange,
}) => {
  const { control, watch } = useFormContext();
  const value = watch(name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              checked={value === true}
              indeterminate={indeterminate}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e.target.checked);
                }
              }}
            />
          }
          label={label}
          disabled={disabled}
          sx={{ mr: 1 }}
        />
      )}
    />
  );
};

export default CheckboxForm;

import React, { useEffect, useState } from 'react';
import { get, keys } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import usePatientAndStudySchema from './_formForPatientAndStudyEdit';
import { WhiteBox } from 'components/WhiteBox/WhiteBox';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormDatePicker from 'components/Form/Date/Date';
import FormTimePicker from 'components/Form/Date/Time';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useUser } from 'utils/hooks/useUser';
import { isDateInFuture } from 'utils/hooks/useDate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import AutocompleteMultipleForm from 'components/Form/Autocomplete/AutocompleteMultiple';
import AutocompleteForm from 'components/Form/Autocomplete/Autocomplete';
import { joinParams } from 'utils/study';
import { format, parse } from 'date-fns';
import { useForm2 } from 'utils/hooks/useForm2';
import { useFormContext } from 'react-hook-form';
import { Announcement } from 'components/Announcement/Announcement';
import { Papeer } from 'components/Papeer/Papeer';

const formatDate = 'dd. MM. yyyy';

const PatientAndStudyEditForm: React.FC<any> = ({
  isStudyEditType = false,
  setOrderDialog,
  errors,
  validatorFormName = 'editForm', // při importu se použije importForm
  collapseForm = false, // používá se při importu u modality worklistu, pacient a studie se zobrazí jako Accordion
  isImport = false,
  withStudyUID = false,
  modalities = [],
  setModalityDialogState = () => {},
  allFormsDisabled = false,
  isDicomOrder = false,
  study,
  showPatientName = false,
  isRequstDistribute = false, // při žádosti na přesun/kopii s opravou
  isRequestEdit = false, // při editaci žádosti na přesun/kopii s opravou
  multipleModality = true,
  orderSelected = false, // When editing from order, allow to edit by clicking button
  studySelected = false, // When study is selected in nondicom import form
  editAllowedForOrderImport = false, // Allow to edit when order is selected
  editAllowedForImportWithStudy = false, // Allow to edit when study is selected
  setEditAllowedForOrderImport,
  setEditAllowedForImportWithStudy,
}) => {
  const { t } = useTranslation('Studies');
  const { validators, portalSetting, compactMode } = useAppInfo();
  const { hasRole } = useUser();
  const [isStudyFormExpanded, setIsStudyFormExpanded] = useState<boolean>(false);
  const [dateIsFuture, setDateIsFuture] = useState<boolean>(false);

  const { fields } = usePatientAndStudySchema({
    t,
    validators: get(validators, validatorFormName, {}),
    portalSetting,
    isStudyEditType,
    isImport,
    withStudyUID,
    modalities,
    allDisabled: allFormsDisabled,
    isDicomOrder,
    isRequstDistribute,
    isRequestEdit,
    multipleModality,
    orderSelected,
    studySelected,
    editAllowedForOrderImport,
    editAllowedForImportWithStudy,
  });

  const renderedPatientInline = `${joinParams([
    get(study, 'patient.name.prefix'),
    get(study, 'patient.name.lastName'),
    get(study, 'patient.name.firstName'),
    get(study, 'patient.name.middleName'),
    get(study, 'patient.name.suffix'),
  ])} (${joinParams(
    [
      joinParams([t('patientID'), get(study, 'patient.identificationNumber', '-')], ': '),
      get(study, 'patient.sex', 'O')
        ? joinParams([t('sex'), t(`sex_${get(study, 'patient.sex', 'O')}`)], ': ')
        : null,
      get(study, 'patient.dateBirth')
        ? joinParams(
            [
              t('dateOfBirth'),
              format(parse(get(study, 'patient.dateBirth'), 'yyyy-MM-dd', new Date()), formatDate),
            ],
            ': ',
          )
        : null,
    ],
    ' | ',
  )})`;
  const {
    getValues,
    setValue,
    formState: { dirtyFields },
    trigger,
    watch,
  } = useFormContext();

  const dateBirthWatch = watch('patient.dateBirth');
  const studyDateWatch = watch('study.studyDate');

  const { getDateBirthdayFromPatientID } = useForm2();

  const patId = getValues('patient.identificationNumber');
  // pokud se napíše do patId rodné číslo, tak se má doplnit pohlaví a datum narození
  useEffect(() => {
    const isPatientIdDirty = get(dirtyFields, 'patient.identificationNumber');
    if (patId && isPatientIdDirty) {
      const { sex, date } = getDateBirthdayFromPatientID(patId);
      if (date) {
        setValue('patient.dateBirth', date);
      }
      if (sex) {
        setValue('patient.sex', sex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patId]);

  useEffect(() => {
    if (errors?.modality) {
      setIsStudyFormExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    const dateBirthIsFuture = isDateInFuture(dateBirthWatch);
    const studyDateIsFuture = isDateInFuture(studyDateWatch);
    if (dateBirthIsFuture || studyDateIsFuture) {
      setDateIsFuture(true);
    } else {
      setDateIsFuture(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateBirthWatch, studyDateWatch]);

  return (
    <Box data-tour="editPatientStudyForm">
      {dateIsFuture && (
        <Papeer>
          <Announcement label={t('Errors:dateIsFuture')} type="warning" spaced={true} />
        </Papeer>
      )}
      {keys(fields).map((key: any, index: any) => {
        let buttonToBeRendered = null;
        if (
          isStudyEditType &&
          setOrderDialog &&
          hasRole('ROLE_MWL_UPDATE_STUDY') &&
          key === 'study'
        ) {
          buttonToBeRendered = (
            <Button
              variant="contained"
              color="inherit"
              size="small"
              onClick={() => setOrderDialog(true)}
              data-tour="loadFromOrder"
            >
              {t('loadFromOrder')}
            </Button>
          );
        }

        if (key === 'study' && orderSelected) {
          buttonToBeRendered = (
            <>
              {editAllowedForOrderImport && (
                <Typography variant="caption" sx={{ color: 'red', mr: 2 }}>
                  {t('editingFromOrder')}
                </Typography>
              )}
              <FormControlLabel
                sx={{
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#333'),
                }}
                control={<Switch checked={editAllowedForOrderImport} />}
                onChange={(e, state) => {
                  setEditAllowedForOrderImport(state);
                }}
                label={t('allowOverwrite')}
              />
            </>
          );
        }

        if (key === 'study' && studySelected) {
          buttonToBeRendered = (
            <>
              {editAllowedForImportWithStudy && (
                <Typography variant="caption" sx={{ color: 'red', mr: 2 }}>
                  {t('editingFromStudy')}
                </Typography>
              )}
              <FormControlLabel
                sx={{
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#333'),
                }}
                control={<Switch checked={editAllowedForImportWithStudy} />}
                onChange={(e, state) => {
                  setEditAllowedForImportWithStudy(state);
                }}
                label={t('allowOverwrite')}
              />
            </>
          );
        }

        const forms = keys(get(fields, key, [])).map((row: any, i: any) => (
          <Grid container={true} spacing={1} key={`${key}_${index}_${i}`}>
            {get(get(fields, key, []), row, []).map((field: any) => {
              const type = get(field, 'type');
              return (
                <Grid item={true} xs={12} md={6} lg={2} {...field.grid} key={field.name}>
                  {type === 'select' && <FormSelect {...field} errors={errors} />}

                  {/* autocomplete je zatím jen pro modalitu + tlačítko pro přidání nové modality*/}
                  {type === 'autocomplete' || type === 'multipleAutocomplete' ? (
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      {type === 'autocomplete' ? (
                        <Grid item={true} xs={10} md={3} lg={2}>
                          <AutocompleteForm {...field} placeholder="" errors={errors} />
                        </Grid>
                      ) : null}
                      {type === 'multipleAutocomplete' ? (
                        <Grid item={true} xs={10} md={3} lg={3}>
                          <AutocompleteMultipleForm
                            {...field}
                            placeholder=""
                            multiple={multipleModality}
                            errors={errors}
                            trigger={trigger}
                          />
                        </Grid>
                      ) : null}

                      {hasRole('ROLE_CAN_ADD_MODALITY') &&
                        !get(field, 'disabled', false) &&
                        !isImport && (
                          <Grid item={true} xs={2} md={2} lg={2}>
                            <Tooltip title={t('addModality')} sx={{ ml: 2 }}>
                              <Button
                                variant="contained"
                                size={compactMode ? 'small' : 'medium'}
                                color="primary"
                                onClick={() => setModalityDialogState(true)}
                              >
                                <AddIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                    </Grid>
                  ) : null}

                  {type === 'datePicker' && (
                    <FormDatePicker
                      {...field}
                      isRequired={get(field, 'required', false)}
                      errors={errors}
                    />
                  )}
                  {type === 'timePicker' && (
                    <FormTimePicker
                      {...field}
                      isRequired={get(field, 'required', false)}
                      errors={errors}
                    />
                  )}
                  {type === 'div' && (
                    <Box sx={{ pt: 1, mt: 1 }}>
                      <Typography component="h6">{get(field, 'label')}</Typography>
                    </Box>
                  )}
                  {type === 'number' &&
                    (get(field, 'tooltip', false) ? (
                      <Tooltip title={field.tooltip} placement="right" arrow={true}>
                        <span>
                          <FormInput {...field} type="number" errors={errors} />
                        </span>
                      </Tooltip>
                    ) : (
                      <FormInput {...field} type="number" errors={errors} />
                    ))}
                  {!type && <FormInput {...field} type="text" errors={errors} />}
                </Grid>
              );
            })}
          </Grid>
        ));
        return collapseForm ? (
          <Accordion
            key={`${key}_${index}`}
            expanded={key === 'study' ? isStudyFormExpanded : undefined} //undefined is default behavior
            onChange={(e, expanded) => {
              if (key === 'study') {
                setIsStudyFormExpanded(expanded);
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{`${t(key)} ${
                key === 'patient' && showPatientName ? ` ${renderedPatientInline}` : ''
              }`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column-reverse',
                  mb: 1,
                }}
              >
                {buttonToBeRendered}
              </Box>
              {forms}
            </AccordionDetails>
          </Accordion>
        ) : (
          <Box data-tour={isStudyEditType ? 'editPatientStudyForm' : 'editPatientForm'}>
            <WhiteBox
              title={t(`studiesEditStudy.${key}`)}
              key={`${key}_${index}`}
              button={buttonToBeRendered}
              topMargin={true}
              sx={{ mt: compactMode ? 1 : 2 }}
            >
              {forms}
            </WhiteBox>
          </Box>
        );
      })}
    </Box>
  );
};

export default PatientAndStudyEditForm;

import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IFacility } from './_types';

export const getAll = (): Promise<IFacility[]> =>
  api()
    .get(`/portal-api/facility/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getFacility = (id: string): Promise<IFacility> =>
  api()
    .get(`/portal-api/facility/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getActiveFacility = (): Promise<IFacility[]> =>
  api()
    .get(`/portal-api/facility/getActiveFacility`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editFacility = (facility: IFacility): Promise<boolean> =>
  api()
    .post(`/portal-api/facility/edit`, facility)
    .then((response) => true)
    .catch((error) => false);

export const createFacility = (facility: IFacility): Promise<boolean> =>
  api()
    .post(`/portal-api/facility/create`, facility)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const setActiveState = (facilityId: string, active: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/facility/setActiveState`, {
      params: { facilityId, active },
    })
    .then((response) => true)
    .catch((error) => false);

export const setActiveFacility = (id: string): Promise<boolean> =>
  api()
    .get(`/portal-api/facility/setActiveFacility`, {
      params: { id },
    })
    .then((response) => true)
    .catch((error) => false);

export const editFacilityExchangeNetwork = (facilityExchangeNetwork: any): Promise<boolean> =>
  api()
    .post(`/portal-api/facility/facilityExchangeNetworkEdit`, facilityExchangeNetwork)
    .then((response) => true)
    .catch((error) => false);

export const createFacilityExchangeNetwork = (facilityExchangeNetwork: any): Promise<boolean> =>
  api()
    .post(`/portal-api/facility/facilityExchangeNetworkAdd`, facilityExchangeNetwork)
    .then((response) => true)
    .catch((error) => false);

export const removeFacilityExchangeNetwork = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/facility/facilityExchangeNetworkRemove`, {
      params: { id },
    })
    .then((response) => true)
    .catch((error) => false);

export const getAllAvailableFacilities = (): Promise<IFacility[]> =>
  api()
    .get(`/portal-api/facility/getAllActiveByAvailableExchangeNetworks`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const checkFacilityCode = (facilityCode: any): Promise<boolean> =>
  api()
    .get(`/portal-api/facility/checkFacilityCode`, {
      params: { facilityCode },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const updateFacilitiesByRemoteSource = (): Promise<boolean> =>
  api()
    .get(`/portal-api/facility/updateFacilitiesByRemoteSource`, {})
    .then((response) => true)
    .catch((error) => false);

import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';
import { IStudy } from 'modules/Search/_types';

// Edit study directly
export const editStudy = (data: any, studies: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/study/editStudy`, { ...data, studies })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

// Edit patient directly
export const editPatient = (
  oldPatient: any,
  patient: any,
  source: any,
  requestComment: string | null,
): Promise<any | boolean> =>
  api()
    .post(`/portal-api/study/editPatient`, { oldPatient, patient, source, requestComment })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

// Create request for study edit
export const createRequestForUpdateStudy = (data: any, studies: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/studyRequest/createForUpdateStudy`, { ...data, studies })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

// Create request for patient edit
export const createRequestForUpdatePatient = (
  oldPatient: any,
  patient: any,
  source: any,
  requestComment: string | null,
): Promise<any | boolean> =>
  api()
    .post(`/portal-api/studyRequest/createForUpdatePatient`, {
      oldPatient,
      patient,
      source,
      requestComment,
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editForUpdatePatient = (request: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/studyRequest/editForUpdatePatient`, request)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editForUpdateStudy = (request: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/studyRequest/editForUpdateStudy`, request)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getStudiesForPatient = (patientID: any, sources: any): Promise<false | IStudy[]> =>
  api()
    .post(`/portal-api/study/getStudies`, {
      patient: { id: patientID },
      source: {
        sources,
      },
      exactSearch: true,
      study: {},
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getProductsByTypeAndFunction = (
  productType: string,
  searchFunction: string,
): Promise<any> =>
  api()
    .get(`/portal-api/product/getProductsByTypeAndFunction`, {
      params: { productType, function: searchFunction },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

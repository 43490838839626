import { TFunction } from 'i18next';
import * as yup from 'yup';

import usePatientAndStudySchema from 'modules/Studies/Form/_formForPatientAndStudyEdit';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = (
  t: TFunction,
  validators: any,
  portalSetting: any,
  isDecubitFieldVisible?: boolean,
  multipleModality: boolean = false,
) => {
  const scheme1 = usePatientAndStudySchema({
    t,
    validators,
    portalSetting,
    isStudyEditType: true, //isStudyEditType
    isImport: true, //isImport
    multipleModality,
  });

  const scheme2 = usePatientAndStudySchema({
    t,
    validators,
    portalSetting,
    isStudyEditType: false, //isStudyEditType
  });

  const schemeDicom = usePatientAndStudySchema({
    t,
    validators,
    portalSetting,
    isStudyEditType: true, //isStudyEditType
    isImport: true, //isImport
    multipleModality: false,
  });

  const { required } = useValidators();

  const ImportNonDicomValidationScheme = yup.object().shape({
    ...scheme1.studyAndPatientEditSchema,
    archiveId: required(yup.string()),
    decubits: yup.string().when([], {
      is: () => isDecubitFieldVisible,
      then: () => required(yup.string()),
    }),
  });

  const ImportNonDicomValidationSchemeForImageAddingOnly = yup.object().shape({
    // ...scheme1.studyAndPatientEditSchema,
    archiveId: required(yup.string()),
    decubits: yup.string().when([], {
      is: () => isDecubitFieldVisible,
      then: () => required(yup.string()),
    }),
    modality: yup.lazy((value) =>
      multipleModality
        ? required(
            yup
              .array()
              .of(yup.object().nullable())
              .nullable()
              .test(
                'empty-array',
                t('Errors:required'),
                (value) => value === null || (Array.isArray(value) && value.length > 0),
              ),
          )
        : required(yup.object().nullable()),
    ),
  });

  const ImportDicomValidationScheme = yup.object().shape({
    // ...schemeDicom.studyAndPatientEditSchema,
    archiveId: required(yup.string()),
  });

  const ModalityWorklistImportDicomValidationScheme = yup.object().shape({
    ...schemeDicom.studyAndPatientEditSchema,
    archiveId: required(yup.string()),
  });

  const EditDicomTagsValidationScheme = yup.object().shape({
    ...scheme2.studyAndPatientEditSchema,
  });

  return {
    ImportNonDicomValidationScheme,
    ImportDicomValidationScheme,
    EditDicomTagsValidationScheme,
    ModalityWorklistImportDicomValidationScheme,
    ImportNonDicomValidationSchemeForImageAddingOnly,
  };
};

export default useValidationSchema;

import { addDays, addMonths } from 'date-fns';
import { get, includes, replace } from 'lodash';
import React from 'react';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { getSettingValueForVariable } from 'utils/products';
import { userRetire } from './_api';
import useAlerts from 'components/Alerts/useAlerts';
import { useTranslation } from 'react-i18next';

const useRetireForm = ({ retireUser, clearAction, loadEntity }: any) => {
  const { t } = useTranslation('Users');
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [retiredDate, setRetiredDate] = React.useState(new Date());

  let retiredInterval = '180d';
  let retiredAmount = '180';
  let retiredPeriod = 'd';
  const { portalProductSettings } = useAppInfo();
  if (portalProductSettings) {
    retiredInterval = getSettingValueForVariable(
      portalProductSettings,
      'retiredInterval',
    ) as string;
  }
  if (includes(retiredInterval, 'm')) {
    retiredPeriod = 'm';
  } else if (includes(retiredInterval, 'd')) {
    retiredPeriod = 'd';
  }
  retiredAmount = replace(retiredInterval, retiredPeriod, '');
  const retiredAmountNumber = parseInt(retiredAmount, 10);
  const dateTo =
    retiredPeriod === 'm'
      ? addMonths(retiredDate, retiredAmountNumber)
      : addDays(retiredDate, retiredAmountNumber);

  const submitHandler = async (values: any) => {
    const resp = await userRetire({
      ...values,
      userId: get(retireUser, 'id', ''),
      revert: false,
    });
    if (resp) {
      addSuccessAlert(t('userRetired'));
      clearAction();
      loadEntity();
    } else {
      addErrorAlert(t('errorSavingNoRetired'));
    }
  };

  return { retiredDate, dateTo, submitHandler, setRetiredDate };
};

export default useRetireForm;

import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useReactTour } from 'components/Tour/useReactTour';
import HelperComponent from 'components/Tour/HelperComponent';
import { getDropDownStep } from 'components/Tour/_dropDownStep';

export const TourMdex = ({ activeTab }: any) => {
  const { t } = useTranslation('Reactour');
  const { mdexDicomPatient, mdexFiles } = useAppInfo();
  let mdexStepsDefinition: any;

  const initialStepsFromStation = {
    '[data-tour="mdex-dicom-uppySelector"]': t('importDicom.uppySelector'),
  };

  const extendedStepsFromStation = {
    '[data-tour="mdex-dicom-grid"]': t('importDicom.grid'),
    '[data-tour="import-dicom-expansionArrow"]': t('importDicom.expansionArrow'),
    '[data-tour="import-dicom-deleteRow"]': t('importDicom.deleteRow'),
    '[data-tour="mdex-dicom-deleteAll"]': t('importDicom.deleteAll'),
    '[data-tour="mdex-dicom-uploadFolder"]': t('importDicom.uploadFolder'),
    '[data-tour="mdex-dicom-uploadFiles"]': t('importDicom.uploadFiles'),
  };

  const initialStepsNonDicom = {
    '[data-tour="import-nonDicom-uppySelector"]': t('importNonDicom.uppySelector'),
  };

  const extendedStepsNonDicom = {
    '[data-tour="import-nonDicom-grid"]': t('importNonDicom.grid'),
    '[data-tour="import-nonDicom-deleteRow"]': t('importNonDicom.deleteRow'),
    '[data-tour="import-nonDicom-deleteAll"]': t('importNonDicom.deleteAll'),
    '[data-tour="import-nonDicom-uploadFolder"]': t('importNonDicom.uploadFolder'),
  };

  const [stepsFromStation, setStepsFromStation] = useState<any>(
    mdexDicomPatient?.length ? extendedStepsFromStation : initialStepsFromStation,
  );

  const [stepsNonDicom, setStepsNonDicom] = useState<any>(
    mdexFiles?.length ? extendedStepsNonDicom : initialStepsNonDicom,
  );

  useEffect(() => {
    setStepsFromStation(
      mdexDicomPatient?.length ? extendedStepsFromStation : initialStepsFromStation,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mdexDicomPatient]);

  useEffect(() => {
    setStepsNonDicom(mdexFiles?.length ? extendedStepsNonDicom : initialStepsNonDicom);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mdexFiles]);

  const allStepsFromArchive = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="mdex-send-navBar"]': t('mdex.send.navBar'),
      '[data-tour="search-header-searchForm-searchFormFields"]': t('searchList.searchFormFields'),
      ...(currentOpenDropdown !== 'institutionName' && getDropDownStep({ isDropDownOpen, t })),
      '[data-tour="search-header-searchForm-expansionArrow"]': t('searchList.expansionArrow'),
      '[data-tour="search-header-searchForm-searchSources"]': t('searchList.searchSources'),

      '[data-tour="search-header-searchForm-clearButton"]': t('searchList.clearButton'),
      '[data-tour="search-header-searchForm-searchButton"]': t('searchList.searchButton'),

      '[data-tour="search-header-searchForm-searchResultGrid"]': t('searchList.searchResultGrid'),
      '[data-tour="common-gridReFetching"]': t('common.gridReFetching'),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
      '[data-tour="mdex-send-recipients"]': t('mdex.send.recipients'),
      ...(currentOpenDropdown === 'institutionName' &&
        getDropDownStep({
          isDropDownOpen,
          t,
          selector: 'autocomplete',
          content: 'mdex.send.recipients',
        })),

      '[data-tour="mdex-send-button"]': t('mdex.send.button'),
    };
    return steps;
  };

  const allStepsFromStation = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="mdex-send-navBar"]': t('mdex.send.navBar'),
      ...stepsFromStation,
      '[data-tour="mdex-send-recipients"]': t('mdex.send.recipients'),
      ...(isDropDownOpen && {
        '.autocomplete-react-tour-class': t('mdex.send.recipients'),
      }),
      '[data-tour="mdex-send-button"]': t('mdex.send.button'),
    };
    return steps;
  };

  const allStepsNonDicom = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="mdex-send-navBar"]': t('mdex.send.navBar'),
      ...stepsNonDicom,
      '[data-tour="mdex-send-recipients"]': t('mdex.send.recipients'),
      ...(isDropDownOpen && {
        '.autocomplete-react-tour-class': t('mdex.send.recipients'),
      }),
      '[data-tour="mdex-send-button"]': t('mdex.send.button'),
    };
    return steps;
  };

  if (activeTab === 'dicomFromArchive') mdexStepsDefinition = allStepsFromArchive;
  if (activeTab === 'dicomFromStation') mdexStepsDefinition = allStepsFromStation;
  if (activeTab === 'nondicom') mdexStepsDefinition = allStepsNonDicom;

  useReactTour({ stepsDefinition: mdexStepsDefinition });

  return (
    <>
      <HelperComponent />
    </>
  );
};

import {
  ARCHIVED,
  NOTIFICATIONS_STATE_ARCHVIED,
  NOTIFICATIONS_STATE_NEW,
  NOTIFICATIONS_STATE_READ,
  NOTIFICATION_ROW_COUNT,
  SEARCH_FUNCTION_REORDER,
  SEARCH_FUNCTION_SEND,
  SEARCH_FUNCTION_SPLIT,
  STATE_NEW,
  STUDY_TYPE_PROVIDED,
  STUDY_TYPE_REQUESTED,
} from 'constants/constants';
import { format } from 'date-fns';
import { find, get, isArray, isEmpty, isString, isUndefined, join, last, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { encryptId } from 'utils/hooks/useApp';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { generateIID, joinParams } from 'utils/study';
import {
  getAllNotificationsByStatesWithLimit,
  setNotificationState,
  setNotificationsState,
} from './_api';
import useRefetchGrid from 'utils/grid/useRefetchGrid';
import useAlerts from 'components/Alerts/useAlerts';
import webstomp from 'webstomp-client';
import { IConfirmationDialogNotification, IWebsocketNotification } from './_types';
import Header from 'components/Header/Header';
import { Box, Button, Grid, Tooltip, useTheme } from '@mui/material';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { Announcement } from 'components/Announcement/Announcement';
import { useUser } from 'utils/hooks/useUser';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppLoader } from 'utils/hooks/useAppLoader';
import { IRequestStudy } from 'modules/Requests/_types';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowModel,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import {
  Delete as DeleteIcon,
  Description as GridDetailIcon,
  MarkEmailUnread as MarkUnreadIcon,
  Markunread as MarkReadIcon,
} from '@mui/icons-material/';
import { IFunctionItem } from 'modules/Administration/Folders/_types';
import { getTranslation } from 'utils/translationUtils';
import i18n from 'constants/translations/i18n';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const buttonStyle = { marginRight: '8px', marginBottom: '8px' };
const textLowercase = (text: string) => (isString(text) ? text.toLowerCase() : '');

const formatDateTime = 'dd.MM.yyyy HH:mm';
const muiGridKey = 'websocketNotificationMui';

export const WebsocketNotification = () => {
  //Redux
  const { user } = useUser();
  const { archives: products, compactMode } = useAppInfo();
  const navigate = useNavigate();
  //Custom Hooks
  const { toggleLoader } = useAppGlobals();
  const { refetchGrid } = useRefetchGrid();
  const { addSuccessAlert, addErrorAlert } = useAlerts();
  const { t } = useTranslation('WebsocketNotifications');
  useWithTitle(); // sets title to document
  const { loadNewNotifications } = useAppLoader();
  const theme = useTheme();
  //UseState hooks
  const [confirmationDialog, setConfirmationDialog] = useState<IConfirmationDialogNotification>({
    title: '',
    successMessage: '',
    errorMessage: '',
    isOpen: false,
  });
  const [selection, setSelection] = useState<number[]>([]);
  const [notifications, fetchNotifications] = useState<IWebsocketNotification[]>([]);
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [announcementLabel, setAnnouncementLabel] = useState<string>('');
  const [buttonIsHidden, hideLoadButton] = useState<boolean>(false);

  const linkStyle = {
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.dark,
    fontWeight: 'bold',
  };

  const onEntityAction = async (row: any) => {
    navigate(`${get(row, 'id')}`);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          width: 250,
          renderCell: ({ row }: GridRenderCellParams) => (
            <>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('SearchResults:showDetail')}>
                    <GridDetailIcon />
                  </Tooltip>
                }
                label={t('SearchResults:showDetail')}
                onClick={() => {
                  onEntityAction(row);
                }}
                disabled={!get(row, 'canDelete', true)}
                sx={compactMode ? { p: 0 } : {}}
              />
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  handleArchiveNotification(row.id);
                }}
                sx={compactMode ? { p: 0 } : {}}
              />
            </>
          ),
        },
        { field: 'id', headerName: t('ID') },
        {
          field: 'subject',
          headerName: t('subject'),
          renderCell: ({ row }: GridRenderCellParams) => {
            const requestIdOperationType = get(row, 'requestId.operationType', '');
            const isSend = requestIdOperationType === SEARCH_FUNCTION_SEND;
            const eventTypeLanguageKey = get(row, 'templateId.eventType.languageKey');
            let inArchive = '';
            if (
              eventTypeLanguageKey === 'event.type.update.patient.ok' ||
              eventTypeLanguageKey === 'event.type.update.patient.nok'
            ) {
              inArchive = get(row, 'sourceProductCode', '');
              const product = find(products, ['code', inArchive]);
              if (!isEmpty(product)) {
                inArchive = get(product, 'name', inArchive);
              }
            }
            if (eventTypeLanguageKey === 'event.type.study.request.created') {
              inArchive = requestIdOperationType ? t(textLowercase(requestIdOperationType)) : '';
            }
            if (isSend) {
              const isSejf = get(row, 'requestId.studySendRequestInfo.sejf', false);
              const exchangeNetworkName = get(
                row,
                'requestId.studySendRequestInfo.exchangeNetworkName',
                '',
              );
              const facilityName = get(row, 'requestId.studySendRequestInfo.facilityName', null);
              inArchive = `${t('targetNode')} ${isSejf ? t('drSejf') : exchangeNetworkName}${
                facilityName ? `, ${facilityName}` : ''
              } `;
            }
            return isSend ? (
              <>
                {`${t(eventTypeLanguageKey)} ${t('send')}`}
                <br />
                {inArchive}
              </>
            ) : (
              getTranslation(
                eventTypeLanguageKey,
                i18n.getResourceBundle(i18n.language, 'WebsocketNotifications'),
              ) + inArchive
            );
          },
          valueGetter: (value: any, row: any) => {
            const requestIdOperationType = get(row, 'requestId.operationType', '');
            const isSend = requestIdOperationType === SEARCH_FUNCTION_SEND;
            const eventTypeLanguageKey = get(row, 'templateId.eventType.languageKey');
            let inArchive = '';
            if (
              eventTypeLanguageKey === 'event.type.update.patient.ok' ||
              eventTypeLanguageKey === 'event.type.update.patient.nok'
            ) {
              inArchive = get(row, 'sourceProductCode', '');
              const product = find(products, ['code', inArchive]);
              if (!isEmpty(product)) {
                inArchive = get(product, 'name', inArchive);
              }
            }
            if (eventTypeLanguageKey === 'event.type.study.request.created') {
              inArchive = requestIdOperationType ? t(textLowercase(requestIdOperationType)) : '';
            }
            if (isSend) {
              const isSejf = get(row, 'requestId.studySendRequestInfo.sejf', false);
              const exchangeNetworkName = get(
                row,
                'requestId.studySendRequestInfo.exchangeNetworkName',
                '',
              );
              const facilityName = get(row, 'requestId.studySendRequestInfo.facilityName', null);
              inArchive = `${t('targetNode')} ${isSejf ? t('drSejf') : exchangeNetworkName}${
                facilityName ? `, ${facilityName}` : ''
              } `;
            }
            return isSend
              ? getTranslation(
                  eventTypeLanguageKey,
                  i18n.getResourceBundle(i18n.language, 'WebsocketNotifications'),
                ) +
                  t('send') +
                  inArchive
              : getTranslation(
                  eventTypeLanguageKey,
                  i18n.getResourceBundle(i18n.language, 'WebsocketNotifications'),
                ) + inArchive;
          },
        },
        {
          field: 'study',
          headerName: t('study'),
          renderCell: ({ row }: GridRenderCellParams) => {
            const status = get(row, 'requestId.status', '');
            const isReorder = get(row, 'requestId.operationType', '') === SEARCH_FUNCTION_REORDER;
            const isSplit = get(row, 'requestId.operationType', '') === SEARCH_FUNCTION_SPLIT;
            const studies: IRequestStudy[] = sortBy(
              get(row, 'requestId.studyRequestStudies'),
              'id',
            );
            const showLink = true;
            const isStateNoCompleted =
              status === 'NEW' || status === 'APPROVED' || status === 'REJECTED';

            let study;
            let moreStudies = false;
            if (isReorder) {
              if (isStateNoCompleted) {
                moreStudies = true;
                study = get(studies, `[1].${STUDY_TYPE_REQUESTED}`);
              } else {
                study = get(studies, `[1].${STUDY_TYPE_PROVIDED}`);
              }
            } else if (isSplit) {
              if (isStateNoCompleted) {
                study = get(studies, `[0].${STUDY_TYPE_REQUESTED}`);
              } else {
                study = get(find(studies, ['state', STATE_NEW]), STUDY_TYPE_PROVIDED);
              }
            } else {
              moreStudies = studies.length > 1;
              const lastStudy = last(get(row, 'requestId.studyRequestStudies'));
              study = get(lastStudy, 'provided') || get(lastStudy, 'requested');
            }
            if (moreStudies) {
              const studiesArray = studies.map((item: IRequestStudy, index: number) => {
                const getStudy = get(item, 'provided') || get(item, 'requested');
                return (
                  getStudy && (
                    <li key={`studies_array_${index}`}>
                      <Link
                        to={`/study/${encryptId(generateIID(getStudy))}?from=notifications`}
                        style={linkStyle}
                      >
                        <div>
                          {`${joinParams([
                            get(getStudy, 'patient.name.lastName'),
                            get(getStudy, 'patient.name.firstName'),
                          ])}, ${get(getStudy, 'modalitiesInStudy', []).join(', ')}`}

                          {isString(get(getStudy, 'dateTime'))
                            ? ` (${format(new Date(get(getStudy, 'dateTime')), formatDateTime)})`
                            : null}
                        </div>
                      </Link>
                    </li>
                  )
                );
              });
              return studiesArray ? (
                <Box component="ul" sx={{ listStyle: 'none', p: '0', m: 0 }}>
                  {studiesArray}
                </Box>
              ) : (
                ''
              );
            } else {
              const div = (
                <div>
                  {`${joinParams([
                    get(study, 'patient.name.lastName'),
                    get(study, 'patient.name.firstName'),
                  ])}, ${get(study, 'modalitiesInStudy', []).join(', ')}`}

                  {isString(get(study, 'dateTime'))
                    ? ` (${format(new Date(get(study, 'dateTime', '')), formatDateTime)})`
                    : null}
                </div>
              );
              return isEmpty(study) ? null : showLink ? (
                <Link
                  to={`/study/${encryptId(generateIID(study))}?from=notifications`}
                  style={linkStyle}
                >
                  {div}
                </Link>
              ) : (
                div
              );
            }
          },
          valueGetter: (value: any, row: any) => {
            const status = get(row, 'requestId.status', '');
            const isReorder = get(row, 'requestId.operationType', '') === SEARCH_FUNCTION_REORDER;
            const isSplit = get(row, 'requestId.operationType', '') === SEARCH_FUNCTION_SPLIT;
            const studies: IRequestStudy[] = sortBy(
              get(row, 'requestId.studyRequestStudies'),
              'id',
            );
            const isStateNoCompleted =
              status === 'NEW' || status === 'APPROVED' || status === 'REJECTED';

            let study;
            let moreStudies = false;
            if (isReorder) {
              if (isStateNoCompleted) {
                study = get(studies, `[1].${STUDY_TYPE_REQUESTED}`);
              } else {
                study = get(studies, `[1].${STUDY_TYPE_PROVIDED}`);
              }
            } else if (isSplit) {
              if (isStateNoCompleted) {
                study = get(studies, `[0].${STUDY_TYPE_REQUESTED}`);
              } else {
                study = get(find(studies, ['state', STATE_NEW]), STUDY_TYPE_PROVIDED);
              }
            } else {
              moreStudies = studies.length > 1;
              const lastStudy = last(get(row, 'requestId.studyRequestStudies'));
              study = get(lastStudy, 'provided') || get(lastStudy, 'requested');
            }
            if (moreStudies) {
              const studiesArray = studies.map((item: IRequestStudy, index: number) => {
                const getStudy = get(item, 'provided') || get(item, 'requested');
                return (
                  getStudy &&
                  joinParams([
                    get(getStudy, 'patient.name.lastName'),
                    get(getStudy, 'patient.name.firstName'),
                  ]) + get(getStudy, 'modalitiesInStudy', []).join(', ')
                );
              });
              return studiesArray ? join(studiesArray, ', ') : null;
            } else {
              const div =
                joinParams([
                  get(study, 'patient.name.lastName'),
                  get(study, 'patient.name.firstName'),
                ]) + get(study, 'modalitiesInStudy', []).join(', ');
              return isEmpty(study) ? null : div;
            }
          },
        },
        {
          field: 'state',
          headerName: t('state'),
          renderCell: ({ row }: GridRenderCellParams) => {
            const value = get(row, 'state', '').toLowerCase();
            return (
              <div>
                {value && value === NOTIFICATIONS_STATE_NEW.toLowerCase() ? (
                  <div
                    style={{
                      width: theme.spacing(1),
                      height: theme.spacing(1),
                      background: theme.palette.primary.main,
                      borderRadius: theme.spacing(0.5),
                      display: 'inline-block',
                      marginRight: theme.spacing(1),
                    }}
                  />
                ) : null}
                {t(value)}
              </div>
            );
          },
        },
        {
          field: 'status',
          headerName: t('status'),
          valueGetter: (value: any, row: any) => t(get(row, 'requestId.status').toLowerCase()),
        },
        {
          field: 'date',
          headerName: t('date'),
          type: 'dateTime',
          renderCell: ({ row }: GridRenderCellParams) =>
            format(new Date(get(row, 'requestId.modifiedWhen', '')), 'dd.MM.yyyy HH:mm:ss'),
          valueGetter: (value: any, row: any) =>
            get(row, 'requestId.modifiedWhen') && new Date(get(row, 'requestId.modifiedWhen')),
        },
      ],
      200,
    ),
  );

  const handleClickOpen = useCallback(
    async (action: string) => {
      const text =
        `${action.toLowerCase()}` + (selection.length > 1 ? 'SetNotifications' : 'SetNotification');
      setSelectedAction(action);
      setConfirmationDialog((prevState: IConfirmationDialogNotification) => ({
        ...prevState,
        title: `${action.toLowerCase()}ConfirmSet`,
        successMessage: `${text}Successfully`,
        errorMessage: `${text}Error`,
        isOpen: true,
      }));
    },
    [selection.length],
  );

  const functionList: IFunctionItem[] = useMemo(
    () => [
      {
        key: 'multipleSetAsNew',
        label: t('multipleSetAsNew'),
        show: true,
        onClick: (row: GridRowModel | null) => {
          handleClickOpen(NOTIFICATIONS_STATE_NEW);
        },
        icon: <MarkUnreadIcon fontSize="small" />,
      },
      {
        key: 'multipleSetAsRead',
        label: t('multipleSetAsRead'),
        show: true,
        onClick: (row: GridRowModel | null) => {
          handleClickOpen(NOTIFICATIONS_STATE_READ);
        },
        icon: <MarkReadIcon fontSize="small" />,
      },
      {
        key: 'multipleSetAsArchived',
        label: t('multipleSetAsArchived'),
        show: true,
        onClick: (row: GridRowModel | null) => {
          handleClickOpen(NOTIFICATIONS_STATE_ARCHVIED);
        },
        icon: <DeleteIcon fontSize="small" />,
      },
    ],
    [t, handleClickOpen],
  );

  const QuickSearchToolbar = useCallback(
    (props: any) => {
      return (
        <div>
          <Box style={{ padding: '5px', display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
            </Box>
          </Box>
          <Box
            sx={{
              px: 1,
              py: 0.5,
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {functionList.map((item: IFunctionItem, index: number) => {
              const showCount = isUndefined(item.showCount) ? true : item.showCount;
              const count = selection.length;
              const xcount = showCount && count ? ` (${count})` : '';
              const maxAllowedItem = isUndefined(item.maxAllowedItem) ? 0 : item.maxAllowedItem;
              return (
                <Tooltip key={index} title={item.label + xcount}>
                  <span>
                    <Button
                      key={item.key}
                      size={'medium'}
                      variant="contained"
                      color="primary"
                      onClick={() => item.onClick(null)}
                      disabled={
                        selection.length === 0 ||
                        (maxAllowedItem > 0 && maxAllowedItem < selection.length)
                      }
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {item.icon}
                    </Button>
                  </span>
                </Tooltip>
              );
            })}
          </Box>
        </div>
      );
    },
    [selection, functionList],
  );

  const handleLoadNextNotification = async () => {
    toggleLoader();
    await getAllNotificationsByStatesWithLimit(
      [NOTIFICATIONS_STATE_NEW, NOTIFICATIONS_STATE_READ],
      notifications.length / NOTIFICATION_ROW_COUNT,
    ).then(
      (response) => {
        if (isArray(response)) {
          const loaded = [...notifications, ...response];
          setAnnouncementLabel(
            t('resultsNum', {
              size: loaded.length,
            }),
          );
          if (isEmpty(response) || response.length < NOTIFICATION_ROW_COUNT) {
            hideLoadButton(true);
          }
          fetchNotifications(loaded);
        } else {
          setAnnouncementLabel('');
          fetchNotifications([]);
        }
        refetchGrid();
      },
      (e) => console.debug(e),
    );
    toggleLoader(false);
  };

  const handleLoadNotificationToGrid = async () => {
    toggleLoader();
    await getAllNotificationsByStatesWithLimit([
      NOTIFICATIONS_STATE_NEW,
      NOTIFICATIONS_STATE_READ,
    ]).then(
      (response) => {
        if (isArray(response)) {
          setAnnouncementLabel(
            t('resultsNum', {
              size: response.length,
            }),
          );
          if (isEmpty(response) || response.length < NOTIFICATION_ROW_COUNT) {
            hideLoadButton(true);
          }
          fetchNotifications(response);
        } else {
          setAnnouncementLabel('');
          fetchNotifications([]);
        }
        refetchGrid();
      },
      (e) => console.debug(e),
    );
    toggleLoader(false);
  };

  const handleSetSelectedNotificationsByAction = async (action: string) => {
    toggleLoader();
    setConfirmationDialog((prevState: IConfirmationDialogNotification) => ({
      ...prevState,
      isOpen: false,
    }));
    const data = { ids: selection, state: action };
    try {
      const resp = await setNotificationsState(data);
      toggleLoader(false);
      return resp;
    } catch (error) {
      console.error('Failed to set notifications state', error);
      toggleLoader(false);
      return false;
    }
  };

  const handleCallbackGetNotifications = async () => {
    toggleLoader();
    setSelection([]);
    fetchNotifications([]);
    await Promise.all([handleLoadNotificationToGrid(), loadNewNotifications()]);
    toggleLoader(false);
  };

  const handleArchiveNotification = async (id: number) => {
    toggleLoader();
    await setNotificationState(id, ARCHIVED).then(
      async (r) => {
        addSuccessAlert(t('archivedSuccessfully'));
        await Promise.all([handleLoadNotificationToGrid(), loadNewNotifications()]);
      },
      (e) => addErrorAlert(t('errorArchiving')),
    );
    toggleLoader(false);
  };

  const handleLoadEntity = async () => {
    await Promise.all([handleLoadNotificationToGrid(), loadNewNotifications()]);
  };

  const handleConfirmAction = () => {
    return handleSetSelectedNotificationsByAction(selectedAction);
  };

  const handleCancelAction = () => {
    setConfirmationDialog((prevState: IConfirmationDialogNotification) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  useEffect(() => {
    const initialize = async () => {
      handleLoadEntity();
      // await loadNewNotifications();
      const surname = get(user, 'sub');
      if (surname) {
        const wsUrl = `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}${
          window.location.host
        }/portal-api/notifySocket`;
        const socket = new WebSocket(wsUrl);
        const ws = webstomp.over(socket, { debug: false });
        ws.connect({}, () => {
          (window as any).subscribedNotifications = ws.subscribe(
            `/user/${surname}/queue/notify`,
            async (message) => {
              try {
                await handleLoadEntity();
                await handleLoadNotificationToGrid();
              } catch (e) {
                console.debug(e);
              }
            },
          );
        });
      }
    };
    initialize();

    return () => {
      try {
        if (get(window, 'subscribedNotifications')) {
          (window as any).subscribedNotifications.unsubscribe();
        }
      } catch (e) {
        console.debug(e);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header title={t('Menu:notifications')} />
      <>
        {!buttonIsHidden && (
          <Grid container={true} spacing={16} style={{ marginBottom: '8px' }}>
            <Grid item={true} xs={12} key={'announcement'}>
              <Announcement
                label={announcementLabel}
                type={'info'}
                spaced={true}
                component={'div'}
              />
            </Grid>
          </Grid>
        )}
        <MuiGrid
          gridKey={muiGridKey}
          rows={notifications}
          columns={columns}
          //rowHeight={true}
          rowSelecting={{
            selecting: true,
            selection,
            setSelection,
          }}
          toolbar={QuickSearchToolbar}
          initialSortMode={[{ field: 'id', sort: 'desc' }]}
        />
        {!buttonIsHidden && (
          <Button
            style={{ ...buttonStyle, marginTop: '8px' }}
            onClick={handleLoadNextNotification}
            variant="contained"
            size="large"
            color="primary"
          >
            {t('loadNext')}
          </Button>
        )}
      </>
      <ConfirmationDialog
        title={t(confirmationDialog.title)}
        open={confirmationDialog.isOpen}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        cancelAction={handleCancelAction}
        confirmAction={handleConfirmAction}
        confirmCallback={handleCallbackGetNotifications}
        successMessage={t(confirmationDialog.successMessage)}
        errorMessage={t(confirmationDialog.errorMessage)}
      />
    </>
  );
};

import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';
import { isEmpty } from 'lodash';

const useValidationSchema = (t: TFunction, validatorFields: any) => {
  const { checkItemsOnRequiredMinAllowedChars } = useValidators();

  const PatientManagementFormSchema = yup.object().shape({
    ...(!isEmpty(validatorFields)
      ? { ...checkItemsOnRequiredMinAllowedChars(yup.string(), validatorFields) }
      : {}),
  });

  return { PatientManagementFormSchema };
};

export default useValidationSchema;

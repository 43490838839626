import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Select } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getOperations, getPriorities, setOperationPriority } from './_api';
import { compact, get, isArray } from 'lodash';
import useAlerts from 'components/Alerts/useAlerts';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'operationsMUI';

const Operations: React.FC = () => {
  const { t } = useTranslation('PrioritiesAndOperations');
  useWithTitle();
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();
  const { hasRole } = useUser();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [allOperations, setAllOperations] = useState<any[]>([]);

  const loadEntity = async () => {
    toggleLoader();
    try {
      const allOperations = await getOperations();
      const allPriorities = await getPriorities();
      let priorities: any[] = [];
      if (isArray(allPriorities)) {
        priorities = allPriorities.map((priority) => {
          return { value: priority.id, label: priority.name };
        });
      }

      if (isArray(allOperations)) {
        const operations = allOperations.map((operation) => ({
          ...operation,
          priorities,
        }));
        setAllOperations(operations);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEditor = hasRole('ROLE_OPERATION_EDITOR');

  const onChangeSelect = async (row: any, priorityId: number) => {
    toggleLoader();
    await setOperationPriority(row.id, priorityId).then(
      (response) => {
        addSuccessAlert(t('updateOperationPriority'));
        loadEntity();
      },
      (error) => {
        addErrorAlert(t('errorUpdateOperationPriority'));
      },
    );
    toggleLoader(false);
  };

  const clearAction = () => dispatch(storeConfirmationDataToStore(null));

  const confirmAction = () => {
    onChangeSelect(get(confirmationData, 'id'), get(confirmationData, 'permission'));
    clearAction();
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      compact([
        isEditor
          ? {
              field: 'actions',
              headerName: t('Grid:actions'),
              type: 'actions',
              hideable: false,
              width: 160,
              align: 'right',
              renderCell: ({ row }: GridRenderCellParams) => (
                <FormControl variant="standard">
                  <Select
                    value={row.priority.id}
                    onChange={(event: any) => {
                      dispatch(
                        storeConfirmationDataToStore({
                          title: 'Grid:confirmChangePriority',
                          id: row,
                          permission: event.target.value,
                          confirmAction: 'confirmActionChangeSelect',
                        }),
                      );
                    }}
                    inputProps={{
                      name: 'permission',
                      id: 'permission-simple',
                    }}
                    style={{ fontSize: 'small' }}
                  >
                    {get(row, 'priorities', []).map((item: any) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ),
            }
          : null,
        { field: 'operation', headerName: t('operation') },
        !isEditor
          ? {
              field: 'priority',
              headerName: t('priority'),
              valueGetter: (value: any, row: any) => get(row, 'priority.name'),
            }
          : null,
      ]),
    ),
  );

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={allOperations}
        columns={columns}
        initialSortMode={[{ field: 'operation', sort: 'asc' }]}
      />
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

export default Operations;

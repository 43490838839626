import { useTranslation } from 'react-i18next';
import { useReactTour } from 'components/Tour/useReactTour';
import HelperComponent from 'components/Tour/HelperComponent';

interface IUseStepsProps {
  hasComments?: boolean;
  type?: 'main' | 'detail';
}

export const TourFindingReports = ({ hasComments, type }: IUseStepsProps) => {
  const { t } = useTranslation('Reactour');

  const allSteps = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="search-header-searchForm-searchFormFields"]': t('searchList.searchFormFields'),
      ...(isDropDownOpen &&
        currentOpenDropdown === 'user' && {
          '.autocomplete-react-tour-class': (
            <>
              {t('findingReport.user')}
              <br />
              <em>{t('common.quitSelectItems')}</em>
            </>
          ),
        }),
      ...(isDropDownOpen &&
        currentOpenDropdown === 'hospital' && {
          '.autocomplete-react-tour-class': (
            <>
              {t('findingReport.hospital')}
              <br />
              <em>{t('common.quitSelectItems')}</em>
            </>
          ),
        }),
      ...(isDropDownOpen && {
        '.select-react-tour-class': (
          <>
            {t('common.selectItems')}
            <br />
            <em>{t('common.quitSelectItems')}</em>
          </>
        ),
      }),
      '[data-tour="finding-report-search"]': t('common.searchButtonTours'),
      '[data-tour="finding-report-grid"]': t('findingReport.grid'),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
      '[data-tour="common-gridExport"]': t('common.gridExport'),
    };
    return steps;
  };

  const stepsDefinition = allSteps;
  useReactTour({ stepsDefinition });
  return <HelperComponent />;
};

// import { IAnnouncement } from 'components/Announcement/_types';
import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IAnnouncement } from './_types';

export const setActiveAnnouncement = (id: string, state: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/announcement/setActive`, { params: { id, state } })
    .then((response) => true)
    .catch((error) => false);

export const getUserSetting = (username: string) =>
  api()
    .get(`/portal-api/userSetting/get`, { params: { username } })
    .then((response) => get(response, 'data'))
    .catch(() => false);

// export const getAnnouncement = (id: string): Partial<Promise<IAnnouncement> | boolean> =>
export const getAnnouncement = (id: string): Promise<IAnnouncement> =>
  api()
    .get(`/portal-api/announcement/get`, { params: { id } })
    .then((response) => {
      let data = get(response, 'data');
      const languageJsonText = get(data, 'languageJsonText', null);
      if (languageJsonText) {
        data = { ...data, languageJsonText: JSON.parse(languageJsonText) };
      }
      return data;
    })
    .catch((error) => false);

export const editAnnouncement = (announcement: IAnnouncement): Promise<boolean> =>
  api()
    .post(`/portal-api/announcement/edit`, announcement)
    .then((response) => true)
    .catch((error) => false);

export const createAnnouncement = (announcement: IAnnouncement): Promise<boolean> =>
  api()
    .post(`/portal-api/announcement/create`, announcement)
    .then((response) => true)
    .catch((error) => false);

export const getAllAnnouncement = (): Promise<IAnnouncement[]> =>
  api()
    .get(`/portal-api/announcement/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

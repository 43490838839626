import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { filter, get, isArray, isEmpty, isFunction, omit } from 'lodash';
import { Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { getFormItem, updateFormItem } from './_api';
import { IFormItemForm } from './_types';
import useValidationSchema from './_form';
import { ILanguage } from '../GeneralSettings/_types';
import { getAllLanguages } from '../GeneralSettings/_apiLanguages';

import { Papeer } from 'components/Papeer/Papeer';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import FormInput from 'components/Form/Input/Input';

import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';

import { storeConfirmationDataToStore } from 'store/reducers/appReducer';

export const FormItemForm: React.FC<any> = ({
  formItemId,
  toggleDialog,
  loadEntity,
  dispatch,
  formItemsLength,
}) => {
  const { t } = useTranslation('ClinicalPortalSettings');
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { id } = useEntityInfo();
  const [entity, fetchEntity] = useState<IFormItemForm>();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [languages, setLanguages] = useState<any[]>([]);

  const { title } = useWithEntityTitle(isCreating, entity, t, 'createFormItem', 'editFormItem');
  const { FormItemFormSchema } = useValidationSchema(t, languages, formItemsLength);

  const methods = useForm<any>({
    resolver: yupResolver(FormItemFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const { toggleLoader } = useAppGlobals();

  const clearAction = () => {
    toggleDialog(false);
    if (isFunction(dispatch)) {
      dispatch(storeConfirmationDataToStore(null));
    }
  };

  const prepareValues = (values: any) => {
    let nameTranslate = {};
    const aKey: any[] = [];
    (languages || []).forEach((lang: any) => {
      const abbr = get(lang, 'abbreviation');
      const key = `nameTranslate_${abbr}`;
      const value = get(values, key, null);
      nameTranslate = { ...nameTranslate, [abbr]: value };
      aKey.push(key);
    });
    const preparedValues: any = {
      ...omit(values, aKey),
      nameTranslate: JSON.stringify(nameTranslate),
      active: get(entity, 'active', true),
      basic: get(entity, 'basic', true),
    };
    return preparedValues;
  };
  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = updateFormItem;
    const response = await fn(prepareValues(values));
    if (response) {
      addSuccessAlert(t('savedFormItem'));
      clearAction();
      loadEntity();
    } else {
      addErrorAlert(t('errorSavingFormItem'));
    }
    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      let languages = null;
      const languagesLists: ILanguage[] = await getAllLanguages();
      if (isArray(languagesLists)) {
        languages = filter(languagesLists, { active: true });
        setLanguages(languages);
      }

      let formItem: IFormItemForm = {
        id: 0,
        name: '',
        position: 0,
        active: true,
        basic: true,
      };
      const entityId = formItemId ? formItemId : id;
      const isCreating = entityId === 'create';
      setIsCreating(isCreating);

      if (!isCreating && entityId) {
        const entity: any = await getFormItem(entityId);
        if (entity) {
          let nameTranslate = {};
          (languages || []).forEach((lang: any) => {
            const key = `nameTranslate_${get(lang, 'abbreviation')}`;
            const value = get(entity, `nameTranslate.${lang.abbreviation}`, '');
            nameTranslate = { ...nameTranslate, [key]: value };
          });
          formItem = { ...entity, ...nameTranslate };
          fetchEntity(entity);
        }
      }
      reset({ ...formItem });
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isEmpty(entity) || isCreating ? (
        <Typography component="div">
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <Grid container={true} alignItems="flex-end" spacing={2}>
                  <Grid item={true} xs={12} md={6}>
                    <FormInput name="name" label={t(`formItemName`)} required={true} />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <FormInput
                      name="position"
                      label={t(`formItemPosition`)}
                      type="number"
                      required={true}
                    />
                  </Grid>
                  <Grid item={true} xs={12} style={{ padding: 0 }} />
                  {(languages || []).map((lang: any) => (
                    <Grid item={true} xs={12} key={lang.id}>
                      <FormInput
                        name={`nameTranslate_${lang.abbreviation}`}
                        label={`${t('name')} - ${get(lang, 'name', '')}`}
                        required={get(lang, 'default', false)}
                      />
                    </Grid>
                  ))}
                  <Grid item={true} xs={12}>
                    <EntityButtons toggleDialog={clearAction} />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
        </Typography>
      ) : (
        <Typography>{t('formItemNotFound')}</Typography>
      )}
    </>
  );
};

import { isString } from 'lodash';
import React, { useEffect, useState } from 'react';

import { api } from 'utils/hooks/useApi';
import { useStorage } from 'utils/hooks/useStorage';
import { COOKIE_PORTAL_VERSION_NAME } from 'constants/constants';
import { stateIsSame } from 'utils/componentOptimizatons';
import { Box } from '@mui/system';

const PlainAppVersionCheck: React.FC<any> = () => {
  const { getCookie, setCookie } = useStorage();
  const versionStoredInCookie = getCookie(COOKIE_PORTAL_VERSION_NAME);
  const [showNewerVersionPanel, setShowNewerVersionPanel] = useState<boolean | string>(false);

  useEffect(() => {
    api()
      .get(`/version.json?date=${new Date().getTime()}`)
      .then(({ data: { version: publicFolderVersion } }) => {
        if (isString(publicFolderVersion)) {
          setCookie(COOKIE_PORTAL_VERSION_NAME, publicFolderVersion, 30);
        }
        if (publicFolderVersion && versionStoredInCookie !== publicFolderVersion) {
          setShowNewerVersionPanel(publicFolderVersion);
        }
      })
      .catch((e) => console.debug(e));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showNewerVersionPanel ? (
    <Box
      sx={{
        displayPrint: 'none',
        bgcolor: 'warning.main',
        p: 1,
        textAlign: 'center',
        color: 'white',
      }}
    >
      Byla zjištěna nová verze portálu: ({showNewerVersionPanel}).{' '}
      <button onClick={() => window.location.reload()}>Restartovat portál</button>
    </Box>
  ) : null;
};

export const AppVersionCheck = React.memo(PlainAppVersionCheck, stateIsSame);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhysicianForm from 'modules/Administration/Dictionaries/PhysicianForm';

const PhysicianDialog: React.FC<any> = ({
  physicianDialogState,
  setPhysicianDialogState,
  typePhysician,
  setModality,
}) => {
  const { t } = useTranslation('Dictionaries');

  return <>
    <Dialog
      open={physicianDialogState && typePhysician}
      onClose={() => {
        setPhysicianDialogState(false);
      }}
      aria-labelledby="folder-dialog-title"
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
    >
      <DialogTitle id="folder-dialog-title">
        <Grid container={true} direction="row" justifyContent="space-between" alignItems="center">
          {t('create')}
          <IconButton
            aria-label="close"
            onClick={() => {
              setPhysicianDialogState(false);
            }}
            size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <PhysicianForm typePhysician={typePhysician} setModality={setModality} />
      </DialogContent>
    </Dialog>
  </>;
};

export default PhysicianDialog;

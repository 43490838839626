import React, { useEffect, useState } from 'react';
import { compact, get, isArray, isEmpty, join, orderBy, trim } from 'lodash';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getSuccessfullyOperations } from './_api';
import { IStudyDetailOperations } from './_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { generateIID, joinParams } from 'utils/study';
import { encryptId } from 'utils/hooks/useApp';
import { settingHasValue } from 'utils/products';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { stateIsSame } from 'utils/componentOptimizatons';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { shortener } from 'utils/grid/GridFormatters';
import { paletteColorDark } from 'utils/variables';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const getRowId = (row: any) => row.requestId;
const muiGridKey = 'logSearchResultsMUI';

const PlainStudyDetailOperations: React.FC<IStudyDetailOperations> = ({ study }) => {
  const { t } = useTranslation('Studies');
  const { toggleLoader } = useAppGlobals();
  const [operations, setOperations] = useState<any[]>([]);

  const { portalProductSettings } = useAppInfo();

  const commentaryInOperation = settingHasValue(
    portalProductSettings,
    'commentaryInOperation',
    'true',
  );

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const loadEntity = async () => {
    toggleLoader();
    const operations = await getSuccessfullyOperations(
      get(study, 'archive.id') as unknown as string,
      get(study, 'studyInstanceUid'),
    );
    if (operations) {
      const iid = generateIID(study);
      setOperations(
        operations.map((item: any) => ({
          ...item,
          operation: t(item.operationType.toLowerCase()),
          hashIID: encryptId(iid),
        })),
      );
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      compact([
        {
          field: 'dateTime',
          headerName: t('dateTime'),
          width: 180,
          type: 'dateTime',
          renderCell: ({ row }: GridRenderCellParams) =>
            format(new Date(get(row, 'dateTime', '')), 'dd.MM.yyyy HH:mm:ss'),
          valueGetter: (value: any) => value && new Date(value),
        },
        {
          field: 'operation',
          headerName: t('operation'),
          width: 250,
          hideable: false,
          renderCell: ({ row }: GridRenderCellParams) => (
            <Link
              to={`/notifications/requestId=${get(row, 'requestId', null)}${
                get(row, 'hashIID') ? `=${get(row, 'hashIID')}` : ``
              }`}
              style={{
                textDecoration: 'none',
                cursor: 'pointer',
                color: paletteColorDark,
                fontWeight: 'bold',
              }}
            >
              {get(row, 'operation', '?')}
            </Link>
          ),
          valueGetter: (value: any, row: any) => get(row, 'operation'),
        },
        {
          field: 'user',
          headerName: t('user'),
          width: 180,
          valueGetter: (value: any, row: any) =>
            `${joinParams([get(row, 'user.lastName'), get(row, 'user.firstName')])}`,
        },
        commentaryInOperation
          ? {
              field: 'comments',
              headerName: t('comment'),
              width: 500,
              renderCell: ({ row }: GridRenderCellParams) => {
                const comments = get(row, 'comments', []);
                let liComments: any[] = [];
                if (isArray(comments) && comments.length) {
                  const sortedComments = orderBy(comments, ['createdWhen'], ['desc']);
                  liComments = sortedComments.map((comment) => (
                    <li key={comment.id}>
                      {shortener(comment.text)} ({get(comment, 'createdBy.lastName', '') || ''}
                      {get(comment, 'createdBy.firstName') ? ' ' : null}
                      {get(comment, 'createdBy.firstName', '') || ''})
                    </li>
                  ));
                }
                return !isEmpty(liComments) ? (
                  <Box component="ul" sx={{ listStyle: 'none', p: '0' }}>
                    {liComments}
                  </Box>
                ) : (
                  ''
                );
              },
              valueGetter: (value: any, row: any) => {
                const comments = get(row, 'comments', []);
                let aComments: any[] = [];
                if (isArray(comments) && comments.length) {
                  const sortedComments = orderBy(comments, ['createdWhen'], ['desc']);
                  aComments = sortedComments.map((comment) => get(comment, 'text', ''));
                }
                return trim(join(aComments, ' '));
              },
            }
          : null,
      ]),
    ),
  );

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={operations}
        columns={columns}
        getRowId={getRowId}
        //rowHeight={true}
        initialSortMode={[{ field: 'dateTime', sort: 'desc' }]}
      />
    </>
  );
};

export const StudyDetailOperations = React.memo(PlainStudyDetailOperations, stateIsSame);

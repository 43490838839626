import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { AppBar, Box, Tab, Tabs } from '@mui/material';
import { IField } from './_types';
import { useUser } from 'utils/hooks/useUser';
import Header from 'components/Header/Header';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { DicomForm } from './DicomForm';
import { TourCstore } from './TourCstore';
import TabCSTORE from './TabCSTORE';

export const Cstore: React.FC = () => {
  const { t } = useTranslation('Cstore');
  const { hasRole } = useUser();
  const { title } = useWithTitle();

  useWithTitle(); // sets title to document

  let [searchParams] = useSearchParams();
  const tabFromUrl = searchParams.get('tab') || '';
  const tab = tabFromUrl
    ? isArray(tabFromUrl)
      ? tabFromUrl[0]
      : tabFromUrl
    : hasRole('ROLE_CSTORE_TO_EXCHANGE_NETWORK')
    ? 'send'
    : hasRole('ROLE_CSTORE_TO_ARCHIVE')
    ? 'save'
    : '';

  const [activeTab, setActiveTab] = useState<string>(tab);
  const [tabs, setTabs] = useState<IField[]>([]);

  const renderedSteps = useMemo(() => {
    return <TourCstore activeTab={activeTab} />;
  }, [activeTab]);

  useEffect(() => {
    const tabs = [];
    if (hasRole('ROLE_CSTORE_TO_EXCHANGE_NETWORK')) {
      tabs.push({ value: 'send', label: 'cstoreToExchangeNetwork' });
    }
    if (hasRole('ROLE_CSTORE_TO_ARCHIVE')) {
      tabs.push({ value: 'save', label: 'cstoreToArchive' });
    }
    setActiveTab(tabs[0].value);
    setTabs(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRole]);

  const CustomAppBar = () => (
    <>
      <AppBar position="static" color="default" sx={{ mb: 2 }} data-tour="cstore-navBar">
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons={false}
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={t(tab.label)} />
          ))}
        </Tabs>
      </AppBar>
    </>
  );

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {activeTab === 'send' && (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
            }}
          >
            <Header title={title} TourComponent={renderedSteps} />
            <CustomAppBar />
            <DicomForm />
          </Box>
        )}

        {activeTab === 'save' && (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
            }}
          >
            <Header title={title} TourComponent={renderedSteps} />
            <CustomAppBar />
            <TabCSTORE />
          </Box>
        )}
      </Box>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { Column } from '@devexpress/dx-react-grid';
import { Tooltip } from '@mui/material';
import { IGridCols, IGridDicomAttributes } from './_types';

const GridDicomAttributes: React.FC<IGridDicomAttributes> = ({ results, rowDetailHeaders }) => {
  const [columns, setColumns] = useState<Column[]>([]);

  useEffect(() => {
    const cols: IGridCols[] = [];
    rowDetailHeaders.forEach((item) => {
      const key = get(item, 'key');
      cols.push({
        name: key,
        title: get(item, 'label'),
        getCellValue: (row) => {
          const value = get(row, key, '');
          return key === '10' ? (
            <Tooltip title={value} sx={{ maxWidth: 'none' }}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            value
          );
        },
      });
    });
    setColumns(cols);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowDetailHeaders]);

  return (
    <div className="grid--doses">
      <Grid rows={results} columns={columns}>
        <Table />
        <TableHeaderRow cellComponent={(props) => <TableHeaderRow.Cell {...props} />} />
      </Grid>
    </div>
  );
};

export default GridDicomAttributes;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Collapse, Grid, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import useValidationSchema from './_formComment';
import { createComment, editComment } from './_api';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';

import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import { Box } from '@mui/system';

const OrderDetailCommentForm: React.FC<any> = ({
  order,
  handleDialog,
  commentItem,
  getComments,
  user,
}) => {
  const { t } = useTranslation('Comment');
  const { toggleLoader } = useAppGlobals();
  const [formError, setFormError] = useState<any>(null);

  const isCreating = !get(commentItem, 'id', false);

  const { CommentFormSchema } = useValidationSchema(isCreating);
  const methods = useForm<any>({
    resolver: yupResolver(CommentFormSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    if (isCreating) {
      await createComment(values)
        .then((resp) => (resp ? handleDialog('comments', false) : setFormError(t('errorSaving'))))
        .catch((e) => setFormError(t('errorSaving')));
    } else {
      await editComment(values).then(
        () => handleDialog('comments', false),
        () => setFormError(t('errorSaving')),
      );
    }
    await getComments();
    toggleLoader(false);
  });

  const getEntities = async () => {
    toggleLoader();
    try {
      const defaultValues = isCreating
        ? {
            comment: {
              createdBy: {
                username: get(user, 'sub'),
              },
            },
            patientId: order.patient.identificationNumber,
            accessionNumber: order.accessionNumber,
          }
        : {
            id: commentItem.id,
            text: commentItem.text,
          };
      reset({ ...defaultValues });
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {commentItem || isCreating ? (
        <Papeer>
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              {isCreating ? (
                <>
                  <input {...register('accessionNumber')} type="hidden" />
                  <input {...register('comment.createdBy.username')} type="hidden" />
                  <input {...register('patientId')} type="hidden" />
                </>
              ) : (
                <>
                  <input {...register('id')} type="hidden" />
                </>
              )}
              <Grid container={true} alignItems="flex-end">
                <Grid item={true} xs={12} md={12} lg={12} xl={12}>
                  <FormInput
                    name={isCreating ? 'comment.text' : 'text'}
                    label={t('textMWL')}
                    required={true}
                    multiline={true}
                    rows={21}
                  />
                </Grid>
                <Grid item={true} xs={12} md={12} lg={12} xl={12}>
                  <Collapse in={errors ? true : false} timeout="auto">
                    {errors && (
                      <Typography align="center" color="error">
                        {formError}
                      </Typography>
                    )}
                  </Collapse>
                </Grid>
                <Grid item={true} xs={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      displayPrint: 'none',
                    }}
                  >
                    <Button
                      onClick={() => handleDialog('comments', false)}
                      variant="contained"
                      size="large"
                      sx={{ mr: 1 }}
                    >
                      {t('close')}
                    </Button>
                    <Button variant="contained" color="primary" type="submit" size="large">
                      <SaveIcon sx={{ mr: 1 }} />
                      {t('confirm')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Papeer>
      ) : (
        <Papeer>
          <Typography>{t('commentNotFound')}</Typography>
        </Papeer>
      )}
    </>
  );
};

export default OrderDetailCommentForm;

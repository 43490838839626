import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isArray, isEmpty, sortBy } from 'lodash';
import { Box, FormControlLabel, Icon, Switch, Tooltip } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getAll, removeButton, setActiveButton } from './_api';
import ButtonsGrid from './ButtonsGrid';
import { IWorkplace } from '../Workplaces/_types';
import Header from 'components/Header/Header';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { SELECTED_BTN_SPLIT_BY } from 'constants/constants';
import Button from 'components/Buttons/Button';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';
import { useLanguages } from 'utils/hooks/useLanguages';

const muiGridKey = 'buttonsMUI';

export const Buttons: React.FC = () => {
  const { t } = useTranslation('Buttons');
  const dispatch = useAppDispatch();

  const [dialogState, setDialogState] = useState<boolean>(false);
  const [dialogStateSplitBy, setDialogStateSplitBy] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [columnName, setColumnName] = useState<string>('urls');
  useWithTitle();
  const { toggleLoader } = useAppGlobals();

  const { currentLocale } = useLanguage();
  const { languages } = useLanguages();
  const { compactMode, confirmationData } = useAppInfo();
  const navigate = useNavigate();
  const [buttons, setButtons] = useState<any[]>([]);

  const loadEntity = async () => {
    toggleLoader();
    try {
      const buttons = await getAll();
      if (buttons && isArray(buttons)) {
        setButtons(buttons);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCustomEntityDetail = (row: any) => {
    navigate(`/administration/announcementsAndButtons/button/${get(row, 'id')}`);
  };

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const { onEntityRemove } = useEntityRemove(removeButton, t, loadEntity);

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  useEffect(() => {
    if (languages?.length > 0) {
      const columns = reorderColumnsByGridSettings(
        injectColumnWidthsIntoColumns([
          {
            field: 'actions',
            headerName: t('Grid:actions'),
            type: 'actions',
            hideable: false,
            width: 220,
            renderCell: ({ row }: GridRenderCellParams) => {
              return (
                <>
                  <Box sx={{ width: '200px' }}>
                    <FormControlLabel
                      sx={{ maxHeight: 30 }}
                      control={
                        <Switch
                          checked={row.active}
                          onChange={(e, state) => {
                            dispatch(
                              storeConfirmationDataToStore({
                                title: 'Grid:confirmChangeState',
                                id: get(row, 'id'),
                                state,
                                confirmAction: 'confirmActionChangeActive',
                              }),
                            );
                          }}
                        />
                      }
                      label={(row.active ? t('Grid:active') : t('Grid:inactive')) as string}
                    />
                  </Box>
                  <GridActionsCellItem
                    icon={
                      <Tooltip title={t('Grid:edit')}>
                        <EditIcon />
                      </Tooltip>
                    }
                    label={t('Grid:edit')}
                    onClick={() => onCustomEntityDetail(row)}
                  />
                  <GridActionsCellItem
                    icon={
                      <Tooltip title={t('Grid:delete')}>
                        <DeleteIcon />
                      </Tooltip>
                    }
                    label={t('Grid:delete')}
                    onClick={() => {
                      dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                    }}
                    disabled={
                      get(row, 'state', null) === 'NEW' ||
                      !get(row, 'canDelete', true) ||
                      get(row, 'system', false)
                    }
                  />
                </>
              );
            },
          },
          {
            field: 'icon',
            headerName: t('icon'),
            renderCell: ({ row }: GridRenderCellParams) => (
              <Box sx={{ mt: compactMode ? 0 : 0.6 }}>
                <Icon baseClassName="material-icons">{row.icon}</Icon>
              </Box>
            ),
            filterable: false,
            sortable: false,
          },
          ...languages.map((language: string, index: number) => {
            const lng = get(language, 'abbreviation', '');
            const field = `languageJsonText_${lng}`;
            if (index === 0) setColumnName(field);
            return {
              field,
              headerName: t(field),
              valueGetter: (_value: any, row: any) => {
                const value = get(row, 'languageJsonText', '');
                const values = value ? JSON.parse(value) : null;
                return get(values, lng, '');
              },
            };
          }),
          { field: 'url', headerName: t('url') },
          {
            field: 'workPlaces',
            headerName: t('workplaces'),
            valueGetter: (value: any, row: any) =>
              isArray(get(row, 'workPlaces', null))
                ? sortBy(
                    get(row, 'workPlaces').map((workPlace: IWorkplace) =>
                      get(
                        get(workPlace, 'text', false) ? JSON.parse(get(workPlace, 'text', '')) : {},
                        currentLocale,
                        workPlace.code,
                      ),
                    ),
                  ).join(', ')
                : null,
          },
          {
            field: 'system',
            headerName: t('system'),
            valueGetter: (value: any, row: any) => (get(row, 'system', false) ? t('yes') : t('no')),
          },
        ]),
      );
      setColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages?.length]);

  const refreshGrid = () => loadEntity();

  const toggleDialog = (state: boolean = false) => {
    setDialogState(state);
  };

  const toggleDialogSplitBy = (state: boolean = false) => {
    setDialogStateSplitBy(state);
  };

  const onChangeActive = async (id: string, isActive: boolean) => {
    toggleLoader();
    const changed = await setActiveButton(id, isActive);
    if (changed) {
      refreshGrid();
    }
    toggleLoader(false);
  };

  const confirmActionChangeActive = async () => {
    await onChangeActive(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  const renderedOtherButton = (
    <Button
      variant="contained"
      color="primary"
      onClick={() => toggleDialogSplitBy(true)}
      sx={{ ml: 1, mb: 0.5 }}
    >
      <AddIcon sx={{ mr: 1 }} />
      {t('selectedButtonsSplitBy')}
    </Button>
  );

  return (
    <>
      <Header
        title=""
        addUrl="/administration/announcementsAndButtons/button/create"
        toggleDialog={toggleDialog}
        toggleDialogText={t('deleteInactive')}
        toggleDialogIcon="delete"
        addObject={renderedOtherButton}
      />
      {!isEmpty(columns) && (
        <MuiGrid
          gridKey={muiGridKey}
          rows={buttons}
          columns={columns}
          initialSortMode={[{ field: columnName, sort: 'asc' }]}
        />
      )}

      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={
            confirmationData.confirmAction === 'confirmActionChangeActive'
              ? confirmActionChangeActive
              : confirmAction
          }
        />
      )}

      <ButtonsGrid
        title={t('deleteInactive')}
        dialogState={dialogState}
        toggleDialog={toggleDialog}
        refreshGrid={refreshGrid}
      />
      <ButtonsGrid
        title={t('selectedButtonsSplitBy')}
        dialogState={dialogStateSplitBy}
        toggleDialog={toggleDialogSplitBy}
        refreshGrid={refreshGrid}
        action={SELECTED_BTN_SPLIT_BY}
      />
    </>
  );
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { differenceInSeconds, format, parseISO } from 'date-fns';
import { each, get, isEmpty, isNumber, keys } from 'lodash';
import { Tooltip } from '@mui/material';
import { Description as GridDetailIcon } from '@mui/icons-material';

import { findTestResults } from './_api';
import ResultsChart from './ResultsChart';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';

import useRefetchGrid from 'utils/grid/useRefetchGrid';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { formatTime } from 'utils/hooks/useDate';
import { convertSearchDates } from 'utils/search';

import { Box } from '@mui/system';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import {
  getGridStringOperators,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { encryptId } from 'utils/hooks/useApp';
import { useNavigate } from 'react-router';
import { testStateFormatter } from './_formatter';
import { TourTests } from './TourTests';
import Button from 'components/Buttons/Button';
import FormSelect from 'components/Form/Select/Select';
import FormDatePicker from 'components/Form/Date/Date';
import {
  DATE_BTN_CUSTOM_DATE,
  DATE_BTN_LAST_MONTH,
  DATE_BTN_LAST_THREE_DAYS,
  DATE_BTN_LAST_WEEK,
  DATE_BTN_TODAY,
  DATE_BTN_YESTERDAY,
} from 'constants/constants';
import { useStudy } from 'utils/hooks/useStudy';
import { useStorage } from 'utils/hooks/useStorage';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const apiDateFormatWithDash = 'yyyy-MM-dd';
const apiDateFormat = 'yyyyMMdd';

const FORM_DATA_KEY = 'allResultsForm';

const mainSx = { width: { xs: '100%', md: '50%', lg: '50%' } };
const muiGridKey = 'allResultsMui';

export const AllResults: React.FC = () => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('Tests');
  const { toggleLoader } = useAppGlobals();

  const { linkBack } = useStudy();
  const { getItem, putItem, removeItem } = useStorage();

  const backFromDetail = linkBack();

  const navigate = useNavigate();
  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const { setPageTitle } = useWithTitle();
  const { refetchGrid } = useRefetchGrid();

  const [results, setResults] = useState<any>({});
  const [title, setTitle] = useState<string>('');
  const [hasCustomDate, setHasCustomDate] = useState(false);

  const methods = useForm<any>({
    defaultValues: { dateFrom: '', dateTo: '' },
  });

  const { handleSubmit, reset, register, setValue, watch } = methods;
  const selectedDate = watch('date');
  const fromDate = watch('dateFrom');
  const toDate = watch('dateTo');
  const searchDisabled = !selectedDate || (selectedDate === DATE_BTN_CUSTOM_DATE && !fromDate);

  const dateSelectItems = [
    DATE_BTN_TODAY,
    DATE_BTN_YESTERDAY,
    DATE_BTN_LAST_THREE_DAYS,
    DATE_BTN_LAST_WEEK,
    DATE_BTN_LAST_MONTH,
    DATE_BTN_CUSTOM_DATE,
  ].map((id) => ({ id, label: t(`SearchForm:${id}`) }));

  useEffect(() => {
    if (selectedDate === DATE_BTN_CUSTOM_DATE) {
      setHasCustomDate(true);
    } else {
      setHasCustomDate(false);

      // Set dates if selected date is not custom
      if (selectedDate) {
        const dateAttributes = convertSearchDates(selectedDate, fromDate, toDate, apiDateFormat);
        setValue('dateFrom', new Date(parseISO(dateAttributes.dateFrom)));
        setValue('dateTo', new Date(parseISO(dateAttributes.dateTo)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const triggerSubmit = () => {
    const submitButton: HTMLElement = document.querySelector('.search-form-button') as HTMLElement;
    submitButton.focus();
    submitButton.click();
  };

  const loadEntity = async () => {
    setTimeout(() => {
      triggerSubmit();
    }, 250);
  };

  const submitHandler = async (values: any) => {
    callPersistForm(values);
    const selectedDate = get(values, 'date', null);
    if (selectedDate) {
      const { dateFrom, dateTo } = convertSearchDates(
        selectedDate,
        get(values, 'dateFrom', ''),
        get(values, 'dateTo', ''),
        apiDateFormatWithDash,
      );

      toggleLoader();
      try {
        const resp = await findTestResults(
          dateFrom === null ? undefined : dateFrom,
          dateTo === null ? undefined : dateTo,
        );
        setResults(resp);
        refetchGrid();
      } catch (e) {
        console.debug(e);
      }
      toggleLoader(false);
    }
  };

  const getEntity = async () => {
    toggleLoader();
    const title = t('allTestResults');
    setTitle(title);
    setPageTitle(title);
    try {
      if (backFromDetail) {
        const data = getItem(FORM_DATA_KEY);
        if (data) {
          // Parse it to a javaScript object
          const values = JSON.parse(data);
          const transformedValues = {
            ...values,
            dateFrom: values.dateFrom ? new Date(values.dateFrom) : null,
            dateTo: values.dateTo ? new Date(values.dateTo) : null,
          };
          each(keys(transformedValues), (key: any) => {
            setValue(key, get(transformedValues, key));
          });
          loadEntity();
        }
      } else {
        removeItem(FORM_DATA_KEY);
        reset({ date: DATE_BTN_LAST_MONTH, dateFrom: '', dateTo: '' });
        loadEntity();
      }
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callPersistForm = (value: any) => {
    putItem(FORM_DATA_KEY, JSON.stringify(value));
  };

  const onCustomEntityDetail = (row: any) => {
    navigate(`/tests/results/${encryptId(get(row, 'id'))}`);
  };

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['startsWith' /* add more over time */].includes(value),
  );
  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 100,
        headerAlign: 'left',
        align: 'left',
        getActions: ({ row }: GridRowParams) => [
          <GridActionsCellItem
            icon={
              <Tooltip title={t('Grid:testResultDetail')}>
                <GridDetailIcon />
              </Tooltip>
            }
            label="Detail"
            onClick={() => onCustomEntityDetail(row)}
          />,
        ],
      },
      {
        field: 'name',
        headerName: t('name'),
        valueGetter: (value: any, row: any) => get(row, 'test.name'),
      },
      {
        field: 'state',
        headerName: t('testState'),
        filterOperators,
        renderCell: ({ row }: GridRenderCellParams) => testStateFormatter(row, t),
        valueGetter: (value: any, row: any) => t(get(row, 'state', '').toLowerCase()),
      },
      {
        field: 'correctAnswers',
        headerName: t('correctAnswers'),
        valueGetter: (value: any, row: any) =>
          `${get(row, 'correctAnswers')}/${get(row, 'questionsCount')}`,
      },
      {
        field: 'startedWhen',
        headerName: t('startedWhen'),
        type: 'date',
        valueGetter: (value: any, row: any) => {
          try {
            return new Date(get(row, 'startedWhen', ''));
          } catch (e) {
            return null;
          }
        },
        renderCell: ({ row }: GridRenderCellParams) =>
          get(row, 'startedWhen', '')
            ? format(new Date(get(row, 'startedWhen', '')), 'dd.MM.yyyy HH:mm')
            : '',
      },
      {
        field: 'testLength',
        headerName: t('testLength'),
        valueGetter: (value: any, row: any) =>
          formatTime(
            differenceInSeconds(
              new Date(get(row, 'finishedWhen')),
              new Date(get(row, 'startedWhen')),
            ),
            t,
          ),
      },
    ]),
  );

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Chart = useMemo(() => {
    if (!isEmpty(get(results, 'testExecutions')) && isNumber(get(results, 'successRate'))) {
      return <ResultsChart successRate={get(results, 'successRate', 0)} />;
    } else {
      return null;
    }
  }, [results]);

  const renderedSteps = () => {
    return <TourTests type="result" />;
  };

  return (
    <>
      <Header title={title} TourComponent={renderedSteps()} />
      <Papeer bottomMargin={true}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <input {...register('date')} type="hidden" />
            <button hidden={true} ref={refSubmitButtom} type={'submit'} />
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              data-tour="testsResultForm"
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: {
                    xs: 'wrap',
                    lg: 'nowrap',
                  },
                  paddingRight: { xs: '0px', sm: 0 },
                }}
              >
                <Box sx={mainSx}>
                  <FormSelect
                    name="date"
                    label={t('datePeriod')}
                    items={dateSelectItems}
                    sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    clearable={true}
                    clearCallback={() => {
                      setValue('dateFrom', null);
                      setValue('dateTo', null);
                    }}
                  />
                </Box>
                <Box sx={mainSx}>
                  <FormDatePicker
                    name="dateFrom"
                    label={t('SearchForm:from')}
                    sx={{ borderRadius: 0 }}
                    disabled={!hasCustomDate}
                  />
                </Box>
                <Box sx={mainSx}>
                  <FormDatePicker
                    name="dateTo"
                    label={t('SearchForm:to')}
                    sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    disabled={!hasCustomDate}
                  />
                </Box>
              </Box>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={searchDisabled}
                  className="search-form-button"
                >
                  {t('search')}
                </Button>
              </div>
            </Box>
          </form>
        </FormProvider>
      </Papeer>
      {Chart}
      <MuiGrid
        gridKey={muiGridKey}
        rows={get(results, 'testExecutions', [])}
        columns={columns}
        dataTour="testsResultGrid"
      />
    </>
  );
};

import { get, includes, keys, set } from 'lodash';
import { IModality } from 'modules/Administration/ModalitiesUnitsStations/_types';
import { IProduct } from 'modules/Administration/Products/_types';
import { api } from 'utils/hooks/useApi';
import { IAuthConfig, ILoginMethodResponse, IRole, IUserRole, IValidators } from './_types';

export const getRoles = (): Promise<IUserRole[]> =>
  api()
    .get(`/portal-api/userSetting/getUserRoles`)
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const getAllRoles = (): Promise<IRole[]> =>
  api()
    .get(`/portal-api/workPlace/getAllRoles`)
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const refreshJwtToken = (): Promise<string> =>
  api()
    .get(`/portal-api/refreshJwtToken`)
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const login = (values: any): Promise<ILoginMethodResponse> =>
  api()
    .post(`/portal-api/login`, values)
    .then((response) => ({
      success: true,
      totpRequired: includes([202], get(response, 'status')),
      token: response.data,
    }))
    .catch((error) => ({
      success: false,
      totpRequired: false,
      msg: includes([404, 500, 503], get(error, 'response.status'))
        ? 'error.apiNotWorking'
        : get(error, 'response.data', 'error.generalError'),
    }));

export const login2FA = (data: any): Promise<ILoginMethodResponse> =>
  api()
    .get(`/portal-api/login2FA`, { params: { user2FADigits: data.TOTPCode } })
    .then((response) => ({ success: true, totpRequired: false, token: response.data }))
    .catch((error) => ({
      success: false,
      totpRequired: false,
      msg: includes([404, 500, 503], get(error, 'response.status'))
        ? 'error.apiNotWorking'
        : includes([409], get(error, 'response.status'))
        ? 'error.authentication.password.invalid'
        : get(error, 'response.data', 'error.generalError'),
      removeToken: includes([409], get(error, 'response.status')) ? true : false,
    }));

export const getValidators = (): Promise<IValidators> =>
  api()
    .get(`/portal-api/config/getValidators`)
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const logout = (): any =>
  api()
    .get(`/portal-api/logout`)
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const getAllModalities = (): Promise<IModality[]> =>
  api()
    .get(`/portal-api/modality/getAll`)
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const getProductByType = (productType: string): Promise<IProduct[]> =>
  api()
    .get(`/portal-api/product/getProductsByType`, { params: { productType } })
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const getTrashForUser = (): any =>
  api()
    .get(`/portal-api/product/getTrashForUser`)
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const getRequestStates = (): Promise<string[]> =>
  api()
    .get(`/portal-api/studyRequest/getStates`)
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const getGridSettings = (): any =>
  api()
    .get(`/portal-api/userSetting/getGridSettings`)
    .then((response) => {
      const settings = get(response, 'data', null);
      if (settings) {
        const settingsObject = {};
        keys(settings).forEach((key: string) =>
          set(settingsObject, key, JSON.parse(get(settings, key, ''))),
        );
        return settingsObject;
      }
    })
    .catch(() => false);

export const getSearchFilters = () =>
  api()
    .get(`/portal-api/userSetting/getDefaultArchives`)
    .then((response) => {
      try {
        const searchFilter = get(response, 'data.defaultArchives', null);
        return searchFilter;
      } catch (e) {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

export const getAllowedArchives = (productType: string, searchFunctions: string[]) =>
  api()
    .post(`/portal-api/product/getProductsByTypeAndFunctions`, {
      productType,
      functions: searchFunctions,
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getArchives = () =>
  api()
    .post(`/portal-api/product/getArchives`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAuthConfig = (): Promise<IAuthConfig> =>
  api()
    .get(`/portal-api/config/getAuthConfig `)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const autologin = (type: string): Promise<ILoginMethodResponse> =>
  api({ type })
    .get(`/portal-api/autologin `)
    .then((response) => ({
      success: true,
      totpRequired: includes([202], get(response, 'status')),
      token: get(response, 'data'),
    }))
    // .catch((error) => false);
    .catch((error) => ({
      success: false,
      totpRequired: false,
      msg: includes([404, 500, 503], get(error, 'response.status'))
        ? 'error.apiNotWorking'
        : includes([409], get(error, 'response.status'))
        ? 'error.authentication.password.invalid'
        : get(error, 'response.data', 'error.generalError'),
      removeToken: includes([409], get(error, 'response.status')) ? true : false,
    }));

export const forgottenPassword = (data: any) =>
  api()
    .post(`/portal-api/user/forgottenPassword`, data)
    .then((response) => true)
    .catch((error) => ({
      errorCode: get(error, 'response.data.errorCode'),
    }));

export const passwordRecovery = (password: string, hash: string) =>
  api()
    .post(`/portal-api/user/passwordRecovery`, { password, hash })
    .then((response) => get(response, 'data'))
    .catch((error) => ({
      errorCode: get(error, 'response.data.errorCode'),
      errorMessage: get(error, 'response.data.errorMessage'),
    }));

export const checkPassword = (password: string) =>
  api()
    .post(`/portal-api/config/checkPassword`, { password })
    .then((response) => true)
    .catch((error) => ({
      errorCode: get(error, 'response.data.errorCode'),
      errorMessage: get(error, 'response.data.errorMessage'),
    }));

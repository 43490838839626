import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { get, join, orderBy, trim } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import {
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
  Description as GridDetailIcon,
} from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import RequestsStateForm from './RequestsStateForm';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import {
  requestHasStatus,
  requestStatusIncludes,
  userHasIssuedRequest,
  userHasRightForRequestOperationType,
} from 'utils/hooks/useRequest';
import { useUser } from 'utils/hooks/useUser';
import { shortener } from 'utils/grid/GridFormatters';
import styles from 'components/SearchResultsMUI/_styles';
import {
  MOVE_TO_TRASH,
  SEARCH_FUNCTION_EDIT_PATIENT,
  SEARCH_FUNCTION_EDIT_STUDY,
  SEARCH_FUNCTION_MOVE_TO_FOLDER,
  SEARCH_FUNCTION_MOVE_TO_TRASH,
  STATE_ABORTED,
  STATE_APPROVED,
  STATE_NEW,
  STATE_REJECTED,
} from 'constants/constants';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'requestResultsMui';

const RequestsResults: React.FC<any> = ({ operation }) => {
  const { t } = useTranslation('Request');
  const navigate = useNavigate();
  const { hasRole, user } = useUser();

  const { pathname } = useLocation();

  const { searchRequestResults, compactMode } = useAppInfo();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const [dialogOpened, toggleDialog] = useState<boolean>(false);
  const [requestToBeModified, setRequestToBeModified] = useState<any>(null);
  const [linkBackAfterChangeState, setLinkBackAfterChangeState] = useState<any>(null);

  useEffect(() => {
    setLinkBackAfterChangeState(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRequestState = (request: any, state: any, title: any) => {
    toggleDialog(true);
    setRequestToBeModified({
      request,
      state,
      title,
    });
  };

  const getOperationType = (row: any) =>
    t(`StudyFunctions:${get(row, 'operationType', '').toLowerCase()}`);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 160,
        headerAlign: 'left',
        align: 'left',
        renderCell: ({ row }: GridRenderCellParams) => (
          <Box data-tour="request-requestGridActions">
            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:showRequestDetail')}>
                  <GridDetailIcon />
                </Tooltip>
              }
              label={t('Grid:showRequestDetail')}
              onClick={() => onCustomEntityDetail(row)}
              sx={compactMode ? { p: 0 } : {}}
            />
            {requestHasStatus(row, STATE_NEW) &&
              userHasRightForRequestOperationType(row, hasRole) && (
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t('Grid:requestApprove')}>
                      <CheckCircleIcon />
                    </Tooltip>
                  }
                  label={t('Grid:requestApprove')}
                  onClick={() => setRequestState(row, STATE_APPROVED, t('requestApprove'))}
                  sx={compactMode ? { p: 0 } : {}}
                />
              )}
            {requestHasStatus(row, STATE_NEW) &&
              userHasRightForRequestOperationType(row, hasRole) && (
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t('Grid:requestReject')}>
                      <CancelIcon />
                    </Tooltip>
                  }
                  label={t('Grid:requestReject')}
                  onClick={() => setRequestState(row, STATE_REJECTED, t('requestReject'))}
                  sx={compactMode ? { p: 0 } : {}}
                />
              )}
            {userHasIssuedRequest(row, user) &&
              requestStatusIncludes(row, [STATE_NEW, STATE_REJECTED, STATE_ABORTED]) && (
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t('Grid:requestEdit')}>
                      <EditIcon />
                    </Tooltip>
                  }
                  label={t('Grid:requestEdit')}
                  onClick={() => onCustomEntityUpdate(row)}
                  sx={compactMode ? { p: 0 } : {}}
                />
              )}
            {userHasIssuedRequest(row, user) &&
              requestStatusIncludes(row, [STATE_NEW, STATE_REJECTED]) && (
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t('Grid:requestAbort')}>
                      <DeleteIcon />
                    </Tooltip>
                  }
                  label={t('Grid:requestAbort')}
                  onClick={() => setRequestState(row, STATE_ABORTED, t('requestAbort'))}
                  sx={compactMode ? { p: 0 } : {}}
                />
              )}
          </Box>
        ),
      },
      {
        field: 'operationType',
        headerName: t('typeOfOperation'),
        hideable: false,
        valueGetter: (value: any, row: any) => getOperationType(row),
        renderCell: ({ row }: GridRenderCellParams) => (
          <Tooltip title={t('Grid:showRequestDetail')}>
            <Box onClick={() => onCustomEntityDetail(row)} sx={styles.gridLink}>
              {getOperationType(row)}
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'createdWhen',
        headerName: t('createdWhen'),
        type: 'dateTime',
        valueGetter: (value: any, row: any) => {
          const dateTime = get(row, 'createdWhen', null);
          return dateTime && new Date(dateTime);
        },
      },
      {
        field: 'requestedBy',
        headerName: t('requestingUser'),
        valueGetter: (value: any, row: any) =>
          `${get(row, 'requestedBy.lastName', '') || ''} ${
            get(row, 'requestedBy.firstName', '') || ''
          }`,
      },
      {
        field: 'status',
        headerName: t('state'),
        valueGetter: (value: any, row: any) => t(get(row, 'status', '').toLowerCase()),
      },
      {
        field: 'comments',
        headerName: t('comment'),
        renderCell: ({ row }: GridRenderCellParams) => {
          const comments = get(row, 'comments', []);
          const sortedComments = orderBy(comments, ['createdWhen'], ['desc']);
          const values = sortedComments.map((comment: any, index: number) => (
            <li key={'comment.id'}>
              {shortener(comment.text, 70, true)} ({get(comment, 'createdBy.lastName', '') || ''}
              {get(comment, 'createdBy.firstName') ? ' ' : null}
              {get(comment, 'createdBy.firstName', '') || ''})
            </li>
          ));
          return values ? (
            <Box component="ul" sx={{ listStyle: 'none', p: '0', my: 0.25 }}>
              {values}
            </Box>
          ) : (
            ''
          );
        },
        valueGetter: (value: any, row: any) => {
          const comments = get(row, 'comments', []);
          const sortedComments = orderBy(comments, ['createdWhen'], ['desc']);
          const values = sortedComments.map(
            (comment: any) =>
              `${get(comment, 'text', '')} ${get(comment, 'createdBy.lastName', '')} ${get(
                comment,
                'createdBy.firstName',
                '',
              )}`,
          );
          return trim(join(values, ' '));
        },
      },
    ]),
  );

  const onCustomEntityDetail = (row: any) =>
    navigate(`/requests/${get(row, 'id')}`, { state: { linkBack: get(row, 'operationType') } });

  const onCustomEntityUpdate = (row: any) =>
    navigate(
      `/requests/${
        row.operationType === SEARCH_FUNCTION_EDIT_PATIENT
          ? 'editPatient'
          : row.operationType === SEARCH_FUNCTION_EDIT_STUDY
          ? 'editStudy'
          : row.operationType === SEARCH_FUNCTION_MOVE_TO_FOLDER
          ? 'moveToFolder'
          : row.operationType === SEARCH_FUNCTION_MOVE_TO_TRASH ||
            row.operationType === MOVE_TO_TRASH
          ? 'moveToTrash'
          : row.operationType.toLowerCase()
      }/${get(row, 'id')}`,
      { state: { isRequestEdit: true } },
    );

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={searchRequestResults}
        columns={columns}
        //rowHeight={true}
        dataTour="request-grid"
        initialSortMode={[{ field: 'createdWhen', sort: 'desc' }]}
      />
      {dialogOpened && linkBackAfterChangeState && (
        <Dialog
          open={true}
          onClose={() => toggleDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">{get(requestToBeModified, 'title')}</DialogTitle>
          <DialogContent>
            <RequestsStateForm
              request={requestToBeModified}
              toggleDialog={toggleDialog}
              linkBackAfterChangeState={`${linkBackAfterChangeState}?refresh=${new Date()
                .getTime()
                .toString()}`}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default RequestsResults;

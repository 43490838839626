import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  SubdirectoryArrowRight as SubdirectoryArrowRightIcon,
} from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { createTheme } from '@mui/material/styles';
import { getAllGroups, setActiveRegisterGroup } from '../_api';
import Header from 'components/Header/Header';
import { IRegisterGroup } from 'components/TagForm/_types';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { shortener } from 'utils/grid/GridFormatters';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { sortCollectionByItemName } from 'utils/hooks/useLocaleCompare';
import { TourTags } from './TourTags';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const theme = createTheme();

export const extractGroupWithSubgroup = (
  group: IRegisterGroup,
  arr: IRegisterGroup[],
  parent: any = null,
) => {
  arr.push({ ...group, parent });
  if (isArray(get(group, 'children'))) {
    sortCollectionByItemName(get(group, 'children', []), 'name').map((subGroup: IRegisterGroup) =>
      extractGroupWithSubgroup(subGroup, arr, group.id),
    );
  }
};

const muiGridKey = 'registerGroupsMui';

export const RegisterGroups: React.FC = () => {
  const { t } = useTranslation('RegisterGroups');
  const { toggleLoader } = useAppGlobals();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { title } = useWithTitle();
  const { confirmationData } = useAppInfo();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const [groups, fetchGroups] = useState<IRegisterGroup[]>([]);

  const onCustomEntityDetail = (row: any) => {
    navigate(`/tags/registerGroups/${get(row, 'id')}`);
  };

  const onChangeActive = async (id: string, isActive: boolean) => {
    toggleLoader();
    const changed = await setActiveRegisterGroup(id, isActive);
    if (changed) {
      await loadGroups();
    }
    toggleLoader(false);
  };

  const loadGroups = async () => {
    toggleLoader();
    const groups = await getAllGroups();
    const sortedGroupWithSubgroup: IRegisterGroup[] = [];
    (isArray(groups) ? sortCollectionByItemName(groups, 'name') : []).forEach(
      (element: IRegisterGroup) => {
        extractGroupWithSubgroup(element, sortedGroupWithSubgroup);
      },
    );
    fetchGroups(sortedGroupWithSubgroup);
    toggleLoader(false);
  };

  useEffect(() => {
    loadGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 180,
        renderCell: ({ row }: GridRenderCellParams) => (
          <>
            <Box sx={{ width: '200px' }}>
              <FormControlLabel
                sx={{ maxHeight: 30 }}
                control={
                  <Switch
                    checked={row.active}
                    onChange={(e, state) => {
                      dispatch(
                        storeConfirmationDataToStore({
                          title: 'Grid:confirmChangeState',
                          id: get(row, 'id'),
                          state,
                          confirmAction: 'confirmActionChangeActive',
                        }),
                      );
                    }}
                  />
                }
                label={(row.active ? t('active') : t('inactive')) as string}
                disabled={get(row, 'disabledRow', false)}
              />
            </Box>
            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:edit')}>
                  <EditIcon />
                </Tooltip>
              }
              label={t('Grid:edit')}
              onClick={() => onCustomEntityDetail(row)}
              disabled={!get(row, 'canUpdate', true)}
            />
          </>
        ),
      },
      {
        field: 'name',
        headerName: t('name'),
        hideable: false,
        width: 400,
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams) => {
          const name = get(row, 'name');
          const rootLevel = get(row, 'rootLevel');
          const isLeveled = rootLevel > 0;
          return (
            <Box
              sx={isLeveled ? { display: 'flex', alignItems: 'center' } : {}}
              style={isLeveled ? { paddingLeft: theme.spacing(rootLevel * 2) } : {}}
            >
              {isLeveled && <SubdirectoryArrowRightIcon sx={{ width: 20, height: 20, mr: 1 }} />}
              {shortener(name, 100)}
            </Box>
          );
        },
      },
    ]),
  );

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };
  const confirmActionChangeActive = async () => {
    await onChangeActive(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  const renderedSteps = () => {
    return <TourTags type="main" />;
  };

  return (
    <>
      <Header title={title} addUrl="/tags/registerGroups/create" TourComponent={renderedSteps()} />
      <MuiGrid
        gridKey={muiGridKey}
        rows={groups}
        columns={columns}
        disableColumnMenu={true}
        dataTour="registerGroupsGrid"
      />
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmActionChangeActive}
        />
      )}
    </>
  );
};

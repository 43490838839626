import { useStepsReactour } from './useStepsReactour';

interface IUseGenerateSteps {
  isDropDownOpen: any;
  currentOpenDropdown: any;
  setCurrentStep: any;
  stepsDefinition: any;
}

const useGenerateSteps = ({
  isDropDownOpen,
  currentOpenDropdown,
  setCurrentStep,
  stepsDefinition,
}: IUseGenerateSteps) => {
  const stepToUse = stepsDefinition({ currentOpenDropdown, isDropDownOpen, setCurrentStep });

  const steps = useStepsReactour({
    allSteps: stepToUse || {},
  });

  return { steps };
};

export default useGenerateSteps;

import { useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import Header from 'components/Header/Header';
import { WhiteBox } from 'components/WhiteBox/WhiteBox';
import { format } from 'date-fns';
import {
  each,
  filter,
  get,
  includes,
  isArray,
  isEqual,
  isString,
  keys,
  omit,
  pick,
  pickBy,
  sortBy,
} from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStudy } from 'utils/hooks/useStudy';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { joinParams, generateIID } from 'utils/study';
import { IReorderDataForApi, IStudiesReorderDetail, IStudiesReorderForm } from './_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { getSeriesAndInstances } from 'components/SeriesViewer/_api';
import { getValuesFromDicomFile } from '../StudyDetail/_api';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useValidationSchema from './_form';
import { SeriesViewer } from 'components/SeriesViewer/SeriesViewer';
import { useSeriesViewer } from 'components/SeriesViewer/useSeriesViewer';
import { IStudyWithStates } from 'components/SeriesViewer/_types';
import RequestAdditionalForm from '../Form/RequestAdditionalForm';
import { createForReorder, editRequestForReorder, reorderStudy } from './_api';
import useAlerts from 'components/Alerts/useAlerts';
import { useNavigate } from 'react-router';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { IExistingReorderRequest } from 'modules/Requests/RequestsReorder/_types';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { Papeer } from 'components/Papeer/Papeer';
import Button from 'components/Buttons/Button';

export const StudiesReorderDetail: React.FC<IStudiesReorderDetail> = ({
  isRequest = false,
  isRequestEdit = false,
  request,
  requestSourceStudy,
  requestTargetStudy,
}) => {
  const { t } = useTranslation('Studies');
  useWithTitle(); // sets title to document
  const { reorderStore } = useStudyInfo();
  const { backToStudyIID, getPatientSexSlug } = useStudy();
  const { portalSetting } = useAppInfo();
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [confirmationData, setConfirmationData] = useState<
    null | IReorderDataForApi | IExistingReorderRequest | any
  >(null);

  const clearConfirmationData = () => setConfirmationData(null);

  const { StudiesSplitFormSchema } = useValidationSchema();
  const navigate = useNavigate();

  const sourceStudy = isRequestEdit ? requestSourceStudy : reorderStore[0];
  const targetStudy = isRequestEdit ? requestTargetStudy : reorderStore[1];

  const sourceArchiveId = get(sourceStudy, 'archive.id');
  const targetArchiveId = get(targetStudy, 'archive.id');

  const sourceStudyId = get(sourceStudy, 'studyInstanceUid');
  const targetStudyId = get(targetStudy, 'studyInstanceUid');

  const [sourceDicomData, setSourceDicomData] = useState<{ performingPhysician: any } | null>(null);
  const [targetDicomData, setTargetDicomData] = useState<{ performingPhysician: any } | null>(null);

  const [existingSourceStudy, setExistingSourceStudy] = useState<any>({});
  const [existingTargetStudy, setExistingTargetStudy] = useState<any>({});

  const [allStudiesLoaded, setAllStudiesLoaded] = useState<boolean>(false);

  const {
    studiesForViewer: sourceStudyWithSeries,
    setStudiesForViewer: setSourceStudies,
    operations: operationsForSourceStudy,
    somethingSelected,
    numberOfSelectedImages,
  } = useSeriesViewer();

  const {
    studiesForViewer: targetStudyWithSeries,
    setStudiesForViewer: setTargetStudies,
    operations: operationsForTargetStudy,
  } = useSeriesViewer();

  const [selectedSeriesInSourceStudy, setSelectedSeriesInSourceStudy] = useState<
    IStudyWithStates[]
  >([]);

  useEffect(() => {
    if (generateIID(sourceStudy) === generateIID(targetStudy)) {
      addErrorAlert(t('cannotReorderSameStudies'));
      navigate(linkBack);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedSeriesInSourceStudy(
      sourceStudyWithSeries.map((study) => ({
        ...study,
        loadedSeries: filter(study.loadedSeries, { serieIsSelected: true }).map((serie) => ({
          ...serie,
          image: filter(serie.image, { imageIsSelected: true }),
        })),
      })),
    );
  }, [sourceStudyWithSeries]);

  const loadStart = async () => {
    if (sourceStudy && targetStudy && sourceArchiveId && targetArchiveId) {
      try {
        toggleLoader();
        const [sourceSeries, targetSeries] = await Promise.all([
          getSeriesAndInstances(sourceArchiveId, sourceStudyId),
          getSeriesAndInstances(targetArchiveId, targetStudyId),
        ]);
        toggleLoader(false);

        if (isArray(sourceSeries)) {
          const sourceStudies = [
            {
              ...sourceStudy,
              iid: generateIID(sourceStudy),
              studyIsSelected: false,
              studyIsOpened: false,
              loadedSeries: sortBy(
                sourceSeries.map((serie) => ({
                  ...serie,
                  serieIsSelected: false,
                  serieIsOpened: false,
                  image: isArray(serie.image)
                    ? serie.image.map((image) => ({ ...image, imageIsSelected: false }))
                    : [],
                })),
                ['modality', 'numberSerie'],
              ),
            } as unknown as IStudyWithStates,
          ];
          setSourceStudies(sourceStudies);
        }

        if (isArray(targetSeries)) {
          const targetStudies = [
            {
              ...targetStudy,
              iid: generateIID(targetStudy),
              studyIsSelected: false,
              studyIsOpened: false,
              loadedSeries: sortBy(
                targetSeries.map((serie) => ({
                  ...serie,
                  serieIsSelected: false,
                  serieIsOpened: false,
                  image: isArray(serie.image)
                    ? serie.image.map((image) => ({ ...image, imageIsSelected: false }))
                    : [],
                })),
                ['modality', 'numberSerie'],
              ),
            } as unknown as IStudyWithStates,
          ];
          setTargetStudies(targetStudies);
        }

        setAllStudiesLoaded(true);
      } catch (e) {
        toggleLoader();
        addErrorAlert(t('couldNotLoadSeries'));
      }

      try {
        const [sourceDicom, targetDicom] = await Promise.all([
          getValuesFromDicomFile(parseInt(sourceArchiveId, 10), sourceStudyId, {
            PERFORMING_PHYSICIAN_NAME: 'NAME',
          }),
          getValuesFromDicomFile(parseInt(targetArchiveId, 10), targetStudyId, {
            PERFORMING_PHYSICIAN_NAME: 'NAME',
          }),
        ]);

        if (sourceDicom) {
          setSourceDicomData({
            performingPhysician: get(sourceDicom, 'PERFORMING_PHYSICIAN_NAME')
              ? JSON.parse(get(sourceDicom, 'PERFORMING_PHYSICIAN_NAME'))
              : null,
          });
        }

        if (targetDicom) {
          setTargetDicomData({
            performingPhysician: get(targetDicom, 'PERFORMING_PHYSICIAN_NAME')
              ? JSON.parse(get(targetDicom, 'PERFORMING_PHYSICIAN_NAME'))
              : null,
          });
        }
      } catch (e) {
        toggleLoader();
        addErrorAlert(t('couldNotLoadDicomData'));
      }
    }
  };

  const extractExistingStudyInfo = (study: any, dicomData: any) => ({
    lastName: get(study, 'patient.name.lastName', ''),
    firstName: get(study, 'patient.name.firstName', ''),
    middleName: get(study, 'patient.name.middleName', ''),
    prefix: get(study, 'patient.name.prefix', ''),
    suffix: get(study, 'patient.name.suffix', ''),
    patientID: get(study, 'patient.identificationNumber', ''),
    sex: t(getPatientSexSlug(get(study, 'patient.sex', ''))),
    dateOfBirth: format(new Date(get(study, 'patient.dateBirth', '')), 'dd. MM. yyyy'),
    dateCreated: format(new Date(get(study, 'dateTime', '')), 'dd. MM. yyyy'),
    timeCreated: format(new Date(get(study, 'dateTime', '')), 'HH:mm'),
    accessionNumber: get(study, 'accessionNumber', ''),
    studyDescription: get(study, 'studyDescription', ''),
    ...pickBy({
      requestingPhysician: get(portalSetting, 'seeRequestingPhysician', null)
        ? joinParams([
            get(study, 'requestingPhysician.name.lastName', ''),
            get(study, 'requestingPhysician.name.firstName', '\u00A0'),
          ])
        : null,
      referringPhysician: get(portalSetting, 'seeReferringPhysician', null)
        ? joinParams([
            get(study, 'referringPhysician.name.lastName', ''),
            get(study, 'referringPhysician.name.firstName', '\u00A0'),
          ])
        : null,
      performingPhysician: dicomData
        ? joinParams([
            get(dicomData, 'performingPhysician.lastName', ''),
            get(dicomData, 'performingPhysician.firstName', '\u00A0'),
          ])
        : get(portalSetting, 'seePerformingPhysician', null)
        ? joinParams([
            get(study, 'performingPhysician.name.lastName', ''),
            get(study, 'performingPhysician.name.firstName', '\u00A0'),
          ])
        : null,
    }),
    hasComments: get(study, 'comments', []).length > 0,
    archive: `${get(study, 'archive.name', '')} (${get(study, 'archive.code', '')})`,
  });

  // Load dicom data for studies
  useEffect(() => {
    if (sourceStudy && targetStudy) {
      loadStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceStudy, targetStudy, sourceArchiveId, targetArchiveId]);

  // Extract study data based on source/target study and dicom data
  useEffect(() => {
    if (sourceStudy && targetStudy) {
      try {
        const existingSourceStudy = extractExistingStudyInfo(sourceStudy, sourceDicomData);
        const existingTargetStudy = extractExistingStudyInfo(targetStudy, targetDicomData);

        setExistingSourceStudy(existingSourceStudy);
        setExistingTargetStudy(existingTargetStudy);
      } catch (e) {
        console.debug(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceStudy, targetStudy, sourceDicomData, targetDicomData]);

  // Set studies checked when editing request
  useEffect(() => {
    if (isRequestEdit && allStudiesLoaded) {
      const selectedSeries = request?.data.series;

      // No selected series from request or no series for source study
      if (!(keys(selectedSeries).length && sourceStudyWithSeries.length)) return;

      // Series Uids
      const selectedSeriesUids = keys(selectedSeries);

      setSourceStudies(
        sourceStudyWithSeries.map((study) => ({
          ...study,
          loadedSeries: study.loadedSeries.map((serie) => {
            // Uid not found in selected => serie not selected
            if (!includes(selectedSeriesUids, serie.uid)) {
              return serie;
            }

            return {
              ...serie,
              serieIsSelected: true,
              image: serie.image.map((image) => {
                const selectedImages = get(selectedSeries, serie.uid, []);

                // sopinstanceUid not found in selectedImages => image not selected
                if (!includes(selectedImages, image.sopinstanceUid)) {
                  return image;
                }
                return { ...image, imageIsSelected: true };
              }),
            };
          }),
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStudiesLoaded]);

  const defaultValues = {
    includeComments: false,
  };

  const methods = useForm<IStudiesReorderForm>({
    resolver: yupResolver(StudiesSplitFormSchema),
    defaultValues,
  });

  const title = isRequestEdit
    ? t('studyReorderTitleRequestEdit')
    : isRequest
    ? t('studyReorderTitleRequest')
    : t('studyReorderTitle');

  let linkBack: string;
  if (backToStudyIID !== null) {
    linkBack = `/study/${backToStudyIID}`;
  } else {
    linkBack = `/${isRequest ? 'requests' : 'studies'}`;
  }

  let numberOfImagesInExistingStudy = 0;
  if (isArray(sourceStudyWithSeries)) {
    sourceStudyWithSeries.forEach((study) => {
      if (isArray(study.loadedSeries)) {
        study.loadedSeries.forEach((serie) => {
          if (isArray(serie.image)) {
            numberOfImagesInExistingStudy += serie.image.length;
          }
        });
      }
    });
  }

  const allInstancesSelected = numberOfImagesInExistingStudy === numberOfSelectedImages;

  const handleReorderRequestEdit = methods.handleSubmit(async (values: any) => {
    const selectedSeries = getSelectedSeriesAddedToStudy();
    const originalSelectedSeries = request?.data.series;

    const mappedSelectedSeries: { [key: string]: string[] } = {};
    each(selectedSeries, (serie: any) => {
      mappedSelectedSeries[serie.uid] = serie.image.map((image: any) => image.sopinstanceUid);
    });

    let numberOfChanges = 0;
    if (!isEqual(mappedSelectedSeries, originalSelectedSeries)) {
      numberOfChanges++;
    }

    const requestComment = get(values, 'requestComment', null);
    if (isString(requestComment) && requestComment.length) {
      numberOfChanges++;
    }

    if (numberOfChanges === 0) {
      return addErrorAlert(t('errorNoChange'));
    }

    const newValues = {
      ...request,
      data: {
        // Only ID's are required for source study
        source: pick(sourceStudy, ['studyInstanceUid', 'archive.id']),

        // Omit FE generated attributes - dateCreate, timeCreated (not neccessary)
        target: pick(targetStudy, ['studyInstanceUid', 'archive.id']),
        series: mappedSelectedSeries,
      },
      requestComment,
    };

    if (allInstancesSelected) {
      setConfirmationData(newValues);
    } else {
      submitActionForEdit(newValues);
    }
  });

  // Creating
  const submitAction = async (newValues: IReorderDataForApi) => {
    toggleLoader();
    const fn = isRequest ? createForReorder : reorderStudy;
    await fn(newValues).then(
      () => {
        addSuccessAlert(t(isRequest ? 'requestForReorderAdded' : 'successfullyReordered'));
        navigate(linkBack);
      },
      () => addErrorAlert(t(isRequest ? 'errorCreatingRequest' : 'errorReordering')),
    );
    toggleLoader(false);
  };

  const submitActionForEdit = async (newValues: any) => {
    toggleLoader();
    await editRequestForReorder(newValues).then(
      () => {
        addSuccessAlert(t('requestEdited'));
        navigate(linkBack);
      },
      () => addErrorAlert(t('errorEditingRequest')),
    );
    toggleLoader(false);
  };

  const submitButtonText = t(
    isRequestEdit ? 'editRequestForReorder' : isRequest ? 'setRequestForReorder' : 'reorder',
  );

  const handleReorder = methods.handleSubmit(async (values) => {
    const series = getSelectedSeriesAddedToStudy();

    const mappedSeries: { [key: string]: string[] } = {};

    each(series, (serie: any) => {
      mappedSeries[serie.uid] = serie.image.map((image: any) => image.sopinstanceUid);
    });

    const newValues = {
      // Only ID's are required for source study
      source: pick(sourceStudy, ['studyInstanceUid', 'archive.id']),

      // Omit FE generated attributes - dateCreate, timeCreated (not neccessary)
      target: pick(targetStudy, ['studyInstanceUid', 'archive.id']),
      series: mappedSeries,
      requestComment: get(values, 'requestComment', null),
    };

    if (allInstancesSelected) {
      setConfirmationData(newValues);
    } else {
      submitAction(newValues);
    }
  });

  const getSelectedSeriesAddedToStudy = () => {
    return selectedSeriesInSourceStudy[0].loadedSeries;
  };

  // Called when dialog confirmed - editing
  const handleConfirmationForRequestEdit = () => submitActionForEdit(confirmationData);

  // Called when dialog confirmed - creating
  const handleConfirmationForEdit = () => submitAction(confirmationData!);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={isRequestEdit ? handleReorderRequestEdit : handleReorder}>
          <Header title={t(title)} backUrl={`${linkBack}?action=back`} />
          <Grid container={true} spacing={2}>
            {[
              { title: t('sourceStudy'), study: existingSourceStudy },
              { title: t('targetStudy'), study: existingTargetStudy },
            ].map((study) => (
              <Grid item={true} xs={12} lg={6}>
                <WhiteBox title={study.title}>
                  <Grid container={true} spacing={1}>
                    {keys(omit(study.study, ['hasComments'])).map((fieldName) => (
                      <Grid item={true} xs={12} md={6} lg={4} key={fieldName}>
                        <div>
                          <strong>{t(fieldName)}</strong>
                        </div>
                        <div>{get(study.study, fieldName, '')}</div>
                      </Grid>
                    ))}
                    {get(study, 'study.hasComments') && (
                      <Grid item={true} xs={12}>
                        {t('studyContainsComment')}
                      </Grid>
                    )}
                  </Grid>
                </WhiteBox>
              </Grid>
            ))}

            <Grid item={true} xs={12} lg={6}>
              <WhiteBox>
                <SeriesViewer
                  studies={sourceStudyWithSeries}
                  showStudiesCheckboxes={true}
                  showSeriesCheckboxes={true}
                  showInstancesCheckboxes={true}
                  allowOpenSeries={true}
                  renderStudy={false}
                  operations={operationsForSourceStudy}
                  title={t('seriesOfSourceStudy')}
                />
              </WhiteBox>
            </Grid>

            <Grid item={true} xs={12} lg={6}>
              <WhiteBox>
                <SeriesViewer
                  studies={targetStudyWithSeries}
                  showStudiesCheckboxes={false}
                  showSeriesCheckboxes={false}
                  showInstancesCheckboxes={false}
                  allowOpenSeries={true}
                  renderStudy={false}
                  operations={operationsForTargetStudy}
                  title={t('seriesOfTargetStudy')}
                />
              </WhiteBox>
              <WhiteBox>
                <SeriesViewer
                  studies={selectedSeriesInSourceStudy}
                  showStudiesCheckboxes={false}
                  showSeriesCheckboxes={false}
                  showInstancesCheckboxes={false}
                  allowOpenSeries={true}
                  renderStudy={false}
                  operations={operationsForSourceStudy}
                  title={t('addedToNewStudy')}
                />
              </WhiteBox>
            </Grid>

            <Grid item={true} xs={12}>
              <RequestAdditionalForm isRequest={isRequest} />
            </Grid>
          </Grid>
          <Papeer>
            <Grid container={true} justifyContent="flex-end">
              <Grid item={true} justifyContent="flex-end">
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!somethingSelected}
                  >
                    {submitButtonText}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>

      {confirmationData !== null ? (
        <ConfirmationDialog
          title={t('reorderSend')}
          open={true}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
          cancelAction={clearConfirmationData}
          confirmAction={
            isRequestEdit ? handleConfirmationForRequestEdit : handleConfirmationForEdit
          }
        >
          <Typography variant="body1">{t('studyWillBeDeleted')}</Typography>
        </ConfirmationDialog>
      ) : null}
    </>
  );
};

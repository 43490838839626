import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SaveIcon from '@mui/icons-material/Save';

import { IEntityButtons } from './_types';

import { scrollToTopForm } from 'utils/scrollTo';
import { Box } from '@mui/system';
import Button from 'components/Buttons/Button';

export const EntityButtons: React.FC<IEntityButtons> = ({
  linkBack,
  disabledSubmit = false,
  otherStyles = {},
  toggleDialog,
  toggleDialogText,
  confirmReplaceSave,
}) => {
  const { t } = useTranslation('Form');
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} style={otherStyles}>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={disabledSubmit}
        onClick={() => scrollToTopForm('', null)}
        data-tour="saveButton"
      >
        {confirmReplaceSave ? (
          <>{t('confirm')}</>
        ) : (
          <>
            <SaveIcon sx={{ mr: 1 }} />
            {t('save')}
          </>
        )}
      </Button>
      {linkBack && (
        <Button
          sx={{ ml: 1 }}
          color="inherit"
          variant="contained"
          component={Link}
          {...({ to: linkBack } as any)}
          data-tour="backButton"
        >
          {t('back')}
        </Button>
      )}
      {toggleDialog && (
        <Button
          sx={{ ml: 1 }}
          color="inherit"
          variant="contained"
          onClick={() => toggleDialog()}
          data-tour="closeButton"
        >
          {toggleDialogText ? toggleDialogText : t('close')}
        </Button>
      )}
    </Box>
  );
};

import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';

const useValidationSchema = (t: TFunction, isCommentRequired: boolean) => {
  const { required } = useValidators();

  const RequestFormSchema = yup.object().shape({
    ...(isCommentRequired ? { comment: required(yup.string().trim()) } : {}),
  });

  const formItems = [
    {
      name: 'comment',
      label: t('comment'),
      required: isCommentRequired,
    },
  ];

  return { RequestFormSchema, formItems };
};

export default useValidationSchema;

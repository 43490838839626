import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';

const useValidationSchema = (
  t: TFunction,
  canAddOrEditUsers: boolean,
  isCreating: boolean,
  isRetired: boolean,
) => {
  const { email, required } = useValidators();

  const UserFormSchema = yup.object().shape({
    username: required(yup.string()),
    lastName: required(yup.string()),
    firstName: required(yup.string()),
    ...(isCreating
      ? {
          password: required(yup.string()),
          confirmPassword: required(yup.string()),
        }
      : {}),
    ...(canAddOrEditUsers
      ? {
          email: email(required(yup.string())),
        }
      : {}),
  });

  const formItems = [
    {
      type: 'input',
      name: 'username',
      label: t('username'),
      required: true,
      disabled: !isCreating || (!isCreating && isRetired),
    },
    {
      type: 'input',
      name: 'lastName',
      label: t('lastname'),
      required: true,
      disabled: !canAddOrEditUsers || (!isCreating && isRetired),
    },
    {
      type: 'input',
      name: 'firstName',
      label: t('firstname'),
      required: true,
      disabled: !canAddOrEditUsers || (!isCreating && isRetired),
    },
    {
      type: 'input',
      name: 'middleName',
      label: t('middlename'),
      disabled: !canAddOrEditUsers || (!isCreating && isRetired),
    },
    {
      type: 'input',
      name: 'prefix',
      label: t('prefix'),
      disabled: !canAddOrEditUsers || (!isCreating && isRetired),
    },
    {
      type: 'input',
      name: 'suffix',
      label: t('suffix'),
      disabled: !canAddOrEditUsers || (!isCreating && isRetired),
    },
    {
      type: 'password',
      name: 'password',
      label: t('password'),
      required: isCreating,
      disabled: (!isCreating && !canAddOrEditUsers) || (!isCreating && isRetired),
    },
    {
      type: 'password',
      name: 'confirmPassword',
      label: t('confirmPassword'),
      required: isCreating,
      disabled: (!isCreating && !canAddOrEditUsers) || (!isCreating && isRetired),
    },
    {
      type: 'input',
      name: 'email',
      label: t('email'),
      required: canAddOrEditUsers,
      disabled: !canAddOrEditUsers || (!isCreating && isRetired),
    },
    {
      type: 'input',
      name: 'icp',
      label: t('icp'),
      placeholder: t('icp'),
      disabled: !canAddOrEditUsers || (!isCreating && isRetired),
    },
    {
      type: 'switch',
      name: 'userIsInternal',
      label: t('userIsInternal') || (!isCreating && isRetired),
      disabled: !isCreating && isRetired,
    },
    {
      type: 'switch',
      name: 'userCanBeNotifiedAboutRequestActions',
      label: t('userCanBeNotifiedAboutRequestActions'),
      disabled: !isCreating && isRetired,
    },
  ];

  return { UserFormSchema, formItems };
};

export default useValidationSchema;

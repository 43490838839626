import React, { useState } from 'react';
// import { isArray } from 'lodash';
import { TourOrders } from './TourOrders';
import OrderSearchForm from './OrderSearchForm';
import OrderSearchResults from './OrderSearchResults';
import Header from 'components/Header/Header';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useUser } from 'utils/hooks/useUser';
import { useWithTitle } from 'utils/hooks/useWithTitle';

const Orders: React.FC = () => {
  const { title } = useWithTitle();
  const { hasRole } = useUser();
  const { compactMode } = useAppInfo();
  const [rows, setRows] = useState<any[]>([]);
  // const shouldShowGrid = isArray(rows) && rows.length ? true : false;
  const canAddUrl = hasRole('ROLE_MWL_NEW');
  useWithTitle(); // sets title to document

  const renderedSteps = () => {
    return <TourOrders canAddUrl={canAddUrl} type="main" />;
  };

  return (
    <>
      <Header
        title={title}
        addUrl={canAddUrl ? '/orders/create' : ''}
        TourComponent={renderedSteps()}
      />
      <OrderSearchForm setRows={setRows} searchAutomatically={false} compactMode={compactMode} />
      <OrderSearchResults rows={rows} setRows={setRows} dataTour="orders-grid" />
    </>
  );
};

export default Orders;

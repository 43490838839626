import React from 'react';
import StudiesDistributeDetail from 'modules/Studies/StudiesDistribute/StudiesDistributeDetail';
import { IRequestsDistributeDetail } from './_types';
import { MOVE } from 'constants/constants';

const RequestsMoveDetail: React.FC<IRequestsDistributeDetail> = () => (
  <StudiesDistributeDetail isRequest={true} requestAction={MOVE} />
);

export default RequestsMoveDetail;

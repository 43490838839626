import React, { useCallback, useEffect, useRef, useState } from 'react';
import { get, isArray, isEmpty, omit, omitBy } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { IRequestDialog, IRequestDialogFieldDefinition, IRequestDialogSearchForm } from './_types';
import {
  CustomColumnMenu,
  CustomGridToolbar,
  useRequestDialogColumnsDataGridPro,
} from './_requestDialogGrid';
import useValidationSchema from 'modules/Orders/_formSearch';
import { findOrderWithCancel } from 'modules/Orders/_api';

import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { convertSearchDates } from 'utils/search';

import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormDatePicker from 'components/Form/Date/Date';
import Button from 'components/Buttons/Button';

import {
  DATA_GRID_DEFAULT_SORTING,
  DATA_GRID_ROW_HEIGHT,
  DATE_BTN_CUSTOM_DATE,
  DATE_BTN_LAST_MONTH,
  DATE_BTN_LAST_THREE_DAYS,
  DATE_BTN_LAST_WEEK,
  DATE_BTN_TODAY,
  DATE_BTN_YESTERDAY,
} from 'constants/constants';
import { CloseButtonDialog } from 'components/Dialog/CloseDialogButton';
import { DataGridPro, GridRowModel, GridSortModel } from '@mui/x-data-grid-pro';
import { uid } from 'uid';
import { parseISO } from 'date-fns';
import useGridLocalization from 'components/SearchResultsMUI/useGridLocalization';

const apiDateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss";
const apiDateFormat = 'yyyyMMdd';

export const RequestDialog: React.FC<IRequestDialog> = ({
  actions,
  toggleDialog,
  reset: resetParentForm,
  setValue: setValueParentForm,
  watchAllFields,
  setOrderSelected,
}) => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('Studies');
  const { toggleLoader } = useAppGlobals();

  const [rows, setRows] = useState<any>(null);

  const gridLocalization = useGridLocalization();

  const initialSortModel: GridSortModel = [
    {
      field: 'originalDatetimeRequested',
      sort: 'desc',
    },
  ];
  const [sortModel, setSortModel] = useState<GridSortModel>(initialSortModel);
  const [hasCustomDate, setHasCustomDate] = useState(false);

  const { validators } = useAppInfo();
  const validators2 = get(validators, 'searchStudyOrderForm');

  const { SearchFormSchema, fields } = useValidationSchema(t, validators2);

  const methods = useForm<IRequestDialogSearchForm>({
    resolver: yupResolver(SearchFormSchema),
    defaultValues: { dateFrom: undefined, dateTo: undefined, date: DATE_BTN_CUSTOM_DATE },
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { isSubmitting },
  } = methods;
  const selectedDate = watch('date');
  const formValues = getValues();
  const canSearchAndReset = !isEmpty(omitBy(formValues, isEmpty));

  const dateSelectItems = [
    DATE_BTN_TODAY,
    DATE_BTN_YESTERDAY,
    DATE_BTN_LAST_THREE_DAYS,
    DATE_BTN_LAST_WEEK,
    DATE_BTN_LAST_MONTH,
    DATE_BTN_CUSTOM_DATE,
  ].map((id) => ({ id, label: t(`SearchForm:${id}`) }));

  useEffect(() => {
    if (selectedDate === DATE_BTN_CUSTOM_DATE) {
      setHasCustomDate(true);
    } else {
      setHasCustomDate(false);

      // Set dates if selected date is not custom
      if (selectedDate) {
        const dateAttributes = convertSearchDates(
          selectedDate,
          get(formValues, 'dateFrom'),
          get(formValues, 'dateTo'),
          apiDateFormat,
        );
        setValue('dateFrom', new Date(parseISO(dateAttributes.dateFrom)));
        setValue('dateTo', new Date(parseISO(dateAttributes.dateTo)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const submitHandler = async (values: IRequestDialogSearchForm) => {
    toggleLoader();
    setRows(null);
    if (selectedDate) {
      const dateAttributes = convertSearchDates(
        selectedDate,
        get(values, 'dateFrom', null),
        get(values, 'dateTo', null),
        apiDateTimeFormat,
      );
      values = { ...values, from: dateAttributes.dateFrom, to: dateAttributes.dateTo };
    }
    const transformedValues = omitBy(
      omit(values, ['date', 'dateFrom', 'dateTo', 'extraModality']),
      isEmpty,
    );

    await findOrderWithCancel(transformedValues /* , (window as any).axiosSource.token */).then(
      (response) => {
        if (isArray(response)) {
          const items = response.map((item, index) => ({ ...item, id: index }));
          setRows(items);
        } else {
          setRows([]);
        }
      },
      () => {
        setRows([]);
      },
    );
    toggleLoader(false);
  };

  const triggerSubmit = () => {
    refSubmitButtom?.current?.click();
  };

  useEffect(() => {
    const keyboardListener = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        triggerSubmit();
      }
    };
    document.body.addEventListener('keydown', keyboardListener);
    return () => {
      document.body.removeEventListener('keydown', keyboardListener);
    };
  }, []);

  //TODO: With real data, delete uuidv1 and use only iid from data
  const getRowId = useCallback((row: GridRowModel) => (row.iid ? row.iid : uid()), []);

  const handleCloseDialog = () => {
    toggleDialog(false);
    setRows(null);
    reset();
  };

  const colsPro = useRequestDialogColumnsDataGridPro({
    handleCloseDialog,
    resetParentForm,
    setValueParentForm,
    enableSortingAllFields: true,
    actions: actions,
    data: watchAllFields,
    setOrderSelected,
  });
  const mainSx = { width: { xs: '100%', md: '50%', lg: '20%' } };
  const mainSxForDate = { width: { xs: '100%', md: '50%', lg: '40%' } };

  return (
    <Dialog
      open={true}
      onClose={() => toggleDialog(false)}
      aria-labelledby="form-tequest-dialog-title"
      maxWidth="lg"
      fullWidth={true}
      PaperProps={{ style: { minHeight: 500 } }}
    >
      <DialogTitle id="form-dialog-title">{t('findOrder')}</DialogTitle>
      <CloseButtonDialog onClose={() => toggleDialog(false)} />
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <button hidden={true} ref={refSubmitButtom} type={'submit'} disabled={isSubmitting} />
            <Papeer bottomMargin={false}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: {
                    xs: 'wrap',
                    lg: 'nowrap',
                  },
                  paddingRight: { xs: '0px', sm: 0 },
                }}
                data-tour="fieldsTours"
              >
                {fields.map((field: IRequestDialogFieldDefinition) => (
                  <Box sx={mainSx} key={field.name}>
                    <Grid item={true} xs={12} md={4} lg={3}>
                      <FormInput {...field} type="text" />
                    </Grid>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: {
                    xs: 'wrap',
                    lg: 'nowrap',
                  },
                  paddingRight: { xs: '0px', sm: 0 },
                }}
              >
                <Box sx={mainSxForDate}>
                  <FormSelect
                    name="date"
                    label={t('orderDate')}
                    items={dateSelectItems}
                    sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    clearable={true}
                    clearCallback={() => {
                      setValue('dateFrom', null);
                      setValue('dateTo', null);
                    }}
                  />
                </Box>
                <Box sx={mainSx}>
                  <FormDatePicker
                    name="dateFrom"
                    label={t('from')}
                    sx={{ borderRadius: 0 }}
                    disabled={!hasCustomDate}
                  />
                </Box>
                <Box sx={mainSx}>
                  <FormDatePicker
                    name="dateTo"
                    label={t('to')}
                    sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    disabled={!hasCustomDate}
                  />
                </Box>
              </Box>

              <Grid container={true} justifyContent="flex-end">
                <Grid item={true} xs={12} lg={4} xl={3}>
                  <Box sx={{ mt: 1 }} display="flex" gap={1} justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="inherit"
                      type="button"
                      disabled={!canSearchAndReset}
                      onClick={() => {
                        reset();
                        setValue('date', '');
                      }}
                    >
                      {t('reset')}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!canSearchAndReset}
                      className="search-form-button"
                      data-tour="searchButtonTours"
                    >
                      {t('find')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Papeer>
          </form>
        </FormProvider>
        {rows && (
          <Papeer>
            <DataGridPro
              sortingOrder={DATA_GRID_DEFAULT_SORTING}
              headerFilters={true}
              getRowId={getRowId}
              rowHeight={DATA_GRID_ROW_HEIGHT}
              autoHeight={isEmpty(rows)}
              rows={rows}
              columns={colsPro}
              disableRowSelectionOnClick={true}
              localeText={gridLocalization}
              hideFooter={true}
              sx={{
                '& .bold-row': { fontWeight: 'bold' },
              }}
              getRowClassName={(params) => (params.row.state === 'NEW' ? 'bold-row' : '')}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              slots={{ toolbar: CustomGridToolbar, columnMenu: CustomColumnMenu }}
              slotProps={{
                headerFilterCell: {
                  InputComponentProps: {
                    size: 'small',
                  },
                },
              }}
            />
          </Papeer>
        )}
      </DialogContent>
    </Dialog>
  );
};

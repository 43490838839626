import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isArray, sortBy } from 'lodash';
import { getAllAddressBook } from 'modules/Mdex/AddressBook/_api';
import { IAddressBook } from 'modules/Mdex/AddressBook/_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import FormAutocomplete from 'components/Form/Autocomplete/Autocomplete';

export const useFetchAddressBooksAndRenderRecipients = (
  shouldReturnRecipients: boolean = false,
) => {
  const { t } = useTranslation('Mdex');
  const { toggleLoader } = useAppGlobals();
  const [addressBooks, setAddressBooks] = useState<IAddressBook[]>([]);
  const [renderedRecipients, setRenderedRecipients] = useState<any>();

  const loadAddressBooks = async () => {
    toggleLoader();
    try {
      const addressBooks = await getAllAddressBook();
      if (addressBooks && isArray(addressBooks)) {
        setAddressBooks(addressBooks);
        if (shouldReturnRecipients) {
          const sortedAddressBooks = sortBy(
            addressBooks.map((item: IAddressBook) => {
              const value = item.uuid;
              const label = item.institutionName;
              return { value, label };
            }),
            ['label'],
          );

          setRenderedRecipients(
            <FormAutocomplete
              name="institutionName"
              label={t('institutionName')}
              options={sortedAddressBooks}
              placeholder={t('select')}
              required={true}
            />,
          );
        }
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadAddressBooks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { addressBooks, loadAddressBooks, renderedRecipients };
};

import { forEach } from 'lodash';

const style = { color: 'rgba(0, 0, 0, 0.87)' };

export const useStepsReactour = ({ allSteps }: { allSteps: any }) => {
  const steps: any = [];
  forEach(allSteps, (value, key) => {
    steps.push({
      selector: key,
      content: ({ setCurrentStep, step }: any) => {
        return <div style={style}>{value?.content ? value?.content : value}</div>;
      },
      action: value?.action ? value.action : {},
      highlightedSelectors: value?.highlightedSelectors ? value.highlightedSelectors : [],
    });
  });

  return steps;
};

import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { find, get, isArray, isEmpty, compact } from 'lodash';
import { SearchForm } from 'components/SeachForm/SearchForm';
import { SearchResultsMUI } from 'components/SearchResultsMUI/SearchResultsMUI';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { ISearch, IStudyResultForGrid } from './_types';
import { useActions } from 'utils/hooks/useActions';
import { useSubmitSearchForm } from 'utils/hooks/useSubmitSearchForm';
import {
  ARCHIVE,
  BASKET,
  COPY,
  DETAIL,
  DOSE_MONITORING,
  DOSE_MONITORING_EXPORT,
  EXPORT,
  LIST,
  MOVE,
  MOVE_TO_FOLDER,
  MOVE_TO_TRASH,
  PROPOSAL_SHREDDING,
  SEARCH_FUNCTION_CHANGE_STATUSES,
  SEARCH_FUNCTION_EDIT_PATIENT,
  SEARCH_FUNCTION_EDIT_STUDY,
  SEARCH_FUNCTION_REORDER,
  SEARCH_FUNCTION_SPLIT,
  SEND,
  TAB_OPERATIONS,
  VIEWER,
  ECG,
} from 'constants/constants';
import { decodeIID } from 'utils/study';
import { ChangeStatusesDialog } from 'modules/Studies/StudyDetail/ChangeStatusesDialog';
import { StudiesActionDialog } from './StudiesActionDialog';
import { useStudy } from 'utils/hooks/useStudy';
import useAlerts from 'components/Alerts/useAlerts';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { ReorderSourceStudyInfo } from './ReorderSourceStudyInfo';
import { Box } from '@mui/material';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useWithTitle } from 'utils/hooks/useWithTitle';

const actions = [
  DETAIL,
  ARCHIVE,
  BASKET,
  VIEWER,
  ECG,
  SEND,
  EXPORT,
  COPY,
  MOVE,
  MOVE_TO_FOLDER,
  PROPOSAL_SHREDDING,
  SEARCH_FUNCTION_REORDER,
  SEARCH_FUNCTION_SPLIT,
  SEARCH_FUNCTION_EDIT_STUDY,
  SEARCH_FUNCTION_EDIT_PATIENT,
  MOVE_TO_TRASH,
  SEARCH_FUNCTION_CHANGE_STATUSES,
  DOSE_MONITORING_EXPORT,
  DOSE_MONITORING,
];

export const SearchList: React.FC<ISearch> = () => {
  const { t } = useTranslation('Studies');
  const { setBulkStudies } = useActions();
  const { searchResults } = useAppInfo();
  let [searchParams] = useSearchParams();
  const type = searchParams.get('type') || null;
  useWithTitle();

  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);
  const [changeStatusesDialog, setChangeStatusesDialog] = useState<boolean>(false);
  const [RenderedChangeStatusesDialog, setRenderedChangeStatusesDialog] =
    useState<JSX.Element | null>(null);
  const [proposalShreddingDialog, setProposalShreddingDialog] = useState<boolean>(false);
  const [RenderedProposalShreddingDialog, setRenderedProposalShreddingDialog] =
    useState<JSX.Element | null>(null);
  const [studiesArchivationDialog, setStudiesArchivationDialog] = useState<boolean>(false);
  const [RenderedStudiesArchivationDialog, setRenderedStudiesArchivationDialog] =
    useState<JSX.Element | null>(null);
  const [studiesDeletionDialog, setStudiesDeletionDialog] = useState<boolean>(false);
  const [RenderedStudiesDeletionDialog, setRenderedStudiesDeletionDialog] =
    useState<JSX.Element | null>(null);

  const { deleteStudies, studyArchivation, studiesProposalsShredding } = useStudy();
  const { addErrorAlert, addSuccessAlert, addInfoAlert } = useAlerts();

  const { onSubmitSearchForm } = useSubmitSearchForm(rows, setRows);

  const onSubmit = async (values: any) => {
    onSubmitSearchForm({ ...values, source: { sources: compact(values.source.sources) } });
  };

  useEffect(() => {
    if (!isEmpty(searchResults)) {
      setRows(searchResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When selection changes, store selected studies to store
  useEffect(() => {
    setBulkStudies({
      type: LIST,
      items: isArray(selection) ? selection.map((iid) => find(rows, { iid })) : [],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  useEffect(() => {
    return () => {
      //zrušení načítání studií (pokud se ještě načítají) přes getStudies při zrušení komponenty
      (window as any).myAbortController.abort();
      (window as any).myAbortController = new AbortController();
    };
  }, []);

  const callFunctionByName = (nameFunction: string, item: string | null = null) => {
    const items = item ? [item] : selection;
    const studies = items.map((select, index) => {
      const findStudy = find(rows, { iid: select });
      const study = decodeIID(select as string);
      const studyID = get(study, 'studyID', '');
      const archiveID = get(study, 'archiveID', '');
      return {
        studyInstanceUid: studyID,
        archive: { id: archiveID },
        medoroStudyFlags: findStudy ? get(findStudy, 'medoroStudyFlags', []) : [],
      };
    });
    setItems(studies);
    if (nameFunction === 'getChangeStatusesDialog') {
      setChangeStatusesDialog(true);
    }
    if (nameFunction === 'proposalShredding') {
      setProposalShreddingDialog(true);
    }
    if (nameFunction === 'archive') {
      setStudiesArchivationDialog(true);
    }
    if (nameFunction === 'moveToTrash') {
      setStudiesDeletionDialog(true);
    }
  };

  const handleLifeCycle = useCallback(
    async (studyID: string, archiveID: string, firstAction: boolean) => {
      setRefreshForm(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const proposalShredding = async () => {
    studiesProposalsShredding({
      studies: items,
      t,
      addInfoAlert,
      addSuccessAlert,
      addErrorAlert,
      callback: handleLifeCycle,
    });
  };

  const archive = async () => {
    studyArchivation({
      studies: items,
      t,
      addSuccessAlert,
      addErrorAlert,
      callback: handleLifeCycle,
    } as any);
  };

  const moveToTrash = async () => {
    deleteStudies({
      studies: items,
      t,
      addSuccessAlert,
      addErrorAlert,
      callback: handleLifeCycle,
    } as any);
  };

  useEffect(() => {
    const RenderedChangeStatusesDialog =
      changeStatusesDialog && items ? (
        <ChangeStatusesDialog
          key={TAB_OPERATIONS}
          changeStatusesDialog={changeStatusesDialog}
          setChangeStatusesDialog={setChangeStatusesDialog}
          studies={items}
          callback={handleLifeCycle}
        />
      ) : null;
    setRenderedChangeStatusesDialog(RenderedChangeStatusesDialog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length, changeStatusesDialog]);

  useEffect(() => {
    const RenderedProposalShreddingDialog =
      proposalShreddingDialog && items ? (
        <StudiesActionDialog
          key={TAB_OPERATIONS}
          studiesActionDialog={proposalShreddingDialog}
          setStudiesActionDialog={setProposalShreddingDialog}
          action={proposalShredding}
          texts={{
            title: `${t('confirmProposalShreddingTitle')}`,
            dialogContentText: `${t('confirmProposalShredding', { par1: items.length })}`,
          }}
        />
      ) : null;
    setRenderedProposalShreddingDialog(RenderedProposalShreddingDialog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length, proposalShreddingDialog]);

  useEffect(() => {
    const RenderedStudiesArchivationDialog =
      studiesArchivationDialog && items ? (
        <StudiesActionDialog
          key={TAB_OPERATIONS}
          studiesActionDialog={studiesArchivationDialog}
          setStudiesActionDialog={setStudiesArchivationDialog}
          action={archive}
          texts={{
            title: `${t('confirmArchivingStudiesTitle')}`,
            dialogContentText: `${t('confirmArchivingStudies', { par1: items.length })}`,
          }}
        />
      ) : null;
    setRenderedStudiesArchivationDialog(RenderedStudiesArchivationDialog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length, studiesArchivationDialog]);

  useEffect(() => {
    const RenderedStudiesDeletionDialog =
      studiesDeletionDialog && items ? (
        <StudiesActionDialog
          key={TAB_OPERATIONS}
          studiesActionDialog={studiesDeletionDialog}
          setStudiesActionDialog={setStudiesDeletionDialog}
          action={moveToTrash}
          texts={{
            title: `${t('confirmMoveToTrashTitle')}`,
            dialogContentText: `${t('confirmMoveToTrash', { par1: items.length })}`,
          }}
        />
      ) : null;
    setRenderedStudiesDeletionDialog(RenderedStudiesDeletionDialog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length, studiesDeletionDialog]);

  const { sourceStudyForReorderIsInStore } = useStudyInfo();
  return (
    <>
      {/* Search TOP */}
      <Box>
        {sourceStudyForReorderIsInStore ? <ReorderSourceStudyInfo /> : null}
        <SearchForm
          onSubmit={onSubmit}
          refreshForm={refreshForm}
          setRefreshForm={setRefreshForm}
          type={type}
          showFilterInHeader={true}
          showActionsInHeader={sourceStudyForReorderIsInStore ? false : true}
          showTourForReorder={sourceStudyForReorderIsInStore ? true : false}
          ownTitle={
            sourceStudyForReorderIsInStore
              ? `${t('studyReorderTitle')} / ${t('searchTargetStudy')}`
              : ''
          }
        />
      </Box>

      {/* Search Results */}
      <SearchResultsMUI
        rows={rows}
        setRows={setRows}
        actions={sourceStudyForReorderIsInStore ? [SEARCH_FUNCTION_REORDER, VIEWER] : actions}
        selecting={!sourceStudyForReorderIsInStore}
        selection={selection}
        setSelection={setSelection}
        operations={{ callFunctionByName }}
      />
      {RenderedChangeStatusesDialog}
      {RenderedProposalShreddingDialog}
      {RenderedStudiesArchivationDialog}
      {RenderedStudiesDeletionDialog}
    </>
  );
};

import { get } from 'lodash';
import { IStudy } from 'modules/Search/_types';
import { api } from 'utils/hooks/useApi';
// import { ISearchValues } from '../../components/SeachForm/_types';

export const getStudies = (data: any, settings: any): Promise<IStudy[] | false> =>
  api()
    .post('/portal-api/study/getStudies2', data, {
      ...settings,
      signal: (window as any).myAbortController.signal,
    })
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const getStudyAdditionalInfo = (searchData: any): Promise<boolean> =>
  api()
    .post(`/portal-api/study/getStudyAdditionalInfo`, searchData)
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const exportECG = (productId: any, studyUid: any): Promise<any> =>
  api()
    .get(`/portal-api/studyExportECG/download?productId=${productId}&studyUid=${studyUid}`)
    .then((response) => response)
    .catch(() => {
      return false;
    });

export const setSearchFilter = async (searchFilter: any) => {
  await api()
    .post(`/portal-api/userSetting/saveDefaultArchives`, {
      defaultArchives: JSON.stringify(searchFilter),
    })
    .then((response) => response)
    .catch((thrown) => {
      return false;
    });
};

import React, { useEffect, useState } from 'react';
import { compact, get, isArray, isFunction } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { getStudyStatuses, setStudyStatuses } from './_api';
import { IChangeStatusesForm, IStatuses } from './_types';
import { getMedoroIcon } from './_icons';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';
import useAlerts from 'components/Alerts/useAlerts';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { TourStudyDetail } from './TourStudyDetail';
import { useDispatch } from 'react-redux';
import { setTourControlPropsToStore } from 'store/reducers/appReducer';

const ChangeStatusesForm: React.FC<IChangeStatusesForm> = ({
  toggleDialog,
  studies,
  handleLifeCycle,
  callback,
}) => {
  const { t } = useTranslation('StudyDetail');
  const navigate = useNavigate();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { toggleLoader } = useAppGlobals();
  const [statuses, setStatuses] = useState<IStatuses[]>([]);
  const { pathname } = useLocation();
  const methods = useForm<any>();
  const { handleSubmit, reset } = methods;
  const dispatch = useDispatch();

  const clearAction = () => {
    toggleDialog();
    dispatch(setTourControlPropsToStore({ isTourOpen: false }));
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const prepareValues = {
      studies: studies.map((study) => ({
        studyInstanceUid: get(study, 'studyInstanceUid', ''),
        archive: {
          id: get(study, 'archive.id', 0),
        },
      })),
      statuses: get(values, 'statuses', []).map((stat: any) => ({
        uuid: get(stat, 'id'),
      })),
    };

    const response = await setStudyStatuses(prepareValues);
    if (response) {
      addSuccessAlert(t('successSetStudyStatuses'));
      clearAction();
      if (isFunction(handleLifeCycle) && studies.length === 1) {
        handleLifeCycle(
          get(studies[0], 'studyInstanceUid', ''),
          get(studies[0], 'archive.id') as unknown as string,
          true,
        );
      } else if (isFunction(callback)) {
        callback();
      } else {
        const linkBack = pathname;
        navigate(`${linkBack}?action=back`);
      }
    } else {
      addErrorAlert(t('errorSetStudyStatuses'));
    }
    toggleLoader(false);
  });

  const loadStudyStatuses = async () => {
    toggleLoader();
    const statuses = await getStudyStatuses();
    if (isArray(statuses)) {
      setStatuses(
        statuses.map((status) => {
          const name = get(status, 'name', '');
          const title = name.charAt(0) === '{' ? t(name.slice(1, -1)) : name;
          const renderIcon = getMedoroIcon(status.icon);
          return { id: status.uuid, name: title, renderIcon };
        }),
      );
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadStudyStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let selectedStatuses: any[] = [];
    if (studies.length === 1 && studies) {
      selectedStatuses = compact(get(studies[0], 'medoroStudyFlags', [])).map((stat: any) => ({
        id: stat,
      }));
    }
    reset({ statuses: selectedStatuses });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studies]);

  const renderedSteps = () => {
    return <TourStudyDetail type="statusDialog" />;
  };

  return (
    <>
      <Header title={t('action.changeStatuses')} TourComponent={renderedSteps()} />
      <Papeer>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Grid container={true} alignItems="flex-end">
              <Grid item={true} xs={12} data-tour="study-detail-status-dialog-choices">
                <SimpleCheckboxArray
                  name="statuses"
                  label={t('studyStatuses')}
                  items={statuses}
                  grid={{ xs: 12, md: 6, lg: 4 }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <EntityButtons
                  confirmReplaceSave={true}
                  toggleDialog={clearAction}
                  otherStyles={{ marginTop: 16 }}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Papeer>
    </>
  );
};

export default ChangeStatusesForm;

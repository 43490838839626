import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  get,
  pickBy,
  omit,
  find,
  pick,
  each,
  compact,
  keys,
  isFunction,
  isEmpty,
  isArray,
} from 'lodash';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import useValidationSchema from './_form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DATE_BTN_CUSTOM_DATE,
  SEARCH_FUNCTION_ALL,
  SEARCH_FUNCTION_REORDER,
} from 'constants/constants';
import { convertSearchDates, convertSearchTimes } from 'utils/search';

import { DATE_BTN_LAST_24_HOURS } from 'constants/constants';
import { SearchHeader } from 'components/SearchHeader/SearchHeader';
import { getStudy } from 'modules/Studies/StudyDetail/_api';
import { ISearchForm, ISearchFormValues, ISelectedSource } from './_types';
import { IStudy } from 'modules/Studies/StudyDetail/_types';
import { useSearch } from 'utils/hooks/useSearch';
import { IArchive } from 'modules/Search/_types';
import SearchFormInner from './SearchFormInner';
import FormFilterDialog from './FormFilterDialog';
import { useUser } from 'utils/hooks/useUser';
import { useActions } from 'utils/hooks/useActions';
import { useStorage } from 'utils/hooks/useStorage';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import { isDate } from 'date-fns';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { TourSearchList } from 'modules/Search/TourSearchList';
import useAlerts from 'components/Alerts/useAlerts';

const listParamsFromPatient2Study = ['accessionNumber', 'description', 'referringPhysician'];
const apiDateFormat = 'yyyyMMdd';
const apiTimeFormat = 'yyyyMMddHHmmss';

export const SearchForm: React.FC<ISearchForm> = ({
  onSubmit,
  useForOtherForm = false,
  refreshForm,
  setRefreshForm,
  type = null,
  isForModal = false,
  showFilterInHeader = false,
  showActionsInHeader = false,
  showTourForReorder,
  ownTitle = '',
  datafromToken,
  setSelectedStudyByToken,
  canUseDataFromToken,
  setCanUseDataFromToken,
}) => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('SearchForm');
  const { hasRole } = useUser();
  const { getItem, putItem, removeItem } = useStorage();
  const { addErrorAlert } = useAlerts();
  /**
   * Pokud jsou v local storage ulozeny hodnoty vyhledavaciho formulare a v nem je Datum vysetreni
   * (dateValue === DATE_BTN_CUSTOM_DATE), pak se po zobrazení formulare zaroven otevre i extended form
   * pro vyhledavani dle dalsich moznosti.
   * Tento extended form ale po nacteni a predvyplneni hodnot z local storage ma byt zavreny. Dosahneme
   * toho dvojim pruchodem useEffect s naslednym zavrenim extended formulare - hlidano pocitadlem myCount
   */
  const [myCount, setMyCount] = useState<number>(0);
  const [searchFieldOpened, setSearchFieldOpened] = useState(false);
  const [hasCustomDate, setHasCustomDate] = useState(false);
  const [filterDialogState, setFilterDialogState] = useState<boolean>(false);
  const { validators, getArchivesForFunctions, getDefaultSources } = useAppInfo();
  const { sourceStudyForReorderIsInStore } = useStudyInfo();
  const { getParsedSearchFilter } = useSearch();
  const [canSearch, setCanSearch] = useState(false);
  // Pro možnost zresetovat výběr filtru v SearchHeader při použití buttonu Vyčistit (reset) v SearchFormInner
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>('');
  const [disableCreateFilter, setDisableCreateFilter] = useState<boolean>();

  const canCreateFilter = hasRole('ROLE_CREATE_FILTER');

  const sources: { name: string; id: number }[] = getArchivesForFunctions(
    sourceStudyForReorderIsInStore ? SEARCH_FUNCTION_REORDER : SEARCH_FUNCTION_ALL,
  ).map((archive) => pick(archive, ['name', 'id']));

  const { SearchStudyFormSchema } = useValidationSchema(t, validators);

  const { resetStudyStore } = useActions();

  const methods = useForm<ISearchFormValues>({
    resolver: yupResolver(SearchStudyFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      patient: {
        id: '',
        firstName: '',
        middleName: '',
        lastName: '',
        sex: '',
        dateBirthFrom: null,
        dateBirthTo: null,
        description: '',
        referringPhysician: '',
      },
      study: {
        accessionNumber: '',
        dateFrom: null,
        dateTo: null,
      },
      studyDate: '',
      showInactiveGroups: false,
      withoutTags: false,
    },
  });

  const { handleSubmit, watch, setValue, reset } = methods;

  const allFormValues = watch();
  const dateValue = watch('studyDate');
  const searchWithoutTags = watch('withoutTags');
  const selectedSources = watch('sources');

  const checkValuesToAllowSubmit = () => {
    const { patient, study, studyDate, sources, modalities } = allFormValues;
    const arrayToCheck = [
      ...Object.values(patient),
      ...Object.values(study),
      studyDate === DATE_BTN_CUSTOM_DATE ? '' : studyDate,
    ];
    //No field is filled out or sources are empty -> disable search
    const canSearchOrCreateFilter = !(
      arrayToCheck.every((value) => !value) ||
      (sources && sources.length === 0)
    );
    return {
      canSearchCheck: canSearchOrCreateFilter || (isArray(modalities) && modalities.length > 0),
      onlyModalitiesInSubmittedValues: !canSearchOrCreateFilter && modalities,
    };
  };

  useEffect(() => {
    const { canSearchCheck } = checkValuesToAllowSubmit();
    // Musí být vyplněno minimálně jedno pole z formuláře pro vyhledávání vyšetření,
    // aby se mohlo vyhledávat/uložit filtr. Pokud je ale vybrána modalita, pak musí být vyplněno
    // ještě jedno jiné pole. Proto modalitu nekontrolovat.
    // Pokud je studyDate = DATE_BTN_CUSTOM_DATE, pak také ignorovat (pak se kontrolují datumy od/do).
    setCanSearch(canSearchCheck);
    setDisableCreateFilter(!canSearchCheck);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFormValues]);

  useEffect(() => {
    const defaultSources = compact(
      getDefaultSources(
        sourceStudyForReorderIsInStore ? SEARCH_FUNCTION_REORDER : SEARCH_FUNCTION_ALL,
      ).map((sourceId) => {
        const defaultSource = pick(find(sources, { id: sourceId }), ['name', 'id']);
        return !isEmpty(defaultSource) ? defaultSource : null;
      }),
    );

    setValue('sources', defaultSources as { name: string; id: number }[]);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sources?.length]);

  const getStudyByToken = async (selectedSources: ISelectedSource[], studyInstanceUid: string) => {
    let allStudies: IStudy[] = [];
    try {
      await Promise.all(
        (selectedSources || []).map(async (source: ISelectedSource) => {
          const study = await getStudy(get(source, 'id').toString(), studyInstanceUid);
          if (study) {
            allStudies.push(study);
          }
        }),
      );
    } catch (e) {
      console.debug(e);
    }
    const lengthAllStudies = allStudies.length;

    if (lengthAllStudies === 0) {
      addErrorAlert(t('studyNotFound'));
    } else if (lengthAllStudies > 1) {
      addErrorAlert(t('multipleStudies'));
    } else {
      setSelectedStudyByToken({ ...allStudies[0] });
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedSources) && !isEmpty(datafromToken) && canUseDataFromToken) {
      if (get(datafromToken, 'addToStudy', false)) {
        const studyInstanceUid = get(datafromToken, 'studyIUID');
        const patientID = get(datafromToken, 'patientID');
        const accessionNumber = get(datafromToken, 'accessionNumber');
        if (studyInstanceUid) {
          getStudyByToken(selectedSources, studyInstanceUid);
          setCanUseDataFromToken(false);
        } else if (patientID && accessionNumber) {
          setValue('patient.id', patientID);
          setValue('study.accessionNumber', accessionNumber);

          refSubmitButtomClick();
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSources?.length, datafromToken]);

  const hasLast24hours = dateValue === DATE_BTN_LAST_24_HOURS;

  const prepareSearchValues = (values: any) => {
    const referringPhysician = get(values, 'patient.referringPhysician', '');
    setSearchFieldOpened(false);
    let dateAttributes = {};
    if (dateValue) {
      if (hasLast24hours) {
        dateAttributes = convertSearchTimes(dateValue, apiTimeFormat);
      } else {
        dateAttributes = convertSearchDates(
          dateValue,
          get(values, 'study.dateFrom'),
          get(values, 'study.dateTo'),
          apiDateFormat,
        );
      }
    }

    const tempTags = values.tags ? Object.values(values.tags) : [];
    let selectedTags: any = [];

    if (!searchWithoutTags) {
      tempTags.forEach((tags: any) => {
        if (!isEmpty(tags)) {
          tags.forEach((tag: any) => {
            selectedTags.push({
              id: tag.value,
            });
          });
        }
      });
    }

    const transformedValues = {
      searchLevel: 'study',
      source: {
        sources: get(values, 'sources', [])
          .map((source: any) => source.id)
          .filter((id: any) => id !== undefined),
      },
      patient: values.patient
        ? pickBy({
            ...omit(values.patient, listParamsFromPatient2Study),
          })
        : {},
      study: values.study
        ? {
            ...values.study,
            modalities: values.modalities ? values.modalities : null,
            ...(dateValue ? dateAttributes : {}),
            ...(referringPhysician ? { referringPhysician } : {}),
          }
        : { ...(referringPhysician ? { referringPhysician } : {}) },
      tags: get(values, 'withoutTags', false)
        ? []
        : selectedTags.length > 0
        ? selectedTags
        : undefined,
    };

    return transformedValues;
  };

  const refSubmitButtomClick = () => {
    // musíme počkat než se do formuláře promítnou data nastavená pomocí setValue, proto setTimeout
    // ještě by byla možnost vzít přímo values z filtru a použít onSubmit, pokud by se u stávající řešení objevila chyba
    setTimeout(() => {
      refSubmitButtom?.current?.click();
    }, 50);
  };
  const searchWithFilter = useCallback(
    async (id: number) => {
      const filter = await getParsedSearchFilter(id);
      if (filter !== undefined) {
        reset();
        each(filter, (item, index) => {
          if (index === 'source') {
            const allowedAndFilteredSources: { name: string; id: number }[] = [];
            each(get(item, 'sources', []) as IArchive[], (filterSource) => {
              const foundSource = find(sources, { id: filterSource.id });
              if (foundSource) {
                allowedAndFilteredSources.push(foundSource);
              }
            });
            setValue('sources', compact(allowedAndFilteredSources));
          } else if (index === 'sources' || index === 'modalities') {
            setValue(index, item);
          } else if (index === 'studyDate') {
            setValue('studyDate', item);
          } else if (index === 'showInactiveGroups') {
            setValue('showInactiveGroups', item);
          } else if (index === 'withoutTags') {
            setValue('withoutTags', item);
          } else if (index === 'tags') {
            each(keys(item), (key) => {
              if (item[key] != null) {
                const name = `${index}.${key}`;
                // @ts-ignore:next-line
                setValue(name, item[key]);
              }
            });
          } else {
            // It has to be set as dot notation, not object 🤷‍♂️
            each(keys(item), (key) => {
              const name = `${index}.${key}`;
              let value = item[key];
              if ((name === 'study.dateFrom' || name === 'study.dateTo') && value) {
                value = isDate(value) ? value : new Date(value);
              }
              if ((name === 'patient.dateBirthFrom' || name === 'patient.dateBirthTo') && value) {
                value = isDate(value) ? value : new Date(value);
              }
              // @ts-ignore:next-line
              setValue(name, value);
            });
          }
        });

        refSubmitButtomClick();
      }
    },
    [getParsedSearchFilter, reset, setValue, sources],
  );

  const showDialogForSaveFilter = () => {
    setFilterDialogState(true);
  };

  useEffect(() => {
    if (refreshForm) {
      refSubmitButtomClick();
      if (isFunction(setRefreshForm)) {
        setRefreshForm(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshForm]);

  // Reset all studies in store when searchform first loaded
  useEffect(() => {
    if (type !== 'reorder') {
      resetStudyStore();
    }
    const buttonRequestJson = getItem('buttonRequestJson');
    const searchFormValues = getItem('searchFormValues');
    if (buttonRequestJson || searchFormValues) {
      const jsonStringValue = buttonRequestJson || searchFormValues;
      try {
        const values = jsonStringValue ? JSON.parse(jsonStringValue) : null;
        if (values) {
          const transformedValues = {
            ...values,
            patient: {
              ...get(values, 'patient', {}),
              dateBirthFrom: get(values, 'patient.dateBirthFrom')
                ? new Date(get(values, 'patient.dateBirthFrom', ''))
                : null,
              dateBirthTo: get(values, 'patient.dateBirthTo')
                ? new Date(get(values, 'patient.dateBirthTo', ''))
                : null,
            },
            study: {
              ...get(values, 'study', {}),
              dateFrom: get(values, 'study.dateFrom')
                ? new Date(get(values, 'study.dateFrom', ''))
                : null,
              dateTo: get(values, 'study.dateTo')
                ? new Date(get(values, 'study.dateTo', ''))
                : null,
            },
          };
          // Instead of reset(), we call setValue for each key
          // Because reset() from reset button then returned to previously reseted values
          each(keys(transformedValues), (key: any) => {
            if (key === 'tags') {
              setValue('tags.active', get(transformedValues, 'tags.active', []));
              setValue('tags.inactive', get(transformedValues, 'tags.inactive', []));
            } else {
              setValue(key, get(transformedValues, key));
            }
          });
          if (buttonRequestJson) {
            /**
             * Pokud je nastavena dlaždice s vyhledáním vyšetření a je nastavena na Automaticky otevírat po přihlášení,
             * pak se následně předvyplní vyhledávací formulář hodnotami z JSON v localStorage 'buttonRequestJson' a
             * smaže se JSON z localeStorage.
             * Dále se nastaví tak, aby se následně odeslal formulář na vyhledání vyšetření.
             */
            removeItem('buttonRequestJson');
            if (isFunction(setRefreshForm)) {
              setRefreshForm(true);
            }
          }
        }
      } catch (e) {
        // do nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dateValue === DATE_BTN_CUSTOM_DATE) {
      if (myCount > -1) {
        setSearchFieldOpened(true);
      }
      setHasCustomDate(true);
    } else {
      setHasCustomDate(false);

      // Set dates if selected date is not custom
      if (dateValue !== '') {
        if (dateValue === DATE_BTN_LAST_24_HOURS) {
          const dateAttributes = convertSearchTimes(dateValue, apiTimeFormat);
          setValue(
            'study.dateFrom',
            new Date(parse(dateAttributes.timeFrom, apiTimeFormat, new Date())),
          );
          setValue(
            'study.dateTo',
            new Date(parse(dateAttributes.timeTo, apiTimeFormat, new Date())),
          );
        } else {
          const dateAttributes = convertSearchDates(
            dateValue,
            get(allFormValues, 'study.dateFrom'),
            get(allFormValues, 'study.dateTo'),
            apiDateFormat,
          );
          setValue('study.dateFrom', new Date(parseISO(dateAttributes.dateFrom)));
          setValue('study.dateTo', new Date(parseISO(dateAttributes.dateTo)));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue]);

  useEffect(() => {
    if (myCount < 2) {
      setSearchFieldOpened(false);
      setMyCount(myCount + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCount]);

  const renderedSteps = () => {
    return TourSearchList({
      isReorderStudyInfo: showTourForReorder,
      showActionsInHeader,
    });
  };

  return (
    <>
      {!useForOtherForm && (
        <SearchHeader
          searchWithFilter={searchWithFilter}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          showFilterInHeader={showFilterInHeader}
          showActionsInHeader={showActionsInHeader}
          ownTitle={ownTitle}
          TourComponent={renderedSteps()}
        />
      )}
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit((values) => {
            if (!canSearch) {
              // prevents search when user hit enter
              return;
            }
            const { onlyModalitiesInSubmittedValues } = checkValuesToAllowSubmit();
            //when only modalities are filled, show error
            if (onlyModalitiesInSubmittedValues) {
              addErrorAlert(t('Errors:additionalFieldRequired'));
              return;
            }
            // Save form values to localStorage
            putItem('searchFormValues', JSON.stringify(values));

            const transformedValues = prepareSearchValues(values);
            // se žádostmi bude povinná fce onSubmit a možno smazat isFunction
            isFunction(onSubmit) ? onSubmit(transformedValues) : console.log({ transformedValues });
          })}
        >
          <button hidden={true} ref={refSubmitButtom} type={'submit'} />
          <SearchFormInner
            sources={sources}
            searchFieldOpened={searchFieldOpened}
            setSearchFieldOpened={setSearchFieldOpened}
            hasCustomDate={hasCustomDate}
            canCreateFilter={canCreateFilter}
            showDialogForSaveFilter={showDialogForSaveFilter}
            canSearch={canSearch}
            disableCreateFilter={disableCreateFilter}
            hideSaveFilterButton={useForOtherForm}
            isForModal={isForModal}
            setSelectedFilter={setSelectedFilter}
          />
        </form>
      </FormProvider>
      <FormFilterDialog
        filterDialogState={filterDialogState}
        setFilterDialogState={setFilterDialogState}
        allFormValues={allFormValues}
      />
    </>
  );
};

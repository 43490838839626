import { get } from 'lodash';

import { api } from 'utils/hooks/useApi';
import { IButton } from './_types';

export const getAll = (): Promise<IButton[]> =>
  api()
    .get(`/portal-api/button/getAll`)
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const getButton = (id: string): Promise<IButton> =>
  api()
    .get(`/portal-api/button/get`, { params: { id } })
    .then((response) => {
      let data = get(response, 'data');
      const languageJsonText = get(data, 'languageJsonText', null);
      if (languageJsonText) {
        data = { ...data, languageJsonText: JSON.parse(languageJsonText) };
      }
      return data;
    })
    .catch(() => false);

export const createButton = (button: IButton): Promise<boolean> =>
  api()
    .post(`/portal-api/button/create`, button)
    .then((response) => true)
    .catch((error) => false);

export const editButton = (button: IButton): Promise<boolean> =>
  api()
    .post(`/portal-api/button/edit`, button)
    .then((response) => true)
    .catch((error) => false);

export const setActiveButton = (id: string, state: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/button/setActive`, { params: { id, state } })
    .then((response) => true)
    .catch((error) => false);

export const removeButton = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/button/remove`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);

export const getAllUnselectedButtons = (): Promise<IButton[]> =>
  api()
    .get(`/portal-api/button/getAllAvailable`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const batchRemove = (values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/button/batchRemove`, values)
    .then((response) => true)
    .catch((error) => false);

export const workPlaceAutoAssign = (userButtonUpdate: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/button/workPlaceAutoAssign`, { params: { userButtonUpdate } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const workPlaceManualAssign = (userButtonUpdate: boolean, values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/button/workPlaceManualAssign?userButtonUpdate=${userButtonUpdate}`, values)
    .then((response) => true)
    .catch((error) => false);

export const workPlaceManualUnassign = (userButtonUpdate: boolean, values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/button/workPlaceManualUnassign?userButtonUpdate=${userButtonUpdate}`, values)
    .then((response) => true)
    .catch((error) => false);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { differenceInSeconds, format } from 'date-fns';
import { Box, Tooltip } from '@mui/material';
import ResultsChart from './ResultsChart';
import { joinParams } from 'utils/study';
import { formatTime } from 'utils/hooks/useDate';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowParams,
  getGridStringOperators,
} from '@mui/x-data-grid';
import GridDetailIcon from '@mui/icons-material/Description';
import { encryptId } from 'utils/hooks/useApp';
import { useNavigate } from 'react-router';
import { testStateFormatter } from './_formatter';
import styles from 'components/SearchResultsMUI/_styles';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'globalStatsExecutedTestMui2';

const GlobalStatsExecutedTests: React.FC<any> = ({ successRate, testExecutions, dataTour }) => {
  const { t } = useTranslation('Tests');
  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);
  const navigate = useNavigate();

  const onCustomEntityDetail = (row: any) => {
    navigate(`/tests/results/${encryptId(get(row, 'id'))}`, {
      state: { linkBack: 'globalStats' },
    });
  };

  const getName = (row: any) => get(row, 'test.name', '') || '';

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['startsWith' /* add more over time */].includes(value),
  );

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 100,
        headerAlign: 'left',
        align: 'left',
        getActions: ({ row }: GridRowParams) => [
          <GridActionsCellItem
            icon={
              <Tooltip title={t('Grid:testResultDetail')}>
                <GridDetailIcon />
              </Tooltip>
            }
            label="Detail"
            onClick={() => onCustomEntityDetail(row)}
          />,
        ],
      },
      {
        field: 'executedBy',
        headerName: t('executedBy'),
        valueGetter: (value: any, row: any) =>
          joinParams([get(row, 'executedBy.firstName'), get(row, 'executedBy.lastName')]),
      },
      {
        field: 'name',
        headerName: t('name'),
        hideable: false,
        renderCell: ({ row }: GridRenderCellParams) => (
          <Tooltip title={getName(row)}>
            <Box onClick={() => onCustomEntityDetail(row)} sx={styles.gridLink}>
              {getName(row)}
            </Box>
          </Tooltip>
        ),
        valueGetter: (value: any, row: any) => getName(row),
      },
      {
        field: 'state',
        headerName: t('testState'),
        filterOperators,
        renderCell: ({ row }: GridRenderCellParams) => testStateFormatter(row, t),
        valueGetter: (value: any, row: any) => t(get(row, 'state', '').toLowerCase()),
      },
      {
        field: 'correctAnswers',
        headerName: t('correctAnswers'),
        valueGetter: (value: any, row: any) =>
          `${get(row, 'correctAnswers')}/${get(row, 'questionsCount')}`,
      },
      {
        field: 'startedWhen',
        headerName: t('startedWhen'),
        type: 'date',
        valueGetter: (value: any, row: any) => {
          try {
            return new Date(get(row, 'startedWhen', ''));
          } catch (e) {
            return null;
          }
        },
        renderCell: ({ row }: GridRenderCellParams) =>
          get(row, 'startedWhen', '')
            ? format(new Date(get(row, 'startedWhen', '')), 'dd.MM.yyyy HH:mm')
            : '',
      },
      {
        field: 'testLength',
        headerName: t('testLength'),
        valueGetter: (value: any, row: any) =>
          formatTime(
            differenceInSeconds(
              new Date(get(row, 'finishedWhen')),
              new Date(get(row, 'startedWhen')),
            ),
            t,
          ),
      },
      {
        field: 'commentsCount',
        headerName: t('commentsCount'),
      },
    ]),
  );
  return (
    <>
      {testExecutions && testExecutions.length > 0 && <ResultsChart successRate={successRate} />}
      <MuiGrid gridKey={muiGridKey} rows={testExecutions} columns={columns} dataTour={dataTour} />
    </>
  );
};

export default GlobalStatsExecutedTests;

import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { TAB_ADD_NEW_DEFINITION } from 'constants/constants';
import { useTranslation } from 'react-i18next';

interface IUseSteps {
  type: 'manage' | 'create' | 'edit' | 'start' | 'result' | 'globalStatistics' | 'detailTest';
  activeTab?: any;
  generalTestType?: any;
  statsType?: string;
}
export const TourTests = ({ type, activeTab, generalTestType, statsType }: IUseSteps) => {
  const { t } = useTranslation('Reactour');

  const allSteps = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="addButton"]': t('tests.addButton'),
      '[data-tour="testsSearchForm"]': t('tests.searchForm'),
      ...(isDropDownOpen && {
        '.select-react-tour-class': t('request.operationType'),
      }),
      '[data-tour="testsGrid"]': t('tests.grid'),
      '[data-tour="testsActions"]': t('tests.actions'),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
    };
    return steps;
  };

  const allCreateSteps = () => {
    const steps = {
      '[data-tour="testCreate"]': t('tests.create'),
      '[data-tour="saveButton"]': t('tests.save'),
      '[data-tour="backButton"]': t('tests.create'),
    };
    return steps;
  };

  const allEditSteps = () => {
    const steps = {
      '[data-tour="testEdit"]': t('tests.edit'),
      '[data-tour="saveButton"]': t('tests.save'),
      '[data-tour="backButton"]': t('tests.create'),
    };
    return steps;
  };

  const allStartSteps = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="testsStartForm"]': t('tests.startForm'),
      ...(isDropDownOpen && {
        '.select-react-tour-class': t('request.operationType'),
      }),
      '[data-tour="testsStartGrid"]': t('tests.startGrid'),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
    };
    return steps;
  };

  const allResultSteps = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="testsResultForm"]': t('tests.resultForm'),
      ...(isDropDownOpen && {
        '.select-react-tour-class': (
          <>
            {t('tests.dateSelect')}
            <br />
            <em>{t('common.quitSelectItems')}</em>
          </>
        ),
      }),
      '[data-tour="testsResultGrid"]': t('tests.resultGrid'),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
    };
    return steps;
  };

  const allGlobalStatisticsSteps = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="testsGlobalStatisticsForm"]': t('tests.globalStatisticsForm'),
      ...(currentOpenDropdown === 'type' &&
        isDropDownOpen && {
          '.select-react-tour-class': (
            <>
              {t('tests.globalStatisticsTypeExecuted')}
              <br />
              {t('tests.globalStatisticsTypeErrorRate')}
              <br />
              <br />
              <em>{t('common.quitSelectItems')}</em>
            </>
          ),
        }),
      ...(statsType === 'EXECUTED' &&
        !currentOpenDropdown &&
        isDropDownOpen && {
          '.autocomplete-react-tour-class': (
            <>
              {t('tests.globalStatisticsUserSelector')}
              <br />
              <em>{t('common.quitSelectItems')}</em>
            </>
          ),
        }),
      ...(statsType === 'EXECUTED' &&
        currentOpenDropdown === 'date' &&
        isDropDownOpen && {
          '.select-react-tour-class': (
            <>
              {t('tests.dateSelect')}
              <br />
              <em>{t('common.quitSelectItems')}</em>
            </>
          ),
        }),
      '[data-tour="testsGlobalStatisticsGrid"]': t('tests.globalStatisticsGrid'),
    };
    return steps;
  };

  const allDetailTestSteps = () => {
    const steps = {
      '[data-tour="testDetailMainSection"]': t('tests.detail.mainSection'),
      '[data-tour="testDetailButtons"]': t('tests.detail.buttons'),
      '[data-tour="testDetailDefinitions"]': t('tests.detail.definitions'),
      ...(activeTab === TAB_ADD_NEW_DEFINITION &&
        generalTestType && {
          '[data-tour="testDetailDefinitionsQuestion"]': t('tests.detail.question'),
          '[data-tour="testDetailDefinitionsOrder"]': t('tests.detail.order'),
          '[data-tour="testDetailDefinitionsAnswer"]': t('tests.detail.answer'),
          '[data-tour="testDetailDefinitionsAddAnswer"]': t('tests.detail.addAnswer'),
          '[data-tour="testDetailDefinitionsAddQuestion"]': t('tests.detail.addQuestion'),
        }),
    };
    return steps;
  };

  let stepsDefinition;
  if (type === 'detailTest') stepsDefinition = allDetailTestSteps;
  if (type === 'globalStatistics') stepsDefinition = allGlobalStatisticsSteps;
  if (type === 'result') stepsDefinition = allResultSteps;
  if (type === 'start') stepsDefinition = allStartSteps;
  if (type === 'edit') stepsDefinition = allEditSteps;
  if (type === 'create') stepsDefinition = allCreateSteps;
  if (type === 'manage') stepsDefinition = allSteps;
  useReactTour({ stepsDefinition });

  return <HelperComponent />;
};

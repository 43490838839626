import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from 'lodash';

export const RedirectToTest: React.FC = () => {
  const params = useParams();
  const id = get(params, 'id', null);
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/tests/manageTests/${id}/detail`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>...</>;
};

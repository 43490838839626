import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { getGeneralQuestions, deleteGeneralQuestion, deleteGeneralAnswer } from './_api';
import { IAnswer } from './_types';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';

import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
} from '@mui/x-data-grid';
import { graphicalBoolFormatter } from './_formatter';
import { Tooltip } from '@mui/material';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { useAppDispatch } from 'store/hooks';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useActions } from 'utils/hooks/useActions';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'generalTestDefinitionsQuestionsMui';

const ExistingGeneralTestTypeDefinitions: React.FC<any> = ({
  id,
  editDefinition,
  fetchQuestions,
  questions,
  isPublished,
}) => {
  const { t } = useTranslation('Tests');
  const { toggleLoader } = useAppGlobals();
  const { confirmationData } = useAppInfo();
  const { storeConfirmationData } = useActions();
  const { onEntityRemove } = useEntityRemove(deleteGeneralAnswer, t);
  const entityProps = useEntityRemove(deleteGeneralQuestion, t);
  const dispatch = useAppDispatch();
  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);

  const loadAllQuestions = async () => {
    toggleLoader();
    const questions = await getGeneralQuestions(id);
    toggleLoader(false);
    fetchQuestions(isArray(questions) ? questions : []);
  };

  const clearAction = () => storeConfirmationData(null);
  const cancelAction = () => {
    clearAction();
  };

  const deleteAnswerConfirmAction = async () => {
    const resp = await onEntityRemove(get(confirmationData, 'id'));
    if (resp) {
      clearAction();
      loadAllQuestions();
    }
  };

  const deleteQuestionConfirmAction = async () => {
    const resp = await entityProps.onEntityRemove(get(confirmationData, 'id'));
    if (resp) {
      clearAction();
      loadAllQuestions();
    }
  };

  useEffect(() => {
    loadAllQuestions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsQuestions = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 185,
        headerAlign: 'left',
        align: 'left',
        getActions: ({ row }: GridRowParams) => [
          <GridActionsCellItem
            icon={
              <Tooltip title={t('Grid:definitionEdit')}>
                <EditIcon />
              </Tooltip>
            }
            label={t('definitionEdit')}
            onClick={() => {
              editDefinition(row);
            }}
            disabled={isPublished}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={t('delete')}>
                <DeleteIcon />
              </Tooltip>
            }
            label={t('delete')}
            onClick={() => {
              dispatch(
                storeConfirmationDataToStore({
                  id: get(row, 'id'),
                  row,
                  confirmAction: 'confirmQuestionRemoveInTest',
                }),
              );
            }}
            disabled={isPublished}
          />,
        ],
      },
      { field: 'position', headerName: t('position') },
      { field: 'label', headerName: t('label') },
    ]),
  );

  const columnsAnswers: GridColDef[] = [
    {
      field: 'actions',
      headerName: t('Grid:actions'),
      type: 'actions',
      hideable: false,
      width: 185,
      headerAlign: 'left',
      align: 'left',
      getActions: ({ row }: GridRowParams) => [
        <GridActionsCellItem
          icon={
            <Tooltip title={t('Grid:definitionEdit')}>
              <EditIcon />
            </Tooltip>
          }
          label={t('definitionEdit')}
          onClick={() => {
            editDefinition(row.question);
          }}
          disabled={isPublished}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title={t('delete')}>
              <DeleteIcon />
            </Tooltip>
          }
          label={t('delete')}
          onClick={() => {
            dispatch(
              storeConfirmationDataToStore({
                id: get(row, 'id'),
                row,
                confirmAction: 'confirmAnswerRemoveInTest',
              }),
            );
          }}
          disabled={isPublished}
        />,
      ],
    },
    { field: 'position', headerName: t('position') },
    { field: 'text', headerName: t('text'), width: 185 },
    {
      field: 'correctness',
      headerName: t('correctness'),
      width: 185,
      renderCell: ({ row }: GridRenderCellParams) =>
        graphicalBoolFormatter(get(row, 'correctness', false)),
    },
  ];

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds);
  }, []);

  const DetailPanel = ({ row }: GridRowParams) => {
    const answers: IAnswer[] = get(row, 'answers', []).map((answer: any) => ({
      ...answer,
      question: row,
    }));
    return isArray(answers) ? (
      <DataGrid
        autoHeight={true}
        rows={answers}
        columns={columnsAnswers}
        hideFooter={true}
        initialState={{
          sorting: {
            sortModel: [{ field: 'position', sort: 'asc' }],
          },
        }}
      />
    ) : null;
  };

  const RenderedGrid = () => {
    return isArray(questions) ? (
      <MuiGrid
        gridKey={muiGridKey}
        rows={questions}
        columns={columnsQuestions}
        rowDetail={{
          showRowDetail: true,
          DetailPanel,
          getDetailPanelHeight: () => 'auto',
          detailPanelExpandedRowIds,
          handleDetailPanelExpandedRowIdsChange: handleDetailPanelExpandedRowIdsChange,
        }}
      />
    ) : null;
  };

  return (
    <>
      <RenderedGrid />
      {confirmationData &&
        confirmationData.id &&
        confirmationData.confirmAction === 'confirmAnswerRemoveInTest' && (
          <ConfirmationDialog
            title={t('confirmDelete')}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={cancelAction}
            confirmAction={deleteAnswerConfirmAction}
          />
        )}

      {confirmationData &&
        confirmationData.id &&
        confirmationData.confirmAction === 'confirmQuestionRemoveInTest' && (
          <ConfirmationDialog
            title={t('confirmDelete')}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={cancelAction}
            confirmAction={deleteQuestionConfirmAction}
          />
        )}
    </>
  );
};

export default ExistingGeneralTestTypeDefinitions;

import { get, includes } from 'lodash';

import { useAppInfo } from './useAppInfo';
import { DEFAULT_LOCALE } from 'constants/constants';
import { useAppSelector } from 'store/hooks';

export const useLanguage = () => {
  const currentLocaleInState = useAppSelector((state) => state.app.locale);
  const currentUserLocaleInState = useAppSelector((state) => state.user.language);
  const { feConfig } = useAppInfo();

  const allowedLocales = get(feConfig, 'allowedLocales');
  const defaultLocale = includes(allowedLocales, navigator.language)
    ? navigator.language
    : get(feConfig, 'defaultLocale', DEFAULT_LOCALE);

  return {
    currentLocale:
      currentUserLocaleInState && includes(allowedLocales, currentUserLocaleInState)
        ? currentUserLocaleInState
        : includes(allowedLocales, currentLocaleInState)
        ? currentLocaleInState
        : defaultLocale,
  };
};

import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';

const useValidationSchema = (t: TFunction) => {
  const { required } = useValidators();

  const LanguageFormSchema = yup.object().shape({
    abbreviation: required(yup.string()),
    name: required(yup.string()),
  });

  const formItems = [
    {
      name: 'abbreviation',
      label: t('abbreviation'),
      required: true,
      disabled: true,
    },
    {
      name: 'name',
      label: t('name'),
      required: true,
    },
    {
      type: 'switch',
      name: 'active',
      label: t('active'),
    },
    {
      type: 'switch',
      name: 'default',
      label: t('default'),
    },
  ];

  return { LanguageFormSchema, formItems };
};

export default useValidationSchema;

import * as yup from 'yup';
//import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';
import { get } from 'lodash';
import {
  DATE_BTN_LAST_24_HOURS,
  DATE_BTN_TODAY,
  DATE_BTN_YESTERDAY,
  DATE_BTN_LAST_THREE_DAYS,
  DATE_BTN_LAST_WEEK,
  DATE_BTN_LAST_MONTH,
  DATE_BTN_CUSTOM_DATE,
} from 'constants/constants';
import useValidators from 'components/Form/validations/useValidators';
import { IValidators } from 'modules/Login/_types';

const useValidationSchema = (t: TFunction, validators: IValidators) => {
  const { minNotRequired, max, required /*, ifModalitiesNotEmptyRequireAdditionalField*/ } =
    useValidators();
  const patientIdMax = parseInt(
    get(validators, 'searchStudyForm.patient.identification.max') as unknown as string,
    10,
  );

  const SearchStudyFormSchema = yup.object().shape(
    {
      patient: yup.object().shape({
        id: max(yup.string(), !isNaN(patientIdMax) ? patientIdMax : 64),
        lastName: minNotRequired(yup.string(), 2),
        dateBirthFrom: yup.date().typeError(t('Errors:invalidDate')).nullable(),
        dateBirthTo: yup.date().typeError(t('Errors:invalidDate')).nullable(),
      }),
      study: yup.object().shape({
        dateFrom: yup.date().typeError(t('Errors:invalidDate')).nullable(),
        dateTo: yup.date().typeError(t('Errors:invalidDate')).nullable(),
      }),
      sources: required(yup.array()),
      /* modalities: ifModalitiesNotEmptyRequireAdditionalField(
        yup.array,
        function (this: yup.TestContext) {
          const patientFields = this.parent.patient;
          const studyFields = this.parent.study;
          return Object.assign({}, patientFields, studyFields);
        },
      ),*/
    },
    //firstName: required(yup.string()),
    // firstName: required(max(yup.string(), 3)),
    // viewer: required(yup.mixed()),
  );

  const dateSelectItems = [
    DATE_BTN_LAST_24_HOURS,
    DATE_BTN_TODAY,
    DATE_BTN_YESTERDAY,
    DATE_BTN_LAST_THREE_DAYS,
    DATE_BTN_LAST_WEEK,
    DATE_BTN_LAST_MONTH,
    DATE_BTN_CUSTOM_DATE,
  ].map((id) => ({ id, label: t(id) }));

  const sexes = [
    { id: '', label: t('sexesAll') },
    { id: 'F', label: t('female') },
    { id: 'M', label: t('male') },
    { id: 'O', label: t('other') },
  ];

  const formItems = [
    {
      type: 'input',
      name: 'patient.firstName',
      label: t('firstName'),
      autoComplete: 'firstName',
    },
    {
      type: 'input',
      name: 'patient.middleName',
      label: t('middleName'),
      autoComplete: 'middleName',
    },
    {
      type: 'select',
      name: 'patient.sex',
      label: t('sex'),
      items: sexes,
    },
    {
      type: 'date',
      name: 'patient.dateBirthFrom',
      label: t('dateBirthFrom'),
    },
    {
      type: 'date',
      name: 'patient.dateBirthTo',
      label: t('dateBirthTo'),
    },
    {
      type: 'input',
      name: 'study.description',
      label: t('description'),
      autoComplete: 'description',
    },
    {
      type: 'input',
      name: 'study.referringPhysician',
      label: t('referringPhysician'),
      autoComplete: 'referringPhysician',
    },
  ];

  const customDateFields = [
    { type: 'date', name: 'study.dateFrom', label: t('studyDateFrom') },
    {
      type: 'date',
      name: 'study.dateTo',
      label: t('studyDateTo'),
    },
  ];

  const extendedFields: string[] = [];
  formItems.forEach((item) => {
    extendedFields.push(item.name);
  });
  customDateFields.forEach((item) => {
    extendedFields.push(item.name);
  });

  return {
    dateSelectItems,
    SearchStudyFormSchema,
    formItems,
    customDateFields,
    extendedFields,
  };
};

export default useValidationSchema;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isArray, lowerCase, sortBy } from 'lodash';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { getAllAnnouncement, setActiveAnnouncement } from './_api';
import { IWorkplace } from '../Workplaces/_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useLanguages } from 'utils/hooks/useLanguages';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useLanguage } from 'utils/hooks/useLanguage';
import Header from 'components/Header/Header';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { Announcement } from 'components/Announcement/Announcement';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'announcementsMUI';

export const Announcements: React.FC = () => {
  const { t } = useTranslation('Announcements');
  useWithTitle();
  const dispatch = useAppDispatch();
  const { toggleLoader } = useAppGlobals();
  const { languages } = useLanguages();
  const { currentLocale } = useLanguage();
  const { compactMode, confirmationData } = useAppInfo();
  const navigate = useNavigate();
  const [announcements, setAnnouncements] = useState<any[]>([]);

  const loadEntity = async () => {
    toggleLoader();
    try {
      const announcements = await getAllAnnouncement();
      if (announcements && isArray(announcements)) {
        setAnnouncements(announcements);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCustomEntityDetail = (row: any) => {
    navigate(`/administration/announcementsAndButtons/announcement/${get(row, 'id')}`);
  };

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 220,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.active}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeState',
                            id: get(row, 'id'),
                            state,
                            confirmAction: 'confirmActionChangeActive',
                          }),
                        );
                      }}
                    />
                  }
                  label={(row.active ? t('Grid:active') : t('Grid:inactive')) as string}
                />
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onCustomEntityDetail(row)}
              />
            </>
          );
        },
      },
      {
        field: 'validFrom',
        headerName: t('validFrom'),
        type: 'date',
        valueGetter: (value: any) => value && new Date(value),
      },
      {
        field: 'validTo',
        headerName: t('validTo'),
        type: 'date',
        valueGetter: (value: any) => value && new Date(value),
      },
      ...(languages || []).map((lang: any) => ({
        field: lang.abbreviation,
        headerName: lang.name,
        valueGetter: (value: any, row: any) =>
          get(JSON.parse(row.languageJsonText), lang.abbreviation, ''),
      })),
      {
        field: 'importance',
        headerName: t('importanceLabel'),
        renderCell: ({ row }: GridRenderCellParams) => {
          const value = get(row, 'importance', '');
          return value ? (
            <Box sx={{ width: '100%' }}>
              <Announcement
                label={t(`importance.${lowerCase(value)}`)}
                type={value.toLowerCase()}
                condensed={true}
                sx={{
                  fontSize: compactMode ? 11 : 12,
                  pt: compactMode ? '0px' : '4px',
                  pb: compactMode ? '0px' : '4px',
                }}
              />
            </Box>
          ) : null;
        },
        valueGetter: (value: any, row: any) =>
          t(`importance.${lowerCase(get(row, 'importance', ''))}`),
      },
      {
        field: 'workPlaces',
        headerName: t('workPlaces'),
        valueGetter: (value: any, row: any) =>
          isArray(get(row, 'workPlaces', null))
            ? sortBy(
                get(row, 'workPlaces').map((workPlace: IWorkplace) =>
                  get(
                    get(workPlace, 'text', false) ? JSON.parse(get(workPlace, 'text', '')) : {},
                    currentLocale,
                    workPlace.code,
                  ),
                ),
              ).join(', ')
            : null,
      },
    ]),
  );

  const onChangeActive = async (id: string, isActive: boolean) => {
    toggleLoader();
    const changed = await setActiveAnnouncement(id, isActive);
    if (changed) {
      await loadEntity();
    }
    toggleLoader(false);
  };

  const confirmActionChangeActive = async () => {
    await onChangeActive(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  return (
    <>
      <Header title={''} addUrl="/administration/announcementsAndButtons/announcement/create" />
      <MuiGrid
        gridKey={muiGridKey}
        rows={announcements}
        columns={columns}
        initialSortMode={[{ field: 'validFrom', sort: 'asc' }]}
      />

      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmActionChangeActive}
        />
      )}
    </>
  );
};

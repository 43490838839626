import { Theme } from '@mui/material';
import { Box } from '@mui/system';
import { get } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Clear';
import { green, red } from 'utils/variables';

export const testStateFormatter = (row: any, t: any) => (
  <Box
    component="span"
    sx={{
      display: 'inline-block',
      padding: '4px',
      borderRadius: '4px',
      lineHeight: 1,
      color: '#fff',
      background: get(row, 'state', '').toLowerCase() === 'passed' ? green : red,
    }}
  >
    {t(get(row, 'state', '').toLowerCase())}
  </Box>
);

export const graphicalBoolFormatter = (value: boolean) =>
  value ? (
    <CheckIcon sx={{ color: (theme: Theme) => theme.palette.success.main }} />
  ) : (
    <CancelIcon sx={{ color: (theme: Theme) => theme.palette.error.main }} />
  );

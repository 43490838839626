import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { Img } from 'components/Image/Img';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { joinParams } from 'utils/study';
import { IRenderSerie } from './_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import Image from '@mui/icons-material/Image';
import { getUrlForOpenViewer } from 'modules/Studies/StudyDetail/_api';
import useAlerts from 'components/Alerts/useAlerts';
import { RenderInstances } from './RenderInstances';
import { PREVIEW_URL_SIZE, PREVIEW_URL_SIZE_COMPACT_MODE } from 'constants/constants';

export const RenderSerie: React.FC<IRenderSerie> = ({
  study,
  serie,
  serieIndex,
  showSeriesCheckboxes,
  operations,
  allowOpenSeries,
  showInstancesCheckboxes,
}) => {
  const { t } = useTranslation('Studies');
  const { defViewer, hidePreview, compactMode } = useAppInfo();
  const { addErrorAlert } = useAlerts();
  //
  const { setSerieSelected, setSerieOpened } = operations;

  const serieIsOpened = serie.serieIsOpened;
  const serieIsSelected = serie.serieIsSelected;
  const serieIsIndeterminate = serie.serieIsIndeterminate;

  const label = `${serieIndex + 1}. ${joinParams(
    [
      get(serie, 'seriesNumber', ''),
      get(serie, 'modality', ''),
      get(serie, 'description', '') || '',
      get(serie, 'numberOfInstances', 0) > 0
        ? t('serieImages', { images: get(serie, 'numberOfInstances', 0) })
        : '',
    ],
    ' | ',
  )}`;

  const onViewer = (studyID: string, patientId: string, archiveID: string, seriesUID: string) => {
    getUrlForOpenViewer(studyID, patientId, archiveID, seriesUID).then(
      (response) =>
        response ? window.open(response, '_blank') : addErrorAlert(t('cannotOpenLink')),
      () => addErrorAlert(t('cannotOpenLink')),
    );
  };

  const disableImagePreviewForSerieIfInstancesExist =
    get(serie, 'numberOfInstances', 0) > 0 && allowOpenSeries;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          pl: disableImagePreviewForSerieIfInstancesExist ? 2 : 0,
          ...(compactMode ? { my: 0.5 } : {}),
        }}
      >
        {get(serie, 'numberOfInstances', 0) > 0 && allowOpenSeries ? (
          <IconButton
            sx={{ mr: 1, ...(compactMode ? { p: 0, mr: 2 } : {}) }}
            onClick={() => setSerieOpened(study.iid, serie.uid)}
          >
            {serieIsOpened ? <KeyboardArrowDown /> : <ChevronRight />}
          </IconButton>
        ) : null}

        {hidePreview || disableImagePreviewForSerieIfInstancesExist ? null : (
          <Img
            patientId={get(study, 'patient.identificationNumber', '')}
            productId={get(study, 'archive.id', '') as string}
            studyIUID={get(study, 'studyInstanceUid', '')}
            seriesIUID={get(serie, 'uid')}
            series={null}
            topIndex={0}
            width={compactMode ? PREVIEW_URL_SIZE_COMPACT_MODE : PREVIEW_URL_SIZE}
            dialogPreview={true}
          />
        )}

        {showSeriesCheckboxes ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={serieIsSelected}
                indeterminate={serieIsIndeterminate}
                sx={{ ...(compactMode ? { p: 0, mr: 1 } : {}) }}
              />
            }
            label={label}
            onChange={(_event) => setSerieSelected(serie.uid, study.iid)}
            sx={{ mr: 1 }}
          />
        ) : (
          label
        )}

        {defViewer && (
          <Tooltip title={t('openInBrowser')} placement="top-start">
            <IconButton
              color="primary"
              aria-label="Report"
              onClick={() => {
                onViewer(
                  get(study, 'studyInstanceUid', ''),
                  get(study, 'patient.identificationNumber', ''),
                  get(study, 'archive.id', '') as unknown as string,
                  get(serie, 'uid', ''),
                );
              }}
              sx={{ ...(compactMode ? { p: 0 } : {}) }}
            >
              <Image />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box>
        {serieIsOpened ? (
          <RenderInstances
            serie={serie}
            study={study}
            showInstancesCheckboxes={showInstancesCheckboxes}
            operations={operations}
          />
        ) : null}
      </Box>
    </>
  );
};

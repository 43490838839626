import React, { useEffect, useState } from 'react';
import { EMFJS, RTFJS, WMFJS } from 'rtf.js';
import { IRTFDocument } from './_types';
import { Box } from '@mui/material';

RTFJS.loggingEnabled(false);
WMFJS.loggingEnabled(false);
EMFJS.loggingEnabled(false);

export const RTFDocument: React.FC<IRTFDocument> = ({ document }) => {
  const [rtfHtml, setRtfHtml] = useState<any[]>([]);

  const stringToArrayBuffer = (rtfString: string) => {
    const buffer = new ArrayBuffer(rtfString.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < rtfString.length; i++) {
      bufferView[i] = rtfString.charCodeAt(i);
    }
    return buffer;
  };

  useEffect(() => {
    if (document != null && document !== '') {
      try {
        const rtfDocument = atob(document);
        const doc = new RTFJS.Document(stringToArrayBuffer(rtfDocument), {});
        doc
          .render()
          .then((htmlElements) => {
            setRtfHtml([...htmlElements]);
          })
          .catch((error) => console.error(error));
      } catch (error) {
        console.error(error);
        setRtfHtml([]);
      }
    } else {
      setRtfHtml([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  return (
    <Box>
      {rtfHtml.map((item, i) => (
        <div key={i} dangerouslySetInnerHTML={{ __html: item.innerHTML }} />
      ))}
    </Box>
  );
};

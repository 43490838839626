import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { warning } from 'utils/variables';
import { Link } from 'react-router-dom';
import { ILegacyPathComponent } from './_types';

export const LegacyPathComponent: React.FC<ILegacyPathComponent> = ({
  redirectToStudiesSearch = false,
  redirectToRequests = false,
}) => {
  const { t } = useTranslation('Homepage');

  return (
    <Typography
      sx={{
        p: 2,
        border: 1,
        borderRadius: 4,
        backgroundColor: warning.background,
        color: warning.color,
      }}
    >
      {t('urlMoved')}.{' '}
      {redirectToStudiesSearch && (
        <Link to="/studies" style={{ color: 'inherit' }}>
          {t('redirectToStudiesSearch')}
        </Link>
      )}
      {redirectToRequests && (
        <Link to="/requests" style={{ color: 'inherit' }}>
          {t('redirectToRequests')}
        </Link>
      )}
      . {t('forMoreInformationPleaseContactAdministrator')}
    </Typography>
  );
};

import { get, join, size } from 'lodash';
import { IAdditionalValidationArgs } from './_types';
import { IStudiesSplitForm } from '../StudiesSplit/_types';
import { useState } from 'react';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import useAlerts from 'components/Alerts/useAlerts';
import { useTranslation } from 'react-i18next';
import { IStudiesEditForm } from '../StudiesEdit/_types';
import { IOrder } from 'modules/Orders/_types';

enum getSizesForNames {
  PATIENT = 'patient',
  REQUESTING_PHYSICIAN = 'requestingPhysician',
  REFERRING_PHYSICIAN = 'referringPhysician',
  PERFORMING_PHYSICIAN = 'performingPhysician',
}

export const useAdditionalValidation = (args: IAdditionalValidationArgs) => {
  const { splitValidators } = useStudyInfo();
  const { addErrorAlert } = useAlerts();
  const { t } = useTranslation('Studies');
  const { isRequestEdit, maxNameLength, prefixForDataAccession } = args;
  const [confirmationData, setConfirmationData] = useState<null | {
    texts: string[];
    values: IStudiesSplitForm | IStudiesEditForm | IOrder;
  }>(null);

  const target = isRequestEdit ? 'data.' : '';

  let areSizesValidated = false;
  let confirmationTexts: string[] = [];

  const validateSizes = (newValues: IStudiesSplitForm | IStudiesEditForm | IOrder) => {
    const {
      sizePatientName,
      sizeRequestingPhysicianName,
      sizeReferringPhysicianName,
      sizePerformingPhysicianName,
    } = getNameSizes(newValues);

    const {
      patientSize,
      patientWeight,
      maxPatientSizeMaxValue,
      patientSizeLength,
      maxPatientSizeMaxLength,
      patientWeightLength,
      maxPatientWeightMaxLength,
    } = getDispositionSizes(newValues);

    if (sizePatientName > maxNameLength) {
      addErrorAlert(t('errorPacientName', { maxNameLength }));
    } else if (sizeRequestingPhysicianName > maxNameLength) {
      addErrorAlert(t('errorRequestingPhysicianName', { maxNameLength }));
    } else if (sizeReferringPhysicianName > maxNameLength) {
      addErrorAlert(t('errorReferringPhysicianName', { maxNameLength }));
    } else if (sizePerformingPhysicianName > maxNameLength) {
      addErrorAlert(t('errorPerformingPhysicianName', { maxNameLength }));
    } else if (patientSizeLength > maxPatientSizeMaxLength) {
      addErrorAlert(t('errorPatientSizeLength'));
    } else if (patientWeightLength > maxPatientWeightMaxLength) {
      addErrorAlert(t('errorPatientWeightLength'));
    } else if (patientSize < 0) {
      addErrorAlert(t('errorPatientSizeNegative'));
    } else if (patientWeight < 0) {
      addErrorAlert(t('errorPatientWeightNegative'));
    } else {
      areSizesValidated = true;
    }
    //Sizes are valid at this point, even though the patient size might be invalid but it goes to confirmation dialog
    if (patientSize > maxPatientSizeMaxValue) {
      confirmationTexts.push(
        t('errorPatientSizeMoreThen', { maxPatientSize: maxPatientSizeMaxValue }),
      );
    }
    return { areSizesValidated, patientSize, maxPatientSizeMaxValue };
  };

  const getNameSizes = (newValues: IStudiesSplitForm | IStudiesEditForm | IOrder) => {
    const nameSizes: { [key: string]: number } = {};
    for (let value of Object.values(getSizesForNames)) {
      nameSizes['size' + capitalize(value) + 'Name'] = getNameSize(
        newValues,
        target + prefixForDataAccession + value,
      );
    }
    return nameSizes;
  };

  const getDispositionSizes = (newValues: IStudiesSplitForm | IStudiesEditForm | IOrder) => {
    const patientDispositions = get(newValues, prefixForDataAccession + 'patientDispositions');
    const patientSize = +get(patientDispositions, 'size', 0);
    const patientWeight = +get(patientDispositions, 'weight', 0);

    const maxPatientSizeMaxValue = get(
      splitValidators,
      'patientValidatorDispositions.size.maxValue',
      5,
    );
    const maxPatientSizeMaxLength = get(
      splitValidators,
      'patientValidatorDispositions.size.max',
      16,
    );
    const maxPatientWeightMaxLength = get(
      splitValidators,
      'patientValidatorDispositions.weight.max',
      16,
    );
    const patientSizeLength = !get(patientDispositions, 'size', '')
      ? 0
      : get(patientDispositions, 'size', '').length;
    const patientWeightLength = !get(patientDispositions, 'weight', '')
      ? 0
      : get(patientDispositions, 'weight', '').length;

    return {
      patientSize,
      patientWeight,
      maxPatientSizeMaxValue,
      patientSizeLength,
      maxPatientSizeMaxLength,
      maxPatientWeightMaxLength,
      patientWeightLength,
    };
  };

  const clearConfirmationData = () => setConfirmationData(null);

  return {
    getNameSizes,
    getDispositionSizes,
    validateSizes,
    confirmationData,
    setConfirmationData,
    clearConfirmationData,
    confirmationTexts,
  };
};

const getNameSize = (values: IStudiesSplitForm | IStudiesEditForm | IOrder, prefix: string) => {
  return size(
    join(
      [
        get(values, prefix + '.name.lastName', ''),
        get(values, prefix + '.name.firstName', ''),
        get(values, prefix + '.name.middleName', ''),
        get(values, prefix + '.name.prefix', ''),
        get(values, prefix + '.name.suffix', ''),
      ],
      '-',
    ),
  );
};

const capitalize = (stringToCapitalize: string) => {
  return stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);
};

export default useAdditionalValidation;

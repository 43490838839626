import { THEME_MODE_DARK, THEME_MODE_LIGHT } from 'constants/constants';
import { IThemeMode, IThemeVariant, ITimeline } from 'utils/config/_types';
import { useAppDispatch } from 'store/hooks';
import {
  setIsLoading,
  setModeToStore,
  setThemeToStore,
  setTimelineToStore,
  toggleMiniMenu,
  setCompactModeToStore,
} from 'store/reducers/appReducer';

export const useAppGlobals = () => {
  const dispatch = useAppDispatch();

  const toggleLoader = (
    isLoading: boolean = true,
    showCancelButton: boolean = false,
    lock: string | undefined = undefined,
  ) => dispatch(setIsLoading({ isLoading, showCancelButton, lock }));

  const toggleMenu = () => dispatch(toggleMiniMenu());

  const setTheme = (theme: IThemeVariant) => dispatch(setThemeToStore(theme));
  const setMode = (mode: IThemeMode) => dispatch(setModeToStore(mode));
  const setTimeline = (timeline: ITimeline) => dispatch(setTimelineToStore(timeline));
  const setCompactMode = (compactMode: boolean) => dispatch(setCompactModeToStore(compactMode));

  const toggleDarkMode = (setDarkMode: boolean) =>
    setMode(setDarkMode ? THEME_MODE_DARK : THEME_MODE_LIGHT);

  const toggleTimelineOrder = (timelineOrder: ITimeline) => setTimeline(timelineOrder);

  const toggleCompactMode = (compactMode: boolean) => setCompactMode(compactMode);

  return {
    toggleLoader,
    toggleMenu,
    setTheme,
    setMode,
    toggleDarkMode,
    setTimeline,
    toggleTimelineOrder,
    toggleCompactMode,
  };
};

export const version = '6.2.5';

export const isIE11 = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;

export const isIE12 = window.navigator.userAgent.indexOf('Edge/') > 0;

export const isOpera = window.navigator.userAgent.match(/Opera|OPR\//) ? true : false;

// cktch colors
export const cktchRed = '#a90607';
export const cktchGrey = '#2f4050';

// default colors
export const paletteColorDark = '#005e7c';
export const paletteColorLight = '#6ebbdd';
export const paletteColorMain = '#388bab';
export const paletteColorLightest = '#79daff';
export const blueBackground = '#2f4050';
export const primaryColor = '#388bab'; // '#1ab394';
export const lightBlue = '#a7b1c2';
export const green = '#388bab'; // '#1ab394';
export const red = '#ed5565';
export const greenDark = '#0d7560';
export const redDark = '#ad2432';

export const darkTypeCardHeader = '#666'; // '#a7b1c2';
export const darkTypeCardIcon = '#888';
export const darkTypeCardHover = 'rgba(66, 66, 66, 0.6)';
export const darkTypeInputBackground = '#424242';

export const info = { color: '#31708f', background: '#d9edf7' };
export const warning = { color: '#8a6d3b', background: '#fcf8e3' };
export const danger = { color: '#a94442', background: '#f2dede' };

export const warningColor = '#ff9800';

export const drawerWidth = 240;
export const drawerWidthMini = 60;

export const appBarHeight = 48;
export const appBarHeightCompact = 40;

export const bodyPadding = 15;

import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Paper, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { get } from 'lodash';
import { setTourControlPropsToStore } from 'store/reducers/appReducer';
import { useDispatch } from 'react-redux';

const AutocompleteMultipleForm = ({
  name,
  label,
  options,
  placeholder,
  required = false,
  filterSelectedOptions = true,
  disabled = false,
  multiple = true,
  trigger,
}: any) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();

  //For managing tour logic
  const handleOpen = (element: any) => {
    setTimeout(() => {
      dispatch(setTourControlPropsToStore({ isDropDownOpen: true, currentOpenDropdown: name }));
      // setIsOpen(true);
    }, 100);
  };

  const handleClose = (element: any) => {
    setTimeout(() => {
      dispatch(setTourControlPropsToStore({ isDropDownOpen: false, currentOpenDropdown: null }));
      // setIsOpen(false);
    }, 100);
  };

  let isError = false;
  let errorMessage = '';
  if (errors && errors.hasOwnProperty(name)) {
    isError = true;
    errorMessage = get(errors, `[${name}].message`, '') as string;
  }
  return (
    <Controller
      render={(props) => (
        <Autocomplete
          id={`${name}-id`}
          multiple={multiple}
          options={options || []}
          getOptionLabel={(option) => option.label || ''}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          filterSelectedOptions={filterSelectedOptions}
          value={props.field.value || []} // value is passed by render from the Controller
          onChange={(e, values) => {
            setValue(name, values);
            trigger && trigger(name);
          }} // instead here the docs said to do: onChange={e => props.onChange(e.target.checked)}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          disabled={disabled}
          onOpen={handleOpen}
          onClose={handleClose}
          PaperComponent={(props) => (
            <Paper {...props} className="autocomplete-multiple-react-tour-class" />
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              label={label}
              placeholder={placeholder}
              InputLabelProps={{
                className: required ? 'required-label' : '',
                required: required || false,
              }}
              variant="outlined"
              size="small"
              fullWidth
              error={isError}
              helperText={errorMessage}
            />
          )}
        />
      )}
      control={control}
      name={name}
      defaultValue={[]} // this prevents the "controlled/uncontrolled change" error

      /* rules={{
        validate: (d) => {
          return d.length > 0;
        },
      }} */
    />
  );
};
export default AutocompleteMultipleForm;

import React, { memo } from 'react';
import { format } from 'date-fns';
import { get, isArray, isString, lowerCase, orderBy, sortBy } from 'lodash';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { FormControlLabel, Icon, Switch, Tooltip, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Clear';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { Link } from 'react-router-dom';

import { findTranslation } from './translate';
import { IWorkplace } from 'modules/Administration/Workplaces/_types';
import { IArchive } from 'modules/Search/_types';

import { useActions } from 'utils/hooks/useActions';
import { useLanguage } from 'utils/hooks/useLanguage';
import { encryptId } from 'utils/hooks/useApp';
import { useStudy } from 'utils/hooks/useStudy';
import { formatTime } from 'utils/hooks/useDate';

import { Announcement } from 'components/Announcement/Announcement';
import { MaterialUiIcon } from 'components/MaterialUiIcon/MaterialUiIcon';

import { Box } from '@mui/system';
import { NOTIFICATIONS_STATE_NEW } from 'constants/constants';
import i18n from 'constants/translations/i18n';

const theme = createTheme();

export const shortener = (text: any, desiredLength = 50, useSpan = false) =>
  isString(text) && text.length > desiredLength ? (
    <Tooltip
      title={
        <Typography sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', mt: 1, lineHeight: 1 }}>
          {text}
        </Typography>
      }
      placement="top"
    >
      <Box component={useSpan ? 'span' : 'div'} style={{ cursor: 'pointer' }}>{`${text.substring(
        0,
        desiredLength,
      )}…`}</Box>
    </Tooltip>
  ) : (
    text
  );

const workplaces = (value: any, locale: any) => {
  return isArray(value)
    ? sortBy(
        value.map((workPlace: IWorkplace) =>
          get(
            get(workPlace, 'text', false) ? JSON.parse(get(workPlace, 'text', '')) : {},
            locale,
            workPlace.code,
          ),
        ),
      ).join(', ')
    : null;
};

const archives = (value: any) => {
  return isArray(value) ? value.map((archive: IArchive) => archive.name).join(', ') : null;
};

const Sex = ({ t, classes, ...rest }: any) => {
  const { getPatientSexSlug } = useStudy();
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        return value ? t(getPatientSexSlug(value)) : null;
      }}
      {...rest}
    />
  );
};

const Color: React.FC<any> = ({ ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ value }: any) =>
      value ? (
        <Tooltip id="tooltip-left" title={value} placement="right">
          <div style={{ backgroundColor: value, width: 20, height: 20 }} />
        </Tooltip>
      ) : null
    }
    {...rest}
  />
);

const Archives: React.FC<any> = ({ ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ value }: any) => <div>{archives(value)}</div>}
    {...rest}
  />
);
const ShortenerArchives: React.FC<any> = ({ length = 30, ...rest }: any) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }: any) => <div>{shortener(archives(value), length)}</div>}
      {...rest}
    />
  );
};
const Browser: React.FC<any> = ({ ...rest }: any) => (
  <DataTypeProvider formatterComponent={({ value }: any) => get(value, 'name', '')} {...rest} />
);

const FormatDate: React.FC<any> = ({
  format: dateFormat = 'dd. MM. yyyy',
  length = 30,
  ...props
}: any) => (
  <DataTypeProvider
    formatterComponent={({ value }: any) => {
      const valueToText = isArray(value)
        ? value.map((item: any) => (item ? format(new Date(item), dateFormat) : null)).join(', ')
        : value
        ? format(new Date(value), dateFormat)
        : null;
      const output =
        isString(valueToText) && valueToText.length > length
          ? `${valueToText.substring(0, length)}…`
          : valueToText;
      return isString(valueToText) && valueToText.length > length ? (
        <Tooltip title={valueToText} placement="top">
          <div style={{ cursor: 'pointer' }}>{output}</div>
        </Tooltip>
      ) : (
        <>{output}</>
      );
    }}
    {...props}
  />
);

const Favourite: React.FC<any> = ({ t, ...rest }: any) => {
  const { storeConfirmationData } = useActions();
  return (
    <DataTypeProvider
      formatterComponent={({ row, value }: any) => (
        <>
          <FormControlLabel
            control={
              <Switch
                checked={row.favourite}
                onChange={(e, state) => {
                  storeConfirmationData({
                    title: 'confirmChangeState',
                    id: get(row, 'id'),
                    state,
                    confirmAction: 'confirmActionChangeFavourite',
                  });
                }}
              />
            }
            label={t('favourite')}
          />
        </>
      )}
      {...rest}
    />
  );
};

const NotificationState: React.FC<any> = ({ classes, locale, translationModule, ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ value }: any) => (
      <div>
        {value && value.toUpperCase() === NOTIFICATIONS_STATE_NEW ? (
          <div
            style={{
              width: theme.spacing(1),
              height: theme.spacing(1),
              background: theme.palette.primary.main,
              borderRadius: theme.spacing(0.5),
              display: 'inline-block',
              marginRight: theme.spacing(1),
            }}
          />
        ) : null}
        {value && findTranslation(i18n.language, translationModule, value.toLowerCase())}
      </div>
    )}
    {...rest}
  />
);

const Workplaces: React.FC<any> = ({ locale, ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ value }: any) => <div>{workplaces(value, locale)}</div>}
    {...rest}
  />
);
const ShortenerWorkplaces: React.FC<any> = ({ locale, length = 30, ...rest }: any) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }: any) => (
        <div>{shortener(workplaces(value, locale), length)}</div>
      )}
      {...rest}
    />
  );
};
const Importance: React.FC<any> = ({ t, ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ value }: any) =>
      value ? (
        <Announcement
          label={t(`importance.${lowerCase(value)}`)}
          type={value.toLowerCase()}
          condensed={true}
        />
      ) : null
    }
    {...rest}
  />
);

const MuiIcon: React.FC<any> = ({ t, ...rest }: any) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }: any) => <Icon baseClassName="material-icons">{value}</Icon>}
      {...rest}
    />
  );
};

const LanguageJsonText: React.FC<any> = ({ ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ value }: any) => {
      try {
        return value ? (
          <ul>
            {Object.keys(JSON.parse(value)).map((item, i) =>
              JSON.parse(value)[item] ? (
                <li key={i}>
                  <span title={item}>
                    {item}: {JSON.parse(value)[item]}
                  </span>
                </li>
              ) : null,
            )}
          </ul>
        ) : null;
      } catch (e) {
        return null;
      }
    }}
    {...rest}
  />
);
const ShortenerLanguageJsonText: React.FC<any> = ({ length = 30, ...rest }: any) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }: any) => {
        try {
          return value ? (
            <ul>
              {Object.keys(JSON.parse(value)).map((item, i) =>
                JSON.parse(value)[item] ? (
                  <li key={i}>
                    <span title={item}>
                      {shortener(`${item}: ${JSON.parse(value)[item]}`, length)}
                    </span>
                  </li>
                ) : null,
              )}
            </ul>
          ) : null;
        } catch (e) {
          return null;
        }
      }}
      {...rest}
    />
  );
};

const Bool: React.FC<any> = ({ t, ...rest }: any) => (
  <DataTypeProvider formatterComponent={({ value }) => (value ? t('yes') : t('no'))} {...rest} />
);

const GraphicalBool: React.FC<any> = ({ t, ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      value ? (
        <CheckIcon sx={{ color: (theme) => theme.palette.success.main }} />
      ) : (
        <CancelIcon sx={{ color: (theme) => theme.palette.error.main }} />
      )
    }
    {...rest}
  />
);

const Modality: React.FC<any> = ({ ...rest }: any) => (
  <DataTypeProvider formatterComponent={({ value }: any) => get(value, 'name', '')} {...rest} />
);

const Unit: React.FC<any> = ({ ...rest }: any) => (
  <DataTypeProvider formatterComponent={({ value }: any) => get(value, 'name', '')} {...rest} />
);

const ReactionType: React.FC<any> = ({ t, ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ value }: any) => t(get(value, 'languageKey', ''))}
    {...rest}
  />
);

const Shortener: React.FC<any> = ({ length = 30, ...rest }: any) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => <div>{shortener(value, length)}</div>}
      {...rest}
    />
  );
};

const Translate: React.FC<any> = ({ translationModule, ...rest }: any) => {
  const { currentLocale } = useLanguage();
  return (
    <DataTypeProvider
      formatterComponent={({ value }: any) => {
        return value === undefined
          ? ''
          : findTranslation(currentLocale, translationModule, value.toLowerCase());
      }}
      {...rest}
    />
  );
};

const TooltipRow: React.FC<any> = ({ tooltipText, ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      isString(tooltipText) ? (
        <Tooltip title={tooltipText} placement="bottom">
          <div style={{ cursor: 'help' }}>{value}</div>
        </Tooltip>
      ) : (
        value
      )
    }
    {...rest}
  />
);

const TranslateWithTooltip: React.FC<any> = ({ translationModule, ...rest }: any) => {
  const { currentLocale } = useLanguage();
  return (
    <DataTypeProvider
      formatterComponent={({ value }: any) => {
        return value === undefined ? (
          ''
        ) : (
          <Tooltip
            title={findTranslation(
              currentLocale,
              translationModule,
              value.toLowerCase() + '_tooltip',
            )}
            placement="top"
          >
            <div style={{ cursor: 'help' }}>
              {findTranslation(currentLocale, translationModule, value.toLowerCase())}
            </div>
          </Tooltip>
        );
      }}
      {...rest}
    />
  );
};

const GroupName: React.FC<any> = ({ length = 100, ...rest }: any) => {
  return (
    <DataTypeProvider
      formatterComponent={({ row, value }: any) => {
        const rootLevel = get(row, 'rootLevel');
        const isLeveled = rootLevel > 0;
        return (
          <Box
            sx={isLeveled ? { display: 'flex', alignItems: 'center' } : {}}
            style={isLeveled ? { paddingLeft: theme.spacing(rootLevel * 2) } : {}}
          >
            {isLeveled && <SubdirectoryArrowRightIcon sx={{ width: 20, height: 20, mr: 1 }} />}
            {shortener(value, length)}
          </Box>
        );
      }}
      {...rest}
    />
  );
};

const FolderLink: React.FC<any> = ({ action, t, hashedId, ...rest }: any) => {
  return (
    <DataTypeProvider
      formatterComponent={({ row, value }: any) => (
        <Tooltip title={t('showDetail')}>
          <Box
            sx={{
              cursor: 'move',
              background: 'none',
              color: (theme) =>
                theme.palette.mode === 'dark'
                  ? theme.palette.primary.lightBlue
                  : theme.palette.primary.main,
            }}
          >
            <Link
              style={{ cursor: 'pointer', fontWeight: 'bold', color: 'inherit' }}
              to={`${
                action === 'view' || action === 'studyDetail' ? '' : '/administration'
              }/folders/${
                hashedId ? encryptId(get(row, 'id')) : get(row, 'id')
              }/detail?from=${action}`}
            >
              {value}
            </Link>
          </Box>
        </Tooltip>
      )}
      {...rest}
    />
  );
};

const NCPeHPatientLink: React.FC<any> = ({ t, ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ row, value }: any) => (
      <Tooltip title={t('showDetail')}>
        <Box
          sx={{
            cursor: 'move',
            background: 'none',
            color: (theme) =>
              theme.palette.mode === 'dark'
                ? theme.palette.primary.lightBlue
                : theme.palette.primary.main,
          }}
        >
          <Link
            style={{ cursor: 'pointer', fontWeight: 'bold', color: 'inherit' }}
            to={`/clinicPortal/byNCPeH/${get(row, 'id')}`}
          >
            {value}
          </Link>
        </Box>
      </Tooltip>
    )}
    {...rest}
  />
);

const PatientLink: React.FC<any> = ({ t, ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ row, value }: any) => (
      <Tooltip title={t('showDetail')}>
        <Box
          sx={{
            cursor: 'move',
            background: 'none',
            color: (theme) =>
              theme.palette.mode === 'dark'
                ? theme.palette.primary.lightBlue
                : theme.palette.primary.main,
          }}
        >
          <Link
            style={{ cursor: 'pointer', fontWeight: 'bold', color: 'inherit' }}
            to={`/clinicPortal/${get(row, 'internalID')}`}
          >
            {value}
          </Link>
        </Box>
      </Tooltip>
    )}
    {...rest}
  />
);

const IconName: React.FC<any> = ({ ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ row, value }: any) => {
      const iconColor = get(row, 'iconColor');
      return (
        <MaterialUiIcon
          icon={value}
          style={{
            color: iconColor,
          }}
          selected={true}
          useDiv={true}
        />
      );
    }}
    {...rest}
  />
);

const ConditionalDelete: React.FC<any> = ({ ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ row, value }: any) => (
      <div className={get(row, 'languageKey') ? 'without-delete' : 'with-delete'}>{value}</div>
    )}
    {...rest}
  />
);

const Comments: React.FC<any> = ({ ...rest }: any) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }: any) => {
        if (isArray(value) && value.length) {
          const sortedComments = orderBy(value, ['createdWhen'], ['desc']);
          return sortedComments.map((comment) => (
            <div key={comment.id}>
              {shortener(comment.text)} ({get(comment, 'createdBy.lastName', '') || ''}
              {get(comment, 'createdBy.firstName') ? ' ' : null}
              {get(comment, 'createdBy.firstName', '') || ''})
            </div>
          ));
        }
        return '';
      }}
      {...rest}
    />
  );
};

const Comment: React.FC<any> = ({ classes, ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ value }: any) => <>{shortener(value, classes)}</>}
    {...rest}
  />
);

const TestLength = ({ t, ...rest }: any) => (
  <DataTypeProvider
    formatterComponent={({ value }) => {
      return formatTime(value, t);
    }}
    {...rest}
  />
);

const TestPass = ({ t, ...rest }: any) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => (
        <Box
          component="span"
          sx={{
            display: 'inline-block',
            padding: '4px',
            borderRadius: '4px',
            lineHeight: 1,
            color: '#fff',
            background: (theme) =>
              value.toLowerCase() === 'passed'
                ? theme.palette.primary.main
                : theme.palette.error.main,
          }}
        >
          {t(value.toLowerCase())}
        </Box>
      )}
      {...rest}
    />
  );
};

const ExportValue: React.FC<any> = ({ t, ...rest }: any) => (
  <DataTypeProvider formatterComponent={({ value }: any) => t(value)} {...rest} />
);

const UpperValue: React.FC<any> = ({ t, ...rest }: any) => (
  <DataTypeProvider formatterComponent={({ value }: any) => t(value.toLowerCase())} {...rest} />
);

export const SexFormatter = memo(Sex);
export const BoolFormatter = memo(Bool);
export const DateFormatter = memo(FormatDate);
export const FavouriteFormatter = memo(Favourite);
export const WorkplacesFormatter = memo(Workplaces);
export const ShortenerWorkplacesFormatter = memo(ShortenerWorkplaces);
export const ImportanceFormatter = memo(Importance);
export const ShortenerFormatter = memo(Shortener);
export const FolderLinkFormatter = memo(FolderLink);
export const ModalityFormatter = memo(Modality);
export const UnitFormatter = memo(Unit);
export const ColorFormatter = memo(Color);
export const ReactionTypeFormatter = memo(ReactionType);
export const ArchivesFormatter = memo(Archives);
export const CommentFormatter = memo(Comment);
export const CommentsFormatter = memo(Comments);
export const ShortenerArchivesFormatter = memo(ShortenerArchives);
export const BrowserFormatter = memo(Browser);
export const LanguageJsonTextFormatter = memo(LanguageJsonText);
export const ShortenerLanguageJsonTextFormatter = memo(ShortenerLanguageJsonText);
export const TranslateFormatter = memo(Translate);
export const TooltipFormatter = memo(TooltipRow);
export const TranslateWithTooltipFormatter = memo(TranslateWithTooltip);
export const IconNameFormatter = memo(IconName);
export const IconFormatter = memo(MuiIcon);
export const ConditionalDeleteFormatter = memo(ConditionalDelete);
export const GroupNameFormatter = memo(GroupName);
export const GraphicalBoolFormatter = memo(GraphicalBool);
export const TestLengthFormatter = memo(TestLength);
export const TestPassFormatter = memo(TestPass);
export const ExportValueFormatter = memo(ExportValue);
export const NCPeHPatientLinkFormatter = memo(NCPeHPatientLink);
export const PatientLinkFormatter = memo(PatientLink);
export const UpperValueFormatter = memo(UpperValue);
export const NotificationStateFormatter = memo(NotificationState);

import { useAppDispatch } from 'store/hooks';
import {
  resetBasketInStore,
  setListsToStore,
  storeBasketToStore,
  storeConfirmationDataToStore,
  storeLogResults,
  storeOrderResultsToStore,
  storeQueueResults,
  storeAddSearchResultsToStore,
  storeSearchResultsToStore,
  storeSelectionToStore,
  storeDocumentsToStore,
  storeFindingReportResults,
  storeDataForDocumentDetailToStore,
  storeStructureResults,
  storeTestResults,
  storeVocabularyIdentityResults,
  storeVocabularyKeysToStore,
  storeVocabularyRowToStore,
  storeDocumentToStore,
  storePatientsToStore,
  storeRequestSearchResultsToStore,
  storeFacilitiesManualLoad,
} from 'store/reducers/appReducer';
import {
  resetAllStudies,
  resetStudiesInStore,
  setBulkStudiesToStore,
  setStudiesToStore,
  setStudiesBasketToStore,
} from 'store/reducers/studyReducer';

export const useActions = () => {
  const dispatch = useAppDispatch();

  const addToSearchResults = (data: any) => dispatch(storeAddSearchResultsToStore(data));
  const storeSearchResults = (data: any) => dispatch(storeSearchResultsToStore(data));

  const setStudies = (data: any) => dispatch(setStudiesToStore(data));
  const setStudiesBasket = (data: any) => dispatch(setStudiesBasketToStore(data));

  const setBulkStudies = (data: any) => dispatch(setBulkStudiesToStore(data));

  const resetStudies = (data: any) => dispatch(resetStudiesInStore(data));

  const resetStudyStore = () => dispatch(resetAllStudies());

  const storeConfirmationData = (data: any) => dispatch(storeConfirmationDataToStore(data));

  const storeSelection = (selection: any) => dispatch(storeSelectionToStore(selection));

  const storeDocuments = (data: any) => dispatch(storeDocumentsToStore(data));

  const storeDataForDocumentDetail = (data: any) =>
    dispatch(storeDataForDocumentDetailToStore(data));

  const storeOrders = (orders = {}) => dispatch(storeOrderResultsToStore(orders));

  const storeTests = (tests = {}) => dispatch(storeTestResults(tests));

  const storeQueues = (queues = {}) => dispatch(storeQueueResults(queues));

  const setLists = (data: any) => dispatch(setListsToStore(data));

  const storeLogs = (logs = {}) => dispatch(storeLogResults(logs));

  const storeFindingReports = (reports = {}) => dispatch(storeFindingReportResults(reports));

  const storeStructure = (structure = {}) => dispatch(storeStructureResults(structure));

  const storeVocabularyIdentity = (vocabularyIdentity = {}) =>
    dispatch(storeVocabularyIdentityResults(vocabularyIdentity));

  const storeVocabularyRow = (vocabularyRow = {}) =>
    dispatch(storeVocabularyRowToStore(vocabularyRow));

  const storeVocabularyKeys = (vocabularyKeys = {}) =>
    dispatch(storeVocabularyKeysToStore(vocabularyKeys));

  const storeBasket = (data: any) => dispatch(storeBasketToStore());

  const resetBasket = (data: any) => dispatch(resetBasketInStore());

  const storeDocument = (data: any) => dispatch(storeDocumentToStore(data));
  const storePatients = (data: any) => dispatch(storePatientsToStore(data));
  const storeRequestSearchResults = (data: any) => dispatch(storeRequestSearchResultsToStore(data));

  const storeFacilitiesManual = (facility = {}) => dispatch(storeFacilitiesManualLoad(facility));

  return {
    addToSearchResults,
    storeSearchResults,
    setStudies,
    resetStudies,
    storeConfirmationData,
    storeBasket,
    resetBasket,
    setBulkStudies,
    storeOrders,
    storeTests,
    storeQueues,
    setLists,
    storeLogs,
    storeFindingReports,
    storeStructure,
    storeVocabularyIdentity,
    storeVocabularyRow,
    storeVocabularyKeys,
    storeSelection,
    storeDocuments,
    storeDataForDocumentDetail,
    storeDocument,
    storePatients,
    storeRequestSearchResults,
    setStudiesBasket,
    resetStudyStore,
    storeFacilitiesManual,
  };
};

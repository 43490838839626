import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { get, isArray, isString, isUndefined, pick } from 'lodash';

import { getStation, editStation, createStation } from './_apiStations';
import { getAllUnits } from './_apiUnits';
import { getAllModalities } from './_apiModalities';
import { IStation, IStationForm, IStationFormContent, IModality, IUnit } from './_types';
import useValidationSchema from './_formStation';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';

const StationForm: React.FC<IStationFormContent> = ({ toggleDialog, id, loadEntity }) => {
  const { t } = useTranslation('Stations');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const [entity, fetchEntity] = useState<IStation>();
  const [modalities, fetchModalities] = useState<{ id: number; label: string }[]>([]);
  const [units, fetchUnits] = useState<IUnit[]>([]);
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);

  const isCreating = id === 'create';
  const { title } = useWithEntityTitle(isCreating, entity, t);

  const { StationFormSchema, formItems } = useValidationSchema(t);

  const methods = useForm<IStationForm>({
    resolver: yupResolver(StationFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const prepareValues = (values: any) => {
    const preparedValues: any = {
      ...pick(values, ['id', 'code', 'aetitle']),
      modality: { id: get(values, 'modalityId', null) },
      unit: { id: get(values, 'unitId', null) },
    };
    return preparedValues;
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createStation : editStation;
    const response = await fn(prepareValues(values));
    if (response === true) {
      addSuccessAlert(t('saved'));
      loadEntity();
      toggleDialog(false);
    } else {
      addErrorAlert(t(isString(response) ? response : 'errorSaving'));
    }
    toggleLoader(false);
  });

  const getEntities = async () => {
    toggleLoader();
    try {
      let station: IStation = { id: 0, code: '', aetitle: '', modalityId: null, unitId: null };

      if (!isCreating && isUndefined(entity) && id) {
        const entity = await getStation(id.toString());
        station = {
          ...entity,
          modalityId: get(entity, 'modality.id', ''),
          unitId: get(entity, 'unit.id', ''),
        };
        if (entity) {
          fetchEntity(station);
        }
      }
      const lists = await getAllModalities();
      if (isArray(lists)) {
        fetchModalities(
          lists.map((item: IModality) => ({
            id: item.id,
            label: item.name,
          })),
        );
      }
      const unitLists = await getAllUnits();
      if (isArray(unitLists)) {
        unitLists.unshift({ id: 0, name: '---' });
        fetchUnits(
          unitLists.map((item: IUnit) => ({
            id: item.id,
            label: item.name,
          })),
        );
      }
      reset({ ...station });
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isFetchingEntity && (
        <>
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <Grid container={true} alignItems="flex-end">
                  <Grid item={true} xs={12} md={6}>
                    {formItems.map((field, index) => {
                      return (
                        <React.Fragment key={`formItem-${index}`}>
                          <FormInput {...field} />
                        </React.Fragment>
                      );
                    })}
                    <FormSelect
                      name="modalityId"
                      label={t('modality')}
                      required={true}
                      items={modalities}
                    />
                    <FormSelect name="unitId" label={t('unit')} items={units} />
                    <EntityButtons toggleDialog={toggleDialog} />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
        </>
      )}
    </>
  );
};

export default StationForm;

export const items = [
  { name: 'expertness', canBeUsedBySetting: '', role: 'ROLE_DICTIONARY' },
  { name: 'healthInsuranceCompany', canBeUsedBySetting: '', role: 'ROLE_DICTIONARY' },
  { name: 'studyOrderPriority', canBeUsedBySetting: '', role: 'ROLE_DICTIONARY' },
  { name: 'requestedProcedure', canBeUsedBySetting: '', role: 'ROLE_DICTIONARY' },
  { name: 'physicians', canBeUsedBySetting: 'useListOfPhysicians', role: 'ROLE_DICTIONARY' },
  { name: 'dose', canBeUsedBySetting: '', role: 'ROLE_DICTIONARY' },
  { name: 'studyDetailBookmarks', canBeUsedBySetting: '', role: 'ROLE_DICTIONARY' },
  { name: 'decubits', canBeUsedBySetting: '', role: 'ROLE_DECUBITS_MANAGE' },
];

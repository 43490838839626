// TODO add mobile date picker

// import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
import { get, isDate, isEmpty } from 'lodash';
import csLocale from 'date-fns/locale/cs';
import enLocale from 'date-fns/locale/en-US';
// import ruLocale from 'date-fns/locale/ru';
// import enLocale from 'date-fns/locale/en-US';
// import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import {
  COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
  COMPACT_MODE_FORM_ELEMENT_HEIGHT,
  COMPACT_MODE_FORM_ELEMENT_LINE_HEIGHT,
} from 'constants/constants';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useTranslation } from 'react-i18next';

// const localeMap = {
//   en: enLocale,
//   cs: csLocale,
//   ru: ruLocale,
// };

// const MuiDatePicker = (props: any) => {
//   const { t } = useTranslation('Form');
//   const { name, isRequired, errors } = props;
//   let isError = false;
//   let errorMessage = '';
//   if (!isEmpty(errors) && get(errors, name) !== undefined) {
//     isError = true;
//     errorMessage = get(errors, `${name}.message`);
//   }
//   return (
//     <div>There should be picker. It's not here anymore.</div>
//     // <KeyboardDatePicker
//     //   showTodayButton={true}
//     //   todayLabel={t('today')}
//     //   cancelLabel={t('close')}
//     //   autoOk={true}
//     //   // variant="inline"
//     //   inputVariant="outlined"
//     //   format="dd.MM.yyyy"
//     //   fullWidth={true}
//     //   size="small"
//     //   InputLabelProps={{
//     //     className: isRequired ? 'required-label' : '',
//     //     required: isRequired || false,
//     //     shrink: true,
//     //   }}
//     //   error={isError}
//     //   helperText={errorMessage}
//     //   {...props}
//     // />
//   );
// };

const FormDatePicker = (props: any) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation('Errors');
  const { name, label, defaultValue = null, required, sx = {} } = props;
  let isError = false;
  let errorMessage = '';
  if (!isEmpty(errors) && get(errors, name) !== undefined) {
    isError = true;
    errorMessage = get(errors, `${name}.message`, '') as string;
  }

  const { compactMode } = useAppInfo();
  const { currentLocale } = useLanguage();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        //funguje jen když není použit yup ( asi :) )
        validate: (value: any) => {
          if (value === null || value === undefined) {
            return true;
          } else {
            return isDate(value) && value.toString() !== 'Invalid Date' ? true : t('invalidDate');
          }
        },
      }}
      {...props}
      render={({ field: { ref, ...rest } }) => {
        return (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={currentLocale === 'en' ? enLocale : csLocale}
          >
            <DatePicker
              disabled={get(props, 'disabled', false)}
              label={label}
              // renderInput={(params: any) => (
              //   <TextField
              //     {...params}
              //     size="small"
              //     margin="dense"
              //     fullWidth={true}
              //     error={isError}
              //     helperText={errorMessage}
              //     InputLabelProps={{
              //       className: required ? 'required-label' : '',
              //       required: required || false,
              //     }}
              //   />
              // )}
              slotProps={{
                field: { clearable: true },
                textField: {
                  size: 'small',
                  margin: 'dense',
                  fullWidth: true,
                  error: isError,
                  helperText: errorMessage,
                  required: required || false,
                  className: required ? 'required-label' : '',
                  InputProps: {
                    sx: compactMode
                      ? {
                          fontSize: COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
                          height: COMPACT_MODE_FORM_ELEMENT_HEIGHT,
                          ...sx,
                        }
                      : { ...sx },
                  },
                  InputLabelProps: {
                    sx: compactMode
                      ? {
                          fontSize: COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
                          lineHeight: COMPACT_MODE_FORM_ELEMENT_LINE_HEIGHT,
                        }
                      : {},
                  },
                },
              }}
              {...rest}
            />
          </LocalizationProvider>
        );
      }}
    />
    // <div style={{ marginTop: '8px' }}>
    //   <MuiPickersUtilsProvider utils={DateFnsUtils} locale={get(localeMap, lang, 'cs')}>
    //     <Controller
    //       name={name}
    //       control={control}
    //       defaultValue={defaultValue}
    //       {...props}
    //       render={({ field: { ref, ...rest } }) => {
    //         return <MuiDatePicker label={label} {...rest} />;
    //       }}
    //     />
    //   </MuiPickersUtilsProvider>
    // </div>
  );
};

export default FormDatePicker;

import {
  TAB_COMMENTS,
  TAB_DOSES,
  TAB_FOLDERS,
  TAB_OPERATIONS,
  TAB_ORDER,
  TAB_REPORT,
  TAB_SERIES,
  TAB_TAGS,
} from 'constants/constants';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { stateIsSame } from 'utils/componentOptimizatons';
import { StudyDetailComments } from './StudyDetailComments';
import { StudyDetailTags } from './StudyDetailTags';
import { StudyDetailFolders } from './StudyDetailFolders';
import { StudyDetailReport } from './StudyDetailReport';
import { StudyDetailOperations } from './StudyDetailOperations';
import { StudyDetailSeries } from './StudyDetailSeries';

import { IStudyDetailTabContent } from './_types';
import { Box } from '@mui/system';
import { OrderDetailFromStudy } from 'modules/Orders/OrderDetailFromStudy';
import { useStatePreviewsAndDecubits } from 'utils/hooks/useStatePreviewsAndDecubits';
import { StudyDetailDoses } from './StudyDetailDoses';

const PlainStudyDetailTabContent: React.FC<IStudyDetailTabContent> = ({
  study,
  studyActions,
  activeTab,
  setActiveTab,
  firstTab,
  handleLifeCycle,
  accessionNumber,
  patientId,
  hashIID,
  order2,
  setOrder2,
  seriesWithPreviewUrl,
}) => {
  const { previews, saveDecubits, setNewPreviews, setNewSaveDecubits } =
    useStatePreviewsAndDecubits();
  const setStudy = useCallback(() => {
    console.debug('SHould set study');
  }, []);

  const [folderDialogState, setFolderDialogState] = useState<boolean>(false);
  const [selectedSeries, setSelectedSeries] = useState<any>(false);

  useEffect(() => {
    if (folderDialogState) {
      setActiveTab(TAB_FOLDERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderDialogState]);

  let RenderedTab;
  switch (activeTab || firstTab) {
    case TAB_COMMENTS:
      RenderedTab = <StudyDetailComments study={study} setStudy={setStudy} />;
      break;
    case TAB_TAGS:
      RenderedTab = <StudyDetailTags study={study} />;
      break;
    case TAB_SERIES:
      RenderedTab = (
        <StudyDetailSeries
          study={study}
          handleLifeCycle={handleLifeCycle}
          previews={previews}
          setNewPreviews={setNewPreviews}
          saveDecubits={saveDecubits}
          setNewSaveDecubits={setNewSaveDecubits}
          setSelectedSeries={setSelectedSeries}
          setFolderDialogState={setFolderDialogState}
        />
      );
      break;
    case TAB_FOLDERS:
      RenderedTab = (
        <StudyDetailFolders
          studyActions={studyActions}
          study={study}
          seriesWithPreviewUrl={seriesWithPreviewUrl}
          folderDialogState={folderDialogState}
          setFolderDialogState={setFolderDialogState}
          selectedSeries={selectedSeries}
          setSelectedSeries={setSelectedSeries}
        />
      );
      break;
    case TAB_ORDER:
      RenderedTab = (
        <OrderDetailFromStudy
          accessionNumber={accessionNumber}
          patientId={patientId}
          hashIID={hashIID}
          order2={order2}
          setOrder2={setOrder2}
          mainTab={/* subTab ||  */ ''}
        />
      );
      break;
    case TAB_REPORT:
      RenderedTab = <StudyDetailReport study={study} />;
      break;
    case TAB_DOSES:
      RenderedTab = <StudyDetailDoses study={study} />;
      break;
    case TAB_OPERATIONS:
      RenderedTab = <StudyDetailOperations study={study} />;
      break;
  }

  return <Box sx={{ p: 2 }}>{RenderedTab}</Box>;
};

export const StudyDetailTabContent = React.memo(PlainStudyDetailTabContent, stateIsSame);

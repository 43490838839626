import React, { useState, useEffect } from 'react';
import { find, get, isArray, isEmpty, isObject, omit, pick } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { IFilterForm } from './_types';
import useValidationSchema from './_form';
import { createFilter, editFilter, getFilter, saveUserFilter } from './_api';
import { getAllWorkplaces } from '../Workplaces/_api';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';
import { Line } from 'components/Line/Line';
import { CheckedButton } from 'components/Buttons/CheckedButton';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';
import SearchFormInner from 'components/SeachForm/SearchFormInner';
import { useUser } from 'utils/hooks/useUser';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { DATE_BTN_CUSTOM_DATE, SEARCH_FUNCTION_ALL } from 'constants/constants';
import { TourFilters } from './TourFilters';

export const FilterForm: React.FC = () => {
  const { t } = useTranslation('Filters');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { id, isCreating } = useEntityInfo(true);
  const { currentLocale } = useLanguage();

  const [entity, setEntity] = useState<IFilterForm | null>(null);
  const [workplaces, fetchWorkplaces] = useState<any[]>([]);
  const [onlyForMe, toggleOnlyForMe] = useState<boolean>(false);
  const [searchFieldOpened, setSearchFieldOpened] = useState(false);
  const [hasCustomDate, setHasCustomDate] = useState(false);
  const [canSearch, setCanSearch] = useState(false);
  const navigate = useNavigate();
  const linkBack = '/filters?action=back';

  const { title } = useWithEntityTitle(isCreating, entity, t);
  const { compactMode, getArchivesForFunctions, getDefaultSources } = useAppInfo();
  const { hasRole, getUserFilters } = useUser();

  const canManageWorkplaceInFilter = hasRole('ROLE_MANAGE_WORKPLACE_IN_FILTER');

  const sources: { name: string; id: number }[] = getArchivesForFunctions(SEARCH_FUNCTION_ALL).map(
    (archive) => pick(archive, ['name', 'id']),
  );

  const defaultSources = getDefaultSources().map((sourceId) =>
    pick(find(sources, { id: sourceId }), ['name', 'id']),
  );

  const defaultFilter: IFilterForm = {
    name: '',
    workPlaces: [],
    active: true,
    author: null,
    filter: '',

    modalities: [],
    sources: defaultSources as { name: string; id: number }[],
    patient: {
      id: '',
      firstName: '',
      middleName: '',
      lastName: '',
      sex: '',
      dateBirthFrom: null,
      dateBirthTo: null,
      description: '',
      referringPhysician: '',
    },
    study: {
      accessionNumber: '',
      dateFrom: null,
      dateTo: null,
    },
    studyDate: '',
  };
  const defaultWorkplaces: any[] = [];

  const { FilterFormSchema } = useValidationSchema(isCreating);

  const methods = useForm<IFilterForm>({
    resolver: yupResolver(FilterFormSchema),
    mode: 'onChange',
  });
  const { handleSubmit, reset, setValue, watch } = methods;
  const allFields = watch();
  const dateValue = get(allFields, 'studyDate');

  useEffect(() => {
    if (dateValue === DATE_BTN_CUSTOM_DATE) {
      setSearchFieldOpened(true);
      setHasCustomDate(true);
    } else {
      setHasCustomDate(false);
      setValue('study.dateFrom', null);
      setValue('study.dateTo', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue]);

  useEffect(() => {
    const { patient, study, studyDate, sources } = allFields;
    const patientValues = patient ? Object.values(patient) : [];
    const studyValues = study ? Object.values(study) : [];
    const arrayToCheck = [
      ...patientValues,
      ...studyValues,
      studyDate === DATE_BTN_CUSTOM_DATE ? '' : studyDate,
    ];
    const canSearch = !(arrayToCheck.every((value) => !value) || (sources && sources.length === 0));
    // Musí být vyplněno minimálně jedno pole z formuláře pro vyhledávání vyšetření,
    // aby se mohl uložit filtr. Pokud je ale vybrána modalita, pak musí být vyplněno
    // ještě jedno jiné pole. Proto modalitu nekontrolovat.
    // Pokud je studyDate = DATE_BTN_CUSTOM_DATE, pak také ignorovat (pak se kontrolují datumy od/do).
    setCanSearch(canSearch);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFields]);

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const workPlaces = get(values, 'workPlaces', []);
    if (isCreating && isEmpty(workPlaces) && !onlyForMe) {
      addErrorAlert(t('selectMin1Workplace'));
    } else {
      const workplace = isEmpty(workPlaces) || onlyForMe ? null : workPlaces;
      const searchForm = omit(values, ['id', 'name', 'workPlaces', 'active', 'author', 'filter']);
      const filter = JSON.stringify(searchForm);
      let transformedValues;
      let fn;
      let response;
      if (isCreating) {
        if (canManageWorkplaceInFilter && !onlyForMe) {
          fn = createFilter;
        } else {
          fn = saveUserFilter;
        }
        transformedValues = {
          name: get(values, 'name', ''),
          active: get(values, 'active', true),
          author: get(values, 'author', null),
          filter,
          workplace,
        };
      } else {
        transformedValues = {
          id,
          name: get(values, 'name', ''),
          filter,
          author: get(values, 'author', null),
          workplace,
        };
        fn = editFilter;
      }
      response = await fn(transformedValues);
      if (response) {
        addSuccessAlert(t('saved'));
        getUserFilters();
        navigate(linkBack);
      } else {
        addErrorAlert(t('errorSaving'));
      }
    }
    toggleLoader(false);
  });

  const loadEntity = async (filterId: string, workplaces: any[]) => {
    toggleLoader();
    try {
      let filter: IFilterForm = defaultFilter;
      let customWorkplaces: any[] = defaultWorkplaces;
      if (!isCreating && filterId) {
        const entity = await getFilter(filterId);
        if (!isEmpty(entity) && isObject(entity)) {
          setEntity(entity);
          const fi = JSON.parse(get(entity, 'filter', ''));
          const transformedValues = {
            ...fi,
            patient: {
              ...fi.patient,
              dateBirthFrom: fi.patient?.dateBirthFrom ? new Date(fi.patient.dateBirthFrom) : null,
              dateBirthTo: fi.patient?.dateBirthTo ? new Date(fi.patient.dateBirthTo) : null,
            },
            study: {
              ...fi.study,
              dateFrom: fi.study?.dateFrom ? new Date(fi.study.dateFrom) : null,
              dateTo: fi.study?.dateTo ? new Date(fi.study.dateTo) : null,
            },
          };
          filter = { ...filter, ...entity, ...transformedValues };
          const selectedWorkplaces = get(entity, 'workPlaces', []);
          if (isArray(workplaces) && !isEmpty(selectedWorkplaces)) {
            workplaces.forEach((item) => {
              selectedWorkplaces.forEach((selWorkplace) => {
                if (item.id === selWorkplace.id) {
                  defaultWorkplaces.push(item);
                }
              });
            });
          }
        }
        reset({ ...filter, workPlaces: customWorkplaces });
      } else {
        customWorkplaces = workplaces;
        reset({ ...filter, workPlaces: customWorkplaces });
      }
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  const getEntities = async () => {
    toggleLoader();
    try {
      const workplaces = await getAllWorkplaces();
      let updatedWorkplaces: any[] = [];
      if (isArray(workplaces)) {
        updatedWorkplaces = workplaces.map((item) => {
          const workplace = {
            ...item,
            name: get(
              get(item, 'text', false) ? JSON.parse(get(item, 'text', '')) : {},
              currentLocale,
              item.code,
            ),
          };

          return workplace;
        });
        fetchWorkplaces(updatedWorkplaces);
      }
      await loadEntity(id.toString(), updatedWorkplaces);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedSteps = () => {
    return <TourFilters type={isCreating ? 'create' : 'edit'} />;
  };

  return (
    <>
      {(entity || isCreating) && sources ? (
        <>
          <Header title={title} TourComponent={renderedSteps()} />
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <Box data-tour={isCreating ? 'filtersStudyForm' : 'filtersEdit'}>
                <SearchFormInner
                  sources={sources}
                  searchFieldOpened={searchFieldOpened}
                  setSearchFieldOpened={setSearchFieldOpened}
                  hasCustomDate={hasCustomDate}
                  useForOtherForm={true}
                  defaultValues={defaultFilter}
                />
              </Box>

              <Papeer>
                <Box data-tour={isCreating ? 'filtersAdditionalInfo' : 'filtersEditAdditionalInfo'}>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} md={6} lg={3} xl={2}>
                      <FormInput name="name" label={t('name')} required={true} />
                    </Grid>
                  </Grid>
                  {isCreating && (
                    <>
                      <Line />
                      <Grid item={true} xs={12}>
                        <Box sx={{ display: 'inline-flex', whiteSpace: 'nowrap', marginTop: '4' }}>
                          <Typography component="div">{t('filterType')}</Typography>
                        </Box>
                        <CheckedButton
                          active={onlyForMe}
                          onClick={() => {
                            toggleOnlyForMe(!onlyForMe);
                          }}
                          leftMargin={true}
                          topMargin={false}
                        >
                          {t('onlyForMe')}
                        </CheckedButton>
                      </Grid>
                    </>
                  )}
                  {!onlyForMe && canManageWorkplaceInFilter && (
                    <>
                      <Line />
                      <Grid container={true} spacing={16}>
                        <Grid item={true} xs={12}>
                          <SimpleCheckboxArray
                            showCheckAllButton={canManageWorkplaceInFilter}
                            setAllCheckboxDisabled={!canManageWorkplaceInFilter}
                            name="workPlaces"
                            label={t('workPlaces')}
                            items={workplaces}
                            grid={compactMode ? {} : { xs: 12, md: 6, lg: 2, xl: 2 }}
                            compactMode={compactMode}
                            required={isCreating}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Line />
                  <EntityButtons linkBack={linkBack} disabledSubmit={!canSearch} />
                </Box>
              </Papeer>
            </form>
          </FormProvider>
        </>
      ) : (
        entity !== null && (
          <Papeer>
            <Typography>{t('filterNotFound')}</Typography>
          </Papeer>
        )
      )}
    </>
  );
};

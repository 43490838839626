import HelperComponent from 'components/Tour/HelperComponent';
import { getDropDownStep } from 'components/Tour/_dropDownStep';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

export const TourSearchArchive = ({ hasRows }: any) => {
  const { t } = useTranslation('Reactour');

  const allSteps = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="tagsArchiveAndIntegrationPlatform-comment"]': t('tagsArchive.comment'),
      '[data-tour="tagsArchiveAndIntegrationPlatform-multipleActiveTags"]': t(
        'tagsArchiveAndIntegrationPlatform.multipleActiveTags',
      ),
      ...(currentOpenDropdown === 'registerItems.active' &&
        getDropDownStep({ isDropDownOpen, t, selector: 'multiAutocomplete' })),

      '[data-tour="tagsArchiveAndIntegrationPlatform-multipleInActiveTags"]': t(
        'tagsArchiveAndIntegrationPlatform.multipleInActiveTags',
      ),
      ...(currentOpenDropdown === 'registerItems.inactive' &&
        getDropDownStep({ isDropDownOpen, t, selector: 'multiAutocomplete' })),

      '[data-tour="tagsArchiveAndIntegrationPlatform-search"]': t(
        'tagsArchiveAndIntegrationPlatform.search',
      ),
      '[data-tour="tagsArchiveAndIntegrationPlatform-clear"]': t(
        'tagsArchiveAndIntegrationPlatform.clear',
      ),
      '[data-tour="tagsArchiveGrid"]': (
        <>
          {t('common.bodyTable')} {t('tagsArchiveAndIntegrationPlatform.grid')}
        </>
      ),
      ...(hasRows && {
        '[data-tour="common-gridReFetching"]': t('common.gridReFetching'),
        '[data-tour="common-gridColumns"]': t('common.gridColumns'),
        '[data-tour="common-gridFilters"]': t('common.gridFilters'),
        '.MuiDataGrid-columnHeaders': t('common.headTableWithSortAndFilter'),
      }),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });

  return <HelperComponent />;
};

import { useCallback } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/hooks';

import { setGridSettingsToStore } from 'store/reducers/appReducer';
import { setGridSettings } from 'components/SearchResults/_api';
import { IGridSettingsStudyMUI } from './_types';
import {
  DATAGRID_SETTINGS_COLUMN_ORDER_MODEL,
  DATAGRID_SETTINGS_COLUMN_PINNED_COLUMNS,
  DATAGRID_SETTINGS_COLUMN_SORT_MODEL,
  DATAGRID_SETTINGS_COLUMN_VISIBILITY_MODEL,
  DATAGRID_SETTINGS_COLUMN_WIDTHS,
} from 'constants/constants';
import { GridPinnedColumnFields } from '@mui/x-data-grid-pro';

export const useGridSettings = () => {
  const dispatch = useAppDispatch();
  const gridSettings = useSelector((state) => get(state, 'app.gridSettings', {}));

  const storeGridSettings = useCallback(
    // key - např. studyMUI, preview, mwl,...
    async (key: string, newSettings: IGridSettingsStudyMUI) => {
      const settings = { ...get(gridSettings, key, {}), ...newSettings };
      dispatch(setGridSettingsToStore({ [key]: settings }));
      await setGridSettings({ key, gridSettingsForAPI: settings });
    },
    [gridSettings, dispatch],
  );

  const getSingleGridSettings = (gridKey: string) => get(gridSettings, gridKey, {});

  const getSingleGridSettingsAttribute = (
    gridKey: string,
    attribute:
      | typeof DATAGRID_SETTINGS_COLUMN_WIDTHS
      | typeof DATAGRID_SETTINGS_COLUMN_VISIBILITY_MODEL
      | typeof DATAGRID_SETTINGS_COLUMN_SORT_MODEL
      | typeof DATAGRID_SETTINGS_COLUMN_ORDER_MODEL,
  ) => get(getSingleGridSettings(gridKey), attribute, []);

  const getGridColumnsWidths = (gridKey: string) =>
    getSingleGridSettingsAttribute(gridKey, DATAGRID_SETTINGS_COLUMN_WIDTHS);

  const getGridColumnsSort = (gridKey: string) =>
    getSingleGridSettingsAttribute(gridKey, DATAGRID_SETTINGS_COLUMN_SORT_MODEL);

  const getGridColumnsOrder = (gridKey: string): string[] =>
    getSingleGridSettingsAttribute(gridKey, DATAGRID_SETTINGS_COLUMN_ORDER_MODEL);

  const getGridColumnsVisibility = (gridKey: string) =>
    get(gridSettings, `${gridKey}.${DATAGRID_SETTINGS_COLUMN_VISIBILITY_MODEL}`);

  const getUpdatedColumnWidthsAfterResize = (
    columns: any[],
    columnName: string,
    columnWidth: number,
  ) => {
    return columns.map((column) => {
      if (column.field !== columnName) return column;
      return { ...column, width: columnWidth };
    });
  };

  const arraymove = (arr: any[], fromIndex: number, toIndex: number) => {
    let arrayCopy = [...arr];
    const element = arrayCopy[fromIndex];
    arrayCopy.splice(fromIndex, 1);
    arrayCopy.splice(toIndex, 0, element);
    return arrayCopy;
  };

  const getUpdatedColumnOrderAfterReordering = (
    columns: any[],
    columnName: string,
    oldIndex: number,
    newIndex: number,
  ) => {
    const newColumns = [...columns];
    return arraymove(newColumns, oldIndex, newIndex);
  };

  const getPinnedColumnsFromGridSettings = (gridKey: string) => {
    try {
      const pinnedColumns = get(
        gridSettings,
        `${gridKey}.${DATAGRID_SETTINGS_COLUMN_PINNED_COLUMNS}`,
        {
          left: [],
          right: [],
        },
      );

      return pinnedColumns as GridPinnedColumnFields;
    } catch (error) {
      return { left: [], right: [] };
    }
  };

  return {
    gridSettings,
    storeGridSettings,
    getUpdatedColumnWidthsAfterResize,
    getGridColumnsWidths,
    getGridColumnsSort,
    getGridColumnsVisibility,
    getSingleGridSettings,
    getSingleGridSettingsAttribute,
    getGridColumnsOrder,
    getUpdatedColumnOrderAfterReordering,
    getPinnedColumnsFromGridSettings,
  };
};

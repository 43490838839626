import { Column } from '@devexpress/dx-react-grid';
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  GridRenderCellParams,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { get, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { format, parse, parseISO } from 'date-fns';
import {
  SELECT_ORDER_TO_EDIT_STUDY,
  SELECT_ORDER_TO_EDIT_STUDY_IN_DISTRIBUTE,
  SELECT_ORDER_TO_IMPORT,
  SELECT_ORDER_TO_NEW_STUDY_FORM,
} from 'constants/constants';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

export const columns: Column[] = [
  { name: 'originalDatetimeRequested', title: 'originalDatetimeRequested' },
  {
    name: 'name',
    title: 'name',
    getCellValue: (row) => {
      const lastname = get(row, 'patient.name.lastName', '');
      const firstname = get(row, 'patient.name.firstName', '');
      return `${lastname || ''} ${firstname || ''}`;
    },
  },
  { name: 'accessionNumber', title: 'accessionNumber' },
  {
    name: 'patientId',
    title: 'patientID',
    getCellValue: (row) => get(row, 'patient.identificationNumber', ''),
  },
  { name: 'unit', title: 'unit', getCellValue: (row) => get(row, 'unit.name', '') },
  {
    name: 'modality',
    title: 'modality',
    getCellValue: (row) => get(row, 'modality.name', ''),
  },
  { name: 'requestedProcedure', title: 'studyDescription' },
];

export const useRequestDialogColumnsDataGridPro = ({
  handleCloseDialog,
  resetParentForm,
  setValueParentForm,
  enableSortingForAllFields = true,
  actions = [],
  data = {},
  setOrderSelected,
}: any): GridColDef[] => {
  const { t } = useTranslation('Orders');

  const cols: any = [
    {
      field: 'actions',
      hideable: false,
      width: 80,
      headerName: t('actions'),
      renderCell: ({ row }: GridRenderCellParams) =>
        getRequestDialogActionButtons(
          actions,
          resetParentForm,
          setValueParentForm,
          handleCloseDialog,
          t,
          row,
          data,
          setOrderSelected,
        ),
      filterable: false,
      sortable: enableSortingForAllFields,
    },
    {
      field: 'originalDatetimeRequested',
      headerName: t('Studies:originalDatetimeRequested'),
      valueFormatter: (value: any) => {
        if (!value) return;
        const date = parse(value, "yyyy-MM-dd'T'HH:mm:ss", new Date());
        return format(date, 'dd.MM.yyyy HH:mm');
      },
      flex: 1,
      sortable: enableSortingForAllFields,
    },
    {
      field: 'name',
      headerName: t('name'),
      valueGetter: (value: any, row: any) => {
        const lastname = get(row, 'patient.name.lastName', '');
        const firstname = get(row, 'patient.name.firstName', '');
        return `${lastname || ''} ${firstname || ''}`;
      },
      flex: 1,
      sortable: enableSortingForAllFields,
    },
    {
      field: 'accessionNumber',
      headerName: t('accessionNumber'),
      flex: 1,
      sortable: enableSortingForAllFields,
    },
    {
      field: 'patientId',
      headerName: t('Studies:patientID'),
      valueGetter: (value: any, row: any) => get(row, 'patient.identificationNumber', ''),
      flex: 1,
      sortable: enableSortingForAllFields,
    },
    {
      field: 'unit',
      headerName: t('unit'),
      valueGetter: (value: any, row: any) => get(row, 'unit.name', ''),
      flex: 1,
      sortable: enableSortingForAllFields,
    },
    {
      field: 'modality',
      headerName: t('modality'),
      valueGetter: (value: any, row: any) => get(row, 'modality.name', ''),
      flex: 1,
      sortable: enableSortingForAllFields,
    },
    {
      field: 'requestedProcedure',
      headerName: t('Studies:studyDescription'),
      flex: 1,
      sortable: enableSortingForAllFields,
    },
  ];

  return cols;
};

// Four types of select buttons - for different forms (importNonDicom, distributeStudyDetail, editStudyDetail, splitStudyDetail)
const getRequestDialogActionButtons = (
  actions: string[],
  resetParentForm: any,
  setValueParentForm: any,
  handleCloseDialog: any,
  t: any,
  row: any,
  data: any = {},
  setOrderSelected: Dispatch<SetStateAction<boolean>>,
) => {
  let actionToShow;
  if (actions.includes(SELECT_ORDER_TO_IMPORT)) {
    actionToShow = (
      <GridActionsCellItem
        icon={
          <Tooltip title={t('Grid:choose')}>
            <CheckIcon />
          </Tooltip>
        }
        label={t('Grid:orderDetail')}
        onClick={() => {
          let modalitiesInStudy = get(row, 'modality.name', null);
          modalitiesInStudy = modalitiesInStudy
            ? { value: modalitiesInStudy, label: modalitiesInStudy }
            : null;
          const values = {
            ...pick(row, [
              'accessionNumber',
              'referringPhysician',
              'requestingPhysician',
              'patientDispositions',
              'studyInstanceUid',
            ]),
            patient: { ...row.patient, dateBirth: new Date(row.patient.dateBirth) },
            study: {
              ...pick(row, [
                'accessionNumber',
                'referringPhysician',
                'requestingPhysician',
                'patientDispositions',
                'studyInstanceUid',
              ]),
              studyDescription: get(row, 'requestedProcedure', ''),
              studyDate: get(row, 'originalDatetimeRequested', null)
                ? new Date(row.originalDatetimeRequested)
                : null,
              studyTime: get(row, 'originalDatetimeRequested', null)
                ? new Date(row.originalDatetimeRequested)
                : null,
              studyInstanceUid: get(row, 'studyInstanceUid', null),
            },
            modality: modalitiesInStudy,
          };
          //Will update only values that are passed in the values object
          if (resetParentForm) {
            resetParentForm(values);
          }
          if (setValueParentForm) {
            setValueParentForm('patient', values.patient);
            setValueParentForm('study', values.study);
            setValueParentForm('modality', values.modality);
          }
          setOrderSelected && setOrderSelected(true);
          handleCloseDialog();
        }}
      />
    );
    return actionToShow;
  }
  if (actions.includes(SELECT_ORDER_TO_EDIT_STUDY_IN_DISTRIBUTE)) {
    return (
      <GridActionsCellItem
        icon={
          <Tooltip title={t('Grid:choose')}>
            <CheckIcon />
          </Tooltip>
        }
        label={t('Grid:orderDetail')}
        onClick={() => {
          const values = {
            ...pick(row, [
              'patient',
              'accessionNumber',
              'referringPhysician',
              'requestingPhysician',
              'patientDispositions',
            ]),
            patient: { ...row.patient, dateBirth: new Date(row.patient.dateBirth) },
            studyDescription: get(row, 'requestedProcedure', ''),
            study: {
              studyDate: get(data, 'study.studyDate', null) ? new Date(data.study.studyDate) : null,
              studyTime: get(data, 'study.studyDate', null) ? new Date(data.study.studyTime) : null,
            },
            productIdTo: get(data, 'productIdTo', null),
            distributeMethod: get(data, 'distributeMethod', null),
            studies: get(data, 'studies', null),
            showEditForm: get(data, 'showEditForm', false),
          };
          resetParentForm(values);
          handleCloseDialog();
        }}
      />
    );
  }
  if (actions.includes(SELECT_ORDER_TO_EDIT_STUDY)) {
    return (
      <GridActionsCellItem
        icon={
          <Tooltip title={t('Grid:choose')}>
            <CheckIcon />
          </Tooltip>
        }
        label={t('Grid:orderDetail')}
        onClick={() => {
          const values = {
            ...pick(row, [
              'patient',
              'accessionNumber',
              'referringPhysician',
              'requestingPhysician',
              'patientDispositions',
              'performingPhysician',
            ]),
            patient: { ...row.patient, dateBirth: new Date(row.patient.dateBirth) },
            studyDescription: get(row, 'requestedProcedure', ''),
            study: {
              studyDate: get(data, 'study.studyDate', null) ? new Date(data.study.studyDate) : null,
              studyTime: get(data, 'study.studyDate', null) ? new Date(data.study.studyTime) : null,
            },
          };
          resetParentForm(values);
          handleCloseDialog();
        }}
      />
    );
  }
  if (actions.includes(SELECT_ORDER_TO_NEW_STUDY_FORM)) {
    return (
      <GridActionsCellItem
        icon={
          <Tooltip title={t('Grid:choose')}>
            <CheckIcon />
          </Tooltip>
        }
        label={t('Grid:choose')}
        onClick={() => {
          const values = {
            ...pick(row, [
              'patient',
              'accessionNumber',
              'referringPhysician',
              'requestingPhysician',
              'patientDispositions',
            ]),
            patient: { ...row.patient, dateBirth: new Date(row.patient.dateBirth) },
            studyDescription: get(row, 'requestedProcedure', ''),
            dateCreated: get(row, 'originalDatetimeRequested', null)
              ? new Date(row.originalDatetimeRequested)
              : null,
            timeCreated: get(row, 'originalDatetimeRequested', null)
              ? format(parseISO(row.originalDatetimeRequested), 'HH:mm')
              : null,
            performingPhysician: {},
          };
          resetParentForm({ targetStudy: values }, { keepDirtyValues: true });
          handleCloseDialog();
        }}
      />
    );
  }
};

// Only filter is being displayed
export const CustomGridToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
};

// Only sorting and filtering is being displayed
export function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
}

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Tooltip, Typography } from '@mui/material';
import { Help as HelpIcon } from '@mui/icons-material';
import { get, isString } from 'lodash';
import { HexColorPicker } from 'react-colorful';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getExchangeNetwork, editExchangeNetwork } from './_apiExchangeNetworks';
import { IExchangeNetwork, IExchangeNetworkFormContent } from './_types';
import useValidationSchema from './_formExchangeNetwork';

import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { Box } from '@mui/system';
import { PriorityButtons } from './PriorityButtons';

const ExchangeNetworkForm: React.FC<IExchangeNetworkFormContent> = ({
  toggleDialog,
  id,
  loadEntity,
}) => {
  const { t } = useTranslation('ExchangeNetworks');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { compactMode } = useAppInfo();

  const [entity, fetchEntity] = useState<IExchangeNetwork>();
  const [colorPickerVisible, setColorPickerVisible] = useState<boolean>(false);
  const { title } = useWithEntityTitle(false, entity, t);

  const { ExchangeNetworkFormSchema, formItems } = useValidationSchema(t);

  const methods = useForm<IExchangeNetwork>({
    resolver: yupResolver(ExchangeNetworkFormSchema),
  });
  const { handleSubmit, reset, register, watch, setValue } = methods;
  const watchColor = watch('color');
  const selectedPriority = watch('priority');
  const setPriority = (priority: any) => setValue('priority', priority);

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const response = await editExchangeNetwork(values);
    if (response === true) {
      addSuccessAlert(t('saved'));
      loadEntity();
      toggleDialog(false);
    } else {
      addErrorAlert(t(isString(response) ? response : 'errorSaving'));
    }
    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      let exchangeNetwork: IExchangeNetwork = { id: 0 };

      if (id) {
        const entity = await getExchangeNetwork(id.toString());
        exchangeNetwork = { ...entity };
        if (entity) {
          fetchEntity(exchangeNetwork);
        }
      }

      reset({ ...exchangeNetwork });
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setColor = (color: string) => {
    setValue('color', color);
  };

  const toggleColorPicker = () => {
    setColorPickerVisible(!colorPickerVisible);
  };
  return (
    <>
      {entity ? (
        <>
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <input {...register('active')} type="hidden" />
                <input {...register('name')} type="hidden" />
                <Grid container={true} alignItems="flex-end">
                  <Grid item={true} xs={12} md={6}>
                    {formItems.map((formItem, index) => {
                      const name = get(formItem, 'name', '');
                      return (
                        <React.Fragment key={`formItem-${index}`}>
                          <div style={{ position: 'relative' }}>
                            {name === 'color' && (
                              <>
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    right: (theme) => theme.spacing(2),
                                    top: (theme) =>
                                      compactMode ? theme.spacing(1) : theme.spacing(2),
                                    width: (theme) => theme.spacing(4),
                                    height: (theme) => theme.spacing(3),
                                    border: '1px solid #000',
                                    zIndex: 69,
                                    cursor: 'pointer',
                                    backgroundColor: `${watchColor}`,
                                  }}
                                  onClick={toggleColorPicker}
                                />
                              </>
                            )}
                            <FormInput {...formItem} />
                            {name === 'color' && colorPickerVisible && (
                              <HexColorPicker color={watchColor} onChange={setColor} />
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                  <Grid container={true} alignItems="flex-end">
                    <Grid item={true} xs={12} sx={{ mb: 2 }}>
                      {t('priority')}
                      <Tooltip title={t('priorityHelp') as string} style={{ cursor: 'pointer' }}>
                        <HelpIcon fontSize="small" />
                      </Tooltip>
                      <PriorityButtons
                        priority={selectedPriority ? selectedPriority : null}
                        setPriority={setPriority}
                      />
                    </Grid>

                    <Grid container={true} alignItems="flex-end">
                      <EntityButtons toggleDialog={toggleDialog} />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
        </>
      ) : (
        <Papeer>
          <Typography>{t('exchangeNetworkNotFound')}</Typography>
        </Papeer>
      )}
    </>
  );
};

export default ExchangeNetworkForm;

import { useEffect, useMemo, useState } from 'react';
import { Grid, Button, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';

import DicomGrid from 'components/Import/DicomGrid';
import Import from 'components/Import/Import';
import { useDicomParser } from 'components/Import/useFileParser';
import FormSelect from 'components/Form/Select/Select';
import useAlerts from 'components/Alerts/useAlerts';
import Header from 'components/Header/Header';
import { SEARCH_FUNCTION_IMPORT } from 'constants/constants';

import { sendMetadata } from './_api';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import useValidationSchema from './_form';
import { Papeer } from 'components/Papeer/Papeer';
import { get, pick, forEach, isObject, isEmpty, omit, filter, isArray } from 'lodash';
import PatientAndStudyEditForm from 'modules/Studies/Form/PatientAndStudyEditForm';
import { checkUploadingStatus, cleanUppy, createUppy } from 'utils/import';
import EditDicomTagsDialog from './Dicom/EditDicomTagsDialog';
import CustomUppyFolderInput from './CustomUppyFolderInput';
import CustomUppyFileInput from './CustomUppyFileInput';
import { format } from 'date-fns';
import { updatedDiff } from 'deep-object-diff';
import { IEditDicomTags, IUpdatedStudies } from './_types';
import { IEditedCells, IImportDicomFormValues, IPatient, ISeries } from 'components/Import/_types';
import { TourImportDicom } from './TourImportDicom';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import LinearProgressWithLabel from 'components/Import/LinearProgressWithLabel';
import { useDispatch } from 'react-redux';
import { setTourControlPropsToStore } from 'store/reducers/appReducer';

let uppyInstance: any;
let intervalUploading: any;

const ImportDicom: React.FC<any> = ({ order, onOrderCloseDialog }) => {
  const { t } = useTranslation('Import');
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [updatedStudies, setUpdatedStudies] = useState<IUpdatedStudies[]>([]); // seznam studií, u kterých se změnily dicom tagy přes editaci v gridu
  const [editDialogState, setEditDialogState] = useState<boolean>(false);
  const [patientForEditDialog, setPatientForEditDialog] = useState<IPatient>({} as IPatient);
  const { parseDicomFiles } = useDicomParser({ setPatients });
  const [timestamp, setTimestamp] = useState(new Date().getTime().toString());
  const [completed, setCompleted] = useState(0);
  const { getArchivesForFunctions, modalities, validators, portalSetting } = useAppInfo();
  const [editedCells, setEditedCells] = useState<IEditedCells>({});
  const [selectedSeries, setSelectedSeries] = useState<Map<string, ISeries>>(new Map());
  const [seriesDetailOpened, setSeriesDetailOpened] = useState<{ [id: string]: boolean }>({});

  const { toggleLoader } = useAppGlobals();
  const { user } = useUser();
  useWithTitle();
  const dispatch = useDispatch();

  useMemo(() => {
    // componentWillMount events
    createUppy('uppy', parseDicomFiles, timestamp, [], true);
    uppyInstance = (window as any).UppyGlobalInstances[
      (window as any).UppyGlobalInstances.length - 1
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isObject(order) && !isEmpty(order)) {
      setOrderSelected(true);
    }
    intervalUploading = setInterval(() => {
      checkUploadingStatus(setCompleted);
    }, 500);
    return () => {
      clearInterval(intervalUploading);
      cleanUppy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log('uppy', uppyInstance, uppyInstance.getState());
  // console.log('globalUppy', (window as any).UppyGlobalInstances);

  const defaultModality = useMemo(() => {
    const modality = filter(modalities, { default: true }).map((mod) => ({
      value: mod.name,
      label: mod.name,
    }));
    return isArray(modality) && modality.length ? modality[0] : null;
  }, [modalities]);

  const [orderSelected, setOrderSelected] = useState<boolean>(false);
  const [editAllowedForOrderImport, setEditAllowedForOrderImport] = useState<boolean>(false);

  // const { steps, tourControlProps } = StepsDicom({ patients });

  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const sources: { label: string; id: number }[] = getArchivesForFunctions(
    SEARCH_FUNCTION_IMPORT,
  ).map((archive) => ({ id: archive.id, label: archive.name }));

  const { ImportDicomValidationScheme, ModalityWorklistImportDicomValidationScheme } =
    useValidationSchema(t, get(validators, 'importForm', {}), portalSetting);

  const methods = useForm<any>({
    resolver: yupResolver(
      order ? ModalityWorklistImportDicomValidationScheme : ImportDicomValidationScheme,
    ),
    defaultValues: order
      ? {
          patient: {
            ...pick(order.patient, ['name', 'identificationNumber', 'sex']),
            dateBirth: order?.patient?.dateBirth ? new Date(order.patient.dateBirth) : null,
          },
          study: {
            accessionNumber: get(order, 'accessionNumber'),
            studyDescription: get(order, 'requestedProcedure'),
            studyDate: order?.originalDatetimeRequested
              ? new Date(order.originalDatetimeRequested)
              : null,
            studyTime: order?.originalDatetimeRequested
              ? new Date(order.originalDatetimeRequested)
              : null,
            studyInstanceUid: get(order, 'studyInstanceUid'),
            patientDispositions: get(order, 'patientDispositions', {}),
          },
          modality: order?.modality
            ? { value: order?.modality?.name, label: order?.modality?.name }
            : defaultModality,
          // requestingPhysician: get(order, 'requestingPhysician', {}),
          // referringPhysician: get(order, 'referringPhysician', {}),
          // performingPhysician: get(order, 'performingPhysician', {}),
          archiveId: sources.length === 1 ? get(sources, '[0].id') : null,
        }
      : { archiveId: sources.length === 1 ? get(sources, '[0].id') : null },
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = methods;

  const selectedSource = getValues('archiveId');

  //pokud se refreshne stranka, tak se nevybere archiv, tento useEffect to napravi
  useEffect(() => {
    if (!selectedSource && sources.length === 1) {
      setValue('archiveId', get(sources, '[0].id'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sources, selectedSource]);

  const removePatient = (patient: IPatient) => {
    const files = get(patient, 'files', []);
    forEach(files, (file) => {
      uppyInstance?.removeFile(get(file, 'id', ''));
    });
    setPatients((prevPatients) => [
      ...prevPatients.filter((pat: IPatient) => pat.id !== patient.id),
    ]);
  };

  useEffect(() => {
    //console.debug('files.length is: ', patients.length);
    if (patients.length <= 0) {
      dispatch(setTourControlPropsToStore({ isTourOpen: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patients]);

  const updatePatients = (
    newValuesPatient: IEditDicomTags,
    initialIdentificationNumber: string,
  ) => {
    setPatients((currentPatients) =>
      currentPatients.map((patient) => {
        if (patient.patID === initialIdentificationNumber) {
          const updatedPatient = serializeDicomTags(patient, newValuesPatient);
          const differences = updatedDiff(patient, updatedPatient);
          if (Object.keys(differences).length > 0) {
            // If there are differences, update the editedCells state for the specific fields
            setEditedCells((prev: IEditedCells) => {
              return {
                ...prev,
                [updatedPatient.id]: {
                  ...(prev[updatedPatient.id] || {}),
                  ...Object.keys(differences).reduce((acc, key) => ({ ...acc, [key]: true }), {}),
                },
              };
            });
          }
          return updatedPatient;
        }
        return patient;
      }),
    );
  };

  const removeAllPatients = () => {
    forEach(patients, (patient) => {
      const files = get(patient, 'files', []);
      forEach(files, (file) => {
        uppyInstance.removeFile(get(file, 'id'));
      });
    });
    setPatients([]);
    dispatch(setTourControlPropsToStore({ isTourOpen: false }));
  };

  const onSubmit = async (values: IImportDicomFormValues) => {
    toggleLoader();
    const patient: any = get(values, 'patient', {});
    const dateBirth: any = get(values, 'patient.dateBirth');
    const study = order
      ? {
          ...omit({ ...get(values, 'study', {}) }, ['studyDate', 'studyTime']),
          patient: {
            ...(patient || {}),
            dateBirth:
              dateBirth && dateBirth instanceof Date
                ? format(dateBirth, 'yyyy-MM-dd') + 'T00:00:00.000Z'
                : null,
          },
          modalitiesInStudy: [get(values, 'modality.value', 'OT')],
          // referringPhysician: get(values, 'referringPhysician'),
          // requestingPhysician: get(values, 'requestingPhysician'),
        }
      : null;
    const data = {
      archiveId: values.archiveId,
      path: `${timestamp}_${user?.sub}`,
      timestamp,
      typeOfUpload: order ? 'mwl' : 'dicom',
      study,
      ...(order
        ? {
            isMwl: true,
            updatedStudies: patients.map((patient) => {
              return {
                ...study,
                studyInstanceUid: get(patient, 'studyInstanceUid', null),
              };
            }),
          }
        : {}),
      ...(!isEmpty(updatedStudies) ? { updatedStudies } : {}),
    };
    uppyInstance.upload().then(async () => {
      // Upload complete
      const resp = await sendMetadata(data);
      if (resp) {
        addSuccessAlert(t('notifications.success'));

        // const newTimestamp = new Date().getTime().toString();
        // setTimestamp(newTimestamp);
        if (order) {
          onOrderCloseDialog();
        }
        // setUppy(() => createUppy('uppy', parseDicomFiles, timestamp));
      } else {
        addErrorAlert(t('notifications.error'));
      }
    });
    setTimeout(() => {
      toggleLoader(false);
    }, 3000);
    // reset + new upload instance
    setPatients([]);
    setEditedCells({});
    reset({});
    const newTimestamp = new Date().getTime().toString();
    createUppy('uppy', parseDicomFiles, newTimestamp, [], true);
    uppyInstance = (window as any).UppyGlobalInstances[
      (window as any).UppyGlobalInstances.length - 1
    ];
    setTimestamp(newTimestamp);
  };

  const toggleEditDialog = (state: boolean = true) => {
    setEditDialogState(state);
  };

  const renderedSteps = () => {
    return <TourImportDicom patients={patients} />;
  };

  return (
    <>
      <DevTool control={control} />
      <FormProvider {...methods}>
        {!order && <Header title={t('dicom.title')} TourComponent={renderedSteps()} />}
        {completed ? (
          <div>
            <LinearProgressWithLabel value={completed} />
            <Typography component="div" sx={{ marginTop: '4px', marginBottom: '8px' }}>
              {t('uploadingFiles')}
            </Typography>
          </div>
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Papeer>
            <Grid container spacing={2}>
              {!patients.length && (
                <Grid item xs={12} data-tour="import-dicom-uppySelector">
                  {/*ref={uppyRef}*/}
                  {uppyInstance && <Import uppy={uppyInstance} />}
                </Grid>
              )}

              {patients && patients.length > 0 && (
                <>
                  <Grid item xs={12} mt={2} data-tour="import-dicom-grid">
                    <DicomGrid
                      patients={patients}
                      removePatient={removePatient}
                      toggleEditDialog={toggleEditDialog}
                      setPatientForEditDialog={setPatientForEditDialog}
                      editedCells={editedCells}
                      enableMasterDetail={true}
                      uppy={uppyInstance}
                      selectedSeries={selectedSeries}
                      setSelectedSeries={setSelectedSeries}
                      seriesDetailOpened={seriesDetailOpened}
                      setSeriesDetailOpened={setSeriesDetailOpened}
                      hideEditPatient={order ? true : false}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    sx={{ mt: 1, gap: 1 }}
                  >
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={removeAllPatients}
                      data-tour="import-dicom-deleteAll"
                    >
                      {t('Import:removeAll')}
                    </Button>
                    <Box data-tour="import-dicom-uploadFiles">
                      <CustomUppyFileInput uppy={uppyInstance} />
                    </Box>
                    <Box data-tour="import-dicom-uploadFolder">
                      <CustomUppyFolderInput uppy={uppyInstance} />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Papeer>

          <Grid item xs={12} mt={2}>
            {order && (
              <PatientAndStudyEditForm
                isRequest={false}
                errors={errors}
                // isStudyEditType={true}
                validatorFormName="importForm"
                modalities={modalities.map((modality) => ({
                  value: modality.name,
                  label: modality.name,
                }))}
                multipleModality={false}
                isImport={true}
                isDicomOrder={true}
                collapseForm={!!order}
                orderSelected={orderSelected}
                editAllowedForOrderImport={editAllowedForOrderImport}
                setEditAllowedForOrderImport={setEditAllowedForOrderImport}
              />
            )}
          </Grid>

          <Papeer sx={{ mt: 2, mb: 4 }}>
            <Grid container spacing={0} alignItems="center" gap={{ xs: 1, md: 0 }}>
              <Grid item xs={12} md={4} lg={3} data-tour="import-dicom-targetArchive">
                <FormSelect
                  name="archiveId"
                  label={t('archive')}
                  items={sources}
                  errors={errors}
                  required={true}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={9}
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!patients.length}
                  data-tour="import-dicom-saveButton"
                >
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>
      <EditDicomTagsDialog
        editDialogState={editDialogState}
        toggleEditDialog={toggleEditDialog}
        patientForEditDialog={patientForEditDialog}
        updatePatients={updatePatients}
        setUpdatedStudies={setUpdatedStudies}
      />
    </>
  );
};

export default ImportDicom;

const serializeDicomTags = (
  existingPatientToUpdate: IPatient,
  newValuesPatient: IEditDicomTags,
) => {
  const { name, dateBirth, identificationNumber, sex } = newValuesPatient;

  existingPatientToUpdate = {
    ...existingPatientToUpdate,
    name: [name.lastName, name.firstName, name.middleName, name.prefix, name.suffix]
      .filter(Boolean) // filter empty values
      .join('^'),
    patID: identificationNumber,
    patSex: sex,
    dateBirth: dateBirth && format(new Date(dateBirth), 'yyyyMMdd'),
  };

  return existingPatientToUpdate;
};

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { compact, filter, get, isArray, isEmpty } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getRegisterGroup, createRegisterItem, editRegisterItem, getRegisterItem } from '../_api';
import useValidationSchema from './_form';

import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';
import { Line } from 'components/Line/Line';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { IRegisterGroup, IRegisterItem } from 'components/TagForm/_types';

export const RegisterItemForm: React.FC = () => {
  const { t } = useTranslation('RegisterGroups');
  const params = useParams();
  const parentId = get(params, 'parentId', '');
  const linkBack = `/tags/registerGroups/${parentId}`;
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { id, isCreating } = useEntityInfo();

  const [entity, fetchEntity] = useState<IRegisterItem>();
  const [registerGroup, setRegisterGroup] = useState<IRegisterGroup>();
  const [registerItems, setParentRegisterItems] = useState<IRegisterItem[]>([]);
  const [title, setTitle] = useState<string>('');
  const navigate = useNavigate();
  const { setPageTitle } = useWithTitle();

  const { RegisterItemSchema } = useValidationSchema();

  const methods = useForm<IRegisterItem>({
    resolver: yupResolver(RegisterItemSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const name = get(values, 'name', '?').toLowerCase();
    const currentItemId = get(values, 'id');
    const registerItems = get(registerGroup, 'registerItems', null);
    const isUsed = isArray(registerItems)
      ? compact(
          registerItems.map((item: IRegisterItem) => {
            if (get(item, 'id', 0) !== currentItemId) {
              if (get(item, 'name', '').toLowerCase() === name) {
                return 'isUsed';
              }
            }
            return null;
          }),
        )
      : [];
    if (isUsed.length > 0) {
      addErrorAlert(t('errorSaveRegisterItemIsUsedName'));
    } else {
      const registerItem = {
        ...values,
        languageJsonText: JSON.stringify(get(values, 'languageJsonText')),
        registerGroupId: Number(parentId),
      };
      const response = isCreating
        ? await createRegisterItem(registerItem as IRegisterItem)
        : await editRegisterItem(registerItem as IRegisterItem);
      if (response === true) {
        addSuccessAlert(t('registerItemSaved', { registerItemName: values.name }));
        navigate(linkBack);
      } else {
        addErrorAlert(t('errorSaveRegisterItem'));
      }
    }
    toggleLoader(false);
  });

  const getEntities = async () => {
    toggleLoader();
    try {
      let registerItem: IRegisterItem = { id: 0, active: true, name: '' };

      if (!isCreating && id) {
        const entity = await getRegisterItem(id.toString());
        registerItem = { ...entity };
        if (entity) {
          fetchEntity(entity);
        }
      }
      reset({ ...registerItem });
    } catch (e) {
      console.debug(e);
    }

    const registerGroup = await getRegisterGroup(parentId);
    const title = `${isCreating ? t('createRegisterItem') : `${t('editRegisterItem')}`} ${get(
      registerGroup,
      'name',
      '',
    )}`;
    setPageTitle(title);
    setTitle(title);
    setRegisterGroup(registerGroup);
    if (registerGroup && get(registerGroup, 'parent')) {
      const parentRegisterItems: IRegisterItem[] = get(
        get(registerGroup, 'parent'),
        'registerItems',
        [],
      );
      if (isArray(parentRegisterItems)) {
        setParentRegisterItems(filter(parentRegisterItems, ['active', true]));
      }
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isEmpty(entity) || isCreating ? (
        <>
          <Header title={title} />
          <Papeer bottomMargin={true}>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <input {...register('active')} type="hidden" />
                <Grid container={true}>
                  <Grid item={true} xs={12} md={4} lg={3}>
                    <FormInput name="name" label={t('name')} required={true} />
                  </Grid>
                  {registerItems.length > 0 && (
                    <>
                      <Grid item={true} xs={12}>
                        <Line />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <SimpleCheckboxArray
                          name="parentRegisterItems"
                          label={t('parentRegisterItems')}
                          items={registerItems}
                          defaultValue={get(entity, 'parentRegisterItems', [])}
                          grid={{ xs: 12, md: 6, lg: 4, xl: 3 }}
                          required={true}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Line />
                      </Grid>
                    </>
                  )}
                </Grid>
                <EntityButtons
                  linkBack={linkBack}
                  otherStyles={{ marginTop: registerItems.length ? 0 : 8 }}
                />
              </form>
            </FormProvider>
          </Papeer>
        </>
      ) : (
        <Papeer>
          <Typography>{t('registerItemNotFound')}</Typography>
        </Papeer>
      )}
    </>
  );
};

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { AppBar, Tab, Tabs } from '@mui/material';

import Priorities from './Priorities';
import Operations from './Operations';
import Dictionaries from './Dictionaries';
import Languages from './Languages';
import NotificationSettings from './NotificationSettings';
import NotificationTemplates from './NotificationTemplates';

import { useUser } from 'utils/hooks/useUser';
import Hospitals from './Hospitals';
import Studies from './Studies';

export const GeneralSettings: React.FC = () => {
  const { t } = useTranslation('GeneralSettings');
  const { hasRole } = useUser();

  let [searchParams] = useSearchParams();
  const tabFromUrl = searchParams.get('tab') || '';
  const tab = tabFromUrl
    ? isArray(tabFromUrl)
      ? tabFromUrl[0]
      : tabFromUrl
    : hasRole('ROLE_DICTIONARY') ||
      hasRole('ROLE_DECUBITS_MANAGE') ||
      hasRole('ROLE_CAN_MANAGE_CLOUD_PACS_RECIPIENTS')
    ? 'dictionaries'
    : hasRole('ROLE_LANGUAGE')
    ? 'languages'
    : hasRole('ROLE_NOTIFICATION_ADMIN')
    ? 'notificationTemplates'
    : hasRole('ROLE_NOTIFICATION_USER')
    ? 'notificationSettings'
    : hasRole('ROLE_PRIORITY_AND_OPERATION')
    ? 'priorities'
    : '';

  const [activeTab, setActiveTab] = useState<string>(tab);

  return (
    <>
      <AppBar position="static" color="default" sx={{ mt: 2, mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons={false}
        >
          {hasRole('ROLE_DICTIONARY') && (
            <Tab key="dictionaries" value="dictionaries" label={t('setDictionaries')} />
          )}
          {hasRole('ROLE_LANGUAGE') && (
            <Tab key="languages" value="languages" label={t('setLanguages')} />
          )}
          {hasRole('ROLE_NOTIFICATION_ADMIN') && (
            <Tab
              key="notificationTemplates"
              value="notificationTemplates"
              label={t('setNotificationTemplates')}
            />
          )}
          {hasRole('ROLE_NOTIFICATION_USER') && (
            <Tab
              key="notificationSettings"
              value="notificationSettings"
              label={t('setNotificationSettings')}
            />
          )}
          {hasRole('ROLE_PRIORITY_AND_OPERATION') && (
            <Tab key="priorities" value="priorities" label={t('setPriorities')} />
          )}
          {hasRole('ROLE_PRIORITY_AND_OPERATION') && (
            <Tab key="operations" value="operations" label={t('setOperations')} />
          )}
          <Tab key="hospitals" value="hospitals" label={t('setHospitals')} />
          <Tab key="studies" value="studies" label={t('setStudies')} />
        </Tabs>
      </AppBar>
      {activeTab === 'dictionaries' && <Dictionaries />}
      {activeTab === 'languages' && <Languages />}
      {activeTab === 'notificationTemplates' && <NotificationTemplates />}
      {activeTab === 'notificationSettings' && <NotificationSettings />}
      {activeTab === 'priorities' && <Priorities />}
      {activeTab === 'operations' && <Operations />}
      {activeTab === 'hospitals' && <Hospitals />}
      {activeTab === 'studies' && <Studies />}
    </>
  );
};

import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';
import { find, get } from 'lodash';

const useValidationSchema = (t: TFunction, languages: any[], editingDatabaseWorkPlace: boolean) => {
  const { required } = useValidators();

  const language = find(languages, { default: true });

  const WorkplaceFormSchema = yup.object().shape({
    ...(editingDatabaseWorkPlace
      ? { [`text_${get(language, 'abbreviation')}`]: required(yup.string().trim()) }
      : {}),
    code: required(yup.string()),
  });

  const languagesItems = [
    ...(languages || []).map((lang: any) => ({
      name: `text_${lang.abbreviation}`,
      label: `${t('name')} - ${get(lang, 'name', '')}`,
      required: get(lang, 'default', false),
    })),
  ];
  const formItems = [
    {
      name: 'code',
      label: t('code'),
      required: true,
    },
    {
      type: 'select',
      name: 'viewer_id',
      label: t('viewer'),
    },
  ];

  return { WorkplaceFormSchema, languagesItems, formItems };
};

export default useValidationSchema;

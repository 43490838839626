import { Theme } from '@mui/material';

const styles = {
  toolbarButton: ({ spacing }: Theme) => ({
    marginRight: spacing(),
    marginTop: spacing(),
    border: '1px solid #ccc',
  }),
  dicomTagSelect: ({ spacing }: Theme) => ({
    marginTop: spacing(2),
  }),
};

export default styles;

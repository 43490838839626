import { Grid, Typography } from '@mui/material';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import { Papeer } from 'components/Papeer/Papeer';
import React, { useEffect } from 'react';
import { IRetireForm } from './_types';
import { get } from 'lodash';
import FormDatePicker from 'components/Form/Date/Date';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import useRetireForm from './useRetireForm';
import { format } from 'date-fns';

const RetireForm: React.FC<IRetireForm> = ({ clearAction, retireUser, loadEntity }) => {
  const { t } = useTranslation('Users');
  const methods = useForm<any>({});
  const { watch, handleSubmit } = methods;

  const { submitHandler, retiredDate, dateTo, setRetiredDate } = useRetireForm({
    clearAction,
    loadEntity,
    retireUser,
  });

  useEffect(() => {
    const formDateValue = get(watch(), 'retiredDate', null);
    if (formDateValue !== retiredDate) {
      setRetiredDate(formDateValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  return (
    <Papeer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Grid container={true} alignItems="flex-end">
            <Grid item={true} xs={12}>
              <FormDatePicker
                type="datePicker"
                name="retiredDate"
                label={t('retiredDate')}
                required={true}
                defaultValue={retiredDate}
              />
            </Grid>
            <Grid item={true} xs={12} style={{ marginTop: 48 }}>
              <Typography component="div">
                {t('retireInfoText', {
                  firstName: get(retireUser, 'firstName', ''),
                  lastName: get(retireUser, 'lastName', ''),
                  username: get(retireUser, 'username', ''),
                  retiredDate: retiredDate ? format(retiredDate, 'dd.MM.yyyy') : '',
                  dateTo: dateTo ? format(dateTo, 'dd.MM.yyyy') : '',
                })}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <EntityButtons toggleDialog={clearAction} otherStyles={{ marginTop: 128 }} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Papeer>
  );
};

export default RetireForm;

import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { each, find, get, includes, isString, size } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Collapse, Tooltip, Typography } from '@mui/material';

import { useUser } from 'utils/hooks/useUser';
import { useToken } from 'utils/hooks/useToken';
import { Papeer } from 'components/Papeer/Papeer';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import FormInput from 'components/Form/Input/Input';
import { useStorage } from 'utils/hooks/useStorage';
import { useValidators } from 'utils/hooks/useValidators';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import randomize from 'randomatic';
import { ILoginForm, ILoginMethodResponse } from './_types';
import {
  login,
  getValidators,
  getRoles,
  getAuthConfig,
  autologin,
  login2FA,
  forgottenPassword,
} from './_api';
import {
  BASKET,
  LOGIN_METHODS_WITH_AUTOLOGIN,
  LOGIN_METHODS_WITH_LOGIN_FORM,
  LOGIN_METHOD_OPENID,
  THEME_MODE_DARK,
  THEME_MODE_LIGHT,
} from 'constants/constants';
import { getOpenIdProfileConfig } from 'modules/Start/_api';
import { Box } from '@mui/system';
import { useActions } from 'utils/hooks/useActions';
import { blue } from '@mui/material/colors';
import useAlerts from 'components/Alerts/useAlerts';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import ResetPasswordForm from './ResetPasswordForm';
import LoginAndPasswordRecoveryContent from './LoginAndPasswordRecoveryContent';
import { useAppDispatch } from 'store/hooks';
import { setUse2FA } from 'store/reducers/appReducer';

export const Login: React.FC = () => {
  const { t } = useTranslation('Login');
  const { tokenDecoder } = useToken();
  const { loadUserToStore, loadRolesToStore, authType } = useUser();
  const { resetStudies, storeSearchResults } = useActions();
  const { loadValidatorsToStore } = useValidators();
  const { putItem, getItem, removeItem } = useStorage();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formError, setFormError] = useState<string>();
  const { lastPageState } = useAppInfo();
  const methods = useForm<ILoginForm>();
  const { handleSubmit, watch } = methods;
  const { toggleLoader, setMode } = useAppGlobals();
  const [canShowForm, setCanShowForm] = useState<boolean>(false);
  const [require2FACode, setRequire2FACode] = useState<boolean>(false);
  const [showOpenIdButton, setShowOpenIdButton] = useState<boolean>(false);
  const [showOpenIdLogoutButton, setShowOpenIdLogoutButton] = useState<boolean>(false);
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState<boolean>(false);
  const [showResetPasswordDialogButton, setShowResetPasswordDialogButton] =
    useState<boolean>(false);

  const [initialLoaded, setInitialLoaded] = useState<boolean>(false);
  const [userHasToScanQrCode, setUserHasToScanQrCode] = useState<boolean>(false);
  const [generatedKey, setGeneratedKey] = useState<any>(undefined);
  const [loggedOutForInactivity, setLoggedOutForInactivity] = useState<boolean>(false);

  const ssoFailed: boolean = window.location.href.indexOf('?ssoFailed=true') > -1;
  const loggedOut: boolean = window.location.href.indexOf('?loggedOut=true') > -1;
  const idmLoggedOut: boolean = window.location.href.indexOf('?idmLoggedOut=true') > -1;
  const accessDenied: boolean = window.location.href.indexOf('?error=access_denied') > -1;

  const watchUsername = watch('username');
  useEffect(() => {
    if (loggedOutForInactivity || ssoFailed || loggedOut || idmLoggedOut || accessDenied) {
      window.history.pushState(null, '', '/login');
    }
  }, [accessDenied, idmLoggedOut, loggedOut, loggedOutForInactivity, navigate, ssoFailed]);

  const confirmScanned = () => {
    setGeneratedKey(undefined);
    setUserHasToScanQrCode(false);
  };

  const finishLogin = async (response: ILoginMethodResponse) => {
    const targetUrl = get(lastPageState, 'url', '/');
    try {
      const decodedToken = tokenDecoder(response.token);
      console.log('decodedToken', decodedToken);
      if (!decodedToken.expired) {
        putItem('token', response.token as string);
        dispatch(setUse2FA(decodedToken.requireTOTPAuth as boolean));
        resetStudies(BASKET);
        loadUserToStore(decodedToken);
        setMode(decodedToken.darkMode ? THEME_MODE_DARK : THEME_MODE_LIGHT);
        const [validators, roles] = await Promise.all([getValidators(), getRoles()]);
        loadValidatorsToStore(validators);
        loadRolesToStore(roles);
        putItem('validators', JSON.stringify(validators));
        setRequire2FACode(false);
        navigate(targetUrl);
      }
    } catch (e) {
      setShowResetPasswordDialogButton(true);
      setFormError(t('error.tokenDecodeError'));
    }
  };

  const redirectToOpenIdLogout = async () => {
    try {
      toggleLoader();
      const openIdConfig = await getOpenIdProfileConfig();
      const openIdLogoutUrl = openIdConfig.logoutUri + '?client_id=' + openIdConfig.clientId;
      window.location.href = openIdLogoutUrl;
    } catch (e) {
      setShowResetPasswordDialogButton(true);
      setFormError(t('error.generalError'));
    }
  };

  const redirectToOpenIdLogin = async () => {
    try {
      let randomState = getItem('openidState');
      if (!randomState) {
        randomState = randomize('aA0', 128);
      }
      const openIdConfig = await getOpenIdProfileConfig();
      const openIdAuthUrl =
        openIdConfig.gatewayUri +
        '?response_type=code&client_id=' +
        openIdConfig.clientId +
        '&grant_type=password&redirect_uri=' +
        openIdConfig.backRedirectURL +
        '&state=' +
        randomState;
      putItem('openidState', randomState as string);
      window.location.href = openIdAuthUrl;
    } catch (e) {
      setShowResetPasswordDialogButton(true);
      setFormError(t('error.generalError'));
    }
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    if (!require2FACode) {
      const response = await login({ ...values });
      if (response.success) {
        setShowResetPasswordDialogButton(false);
        setFormError('');
        if (!response.totpRequired) {
          setUserHasToScanQrCode(false);
          try {
            const decodedToken = tokenDecoder(response.token);
            if (decodedToken.totpAuthenticated) {
              finishLogin(response);
            }
            if (decodedToken.requireTOTPAuth) {
              putItem('token', response.token as string);
              setRequire2FACode(true);
            }
          } catch (e) {
            setShowResetPasswordDialogButton(true);
            setFormError(t('error.tokenDecodeError'));
          }
        } else {
          setGeneratedKey(response.token);
          setUserHasToScanQrCode(true);
        }
      } else {
        setShowResetPasswordDialogButton(true);
        setFormError(
          t(response.msg && isString(response.msg) ? response.msg : 'error.generalError'),
        );
      }
    } else {
      const response = await login2FA({ ...values });
      if (response.success) {
        const decodedToken = tokenDecoder(response.token);
        if (decodedToken.requireTOTPAuth && decodedToken.totpAuthenticated) {
          finishLogin(response);
        }
        finishLogin(response);
      } else {
        if (get(response, 'removeToken', false)) {
          removeItem('token');
          setRequire2FACode(false);
        }
        setShowResetPasswordDialogButton(true);
        setFormError(
          t(response.msg && isString(response.msg) ? response.msg : 'error.generalError'),
        );
      }
    }
    toggleLoader(false);
  });

  const callAutologin = async (type: string) => {
    toggleLoader();
    const response = await autologin(type);
    if (response.success) {
      if (!response.totpRequired) {
        setUserHasToScanQrCode(false);
        const decodedToken = tokenDecoder(response.token);
        if (decodedToken.totpAuthenticated) {
          finishLogin(response);
        }
        if (decodedToken.requireTOTPAuth) {
          putItem('token', response.token as string);
          setRequire2FACode(true);
        }
      } else {
        setGeneratedKey(response.token);
        setUserHasToScanQrCode(true);
      }
    } else {
      setShowResetPasswordDialogButton(false);
      setFormError(t('error.generalSSOError'));
    }
    toggleLoader(false);
  };

  const loadAuthConfig = async () => {
    const authConfig = await getAuthConfig();
    let stopLoader = true;
    if (authConfig) {
      const allowedAuthMethods = get(authConfig, 'allowedAuthMethods', []);
      let checkOpenId = false;

      each(allowedAuthMethods, (method) => {
        const type = method.type;
        if (includes(LOGIN_METHODS_WITH_LOGIN_FORM, type)) {
          setCanShowForm(true);
        }
        if (includes(LOGIN_METHODS_WITH_AUTOLOGIN, type)) {
          if (
            !loggedOutForInactivity &&
            !loggedOut &&
            !ssoFailed &&
            !idmLoggedOut &&
            !accessDenied
          ) {
            callAutologin(type);
          }
        }
        if (includes(['openid'], type)) {
          if (get(method, 'showForm', false)) {
            setCanShowForm(true);
          } else {
            checkOpenId = true;
          }
        }
      });

      if (checkOpenId) {
        let canRedirect = true;
        let validState = false;
        const storageState = getItem('openidState');
        if (storageState && window.location.href.indexOf('state=') !== -1) {
          let urlState = get(queryString.parse(window.location.search), 'state', null);
          if (urlState && typeof urlState === 'string') {
            urlState = urlState.replace(/\W/g, '');
            if (urlState === storageState) {
              validState = true;
            }
          }
        }
        if (validState && window.location.href.indexOf('code=') !== -1) {
          let code = get(queryString.parse(window.location.search), 'code', null);
          if (code && typeof code === 'string') {
            code = code.replace(' ', '');
          }
          const openIdState = getItem('openidState');
          if (openIdState) {
            putItem('openidState', openIdState);
          }
          if (code && typeof code === 'string') {
            putItem('openidCode', code);
          }
          const response = await login({
            username: '',
            passwrod: '',
          });
          if (response.success) {
            removeItem('state');
            const decodedToken = tokenDecoder(response.token);
            if (!decodedToken.expired) {
              if (decodedToken.totpAuthenticated) {
                finishLogin(response);
                canRedirect = false;
              }
              if (decodedToken.requireTOTPAuth) {
                putItem('token', response.token as string);
                setRequire2FACode(true);
                canRedirect = false;
              }
            }
          }
        }

        if (canRedirect) {
          setShowOpenIdButton(true);
          if (
            !ssoFailed &&
            !loggedOut &&
            !loggedOutForInactivity &&
            !idmLoggedOut &&
            !accessDenied
          ) {
            const openId = find(allowedAuthMethods, { type: LOGIN_METHOD_OPENID });
            if (
              get(openId, 'order', 999) === 1 ||
              (openId !== undefined && size(allowedAuthMethods) === 1)
            ) {
              redirectToOpenIdLogin();
              stopLoader = false;
            }
          }
        }
        if (loggedOut) {
          toggleLoader();
          stopLoader = false;
          const openIdConfig = await getOpenIdProfileConfig();
          if (get(openIdConfig, 'id') === authType) {
            if (openIdConfig.autoLogout) {
              redirectToOpenIdLogout();
            } else {
              setShowOpenIdLogoutButton(true);
              stopLoader = true;
            }
          } else {
            stopLoader = true;
          }
        }
      }
      setInitialLoaded(true);
    }

    if (stopLoader) {
      toggleLoader(false);
    }
  };

  useEffect(() => {
    loadAuthConfig();
    const loggedOutForInactivity: boolean = window.location.href.indexOf('?inactive=true') > -1;
    if (!loggedOutForInactivity) {
      removeItem('searchFormValues');
      storeSearchResults([]);
    }
    setLoggedOutForInactivity(loggedOutForInactivity);
    const pathname = window.location.pathname;
    const search = window.location.search;
    const url = pathname.replace('portal/', '') + search;
    const error = get(queryString.parse(window.location.search), 'error', null);
    if (error) {
      addErrorAlert(t(error as string));
    }
    navigate(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callForgottenPassword = async (username: string) => {
    toggleLoader();
    const response = await forgottenPassword({ username });
    if (response) {
      addSuccessAlert(t('resetPasswordSend'));
    } else {
      addErrorAlert(t('resetPasswordSErrorSending'));
    }
    toggleLoader(false);
  };

  return (
    <>
      <LoginAndPasswordRecoveryContent>
        {initialLoaded ? (
          <Box sx={{ maxWidth: { sm: 400 } }}>
            <Papeer sx={{ flex: 0 }}>
              {userHasToScanQrCode ? (
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                    }}
                  >
                    {generatedKey !== undefined && (
                      <Tooltip title={get(generatedKey, 'secretKey')} placement="top">
                        <img
                          src={`data:${get(generatedKey, 'imgType')};base64, ${get(
                            generatedKey,
                            'qrCode',
                          )}`}
                          alt="QR code"
                          width="100%"
                        />
                      </Tooltip>
                    )}
                  </Box>
                  <Typography component="p" sx={{ mb: 2 }}>{`* ${t('explainPage')}`}</Typography>
                  <Typography component="p" sx={{ mb: 2 }}>
                    {`* ${t('explainQRCodeApps')}`}
                    <a
                      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=cs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Play
                    </a>
                    {`${t('explainQRCodeAppsOr')}`}
                    <a
                      href="https://apps.apple.com/cz/app/google-authenticator/id388497605?l=cs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      App Store
                    </a>
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    fullWidth={true}
                    onClick={confirmScanned}
                  >
                    {t('scaned')}
                  </Button>
                </Box>
              ) : (
                <>
                  {canShowForm ? (
                    <Box sx={{ margin: '0 auto' }}>
                      <Box>
                        <FormProvider {...methods}>
                          <form onSubmit={onSubmit}>
                            {require2FACode === false ? (
                              <>
                                <FormInput name="username" label={t('username')} required={true} />
                                <FormInput
                                  name="password"
                                  label={t('password')}
                                  type="password"
                                  required={true}
                                />
                              </>
                            ) : (
                              <FormInput name="TOTPCode" label={t('TOTPCode')} required={true} />
                            )}
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              fullWidth={true}
                              data-selenium-selector="login-button"
                              sx={{ mb: 1, mt: 2 }}
                            >
                              {t('login')}
                            </Button>
                          </form>
                        </FormProvider>
                      </Box>
                    </Box>
                  ) : null}

                  {!require2FACode ? (
                    <>
                      {showOpenIdButton ? (
                        <>
                          <Button
                            size="small"
                            color="inherit"
                            variant="contained"
                            onClick={redirectToOpenIdLogin}
                            disabled={ssoFailed}
                            fullWidth={true}
                            sx={showResetPasswordDialogButton ? { mb: 1 } : {}}
                          >
                            {t('openIdButton')}
                          </Button>

                          {/* accessDeniedMessage */}
                          {accessDenied ? (
                            <Typography align="center" sx={{ mt: 2, color: blue[500] }}>
                              {t('openIdSSOAccessDeniedMessage')}
                            </Typography>
                          ) : null}
                        </>
                      ) : null}

                      {showOpenIdLogoutButton ? (
                        <>
                          {/* ssoLogoutMessageForOpenId */}
                          <Typography align="center" sx={{ mt: 2, color: blue[500] }}>
                            {t('openIdSSOlougoutMessage')}
                          </Typography>

                          {/* logoutButton */}
                          <Button
                            size="small"
                            color="inherit"
                            variant="contained"
                            onClick={redirectToOpenIdLogout}
                            disabled={ssoFailed}
                            fullWidth={true}
                          >
                            {t('openIdLogoutButton')}
                          </Button>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {formError &&
                    canShowForm &&
                    require2FACode === false &&
                    showResetPasswordDialogButton && (
                      <Button
                        size="small"
                        color="inherit"
                        variant="contained"
                        onClick={() =>
                          watchUsername
                            ? callForgottenPassword(watchUsername)
                            : setShowResetPasswordDialog(true)
                        }
                        disabled={ssoFailed}
                        fullWidth={true}
                      >
                        {t('forgottenPasswordTitle')}
                      </Button>
                    )}
                  <Collapse in={formError || loggedOutForInactivity ? true : false} timeout="auto">
                    {formError && (
                      <Typography align="center" color="error" sx={{ mt: 2 }}>
                        {formError}
                      </Typography>
                    )}
                    {loggedOutForInactivity && !formError ? (
                      <Typography align="center" sx={{ mt: 1, color: 'info.main' }}>
                        {t('inactiveLogout')}
                      </Typography>
                    ) : null}
                  </Collapse>
                </>
              )}
            </Papeer>
          </Box>
        ) : (
          <></>
        )}
      </LoginAndPasswordRecoveryContent>

      {showResetPasswordDialog && (
        <OverflowedDialog key={'reset_password_dialog'} open={true} maxWidth="xs" fullWidth={true}>
          <ResetPasswordForm toggleDialog={setShowResetPasswordDialog} />
        </OverflowedDialog>
      )}
    </>
  );
};

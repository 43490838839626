import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';

import { getOrder } from './_api';
import OrderForm from './OrderForm';

import { decryptId } from 'utils/hooks/useApp';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useLanguage } from 'utils/hooks/useLanguage';
import { joinParams } from 'utils/study';

const OrderEdit: React.FC = () => {
  const { t } = useTranslation('Orders');
  const params = useParams();
  const { toggleLoader } = useAppGlobals();
  const { currentLocale } = useLanguage();
  const patientId = decryptId(get(params, 'patientId', null));
  const accessionNumber = decryptId(get(params, 'accessionNumber', null));
  const validEntity = !(patientId === 'null' || accessionNumber === 'null');

  const [entity, setEntity] = useState<any>({});

  const getLabelForList = (order: any, item: any, itemName: string) => {
    let label = null;
    if (item) {
      const itemNameCode = get(order, `${itemName}.code`, '');
      const itemNameValue = get(order, `${itemName}.value`);
      const itemNameText = get(order, `${itemName}.text`, false);

      const textForLabel = itemNameText
        ? get(JSON.parse(itemNameText), currentLocale, itemNameValue)
        : itemNameValue;

      label = `${joinParams([itemNameCode, textForLabel])}`;
    }
    return label;
  };

  const getEntity = async () => {
    toggleLoader();
    if (patientId && accessionNumber) {
      const resp = await getOrder(patientId, accessionNumber);
      if (resp) {
        const healthInsurance = get(resp, 'healthInsuranceCompany', null);
        const healthInsuranceCompany = healthInsurance
          ? {
              ...healthInsurance,
              label: getLabelForList(resp, healthInsurance, 'healthInsuranceCompany'),
            }
          : null;
        const expertness2 = get(resp, 'expertness', null);
        const expertness = expertness2
          ? { ...expertness2, label: getLabelForList(resp, expertness2, 'expertness') }
          : null;
        const entity = { ...resp, healthInsuranceCompany, expertness };
        setEntity(entity);
      } else {
        setEntity(null);
      }
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>{validEntity ? !isEmpty(entity) && <OrderForm entity={entity} /> : t('orderNotFound')}</>
  );
};

export default OrderEdit;

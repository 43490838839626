import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setGridRefetch } from 'store/reducers/appReducer';

const useGridRefetch = () => {
  const dispatch = useAppDispatch();

  const refetchGridTimestamp = useAppSelector(({ app }) => app.refetchGrid);

  const refetchGrid = () => dispatch(setGridRefetch(new Date().getTime().toString()));

  return { refetchGrid, refetchGridTimestamp };
};

export default useGridRefetch;

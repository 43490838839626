import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { getAll, setActiveProduct } from './_api';
import Header from 'components/Header/Header';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'productsMUI';

export const Products: React.FC = () => {
  const { t } = useTranslation('Products');
  useWithTitle();
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();
  const navigate = useNavigate();

  const [products, setProducts] = useState<any[]>([]);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const onCustomEntityDetail = (row: any) => {
    const editLink = `product/${get(row, 'id')}${
      get(row, 'backToByAction', '')
        ? '?from=' + get(row, 'backToByAction', '')
        : get(row, 'userDirectoryId', '')
        ? `?userDirectoryId=${get(row, 'userDirectoryId', '')}&parCode=${get(row, 'parCode', '')}`
        : ''
    }`;
    navigate(editLink);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 220,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.active}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeState',
                            id: get(row, 'id'),
                            state,
                            confirmAction: 'confirmActionChangeActive',
                          }),
                        );
                      }}
                    />
                  }
                  label={(row.active ? t('Grid:active') : t('Grid:inactive')) as string}
                />
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onCustomEntityDetail(row)}
              />
            </>
          );
        },
      },
      { field: 'type', headerName: t('type') },
      { field: 'subtype', headerName: t('subtype') },
      { field: 'description', headerName: t('description') },
    ]),
  );

  const loadEntity = async () => {
    toggleLoader();
    try {
      const products = await getAll();
      if (products && isArray(products)) {
        setProducts(
          products.map((product) => {
            const subtype = get(product, 'type.subtype.name', '');
            return {
              id: get(product, 'id', ''),
              type: t(get(product, 'type.name', '')),
              subtype: subtype === '' ? subtype : t(subtype),
              description: get(product, 'description', ''),
              active: get(product, 'active', false),
            };
          }),
        );
      } else {
        setProducts([]);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeActive = async (code: string, active: boolean) => {
    toggleLoader();
    const changed = await setActiveProduct(code, active);
    if (changed) {
      await loadEntity();
    }
    toggleLoader(false);
  };

  const confirmActionChangeActive = async () => {
    await onChangeActive(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  return (
    <>
      <Header title="" addUrl="/administration/settings/product/create" />
      <MuiGrid
        gridKey={muiGridKey}
        rows={products}
        columns={columns}
        initialSortMode={[{ field: 'type', sort: 'asc' }]}
      />
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmActionChangeActive}
        />
      )}
    </>
  );
};

import { compact, get, isNaN, last } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppInfo } from './useAppInfo';

export const useWithTitle = () => {
  const { t } = useTranslation('Menu');
  const location = useLocation();
  const { feConfig } = useAppInfo();
  const titleAppendix = get(feConfig, 'titleAppendix', '');
  const titlePrefix = get(feConfig, 'titlePrefix', '');

  const setPageTitle = (title: string) =>
    (document.title = `${titlePrefix}${title}${titleAppendix}`);

  try {
    const pathname = location.pathname;
    const text = compact(pathname.split('/'));
    let title = '';
    // Different for
    if (
      text[0] === 'import' ||
      text[0] === 'exports' ||
      text[0] === 'histories' ||
      text[0] === 'requests' ||
      text[0] === 'shreddings' ||
      text[0] === 'studies'
    ) {
      if (text.length === 2 && text[0] === 'requests' && text[1] !== 'search' && !isNaN(text[1])) {
        title = `${text[0]}.detail`;
      } else if (
        text.length === 3 &&
        text[0] === 'requests' &&
        text[2] !== 'create' &&
        !isNaN(text[2])
      ) {
        title = `${text[0]}.${text[1]}.edit`;
      } else if (text[0] === 'shreddings') {
        title = 'detail.' + text.join('.');
      } else if (
        (text[0] === 'exports' && text[1] === 'detail') ||
        text[0] === 'import' ||
        text[0] === 'histories' ||
        (text[0] === 'studies' && text[2] === 'detail')
      ) {
        title = text.join('.');
      } else if (text[1] === 'search' || text[2] === 'create') {
        title = text.join('.');
      } else if (text.length === 1) {
        title = `${text[0]}.${text[0]}`;
      }
      title = t(title);
    } else if (text[0] === 'orders' && text.length === 4) {
      title = t(`${text[3]}.${text[0]}`);
    } else if (text[1] === 'settingsFromLocalUserDirectory') {
      title = t('users');
    } else {
      const lastValue = last(text);
      title = lastValue ? t(lastValue) : '';
    }
    setPageTitle(title);
    return { title, setPageTitle };
  } catch (e) {
    setPageTitle('Portal');
    return { title: '', setPageTitle };
  }
};

import { IUserReducerInitialState } from './_types';
import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

const initialState: IUserReducerInitialState = {
  isAuthorized: false,
  auth: [],
  darkMode: false,
  language: '',
  authType: '',
  exp: 3600,
  expired: false,
  first_name: '',
  fromToken: undefined,
  iat: 3600,
  last_name: '',
  maxUserInactiveTimeout: '3600',
  middle_name: '',
  prefix: '',
  requireTOTPAuth: false,
  sub: '',
  suffix: '',
  totpAuthenticated: false,
  ttl: '3600',
};

export const userSlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    userStore: (state, action) => {
      return {
        fromToken: get(state, 'fromToken'),
        ...action.payload,
        isAuthorized: !action.payload.expired,
      };
    },
    userUnload: () => {
      return initialState;
    },
    rolesLoad: (state, action) => {
      state.auth = action.payload;
    },
  },
});

export const { userStore, userUnload, rolesLoad } = userSlice.actions;

export default userSlice.reducer;

// export const userReducer = (state = initialState, action: IAction) => {
//   switch (action.type) {
//     // case USER_STORE: {
//     //   return { ...action.payload, isAuthorized: !action.payload.expired };
//     // }

//     // case USER_UNLOAD: {
//     //   return initialState;
//     // }

//     case ROLES_LOAD:
//       return {
//         ...state,
//         auth: action.payload,
//       };

//     default:
//       return state;
//   }
// };

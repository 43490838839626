import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { each, filter, get, indexOf, isArray, isEmpty, isString, keys } from 'lodash';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IRequestDetail } from './_types';
import { getRequest } from './_api';
import { getAllByUser } from 'modules/Administration/Folders/_api';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';

import {
  DETAIL_QUEUE,
  DETAIL_REQUEST,
  OPERATION_TYPE_CREATE_SR,
  OPERATION_TYPE_EDIT_PATIENT,
  OPERATION_TYPE_EDIT_STUDY,
  OPERATION_TYPE_MOVE_TO_FOLDER,
  OPERATION_TYPE_MOVE_TO_TRASH,
  OPERATION_TYPE_REORDER,
  OPERATION_TYPE_SPLIT,
} from 'constants/constants';
import useItemsForRequestDetail from './_ItemsForRequestDetail';
import RequestsStateForm from './RequestsStateForm';

import { styled } from '@mui/material/styles';
import { RequestDetailStudies } from './RequestDetailStudies';
import { RequestDetailHistories } from './RequestDetailHistories';
import { RequestDetailComments } from './RequestDetailComments';
import { RequestDetailFolders } from './RequestDetailFolders';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { hasNameRoleForRequestOperationType } from 'utils/hooks/useRequest';
import { RestrictedPage } from 'components/RestrictedSection/RestrictedPage';
import { TourRequests } from './TourRequests';

const Th = styled('th')(({ theme }) => ({
  textAlign: 'left',
  paddingRight: theme.spacing(1),
}));

const RequestsDetail: React.FC<IRequestDetail> = ({ isQueue = false }) => {
  const { t } = useTranslation('Request');
  useWithTitle(); // sets title to document
  const params = useParams();
  const { toggleLoader } = useAppGlobals();
  const { hasRole, user } = useUser();
  const { archives: products } = useAppInfo();

  const [rawRequest, setRawRequest] = useState<any>(null);
  const [linkBackAfterChangeState, setLinkBackAfterChangeState] = useState<any>(null);
  const [linkBack, setLinkBack] = useState<any>(null);
  const [folders, setFolders] = useState<any[]>([]);
  const [dialogOpened, toggleDialog] = useState<boolean>(false);
  const [requestToBeModified, setRequestToBeModified] = useState<any>(null);

  const callUrl = (url: string) => {
    navigate(url);
  };
  const setRequestState = (request: any, state: any, title: any) => {
    toggleDialog(true);
    setRequestToBeModified({
      request,
      state,
      title,
    });
  };

  const { request, buttons } = useItemsForRequestDetail(
    rawRequest,
    folders,
    products,
    user,
    isQueue,
    hasRole,
    callUrl,
    setRequestState,
    t,
  );

  const nameRoles = hasNameRoleForRequestOperationType(get(rawRequest, 'operationType', ''));
  let isAllowed = false;
  if (isString(nameRoles) && hasRole(nameRoles)) {
    isAllowed = true;
  } else if (isArray(nameRoles)) {
    each(nameRoles, (role) => {
      if (hasRole(role)) {
        isAllowed = true;
      }
    });
  } else if (nameRoles === undefined) {
    isAllowed = true;
  }
  const hasNotRoles = isAllowed ? '' : nameRoles;

  const navigate = useNavigate();
  const location = useLocation();

  const loadRequest = async () => {
    const id: any = get(params, 'id', null);
    let folders: any[] = [];
    if (id && !isNaN(id)) {
      const rawRequest = await getRequest(id);
      if (get(rawRequest, 'operationType') === OPERATION_TYPE_MOVE_TO_FOLDER) {
        const respFolders = await getAllByUser();
        if (respFolders && isArray(respFolders)) {
          const folderIDs = get(rawRequest, 'data.folderIDs');
          folders = filter(
            respFolders.map((item) => {
              const isSelect = indexOf(folderIDs, item.id) > -1;
              return {
                ...item,
                isSelect,
              };
            }),
            { isSelect: true },
          );
          setFolders(folders);
        }
      }
      setRawRequest(rawRequest);
    }
  };
  const getEntity = async () => {
    toggleLoader();
    await loadRequest();
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();
    const linkBackParam: string = get(location, 'state.linkBack', '');
    let backUrl;
    if (!isEmpty(linkBackParam) && linkBackParam && isString(linkBackParam)) {
      backUrl =
        linkBackParam === DETAIL_QUEUE
          ? `/queueRequests/`
          : linkBackParam.split('/')[0] === DETAIL_REQUEST
          ? `/histories/${linkBackParam.split('/')[1]}`
          : `/requests/`;
      setLinkBackAfterChangeState(backUrl);
      setLinkBack(backUrl + '?action=back');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderStudies = (title: string, studies: any) => (
    <RequestDetailStudies
      rows={studies.length === 1 && isEmpty(studies[0]) ? [] : studies}
      title={title}
    />
  );

  // Get all keys to use them for display of the old and new patient
  const oldPatientToDisplay = get(request, 'oldPatient', {});
  const newPatientToDisplay = get(request, 'data', {});
  // These fields always be displayed in the notification detail
  const mandatoryKeys = ['patientLastName', 'patientFirstName', 'patientId'];
  // Not always are the old and new patient objects the same, so we need to get all keys from both
  const uniqueKeys = new Set([
    ...Object.keys(oldPatientToDisplay),
    ...Object.keys(newPatientToDisplay),
    ...mandatoryKeys,
  ]);
  // Include fields with non-empty values from both old and new patient objects
  const patientKeysToDisplay: string[] = Array.from(uniqueKeys).reduce((acc: string[], key) => {
    if (!!newPatientToDisplay[key] || !!oldPatientToDisplay[key] || mandatoryKeys.includes(key)) {
      if (!acc.includes(key)) {
        acc.push(key);
      }
    }
    return acc;
  }, mandatoryKeys.slice());

  const renderedSteps = () => {
    return <TourRequests hasComments={rawRequest?.comments.length > 0} />;
  };

  return (
    <>
      {hasNotRoles ? (
        <RestrictedPage allowedRoles={hasNotRoles} />
      ) : (
        <>
          <Header
            title={get(request, 'title', '')}
            {...(linkBack ? { backUrl: linkBack } : {})}
            TourComponent={renderedSteps()}
          />
          {request !== null &&
            (request !== false ? (
              <>
                <Papeer>
                  <Box data-tour="request-detail-mainSection">
                    <Typography component="div">
                      <table>
                        <tbody>
                          {keys(request.basic).map((key) => (
                            <tr key={key}>
                              <Th>
                                <strong>
                                  {t(key === 'operationType' ? 'typeOfOperation' : key)}
                                </strong>
                                :
                              </Th>
                              <td>{get(request, `basic.${key}`)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Typography>
                    <Grid container={true} spacing={2}>
                      <Grid item={true}>
                        {buttons.map((button, index) => (
                          <Button
                            key={`button${index}`}
                            color="inherit"
                            sx={{ mr: 1, mt: 1, border: '1px solid #ccc' }}
                            size="small"
                            {...button}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Box>
                </Papeer>

                {!isEmpty(request.comments) && (
                  <RequestDetailComments rows={request.comments} title={t('comments')} />
                )}

                <Box data-tour="request-detail-otherSections">
                  <>
                    {request.operationType.toLowerCase() !==
                      OPERATION_TYPE_EDIT_PATIENT.toLowerCase() &&
                      request.operationType.toLowerCase() !==
                        OPERATION_TYPE_CREATE_SR.toLowerCase() &&
                      renderStudies(
                        t(
                          request.operationType.toLowerCase() ===
                            OPERATION_TYPE_REORDER.toLowerCase() ||
                            request.operationType.toLowerCase() ===
                              OPERATION_TYPE_SPLIT.toLowerCase()
                            ? 'sourceStudy'
                            : request.operationType.toLowerCase() ===
                                OPERATION_TYPE_EDIT_STUDY.toLowerCase() ||
                              request.operationType.toLowerCase() ===
                                OPERATION_TYPE_EDIT_PATIENT.toLowerCase()
                            ? 'oldStudy'
                            : 'studies',
                        ),
                        request.operationType.toLowerCase() === OPERATION_TYPE_SPLIT.toLowerCase()
                          ? [request.std.sourceStudy]
                          : request.operationType.toLowerCase() ===
                            OPERATION_TYPE_REORDER.toLowerCase()
                          ? [request.std.sourceStudy]
                          : request.std.requestedStudies,
                      )}
                  </>

                  {!isEmpty(request.std.sourceNewStudy) && (
                    <>{renderStudies(t('sourceNewStudy'), [request.std.sourceNewStudy])}</>
                  )}

                  {(!isEmpty(request.std.targetStudy) || !isEmpty(request.std.providedStudies)) && (
                    <>
                      {renderStudies(
                        t(
                          request.operationType.toLowerCase() ===
                            OPERATION_TYPE_SPLIT.toLowerCase() &&
                            isEmpty(request.std.sourceNewStudy)
                            ? 'newPlannedStudy'
                            : request.operationType.toLowerCase() ===
                              OPERATION_TYPE_SPLIT.toLowerCase()
                            ? 'newStudy'
                            : request.operationType.toLowerCase() ===
                                OPERATION_TYPE_EDIT_STUDY.toLowerCase() ||
                              request.operationType.toLowerCase() ===
                                OPERATION_TYPE_EDIT_PATIENT.toLowerCase()
                            ? 'repairStudy'
                            : request.operationType.toLowerCase() === 'COPY'.toLowerCase()
                            ? 'copyStudies'
                            : request.operationType.toLowerCase() === 'MOVE'.toLowerCase()
                            ? 'moveStudies'
                            : request.operationType.toLowerCase() ===
                              OPERATION_TYPE_CREATE_SR.toLowerCase()
                            ? 'sourceStudy'
                            : 'targetStudy',
                        ),
                        request.operationType.toLowerCase() ===
                          OPERATION_TYPE_REORDER.toLowerCase() ||
                          request.operationType.toLowerCase() === OPERATION_TYPE_SPLIT.toLowerCase()
                          ? [request.std.targetStudy]
                          : request.std.providedStudies,
                      )}
                    </>
                  )}

                  <Grid container={true} spacing={2}>
                    {!isEmpty(get(request, 'oldPatient')) && (
                      <Grid item={true} xs={12} md={6} lg={6} key="oldPatient">
                        <Header
                          title={t(`operationType.${get(request, 'operationType', 'other')}_old`)}
                          topMargin={true}
                        />
                        <Papeer>
                          <Typography component="div">
                            <table>
                              <tbody>
                                {patientKeysToDisplay.map((key: any) => (
                                  <tr key={key}>
                                    <Th>
                                      <strong>{t(`oldPatient.${key}`)}</strong>:
                                    </Th>
                                    <td>{get(request, `oldPatient.${key}`)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Typography>
                        </Papeer>
                      </Grid>
                    )}

                    {get(request, 'operationType') !== OPERATION_TYPE_CREATE_SR.toLowerCase() &&
                    get(request, 'operationType') !== OPERATION_TYPE_MOVE_TO_TRASH.toLowerCase() ? (
                      <Grid
                        item={true}
                        xs={12}
                        md={get(request, 'operationType') === 'move_to_folder' ? 12 : 6}
                        key="data"
                      >
                        <Header
                          title={t(`operationType.${get(request, 'operationType', 'other')}`)}
                          topMargin={true}
                        />
                        {get(request, 'operationType') === 'move_to_folder' ? (
                          <RequestDetailFolders rows={get(request, 'data.selectFolders', [])} />
                        ) : (
                          <Papeer>
                            <Typography component="div">
                              {get(request, 'data') && (
                                <table>
                                  <tbody>
                                    {patientKeysToDisplay.map((key: any) => (
                                      <tr key={key}>
                                        <Th>
                                          <strong>{t(`data.${key}`)}</strong>:
                                        </Th>
                                        <td>{get(request, `data.${key}`)}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </Typography>
                          </Papeer>
                        )}
                      </Grid>
                    ) : (
                      <div>{'\u00A0'}</div>
                    )}
                  </Grid>
                </Box>
                <RequestDetailHistories
                  rows={get(request, 'history', [])}
                  title={t('history')}
                  data-tour="request-detail-history"
                />
              </>
            ) : (
              <Papeer>
                <Typography>{t('requestNotFound')} </Typography>
                <Button color="primary" variant="contained" sx={{ mt: 1 }}>
                  {t('backToRequestsList')}
                </Button>
              </Papeer>
            ))}
          {dialogOpened && (
            <Dialog
              open={true}
              onClose={() => toggleDialog(false)}
              aria-labelledby="form-dialog-title"
              maxWidth="md"
              fullWidth={true}
            >
              <DialogTitle id="form-dialog-title">
                <Grid
                  container={true}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {get(requestToBeModified, 'title')}
                  <IconButton aria-label="close" onClick={() => toggleDialog(false)}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <RequestsStateForm
                  request={requestToBeModified}
                  toggleDialog={toggleDialog}
                  linkBackAfterChangeState={linkBackAfterChangeState}
                />
              </DialogContent>
            </Dialog>
          )}
        </>
      )}
    </>
  );
};

export default RequestsDetail;

import { GridColDef } from '@mui/x-data-grid';
import { TFunction } from 'i18next';

export const useTranslateColumnsDataGridPro = (
  columns: GridColDef[],
  t: TFunction<'Studies', undefined>,
  name = 'cols',
) => {
  const translateColumns = (columns: GridColDef[], t: TFunction<'Studies', undefined>) =>
    columns.map((col: GridColDef) => ({ ...col, headerName: t(col.headerName || '') }));

  return {
    [name]: translateColumns(columns, t),
  };
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { get, join } from 'lodash';
import { Box, Button, Grid, Typography } from '@mui/material';
import { send } from './_api';
import { getRules } from 'modules/Import/_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { checkUploadingStatus, cleanUppy, createUppy } from 'utils/import';
import { useUser } from 'utils/hooks/useUser';
import { useFetchAddressBooksAndRenderRecipients } from 'utils/hooks/useFetchAddressBooksAndRenderRecipients';
import { useDicomParser } from 'components/Import/useFileParser';
import useAlerts from 'components/Alerts/useAlerts';
import Import from 'components/Import/Import';
import { Announcement } from 'components/Announcement/Announcement';
import FilesGrid from 'components/Import/FilesGrid';
import { Papeer } from 'components/Papeer/Papeer';
import CustomUppyFolderInput from 'modules/Import/CustomUppyFolderInput';
import CustomUppyFileInput from 'modules/Import/CustomUppyFileInput';
import { setMdexFilesToStore } from 'store/reducers/appReducer';
import { useAppDispatch } from 'store/hooks';
import LinearProgressWithLabel from 'components/Import/LinearProgressWithLabel';

let uppyInstance: any;
let intervalUploading: any;

export const NonDicom: React.FC<any> = () => {
  const { t } = useTranslation('Import');
  const { toggleLoader } = useAppGlobals();
  const { user } = useUser();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { renderedRecipients } = useFetchAddressBooksAndRenderRecipients(true);

  const [rules, setRules] = useState<any[]>([]);

  const [files, setFiles] = useState<any[]>([]);
  const { parseNonDicomFiles } = useDicomParser({ setFiles });
  const [timestamp, setTimestamp] = useState(new Date().getTime().toString());
  const [completed, setCompleted] = useState(0);
  const dispatch = useAppDispatch();

  const methods = useForm<any>({
    defaultValues: {},
  });
  const { handleSubmit, reset, watch } = methods;

  const institutionNameWatch = watch('institutionName');

  useEffect(() => {
    dispatch(setMdexFilesToStore(files));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const removeFile = (id: any) => {
    uppyInstance.removeFile(id);
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const removeAllFiles = () => {
    uppyInstance.getFiles().forEach((file: any) => uppyInstance.removeFile(file.id));
    setFiles([]);
  };

  const onSubmit = async (values: any) => {
    toggleLoader();
    //TODO pořešit chybové stavy
    uppyInstance.upload().then(async () => {
      // Upload complete
      const updatedValues = {
        recipient: get(values, 'institutionName.value', null),
        type: 'NONDICOM',
        filePath: `${timestamp}_${user?.sub}`,
      };
      const resp = await send(updatedValues);
      toggleLoader(false);
      if (resp) {
        addSuccessAlert(t('notifications.mdexSuccess'));
      } else {
        addErrorAlert(t('notifications.mdexError'));
      }
    });
    setTimeout(() => {
      toggleLoader(false);
    }, 3000);
    const newTimestamp = new Date().getTime().toString();
    createUppy('uppyMdex', parseNonDicomFiles, timestamp, get(rules, 'multimedia', []), true);
    uppyInstance = (window as any).UppyGlobalInstances[
      (window as any).UppyGlobalInstances.length - 1
    ];
    setTimestamp(newTimestamp);
    setFiles([]);
    reset({});
  };

  const loadRules = async () => {
    toggleLoader(true);
    const rules = await getRules();
    if (rules) {
      setRules(rules);
      createUppy('uppyMdex', parseNonDicomFiles, timestamp, get(rules, 'multimedia', []), true);
      uppyInstance = (window as any).UppyGlobalInstances[
        (window as any).UppyGlobalInstances.length - 1
      ];
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadRules();
    intervalUploading = setInterval(() => {
      checkUploadingStatus(setCompleted);
    }, 500);
    return () => {
      cleanUppy();
      clearInterval(intervalUploading);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {completed ? (
          <div>
            <LinearProgressWithLabel value={completed} />
            <Typography component="div" sx={{ marginTop: '4px', marginBottom: '8px' }}>
              {t('uploadingFiles')}
            </Typography>
          </div>
        ) : null}
        {!files.length && (
          <Papeer>
            <Announcement
              label={t('allowedExtensions', {
                rules: join(get(rules, 'multimedia', []), ', '),
              })}
              type={'info'}
              spaced={true}
              component={'div'}
            />
            <Grid container spacing={1} sx={{ mt: 0.5 }}>
              <Grid item xs={12} data-tour="import-nonDicom-uppySelector">
                {uppyInstance && <Import uppy={uppyInstance} />}
              </Grid>
            </Grid>
          </Papeer>
        )}
        {!!files.length && (
          <Papeer sx={{ mt: 2 }}>
            <Grid container>
              <Grid item xs={12} data-tour="import-nonDicom-grid">
                <FilesGrid files={files} removeFile={removeFile} />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                sx={{ mt: 1, gap: 1 }}
              >
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={removeAllFiles}
                  data-tour="import-nonDicom-deleteAll"
                >
                  {t('Import:removeAll')}
                </Button>
                <Box data-tour="import-nonDicom-uploadFolder">
                  <CustomUppyFolderInput uppy={uppyInstance} />
                </Box>
                <Box data-tour="import-nonDicom-uploadFiles">
                  <CustomUppyFileInput uppy={uppyInstance} />
                </Box>
              </Grid>
            </Grid>
          </Papeer>
        )}

        <Papeer sx={{ mt: 2 }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flex: 1 }} data-tour="mdex-send-recipients">
                  {renderedRecipients}
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!files.length || !institutionNameWatch}
                    sx={{ ml: 2 }}
                    data-tour="mdex-send-button"
                  >
                    {t('Mdex:send')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Papeer>
      </form>
    </FormProvider>
  );
};

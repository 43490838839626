import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';

import { ISimpleCheckboxArray } from './_types';
import { useTranslation } from 'react-i18next';
import Done from '@mui/icons-material/Done';
import { get, find, isArray, sortBy, without, isObject } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { stateIsSame } from 'utils/componentOptimizatons';

const PlainSimpleCheckboxArray: React.FC<ISimpleCheckboxArray> = ({
  label = '',
  items,
  name,
  changeName = name,
  showCheckAllButton = true,
  setAllCheckboxDisabled = false,
  grid = false,
  required,
  compactMode,
  allItems,
  toggleAllItems,
}) => {
  const { t } = useTranslation('Form');
  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const [process, setProcess] = useState<number>(0);

  const values = getValues(changeName) ? getValues(changeName) : [];
  watch(changeName);

  let isError = false;
  let errorMessage = '';

  if (errors && errors.hasOwnProperty(changeName)) {
    isError = true;
    errorMessage = get(errors, `[${changeName}].message`, '') as string;
  }

  const handleSelect = (item: any): any[] => {
    const itemSelected = find(values, { id: item.id });
    const newItemsSelected = itemSelected ? without(values, itemSelected) : [...values, item];
    setValue(changeName, newItemsSelected);
    return newItemsSelected;
  };

  const allItemsChecked = isArray(items) && isArray(values) && items.length === values.length;

  const setAllOrNoValues = () => {
    const list = allItemsChecked ? [] : items || [];
    setValue(changeName, list);
  };

  if (allItems && process === 0) {
    toggleAllItems(false);
    setProcess(1);
  }
  if (!allItems) {
    if (process !== 0) {
      setProcess(0);
    }
  }
  if (process === 1) {
    setAllOrNoValues();
    toggleAllItems(false);
    setProcess(2);
  }

  return (
    <>
      {(label || showCheckAllButton) && (
        <Typography variant="body1" sx={{ mb: 1 }}>
          {label}
          {showCheckAllButton && (
            <Button
              onClick={() => setAllOrNoValues()}
              sx={{
                ml: label ? 2 : 0,
              }}
              size="small"
              variant="contained"
              color={allItemsChecked ? 'primary' : 'inherit'}
              data-tour="selectAll"
              disabled={setAllCheckboxDisabled}
            >
              {t('checkAll')}
              <Done
                sx={{
                  ml: 1,
                  width: 16,
                  height: 16,
                }}
              />
            </Button>
          )}
        </Typography>
      )}
      <Grid
        container={grid ? true : false}
        style={compactMode ? { width: 'auto', marginLeft: '8px', display: 'inline-flex' } : {}}
      >
        {sortBy(items, ['position', 'name']).map((item: any) => {
          const renderedCheckbox = (
            <FormControlLabel
              sx={{
                '& > span': {
                  pt: 0,
                  pb: 0,
                  pr: 1,
                },
                cursor: setAllCheckboxDisabled ? 'default' : 'pointer',
              }}
              control={
                <Controller
                  name={changeName}
                  render={({ field: { onChange: onCheckChange } }) => {
                    return (
                      <Checkbox
                        checked={find(values, { id: item.id }) ? true : false}
                        onChange={() => onCheckChange(handleSelect(item))}
                        disabled={setAllCheckboxDisabled}
                      />
                    );
                  }}
                  control={control}
                />
              }
              key={item.id}
              label={item.name}
            />
          );
          return grid ? (
            <Grid key={item.id} item={true} {...(isObject(grid) ? grid : {})}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {renderedCheckbox}
                {item.renderIcon ? <Box sx={{ pr: 2 }}>{item.renderIcon}</Box> : ''}
              </Box>
            </Grid>
          ) : (
            <div>{renderedCheckbox}</div>
          );
        })}
      </Grid>
      {required && isError && <FormHelperText error={true}>{errorMessage}</FormHelperText>}
    </>
  );
};

export const SimpleCheckboxArray = React.memo(PlainSimpleCheckboxArray, stateIsSame);

import { get } from 'lodash';

import { useAppInfo } from 'utils/hooks/useAppInfo';
import { CKTCH, ORCZ } from 'constants/constants';

const Logo2: React.FC<any> = () => {
  const { feConfig } = useAppInfo();
  return (
    <img
      src={`${process.env.PUBLIC_URL}/img/${get(feConfig, 'logo', 'orcz-logo.png')}`}
      style={get(feConfig, 'usedBranding', ORCZ) === CKTCH ? { maxHeight: '100%' } : {}}
      alt="OR-CZ"
    />
  );
};

export default Logo2;

import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { IStudyResultForGrid } from 'modules/Search/_types';
import { SearchForm } from 'components/SeachForm/SearchForm';
import { SearchResultsMUI } from 'components/SearchResultsMUI/SearchResultsMUI';
import { useGridColumns } from 'components/SearchResultsMUI/useGridColumns';
import { useSubmitSearchForm } from 'utils/hooks/useSubmitSearchForm';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import {
  MOVE_TO_FOLDER,
  MOVE_TO_TRASH,
  SEARCH_FUNCTION_COPY,
  SEARCH_FUNCTION_EDIT_PATIENT,
  SEARCH_FUNCTION_EDIT_STUDY,
  SEARCH_FUNCTION_MOVE,
  SEARCH_FUNCTION_REORDER,
  SEARCH_FUNCTION_SPLIT,
  SEND,
  VIEWER,
} from 'constants/constants';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { ReorderSourceStudyInfo } from 'modules/Search/ReorderSourceStudyInfo';

const RequestsSearch: React.FC = () => {
  const { searchResults } = useAppInfo();
  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const { columns } = useGridColumns({});
  const { title } = useWithTitle();

  const { onSubmitSearchForm } = useSubmitSearchForm(rows, setRows);

  const onSubmit = async (values: any) => {
    onSubmitSearchForm(values, columns);
  };

  const { sourceStudyForReorderIsInStore } = useStudyInfo();

  useEffect(() => {
    if (!isEmpty(searchResults)) {
      setRows(searchResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {sourceStudyForReorderIsInStore ? <ReorderSourceStudyInfo /> : null}
      <SearchForm
        onSubmit={onSubmit}
        useForOtherForm={false}
        showFilterInHeader={true}
        ownTitle={title}
        showTourForReorder={sourceStudyForReorderIsInStore ? true : false}
      />
      <SearchResultsMUI
        rows={rows}
        setRows={setRows}
        actions={
          sourceStudyForReorderIsInStore
            ? [SEARCH_FUNCTION_REORDER, VIEWER]
            : [
                SEND,
                SEARCH_FUNCTION_REORDER,
                SEARCH_FUNCTION_SPLIT,
                SEARCH_FUNCTION_EDIT_PATIENT,
                SEARCH_FUNCTION_EDIT_STUDY,
                SEARCH_FUNCTION_COPY,
                SEARCH_FUNCTION_MOVE,
                MOVE_TO_FOLDER,
                MOVE_TO_TRASH,
                VIEWER,
              ]
        }
        selecting={!sourceStudyForReorderIsInStore}
        selection={selection}
        setSelection={setSelection}
        isRequest={true}
      />
    </>
  );
};

export default RequestsSearch;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { find, get, isArray, noop } from 'lodash';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography } from '@mui/material';

import { getChartDataset, getFormData } from './_api';
import useValidationSchema from './_form';
import { IField, IStatisticsForm } from './_types';

import Header from 'components/Header/Header';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Papeer } from 'components/Papeer/Papeer';
import FormAutocompleteMultiple from 'components/Form/Autocomplete/AutocompleteMultiple';
import FormSelect from 'components/Form/Select/Select';
import { Box } from '@mui/system';
import { ISelectItem } from 'components/Form/Select/_types';
import { CheckedButton } from 'components/Buttons/CheckedButton';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourReports } from './TourReports';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
);

const colors = { 1: 'rgb(255, 0, 0)', 2: 'rgb(0, 255, 0)', 3: 'rgb(0, 0, 255)' };
const isDarkMode = false;

const FILLING = 'FILLING';
const QUANTITY = 'QUANTITY';

export const Statistics: React.FC = () => {
  const { t } = useTranslation('Reports');
  const { toggleLoader } = useAppGlobals();
  const { modalities } = useAppInfo();

  const [canViewStatistics, setCanViewStatistics] = useState<boolean>(false);
  const [allStationNames, setAllStationNames] = useState<IField[]>([]);
  const [allModalityNames, setAllModalityNames] = useState<IField[]>([]);
  const [settings, setSettings] = useState<any>(undefined);
  const [data, setData] = useState<any>([]);
  const [labels, setLabels] = useState<any>([]);
  const [options, setOptions] = useState<any>(null);
  const [sourceCheckButtons, setSourceCheckButtons] = useState<ISelectItem[]>([]);
  const [typeCheckButtons, setTypeCheckButtons] = useState<ISelectItem[]>([]);
  const [activeChartDataSource, setActiveChartDataSource] = useState<string>('');
  const [activeChartDataType, setActiveChartDataType] = useState<string>(FILLING);
  const [monthSelectDisabled, setMonthSelectDisabled] = useState<boolean>(true);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  // TODO: Just for the "isSubmitted" not to be unused. It is not used anywhere
  noop(isSubmitted);

  useWithTitle(); // sets title to document

  const { StatisticsFormSchema, formItems } = useValidationSchema(
    t,
    settings,
    activeChartDataType,
    activeChartDataSource,
    QUANTITY,
    monthSelectDisabled,
  );

  const methods = useForm<IStatisticsForm>({
    resolver: yupResolver(StatisticsFormSchema),
    mode: 'onChange',
  });
  const { handleSubmit, reset, setValue, trigger, watch } = methods;
  const selectedStations = watch('stationNames') || [];
  const selectedModalities = watch('modalityNames') || [];
  const selectedYear = watch('year') || '';
  const selectedMonth = watch('month') || '';
  const selectedChartType = watch('chartType') || '';
  const watchArchives = watch('archives.id') || '';

  const allFields = watch();
  const isSelectedAllStations = selectedStations.length === allStationNames.length;
  const isSelectedAllModalities = selectedModalities.length === allModalityNames.length;

  const getRanodmColor = () => {
    const o = Math.round;
    const r = Math.random;
    const s = 255;
    return 'rgb(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ')';
  };

  const getColor = (i: number) => {
    const color = get(colors, i + 1, undefined);
    if (color !== undefined) {
      return color;
    } else {
      return getRanodmColor();
    }
  };

  const getModalityColor = (i: number, data: any) => {
    const modality = find(modalities, ['name', data]);
    if (modality !== undefined) {
      return get(modality, 'color');
    }
    return getColor(i);
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const stationNamesArray: IField[] = get(values, 'stationNames', []);
    const modalityNamesArray: IField[] = get(values, 'modalityNames', []);
    const year = values.year;
    const month = values.month;
    setData(null);
    setOptions(null);
    const chartData = {
      archiveId: get(values, 'archives.id'),
      ...(year ? { year } : {}),
      ...(month ? { month } : {}),
      chartType: values.chartType,
      chartDataType: activeChartDataType,
      ...(activeChartDataSource !== 'MODALITY'
        ? { stationNames: stationNamesArray.map((item) => item.value) }
        : {}),
      ...(activeChartDataSource === 'MODALITY'
        ? { modalityNames: modalityNamesArray.map((item) => item.value) }
        : {}),
    };
    const data = await getChartDataset(chartData);
    const stack = Object.keys(get(data, 'columnMap', {})).length > 3;
    const barData = Object.keys(get(data, 'columnMap', {})).map((station, i) => {
      const columnMap = get(data, `columnMap.${station}`, '') as string;
      return {
        label: columnMap === 'sum' ? t('sum') : columnMap,
        backgroundColor:
          activeChartDataSource === 'AET' ? getColor(i) : getModalityColor(i, columnMap),
        borderColor: 'rgb(255, 255, 255)',
        data: get(data, 'data', []).map(
          (item: any) =>
            activeChartDataType === QUANTITY
              ? get(item, station)
              : get(item, station) / 1024 / 1024 / 1024, // B -> GB
        ),
      };
    });
    setLabels(get(data, 'data', []).map((item: any) => item.name));
    setData(barData);
    setOptions({
      plugins: {
        title: {
          display: false,
        },
      },
      responsive: true,
      scales: {
        y: {
          display: true,
          stacked: stack,
          title: {
            display: true,
            text: t(activeChartDataType === QUANTITY ? 'labelQuantityY' : 'labelY'),
            backgroundColor: 'rgba(159,170,174,0.8)',
            fontColor: isDarkMode ? 'white' : 'rgba(0, 0, 0, 0.54)',
            fontSize: 18,
          },
          ticks: {
            beginAtZero: true,
            min: 0,
            fontColor: isDarkMode ? 'white' : 'rgba(0, 0, 0, 0.54)',
          },
        },

        x: {
          stacked: stack,
          ticks: {
            fontColor: isDarkMode ? 'white' : 'rgba(0, 0, 0, 0.54)',
          },
        },
      },
    });
    setIsSubmitted(true);
    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      const settings = await getFormData();
      if (settings) {
        setSettings(settings);
        const archives = get(settings, 'archives');
        const stationNames = get(settings, 'stationNames', []);
        const modalityNames = get(settings, 'modalityNames', []);

        const canViewStatistics =
          isArray(archives) &&
          archives.length &&
          ((isArray(stationNames) && stationNames !== null && stationNames.length) ||
            (isArray(modalityNames) && modalityNames !== null && modalityNames.length))
            ? true
            : false;
        const allStationNames = stationNames.map((item: string) => ({
          value: item,
          label: item === 'UNDEFINED' ? t('undefined') : item,
        }));
        const allModalityNames = modalityNames.map((item: string) => ({
          value: item,
          label: item === 'UNDEFINED' ? t('undefined') : item,
        }));
        setAllStationNames(allStationNames);
        setAllModalityNames(allModalityNames);
        setCanViewStatistics(canViewStatistics);
        const initialValues: IStatisticsForm = {
          chartType: 'INCREMENTAL',
          archives: { id: null },
        };
        reset({ ...initialValues });

        let buttons: ISelectItem[] = [];
        buttons = [
          ...(isArray(stationNames) && stationNames !== null && stationNames.length
            ? [{ id: 'AET', label: t('byAets') }]
            : []),
          ...(isArray(modalityNames) && modalityNames !== null && modalityNames.length
            ? [{ id: 'MODALITY', label: t('byModalities') }]
            : []),
        ];
        if (buttons.length > 0) {
          setActiveChartDataSource(buttons[0].id);
        }
        setSourceCheckButtons(buttons);
        setTypeCheckButtons([
          { id: FILLING, label: t('filling') },
          { id: QUANTITY, label: t('quantity') },
        ]);
      } else {
        setSettings(null);
      }
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleAllStations = () => {
    const initialValues: IStatisticsForm = {
      ...allFields,
      stationNames: isSelectedAllStations ? [] : allStationNames,
    };
    reset({ ...initialValues });
  };

  const toggleAllModalities = () => {
    const initialValues: IStatisticsForm = {
      ...allFields,
      modalityNames: isSelectedAllModalities ? [] : allModalityNames,
    };
    reset({ ...initialValues });
  };

  useEffect(() => {
    if (selectedStations.length) {
      trigger('stationNames');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStations.length]);

  useEffect(() => {
    if (selectedModalities.length) {
      trigger('modalityNames');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModalities.length]);

  useEffect(() => {
    if (selectedYear === '') {
      setValue('month', '');
    }
    setMonthSelectDisabled(selectedYear === '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  useEffect(() => {
    setIsSubmitted(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeChartDataType,
    activeChartDataSource,
    selectedYear,
    selectedMonth,
    selectedChartType,
    watchArchives,
    selectedStations.length,
    selectedModalities.length,
  ]);

  const renderedSteps = () => {
    return <TourReports type="statistics" data={data} options={options} />;
  };

  return (
    <>
      <Header title={t('titleStatistics')} TourComponent={renderedSteps()} />
      {settings !== undefined &&
        (canViewStatistics ? (
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <Box>
                  <Box sx={{ mb: 2 }}>
                    <Grid
                      container={true}
                      spacing={2}
                      alignItems="flex-start"
                      data-tour="statisticsCheckBoxes"
                    >
                      <Grid item={true} xs={12} md={12} lg={4}>
                        {typeCheckButtons.map((button: ISelectItem) => (
                          <React.Fragment key={button.id}>
                            <CheckedButton
                              sx={{ minWidth: 23 * 8, mr: 1 }}
                              size="large"
                              active={activeChartDataType === button.id}
                              rightMargin={true}
                              onClick={() =>
                                setActiveChartDataType(
                                  activeChartDataType === button.id ? '' : button.id,
                                )
                              }
                            >
                              {button.label}
                            </CheckedButton>
                          </React.Fragment>
                        ))}
                      </Grid>
                      {formItems.map((field: any) => (
                        <React.Fragment key={field.name}>
                          {field.type === 'select' && (
                            <Grid item={true} xs={12} md={6} lg={2}>
                              <FormSelect
                                name={field.name}
                                label={field.label}
                                required={field.required}
                                items={field.items}
                                disabled={get(field, 'disabled', false)}
                              />
                            </Grid>
                          )}
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Box>
                  <Box>
                    <Box sx={{ mb: 1 }}>
                      <Grid
                        container={true}
                        spacing={2}
                        alignItems="flex-start"
                        data-tour="statisticsAdditionalInfo"
                      >
                        <Grid item={true} xs={12} md={12} lg={4}>
                          {sourceCheckButtons.map((button: ISelectItem) => (
                            <CheckedButton
                              sx={{ minWidth: 23 * 8, mr: 1, mt: 0 }}
                              size="large"
                              key={button.id}
                              active={activeChartDataSource === button.id}
                              rightMargin={true}
                              onClick={() =>
                                setActiveChartDataSource(
                                  activeChartDataSource === button.id ? '' : button.id,
                                )
                              }
                            >
                              {button.label}
                            </CheckedButton>
                          ))}
                        </Grid>
                        {activeChartDataSource !== 'MODALITY' && (
                          <>
                            <Grid item={true} xs={8} md={6} lg={6}>
                              <FormAutocompleteMultiple
                                name="stationNames"
                                label={t('stationNames')}
                                options={allStationNames}
                                required={true}
                              />
                            </Grid>
                            <Grid item={true} xs={4} md={6} lg={2}>
                              <Button variant="contained" size="medium" onClick={toggleAllStations}>
                                {t(isSelectedAllStations ? 'setNoStations' : 'setAllStations')}
                              </Button>
                            </Grid>
                          </>
                        )}
                        {activeChartDataSource === 'MODALITY' && (
                          <>
                            <Grid item={true} xs={8} md={6}>
                              <FormAutocompleteMultiple
                                name="modalityNames"
                                label={t('modalityNames')}
                                options={allModalityNames}
                                required={true}
                              />
                            </Grid>
                            <Grid item={true} xs={4} md={6} lg={2}>
                              <Button
                                variant="contained"
                                size="medium"
                                onClick={toggleAllModalities}
                              >
                                {t(
                                  isSelectedAllModalities ? 'setNoModalities' : 'setAllModalities',
                                )}
                              </Button>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                  <Grid container={true} alignItems="flex-end" data-tour="statisticsShowButton">
                    <Grid item={true} xs={12} lg={4} xl={3}>
                      <Box sx={{ mt: 1 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          size="large"
                          disabled={activeChartDataType && activeChartDataSource ? false : true}
                        >
                          {t('show')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </FormProvider>
          </Papeer>
        ) : (
          <Papeer>
            <Typography>{t('cannotWiewStatisticNow')}</Typography>
          </Papeer>
        ))}

      <Bar data={{ datasets: data, labels }} options={options} />
    </>
  );
};

import { format } from 'date-fns';
import { get } from 'lodash';
import { ITest, IStudyQuestion, IGeneralQuestionForm } from './_types';
import { api } from 'utils/hooks/useApi';
import { TEST_FINISHED } from 'constants/constants';

export const getTestTypes = (): Promise<any | boolean> =>
  api()
    .get(`/portal-api/mamo/getTypes`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getTestStates = (): Promise<any | boolean> =>
  api()
    .get(`/portal-api/mamo/getStates`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const findTests = (
  values: any = {},
  forStartTest: boolean = false,
): Promise<boolean | ITest[]> =>
  api()
    .post(`/portal-api/mamo/find`, { ...values, forStartTest })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const createTest = (test: Partial<ITest>): Promise<boolean | ITest[]> =>
  api()
    .post(`/portal-api/mamo/createTest`, test)
    .then((response) => get(response, 'data'))
    .catch((error) => {
      throw new Error(error);
    });

export const editTest = (test: Partial<ITest>): Promise<boolean | ITest[]> =>
  api()
    .post(`/portal-api/mamo/editTest`, test)
    .then((response) => get(response, 'data'))
    .catch((error) => {
      throw new Error(error);
    });

export const getTest = (id: number): Promise<any | ITest> =>
  api()
    .get(`/portal-api/mamo/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const removeTest = (id: number) =>
  api()
    .get(`/portal-api/mamo/deleteTest`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const createGeneralQuestion = (
  question: Partial<IGeneralQuestionForm>,
): Promise<boolean | IGeneralQuestionForm> =>
  api()
    .post(`/portal-api/mamo/createGeneralQuestion`, question)
    .then((response) => get(response, 'data'))
    .catch((error) => {
      throw new Error(error);
    });

export const editGeneralQuestion = (
  question: Partial<IGeneralQuestionForm>,
): Promise<boolean | IGeneralQuestionForm> =>
  api()
    .post(`/portal-api/mamo/editGeneralQuestion`, question)
    .then((response) => get(response, 'data'))
    .catch((error) => {
      throw new Error(error);
    });

export const createStudyQuestion = (
  question: Partial<IStudyQuestion>,
): Promise<boolean | IStudyQuestion> =>
  api()
    .post(`/portal-api/mamo/createStudyQuestion`, question)
    .then((response) => get(response, 'data'))
    .catch((error) => {
      throw new Error(error);
    });

export const editStudyQuestion = (
  question: Partial<IStudyQuestion>,
): Promise<boolean | IStudyQuestion> =>
  api()
    .post(`/portal-api/mamo/editStudyQuestion`, question)
    .then((response) => get(response, 'data'))
    .catch((error) => {
      throw new Error(error);
    });

export const getGeneralQuestions = (testId: number): Promise<any | ITest> =>
  api()
    .get(`/portal-api/mamo/getGeneralQuestions`, { params: { testId } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getGeneralAnswers = (stepId: number): Promise<any | ITest> =>
  api()
    .get(`/portal-api/mamo/getGeneralAnswers`, { params: { stepId } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getStudyQuestions = (testId: number): Promise<any | ITest> =>
  api()
    .get(`/portal-api/mamo/getStudyQuestions`, { params: { testId } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const deleteStudyQuestion = (definitionId: number): Promise<boolean> =>
  api()
    .get(`/portal-api/mamo/deleteStudyQuestion`, { params: { definitionId } })
    .then((response) => true)
    .catch((error) => false);

export const deleteGeneralQuestion = (stepId: number): Promise<boolean> =>
  api()
    .get(`/portal-api/mamo/deleteGeneralQuestion`, { params: { stepId } })
    .then((response) => true)
    .catch((error) => false);

export const deleteGeneralAnswer = (answerId: number): Promise<boolean> =>
  api()
    .get(`/portal-api/mamo/deleteGeneralAnswer`, { params: { answerId } })
    .then((response) => true)
    .catch((error) => false);

export const cloneTest = (testId: number): Promise<ITest | boolean> =>
  api()
    .get('/portal-api/mamo/cloneTest', { params: { id: testId } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const publishTest = (testId: number): Promise<boolean> =>
  api()
    .get('/portal-api/mamo/publishTest', { params: { id: testId } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const executeTest = (testId: number, trial: boolean = true): Promise<any> =>
  api()
    .post('/portal-api/mamo/execute', { testId, trial })
    .then((response) => get(response, 'data'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const nextQuestion = (currentQuestion: any): Promise<any> =>
  api()
    .post('/portal-api/mamo/nextQuestion', currentQuestion)
    .then((response) => {
      const data = get(response, 'data');
      return data === '' ? TEST_FINISHED : get(response, 'data');
    })
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const previousQuestion = (currentQuestion: any): Promise<any> =>
  api()
    .post('/portal-api/mamo/previousQuestion', currentQuestion)
    .then((response) => get(response, 'data'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const finishTest = (currentQuestion: any): Promise<any> =>
  api()
    .post('/portal-api/mamo/finish', currentQuestion)
    .then((response) => get(response, 'data'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const getTestResults = (testExecutionId: any): Promise<any> =>
  api()
    .get('/portal-api/mamo/getTestResult', { params: { testExecutionId } })
    .then((response) => get(response, 'data'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const findTestResults = (from: any, to = format(new Date(), 'yyyy-MM-dd')): Promise<any> =>
  api()
    .post('/portal-api/mamo/findTestResults', { from, to })
    .then((response) => get(response, 'data'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

export const findTestStatistics = (values: any): Promise<any> =>
  api()
    .post('/portal-api/mamo/findTestStatistics', values)
    .then((response) => get(response, 'data'))
    .catch((error) => Promise.reject(get(error, 'response.data', '')));

import { includes, get, without, find, uniqBy } from 'lodash';
import { DOSE_MONITORING } from 'constants/constants';

import { IStudyReducerInitialState, StudyType } from './_types';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IStudyReducerInitialState = {
  list: [],
  send: [],
  send_confirm: [],
  reorder: [],
  split: [],
  edit: [],
  export: [],
  copy: [],
  move: [],
  archive: [],
  archiving: [],
  dose_monitoring: [],
  moveToFolder: [],
  listForFolder: [],
  listUnsigned: [],
  shredding: [],
  shreddingPrint: [],
  basket: [],
  moveToTrash: [],
  import: [],
};

export const studySlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    setStudiesBasketToStore: (state, action) => {
      const type: StudyType = action.payload.type;
      const currentItems = get(action, 'payload.currentItems', []);
      const items = action.payload.items;
      let newItems: any[] = [...currentItems];
      items.forEach((item: any) => (newItems = [...newItems, item]));
      state[type] = uniqBy(newItems, 'iid');
    },
    setStudiesToStore: (state, action) => {
      const type: StudyType = action.payload.type;
      const currentItems = get(state, type);
      let item = action.payload.item;
      const isSelected = get(action, 'payload.isSelected', false);
      if (type === DOSE_MONITORING && isSelected) {
        item = find(currentItems, { identificationNumber: item.identificationNumber });
      }
      state[type] = includes(currentItems, item)
        ? without(currentItems, item)
        : [...currentItems, item];
    },
    setBulkStudiesToStore: (state, action) => {
      const type: StudyType = action.payload.type;
      state[type] = action.payload.items;
    },
    resetStudiesInStore: (state, action) => {
      const type: StudyType = action.payload;
      state[type] = [];
    },
    resetAllStudies: () => {
      return initialState;
    },
  },
});

export const {
  setStudiesToStore,
  setBulkStudiesToStore,
  setStudiesBasketToStore,
  resetStudiesInStore,
  resetAllStudies,
} = studySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export default studySlice.reducer;

// export const studyReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     // case SET_STUDIES:
//     //   const currentItems = get(state, action.payload.type);
//     //   let item = action.payload.item;
//     //   const isSelected = get(action, 'payload.isSelected', false);
//     //   if (action.payload.type === DOSE_MONITORING && isSelected) {
//     //     item = find(currentItems, { identificationNumber: item.identificationNumber });
//     //   }
//     //   return {
//     //     ...state,
//     //     [action.payload.type]: includes(currentItems, item)
//     //       ? without(currentItems, item)
//     //       : [...currentItems, item],
//     //   };
//     // case SET_BULK_STUDIES:
//     //   return {
//     //     ...state,
//     //     [action.payload.type]: action.payload.items,
//     //   };
//     // case RESET_STUDIES:
//     //   return {
//     //     ...state,
//     //     [action.payload]: [],
//     //   };
//     // case RESET_ALL_STUDIES:
//     //   return initialState;
//     default:
//       return state;
//   }
// };

import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { compact, get, isArray, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from '@mui/material';

import { getByStates } from './_api';
import { IQueueRequestsSearch } from './_types';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useStudy } from 'utils/hooks/useStudy';
import { useActions } from 'utils/hooks/useActions';
import { useUser } from 'utils/hooks/useUser';
import { generateIID, joinParams } from 'utils/study';

import useAlerts from 'components/Alerts/useAlerts';
import { Papeer } from 'components/Papeer/Papeer';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';

import {
  OPERATION_TYPE_EDIT_PATIENT,
  OPERATION_TYPE_SEND,
  OPERATION_TYPE_EXPORT,
  OPERATION_TYPE_IMPORT,
  OPERATION_TYPE_REOPEN,
} from 'constants/constants';
import { useAppInfo } from 'utils/hooks/useAppInfo';

const QueueSearchForm: React.FC = () => {
  const { t } = useTranslation('SearchForm');
  const { hasRole, user } = useUser();
  const { storeQueues } = useActions();
  const { addErrorAlert } = useAlerts();
  const { compactMode } = useAppInfo();

  const defaultValue = [
    { id: 'PROCESSED', name: t('PROCESSED'.toLowerCase()), position: 1 },
    { id: 'WAITING', name: t('WAITING'.toLowerCase()), position: 2 },
  ];
  const states = [...defaultValue, { id: 'DONE', name: t('DONE'.toLowerCase()), position: 3 }];
  const statesFetched = isArray(states) && compact(states).length ? true : false;

  const { linkBack } = useStudy();

  const methods = useForm<IQueueRequestsSearch>();

  const { handleSubmit, reset, watch } = methods;
  const selectedStateOfQueue = watch('stateOfQueue');
  const canSearch = !isEmpty(selectedStateOfQueue);

  const { toggleLoader } = useAppGlobals();

  const submitHandler = async (values: any) => {
    toggleLoader();
    const userName = get(user, 'sub', '???');
    const transformedValues = {
      states:
        isArray(get(values, 'stateOfQueue')) && statesFetched
          ? compact(get(values, 'stateOfQueue')).map((state: any) => state.id)
          : !statesFetched
          ? ['PROCESSED', 'WAITING']
          : [],
    };
    const resp = await getByStates(transformedValues);
    storeQueues([]);
    if (isArray(resp)) {
      const queues = resp.map((queueItem) => {
        const operation = get(queueItem, 'operation', '');
        const isSend = operation === OPERATION_TYPE_SEND;
        const state = get(queueItem, 'state', '');
        const author = get(queueItem, 'user.username', '');
        const canShowDetail =
          operation !== '' &&
          operation !== OPERATION_TYPE_EXPORT &&
          operation !== OPERATION_TYPE_IMPORT &&
          operation !== OPERATION_TYPE_REOPEN;
        const canCancelRequest =
          (operation === OPERATION_TYPE_EXPORT ||
            operation === OPERATION_TYPE_IMPORT ||
            operation === OPERATION_TYPE_SEND) &&
          (state === 'PROCESSED' || state === 'WAITING') &&
          ((hasRole('ROLE_CANCEL_OWN_REQUEST') && author === userName) ||
            hasRole('ROLE_CANCEL_ANY_REQUEST'));
        const username = `${joinParams([
          get(queueItem, 'user.lastName'),
          get(queueItem, 'user.firstName'),
        ])}`;
        const patientNames = queueItem.studies.map((study: any) => ({
          name: `${joinParams([
            get(study, 'patient.name.lastName'),
            get(study, 'patient.name.firstName'),
          ])}`,
          iid: generateIID(study),
        }));
        let inArchive = '';
        if (operation === OPERATION_TYPE_EDIT_PATIENT) {
          inArchive = get(queueItem, 'sourceProduct.name', '?');
        }

        if (isSend) {
          const isSejf = get(queueItem, 'studySendRequestInfo.sejf', false);
          const exchangeNetworkName = get(
            queueItem,
            'studySendRequestInfo.exchangeNetworkName',
            '',
          );
          const facilityName = get(queueItem, 'studySendRequestInfo.facilityName', null);
          inArchive = `${t('Queues:targetNode')} ${
            isSejf ? t('Queues:drSejf') : exchangeNetworkName
          }${facilityName ? `, ${facilityName}` : ''} `;
        }

        return {
          ...queueItem,
          canShowDetail,
          canCancelRequest,
          id: queueItem.requestId,
          username,
          patientNames,
          operationOrig: operation,
          operation: isSend ? (
            <>
              {t(`Queues:${operation.toLowerCase()}`)}
              <br />
              {inArchive}
            </>
          ) : (
            t(`Queues:${operation.toLowerCase()}`) +
            (inArchive ? t('Queues:inArchive') + inArchive : '')
          ),
          operationSearch: isSend
            ? t(operation.toLowerCase()) + ' ' + inArchive
            : t(operation.toLowerCase()) + (inArchive ? t('Queues:inArchive') + inArchive : ''),
        };
      });
      storeQueues(queues);
    } else {
      addErrorAlert(t('submitError'));
    }
    toggleLoader(false);
  };

  const triggerSubmit = () => {
    try {
      const submitButton: HTMLElement = document.querySelector(
        '.search-form-button',
      ) as HTMLElement;
      submitButton.focus();
      submitButton.click();
    } catch (e) {
      console.debug(e);
    }
  };

  const loadEntities = async () => {
    toggleLoader();
    const backFromDetail = linkBack();

    if (!backFromDetail) {
      storeQueues([]);
      reset({
        stateOfQueue: defaultValue,
      });
      setTimeout(() => {
        triggerSubmit();
      }, 250);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Papeer bottomMargin={true}>
            <Grid
              container={true}
              spacing={2}
              alignItems="flex-start"
              data-tour="requestQueue-status"
            >
              <Grid item={true} xs={12} md={10}>
                <SimpleCheckboxArray
                  changeName="stateOfQueue"
                  name="stateOfQueue"
                  label={t('stateOfQueue')}
                  items={states}
                  defaultValue={defaultValue}
                  grid={compactMode ? {} : { xs: 12, md: 4, lg: 2, xl: 2 }}
                  compactMode={compactMode}
                />
              </Grid>
            </Grid>

            <Grid container={true} alignItems="flex-end" data-tour="requestQueue-searchButton">
              <Grid item={true} xs={12} lg={4} xl={3}>
                <Box sx={{ mt: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={!canSearch}
                    className="search-form-button"
                  >
                    {t('search')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>
    </>
  );
};

export default QueueSearchForm;

import { Box, Button, Typography } from '@mui/material';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { get, sortBy } from 'lodash';
import { UppyFileWithDicomTags } from './_types';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';
import { Papeer } from 'components/Papeer/Papeer';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

export const DicomCornestoneFileViewer = ({ row }: any) => {
  const [imageIds, setImageIds] = useState<string[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [instanceNumber, setInstanceNumber] = useState<string>('');
  const elementRef = useRef<HTMLDivElement | null>(null);
  const imageIdsRef = useRef<string[]>([]);

  useEffect(() => {
    imageIdsRef.current = imageIds;
  }, [imageIds]);

  const handleImageScrolling = (direction: any) => {
    if (direction === 'next') {
      let index = currentImageIndex + 1;
      if (index > imageIdsRef.current.length - 1) {
        index = imageIdsRef.current.length - 1;
      }
      setCurrentImageIndex(index);
    } else {
      let index = currentImageIndex - 1;
      if (index < 0) {
        index = 0;
      }
      setCurrentImageIndex(index);
    }
  };

  const handleUpdateImage = useCallback(
    (imageIndex: number) => {
      try {
        setInstanceNumber(`${imageIndex + 1}/${imageIds.length}`);
        setCurrentImageIndex(imageIndex);

        cornerstone.loadImage(imageIds[imageIndex]).then(
          (image: any) => {
            if (elementRef.current) {
              const viewport = cornerstone.getViewport(elementRef.current);
              cornerstone.displayImage(elementRef.current, image, viewport);
            }
          },
          (error: any) => console.error(error),
        );
      } catch (e) {
        console.error(e);
      }
    },
    [imageIds],
  );

  useEffect(() => {
    const fetchImageIds = async () => {
      const idsPromises = get(row, 'files', []).map(async (file: UppyFileWithDicomTags) => {
        const imageId2 = cornerstoneWADOImageLoader.wadouri.fileManager.add(file.data);
        return { imageId: imageId2, instanceNumber: file.instanceNumber };
      });

      const ids = await Promise.all(idsPromises);
      const sortedIds = sortBy(ids, (item) => item.instanceNumber).map((id) => id.imageId);
      setImageIds(sortedIds);
    };

    fetchImageIds();
  }, [row]);

  useEffect(() => {
    handleUpdateImage(currentImageIndex);

    const handleWheelEvent = (e: any) => {
      if (e.wheelDelta < 0 || e.detail > 0) {
        handleImageScrolling('next');
      } else {
        handleImageScrolling('previous');
      }
      e.preventDefault();
    };

    if (elementRef.current) {
      elementRef.current.addEventListener('mousewheel', handleWheelEvent);
      elementRef.current.addEventListener('DOMMouseScroll', handleWheelEvent);
    }

    return () => {
      if (elementRef.current) {
        elementRef.current.removeEventListener('mousewheel', handleWheelEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        elementRef.current.removeEventListener('DOMMouseScroll', handleWheelEvent);
      }
    };
    //eslint-disable-next-line
  }, [currentImageIndex, handleUpdateImage]);

  useEffect(() => {
    if (elementRef.current) {
      cornerstone.enable(elementRef.current);
      return () => {
        if (elementRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          cornerstone.disable(elementRef.current);
        }
      };
    }
  }, []);

  return (
    <Papeer>
      <Box margin="0 auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button sx={{ marginRight: 0 }} onClick={() => handleImageScrolling('previous')}>
          <KeyboardDoubleArrowLeft sx={{ fontSize: '4rem' }} />
        </Button>
        <Box
          ref={elementRef}
          sx={{
            width: '512px',
            height: '512px',
            borderStyle: 'solid',
            borderWidth: 'thin',
            margin: 0,
          }}
        >
          <canvas className="cornerstone-canvas" />
        </Box>
        <Button sx={{ marginLeft: 0 }}>
          <KeyboardDoubleArrowRight
            sx={{ fontSize: '4rem' }}
            onClick={() => handleImageScrolling('next')}
          />
        </Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box id="instanceNumber">
          <Typography>{instanceNumber}</Typography>
        </Box>
      </Box>
    </Papeer>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import LogSearchForm from './LogSearchForm';
import LogSearchResults from './LogSearchResults';
import Header from 'components/Header/Header';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourAuditLogs } from './TourAuditLogs';

const AuditLogs: React.FC = () => {
  const { t } = useTranslation('Logs');
  useWithTitle(); // sets title to document
  const renderedSteps = () => {
    return <TourAuditLogs />;
  };

  return (
    <>
      <Header title={t('auditLogList')} TourComponent={renderedSteps()} scrollIntoView={false} />
      <LogSearchForm />
      <LogSearchResults />
    </>
  );
};

export default AuditLogs;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';

import useValidationSchema from './_formSeries';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import FormInput from 'components/Form/Input/Input';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { get, isEmpty, trim } from 'lodash';
import { deleteSeries } from './_api';
import useAlerts from 'components/Alerts/useAlerts';
import { GridSeriesMUI } from 'components/SearchResultsMUI/GridSeriesMUI';
import { IStudyDetailSeriesForm } from './_types';
import { TAB_SERIES } from 'constants/constants';

const StudyDetailSeriesForm: React.FC<IStudyDetailSeriesForm> = ({
  toggleDialog,
  study,
  series,
  handleLifeCycle,
  previews,
  setNewPreviews,
  decubits,
  setNewDecubits,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { toggleLoader } = useAppGlobals();
  const { SeriesFormSchema } = useValidationSchema();
  const methods = useForm<any>({
    resolver: yupResolver(SeriesFormSchema),
  });
  const { handleSubmit, watch, reset } = methods;
  const canConfirm = isEmpty(trim(watch('reason')));

  const clearAction = () => {
    toggleDialog(false);
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const prepareValues = {
      reason: get(values, 'reason', ''),
      study: {
        studyInstanceUid: get(study, 'studyInstanceUid', ''),
        archive: {
          id: get(study, 'archive.id', 0),
        },
      },
      series: series.map((serie: any) => ({
        uid: get(serie, 'uid', null),
      })),
    };
    const response = await deleteSeries(prepareValues);
    if (response) {
      addSuccessAlert(t('confirmDeletingSeries'));
      clearAction();
      handleLifeCycle(
        get(study, 'studyInstanceUid', ''),
        get(study, 'archive.id', 0).toString(),
        false,
        TAB_SERIES,
        true,
      );
    } else {
      addErrorAlert(t('errorDeletingSeries'));
    }
    toggleLoader(false);
  });

  useEffect(() => {
    reset({ reason: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header title={t('removeSeries')} />
      <Papeer>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Grid container={true} alignItems="flex-end">
              <Grid item={true} xs={12}>
                <FormInput
                  name="reason"
                  label={t('reasonRemoveSeries')}
                  required={true}
                  multiline={true}
                  rows={2}
                />
                <EntityButtons
                  confirmReplaceSave={true}
                  toggleDialog={clearAction}
                  disabledSubmit={canConfirm}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Papeer>
      <Papeer>
        <GridSeriesMUI
          rows={series}
          previews={previews}
          setNewPreviews={setNewPreviews}
          decubits={decubits}
          setNewDecubits={setNewDecubits}
        />
      </Papeer>
    </>
  );
};

export default StudyDetailSeriesForm;

import { useEffect, useState } from 'react';
import { useTour } from '@reactour/tour';
import useTourControlDropdown from 'components/Tour/useTourControlDropdown';
import useGenerateSteps from 'components/Tour/useGenerateSteps';
import { setTourControlPropsToStore } from 'store/reducers/appReducer';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useDispatch } from 'react-redux';

interface useReactTourProps {
  stepsDefinition: any;
}

export const useReactTour = ({ stepsDefinition }: useReactTourProps) => {
  const { isOpen, setIsOpen, currentStep, setCurrentStep, setSteps } = useTour();
  const { tourControlPropsStore } = useAppInfo();
  const [serializedSteps, setSerializedSteps] = useState('');
  const [serializedStepsDefinition, setSerializedStepsDefinition] = useState('');
  const dispatch = useDispatch();

  //Handle dynamics of tour when opening and closing dropdowns
  useTourControlDropdown({
    currentTourStep: currentStep,
    setCurrentTourStep: setCurrentStep,
  });

  //Generate the steps for the tour based on the type of tour passed into the useReactTour hook (e.g. 'searchForm')
  const { steps } = useGenerateSteps({
    isDropDownOpen: tourControlPropsStore?.isDropDownOpen,
    currentOpenDropdown: tourControlPropsStore?.currentOpenDropdown,
    setCurrentStep: setCurrentStep,
    stepsDefinition,
  });

  useEffect(() => {
    const newSerializedSteps = JSON.stringify(steps);
    const newSerializedStepsDefinition = JSON.stringify(stepsDefinition);

    if (
      newSerializedSteps !== serializedSteps ||
      newSerializedStepsDefinition !== serializedStepsDefinition
    ) {
      setSerializedSteps(newSerializedSteps);
      setSerializedStepsDefinition(newSerializedStepsDefinition);

      setSteps && setSteps(steps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tourControlPropsStore?.isDropDownOpen,
    serializedSteps,
    serializedStepsDefinition,
    setSteps,
    stepsDefinition,
    steps,
  ]);

  // Handle opening the tour from the tour button
  const handleOpenReactTour = () => {
    setCurrentStep(0);
    if (setSteps) {
      setSteps(steps);
    }
    setIsOpen(true);
  };

  const handleCloseReactTour = () => {
    dispatch(setTourControlPropsToStore({ isTourOpen: false }));
  };

  useEffect(() => {
    if (!isOpen) handleCloseReactTour();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (tourControlPropsStore?.isTourOpen) {
      handleOpenReactTour();
    }
    if (!tourControlPropsStore?.isTourOpen) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourControlPropsStore?.isTourOpen]);

  const tourControlProps = {
    isTourOpen: isOpen,
    setIsTourOpen: setIsOpen,
    currentTourStep: currentStep,
    setCurrentTourStep: setCurrentStep,
    handleOpenReactTour,
    setSteps,
    steps,
  };

  return { steps, tourControlProps };
};

import React, { useEffect, useState } from 'react';
import { filter, find, get, remove } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormControlLabel, Switch } from '@mui/material';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { setWorkPlaceFavouriteFacility } from './_api';
import { getFacilities } from '../Users/_api';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { EXCHANGE_NETWORK_EPACS, EXCHANGE_NETWORK_REDIMED } from 'constants/constants';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'workPlaceFacilitiesMUI';

const WorkplaceFacilityComponent: React.FC<any> = ({ code, workplaceId }) => {
  const { t } = useTranslation('Facilities');
  const dispatch = useAppDispatch();
  const { compactMode, confirmationData } = useAppInfo();

  const [facilities, setFacilities] = useState<any[]>([]);

  const { toggleLoader } = useAppGlobals();
  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };
  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 80,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <FormControlLabel
              sx={{ maxHeight: 80 }}
              control={
                <Switch
                  checked={row.favourite}
                  onChange={(e, state) => {
                    dispatch(
                      storeConfirmationDataToStore({
                        title: 'Grid:confirmChangeState',
                        id: get(row, 'id'),
                        state,
                        workplaceId: get(row, 'workplaceId'),
                        confirmAction: 'confirmActionChangeFavouriteForWorkplace',
                      }),
                    );
                  }}
                />
              }
              label={t('Grid:favourite')}
            />
          );
        },
      },
      { field: 'name', headerName: t('facility') },
      { field: 'code', headerName: t('code') },
      { field: 'city', headerName: t('city') },
      { field: 'country', headerName: t('country') },
      {
        field: 'epacs',
        headerName: t('epacs'),
        valueGetter: (value: any, row: any) => {
          const out = find(row.facilityExchangeNetworkModels, (item) => {
            return get(item, 'exchangeNetwork.name', null) === EXCHANGE_NETWORK_EPACS;
          });
          return out ? `${EXCHANGE_NETWORK_EPACS} (${get(out, 'identifier', '')})` : null;
        },
      },
      {
        field: 'redimed',
        headerName: t('redimed'),
        valueGetter: (value: any, row: any) => {
          const out = find(row.facilityExchangeNetworkModels, (item) => {
            return get(item, 'exchangeNetwork.name', null) === EXCHANGE_NETWORK_REDIMED;
          });
          return out ? `${EXCHANGE_NETWORK_REDIMED} (${get(out, 'identifier', '')})` : null;
        },
      },
    ]),
  );

  const loadFacilities = async () => {
    toggleLoader();
    const data = await getFacilities(false, true, false, code);
    if (data) {
      setFacilities(
        filter(
          (data || []).map((facility: any) => ({ ...facility, workplaceId })),
          ['activeFacility', false],
        ),
      );
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadFacilities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeFavourite = async (id: string, state: boolean, workplaceId: number) => {
    toggleLoader();
    const changed = await setWorkPlaceFavouriteFacility(id, state, workplaceId);
    if (changed) {
      const newFacilities = [...facilities];
      remove(newFacilities, (facility) => {
        return get(facility, 'id') === get(changed, 'id');
      });
      newFacilities.push({ ...changed, workplaceId });
      setFacilities(newFacilities);
    }
    toggleLoader(false);
  };

  const confirmActionChangeFavouriteForWorkplace = () => {
    onChangeFavourite(
      get(confirmationData, 'id'),
      get(confirmationData, 'state'),
      get(confirmationData, 'workplaceId'),
    );
    clearAction();
  };

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={facilities}
        columns={columns}
        sxPapeer={{ mt: compactMode ? 1 : 2 }}
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />

      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmActionChangeFavouriteForWorkplace}
        />
      )}
    </>
  );
};

export default WorkplaceFacilityComponent;

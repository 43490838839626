import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { find, get, isArray } from 'lodash';
import {
  AppBar,
  Button,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';

import useItemsForOrderDetail from './_ItemsForOrderDetail';
import { getAllModalities } from 'modules/Administration/ModalitiesUnitsStations/_apiModalities';
import { IOrderDetail } from './_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Papeer } from 'components/Papeer/Papeer';
import Header from 'components/Header/Header';
import { Line } from 'components/Line/Line';
import { encryptId } from 'utils/hooks/useApp';
import OrderDetailComments from './OrderDetailComments';
import OrderDetailAttachments from './OrderDetailAttachments';
import OrderDetailImport from './OrderDetailImport';
import { Box } from '@mui/system';
import { TourOrders } from './TourOrders';

const OrderDetailContent: React.FC<IOrderDetail> = ({
  order,
  loadOrder,
  attachments,
  user,
  linkBack,
  detailIID,
  patientId,
  accessionNumber,
  mainTab,
  isFromStudy = false,
  hasRole,
}) => {
  const { t } = useTranslation('Orders');
  const navigate = useNavigate();
  const { toggleLoader } = useAppGlobals();
  const { canImport, fieldsForOrder, fieldsForPatient, fieldsForPrint, setAction } =
    useItemsForOrderDetail(order, t, hasRole, isFromStudy);

  const [modalities, setModalities] = useState<any[]>([]);
  const [commentItem, setCommentItem] = useState<any>(null);
  const [dialogStates, setDialogStates] = useState<any>({
    comments: false,
    attachments: false,
    import: false,
  });
  const [activeTab, setActiveTab] = useState<string>(
    mainTab === 'att' ? 'attachments' : 'comments',
  );

  const getEntities = async () => {
    toggleLoader();
    const modalities = await getAllModalities();
    if (isArray(modalities)) {
      setModalities(modalities);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = (dialogName: string, state: boolean = true) => {
    setDialogStates({ ...dialogStates, [dialogName]: state });
  };
  const onPrint = () => window.print();

  const renderedSteps = () => {
    return <TourOrders type="details" />;
  };

  return (
    <>
      {order && (
        <>
          <Box sx={{ displayPrint: 'none' }}>
            <Typography component="div">
              <Papeer sx={isFromStudy ? { p: 0 } : {}}>
                {!isFromStudy && (
                  <Header title={t('detail')} backUrl={linkBack} TourComponent={renderedSteps()} />
                )}
                {isFromStudy && hasRole('ROLE_MWL_VIEW') && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    component={Link}
                    {...({ to: linkBack } as any)}
                    sx={{ mr: 1 }}
                  >
                    {t('action.showStudyOrder')}
                  </Button>
                )}
                <div id="patient">
                  <Grid container={true} spacing={2} data-tour="orderDetailMain">
                    {fieldsForPatient.map((field: any) => (
                      <Grid item={true} xs={12} md={12} lg={12} key={field.name}>
                        {field.value}
                      </Grid>
                    ))}
                  </Grid>
                </div>
                <Line topMargin={2} bottomMargin={2} />
                <Grid container={true} spacing={2} data-tour="orderDetailAdditionalInfo">
                  {fieldsForOrder.map((field: any) => (
                    <Grid item={true} xs={6} md={4} lg={3} {...field.grid} key={field.name}>
                      <Box component="label" sx={{ fontWeight: 'bold' }}>
                        {field.label}
                      </Box>
                      <div>
                        {field.name === 'modality'
                          ? field.value
                            ? field.value.split(', ').map((mod: any) => {
                                let findModality = find(modalities, (item) => {
                                  return item.name === mod;
                                });
                                if (findModality === undefined) {
                                  findModality = { name: 'OTHER - ' + mod, color: '#000000' };
                                }
                                return (
                                  <Box
                                    component="span"
                                    key={mod}
                                    sx={{
                                      color: '#ffffff',
                                      fontSize: 10,
                                      fontWeight: 'bold',
                                      padding: '3px 8px',
                                      display: 'inline',
                                      textAlign: 'center',
                                      whiteSpace: 'nowrap',
                                      verticalAlign: 'baseline',
                                      borderRadius: '0.25em',
                                      mr: 0.5,
                                      backgroundColor: findModality.color,
                                    }}
                                  >
                                    {findModality.name}
                                  </Box>
                                );
                              })
                            : ''
                          : field.value}
                      </div>
                    </Grid>
                  ))}
                </Grid>
                {!isFromStudy && (
                  <>
                    <Line bottomMargin={1} />
                    <Grid container={true} spacing={2} data-tour="orderDetailActionButtons">
                      <Grid item={true}>
                        <Button
                          key="edit"
                          onClick={() => {
                            navigate(
                              `/orders/${encryptId(patientId)}/${encryptId(
                                accessionNumber,
                              )}/edit?from=detail${detailIID ? '&backTo=' + detailIID : ''}`,
                            );
                          }}
                          size="small"
                          color="inherit"
                          sx={{ mr: 1, mt: 1, border: '1px solid #ccc' }}
                          disabled={setAction.update.disable}
                        >
                          <EditIcon sx={{ mr: 1, fontSize: 20 }} />
                          {t('action.edit')}
                        </Button>
                        {canImport && (
                          <Button
                            key="import"
                            onClick={() => {
                              handleDialog('import');
                            }}
                            size="small"
                            color="inherit"
                            sx={{ mr: 1, mt: 1, border: '1px solid #ccc' }}
                            disabled={setAction.addAttachment.disable}
                          >
                            <AddIcon sx={{ mr: 1, fontSize: 20 }} />
                            {t('action.createStudy')}
                          </Button>
                        )}
                        <Button
                          key="print"
                          onClick={() => {
                            onPrint();
                          }}
                          size="small"
                          color="inherit"
                          sx={{ mr: 1, mt: 1, border: '1px solid #ccc' }}
                          disabled={setAction.print.disable}
                        >
                          <PrintIcon sx={{ mr: 1, fontSize: 20 }} />
                          {t('action.print')}
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Papeer>
            </Typography>
            <Paper elevation={0}>
              <AppBar
                position="static"
                color="default"
                sx={{ mt: 2 }}
                data-tour="orderDetailNavBar"
              >
                <Tabs
                  value={activeTab}
                  onChange={(event: any, value: any) => setActiveTab(value)}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab value="comments" label={t('comments')} />
                  <Tab value="attachments" label={t('attachments')} />
                </Tabs>
              </AppBar>
              <Box sx={{ p: 2 }}>
                {activeTab === 'comments' && (
                  <>
                    {!isFromStudy && !setAction.addComment.disable && (
                      <Button
                        onClick={() => {
                          handleDialog('comments');
                          setCommentItem(null);
                        }}
                        size="small"
                        variant="contained"
                        color="primary"
                        sx={{ mb: 2 }}
                      >
                        {t('action.addComment')}
                      </Button>
                    )}
                    <OrderDetailComments
                      commentItem={commentItem}
                      setCommentItem={setCommentItem}
                      comments={get(order, 'comments', [])}
                      dialogState={dialogStates.comments}
                      handleDialog={handleDialog}
                      getComments={loadOrder}
                      user={user}
                      order={order}
                      canEditDelete={!isFromStudy}
                      hasRole={hasRole}
                    />
                  </>
                )}
                {activeTab === 'attachments' && (
                  <>
                    {!setAction.addAttachment.disable && (
                      <Button
                        onClick={() => handleDialog('attachments')}
                        size="small"
                        variant="contained"
                        color="primary"
                        sx={{ mb: 2 }}
                      >
                        {t('action.addAttachment')}
                      </Button>
                    )}
                    <OrderDetailAttachments
                      order={order}
                      attachments={attachments}
                      dialogState={dialogStates.attachments}
                      handleDialog={handleDialog}
                      loadOrder={loadOrder}
                      accessionNumber={accessionNumber}
                      patientId={patientId}
                      availableRemove={!isFromStudy}
                      setActiveTab={setActiveTab}
                    />
                  </>
                )}
              </Box>
            </Paper>
            <OrderDetailImport
              dialogState={dialogStates.import}
              handleDialog={handleDialog}
              order={order}
            />
          </Box>
          <Typography component="div" sx={{ display: 'none', displayPrint: 'block' }}>
            <Papeer>
              <Typography variant="h6">{t(`print.title`)}</Typography>
              <Table>
                <TableBody>
                  {fieldsForPrint.map((field: any) => (
                    <TableRow key={field.name}>
                      <TableCell>{field.label}</TableCell>
                      <TableCell>{field.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Papeer>
          </Typography>
        </>
      )}
    </>
  );
};

export default OrderDetailContent;

import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';

export const getByStates = (data: any): Promise<any> | boolean =>
  api()
    .post(`/portal-api/requestsQueue/get`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const cancelRequest = (requestId: number): Promise<any> | boolean =>
  api()
    .get(`/portal-api/requestsQueue/cancel`, { params: { requestId } })
    .then((response) => get(response, 'data', false))
    .catch((error) => false);

import { Box } from '@mui/material';
import { IImg } from './_types';
import { isString } from 'lodash';

export const Img: React.FC<IImg> = ({ width, height, src, alt, style, sx }) => {
  let computedHeight = isString(height) && height.indexOf('px') === -1 ? `${height}px` : height;
  let computedWidth = isString(width) && width.indexOf('px') === -1 ? `${width}px` : width;
  return (
    <Box
      component="img"
      width={computedWidth}
      height={computedHeight}
      alt={alt}
      src={src}
      style={style}
      sx={{ ...sx, maxWidth: '100%' }}
    />
  );
};

import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';

const useValidationSchema = (t: TFunction) => {
  const { required } = useValidators();

  const NotificationTemplateFormSchema = yup.object().shape({
    eventTypeID: required(yup.string()),
    reactionTypeID: required(yup.string()),
  });

  return { NotificationTemplateFormSchema };
};

export default useValidationSchema;

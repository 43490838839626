import { Grid, Tooltip, Typography } from '@mui/material';
import { INewStudyForm } from './_types';
import FormInput from 'components/Form/Input/Input';
import { useTranslation } from 'react-i18next';
import { compact, get } from 'lodash';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { SEXES } from 'constants/constants';
import FormDatePicker from 'components/Form/Date/Date';
import FormSelect from 'components/Form/Select/Select';

export const NewStudyForm: React.FC<INewStudyForm> = ({
  dicomData,
  initialValues,
  isRequest,
  isRequestEdit,
  sourceStudy,
  targetStudy,
}) => {
  const { t } = useTranslation('Studies');
  const { splitValidators } = useStudyInfo();
  const { portalSetting } = useAppInfo();

  const studyDescriptionProps = {
    required: get(splitValidators, 'study.description.required', false),
    inputProps: { maxLength: get(splitValidators, 'study.description.max', 60) },
  };

  interface IField {
    name: string;
    label: string;
    required: boolean;
    inputProps?: { [key: string]: any };
    hasOwnChange?: boolean;
    type?: string;
    items?: { id: string; label: string }[];
    placeholder?: string;
    tooltip?: string;
  }

  const setPhysician = (physicianLabel: string) => {
    return [
      // { type: 'div', name: physicianLabel, label: t(physicianLabel) },
      {
        name: `targetStudy.${physicianLabel}.name.lastName`,
        label: t('lastName'),
        required: false,
        inputProps: {
          maxLength: get(splitValidators, `study.${physicianLabel}.name.lastName.max`, 60),
        },
      },
      {
        name: `targetStudy.${physicianLabel}.name.firstName`,
        label: t('firstName'),
        required: false,
        inputProps: {
          maxLength: get(splitValidators, `study.${physicianLabel}.name.firstName.max`, 60),
        },
      },
      {
        name: `targetStudy.${physicianLabel}.name.middleName`,
        label: t('middleName'),
        required: false,
        inputProps: {
          maxLength: get(splitValidators, `study.${physicianLabel}.name.middleName.max`, 60),
        },
      },
      {
        name: `targetStudy.${physicianLabel}.name.prefix`,
        label: t('prefix'),
        required: false,
        inputProps: {
          maxLength: get(splitValidators, `study.${physicianLabel}.name.prefix.max`, 60),
        },
      },
      {
        name: `targetStudy.${physicianLabel}.name.suffix`,
        label: t('suffix'),
        required: false,
        inputProps: {
          maxLength: get(splitValidators, `study.${physicianLabel}.name.suffix.max`, 60),
        },
      },
    ];
  };

  const sections: { label?: string; id: string; fields: IField[] }[] = compact([
    {
      id: 'first',
      fields: [
        {
          name: 'targetStudy.patient.name.lastName',
          label: t('lastName'),
          required: get(splitValidators, 'patient.name.lastName.required', false),
          inputProps: { maxLength: get(splitValidators, 'patient.name.lastName.max', 60) },
        },
        {
          name: 'targetStudy.patient.name.firstName',
          label: t('firstName'),
          required: get(splitValidators, 'patient.name.firstName.required', false),
          inputProps: { maxLength: get(splitValidators, 'patient.name.firstName.max', 60) },
        },
        {
          name: 'targetStudy.patient.name.middleName',
          label: t('middleName'),
          required: get(splitValidators, 'patient.name.middle.required', false),
          inputProps: { maxLength: get(splitValidators, 'patient.name.middleName.max', 60) },
        },
        {
          name: 'targetStudy.patient.name.prefix',
          label: t('prefix'),
          required: get(splitValidators, 'patient.name.prefix.required', false),
          inputProps: { maxLength: get(splitValidators, 'patient.name.prefix.max', 60) },
        },
        {
          name: 'targetStudy.patient.name.suffix',
          label: t('suffix'),
          required: get(splitValidators, 'patient.name.suffix.required', false),
          inputProps: { maxLength: get(splitValidators, 'patient.name.suffix.max', 60) },
        },
      ],
    },
    {
      id: 'second',
      fields: [
        {
          name: 'targetStudy.patient.identificationNumber',
          label: t('patientID'),
          required: get(splitValidators, 'patient.identification.required', false),
          inputProps: { maxLength: get(splitValidators, 'patient.identification.max', 64) },
          hasOwnChange: true,
        },
        {
          type: 'select',
          name: 'targetStudy.patient.sex',
          label: t('sex'),
          items: SEXES.map((sex) => ({ id: sex.value, label: t(sex.label) })),
          required: get(splitValidators, 'patient.sex.required', false),
          inputProps: { maxLength: get(splitValidators, 'patient.identification.max', 1) },
        },
        {
          type: 'datePicker',
          name: 'targetStudy.patient.dateBirth',
          label: t('dateOfBirth'),
          required: get(splitValidators, 'patient.dateBirth.required', false),
        },
        {
          type: 'datePicker',
          name: 'targetStudy.dateCreated',
          label: t('dateCreated'),
          required: get(splitValidators, 'study.studyDate.required', false),
        },
        {
          name: 'targetStudy.timeCreated',
          label: t('timeCreated'),
          placeholder: 'hh:mm',
          required: get(splitValidators, 'study.studyTime.required', false),
          inputProps: { maxLength: 5 },
        },
      ],
    },
    {
      id: 'third',
      fields: [
        {
          name: 'targetStudy.accessionNumber',
          label: t('accessionNumber'),
          required: get(splitValidators, 'study.accessionNumber.required', false),
          inputProps: { maxLength: get(splitValidators, 'study.accessionNumber.max', 64) },
        },
        {
          type: 'number',
          name: 'targetStudy.patientDispositions.size',
          label: t('sizeLabel'),
          required: get(splitValidators, 'study.patientDispositions.size.required', false),
          inputProps: {
            maxLength: get(splitValidators, 'study.patientDispositions.size.max', 16),
            step: 'any',
            autoComplete: 'off',
            placeholder: t('sizePlaceHolder'),
          },
          tooltip: t('sizeTooltip'),
        },
        {
          type: 'number',
          name: 'targetStudy.patientDispositions.weight',
          label: t('weightLabel'),
          required: get(splitValidators, 'study.patientDispositions.weight.required', false),
          inputProps: {
            maxLength: get(splitValidators, 'study.patientDispositions.weight.max', 16),
            step: 'any',
            autoComplete: 'off',
            placeholder: t('weightPlaceHolder'),
          },
          tooltip: t('weightTooltip'),
        },
      ],
    },

    get(portalSetting, 'seeRequestingPhysician', null)
      ? {
          id: 'fourth',
          label: t('requestingPhysician'),
          fields: setPhysician('requestingPhysician'),
        }
      : null,
    get(portalSetting, 'seeReferringPhysician', null)
      ? {
          id: 'fifth',
          label: t('referringPhysician'),
          fields: setPhysician('referringPhysician'),
        }
      : null,
    get(portalSetting, 'seePerformingPhysician', null)
      ? {
          id: 'sixth',
          label: t('performingPhysician'),
          fields: setPhysician('performingPhysician'),
        }
      : null,
  ]);

  return (
    <>
      {sections.map((section) => (
        <Grid container={true} key={section.id} spacing={1}>
          {section.label ? (
            <Grid item={true} xs={12}>
              <Typography sx={{ mt: 1, fontWeight: 'bold' }}>{section.label}</Typography>
            </Grid>
          ) : null}
          {section.fields.map((field) => {
            const type = get(field, 'type', 'text');
            return (
              <Grid
                item={true}
                xs={12}
                md={6}
                lg={4}
                key={field.name}
                sx={{
                  maxWidth: {
                    xl: `20%`,
                  },
                }}
              >
                {type === 'div' && (
                  <Typography variant="body1" sx={{ mt: 2, fontWeight: 'bold' }}>
                    {field.label}
                  </Typography>
                )}
                {type === 'select' && <FormSelect {...field} />}

                {type === 'datePicker' && (
                  <FormDatePicker {...field} isRequired={get(field, 'required', false)} />
                )}
                {type === 'number' &&
                  (get(field, 'tooltip', false) ? (
                    <Tooltip title={field.tooltip}>
                      <span style={{ paddingBottom: '1rem' }}>
                        <FormInput {...field} type="number" />
                      </span>
                    </Tooltip>
                  ) : (
                    <FormInput {...field} type="number" />
                  ))}
                {type === 'text' && <FormInput {...field} type="text" />}
              </Grid>
            );
          })}
        </Grid>
      ))}
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <FormInput
            name="targetStudy.studyDescription"
            label={t('studyDescription')}
            type="text"
            {...studyDescriptionProps}
          />
        </Grid>
      </Grid>
    </>
  );
};

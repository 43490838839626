import useValidators from 'components/Form/validations/useValidators';
import * as yup from 'yup';

export const useValidationSchema = () => {
  const { max } = useValidators();
  // username, firstName, lastName
  const FrontendSettingsFormSchema = yup.object().shape({
    udi: max(yup.string().trim(), 52),
  });
  return { FrontendSettingsFormSchema };
};

export default useValidationSchema;

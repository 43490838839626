import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isArray, isEmpty } from 'lodash';
import { Box, Paper, Tooltip, Typography } from '@mui/material';
import { Description as GridDetailIcon } from '@mui/icons-material';
import { INCPeHSearchResults } from './_types';
import { ClinicalPortalByNCPeHDetail } from './NCPeHDetail';
import { scrollToTopForm } from 'utils/scrollTo';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { joinParams } from 'utils/study';
import { format } from 'date-fns';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';

const formatDate = 'dd.MM.yyyy';
const muiGridKey = 'ncpehSearchResults';

const NCPeHSearchResults: React.FC<INCPeHSearchResults> = ({ items }) => {
  const { t } = useTranslation('NCPeH');
  const navigate = useNavigate();

  const [changePatients, setChangePatients] = useState<boolean>(false);

  const shouldShowGrid = isArray(items) && items.length > 1 ? true : false;
  const shouldShowDetail = isArray(items) && items.length === 1 ? true : false;

  useEffect(() => {
    setChangePatients(true);
    if (!isEmpty(items)) {
      scrollToTopForm('search-results', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const onCustomEntityDetail = (row: any) => {
    navigate(`/clinicPortal/byNCPeH/${row.id}`);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          width: 50,
          renderCell: ({ row }: GridRenderCellParams) => (
            <GridActionsCellItem
              icon={
                <Tooltip title={t('SearchResults:showDetail')}>
                  <GridDetailIcon />
                </Tooltip>
              }
              label="Detail"
              onClick={() => onCustomEntityDetail(row)}
            />
          ),
        },
        {
          field: 'name',
          headerName: t('name'),
          valueGetter: (value: any, row: any) =>
            joinParams([get(row, 'familyName', ''), get(row, 'givenName', '')]),
        },
        {
          field: 'patientBirthDate',
          headerName: t('patientBirthDate'),
          valueGetter: (_value: any, row: any) => {
            const value = get(row, 'birthDate', '');
            try {
              return format(new Date(value), formatDate);
            } catch (e) {
              return '';
            }
          },
        },
        { field: 'description', headerName: t('description') },
        { field: 'author', headerName: t('author') },
        {
          field: 'patientSex',
          headerName: t('patientSex'),
          valueGetter: (value: any, row: any) =>
            t('patient_sex_' + get(row, 'administrativeGender')),
        },
        {
          field: 'address',
          headerName: t('address'),
          valueGetter: (value: any, row: any) =>
            joinParams(
              [
                get(row, 'streetAddress', ''),
                joinParams([get(row, 'postalCode', ''), get(row, 'city', '')]),
              ],
              ', ',
            ),
        },
        { field: 'country', headerName: t('country') },
        { field: 'email', headerName: t('email') },
        { field: 'telephone', headerName: t('telephone') },
      ],
      250,
    ),
  );

  return (
    <>
      {shouldShowGrid ? (
        <>
          <MuiGrid
            gridKey={muiGridKey}
            rows={items}
            columns={columns}
            initialSortMode={[{ field: 'name', sort: 'asc' }]}
            rowHeight={true}
          />
          <Box id="search-results" sx={{ pt: 7 }} />
        </>
      ) : shouldShowDetail ? (
        <>
          <ClinicalPortalByNCPeHDetail
            isFromSearch={true}
            parId={items[0].id}
            changePatients={changePatients}
            setChangePatients={setChangePatients}
          />
          <Box id="search-results" sx={{ pt: 7 }} />
        </>
      ) : (
        <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
          <Typography>{t('noData')}</Typography>
        </Paper>
      )}
    </>
  );
};

export default NCPeHSearchResults;

import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';

export const getSize = (productId: number, studyUid: string): Promise<boolean> =>
  api()
    .post(`/portal-api/studyExport/getStudySize`, { productId, studyUid }, { timeout: 1000000 })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllStudyExportTypes = (): Promise<any | boolean> =>
  api()
    .get(`/portal-api/studyExport/getAllStudyExportTypes`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const exportStudy = (data: any) => api().post(`/portal-api/studyExport/export`, data);

export const sign = (requestID: number): Promise<any> =>
  api()
    .get(`/portal-api/studyExport/sign`, { params: { requestID } })
    .then((response) => response)
    .catch((error) => false);

export const getAllExportList = (onlyAvailable: boolean): Promise<any | boolean> =>
  api()
    .get(`/portal-api/studyExport/getExportList`, { params: { onlyAvailable } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { ILanguage } from './_types';

export const getAllLanguages = (): Promise<ILanguage[]> =>
  api()
    .get(`/portal-api/language/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getLanguage = (id: string): Promise<ILanguage> =>
  api()
    .get(`/portal-api/language/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editLanguage = (language: ILanguage): Promise<boolean> =>
  api()
    .post(`/portal-api/language/edit`, language)
    .then((response) => true)
    .catch((error) => false);

import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = (isCreating: boolean) => {
  const { required } = useValidators();

  const CommentFormSchema = isCreating
    ? yup.object().shape({
        comment: yup.object({
          text: required(yup.string()),
        }),
      })
    : yup.object().shape({
        text: required(yup.string()),
      });

  return { CommentFormSchema };
};

export default useValidationSchema;

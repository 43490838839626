import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

export const TourDoseMonitoring = () => {
  const { t } = useTranslation('Reactour');

  const allSteps = () => {
    const steps = {
      '[data-tour="backButton"]': t('study.back'),
      '[data-tour="studyDoseMonitoringExport"]': t('study.doseMonitoring.export'),
      '[data-tour="studyDoseMonitoringGrid"]': t('study.doseMonitoring.grid'),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });

  return <HelperComponent />;
};

import React from 'react';
import { useParams } from 'react-router';
import { find, get } from 'lodash';

import DictionariesItemDictionary from './DictionariesItemDictionary';
import Physicians from './Physicians';
import { items } from './_items';
import { useUser } from 'utils/hooks/useUser';
import { RestrictedPage } from 'components/RestrictedSection/RestrictedPage';

export const DictionariesItem: React.FC = () => {
  const params = useParams();
  const dictionary = get(params, 'dictionary', null);
  const { hasRole } = useUser();

  const item = find(items, { name: dictionary });
  const hasNotRole = get(item, 'role', '') ? hasRole(get(item, 'role', '')) : false;

  return (
    <>
      {!hasNotRole ? (
        <RestrictedPage allowedRoles={get(item, 'role', '')} />
      ) : dictionary === 'physicians' ? (
        <Physicians dictionary={dictionary} />
      ) : (
        <DictionariesItemDictionary dictionary={dictionary} />
      )}
    </>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Tab, Tabs, Typography } from '@mui/material';

import TabRSA from './TabRSA';
import Header from 'components/Header/Header';

import { useUser } from 'utils/hooks/useUser';
import { useWithTitle } from 'utils/hooks/useWithTitle';

export const Tools: React.FC = () => {
  const { t } = useTranslation('Tools');
  useWithTitle(); // sets title to document
  const { hasRole } = useUser();
  const [activeTab, setActiveTab] = useState<string>('rsa');

  return (
    <Typography component="div">
      <Header title={t('tools')} />
      <AppBar position="static" color="default" sx={{ mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={false}
        >
          {hasRole('ROLE_TOOLS_RSA') && <Tab value="rsa" label={t('toolsRSA')} />}
        </Tabs>
      </AppBar>
      <Typography component="div">
        {activeTab === 'rsa' && hasRole('ROLE_TOOLS_RSA') && <TabRSA />}
      </Typography>
    </Typography>
  );
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import { DevTool } from '@hookform/devtools';
import { get, isEmpty, keys, omit, split } from 'lodash';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import PhysicianDialog from './PhysicianDialog';
import { createOrder, editOrder } from './_api';
import useValidationSchema from './_form';
import { IOrder, IOrderComponent } from './_types';

import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormAutocomplete from 'components/Form/Autocomplete/Autocomplete';
import FormDatePicker from 'components/Form/Date/Date';
import { Papeer } from 'components/Papeer/Papeer';
import useAlerts from 'components/Alerts/useAlerts';
import Header from 'components/Header/Header';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import { Line } from 'components/Line/Line';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { Announcement } from 'components/Announcement/Announcement';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { settingHasValue } from 'utils/products';
import { useSearch } from 'utils/hooks/useSearch';
import { useUser } from 'utils/hooks/useUser';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useActions } from 'utils/hooks/useActions';
import { useMwl } from 'utils/hooks/useMwl';
import { isDateInFuture } from 'utils/hooks/useDate';
import { Box } from '@mui/system';
import useSizesValidation from 'modules/Studies/hooks/useSizesValidation';
import { TourOrders } from './TourOrders';

const apiDateFormat = 'yyyy-MM-dd';
const linkNewFind = '/orders';

const OrderFormComponent: React.FC<IOrderComponent> = ({
  isCreating,
  defaultValues,
  dictionaryLists,
  dictionarySelect,
  entity,
  linkBack2,
  compactMode,
  portalProductSettings,
  validators,
  loadPhysicians,
}) => {
  const { t } = useTranslation('Orders');
  const navigate = useNavigate();
  const { toggleLoader } = useAppGlobals();
  const { formatDateForSearch } = useSearch();
  const { setPhysician2 } = useMwl();
  const { hasRole, user } = useUser();
  const { storeOrders } = useActions();

  const useListOfPhysicians = !isEmpty(portalProductSettings)
    ? settingHasValue(portalProductSettings, 'useListOfPhysicians', 'true')
    : false;
  const seeRequestingPhysician = !isEmpty(portalProductSettings)
    ? settingHasValue(portalProductSettings, 'seeRequestingPhysician', 'true')
    : false;
  const seeReferringPhysician = !isEmpty(portalProductSettings)
    ? settingHasValue(portalProductSettings, 'seeReferringPhysician', 'true')
    : false;

  const { title } = useWithEntityTitle(isCreating, entity, t);

  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [previewRequestingPhysician, setPreviewRequestingPhysician] = useState(null);
  const [previewReferringPhysician, setPreviewReferringPhysician] = useState(null);
  const [modality, setModality] = useState(null);
  const [physicianDialogState, setPhysicianDialogState] = useState<boolean>(true);
  const [typePhysician, setTypePhysician] = useState(null);
  const [dateIsFuture, setDateIsFuture] = useState<boolean>(false);

  const { OrderFormSchema, fields } = useValidationSchema(
    t,
    isCreating,
    validators,
    dictionaryLists,
    dictionarySelect,
    useListOfPhysicians,
    seeRequestingPhysician,
    seeReferringPhysician,
    hasRole,
  );

  const methods = useForm<IOrder>({
    resolver: yupResolver(OrderFormSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    setValue,
    watch,
    register,
    // control,
  } = methods;
  const watchedRequestingPhysician2 = watch('requestingPhysician2', null);
  const watchedReferringPhysician2 = watch('referringPhysician2', null);
  const dateBirthWatch = watch('patient.dateBirth2');

  const {
    validateSizes,
    confirmationData,
    setConfirmationData,
    clearConfirmationData,
    confirmationTexts,
  } = useSizesValidation({
    maxNameLength: 44,
    prefixForDataAccession: '',
  });

  const changeValues = (value: any, fieldName: 'referringPhysician' | 'requestingPhysician') => {
    const physician = value ? split(value.value, '|', 5) : null;
    setValue(
      `${fieldName}_name_lastName`,
      physician && physician[0] !== 'null' ? physician[0] : null,
    );
    setValue(
      `${fieldName}_name_firstName`,
      physician && physician[1] !== 'null' ? physician[1] : null,
    );
    setValue(
      `${fieldName}_name_middleName`,
      physician && physician[2] !== 'null' ? physician[2] : null,
    );
    setValue(
      `${fieldName}_name_prefix`,
      physician && physician[3] !== 'null' ? physician[3] : null,
    );
    setValue(
      `${fieldName}_name_suffix`,
      physician && physician[4] !== 'null' ? physician[4] : null,
    );
  };

  if (previewRequestingPhysician !== watchedRequestingPhysician2) {
    const reqPhys = watchedRequestingPhysician2;
    setPreviewRequestingPhysician(reqPhys);
    changeValues(reqPhys, 'requestingPhysician');
  }
  if (previewReferringPhysician !== watchedReferringPhysician2) {
    const refPhys = watchedReferringPhysician2;
    setPreviewReferringPhysician(refPhys);
    changeValues(refPhys, 'referringPhysician');
  }

  const submitAction = async (order: any) => {
    toggleLoader();
    const response = isCreating ? await createOrder(order) : await editOrder(order);
    if (response) {
      addSuccessAlert(t('saved'));
      navigate(linkNewFind);
    } else {
      addErrorAlert(t('errorSaving'));
    }
    toggleLoader(false);
  };

  const confirmAction = async () => {
    submitAction(get(confirmationData, 'order'));
    clearConfirmationData();
  };

  const onSubmit = handleSubmit(async (values) => {
    const requiredHealthInsuranceCompany = get(
      validators,
      'healthInsuranceCompany.required',
      false,
    );
    const requiredRequestedProcedure = get(
      validators,
      'studyOrder.requestedProcedure.required',
      false,
    );
    const requiredExpertness = get(validators, 'studyOrder.expertness.required', false);
    if (get(values, 'patientDispositions.size') === 'null') {
      values = {
        ...values,
        patientDispositions: { ...omit(get(values, 'patientDispositions', null), ['size']) },
      };
    }
    if (get(values, 'patientDispositions.weight') === 'null') {
      values = {
        ...values,
        patientDispositions: { ...omit(get(values, 'patientDispositions', null), ['weight']) },
      };
    }

    values.healthInsuranceCompany =
      get(values, 'healthInsuranceCompany2', null) && get(values, 'healthInsuranceCompany2.value')
        ? { id: get(values, 'healthInsuranceCompany2.value') }
        : null;

    values.expertness =
      get(values, 'expertness2', null) && get(values, 'expertness2.value')
        ? { id: get(values, 'expertness2.value') }
        : null;

    values.requestedProcedure =
      get(values, 'requestedProcedure2', null) && get(values, 'requestedProcedure2.value')
        ? get(values, 'requestedProcedure2.value')
        : '';

    if (useListOfPhysicians) {
      if (get(values, 'referringPhysician2', null) && get(values, 'referringPhysician2.value')) {
        const physician = split(get(values, 'referringPhysician2.value'), '|', 5);
        values.referringPhysician_name_lastName = physician[0] === 'null' ? null : physician[0];
        values.referringPhysician_name_firstName = physician[1] === 'null' ? null : physician[1];
        values.referringPhysician_name_middleName = physician[2] === 'null' ? null : physician[2];
        values.referringPhysician_name_prefix = physician[3] === 'null' ? null : physician[3];
        values.referringPhysician_name_suffix = physician[4] === 'null' ? null : physician[4];
      }
      if (get(values, 'requestingPhysician2', null) && get(values, 'requestingPhysician2.value')) {
        const physician = split(get(values, 'requestingPhysician2.value'), '|', 5);
        values.requestingPhysician_name_lastName = physician[0] === 'null' ? null : physician[0];
        values.requestingPhysician_name_firstName = physician[1] === 'null' ? null : physician[1];
        values.requestingPhysician_name_middleName = physician[2] === 'null' ? null : physician[2];
        values.requestingPhysician_name_prefix = physician[3] === 'null' ? null : physician[3];
        values.requestingPhysician_name_suffix = physician[4] === 'null' ? null : physician[4];
      }
    }
    toggleLoader(true);
    values.createdBy = { username: get(user, 'sub', null), id: 0 };

    values.originalDatetimeRequested = `${formatDateForSearch(
      get(values, 'originalDateRequested', ''),
      apiDateFormat,
    )}T${values.originalTimeRequested}`;
    const patientBirth = formatDateForSearch(
      get(values, 'patient.dateBirth2', '') as unknown as string,
      apiDateFormat,
    );
    values.patient.dateBirth = patientBirth || '';
    storeOrders([]);

    const order = omit(values, [
      'originalDateRequested',
      'originalTimeRequested',
      'healthInsuranceCompany2',
      'expertness2',
      'requestedProcedure2',
      'referringPhysician2',
      'requestingPhysician2',
      'patient.dateBirth2',
    ]);

    const { areSizesValidated } = validateSizes(values);

    if (requiredHealthInsuranceCompany && isEmpty(get(values, 'healthInsuranceCompany2', null))) {
      addErrorAlert(t('healthInsuranceCompanyRequired'));
    } else if (requiredExpertness && isEmpty(get(values, 'expertness2', null))) {
      addErrorAlert(t('expertnessRequired'));
    } else if (requiredRequestedProcedure && isEmpty(get(values, 'requestedProcedure2', null))) {
      addErrorAlert(t('requestedProcedureRequired'));
    } else {
      if (confirmationTexts.length > 0) {
        setConfirmationData({
          texts: confirmationTexts,
          values,
        });
      } else {
        if (areSizesValidated) submitAction(order);
      }
    }
    toggleLoader(false);
  });

  const changeField = (selectPhysician: any, physicianName: any) => {
    const physician = selectPhysician.value ? split(selectPhysician.value, '|', 5) : null;
    setValue(physicianName, {
      name: {
        lastName: physician && physician[0] !== 'null' ? physician[0] : null,
        firstName: physician && physician[1] !== 'null' ? physician[1] : null,
        middleName: physician && physician[2] !== 'null' ? physician[2] : null,
        prefix: physician && physician[3] !== 'null' ? physician[3] : null,
        suffix: physician && physician[4] !== 'null' ? physician[4] : null,
      },
    });
  };

  if (modality && physicianDialogState) {
    setPhysicianDialogState(false);
    const isReferringPhysician = get(modality, 'referringPhysician', false);
    const isRequestingPhysician = get(modality, 'requestingPhysician', false);
    loadPhysicians(dictionaryLists, isReferringPhysician, isRequestingPhysician);
    const changeName = isReferringPhysician ? 'referringPhysician' : 'requestingPhysician';
    const physician2 = setPhysician2({ [changeName]: modality }, changeName);
    setValue(`${changeName}2`, physician2);
    changeField(physician2, changeName);
    setModality(null);
  }

  const renderedSteps = () => {
    return <TourOrders type={isCreating ? 'create' : 'edit'} />;
  };

  useEffect(() => {
    const dateBirthIsFuture = isDateInFuture(dateBirthWatch);
    if (dateBirthIsFuture) {
      setDateIsFuture(true);
    } else {
      setDateIsFuture(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateBirthWatch]);

  return (
    <>
      {/* <DevTool control={control} placement="top-right" /> */}
      <div id="scrollToForm" style={{ paddingBottom: 52 }}>
        <FormProvider {...methods} data-tour="oderCreate">
          <form onSubmit={onSubmit}>
            {!isCreating && (
              <>
                <input {...register('accessionNumber')} type="hidden" />
                <input {...register('patient.id')} type="hidden" />
                <input {...register('referringPhysician.id')} type="hidden" />
                <input {...register('requestingPhysician.id')} type="hidden" />
                <input {...register('id')} type="hidden" />
              </>
            )}
            <Header title={title} TourComponent={renderedSteps()} />
            {dateIsFuture && (
              <Papeer sx={{ mb: compactMode ? 1 : 2 }}>
                <Announcement label={t('Errors:dateBirthIsFuture')} type="warning" spaced={true} />
              </Papeer>
            )}
            <Papeer>
              <Box data-tour={isCreating ? 'ordersCreate' : 'ordersEdit'}>
                {keys(fields).map((key: any, index: any) => (
                  <div key={`${key}_${index}`}>
                    {((isCreating && index > 1) || (!isCreating && index > 0)) && (
                      <Line topMargin={2} bottomMargin={2} />
                    )}

                    {keys(get(fields, key, [])).map((row: any, i: any) => (
                      <Grid container={true} spacing={2} key={`${key}_${index}_${i}`}>
                        {get(get(fields, key, []), row).map((field: any) => {
                          const type = get(field, 'type');
                          return (
                            <Grid
                              item={true}
                              xs={12}
                              md={6}
                              lg={2}
                              {...field.grid}
                              key={field.name}
                            >
                              {type === 'select' && <FormSelect {...field} />}
                              {type === 'select2' && (
                                <FormAutocomplete {...field} placeholder={t('select')} />
                              )}
                              {type === 'datePicker' && (
                                <FormDatePicker
                                  {...field}
                                  isRequired={get(field, 'required', false)}
                                />
                              )}
                              {get(field, 'type') === 'timePicker' && (
                                <FormInput {...field} type="text" />
                              )}
                              {type === 'div' && (
                                <Box sx={{ pt: 1, mt: 1 }}>
                                  <Typography component="h6">{get(field, 'label')}</Typography>
                                </Box>
                              )}
                              {get(field, 'type') === 'iconButton' && (
                                <Tooltip title={field.tooltip}>
                                  <IconButton
                                    sx={{ pt: 1, mt: 1 }}
                                    style={compactMode ? { marginTop: -2 } : {}}
                                    color="primary"
                                    onClick={() => {
                                      setTypePhysician(field.name);
                                      setPhysicianDialogState(true);
                                    }}
                                    size="large"
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {type === 'number' &&
                                (get(field, 'tooltip', false) ? (
                                  <Tooltip title={field.tooltip}>
                                    <span style={{ paddingBottom: '1rem' }}>
                                      <FormInput {...field} type="number" />
                                    </span>
                                  </Tooltip>
                                ) : (
                                  <FormInput {...field} type="number" />
                                ))}
                              {!type && <FormInput {...field} type="text" />}
                            </Grid>
                          );
                        })}
                      </Grid>
                    ))}
                  </div>
                ))}
              </Box>
            </Papeer>
            <Papeer>
              <EntityButtons linkBack={linkBack2} />
            </Papeer>
          </form>
        </FormProvider>
        {confirmationData && confirmationData.values && (
          <ConfirmationDialog
            title={t('patientSize')}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={clearConfirmationData}
            confirmAction={confirmAction}
          >
            <Typography variant="body1">{confirmationData.texts}</Typography>
          </ConfirmationDialog>
        )}
        <PhysicianDialog
          physicianDialogState={physicianDialogState}
          setPhysicianDialogState={setPhysicianDialogState}
          typePhysician={typePhysician}
          setModality={setModality}
        />
      </div>
    </>
  );
};

export default OrderFormComponent;

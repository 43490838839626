import { Column } from '@devexpress/dx-react-grid';
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { get } from 'lodash';
import { Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { IUsePatientManagementDialogColumnsProps } from './_types';

export const patientManagementDialogColumns: Column[] = [
  {
    name: 'name',
    title: 'name',
    getCellValue: (row) => {
      const lastname = get(row, 'name.lastName', '');
      const firstname = get(row, 'name.firstName', '');
      return `${lastname || ''} ${firstname || ''}`;
    },
  },
  { name: 'identificationNumber', title: 'patientID' },
  { name: 'dateBirth', title: 'birthDate' },
  {
    name: 'sex',
    title: 'sex',
    getCellValue: (row) => (get(row, 'sex', 'O') === null ? 'O' : get(row, 'sex', 'O')),
  },
];

export const usePatientManagementDialogColumns = ({
  handleCloseDialog,
  setValue,
  enableSortingForAllFields = true,
}: IUsePatientManagementDialogColumnsProps): GridColDef[] => {
  const { t } = useTranslation('Grid');
  return [
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      renderCell: (params) => (
        <GridActionsCellItem
          icon={
            <Tooltip title={t('select')}>
              <CheckIcon />
            </Tooltip>
          }
          label={t('select')}
          onClick={() => {
            const values = {
              patient: {
                ...params.row,
                dateBirth: params.row.dateBirth ? new Date(params.row.dateBirth) : null,
              },
            };
            handleCloseDialog();
            if (setValue) {
              setValue('patient', values.patient);
            }
          }}
        />
      ),
      filterable: false,
      sortable: enableSortingForAllFields,
    },
    {
      field: 'name',
      headerName: 'name',
      valueGetter: (value, row) => {
        const lastname = get(row, 'name.lastName', '');
        const firstname = get(row, 'name.firstName', '');
        return `${lastname || ''} ${firstname || ''}`;
      },
      flex: 1,
      sortable: enableSortingForAllFields,
    },
    {
      field: 'identificationNumber',
      headerName: 'patientID',
      flex: 1,
      sortable: enableSortingForAllFields,
    },
    {
      field: 'dateBirth',
      headerName: 'birthDate',
      flex: 1,
      valueGetter: (value: any) => value && new Date(value),
      valueFormatter: (value: any) => value && format(value, 'dd. MM. yyyy'),

      sortable: enableSortingForAllFields,
    },
    {
      field: 'sex',
      headerName: 'sex',
      valueGetter: (value, row) => (get(row, 'sex', 'O') === null ? 'O' : get(row, 'sex', 'O')),
      flex: 1,
      valueFormatter: (value) => {
        if (value === 'M') {
          return t('male');
        }
        if (value === 'F') {
          return t('female');
        }
        if (value === 'O') {
          return t('different');
        }
      },
      sortable: enableSortingForAllFields,
    },
  ];
};

// Only filter is being displayed
export const CustomGridToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
};

// Only sorting and filtering is being displayed
export function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
}

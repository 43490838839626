import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = (isCreating: boolean) => {
  const { min, required } = useValidators();

  const FilterFormSchema = yup.object().shape({
    name: required(yup.string()),
    ...(isCreating
      ? {
          workPlaces: min(
            yup.array().transform((ids) => {
              return ids.filter((id: any) => {
                return id === 0 || id;
              });
            }),
            1,
            'selectMin1Workplace',
          ),
        }
      : {}),
  });

  return { FilterFormSchema };
};

export default useValidationSchema;

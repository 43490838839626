import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { each, get, isArray, isString, uniqBy } from 'lodash';
import { IRestrictedPage } from './_types';
import { IRole } from 'modules/Login/_types';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';

export const RestrictedPage: React.FC<IRestrictedPage> = ({ allowedRoles }) => {
  const { t } = useTranslation('Homepage');
  const { lists } = useAppInfo();
  const roles: IRole[] = get(lists, 'roles');
  const [missingRoles, setMissingRoles] = useState<IRole[]>([]);
  const { currentLocale } = useLanguage();
  const { toggleLoader } = useAppGlobals();

  useEffect(() => {
    if (isArray(roles) && roles.length) {
      toggleLoader();
      try {
        const missingRoles: IRole[] = [];
        if (isString(allowedRoles)) {
          const filteredRole = roles.filter((role) => role.code === allowedRoles)[0];
          missingRoles.push(filteredRole);
        } else if (isArray(allowedRoles)) {
          each(allowedRoles, (role) => {
            missingRoles.push(roles.filter((r) => r.code === role)[0]);
          });
        }
        setMissingRoles(missingRoles);
        toggleLoader(false);
      } catch (e) {
        toggleLoader(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  return (
    <>
      <Header title={t('restrictedAreaTitle')} />
      <Papeer>
        <div>{t('restrictedArea')}</div>
        <p>{t('missingRoles')}:</p>
        <ul>
          {isArray(missingRoles)
            ? uniqBy(missingRoles, (role) => role.code).map((role) => {
                return (
                  <li key={role.code}>{get(JSON.parse(role.text as string), currentLocale)}</li>
                );
              })
            : null}
        </ul>
        <p>{t('contactAdmin')}</p>
      </Papeer>
    </>
  );
};

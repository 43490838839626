import { isArray } from 'lodash';

import { getAllNotificationsByStateWithLimit } from 'modules/websocketNotification/_api';
import { SET_WEBSOCKET_NOTIFICATIONS } from 'constants/constants';

export const ASetWebsocketNotifications = (websocketNotifications: number) => ({
  payload: websocketNotifications,
  type: SET_WEBSOCKET_NOTIFICATIONS,
});

export const AloadNotifications = (state: string) => async (dispatch: any) => {
  const notifications = await getAllNotificationsByStateWithLimit(state);

  if (isArray(notifications)) {
    dispatch({
      payload: notifications,
      type: SET_WEBSOCKET_NOTIFICATIONS,
    });
  }
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { get, isObject } from 'lodash';
import { Typography } from '@mui/material';
import { warningColor } from 'utils/variables';
import { useAppInfo } from 'utils/hooks/useAppInfo';

export const WarningMessage: React.FC<any> = ({ textError }) => {
  const { t } = useTranslation('Errors');
  const { compactMode } = useAppInfo();

  const warningText = isObject(textError)
    ? t(get(textError, 'code', 'allowedChars'), {
        par1: get(textError, 'par1', '?'),
      })
    : t(textError);

  return (
    <Typography variant="caption" sx={{ mt: compactMode ? 0 : 1, color: warningColor }}>
      {warningText}
    </Typography>
  );
};

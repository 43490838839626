import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import {
  CustomColumnMenu,
  CustomGridToolbar,
  usePatientManagementDialogColumns,
} from './_patientManagementGrid';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, isEmpty, isNull, omitBy, upperFirst } from 'lodash';
import {
  IPatientManagementDialog,
  IPatientManagementDialogFieldDefinition,
  IPatientManagementSearchForm,
} from './_types';
import useValidationSchema from './_formSearch';
import { getPatientsFromPatientManagement } from '../_api';
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Announcement } from 'components/Announcement/Announcement';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import { usePatientManagementDialogFields } from './usePatientMagementDialogFields';
import { CloseButtonDialog } from 'components/Dialog/CloseDialogButton';
import { DataGridPro, GridRowModel, GridSortModel } from '@mui/x-data-grid-pro';
import { useTranslateColumnsDataGridPro } from 'utils/hooks/useTranslateColumnsDataGridPro';
import { uid } from 'uid';
import { IPatient } from 'modules/Search/_types';
import { DATA_GRID_DEFAULT_SORTING, DATA_GRID_ROW_HEIGHT } from 'constants/constants';
import useGridLocalization from 'components/SearchResultsMUI/useGridLocalization';

const checkFields: string[] = ['lastName', 'firstName', 'id'];

const PatientManagementDialog: React.FC<IPatientManagementDialog> = ({
  isOpen,
  setIsOpen,
  setValueStudyAndPatientForm,
}) => {
  const [rows, setRows] = useState<IPatient[]>([]);
  const initialSortModel: GridSortModel = [
    {
      field: 'name',
      sort: 'asc',
    },
  ];
  const [sortModel, setSortModel] = useState<GridSortModel>(initialSortModel);
  const [validatorFields, setValidatorFields] = useState<any[]>([]);

  const { t } = useTranslation('Studies');
  const { toggleLoader } = useAppGlobals();

  const { validators } = useAppInfo();

  const handleCloseDialog = () => {
    reset();
    setIsOpen(false);
    setRows([]);
  };

  const initCols = usePatientManagementDialogColumns({
    setValue: setValueStudyAndPatientForm,
    handleCloseDialog,
    enableSortingForAllFields: true,
  });

  const gridLocalization = useGridLocalization();

  const { cols } = useTranslateColumnsDataGridPro(initCols, t);

  //TODO: With real data, delete uuidv1 and use only row.iid from data
  const getRowId = useCallback((row: GridRowModel) => (row.iid ? row.iid : uid()), []);

  const importPatientManagementValidators = get(
    validators,
    'importPatientManagementSearchForm',
    null,
  );

  const { PatientManagementFormSchema } = useValidationSchema(t, validatorFields);

  const methods = useForm<IPatientManagementSearchForm>({
    resolver: yupResolver(PatientManagementFormSchema),
    defaultValues: { lastName: '', firstName: '', id: '' },
    mode: 'onChange',
  });

  const { handleSubmit, control, reset, clearErrors, setError, watch } = methods;
  const watchLastName = watch('lastName');
  const watchFirstName = watch('firstName');
  const watchId = watch('id');

  const { fields, disableSearch: disableSearchAndReset } = usePatientManagementDialogFields(
    { lastName: watchLastName, firstName: watchFirstName, id: watchId },
    t,
    importPatientManagementValidators,
  );

  const getMin = (validators: any, itemName: string) => {
    return get(validators, `${itemName}.min`, 1);
  };

  const getAllowedChars = (validators: any, itemName: string) => {
    return get(validators, `${itemName}.allowedChars`, '.');
  };

  const getRequired = (validators: any, itemName: string) => {
    return get(validators, `${itemName}.required`, false);
  };

  const getRequiredWithOrNotRequireWith = (
    typeRequiredWithOrNotRequiredWith: string,
    validators: any,
    itemName: string,
  ) => {
    const requiredWithOrNotRequiredWith = get(
      validators,
      itemName + typeRequiredWithOrNotRequiredWith,
    );
    const updatedRequireWithOrNotRequireWith: any[] = [];
    if (!isEmpty(requiredWithOrNotRequiredWith)) {
      requiredWithOrNotRequiredWith.forEach((item: any) => {
        updatedRequireWithOrNotRequireWith.push(item === 'identification' ? 'id' : item);
      });
    }
    return updatedRequireWithOrNotRequireWith;
  };

  useEffect(() => {
    const validatorFields: any[] = [];
    checkFields.forEach((checkField: any) => {
      let required = false;
      let min = 2;
      let allowedChars = '.';
      if (importPatientManagementValidators) {
        const customCheckField =
          checkField === 'id' ? 'patient.identification' : `patient.name.${checkField}`;
        const isRequired = getRequired(importPatientManagementValidators, customCheckField);
        const requiredWith = getRequiredWithOrNotRequireWith(
          '.requiredWith',
          importPatientManagementValidators,
          customCheckField,
        );
        const notRequiredWith = getRequiredWithOrNotRequireWith(
          '.notRequiredWith',
          importPatientManagementValidators,
          customCheckField,
        );
        if (isRequired && isEmpty(requiredWith) && isEmpty(notRequiredWith)) {
          required = true;
        }
        min = getMin(importPatientManagementValidators, customCheckField);
        allowedChars = getAllowedChars(importPatientManagementValidators, customCheckField);
      }
      validatorFields.push({
        name: checkField,
        required,
        min,
        allowedChars,
      });
    });
    setValidatorFields(validatorFields);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importPatientManagementValidators]);

  const validateFields = (values: any) => {
    let shoudEndSubmit = false;

    if (!get(values, 'lastName') && !get(values, 'firstName') && !get(values, 'id')) {
      // Clear previous errors and begin with clean sheet
      clearErrors(['lastName', 'firstName', 'id']);

      setError('id', { type: 'custom', message: t('Errors:required') });
      setError('lastName', { type: 'custom', message: t('Errors:required') });
      setError('firstName', { type: 'custom', message: t('Errors:required') });
      shoudEndSubmit = true;
    } else {
      checkFields.forEach((checkField: any) => {
        if (!get(values, checkField)) {
          // Clear previous errors and begin with clean sheet
          clearErrors(checkField);

          const customCheckField =
            checkField === 'id' ? 'patient.identification' : `patient.name.${checkField}`;
          const isRequired = getRequired(importPatientManagementValidators, customCheckField);
          const requiredWith = getRequiredWithOrNotRequireWith(
            '.requiredWith',
            importPatientManagementValidators,
            customCheckField,
          );
          const notRequiredWith = getRequiredWithOrNotRequireWith(
            '.notRequiredWith',
            importPatientManagementValidators,
            customCheckField,
          );
          if (isRequired && isEmpty(requiredWith) && isEmpty(notRequiredWith)) {
            setError(checkField, { type: 'custom', message: t('Errors:required') });
            shoudEndSubmit = true;
          } else {
            let isNotRequiredWith = true;
            notRequiredWith.forEach((item) => {
              if (get(values, item, null)) {
                isNotRequiredWith = false;
              }
            });
            if (isNotRequiredWith) {
              requiredWith.forEach((item) => {
                if (get(values, item, null)) {
                  setError(checkField, {
                    type: 'custom',
                    message: t(`Errors:requiredWith${upperFirst(item)}`),
                  });
                  shoudEndSubmit = true;
                }
              });
            }
          }
        }
      });
    }
    return shoudEndSubmit;
  };

  useEffect(() => {
    validateFields({ lastName: watchLastName, firstName: watchFirstName, id: watchId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchLastName, watchFirstName, watchId]);

  const submitHandler = async (values: IPatientManagementSearchForm) => {
    const shoudEndSubmit = importPatientManagementValidators ? validateFields(values) : false;
    if (shoudEndSubmit) {
      return;
    }
    toggleLoader();
    setRows([]);
    const transformedValues: IPatientManagementSearchForm = omitBy(values, isEmpty);
    const resp = await getPatientsFromPatientManagement(transformedValues);
    if (Array.isArray(resp)) {
      const rows = resp.map((item: IPatient) => ({
        ...item,
      }));
      setRows(rows);
    } else {
      setRows([]);
    }
    toggleLoader(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleCloseDialog();
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">{t('findPatientManagement')}</DialogTitle>
      <CloseButtonDialog onClose={handleCloseDialog} />
      <DialogContent>
        {isNull(importPatientManagementValidators) && (
          <Grid container={true} spacing={16}>
            <Grid item={true} xs={12} key={'announcement'}>
              <Announcement
                label={t('patientManagementAnnouncement')}
                type={'info'}
                spaced={true}
              />
            </Grid>
          </Grid>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Papeer bottomMargin={true}>
              <Grid container={true} spacing={2}>
                {fields.map((field: IPatientManagementDialogFieldDefinition) => (
                  <Grid item={true} xs={12} md={4} lg={3} key={field.name}>
                    <FormInput {...field} type="text" control={control} />
                  </Grid>
                ))}
              </Grid>
              <Grid container={true} display="flex" alignItems="flex-end">
                {/* spacing={16} */}
                <Grid item={true} xs={12} display="flex" justifyContent="flex-end" gap={1}>
                  <Button
                    variant="contained"
                    color="inherit"
                    disabled={!disableSearchAndReset}
                    onClick={() => reset()}
                  >
                    {t('reset')}
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={disableSearchAndReset}
                  >
                    {t('find')}
                  </Button>
                </Grid>
              </Grid>
            </Papeer>
          </form>
        </FormProvider>

        {rows && rows.length > 0 && (
          <Papeer>
            <>
              <DataGridPro
                sortingOrder={DATA_GRID_DEFAULT_SORTING}
                headerFilters={true}
                getRowId={getRowId}
                rowHeight={DATA_GRID_ROW_HEIGHT}
                autoHeight={isEmpty(rows)}
                rows={rows}
                columns={cols}
                disableRowSelectionOnClick={true}
                localeText={gridLocalization}
                hideFooter={true}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                slots={{ toolbar: CustomGridToolbar, columnMenu: CustomColumnMenu }}
                slotProps={{
                  headerFilterCell: {
                    InputComponentProps: {
                      size: 'small',
                    },
                  },
                }}
              />
            </>
          </Papeer>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PatientManagementDialog;

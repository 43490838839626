import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = () => {
  const { required } = useValidators();

  const RegisterGroupDetailSchema = yup.object().shape({
    name: required(yup.string()),
  });

  const RegisterGroupSchema = yup.object().shape({
    name: required(yup.string()),
  });

  const RegisterItemSchema = yup.object().shape({
    name: required(yup.string()),
  });

  return { RegisterGroupDetailSchema, RegisterGroupSchema, RegisterItemSchema };
};

export default useValidationSchema;

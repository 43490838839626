import React, { useCallback, useEffect, useState } from 'react';
import { compact, difference, find, get, isArray, sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
} from '@mui/material';
import { Close as CloseIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro';

import {
  addDocuments,
  addSeries,
  addStudy,
  getAllByUserAndStudy,
  removeSeries,
  removeStudy,
} from './_api';
import { ISerie } from 'modules/Studies/StudyDetail/_types';
import { getPreviewWithSize } from 'modules/Studies/StudyDetail/_api';
import DialogFoldersForm from './DialogFoldersForm';
import RequestAdditionalForm from 'modules/Studies/Form/RequestAdditionalForm';
import useAlerts from 'components/Alerts/useAlerts';
import { BatchButton } from 'components/Form/BatchButton/BatchButton';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { IDecubitDetail, IPreview, ISerieDetail } from 'components/SearchResultsMUI/_types';
import { GridSeriesMUI } from 'components/SearchResultsMUI/GridSeriesMUI';
import { useUser } from 'utils/hooks/useUser';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { generateIID, joinParams } from 'utils/study';
import { getPreview } from 'utils/hooks/usePreview';
import { useActions } from 'utils/hooks/useActions';
import { stateIsSame } from 'utils/componentOptimizatons';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { encryptId } from 'utils/hooks/useApp';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { LIST, PREVIEW_URL_SIZE, REMOVE_STUDY_FROM_FOLDER } from 'constants/constants';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const buttonStyle = { marginRight: '8px', marginBottom: '8px', marginTop: '8px' };
const GRID_SETTINGS_KEY: string = 'dialogFoldersMUI';

const PlainDialogFolders: React.FC<any> = ({
  showFoldersByUserAndStudy,
  selectedStudies,
  action,
  selectedPatientDocuments,
  study,
  series,
  folderDialogState,
  setFolderDialogState,
  selectedSeries,
  setSelectedSeries,
}) => {
  const { t } = useTranslation('Folders');
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { user } = useUser();
  const { confirmationData } = useAppInfo();
  const { storeSearchResults, resetStudies } = useActions();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } =
    useMuiGrid(GRID_SETTINGS_KEY);

  const [selection, setSelection] = useState<any[]>([]);
  const [foldersByUserAndStudy, setFoldersByUserAndStudy] = useState<any[]>([]);
  const [stateSeries, setStateSeries] = useState<ISerieDetail[]>([]);
  const [previews, setPreviews] = useState<IPreview[]>([]);
  const [decubits, setDecubits] = useState<IDecubitDetail[]>([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);

  const methods = useForm();
  const { watch } = methods;
  const requestComment = watch('requestComment');

  const loadFoldersByUserAndStudy = async () => {
    const username = get(user, 'sub', '???');
    const patientId = get(study, 'patient.identificationNumber', null);
    const archiveId = get(study, 'archive.id', null);
    const studyUID = get(study, 'studyInstanceUid', null);
    toggleLoader();

    let foldersWithSeriesAndPreview: any[] = [];
    const folders = await getAllByUserAndStudy(archiveId, studyUID);
    if (folders && isArray(folders)) {
      foldersWithSeriesAndPreview = folders.map((item) => {
        const owner = get(item, 'user.username', '!!!') === username;
        const permission = owner ? '' : (get(item, 'users[0].permission') as unknown as any);
        const canUpdate = owner || permission === 'WRITE_PLUS_UPDATE';
        const canDelete = owner;
        const canRemoveStudyFromFolder =
          owner || permission === 'READ_PLUS_WRITE' || permission === 'WRITE_PLUS_UPDATE';

        const backToByAction = generateIID(study);

        const seriesWithPreviewUrl = sortBy(
          get(item, 'series', []).map((serie: any) => {
            const previewUrl = getPreview(archiveId, get(study, 'studyInstanceUid', ''), serie);
            return {
              ...serie,
              previewUrl,
              numberSerie: isNaN(get(serie, 'seriesNumber')) ? 0 : get(serie, 'seriesNumber') * 1,
              study: { archiveId, patientId, uid: studyUID },
            };
          }),
          ['modality', 'numberSerie'],
        );
        return {
          ...item,
          series: seriesWithPreviewUrl,
          canUpdate,
          canDelete,
          canRemoveStudyFromFolder,
          backToByAction,
          archiveId,
          patientId,
        };
      });
    }
    setFoldersByUserAndStudy(foldersWithSeriesAndPreview);

    toggleLoader(false);
  };

  useEffect(() => {
    if (!folderDialogState) {
      setSelection([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foldersByUserAndStudy]);

  const onRemoveStudyFromFolder = async (folderId: any) => {
    toggleLoader();
    const removed = await removeStudy(
      folderId,
      get(study, 'archive.id', null),
      get(study, 'studyInstanceUid', null),
    );
    if (removed) {
      setFolderDialogState(false);
      await loadFoldersByUserAndStudy();
      addSuccessAlert(t('removedStudyFromFolder'));
    } else {
      addErrorAlert(t('errorRemovingStudyFromFolder'));
    }
    toggleLoader(false);
  };
  const onRemoveSerieFromFolder = async (folderId: any, uid: any) => {
    toggleLoader();
    const selection = isArray(uid) ? uid : [uid];
    const listOfSeries = selection.map((item) => find(series, { uid: item }));
    const values = {
      folderId: [folderId],
      studies: [
        {
          archive: get(study, 'archive', null),
          listOfSeries,
          studyInstanceUid: get(study, 'studyInstanceUid', null),
        },
      ],
    };
    const removed = await removeSeries(values);
    if (removed) {
      await loadFoldersByUserAndStudy();
      addSuccessAlert(t('removedSeriesFromFolder'));
    } else {
      addErrorAlert(t('errorRemovingSeriesFromFolder'));
    }
    toggleLoader(false);
  };

  const callAddStudyToFolder = async (id: any, studies: any) => {
    toggleLoader();
    const txtSuccess = selectedSeries ? 'seriesSelectedToFolder' : 'studySelectedToFolder';
    const txtError = selectedSeries
      ? 'errorSelectingSeriesToFolder'
      : 'errorSelectingStudyToFolder';

    const prepareStudies = studies.map((study: any) => ({
      ...study,
      listOfSeries:
        get(study, 'listOfSeries', false) === false ? null : get(study, 'listOfSeries', null),
    }));
    const fn =
      selectedSeries && find(foldersByUserAndStudy, { id }) !== undefined ? addSeries : addStudy;
    const selected = await fn({ folderId: id, studies: prepareStudies, requestComment });
    if (selected) {
      setFolderDialogState(false);
      if (showFoldersByUserAndStudy) {
        await loadFoldersByUserAndStudy();
      } else {
        resetStudies(LIST);
        storeSearchResults([]);
      }
      addSuccessAlert(t(txtSuccess));
    } else {
      addErrorAlert(t(txtError));
    }
    toggleLoader(false);
  };
  const callAddDocumentsToFolder = async (id: any, documents: any) => {
    toggleLoader();
    const selected = await addDocuments({ documents, folderId: id, requestComment });
    setFolderDialogState(false);
    if (selected) {
      addSuccessAlert(t('documentsSelectedToFolders'));
    } else {
      addErrorAlert(t('errorSelectingDocumentsToFolders'));
    }
    toggleLoader(false);
  };

  const onSelectEntity = async (id: any) => {
    if (action === 'documents') {
      callAddDocumentsToFolder(selection, selectedPatientDocuments);
    } else {
      const studies = selectedStudies.map((study: any) => ({
        archive: get(study, 'archive', null),
        listOfSeries: selectedSeries,
        studyInstanceUid: get(study, 'studyInstanceUid', null),
      }));
      setSelectedSeries(null);
      if (id) {
        callAddStudyToFolder([id], studies);
      } else {
        if (selection.length === 0) {
          addErrorAlert(t('notSelectedFolder'));
        } else {
          callAddStudyToFolder(selection, studies);
        }
      }
    }
  };

  useEffect(() => {
    if (showFoldersByUserAndStudy) {
      loadFoldersByUserAndStudy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };
  const confirmAction = async () => {
    await onRemoveStudyFromFolder(get(confirmationData, 'id'));
    clearAction();
  };

  const confirmActionRemoveSerieFromFolder = async () => {
    await onRemoveSerieFromFolder(get(confirmationData, 'id'), get(confirmationData, 'uid'));
    clearAction();
  };

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      compact([
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          renderCell: ({ row }: GridRenderCellParams) => (
            <>
              {get(row, 'canRemoveStudyFromFolder', false) && (
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t('Grid:removeStudyFromFolder')}>
                      <DeleteIcon />
                    </Tooltip>
                  }
                  label={t('Grid:removeStudyFromFolder')}
                  onClick={() => {
                    dispatch(
                      storeConfirmationDataToStore({
                        id: get(row, 'id'),
                        title: 'confirmRemoveStudyFromFolder',
                        confirmAction: REMOVE_STUDY_FROM_FOLDER,
                      }),
                    );
                  }}
                  disabled={!get(row, 'canDelete', true)}
                />
              )}
            </>
          ),
        },
        {
          field: 'name',
          headerName: t('name'),
          hideable: false,
          renderCell: ({ row }: GridRenderCellParams) => (
            <>
              <Tooltip title={t('showDetail')}>
                <Box
                  sx={{
                    cursor: 'move',
                    background: 'none',
                    color: (theme) =>
                      theme.palette.mode === 'dark'
                        ? theme.palette.primary.contrastText
                        : theme.palette.primary.main,
                  }}
                >
                  <Link
                    style={{ cursor: 'pointer', fontWeight: 'bold', color: 'inherit' }}
                    to={`${
                      action === 'view' || action === 'studyDetail' ? '' : '/administration'
                    }/folders/${encryptId(get(row, 'id'))}/detail?from=${action}`}
                  >
                    {get(row, 'name', '?')}
                  </Link>
                </Box>
              </Tooltip>
            </>
          ),
          valueGetter: (value: any, row: any) => get(row, 'name'),
        },
        {
          field: 'owner',
          headerName: t('owner'),
          width: 200,
          valueGetter: (value: any, row: any) =>
            `${joinParams([get(row, 'user.lastName'), get(row, 'user.firstName')])}`,
        },
      ]),
    ),
  );

  const onCustomEntityRemove = useCallback(
    (row: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {
      dispatch(
        storeConfirmationDataToStore({
          title: 'Grid:confirmRemoveSerieFromFolder',
          id: get(row, 'folderId'),
          uid: get(row, 'uid'),
          confirmAction: 'confirmActionRemoveSerieFromFolder',
        }),
      );
    },
    [dispatch],
  );

  const setNewPreviews = useCallback(
    (newPreview: IPreview) => {
      setPreviews((prevPreviews: IPreview[]) => [...prevPreviews, newPreview]);
    },
    [setPreviews],
  );

  const setNewDecubits = useCallback(
    (newDecubit: IDecubitDetail) => {
      setDecubits((prevDecubits: IDecubitDetail[]) => [...prevDecubits, newDecubit]);
    },
    [setDecubits],
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const DetailPanel = useCallback(
    (row: GridRowParams) => {
      const seriesToShow = find(stateSeries, { iid: row.id }) as ISerieDetail;
      const seriesRows = (get(seriesToShow, 'items') || []).map((serie: ISerie) => ({
        ...serie,
        study: {
          ...seriesToShow?.study,
          archiveId: get(row, 'row.archiveId'),
          patientId: get(row, 'row.patientId'),
        },
        folderId: get(row, 'id'),
      }));
      const canRemoveSerieFromFolder = get(row, 'row.canRemoveStudyFromFolder', false);
      return (
        <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
          <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
            <Stack direction="column" spacing={1} sx={{ height: 1 }}>
              <GridSeriesMUI
                rows={seriesRows}
                previews={previews}
                setNewPreviews={setNewPreviews}
                decubits={decubits}
                setNewDecubits={setNewDecubits}
                customActions={(row) =>
                  compact([
                    canRemoveSerieFromFolder ? (
                      <GridActionsCellItem
                        icon={
                          <Tooltip title={t('Grid:removeSerieFromFolder')}>
                            <DeleteIcon />
                          </Tooltip>
                        }
                        label={t('Grid:removeSerieFromFolder')}
                        onClick={() => {
                          onCustomEntityRemove(row);
                        }}
                      />
                    ) : null,
                  ])
                }
              />
            </Stack>
          </Paper>
        </Stack>
      );
    },
    [stateSeries, previews, decubits, setNewPreviews, setNewDecubits, onCustomEntityRemove, t],
  );

  // handle funkce na rozbalení sérií u studie
  const handleDetailPanelExpandedRowIdsChange = useCallback(
    async (newIds: GridRowId[]) => {
      const archiveID = get(study, 'archive.id', null);
      const studyID = get(study, 'studyInstanceUid', null);
      if (newIds.length > detailPanelExpandedRowIds.length) {
        const searchForStudySeries = get(difference(newIds, detailPanelExpandedRowIds), '[0]', '');
        const isSerieLoaded = find(stateSeries, { iid: searchForStudySeries });
        if (!isSerieLoaded) {
          toggleLoader(true);
          let seriesLoad: any = null;
          const row = find(foldersByUserAndStudy, { id: searchForStudySeries });
          if (row) {
            seriesLoad = get(row, 'series', []) === null ? [] : get(row, 'series', []);
          }
          if (seriesLoad) {
            setStateSeries((prevSeries: ISerieDetail[]) => [
              ...prevSeries,
              {
                iid: searchForStudySeries,
                items: seriesLoad,
                study: { uid: studyID },
              },
            ]);
            seriesLoad.forEach(async (serie: ISerie) => {
              const img = await getPreviewWithSize(
                archiveID,
                studyID,
                get(serie, 'uid'),
                undefined,
                PREVIEW_URL_SIZE,
              );
              setNewPreviews({ iid: studyID, uid: get(serie, 'uid'), img });
            });
          }
          toggleLoader(false);
        }
      }
      setDetailPanelExpandedRowIds(newIds);
    },
    [
      detailPanelExpandedRowIds,
      stateSeries,
      toggleLoader,
      setNewPreviews,
      foldersByUserAndStudy,
      study,
    ],
  );

  return (
    <>
      {showFoldersByUserAndStudy && foldersByUserAndStudy ? (
        <>
          <MuiGrid
            gridKey={GRID_SETTINGS_KEY}
            rows={foldersByUserAndStudy}
            columns={columns}
            rowDetail={{
              showRowDetail: true,
              DetailPanel,
              getDetailPanelHeight,
              detailPanelExpandedRowIds,
              handleDetailPanelExpandedRowIdsChange,
            }}
            initialSortMode={[{ field: 'name', sort: 'asc' }]}
          />
        </>
      ) : (
        <></>
      )}
      <Dialog
        open={folderDialogState && (foldersByUserAndStudy !== undefined || action === 'documents')}
        onClose={() => {
          setFolderDialogState(false);
        }}
        aria-labelledby="folder-dialog-title"
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
      >
        <DialogTitle id="folder-dialog-title">
          <Grid container={true} direction="row" justifyContent="space-between" alignItems="center">
            {t('selectFolder')}
            <IconButton
              aria-label="close"
              onClick={() => {
                setFolderDialogState(false);
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogFoldersForm
            setFolderDialogState={setFolderDialogState}
            user={user}
            showFoldersByUserAndStudy={showFoldersByUserAndStudy}
            loadFoldersByUserAndStudy={loadFoldersByUserAndStudy}
            selectedStudies={selectedStudies}
            selectedSeries={selectedSeries}
            foldersByUserAndStudy={foldersByUserAndStudy}
            showGridWithFolders={true}
            refreshGridAfterAddNew={true}
            action={action}
            selection={selection}
            setSelection={setSelection}
            selectedPatientDocuments={selectedPatientDocuments}
          />
          <Grid container={true} spacing={2} style={{ margin: '8px -8px' }}>
            <Grid item={true} xs={12} lg={true}>
              <FormProvider {...methods}>
                <form>
                  <RequestAdditionalForm isRequest={false} />
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {action === 'studyDetail' && (
            <BatchButton
              onClick={() => onSelectEntity(null)}
              label={t('multipleAddToFolder')}
              numberOfSelectedStudies={selection.length}
              style={buttonStyle}
            />
          )}
          {action === 'documents' && (
            <BatchButton
              onClick={() => onSelectEntity(null)}
              label={t('multipleAddToFolder')}
              numberOfSelectedStudies={selection.length}
              style={buttonStyle}
            />
          )}
        </DialogActions>
      </Dialog>

      {confirmationData &&
        confirmationData.id &&
        (confirmationData.confirmAction === REMOVE_STUDY_FROM_FOLDER ||
          confirmationData.confirmAction === 'confirmActionRemoveSerieFromFolder') && (
          <ConfirmationDialog
            title={t(confirmationData.title)}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={clearAction}
            confirmAction={
              confirmationData.confirmAction === 'confirmActionRemoveSerieFromFolder'
                ? confirmActionRemoveSerieFromFolder
                : confirmAction
            }
          />
        )}
    </>
  );
};

export const DialogFolders = React.memo(PlainDialogFolders, stateIsSame);

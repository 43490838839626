import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { get } from 'lodash';
import { getRequest } from '../_api';
import StudiesEditDetail from 'modules/Studies/StudiesEdit/StudiesEditDetail';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { IRequestsEditStudyDetailEdit } from './_types';
import { IStudy } from 'modules/Studies/StudyDetail/_types';
import { IExistingRequest } from '../_types';
import { EDIT_TYPE_STUDY_SLUG } from 'constants/constants';

const RequestsEditStudyDetailEdit: React.FC<IRequestsEditStudyDetailEdit> = () => {
  const { id } = useEntityInfo();
  const navigate = useNavigate();

  const [request, fetchRequest] = useState<IExistingRequest>();
  const [studies, fetchStudies] = useState<IStudy[]>();

  const loadEntity = async () => {
    if (id) {
      const request = await getRequest(parseInt(id.toString(), 10));
      if (request) {
        fetchRequest(request);
        fetchStudies(get(request, 'data.studies'));
      } else {
        navigate('/requests/edit');
      }
    }
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {request ? (
        <StudiesEditDetail
          isRequest={true}
          isRequestEdit={true}
          request={request}
          operationType={EDIT_TYPE_STUDY_SLUG}
          studiesFromRequest={studies}
        />
      ) : null}
    </>
  );
};

export default RequestsEditStudyDetailEdit;

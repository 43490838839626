import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { ICloudPacdsRecipient, IDecubit, IPhysician } from './_types';

export const getDictionary = (id: any): any =>
  api()
    .get(
      `/portal-api/${
        id.dictionary === 'dose'
          ? 'studyDose/dictionary'
          : id.dictionary === 'studyDetailBookmarks'
          ? 'studyDetailBookmarks'
          : id.dictionary === 'decubits'
          ? 'decubits/dictionary'
          : id.dictionary
      }/get`,
      {
        params: { id: id.id },
      },
    )
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editDictionary = ({ dictionary, ...entity }: any): Promise<boolean> =>
  api()
    .post(
      `/portal-api/${
        dictionary === 'dose'
          ? 'studyDose/dictionary/update'
          : dictionary === 'decubits'
          ? 'decubits/dictionary/update'
          : dictionary + '/edit'
      }`,
      {
        ...entity,
      },
    )
    .then((response) => true)
    .catch((error) => false);

export const createDictionary = ({ dictionary, ...entity }: any): Promise<boolean> =>
  api()
    .post(
      `/portal-api/${
        dictionary === 'dose'
          ? 'studyDose/dictionary'
          : dictionary === 'decubits'
          ? 'decubits/dictionary'
          : dictionary
      }/create`,
      entity,
    )
    .then((response) => true)
    .catch((error) => false);

export const removeDictionary = (id: any): Promise<boolean> =>
  api()
    .get(
      `/portal-api/${
        id.dictionary === 'dose'
          ? 'studyDose/dictionary'
          : id.dictionary === 'decubits'
          ? 'decubits/dictionary'
          : id.dictionary
      }/delete`,
      { params: { id: id.id } },
    )
    .then((response) => true)
    .catch((error) => false);

export const getAllPhysician = (): Promise<IPhysician[]> =>
  api()
    .get(`/portal-api/physicianDictionary/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getPhysician = (physicianId: any): any =>
  api()
    .get(`/portal-api/physicianDictionary/get`, { params: { physicianId } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllByPhysicianInclusion = (
  referringPhysician: boolean,
  requestingPhysician: boolean,
): Promise<IPhysician[]> =>
  api()
    .get(`/portal-api/physicianDictionary/getAllByInclusion`, {
      params: { referringPhysician, requestingPhysician },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editPhysician = ({ dictionary, ...entity }: any): Promise<boolean> =>
  api()
    .post(`/portal-api/physicianDictionary/edit`, { ...entity })
    .then((response) => true)
    .catch((error) => false);

export const createPhysician = ({ dictionary, ...entity }: any): Promise<boolean> =>
  api()
    .post(`/portal-api/physicianDictionary/create`, entity)
    .then((response) => true)
    .catch((error) => false);

export const removePhysician = (physicianId: number): Promise<boolean> =>
  api()
    .get(`/portal-api/physicianDictionary/delete`, { params: { physicianId } })
    .then((response) => true)
    .catch((error) => false);

export const getAllDecubitsDictionaries = (): Promise<boolean> =>
  api()
    .get(`/portal-api/decubits/dictionaries/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllDecubits = (): Promise<IDecubit[]> =>
  api()
    .get(`/portal-api/decubits/dictionary/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const synchronizeCloudPacs = (): Promise<boolean> =>
  api()
    .get(`/portal-api/cloudPacs/synchronize`)
    .then((response) => true)
    .catch((error) => false);

export const getRecipients = (): Promise<ICloudPacdsRecipient[] | false> =>
  api()
    .get(`/portal-api/cloudPacs/getConfiguration`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllDictionaries = (dictionary: string): any =>
  api()
    .get(
      `/portal-api/${
        dictionary === 'dose'
          ? 'studyDose/dictionary'
          : dictionary === 'studyDetailBookmarks'
          ? 'studyDetailBookmarks'
          : dictionary === 'decubits'
          ? 'decubits/dictionary'
          : dictionary
      }/getAll`,
    )
    .then((response) => get(response, 'data'))
    .catch((error) => false);

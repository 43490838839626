import Header from 'components/Header/Header';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchResultsMUI } from 'components/SearchResultsMUI/SearchResultsMUI';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { IStudyResultForGrid } from 'modules/Search/_types';
import { FormProvider, useForm } from 'react-hook-form';
import { each, get, isEmpty, keys } from 'lodash';
import { useGridColumns } from 'components/SearchResultsMUI/useGridColumns';
import { useSubmitSearchTagForm } from 'utils/hooks/useSubmitSearchTagForm';
import { TagFormSearch } from 'components/TagForm/TagFormSearch';
import { ISearchArchiveTagFormValues, ISearchArchiveTagRequest } from '../_types';
import { FormType, ITagAutocomplete } from 'components/TagForm/_types';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourSearchArchive } from './TourSearchArchive';
import { DETAIL } from 'constants/constants';
import { useStorage } from 'utils/hooks/useStorage';
import { useStudy } from 'utils/hooks/useStudy';

const FORM_DATA_KEY = 'searchArchiveForm';

export const SearchArchive = () => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('Studies');
  useWithTitle(); // sets title to document
  const { getItem, putItem, removeItem } = useStorage();
  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const { columns } = useGridColumns({});

  const { onSubmitSearchTagForm } = useSubmitSearchTagForm(rows, setRows);

  const methods = useForm<ISearchArchiveTagFormValues>({});
  const { handleSubmit, setValue } = methods;

  const { linkBack } = useStudy();
  const backFromDetail = linkBack();

  const prepareSearchValues = (values: ISearchArchiveTagFormValues) => {
    const requestArray: { id: number }[] = [];
    const tempTags = values.registerItems ? Object.values(values.registerItems) : [];
    tempTags.forEach((tags: any) => {
      if (!isEmpty(tags)) {
        tags.forEach((tag: ITagAutocomplete) => {
          requestArray.push({
            id: tag.value,
          });
        });
      }
    });

    let requestData: ISearchArchiveTagRequest = { registerItems: requestArray };

    const commentValue = get(values, 'comment');
    requestData = commentValue
      ? { ...requestData, comment: get(values, 'comment') }
      : { ...requestData };
    return requestData;
  };

  const onSubmit = async (requestData: ISearchArchiveTagRequest) => {
    onSubmitSearchTagForm(requestData, columns);
  };

  const callPersistForm = (value: any) => {
    putItem(FORM_DATA_KEY, JSON.stringify(value));
  };

  const triggerSubmit = () => {
    refSubmitButtom?.current?.click();
  };

  useEffect(() => {
    try {
      if (backFromDetail) {
        const data = getItem(FORM_DATA_KEY);
        if (data) {
          // Parse it to a javaScript object
          const values = JSON.parse(data);
          each(keys(values), (key: any) => {
            if (key === 'registerItems') {
              setValue('registerItems.active', get(values, 'registerItems.active'));
              setValue('registerItems.inactive', get(values, 'registerItems.inactive'));
            } else {
              setValue(key, get(values, key));
            }
          });
          triggerSubmit();
        }
      } else {
        removeItem(FORM_DATA_KEY);
      }
    } catch (e) {
      console.debug(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedSteps = () => {
    return <TourSearchArchive hasRows={!!rows.length} />;
  };

  return (
    <>
      <Header title={t('scientificArchiveTitle')} TourComponent={renderedSteps()} />
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit((values: ISearchArchiveTagFormValues) => {
            const transformedValues = prepareSearchValues(values);
            callPersistForm(values);
            onSubmit(transformedValues);
          })}
        >
          <button hidden={true} ref={refSubmitButtom} type={'submit'} />
          <TagFormSearch showCommentField={true} formType={FormType.Default} />
        </form>
      </FormProvider>

      <SearchResultsMUI
        rows={rows}
        setRows={setRows}
        selecting={false}
        selection={selection}
        setSelection={setSelection}
        isArchive={true}
        actions={[DETAIL]}
        dataTour="tagsArchiveGrid"
      />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { filter, find, forEach, get, isArray, isEmpty, remove, sortBy, uniq } from 'lodash';
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CopyIcon from '@mui/icons-material/FileCopy';
import MoveIcon from '@mui/icons-material/SwapHoriz';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ArchiveArchivedIcon from '@mui/icons-material/Archive';
import ShreddingIcon from '@mui/icons-material/RestoreFromTrash';
import FolderIcon from '@mui/icons-material/FolderOpen';
import LabelIcon from '@mui/icons-material/Label';
import OpenInBrowserIcon from '@mui/icons-material/Image';

import { IArchive, IStudyResultForGrid } from 'modules/Search/_types';
import { IButton, ICannotActionArchives } from './_types';
import { getUrlForOpenAllInViewer } from '../StudyDetail/_api';

import Header from 'components/Header/Header';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { Papeer } from 'components/Papeer/Papeer';
import { SearchResultsMUI } from 'components/SearchResultsMUI/SearchResultsMUI';
import { Announcement } from 'components/Announcement/Announcement';
import useAlerts from 'components/Alerts/useAlerts';
import Button from 'components/Buttons/Button';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useActions } from 'utils/hooks/useActions';
import { getAvailability, studyCanBeArchived } from 'utils/study';
import { settingHasValue } from 'utils/products';
import { useUser } from 'utils/hooks/useUser';
import { useStudy } from 'utils/hooks/useStudy';
import StudyDetailTagForm from '../StudyDetail/StudyDetailTagForm';
import {
  ARCHIVE,
  ActionArchivesKey,
  BASKET,
  ECG,
  MOVE_TO_FOLDER,
  PROPOSAL_SHREDDING,
  REMOVE_ALL_STUDIES_FROM_BASKET,
  REMOVE_STUDIES_FROM_BASKET,
  SEARCH_FUNCTION_COPY,
  SEARCH_FUNCTION_EXPORT,
  SEARCH_FUNCTION_MOVE,
  SEARCH_FUNCTION_SEND,
  STUDY_AVAILABILITY_ARCHIVED,
  SYSTEM_SETTINGS_FOR_SEARCH_FUNCTION,
  VIEWER,
} from 'constants/constants';
import { TourStudiesBasket } from './TourStudiesBasket';

const withoutStudiesLink = '/';

const setCannotActionArchives = (
  cannotActionArchives: ICannotActionArchives,
  key: ActionArchivesKey,
  archive: IArchive,
) => {
  const previewValue = get(cannotActionArchives, key, '');
  cannotActionArchives = {
    ...cannotActionArchives,
    [key]: (previewValue ? previewValue + ' | ' : '') + `${archive.name} (${archive.code})`,
  };
  return cannotActionArchives;
};

const StudiesBasket: React.FC = () => {
  const { t } = useTranslation('StudiesBasket');
  const { compactMode, confirmationData, defViewer, archives: products } = useAppInfo();
  const { hasRole } = useUser();
  const { basketStore } = useStudyInfo();
  const { resetStudies, setStudiesBasket, storeConfirmationData, setBulkStudies, setStudies } =
    useActions();
  const { checkAllowedArchives, studyArchivation, studiesProposalsShredding } = useStudy();
  const { addErrorAlert, addSuccessAlert, addInfoAlert } = useAlerts();

  const [tagDialogState, setTagDialogState] = useState<boolean>(false);
  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const [items, setItems] = useState<GridRowSelectionModel>([]);
  const [selectedArchives, setSelectedArchives] = useState<IArchive[]>([]);
  const [buttons, setButtons] = useState<IButton[]>([]);
  const [announcementTexts, setAnnouncementTexts] = useState<string[]>([]);

  const navigate = useNavigate();

  const actions: string[] = [ECG, REMOVE_STUDIES_FROM_BASKET, REMOVE_ALL_STUDIES_FROM_BASKET];

  if (defViewer) {
    actions.push(VIEWER);
  }

  useEffect(() => {
    if (isEmpty(basketStore)) {
      return navigate(withoutStudiesLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAction = (hasRedirect: boolean = false) => {
    storeConfirmationData(null);
    if (hasRedirect) {
      navigate(withoutStudiesLink);
    }
  };

  const clearBasket = (hasRedirect: boolean = false) => {
    resetStudies(BASKET);
    clearAction(hasRedirect);
  };

  const getArchives = () => {
    const archiveIds: number[] = [];
    const selectedArchives: IArchive[] = [];
    basketStore.forEach((study) => {
      if (study.archive.id) {
        archiveIds.push(study.archive.id);
      }
    });
    uniq(archiveIds).forEach((item) => {
      const arch = find(products, ['id', item]);
      if (arch) {
        selectedArchives.push(arch);
      }
    });
    setSelectedArchives(selectedArchives);
  };

  useEffect(() => {
    setRows(basketStore);
    if (!isEmpty(basketStore)) {
      getArchives();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketStore.length]);

  const filterProductsForSearchFunction = () => {
    if (isArray(products) && products.length) {
      const newProducts = filter(products, ({ systemSetting }: any) =>
        settingHasValue(
          systemSetting,
          SYSTEM_SETTINGS_FOR_SEARCH_FUNCTION[SEARCH_FUNCTION_MOVE],
          'true',
        ),
      );
      return sortBy(
        filter(newProducts, ({ systemSetting }: any) =>
          settingHasValue(systemSetting, 'dataOutputAllowed', 'true'),
        ),
        ['name'],
      );
    } else {
      return [];
    }
  };

  const callAction = (type: string, link: string) => {
    const storedStudies = [...basketStore];
    if (!isEmpty(storedStudies)) {
      if (type === 'addTag') {
        setTagDialogState(true);
      } else if (type === 'viewer') {
        getUrlForOpenAllInViewer({ studies: basketStore }).then(
          (response) => {
            window.open(response, '_blank');
            clearBasket(true);
          },
          (error) => console.log(error),
        );
      } else if (link === 'storeConfirmationData') {
        storeConfirmationData({
          open: true,
          type,
          id: true,
          title:
            type === 'archive'
              ? 'confirmArchivingStudies'
              : type === 'proposalShredding'
              ? 'confirmProposalShreddingTitle'
              : '',
          confirmAction:
            type === 'archive'
              ? 'confirmActionArchivingAllStudies'
              : type === 'proposalShredding'
              ? 'confirmActionProposalShreddingAllStudies'
              : '',
          content:
            type === 'proposalShredding'
              ? t('confirmProposalShredding', { par1: basketStore.length })
              : '',
        });
      } else {
        clearBasket();
        resetStudies(type === 'edit_patient' ? 'edit' : type);
        if (storedStudies.length > 1) {
          setBulkStudies({ type, items: storedStudies });
        } else {
          setStudies({
            type: type === 'edit_patient' ? 'edit' : type,
            item: type === 'edit_patient' ? get(storedStudies[0], 'patient', {}) : storedStudies[0],
          });
        }
        navigate(link);
      }
    }
  };

  const setAnnouncementsReason = (
    key: ActionArchivesKey,
    cannotActionArchives: ICannotActionArchives,
    countOfArchives = 0,
  ) => {
    const reason =
      countOfArchives > 1
        ? t('cannotActionMoreArchives', { action: t(`action.${key}`) })
        : t('cannotAction', {
            action: t(`action.${key}`),
            archives: cannotActionArchives[key],
          });
    return reason;
  };

  useEffect(() => {
    const sourcesMove = filterProductsForSearchFunction();
    const buttons: IButton[] = [];
    const announcementTexts: string[] = [];
    let studyArchiveId = 0;
    let availability = null;
    let cannotMoveAvailability = false;
    let cannotArchiveAvailability = false;
    if (!isEmpty(selectedArchives) && !isEmpty(basketStore)) {
      const countOfStudies = basketStore.length;
      const countOfArchives = selectedArchives.length;
      let isLocked = false;
      basketStore.forEach((study) => {
        isLocked = isLocked || get(study, 'inProcessing', false);
        studyArchiveId = get(study, 'archive.id', 0);
        availability = getAvailability(study);
        cannotMoveAvailability =
          cannotMoveAvailability || availability === STUDY_AVAILABILITY_ARCHIVED;
        cannotArchiveAvailability = cannotArchiveAvailability || !studyCanBeArchived(study);
      });
      let canSend = checkAllowedArchives(SEARCH_FUNCTION_SEND, [...basketStore]);
      let canCopy =
        countOfArchives === 1 && checkAllowedArchives(SEARCH_FUNCTION_COPY, [...basketStore]);
      let canMove =
        countOfArchives === 1 && checkAllowedArchives(SEARCH_FUNCTION_MOVE, [...basketStore]);
      let canArchive = checkAllowedArchives(ARCHIVE, [...basketStore]);
      let canExport = checkAllowedArchives(SEARCH_FUNCTION_EXPORT, [...basketStore]);
      let canShredding = checkAllowedArchives(PROPOSAL_SHREDDING, [...basketStore]);
      let canOpenInBrowser = true;
      let cannotActionArchives: ICannotActionArchives = {
        send: '',
        copy: '',
        move: '',
        archive: '',
        export: '',
        shredding: '',
      };
      if (isLocked) {
        announcementTexts.push(t('someStudiesAreLocked'));
      } else {
        selectedArchives.forEach((archive) => {
          const systemSetting = get(archive, 'systemSetting', []);
          const canSendForArchive = settingHasValue(systemSetting, 'sendAllowed', 'true');
          const canCopyForArchive = settingHasValue(systemSetting, 'copyAllowed', 'true');
          const canMoveForArchive =
            !isEmpty(filter(sourcesMove, (source) => source.id === studyArchiveId)) &&
            settingHasValue(systemSetting, 'moveAllowed', 'true');
          const canArchiveForArchive = settingHasValue(systemSetting, 'archivingAllowed', 'true');
          const canExportForArchive = settingHasValue(systemSetting, 'exportAllowed', 'true');
          const canShreddingForArchive = settingHasValue(systemSetting, 'shreddingAllowed', 'true');
          canSend = canSend ? canSendForArchive : false;
          canCopy = canCopy ? canCopyForArchive : false;
          canMove = canMove ? canMoveForArchive : false;
          canArchive = canArchive ? canArchiveForArchive : false;
          canExport = canExport ? canExportForArchive : false;
          canShredding = canShredding ? canShreddingForArchive : false;

          if (!canSendForArchive) {
            cannotActionArchives = setCannotActionArchives(cannotActionArchives, 'send', archive);
          }
          if (!canCopyForArchive) {
            cannotActionArchives = setCannotActionArchives(cannotActionArchives, 'copy', archive);
          }
          if (!canMoveForArchive) {
            cannotActionArchives = setCannotActionArchives(cannotActionArchives, 'move', archive);
          }
          if (!canArchiveForArchive) {
            cannotActionArchives = setCannotActionArchives(
              cannotActionArchives,
              'archive',
              archive,
            );
          }
          if (!canExportForArchive) {
            cannotActionArchives = setCannotActionArchives(cannotActionArchives, 'export', archive);
          }
          if (!canShreddingForArchive) {
            cannotActionArchives = setCannotActionArchives(
              cannotActionArchives,
              'shredding',
              archive,
            );
          }
        });
      }
      if (!canSend) {
        announcementTexts.push(setAnnouncementsReason('send', cannotActionArchives));
      }
      if (!canCopy) {
        announcementTexts.push(
          setAnnouncementsReason('copy', cannotActionArchives, countOfArchives),
        );
      }
      if (!canMove) {
        announcementTexts.push(
          setAnnouncementsReason('move', cannotActionArchives, countOfArchives),
        );
      }
      if (canMove && cannotMoveAvailability) {
        announcementTexts.push(t('cannotMoveAvailability'));
      }
      if (!canArchive) {
        announcementTexts.push(setAnnouncementsReason('archive', cannotActionArchives));
      }
      if (canArchive && cannotArchiveAvailability) {
        announcementTexts.push(t('cannotArchiveAvailability'));
      }
      if (!canExport) {
        announcementTexts.push(setAnnouncementsReason('export', cannotActionArchives));
      }
      if (!canShredding) {
        announcementTexts.push(setAnnouncementsReason('shredding', cannotActionArchives));
      }
      if (!defViewer) {
        canOpenInBrowser = false;
        announcementTexts.push(t('openInBrowser.noDefViewer'));
      } else {
        const code: string = get(defViewer, 'code', '');
        if (code !== 'MWV') {
          canOpenInBrowser = false;
          announcementTexts.push(t('openInBrowser.viewerByTypeMWV'));
        } else if (countOfArchives > 1) {
          canOpenInBrowser = false;
          announcementTexts.push(t('openInBrowser.onlyFrom1Archive'));
        } else if (get(selectedArchives[0], 'code', '') === 'MS' && countOfStudies > 1) {
          canOpenInBrowser = false;
          announcementTexts.push(t('openInBrowser.only1StudyFromMSArchive'));
        } else if (countOfStudies > 20) {
          canOpenInBrowser = false;
          announcementTexts.push(t('openInBrowser.max20Studies'));
        }
      }

      const setAction = {
        send: {
          show:
            hasRole('ROLE_SEND_DR_SEJF') ||
            hasRole('ROLE_SEND_EPACS') ||
            hasRole('ROLE_SEND_REDIMED'),
          disable: isLocked || !canSend,
          url: `/studies/send/detail`,
          children: <SendIcon />,
          title: t('action.send'),
        },
        copy: {
          show: hasRole('ROLE_COPY'),
          disable: isLocked || !canCopy,
          url: `/studies/copy/detail`,
          children: <CopyIcon />,
          title: t('action.copy'),
        },
        move: {
          show: hasRole('ROLE_MOVE'),
          disable: isLocked || cannotMoveAvailability || !canMove,
          url: `/studies/move/detail`,
          children: <MoveIcon />,
          title: t('action.move'),
        },
        archive: {
          show: hasRole('ROLE_ARCHIVATION'),
          disable: isLocked || cannotArchiveAvailability || !canArchive,
          url: 'storeConfirmationData',
          children: <ArchiveArchivedIcon />,
          title: t('action.archive'),
        },
        export: {
          show:
            hasRole('ROLE_EXPORT_CD') ||
            hasRole('ROLE_EXPORT_DICOM') ||
            hasRole('ROLE_EXPORT_DVD') ||
            hasRole('ROLE_EXPORT_PICTURE') ||
            hasRole('ROLE_EXPORT_VIDEO'),
          disable: isLocked || !canExport,
          url: `/exports/detail`,
          children: <ImportExportIcon />,
          title: t('action.export'),
        },
        proposalShredding: {
          show: hasRole('ROLE_SHREDDING'),
          disable: isLocked || !canShredding,
          url: 'storeConfirmationData',
          children: <ShreddingIcon />,
          title: t('action.proposalShredding'),
        },
        addToFolder: {
          show: hasRole('ROLE_CAN_OPERATE_WITH_FOLDERS'),
          disable: isLocked,
          url: '/studies/addToFolder',
          children: <FolderIcon />,
          title: t('action.addToFolder'),
        },
        addTag: {
          show: true,
          disable: isLocked,
          url: '',
          children: <LabelIcon />,
          title: t('action.addTag'),
        },
        viewer: {
          show: defViewer ? true : false,
          disable: isLocked || !canOpenInBrowser,
          url: '',
          children: <OpenInBrowserIcon />,
          title: t('action.openInBrowserTitle'),
        },
      };

      forEach(setAction, (value, key) => {
        buttons.push({
          show: value.show,
          onClick: () => callAction(key === 'addToFolder' ? MOVE_TO_FOLDER : key, value.url),
          disabled: value.disable,
          children: value.children,
          title: get(value, 'title', '??'),
        });
      });
    }
    setAnnouncementTexts(announcementTexts);
    setButtons(buttons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArchives.length, basketStore.length]);

  const renderedDialog = (
    <OverflowedDialog
      open={tagDialogState}
      onClose={() => setTagDialogState(false)}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth={true}
      title={t('StudyDetail:dialog.tag')}
      withScrolling={true}
    >
      <StudyDetailTagForm
        setDialogState={setTagDialogState}
        study={basketStore[0]}
        studies={[...basketStore]}
        callback={() => clearBasket(true)}
      />
    </OverflowedDialog>
  );

  const confirmAction = () => clearAction();

  const confirmActionArchivingAllStudies = async () => {
    studyArchivation({
      studies: basketStore,
      t,
      addSuccessAlert,
      addErrorAlert,
      callback: () => clearBasket(true),
    } as any);
  };

  const confirmActionProposalShreddingAllStudies = async () => {
    studiesProposalsShredding({
      studies: basketStore,
      t,
      addInfoAlert,
      addSuccessAlert,
      addErrorAlert,
      callback: () => clearBasket(true),
    });
  };

  const cancelAction = () => {
    clearAction();
  };

  const confirmActionRemoveStudies = async () => {
    const newBasket = [...rows];
    items.forEach((item: GridRowId) => {
      remove(newBasket, (basket) => {
        return get(basket, 'iid') === item;
      });
    });
    setStudiesBasket({
      type: BASKET,
      items: newBasket,
      currentItems: [],
    });
    clearAction(isEmpty(newBasket));
  };
  const confirmActionRemoveAllStudies = async () => {
    clearBasket(true);
  };

  const deleteSelectedStudies = (items: GridRowSelectionModel) => {
    storeConfirmationData({
      title:
        isArray(items) && items.length === 1
          ? 'confirmRemoveStudyFromBasket'
          : 'confirmRemoveStudiesFromBasket',
      id: 'studies',
      confirmAction: 'confirmActionRemoveStudies',
    });
  };
  const deleteAllStudies = () => {
    storeConfirmationData({
      title: 'confirmRemoveAllStudiesFromBasket',
      id: 'studies',
      confirmAction: 'confirmActionRemoveAllStudies',
    });
  };

  const callFunctionByName = (nameFunction: string, item: string | null = null) => {
    const items = item ? [item] : selection;
    setItems(items);
    if (nameFunction === 'deleteSelectedStudies') {
      deleteSelectedStudies(items);
    }
    if (nameFunction === 'deleteAllStudies') {
      deleteAllStudies();
    }
  };

  const renderedSteps = () => {
    return <TourStudiesBasket />;
  };

  return (
    <>
      <Header title={t('studyBasketTitle')} TourComponent={renderedSteps()} />

      <Papeer sx={{ mb: compactMode ? 1 : 2 }}>
        <Grid container={true} spacing={2} data-tour="studiesBasketActionButton">
          <Grid item={true}>
            {buttons.map((button, index) => (
              <Tooltip key={index} title={button.title}>
                <span>
                  <Button
                    variant="contained"
                    sx={{ mr: compactMode ? 0.5 : 1, mb: compactMode ? 0.5 : 1 }}
                    {...button}
                  />
                </span>
              </Tooltip>
            ))}
          </Grid>
        </Grid>
        {!isEmpty(announcementTexts) ? (
          <Box sx={{ mt: compactMode ? 0.5 : 1, mb: compactMode ? 0.5 : 1 }}>
            <Grid container={true} spacing={1}>
              {announcementTexts.map((announcement, index) => (
                <Grid item={true} xs={12} key={index}>
                  <Announcement label={announcement} type="info" spaced={true} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : null}
      </Papeer>
      <SearchResultsMUI
        rows={rows}
        setRows={setRows}
        actions={actions}
        selecting={true}
        selection={selection}
        setSelection={setSelection}
        operations={{ callFunctionByName }}
      />

      {renderedDialog}

      {confirmationData && confirmationData.id && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={cancelAction}
          confirmAction={
            confirmationData.confirmAction === 'confirmActionRemoveStudies'
              ? confirmActionRemoveStudies
              : confirmationData.confirmAction === 'confirmActionRemoveAllStudies'
              ? confirmActionRemoveAllStudies
              : confirmationData.confirmAction === 'confirmActionArchivingAllStudies'
              ? confirmActionArchivingAllStudies
              : confirmationData.confirmAction === 'confirmActionProposalShreddingAllStudies'
              ? confirmActionProposalShreddingAllStudies
              : confirmAction
          }
        >
          {confirmationData.content ? (
            <Typography variant="body1">{confirmationData.content}</Typography>
          ) : null}
        </ConfirmationDialog>
      )}
    </>
  );
};

export default StudiesBasket;

import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { forEach, get } from 'lodash';
import { Box, Button, Grid, Typography } from '@mui/material';
import { send } from './_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useDicomParser } from 'components/Import/useFileParser';
import { checkUploadingStatus, cleanUppy, createUppy } from 'utils/import';
import { useUser } from 'utils/hooks/useUser';
import { useFetchAddressBooksAndRenderRecipients } from 'utils/hooks/useFetchAddressBooksAndRenderRecipients';
import useAlerts from 'components/Alerts/useAlerts';
import Import from 'components/Import/Import';
import DicomGrid from 'components/Import/DicomGrid';
import { Papeer } from 'components/Papeer/Papeer';
import { ISeries } from 'components/Import/_types';

import CustomUppyFolderInput from 'modules/Import/CustomUppyFolderInput';
import CustomUppyFileInput from 'modules/Import/CustomUppyFileInput';
import { setMdexDicomPatientToStore } from 'store/reducers/appReducer';
import { useAppDispatch } from 'store/hooks';
import LinearProgressWithLabel from 'components/Import/LinearProgressWithLabel';

let uppyInstance: any;
let intervalUploading: any;

export const Dicom: React.FC<any> = () => {
  const { t } = useTranslation('Import');
  const { toggleLoader } = useAppGlobals();
  const { user } = useUser();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { renderedRecipients } = useFetchAddressBooksAndRenderRecipients(true);

  const [patients, setPatients] = useState<any[]>([]);
  const { parseDicomFiles } = useDicomParser({ setPatients });
  const [timestamp, setTimestamp] = useState(new Date().getTime().toString());
  const [completed, setCompleted] = useState(0);
  // const [editedCells, setEditedCells] = useState<IEditedCells>({});
  const [selectedSeries, setSelectedSeries] = useState<Map<string, ISeries>>(new Map());
  const [seriesDetailOpened, setSeriesDetailOpened] = useState<{ [id: string]: boolean }>({});
  const dispatch = useAppDispatch();

  useMemo(() => {
    // componentWillMount events
    createUppy('uppyMdex', parseDicomFiles, timestamp, [], true);
    uppyInstance = (window as any).UppyGlobalInstances[
      (window as any).UppyGlobalInstances.length - 1
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    intervalUploading = setInterval(() => {
      checkUploadingStatus(setCompleted);
    }, 500);
    return () => {
      cleanUppy();
      clearInterval(intervalUploading);
    };
  }, []);

  const methods = useForm<any>({ defaultValues: {} });
  const { handleSubmit, reset, watch } = methods;

  const institutionNameWatch = watch('institutionName');
  useEffect(() => {
    const files = patients.flatMap((patient) => get(patient, 'files', []));
    let filesIds: string[] = [];
    files.forEach((file: any) => {
      filesIds.push(get(file, 'id'));
    });
    dispatch(setMdexDicomPatientToStore(filesIds));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patients]);

  const removePatient = (patient: any) => {
    // dispatch(setTourControlPropsToStore({ isTourOpen: false }));
    const files = get(patient, 'files', []);
    forEach(files, (file) => {
      uppyInstance.removeFile(get(file, 'id'));
    });

    setPatients((prevPatients) => [...prevPatients.filter((pat: any) => pat.id !== patient.id)]);
  };

  const removeAllPatients = () => {
    // dispatch(setTourControlPropsToStore({ isTourOpen: false }));
    forEach(patients, (patient) => {
      const files = get(patient, 'files', []);
      forEach(files, (file) => {
        uppyInstance.removeFile(get(file, 'id'));
      });
    });
    setPatients([]);
  };

  const onSubmit = async (values: any) => {
    toggleLoader();
    uppyInstance.upload().then(async () => {
      // Upload complete
      const transformedValues = {
        recipient: get(values, 'institutionName.value', null),
        type: 'DICOM',
        filePath: `${timestamp}_${user?.sub}`,
      };
      const resp = await send(transformedValues);
      toggleLoader(false);
      if (resp) {
        addSuccessAlert(t('notifications.mdexSuccess'));
      } else {
        addErrorAlert(t('notifications.mdexError'));
      }
    });
    setTimeout(() => {
      toggleLoader(false);
    }, 3000);
    setPatients([]);
    reset({});
    const newTimestamp = new Date().getTime().toString();
    setTimestamp(newTimestamp);
    createUppy('uppyMdex', parseDicomFiles, newTimestamp, [], true);
    uppyInstance = (window as any).UppyGlobalInstances[
      (window as any).UppyGlobalInstances.length - 1
    ];
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {completed ? (
            <div>
              <LinearProgressWithLabel value={completed} />
              <Typography component="div" sx={{ marginTop: '4px', marginBottom: '8px' }}>
                {t('uploadingFiles')}
              </Typography>
            </div>
          ) : null}
          {!patients.length && (
            <Papeer>
              <Grid container spacing={1}>
                <Grid item xs={12} data-tour="mdex-dicom-uppySelector">
                  {uppyInstance && <Import uppy={uppyInstance} />}
                </Grid>
              </Grid>
            </Papeer>
          )}
          {!!patients.length && (
            <Papeer sx={{ mt: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} data-tour="mdex-dicom-grid">
                  <DicomGrid
                    patients={patients}
                    removePatient={removePatient}
                    uppy={uppyInstance}
                    // editedCells={editedCells}
                    enableMasterDetail={true}
                    selectedSeries={selectedSeries}
                    setSelectedSeries={setSelectedSeries}
                    seriesDetailOpened={seriesDetailOpened}
                    setSeriesDetailOpened={setSeriesDetailOpened}
                    hideEditPatient={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  sx={{ mt: 1, gap: 1 }}
                >
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={removeAllPatients}
                    data-tour="mdex-dicom-deleteAll"
                  >
                    {t('Import:removeAll')}
                  </Button>
                  <Box data-tour="mdex-dicom-uploadFolder">
                    <CustomUppyFolderInput uppy={uppyInstance} />
                  </Box>
                  <Box data-tour="mdex-dicom-uploadFiles">
                    <CustomUppyFileInput uppy={uppyInstance} />
                  </Box>
                </Grid>
              </Grid>
            </Papeer>
          )}

          <Papeer sx={{ mt: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1 }} data-tour="mdex-send-recipients">
                    {renderedRecipients}
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!patients.length || !institutionNameWatch}
                      sx={{ ml: 2 }}
                      data-tour="mdex-send-button"
                    >
                      {t('Mdex:send')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>
      {/* <EditDicomTagsDialog
        editDialogState={editDialogState}
        toggleEditDialog={toggleEditDialog}
        patientForEditDialog={patientForEditDialog}
      /> */}
    </>
  );
};

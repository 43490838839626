import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { find, get, isArray, isEmpty, sortBy } from 'lodash';
import { Box, Button, Grid, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { setAllowedFunctionsForArchive } from './_api';
import { IWorkplaceArchiveAction } from './_types';
import { Papeer } from 'components/Papeer/Papeer';
import useAlerts from 'components/Alerts/useAlerts';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { FUNCTIONS_OF_STUDIES } from 'constants/constants';

export const WorkplaceArchiveActionsForm: React.FC<any> = ({
  allowedFunctionsForDialog,
  archiveId,
  workPlaceId,
  toggleDialog,
  loadEntity,
}) => {
  const { t } = useTranslation('Workplaces');
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [allFunctions, setAllFunctions] = useState<any[]>([]);
  const [entity, setEntity] = useState<any>({});

  const methods = useForm<any>();
  const { watch, reset } = methods;
  const selectedItems = watch('functions');

  const { toggleLoader } = useAppGlobals();

  const setFunctions = async () => {
    toggleLoader();
    let allowedFunctions = {};
    if (isArray(allFunctions)) {
      allFunctions.forEach((fce: any) => {
        const isSelected = find(selectedItems, { id: fce.id }) ? true : false;
        allowedFunctions = { ...allowedFunctions, [fce.id]: isSelected };
      });
    }
    await setAllowedFunctionsForArchive({ allowedFunctions, archiveId, workPlaceId }).then(
      (response) => {
        addSuccessAlert(t('savedActionsForArchive'));
        loadEntity();
        toggleDialog(false);
      },
      (error) => {
        addErrorAlert(t('errorSavingActionsForArchive'));
      },
    );
    toggleLoader(false);
  };

  const getEntity = async () => {
    toggleLoader();
    try {
      const functions: any[] = [];
      const allFunctions = sortBy(
        FUNCTIONS_OF_STUDIES.map((action) => {
          if (get(allowedFunctionsForDialog, action, false)) {
            functions.push({ id: action, name: t(action) });
          }
          return { id: action, name: t(action) };
        }),
        ['name'],
      );
      setAllFunctions(allFunctions);
      const entity: IWorkplaceArchiveAction = { functions };
      setEntity(entity);
      reset({ ...entity });
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isEmpty(entity) ? (
        <Typography component="div">
          <Papeer>
            <FormProvider {...methods}>
              <form>
                <Grid container={true} alignItems="flex-end">
                  <Grid item={true} xs={12}>
                    <SimpleCheckboxArray
                      name="functions"
                      label={t('allowedArchiveActions')}
                      items={allFunctions}
                      defaultValue={get(entity, 'functions', [])}
                      grid={{ xs: 12, md: 6, lg: 4 }}
                      required={true}
                    />
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    '@media print': {
                      display: 'none',
                    },
                  }}
                >
                  <Button
                    onClick={() => toggleDialog(false)}
                    color="inherit"
                    variant="contained"
                    size="large"
                  >
                    {t('close')}
                  </Button>
                  <Button
                    onClick={() => setFunctions()}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ ml: 1 }}
                  >
                    <SaveIcon />
                    {t('save')}
                  </Button>
                </Box>
              </form>
            </FormProvider>
          </Papeer>
        </Typography>
      ) : (
        <Typography>{t('archiveFunctionsNotFound')}</Typography>
      )}
    </>
  );
};

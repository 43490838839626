import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';

import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { IResultsChart } from './_types';
import { green, red } from 'utils/variables';

ChartJS.register(ArcElement, Tooltip, Legend);

const ResultsChart: React.FC<IResultsChart> = ({ successRate }) => {
  const { t } = useTranslation('Tests');

  const ChartComponent = useMemo(() => {
    if (isNumber(successRate)) {
      const chartData = {
        chartData: {
          labels: [t('wrongTests'), t('successfullTests')],
          datasets: [
            {
              data: [(100 - successRate).toFixed(1), successRate.toFixed(1)],
              backgroundColor: [red, green],
              hoverOffset: 4,
            },
          ],
        },
      };
      return (
        <div>
          <Pie
            data={chartData.chartData}
            width={400}
            height={200}
            options={{ maintainAspectRatio: false }}
          />
        </div>
      );
    } else {
      return null;
    }
  }, [successRate, t]);

  return ChartComponent;
};

export default ResultsChart;

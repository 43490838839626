import React, { useEffect, useState } from 'react';
import {
  filter,
  get,
  includes,
  isEmpty,
  concat,
  sortBy,
  keys,
  values,
  head,
  isArray,
  toNumber,
  uniq,
  compact,
  set,
  find,
} from 'lodash';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { downloadPdfDocument, getDocuments, getPatient, getPdfDocument } from './_api';
import { getClinicalPortalSetting } from 'modules/Administration/ClinicalPortal/_api';
import { getProductByType } from 'modules/Administration/Products/_api';
import useAlerts from 'components/Alerts/useAlerts';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useLanguage } from 'utils/hooks/useLanguage';
import { joinParams } from 'utils/study';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { apiDataToDownloadFile, printOrDownloadBase64String } from 'utils/hooks/useApp';
import { useStudy } from 'utils/hooks/useStudy';
import { getFirstActiveProduct, getSystemSetting } from 'utils/products';
import {
  addToStoreFacilitiesManualLoad,
  storeFacilitiesManualLoad,
} from 'store/reducers/appReducer';
import { DocumentGrid } from './DocumentGrid';
import {
  IAllergy,
  IBookmark,
  IFacitiesLoad,
  IFieldForPatient,
  IMaxCount,
  IPatient,
  IPatientDocument,
  ITypeOfDocument,
} from './_types';
import { useStorage } from 'utils/hooks/useStorage';

const formatDate = 'dd. MM. yyyy';

const sxDisplayPrintNone = {
  '@media print': {
    display: 'none',
  },
};

const OTHER_DATA_KEY = 'patientDetailOther';
const EXPANDED_VALUE = true;
const EXPANDED_EXTENDED_VALUE = false;

export const ClinicalPortalByPatientDetail: React.FC = () => {
  const { t } = useTranslation('ClinicalPortal');
  const { toggleLoader } = useAppGlobals();
  const { id } = useEntityInfo();
  const { addErrorAlert } = useAlerts();
  const { getFacilitiesManualLoad, selectedDocuments } = useAppInfo();
  const { currentLocale } = useLanguage();
  const { linkBack } = useStudy();
  const { getItem, putItem } = useStorage();

  const backFromDetail = linkBack();
  const backUrl = '/clinicPortal/byPatient?action=back';

  const [facilitiesAutoLoad, setFacilitiesAutoLoad] = useState<IFacitiesLoad[]>([]);
  const [facilitiesManualLoad, setFacilitiesManualLoad] = useState<IFacitiesLoad[]>([]);
  const [bookmarks, setBookmarks] = useState<IBookmark[]>([]);
  const [activeTab, setActiveTab] = useState<string>('');
  const [maxCounts, setMaxCounts] = useState<IMaxCount>({
    maxExportDocumentsCount: 16,
    maxViewDocumentsCount: 16,
  });
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [standardizedPrint, setStandardizedPrint] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingFacility, setLoadingFacility] = useState<string>('');
  const [expanded, setExpanded] = useState<boolean>(EXPANDED_VALUE);
  const [expandedExtended, setExpandedExtended] = useState<boolean>(EXPANDED_EXTENDED_VALUE);

  const [entity, setEntity] = useState<IPatient | null>(null);
  const [patientDocuments, setPatientDocuments] = useState<IPatientDocument[]>([]);
  const [fieldsForPatientName, setFieldsForPatientName] = useState<IFieldForPatient[]>([]);
  const [fieldsForPatientInfo, setFieldsForPatientInfo] = useState<IFieldForPatient[]>([]);
  const [fieldsForPatientExtendedInfo, setFieldsForPatientExtendedInfo] = useState<
    IFieldForPatient[]
  >([]);
  const [RenderedTab, setRenderedTab] = useState<JSX.Element[]>([]);

  const toggleExpanded = () => {
    putToStorage(!expanded, expandedExtended, activeTab);
    setExpanded(!expanded);
  };
  const toggleExpandedExtended = () => {
    putToStorage(expanded, !expandedExtended, activeTab);
    setExpandedExtended(!expandedExtended);
  };
  const toggleActiveTab = (activeTab: string) => {
    putToStorage(expanded, expandedExtended, activeTab);
    setActiveTab(activeTab);
  };

  const findDocumentsForFacility = async (
    masterPatientID: string,
    activeTab: string,
    facility: string,
  ) => {
    setLoadingFacility(facility);
    let allDocuments: IPatientDocument[] = [];
    const documents: IPatientDocument[] = await getDocuments(masterPatientID, activeTab, facility);
    if (documents) {
      const preparedDocuments = documents.map((item, index: number) => ({
        ...item,
        index,
        uuidex: `${item.uuid}_${index}`,
        cannotBySelected: get(item, 'typeOfDocument.name', '') === 'DICOM',
        showDocumentIcon: 'Search',
        typeOfDocumentName: get(item, 'typeOfDocument.translateJson')
          ? get(
              JSON.parse(get(item, 'typeOfDocument.translateJson')),
              currentLocale,
              get(item, 'typeOfDocument.name'),
            )
          : '?',
        facility,
      }));
      allDocuments = [...preparedDocuments];
    }
    setLoadingFacility('');
    return allDocuments;
  };

  const loadDocuments = async (entity: IPatient) => {
    toggleLoader();
    const masterPatientID = get(entity, 'masterPatientID', '');
    let documents: any[] = [];
    let allDocuments: IPatientDocument[] = [];
    setIsLoading(true);
    if (!isEmpty(facilitiesAutoLoad)) {
      documents = await Promise.all(
        facilitiesAutoLoad.map((facilityItem) => {
          return findDocumentsForFacility(
            masterPatientID,
            activeTab,
            get(facilityItem, 'facility', ''),
          );
        }),
      );
      documents.forEach((docs) => {
        allDocuments = uniq(compact([...(allDocuments || []), ...(docs || [])]));
      });
      setPatientDocuments(allDocuments);
      setIsLoading(false);
    }
    toggleLoader(false);
  };

  const loadEntities = async () => {
    toggleLoader();
    if (!backFromDetail) {
      storeFacilitiesManualLoad([]);
    }
    const [clinicalPortalSetting, productsByType, patient] = await Promise.all([
      getClinicalPortalSetting(),
      getProductByType('clinical_portal'),
      getPatient(id.toString()),
    ]);
    if (!patient) {
      addErrorAlert(t('getDataError'));
    } else {
      let facilitiesAutoLoad = [];
      let facilitiesManualLoad = [];
      let bookmarks = [];
      if (clinicalPortalSetting) {
        bookmarks = sortBy(filter(get(clinicalPortalSetting, 'bookmarks', []), { enabled: true }), [
          'position',
        ]);
        facilitiesAutoLoad = filter(get(clinicalPortalSetting, 'facilities', []), {
          autoLoad: true,
        });
        facilitiesManualLoad = filter(get(clinicalPortalSetting, 'facilities', []), {
          autoLoad: false,
        });
        const newFacitiesManualLoad: any[] = backFromDetail
          ? facilitiesManualLoad.map((facilityItem) => ({
              facility: get(facilityItem, 'facility'),
              label: get(facilityItem, 'label'),
              disabled: includes(getFacilitiesManualLoad, get(facilityItem, 'facility'))
                ? true
                : false,
            }))
          : [...facilitiesManualLoad];
        const selectedFacilityManualLoad = backFromDetail
          ? getFacilitiesManualLoad.map((item: any) => ({
              facility: item,
            }))
          : [];
        const concatFacilities: IFacitiesLoad[] = concat(
          facilitiesAutoLoad,
          selectedFacilityManualLoad,
        );
        setFacilitiesAutoLoad(concatFacilities);
        setFacilitiesManualLoad(newFacitiesManualLoad);
      }
      bookmarks = bookmarks.map((bookmark) => {
        const bookmarkNames = JSON.parse(get(bookmark, 'name', null));
        const aKeys = keys(bookmarkNames);
        const zValues = values(bookmarkNames);
        let title = '';
        aKeys.forEach((key, i) => {
          if (key === currentLocale && zValues[i] !== '') {
            title = zValues[i];
          }
          if (title === '' && zValues[i] !== '') {
            title = zValues[i];
          }
        });
        return { ...bookmark, title };
      });
      setBookmarks(bookmarks);
      const first = head(bookmarks);
      let activeTab = '';
      if (first !== undefined) {
        activeTab = get(first, 'id', '');
        setActiveTab(activeTab);
      }
      setEntity(patient);

      if (isArray(productsByType)) {
        const product = getFirstActiveProduct(productsByType);

        if (product) {
          const systemSettings = get(product, 'systemSetting', []);
          const maxExportDocumentsCount = get(
            getSystemSetting(systemSettings, 'variable', 'maxExportDocumentsCount'),
            'value',
          );
          setMaxCounts({
            ...maxCounts,
            maxExportDocumentsCount: maxExportDocumentsCount
              ? toNumber(maxExportDocumentsCount)
              : 5,
          });
        }
      }
      if (backFromDetail) {
        const otherData = getItem(OTHER_DATA_KEY);
        if (otherData) {
          // Parse it to a javaScript object
          try {
            const parsedOtherData = JSON.parse(otherData);
            const storageExpanded = get(parsedOtherData, 'expanded', EXPANDED_VALUE);
            const storageExpandedExtended = get(
              parsedOtherData,
              'expandedExtended',
              EXPANDED_EXTENDED_VALUE,
            );
            const storageActiveTab = get(parsedOtherData, 'activeTab', get(first, 'id', ''));
            setExpanded(storageExpanded);
            setExpandedExtended(storageExpandedExtended);
            setActiveTab(storageActiveTab);
          } catch (err) {
            console.log(err);
          }
        }
      } else {
        putToStorage(
          EXPANDED_VALUE,
          EXPANDED_EXTENDED_VALUE,
          first !== undefined ? get(first, 'id', '') : '',
        );
      }
    }
    toggleLoader(false);
  };

  useEffect(() => {
    if (!isEmpty(entity)) {
      const fieldsForPatientName = [
        {
          name: 'name',
          value: `${joinParams([
            get(entity, 'patientPrefix'),
            get(entity, 'patientLastName'),
            get(entity, 'patientFirstName'),
            get(entity, 'patientMiddleName'),
            get(entity, 'patientSuffix'),
          ])}
        `,
          lg: 6,
        },
      ];
      setFieldsForPatientName(fieldsForPatientName);

      const fieldsForPatientInfo = [
        {
          name: 'patientIdentificationNumber',
          label: t('patientIdentificationNumber'),
          value: `${
            get(entity, 'patientIdentificationNumber', '-') != null
              ? get(entity, 'patientIdentificationNumber', '-')
              : '-' || '-'
          }`,
        },
        {
          name: 'patientID',
          label: t('patientInsuranceNumber'),
          value: `${get(entity, 'patientID', '-') || '-'}`,
        },
        {
          name: 'patientBirthDate',
          label: t('patientBirthDate'),
          value: get(entity, 'patientBirthDate', '')
            ? format(new Date(get(entity, 'patientBirthDate', '')), formatDate)
            : '-',
        },
        {
          name: 'patientSex',
          label: t('patientSex'),
          value: t(`patient_sex_${get(entity, 'patientSex', 'O')}`),
        },
        {
          name: 'address',
          label: t('address'),
          value: joinParams(
            [
              get(entity, 'address', ''),
              joinParams([get(entity, 'postcode', ''), get(entity, 'town', '')]),
            ],
            ', ',
          ),
        },
        {
          name: 'telephone',
          label: t('telephone'),
          value: `${get(entity, 'telephone', '') || ''}`,
        },
        {
          name: 'motherMaidenName',
          label: t('motherMaidenName'),
          value: `${get(entity, 'motherMaidenName', '') || ''}`,
        },
        {
          name: 'masterPatientID',
          label: t('masterPatientID'),
          value: `${get(entity, 'masterPatientID', '') || ''}`,
        },
      ];
      setFieldsForPatientInfo(fieldsForPatientInfo);

      const fieldsForPatientExtendedInfo = [
        {
          name: 'patientInsurance',
          label: t('patientInsurance'),
          value: `${get(entity, 'insuranceName', '-') || '-'} (${
            get(entity, 'insuranceCode', '-') || '-'
          })`,
        },
        {
          name: 'email',
          label: t('email'),
          value: `${get(entity, 'email', '-') || '-'}`,
        },
        {
          name: 'employer',
          label: t('employer'),
          value: `${get(entity, 'employer', '-') || '-'}`,
        },
        {
          name: 'occupation',
          label: t('occupation'),
          value: `${get(entity, 'occupation', '-') || '-'}`,
        },
        {
          name: 'attendingGeneralPractitioner',
          label: t('attendingGeneralPractitioner'),
          value: `${get(entity, 'attendingGeneralPractitioner', '-') || '-'}`,
        },
        {
          name: 'bloodGroup',
          label: t('bloodGroup'),
          value: `${get(entity, 'bloodGroup', '-') || '-'}`,
        },
        {
          name: 'allergy',
          label: t('allergy'),
          value: joinParams(
            [
              ...get(entity, 'allergy', []).map((allergy: IAllergy) => {
                return get(allergy, 'description', '-');
              }),
            ],
            ', ',
          ),
        },
      ];
      setFieldsForPatientExtendedInfo(fieldsForPatientExtendedInfo);

      loadDocuments(entity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findDocuments = async (facility: string) => {
    setIsLoading(true);
    const masterPatientID = get(entity, 'masterPatientID', '');
    const newFacitiesManualLoad = facilitiesManualLoad.map((facilityItem) => ({
      facility: facilityItem.facility,
      label: facilityItem.label,
      disabled: get(facilityItem, 'disabled', false)
        ? true
        : facilityItem.facility === facility
        ? true
        : false,
    }));
    setFacilitiesManualLoad(newFacitiesManualLoad);

    const allDocuments = await findDocumentsForFacility(masterPatientID, activeTab, facility);
    setPatientDocuments(uniq(compact([...(patientDocuments || []), ...(allDocuments || [])])));

    addToStoreFacilitiesManualLoad(facility);
    setIsLoading(false);
  };

  useEffect(() => {
    if (bookmarks != null) {
      bookmarks.forEach((bookmark) => {
        const allowedTypesOfDocument = get(bookmark, 'allowedTypesOfDocument', null);
        if (allowedTypesOfDocument !== null) {
          let filteredPatientDocuments: IPatientDocument[] = [];
          allowedTypesOfDocument.forEach((allowedType) => {
            const subFilterred = filter(patientDocuments, { typeOfDocument: allowedType });
            if (subFilterred !== undefined) {
              filteredPatientDocuments = [...filteredPatientDocuments, ...subFilterred];
            }
          });
          set(bookmark, 'patientDocumentCount', filteredPatientDocuments.length);
        }
      });
      setBookmarks(bookmarks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDocuments]);

  useEffect(() => {
    const bookmark = find(bookmarks, { id: activeTab });
    const allowedTypesOfDocument = get(bookmark, 'allowedTypesOfDocument', null) as
      | ITypeOfDocument[]
      | null;
    let filteredPatientDocuments: IPatientDocument[] = [];
    if (isArray(allowedTypesOfDocument) && allowedTypesOfDocument !== null) {
      allowedTypesOfDocument.forEach((allowedType: ITypeOfDocument) => {
        const subFilterred = filter(patientDocuments, { typeOfDocument: allowedType });
        if (subFilterred !== undefined) {
          filteredPatientDocuments = [...filteredPatientDocuments, ...subFilterred];
        }
      });
    }
    const RenderedTab = [];
    let facilities = facilitiesAutoLoad.map((facilityItem) => {
      return get(facilityItem, 'facility', '');
    });
    facilities = uniq(concat(facilities, getFacilitiesManualLoad));
    if (
      entity !== null &&
      bookmarks !== null &&
      isArray(facilities) &&
      !isEmpty(patientDocuments)
    ) {
      RenderedTab.push(
        <Box key={activeTab}>
          <DocumentGrid
            masterPatientID={get(entity, 'masterPatientID', '')}
            facilities={facilities}
            bookmarkId={activeTab}
            allowedTypesOfDocument={allowedTypesOfDocument}
            patientDocuments={filteredPatientDocuments}
            patient={entity}
            maxCounts={maxCounts}
            setIsPrinting={setIsPrinting}
            setStandardizedPrint={setStandardizedPrint}
            setIsExporting={setIsExporting}
            isLoading={isLoading}
          />
        </Box>,
      );
    }
    setRenderedTab(RenderedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    entity,
    patientDocuments.length,
    bookmarks.length,
    isLoading,
    facilitiesAutoLoad.length,
    getFacilitiesManualLoad,
  ]);

  const loadDocument = async (
    masterPatientID: string,
    documentUiid: string,
    typeOfDocument: string,
    standardizedPrint: boolean,
  ) => {
    if (masterPatientID !== null && documentUiid !== null && typeOfDocument !== null) {
      const documentPrintInfoResponse = await getPdfDocument(
        masterPatientID,
        documentUiid,
        typeOfDocument,
        standardizedPrint,
      );
      if (documentPrintInfoResponse) {
        return documentPrintInfoResponse;
      }
    }
    return undefined;
  };
  const workWithPdf = async () => {
    toggleLoader();
    for await (const uuid of selectedDocuments) {
      const patientDoc = find(patientDocuments, { uuid });
      const fileName = get(entity, 'masterPatientID', null) + '.pdf';
      if (isPrinting) {
        const base64PdfString = await loadDocument(
          get(entity, 'masterPatientID', ''),
          uuid,
          get(patientDoc, 'typeOfDocument.name', ''),
          standardizedPrint,
        );
        const contentType = 'application/pdf';
        if (base64PdfString) {
          printOrDownloadBase64String(base64PdfString, contentType, fileName);
        } else {
          addErrorAlert(t('printDocumentError'));
        }
        setStandardizedPrint(false);
        setIsPrinting(false);
      } else if (isExporting) {
        const base64PdfString = await downloadPdfDocument(
          get(entity, 'masterPatientID', null),
          uuid,
          get(patientDoc, 'typeOfDocument.name', ''),
          standardizedPrint,
        );
        if (base64PdfString) {
          apiDataToDownloadFile(base64PdfString, fileName);
        } else {
          addErrorAlert(t('exportDocumentError'));
        }
        setStandardizedPrint(false);
        setIsExporting(false);
      }
    }
    toggleLoader(false);
  };
  useEffect(() => {
    if (isPrinting || isExporting) {
      workWithPdf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExporting, isPrinting]);

  const putToStorage = (
    expandedValue: boolean,
    expandedExtendedValue: boolean,
    activeTabValue: string,
  ) => {
    putItem(
      OTHER_DATA_KEY,
      JSON.stringify({
        expanded: expandedValue,
        expandedExtended: expandedExtendedValue,
        activeTab: activeTabValue,
      }),
    );
  };

  return (
    <>
      {entity && fieldsForPatientName && (
        <>
          <Typography component="div">
            <Papeer>
              <Header
                title={t('detail')}
                backUrl={backUrl}
                loading={loadingFacility}
                loadingDescription={t('loadingDataForFacility', { par1: loadingFacility })}
              />

              <Box id="patient">
                <Accordion
                  elevation={0}
                  defaultExpanded={expanded}
                  onChange={() => toggleExpanded()}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-patient"
                    id="panel-patient"
                  >
                    <Grid container={true} spacing={1}>
                      {fieldsForPatientName.map((field) => (
                        <Grid item={true} xs={12} md={12} lg={12} key={field.name}>
                          {field.value}
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container={true} spacing={1}>
                      {fieldsForPatientInfo.map((field) => (
                        <Grid item={true} xs={6} md={4} lg={3} {...field.grid} key={field.name}>
                          <Box component="label" sx={{ fontWeight: 'bold' }}>
                            {field.label}
                          </Box>
                          <div>{field.value}</div>
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {expanded && (
                <Box id="patinetExtendedInfo" sx={sxDisplayPrintNone}>
                  <Accordion
                    elevation={0}
                    defaultExpanded={expandedExtended}
                    onChange={() => toggleExpandedExtended()}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel-patient-extended"
                      id="panel-patient-extended"
                    >
                      <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12} md={12} lg={12} key={'moreInfo'}>
                          <Typography
                            style={{
                              textAlign: 'justify',
                              textAlignLast: 'right',
                              paddingRight: 18,
                            }}
                          >
                            {t(expandedExtended ? 'lessPatientInfo' : 'morePatientInfo')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container={true} spacing={1}>
                        {fieldsForPatientExtendedInfo.map((field) => (
                          <Grid item={true} xs={6} md={4} lg={3} {...field.grid} key={field.name}>
                            <Box component="label" sx={{ fontWeight: 'bold' }}>
                              {field.label}
                            </Box>
                            <div>{field.value}</div>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
              {!isEmpty(facilitiesManualLoad) && (
                <Box sx={sxDisplayPrintNone}>
                  {facilitiesManualLoad.map((facilityItem) => (
                    <Button
                      key={facilityItem.facility}
                      variant="contained"
                      color="primary"
                      size="medium"
                      disabled={get(facilityItem, 'disabled', false)}
                      onClick={() => findDocuments(get(facilityItem, 'facility', ''))}
                      sx={{ mr: 1 }}
                    >
                      {facilityItem.label}
                    </Button>
                  ))}
                </Box>
              )}
            </Papeer>
          </Typography>

          <Box id="patient" sx={sxDisplayPrintNone}>
            <AppBar position="static" color="default" sx={{ mt: 2 }}>
              <Tabs
                value={activeTab}
                onChange={(event, value) => toggleActiveTab(value)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {!isEmpty(bookmarks) &&
                  bookmarks.map((bookmark) => (
                    <Tab
                      value={get(bookmark, 'id')}
                      label={`${get(bookmark, 'title')} ${
                        get(bookmark, 'patientDocumentCount') !== undefined
                          ? `(${get(bookmark, 'patientDocumentCount')})`
                          : ''
                      }`}
                      key={`tab_${get(bookmark, 'id')}`}
                      disabled={get(bookmark, 'patientDocumentCount') === 0}
                    />
                  ))}
              </Tabs>
            </AppBar>
            <Box sx={{ py: 2 }}>{activeTab !== '' && RenderedTab}</Box>
          </Box>
        </>
      )}
    </>
  );
};

import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { filter } from 'lodash';

const useValidators = () => {
  const { t } = useTranslation('Errors');

  // Validate for e-mail right format
  const email = (yupInstance: any) => yupInstance.email(t('invalidEmailAddress'));

  // Required input field
  const required = (yupInstance: any, errorMessage: string = 'required') =>
    yupInstance.required(t(`${errorMessage}`));

  // Required if minimum input field
  const requiredIfMinimum = (
    yupInstance: any,
    isRequired: boolean,
    minimum: number,
    errorMessage: string = 'required',
  ) => {
    if (isRequired) {
      if (minimum > 0) {
        return min(yupInstance, minimum);
      } else {
        return required(yupInstance);
      }
    } else {
      if (minimum) {
        return min(yupInstance, minimum);
      } else {
        return yupInstance;
      }
    }
  };

  // Required checkbox
  const requiredCheckbox = (yupInstance: any, errorMessage: string = 'required') =>
    yupInstance.oneOf([true], t(`${errorMessage}`));

  // Is number
  const number = (yupInstance: any, errorMessage: string = 'mustBeNumber') =>
    yupInstance.typeError(t(`${errorMessage}`));

  // Is date
  const date = (yupInstance: any, errorMessage: string = 'mustBeDate') =>
    yupInstance.typeError(t(`${errorMessage}`));

  // One field to match other
  const fieldsMatch = (yupInstance: any, firstFieldName: string, errorMessage: string) =>
    yupInstance.oneOf([yup.ref(firstFieldName), null], t(`${errorMessage}`));

  // Field matches regex
  const matchesRegex = (yupInstance: any, regex: any, errorMessage: string) =>
    yupInstance.matches(regex, t(`${errorMessage}`));

  const allowedCharsWithListAllowedChars = (
    yupInstance: any,
    chars: any,
    errorMessage: string = 'allowedCharsWithListAllowedChars',
  ) => yupInstance.matches(new RegExp(`^${chars}+$`, 'i'), errorMessage);

  const allowedCharsWithListAllowedCharsWithoutPlus = (
    yupInstance: any,
    chars: any,
    errorMessage: string = 'allowedCharsWithListAllowedChars',
  ) => yupInstance.matches(new RegExp(`^${chars}$`, 'i'), errorMessage);

  const allowedChars = (
    yupInstance: any,
    chars: any,
    errorMessage: string = 'allowedCharsWithListAllowedChars',
  ) => yupInstance.matches(new RegExp(`^${chars}$`, 'i'), errorMessage);

  // min character/item array count
  const min = (yupInstance: any, minCount: number, errorMessage: string = 'minLength') =>
    yupInstance.min(minCount, t(`${errorMessage}`, { n: minCount }));

  const minNotRequired = (yupInstance: any, minCount: number, errorMessage: string = 'minLength') =>
    yupInstance
      .transform((value: any) => value || null)
      .min(minCount, t(`${errorMessage}`, { n: minCount }))
      .nullable();

  // max character count
  const max = (yupInstance: any, maxCount: number, errorMessage: string = 'maxLength') =>
    yupInstance.max(maxCount, t(`${errorMessage}`, { n: maxCount }));

  // dateToCanNotBeBeforeDateFrom
  const dateComparison = (
    yupInstance: any,
    firstFieldName: string,
    errorMessage: string = 'dateToCanNotBeBeforeDateFrom',
  ) => yupInstance.min(yup.ref(firstFieldName), t(`${errorMessage}`));

  const notFuture = (yupInstance: any, errorMessage: string = 'notFuture') =>
    yupInstance.max(new Date(), t(`${errorMessage}`));

  // time in format hh:mm
  const time = (value: any) =>
    value && !/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/i.test(value) ? 'time' : undefined;

  // time in format hh:mm:ss
  const timeWithSecond = (value: any) =>
    value && !/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/i.test(value)
      ? 'timeWithSecond'
      : undefined;

  const withoutDiacritics = (yupInstance: any, errorMessage: string = 'withoutDiacritics') =>
    yupInstance.matches(/^[a-zA-Z0-9]+$/i, {
      message: t(`${errorMessage}`),
      excludeEmptyString: true,
    });

  const allCharsWithoutDiacritics = (
    yupInstance: any,
    errorMessage: string = 'allCharsWithoutDiacritics',
  ) =>
    yupInstance.matches(/^[^À-ž]+$/i, {
      message: t(`${errorMessage}`),
      excludeEmptyString: true,
    });

  const withoutSpecialChars = (yupInstance: any, errorMessage: string = 'withoutSpecialChars') =>
    yupInstance.matches(/^[a-zA-ZÀ-ž0-9 ]+$/i, {
      message: t(`${errorMessage}`),
      excludeEmptyString: true,
    });

  // /^\(([0-9]{4}),([0-9]{4})\)$/i
  const dicomTag = (yupInstance: any, errorMessage: string = 'dicomTag') =>
    yupInstance.matches(/^\(([0-9A-F]{4}),([0-9A-F]{4})\)$/, {
      message: t(`${errorMessage}`),
      excludeEmptyString: true,
    });

  const hexaColor = (yupInstance: any, errorMessage: string = 'hexaColor') =>
    yupInstance.matches(/^#(?:[0-9a-f]{3}){1,2}$/i, {
      message: t(`${errorMessage}`),
      excludeEmptyString: true,
    });

  const unique = (yupInstance: any, keyValues: any, errorMessage: string = 'duplicateKey') =>
    yupInstance.notOneOf(keyValues, t(`${errorMessage}`));

  // positive number
  const positive = (yupInstance: any, errorMessage: string = 'positiveWithoutZero') =>
    yupInstance.positive(t(`${errorMessage}`));
  // integer number
  const integer = (yupInstance: any, errorMessage: string = 'integer') =>
    yupInstance.integer(t(`${errorMessage}`));

  const testLengthUnder60Hours = (yupInstance: any, errorMessage: string = 'lengthUnder60Hours') =>
    yupInstance.matches(/^([0-5][0-9]):([0-5][0-9]):([0-5][0-9])$/i, {
      message: t(`${errorMessage}`),
      excludeEmptyString: true,
    });

  // Required and allowed chars with listAllowedChars
  const requiredAllowedCharsWithListAllowedChars = (yupInstance: any, obj: any) => {
    if (obj.isRequired) {
      return allowedCharsWithListAllowedChars(
        min(required(yupInstance), obj.minimum),
        obj.allowedChars,
        obj.message,
      );
    } else {
      return yupInstance
        .notRequired()
        .nullable()
        .test(obj.name, obj.message, function (value: any) {
          if (!!value) {
            const schema = yupInstance.min(obj.minimum).matches(obj.matches);
            return schema.isValidSync(value);
          }
          return true;
        });
    }
  };
  // Required and allowed chars
  const requiredAllowedChars = (yupInstance: any, obj: any) => {
    if (obj.isRequired) {
      return allowedChars(min(required(yupInstance), obj.minimum), allowedChars, obj.message);
    } else {
      return yupInstance
        .notRequired()
        .nullable()
        .test(obj.name, obj.message, function (value: any) {
          if (!!value) {
            const schema = yupInstance.min(obj.minimum).matches(obj.matches);
            return schema.isValidSync(value);
          }
          return true;
        });
    }
  };

  // Required and allowed chars
  const requiredEnabledNotFutureObject = (
    yupInstance: any,
    obj: any,
    errorMessage: string = 'required',
  ) => {
    let response = {};
    if (obj.isRequired && obj.isEnabled) {
      response = { [obj.fieldName]: notFuture(date(yupInstance, errorMessage)) };
    } else if (obj.isRequired) {
      response = { [obj.fieldName]: date(yupInstance, errorMessage) };
    } else if (obj.isEnabled) {
      response = { [obj.fieldName]: notFuture(yupInstance) };
    }
    return response;
  };
  // Required
  const requiredOrNotObject = (yupInstance: any, obj: any, errorMessage: string = 'required') => {
    if (obj.isRequired) {
      return { [obj.fieldName]: required(yupInstance, errorMessage) };
    } else {
      return {};
    }
  };

  // Required
  const requiredOrNotDateTimeObject = (
    yupInstance: any,
    obj: any,
    errorMessage: string = 'required',
  ) => {
    if (obj.isRequired) {
      return { [obj.fieldName]: date(yupInstance, errorMessage) };
    } else {
      return {};
    }
  };

  const requiredItemsFromObjectOrNotObject = (
    yupInstance: any,
    fields: any[],
    errorMessage: string = 'required',
  ) => {
    const requiredFields = filter(fields, { required: true });
    let preparedObject = {};
    if (requiredFields) {
      requiredFields.forEach((field: any) => {
        preparedObject = { ...preparedObject, [field.name]: required(yupInstance, errorMessage) };
      });
      return preparedObject;
    } else {
      return {};
    }
  };

  const allMatches = (allowedChars: string, withRepeat: boolean = false) =>
    new RegExp(`^${allowedChars}${withRepeat ? '+' : ''}$`, 'i');

  // Required and allowed chars with listAllowedChars
  const requiredAllowedCharsWithListAllowedCharsWithoutPlus = (yupInstance: any, obj: any) => {
    const message = t('allowedCharsWithListAllowedChars', { par1: obj.allowedChars });
    if (obj.required) {
      return allowedCharsWithListAllowedCharsWithoutPlus(
        required(yupInstance),
        obj.allowedChars,
        message,
      );
    } else {
      return yupInstance
        .notRequired()
        .nullable()
        .test(obj.name, message, function (value: any) {
          if (!!value) {
            const schema = yupInstance.matches(allMatches(obj.allowedChars, false));
            return schema.isValidSync(value);
          }
          return true;
        });
    }
  };

  const requiredAllItemsFromObjectOrNotObject = (yupInstance: any, fields: any[]) => {
    let preparedObject = {};
    if (fields) {
      fields.forEach((field: any) => {
        preparedObject = {
          ...preparedObject,
          [field.name]: requiredAllowedCharsWithListAllowedCharsWithoutPlus(yupInstance, field),
        };
      });
      return preparedObject;
    } else {
      return {};
    }
  };

  const ifModalitiesNotEmptyRequireAdditionalField = (
    yupArray: () => any,
    fieldsAccessor: (this: yup.TestContext) => any,
    errorMessage: string = 'additionalFieldRequired',
  ) => {
    return (
      yupArray()
        .min(1)
        .nullable()
        // Transforming empty array to null, otherwise validation fails when user unselect all modalities
        .transform((value: any, originalValue: string | any[]) =>
          Array.isArray(originalValue) && originalValue.length === 0 ? null : value,
        )
        .test('modalities', t(`${errorMessage}`), function (this: yup.TestContext) {
          const modalities = this.parent.modalities;
          if (!modalities || modalities.length === 0) {
            // If modalities is empty, we pass the validation
            return true;
          }
          const fields = fieldsAccessor.call(this);
          const arrayValues = Object.values(fields);
          return arrayValues.some((value) => value !== null && value !== '');
        })
    );
  };

  // Min and required and allowed chars with listAllowedChars
  const requiredMinAllowedChars = (yupInstance: any, obj: any) => {
    const message = t('allowedCharsWithListAllowedChars', { par1: obj.allowedChars });
    return allowedCharsWithListAllowedChars(
      minNotRequired(obj.required ? required(yupInstance) : yupInstance, obj.min),
      obj.allowedChars,
      message,
    );
  };

  const checkItemsOnRequiredMinAllowedChars = (yupInstance: any, fields: any[]) => {
    let preparedObject = {};
    if (fields) {
      fields.forEach((field: any) => {
        preparedObject = {
          ...preparedObject,
          [field.name]: requiredMinAllowedChars(yupInstance, field),
        };
      });
      return preparedObject;
    } else {
      return {};
    }
  };

  return {
    max,
    min,
    minNotRequired,
    date,
    email,
    number,
    required,
    fieldsMatch,
    matchesRegex,
    dateComparison,
    requiredCheckbox,
    withoutDiacritics,
    allCharsWithoutDiacritics,
    withoutSpecialChars,
    requiredIfMinimum,
    dicomTag,
    hexaColor,
    unique,
    integer,
    positive,
    allowedChars,
    allowedCharsWithListAllowedChars,
    notFuture,
    time,
    timeWithSecond,
    testLengthUnder60Hours,
    requiredAllowedCharsWithListAllowedChars,
    requiredAllowedChars,
    requiredEnabledNotFutureObject,
    requiredOrNotObject,
    requiredOrNotDateTimeObject,
    requiredItemsFromObjectOrNotObject,
    requiredAllItemsFromObjectOrNotObject,
    ifModalitiesNotEmptyRequireAdditionalField,
    checkItemsOnRequiredMinAllowedChars,
  };
};

export default useValidators;

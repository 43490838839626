import React from 'react';
import { useTranslation } from 'react-i18next';

import { IPriorityButtons } from './_types';
import { CheckedButton } from 'components/Buttons/CheckedButton';

export const PriorityButtons: React.FC<IPriorityButtons> = ({ priority, setPriority }) => {
  const { t } = useTranslation('Studies');
  const buttons = [
    { id: 1, label: t('HIGH') },
    { id: 2, label: t('MEDIUM') },
    { id: 3, label: t('LOW') },
  ];
  const renderedButtons = buttons.map((button: any) => (
    <CheckedButton
      key={button.id}
      active={priority === button.id}
      leftMargin={false}
      rightMargin={true}
      onClick={(e: any) => {
        setPriority(priority === button.id ? null : button.id);
      }}
      onDoubleClick={(e: any) => {
        setPriority(priority === button.id ? null : button.id);
      }}
      id={button.id}
    >
      {t(button.label)}
    </CheckedButton>
  ));

  return <>{renderedButtons}</>;
};

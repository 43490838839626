import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { resources } from './languages';

i18n
  .use(initReactI18next)
  .use(XHR)
  .init({
    lng: 'cs',
    fallbackLng: 'cs',
    defaultNS: 'translation',
    resources: resources,

    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: false,
      defaultTransParent: 'div',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  });

export default i18n;

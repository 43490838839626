import React from 'react';
import { isFunction } from 'lodash';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { IOverflowedDialog } from './_types';

const OverflowedDialog: React.FC<IOverflowedDialog> = ({
  children,
  title,
  withScrolling,
  contentStyle,
  dialogStyle = {},
  toggleDialog,
  dialogTitleActions,
  actions,
  actionsSx = {},
  ...rest
}) => {
  return (
    <>
      <Dialog {...rest} sx={dialogStyle}>
        {(title || dialogTitleActions) && (
          <DialogTitle id="form-dialog-title">
            <Grid
              container={true}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {title}
              {isFunction(toggleDialog) && (
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    toggleDialog(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
            {dialogTitleActions}
          </DialogTitle>
        )}
        <DialogContent style={contentStyle}>{children}</DialogContent>
        {actions && <DialogActions sx={actionsSx}>{actions}</DialogActions>}
      </Dialog>
    </>
  );
};

export default OverflowedDialog;

import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = () => {
  const { email, required } = useValidators();

  const ResetPasswordFormSchema = yup.object().shape({
    email: email(required(yup.string())),
  });

  return { ResetPasswordFormSchema };
};

export default useValidationSchema;

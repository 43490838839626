import React, { useEffect, useState } from 'react';
import { compact, get, isEmpty, isEqual, pick, sortBy, sum } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, DialogContentText, Grid } from '@mui/material';
import { createForMoveToTrash, editRequestForMoveToTrash } from './_api';
import { IRequestsMoveToTrashDetail, IStudiesMoveToTrashForm } from './_types';
import RequestAdditionalForm from 'modules/Studies/Form/RequestAdditionalForm';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { useSeriesViewer } from 'components/SeriesViewer/useSeriesViewer';
import { SeriesViewer } from 'components/SeriesViewer/SeriesViewer';
import { Papeer } from 'components/Papeer/Papeer';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { generateIID, sortSeriesBySeriesNumber } from 'utils/study';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { useUser } from 'utils/hooks/useUser';
import { useWithTitle } from 'utils/hooks/useWithTitle';

const RequestsMoveToTrashDetail: React.FC<IRequestsMoveToTrashDetail> = ({
  isRequestEdit = false,
  request = {},
}) => {
  const { t } = useTranslation('Studies');
  useWithTitle(); // sets title to document
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { moveToTrahStore } = useStudyInfo();
  const { user } = useUser();
  const studiesFromStore = moveToTrahStore;
  const navigate = useNavigate();

  const { studiesForViewer, setStudiesForViewer, operations, somethingSelected } =
    useSeriesViewer();

  let [searchParams] = useSearchParams();
  const detailIID = searchParams.get('backTo') || null;
  const linkBack = detailIID ? `/study/${detailIID}` : `/requests`;

  const [titleText, setTitleText] = useState<string>('');
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [fullSelectedStudies, setFullSelectedStudies] = useState<any>(false);
  const [numberOfSelectedStudies, setNumberOfSelectedStudies] = useState<number>(0);
  const [numberOfSelectedSeries, setNumberOfSelectedSeries] = useState<number>(0);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [initVal, setInitVal] = useState<any>(null);

  const prepareInitVal = (initVal: any) => {
    const prepareInitialValues = {
      ...(initVal
        ? {
            ...initVal,
            requestComment: get(watchAllFields, 'requestComment', ''),
          }
        : {
            ...watchAllFields,
          }),

      requestingUser: {
        name: {
          lastName: get(user, 'last_name'),
          firstName: get(user, 'first_name'),
        },
      },
    };
    return prepareInitialValues;
  };
  const callReset = (initVal: any) => {
    reset(initVal);
  };

  const methods = useForm<IStudiesMoveToTrashForm>({ mode: 'onChange' });
  const { handleSubmit, reset, watch, trigger } = methods;

  const watchAllFields = watch();

  useEffect(() => {
    const titleText = t(
      isRequestEdit ? 'studyMoveToTrashTitleRequestEdit' : 'studyMoveToTrashTitleRequest',
    );
    setTitleText(titleText);
    const studies: any[] = isRequestEdit
      ? get(request, 'data.studies', []).map((study: any) => {
          const listOfSeries = get(study, 'listOfSeries', null);
          return {
            ...study,
            iid: generateIID(study),
            listOfSeries: listOfSeries ? sortSeriesBySeriesNumber(listOfSeries) : null,
          };
        })
      : studiesFromStore;
    if (!isRequestEdit && studies.length < 1) {
      return navigate(linkBack);
    }
    setStudiesForViewer(
      studies.map((study: any) => ({
        ...study,
        studyIsSelected: true,
        studyIsOpened: false,
        loadedSeries: [],
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const canSubmit: boolean = somethingSelected > 0 ? true : false;
    setCanSubmit(canSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [somethingSelected]);

  useEffect(() => {
    const fullSelectedStudies = compact(
      studiesForViewer.map((study: any) => {
        if (!study.studyIsSelected) {
          return false;
        }
        return {
          ...study,
          listOfSeries: study?.listOfSeries,
        };
      }),
    );

    const numberOfSelectedSeries = fullSelectedStudies.map((study) => {
      let numberOfSelectedSeriesSum = 0;
      const selectedSeriesByStudy = compact(get(study, 'listOfSeries', [])).length;
      const seriesByStudy =
        selectedSeriesByStudy > 0
          ? selectedSeriesByStudy
          : get(study, 'numberOfStudyRelatedSeries', 0);
      numberOfSelectedSeriesSum += seriesByStudy * 1;
      return numberOfSelectedSeriesSum;
    });
    setNumberOfSelectedStudies(fullSelectedStudies.length);
    setNumberOfSelectedSeries(sum(numberOfSelectedSeries));
    setFullSelectedStudies(fullSelectedStudies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [somethingSelected]);

  const getInitialValues = (studies: any) => {
    const initVal = { studies };
    return initVal;
  };

  useEffect(() => {
    if (!isEmpty(studiesForViewer)) {
      if (studiesForViewer.length > 0) {
        const initialValues = prepareInitVal(getInitialValues(studiesForViewer));
        setInitVal(initialValues);
        callReset(initialValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studiesForViewer.length]);

  const handleFormSubmit = async (formValues: any) => {
    toggleLoader();
    const requestComment = get(formValues, 'requestComment', null) || null;

    const preparedStudies = compact(fullSelectedStudies).map((study: any) => ({
      ...study,
      listOfSeries: compact(get(study, 'listOfSeries', null)),
    }));

    try {
      const resp = await createForMoveToTrash({ studies: preparedStudies, requestComment });
      if (resp) {
        addSuccessAlert(t('requestForMoveToTrashAdded'));
        navigate(linkBack);
      } else {
        addErrorAlert(t('errorCreatingRequest'));
      }
    } catch (e) {
      addErrorAlert(t('errorCreatingRequest'));
    }
    toggleLoader(false);
  };

  const handleRequestEdit = async (values: any) => {
    toggleLoader();
    const requestComment = get(values, 'requestComment', null) || null;
    const studies = compact(fullSelectedStudies).map((study: any) => {
      const listOfSeries = compact(get(study, 'listOfSeries', null));
      return { ...study, listOfSeries: isEmpty(listOfSeries) ? null : listOfSeries };
    });

    const data = { studies };

    const editedRequest = {
      ...request,
      data: {
        ...request.data,
        ...data,
      },
      requestComment,
    };

    let numberOfChanges = 0;
    if (!isEmpty(requestComment)) {
      numberOfChanges++;
    }
    const sourceListOfStudies = get(initVal, 'studies', []).map((study: any) => {
      const noSortListOfSeries = get(study, 'listOfSeries', []);
      const listOfSeries =
        noSortListOfSeries === null
          ? []
          : sortBy(
              noSortListOfSeries.map((serie: any) => pick(serie, ['uid'])),
              ['numberSerie'],
            );
      return { ...pick(study, ['studyInstanceUid']), listOfSeries };
    });
    const targetListOfStudies = studies.map((study: any) => {
      const noSortListOfSeries = get(study, 'listOfSeries', []);
      const listOfSeries =
        noSortListOfSeries === null
          ? []
          : sortBy(
              noSortListOfSeries.map((serie: any) => pick(serie, ['uid'])),
              ['numberSerie'],
            );
      return { ...pick(study, ['studyInstanceUid']), listOfSeries };
    });
    if (!isEqual(sourceListOfStudies, targetListOfStudies)) {
      ++numberOfChanges;
    }
    if (numberOfChanges === 0) {
      addErrorAlert(t('errorNoChange'));
    } else {
      await editRequestForMoveToTrash(editedRequest).then(
        (response) => {
          addSuccessAlert(t(`requestEdited`));
          navigate(linkBack);
        },
        (error) => {
          addErrorAlert(t(`errorEditingRequest`));
        },
      );
    }
    toggleLoader(false);
  };

  const callHandleSubmit = async (values: any) => {
    isRequestEdit ? handleRequestEdit(values) : handleFormSubmit(values);
  };

  const onSubmit = handleSubmit(async (values: IStudiesMoveToTrashForm) => {
    callHandleSubmit(values);
  });

  const submitButtonText = t(
    isRequestEdit ? `editRequestFor.moveToTrash` : `setRequestFor.moveToTrash`,
  );

  const handleClickOpen = async () => {
    const result = await trigger();
    if (result) {
      setConfirmDialog(true);
    }
  };

  const sendingConfirmed = () => {
    setConfirmDialog(false);
    callHandleSubmit(watchAllFields);
  };

  const renderedDialog = (
    <OverflowedDialog
      title={t(`${isRequestEdit ? 'requestEdit' : 'requestCreate'}.moveToTrash.confirmation`)}
      open={confirmDialog}
      onClose={() => {
        setConfirmDialog(false);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
      actions={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '@media print': {
              display: 'none',
            },
          }}
        >
          <Button
            onClick={() => setConfirmDialog(false)}
            variant="contained"
            size="large"
            color="inherit"
            sx={{ mr: 1 }}
          >
            {t('close')}
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={sendingConfirmed}
            data-selenium-selector="edit-confirm-button"
          >
            {t('confirm')}
          </Button>
        </Box>
      }
    >
      <DialogContentText id="alert-dialog-description">
        {t('selectedStudiesAndSeries')}
        {`${numberOfSelectedStudies} / ${numberOfSelectedSeries}`}
      </DialogContentText>
    </OverflowedDialog>
  );

  return (
    <>
      <Header title={titleText} backUrl={`${linkBack}?action=back`} />
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Box sx={{ mt: 2 }}>
            <Papeer>
              <SeriesViewer
                studies={studiesForViewer}
                showStudiesCheckboxes={true}
                showSeriesCheckboxes={false}
                operations={operations}
              />
            </Papeer>
          </Box>
          <Grid container={true} spacing={2} sx={{ mt: 1 }}>
            <Grid item={true} xs={12}>
              <RequestAdditionalForm isRequest={true} initialValues={{ initVal }} />
            </Grid>
          </Grid>
          <Grid container={true} justifyContent="flex-end">
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                size="large"
                disabled={!canSubmit}
                data-selenium-selector="copy-submit-button"
                sx={{ mt: 2 }}
              >
                {submitButtonText}
              </Button>
            </Box>
          </Grid>
        </form>
      </FormProvider>
      {renderedDialog}
    </>
  );
};

export default RequestsMoveToTrashDetail;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { getIndexForUser } from '../NotificationSettings/_api';
import { get, isArray, pick, sortBy } from 'lodash';
import { Papeer } from 'components/Papeer/Papeer';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NotificationSettingsDialog from '../NotificationSettings/NotificationSettingsDialog';
import { SmallButton } from 'components/Form/SmallButton/SmallButton';

const UserProfileNotificationsComponent: React.FC = () => {
  const { t } = useTranslation('Users');

  const { toggleLoader } = useAppGlobals();
  const [notifications, fetchNotifications] = useState<any[]>([]);
  const [notificationItem, setNotificationItem] = useState<any>(null);
  const [dialogState, setDialogState] = useState<boolean>(false);

  const handleClickOpen = () => setDialogState(true);
  const handleClose = () => setDialogState(false);

  const getNotifications = async () => {
    toggleLoader();
    const notifications = await getIndexForUser();
    if (isArray(notifications)) {
      const mappedNotifications = notifications.map((notification: any, index: number) => ({
        ...pick(notification, ['id', 'eventType', 'reactionType']),
        title: t(get(notification.eventType, 'languageKey', '') as string),
        className: 'button-bg-unselected',
      }));
      fetchNotifications(sortBy(mappedNotifications, ['title']));
    } else {
      fetchNotifications([]);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {notifications.length ? (
        <Papeer>
          <Grid container={true} spacing={2}>
            {notifications.map((notification: any) => (
              <Grid item={true} xs={12} md={6} lg={4} key={notification.id}>
                <Card>
                  <CardHeader
                    subheader={t(get(notification.eventType, 'languageKey', ''))}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'dark'
                          ? theme.palette.grey[700]
                          : theme.palette.grey[300],
                    }}
                  />
                  <CardContent
                    sx={{
                      textAlign: 'center',
                      height: 40,
                      boxSizing: 'content-box',
                      pb: 2,
                    }}
                  >
                    <SmallButton
                      variant="contained"
                      onClick={() => {
                        setNotificationItem(notification);
                        handleClickOpen();
                      }}
                      title={t('userProfileSetNotification')}
                    >
                      <EditIcon sx={{ mr: 1 }} />
                      {t(get(notification.reactionType, 'languageKey', ''))}
                    </SmallButton>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            {dialogState && (
              <NotificationSettingsDialog
                closeDialog={handleClose}
                notificationItem={notificationItem}
                getNotifications={getNotifications}
              />
            )}
          </Grid>
        </Papeer>
      ) : null}
    </>
  );
};

export default UserProfileNotificationsComponent;

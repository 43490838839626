import { get } from 'lodash';

export const sortLocaleCompare = (a: any, b: any) => {
  return (a === null || a === undefined ? '' : a)
    .toLowerCase()
    .localeCompare((b === null || b === undefined ? '' : b).toLowerCase());
};

export const sortAlg = (a: any, b: any) => {
  return a
    .replace('(CZ) ', '')
    .replace('(SK) ', '')
    .toLowerCase()
    .localeCompare(b.replace('(CZ) ', '').replace('(SK) ', '').toLowerCase());
};

export const sortCollectionByItemName = (collection: any, itemName: string) =>
  (collection || []).sort((f1: any, f2: any) =>
    get(f1, itemName).toLowerCase().localeCompare(get(f2, itemName).toLowerCase()),
  );

import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';

export const getCountOfProposals = (): Promise<any | boolean> =>
  api()
    .get(`/portal-api/shreddingRecord/getCountOfProposals`, {})
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getProposals = (dateFrom: string, dateTo: string): Promise<boolean> =>
  api()
    .get(`/portal-api/shreddingRecord/getProposals`, { params: { dateFrom, dateTo } })
    .then((response) => get(response, 'data', false))
    .catch((error) => false);

export const getConfirmed = (params: any): Promise<boolean> =>
  api()
    .get(`/portal-api/shreddingRecord/getConfirmed`, { params })
    .then((response) => get(response, 'data', false))
    .catch((error) => false);

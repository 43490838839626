import { csCZ, enUS } from '@mui/x-data-grid-pro/locales';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'utils/hooks/useLanguage';

const useGridLocalization = () => {
  const { currentLocale } = useLanguage();
  const { t } = useTranslation();

  return {
    ...(currentLocale === 'en'
      ? enUS.components.MuiDataGrid.defaultProps.localeText
      : csCZ.components.MuiDataGrid.defaultProps.localeText),
    detailPanelToggle: t('Studies:listOfSeries'),
  };
};

export default useGridLocalization;

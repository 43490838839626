import React, { useEffect } from 'react';
import { get, isArray } from 'lodash';
import { CssBaseline, GlobalStyles } from '@mui/material';

import { IStart } from './_types';
import { useUser } from 'utils/hooks/useUser';
import { useToken } from 'utils/hooks/useToken';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { AppVersionCheck } from 'components/AppVersionCheck/AppVersionCheck';
import { useValidators } from 'utils/hooks/useValidators';
import { getRoles, getValidators } from 'modules/Login/_api';
import { THEME_MODE_DARK, THEME_MODE_LIGHT } from 'constants/constants';
import Alerts from 'components/Alerts/Alerts';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useTranslation } from 'react-i18next';
import { stateIsSame } from 'utils/componentOptimizatons';
import { SwitchForLogged } from './SwitchForLogged';
import { IDecodedToken } from 'modules/Login/_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { getFrontendConfig } from 'utils/config/_api';
import queryString from 'query-string';
import { getFrontendConfigFromApi, getPasswordPolicyConfig, parseToken } from './_api';
import { putItem, removeItem } from 'utils/hooks/useStorage';
import { useNavigate } from 'react-router';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useAppDispatch } from 'store/hooks';
import { setConfig, setConfigFromApi, setPasswordPolicyConfig } from 'store/reducers/appReducer';

const PlainStart: React.FC<IStart> = () => {
  const { t, i18n } = useTranslation('Homepage');
  const { user, roles, idleTimeToLogout, loadUserToStore, loadRolesToStore, unloadUserFromStore } =
    useUser();
  const { validators, isDarkMode } = useAppInfo();
  const { tokenDecoder } = useToken();
  const { storageValidators, loadValidatorsToStore } = useValidators();
  const decodedToken = tokenDecoder();
  const { setMode } = useAppGlobals();
  const { setPageTitle } = useWithTitle();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentLocale } = useLanguage();
  setPageTitle(t('homepage'));

  useEffect(() => {
    const htmlTag = document.getElementsByTagName('html')[0];
    const htmlTagLang = htmlTag.getAttribute('lang');
    if (htmlTagLang !== currentLocale) {
      htmlTag.setAttribute('lang', currentLocale);
    }
    i18n.changeLanguage(currentLocale);
  }, [currentLocale, i18n]);

  const getAllRolesForUser = async () => {
    const newRoles = await getRoles();
    if (!newRoles) {
      removeItem('token');
    }
    loadRolesToStore(newRoles);
  };
  const getAllValidators = async () => {
    const validators = await getValidators();
    loadValidatorsToStore(validators);
    putItem('validators', JSON.stringify(validators));
  };

  useEffect(() => {
    try {
      if (
        (!decodedToken || decodedToken.expired) &&
        window.location.href.indexOf('/portal/login') === -1 &&
        window.location.href.indexOf('/portal/passwordRecovery') === -1 &&
        window.location.href.indexOf('?token=') === -1
      ) {
        window.location.href = '/portal/login';
      } else {
        if (!user.isAuthorized) {
          loadUserToStore(decodedToken);
        }
        if (!isArray(validators) || validators.length === 0) {
          if (storageValidators) {
            loadValidatorsToStore(storageValidators);
          } else {
            getAllValidators();
          }
        }
        if (!isArray(roles) || roles.length === 0) {
          getAllRolesForUser();
        }
      }
    } catch (e) {
      console.debug(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isAuthorized, decodedToken?.expired]);

  const getFeConfigAndStore = async () => {
    const config = await getFrontendConfig();
    dispatch(setConfig(config));
  };

  const getFeConfigFromApiAndStore = async () => {
    const config = await getFrontendConfigFromApi();
    dispatch(setConfigFromApi(get(config, 'config')));
  };

  const getPasswordPolicyConfigAndStore = async () => {
    const config = await getPasswordPolicyConfig();
    dispatch(setPasswordPolicyConfig(config));
  };

  const redirectToStudyDetail = async () => {
    // otevření detailu studie/studií z tokenu
    if (window.location.href.indexOf('?token=') !== -1) {
      const token = get(queryString.parse(window.location.search), 'token', null);
      const parsedToken = await parseToken(token);
      if (parsedToken) {
        putItem('token', parsedToken.jwt);
        const newDecodedToken = tokenDecoder(parsedToken.jwt);
        loadUserToStore({
          ...newDecodedToken,
          fromToken: {
            function: get(parsedToken, 'function'),
            data: get(parsedToken, 'data', []),
          },
        });
      } else {
        removeItem('token');
        unloadUserFromStore();
        navigate('/login?error=badToken');
        // window.location.href = '/portal/login?error=badToken';
      }
    } else {
      if (
        (!decodedToken || decodedToken.expired) &&
        window.location.href.indexOf('/portal/login') === -1 &&
        window.location.href.indexOf('/portal/passwordRecovery') === -1
      ) {
        navigate('/portal/login');
      }
    }
  };

  useEffect(
    () => {
      try {
        getFeConfigAndStore();
        getFeConfigFromApiAndStore();
        getPasswordPolicyConfigAndStore();
      } catch (e) {}
      redirectToStudyDetail();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      setMode(decodedToken.darkMode ? THEME_MODE_DARK : THEME_MODE_LIGHT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const globalStyles = (
    <GlobalStyles
      styles={() => ({
        html: {
          colorScheme: isDarkMode ? 'dark' : 'light',
        },
      })}
    />
  );

  return (
    <>
      <AppVersionCheck />
      <CssBaseline />
      {globalStyles}
      <SwitchForLogged
        user={user}
        roles={roles}
        idleTimeToLogout={idleTimeToLogout}
        decodedToken={decodedToken as IDecodedToken}
      />
      <Alerts />
    </>
  );
};

export const Start = React.memo(PlainStart, stateIsSame);

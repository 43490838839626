export const getTranslation = (key: string, translations: any) => {
  const parts = key.split('.');
  let current = translations;

  for (const part of parts) {
    if (current[part]) {
      current = current[part];
    } else {
      return undefined;
    }
  }

  // If 'current' is an object and has a 'default' key, return the 'default' value.
  if (typeof current === 'object' && current.default) {
    return current.default;
  }

  return current;
};

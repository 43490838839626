import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgottenPassword } from './_api';
import useValidationSchema from './_form';
import { IResetPasswordForm, IResetPassword } from './_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import FormInput from 'components/Form/Input/Input';
import useAlerts from 'components/Alerts/useAlerts';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import Header from 'components/Header/Header';
import { get, isString } from 'lodash';

const ResetPasswordForm: React.FC<IResetPasswordForm> = ({ toggleDialog }) => {
  const { t } = useTranslation('Login');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const { ResetPasswordFormSchema } = useValidationSchema();

  const methods = useForm<IResetPassword>({
    resolver: yupResolver(ResetPasswordFormSchema),
  });
  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const response = await forgottenPassword({ email: get(values, 'email') });
    if (response === true) {
      addSuccessAlert(t('resetPasswordSend'));
      toggleDialog(false);
    } else {
      const errorCode = get(response, 'errorCode', '');
      addErrorAlert(
        t(
          errorCode && isString(errorCode)
            ? `error.passwordRecovery.${errorCode}`
            : 'resetPasswordSErrorSending',
        ),
      );
    }
    toggleLoader(false);
  });

  return (
    <>
      <Header title={t('resetPasswordTitle')} />
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Grid container={true} spacing={0}>
            <Grid item={true} xs={12}>
              <FormInput name="email" label={t('email')} type="text" required={true} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 1 }}>
            <EntityButtons toggleDialog={toggleDialog} confirmReplaceSave={true} />
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default ResetPasswordForm;

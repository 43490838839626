import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';

export const getDataForEditUserNotificationTemplate = (eventTypeId: string): Promise<any> =>
  api()
    .get(`/portal-api/notification/templates/getDataForEditUserNotificationTemplate`, {
      params: { eventTypeId },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const saveUserNotificationTemplate = (notificationTemplate: any): Promise<any> =>
  api()
    .post(`/portal-api/notification/templates/saveUserNotificationTemplate`, notificationTemplate)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getLists = (): Partial<Promise<any> | boolean> =>
  api()
    .get(`/portal-api/notification/templates/getLists`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getIndexForUser = (): Promise<any> =>
  api()
    .get(`/portal-api/notification/templates/indexForUser`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

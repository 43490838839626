import { get } from 'lodash';
import { Column } from '@devexpress/dx-react-grid';

export const generalQuestionColumns: Column[] = [
  { name: 'position', title: 'position' },
  { name: 'label', title: 'label' },
];
export const studyQuestionColumns: Column[] = [
  {
    name: 'registerItemGroup',
    title: 'group',
    getCellValue: (row) => {
      return get(row, 'registerItem.registerGroupName');
    },
  },
  {
    name: 'registerItemName',
    title: 'registerItem',
    getCellValue: (row) => {
      return get(row, 'registerItem.name');
    },
  },
  { name: 'questionsMin', title: 'questionsMin' },
  { name: 'questionsMax', title: 'questionsMax' },
];

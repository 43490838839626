import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useUser } from 'utils/hooks/useUser';

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { isArray } from 'lodash';
import { ISearchHeader } from './_types';
import { useDispatch } from 'react-redux';
import { setTourControlPropsToStore } from 'store/reducers/appReducer';

export const SearchHeader: React.FC<ISearchHeader> = ({
  searchWithFilter,
  // steps = [],
  // tourControlProps,
  // currentTourStep,
  // setCurrentTourStep,
  selectedFilter,
  setSelectedFilter,
  showFilterInHeader = false,
  showActionsInHeader = false,
  ownTitle = '',
  TourComponent,
}) => {
  const { t } = useTranslation('SearchForm');
  const navigate = useNavigate();
  const { hasRole } = useUser();
  const { filters } = useAppInfo();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();

  //For managing tour logic
  const handleOpen = (element: any) => {
    setTimeout(() => {
      dispatch(
        setTourControlPropsToStore({ isDropDownOpen: true, currentOpenDropdown: 'filterDropdown' }),
      );
      // tourControlProps?.setCurrentOpenDropdown('filterDropdown');
      // tourControlProps?.setIsDropDownOpen(true);
    }, 100);
  };

  const handleClose = (element: any) => {
    setTimeout(() => {
      dispatch(setTourControlPropsToStore({ isDropDownOpen: false, currentOpenDropdown: null }));

      // tourControlProps?.setCurrentOpenDropdown(null);
      // tourControlProps?.setIsDropDownOpen(false);
    }, 100);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: 1,
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{ownTitle || t('searchForm')}</Typography>
        {TourComponent}
        {/* <TourComponent steps={steps} tourControlProps={tourControlProps} /> */}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          gap: 1,
        }}
      >
        {showActionsInHeader && (
          <Button
            variant="contained"
            size="small"
            onClick={() => navigate('/exportsList')}
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            data-tour="search-header-export-list"
          >
            {t('exportList')}
          </Button>
        )}
        {showActionsInHeader && hasRole('ROLE_HISTORY_OF_SEND_STUDY') && (
          <Button
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            variant="contained"
            size="small"
            onClick={() => navigate('/histories/send')}
            data-tour="search-header-sent-history"
          >
            {t('historyList')}
          </Button>
        )}
        {showFilterInHeader && (
          <Box sx={{ width: { xs: '100%', sm: 200 } }} data-tour="search-header-filters">
            <FormControl size="small" fullWidth={true} margin="dense" sx={{ m: 0 }}>
              <InputLabel htmlFor="select-filters" sx={{ lineHeight: 1 }}>
                {t('filters')}
              </InputLabel>
              <Select
                id="select-filters"
                label={t('filters')}
                size="small"
                sx={{
                  background: theme.palette.background.paper,
                  height: '29px',
                  lineHeight: '29px',
                }}
                value={selectedFilter}
                onChange={(event: SelectChangeEvent) => {
                  setSelectedFilter(event.target.value as string);
                  searchWithFilter(parseInt(event.target.value, 10));
                }}
                MenuProps={{
                  classes: { paper: 'select-react-tour-class-filter' },
                }}
                {...(handleOpen && { onOpen: handleOpen })}
                {...(handleClose && { onClose: handleClose })}
              >
                {isArray(filters)
                  ? filters.map((filter) => (
                      <MenuItem key={filter.id} value={filter.id}>
                        {filter.name}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Box>
        )}
      </Box>
    </Box>
  );
};

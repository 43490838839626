import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { filter, get, isArray, pick } from 'lodash';

import { getAnnouncement } from './_api';
import { IAnnouncement } from './_types';
import { getAllWorkplaces } from '../Workplaces/_api';
import { ILanguage } from '../GeneralSettings/_types';
import { getAllLanguages } from '../GeneralSettings/_apiLanguages';

import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import AnnouncementFormComponent from './AnnouncementFormComponent';
import { ISelectItem } from 'components/Form/Select/_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useLanguage } from 'utils/hooks/useLanguage';

export const AnnouncementForm: React.FC = () => {
  const { t } = useTranslation('Announcements');
  const { toggleLoader } = useAppGlobals();
  const { id, isCreating } = useEntityInfo();

  const [entity, fetchEntity] = useState<IAnnouncement>();

  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [workplaceItems, getWorkplaceItems] = useState<any[]>();
  const [languages, setLanguages] = useState<any[]>([]);
  const [defaultValues, setDefaultValues] = useState({});
  const [importanceItems, setImportanceItems] = useState<ISelectItem[]>([]);

  const { currentLocale } = useLanguage();

  const getEntities = async () => {
    toggleLoader();
    try {
      let announcement: IAnnouncement = {};
      let languages = null;
      const languagesLists: ILanguage[] = await getAllLanguages();
      if (isArray(languagesLists)) {
        languages = filter(languagesLists, { active: true });
        setLanguages(languages);
      }
      if (!isCreating && id) {
        const entity = await getAnnouncement(id.toString());
        announcement = { ...entity };
        if (entity) {
          fetchEntity(entity);
        }
      }
      const workplaces = await getAllWorkplaces();
      if (isArray(workplaces)) {
        const workplaces2 = workplaces.map((item: any) => pick(item, ['id', 'text', 'code']));
        const workplaceItems = workplaces2
          ? workplaces2.map((workplace) => ({
              ...workplace,
              name: get(
                get(workplace, 'text', '') ? JSON.parse(get(workplace, 'text', '')) : {},
                currentLocale,
                workplace.code,
              ),
            }))
          : [];
        getWorkplaceItems(workplaceItems);
      }

      const items: any = [
        { id: 'INFO', label: t('importance.info') },
        { id: 'WARNING', label: t('importance.warning') },
        { id: 'DANGER', label: t('importance.danger') },
      ];
      setImportanceItems(items);

      let defaultValues = {};
      (languages || []).forEach((lang: any) => {
        const key = `languageJsonText_${get(lang, 'abbreviation')}`;
        const value = get(announcement, `languageJsonText.${lang.abbreviation}`, '');
        defaultValues = { ...defaultValues, [key]: value };
      });
      defaultValues = {
        ...defaultValues,
        importance: get(announcement, 'importance', null),
        validFrom: isCreating ? '' : new Date(get(announcement, 'validFrom', '')),
        validTo: isCreating ? '' : new Date(get(announcement, 'validTo', '')),
        workPlaces: isCreating ? workplaces : get(announcement, 'workPlaces', []),
      };
      setDefaultValues(defaultValues);
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isFetchingEntity && (
        <AnnouncementFormComponent
          isCreating={isCreating}
          entity={entity}
          workplaceItems={workplaceItems}
          languages={languages}
          importanceItems={importanceItems}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

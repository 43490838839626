import { compact, includes, isArray, isEqual, omit } from 'lodash';

import { useCallback, useMemo } from 'react';
import { IUser } from 'modules/Login/_types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { rolesLoad, userStore, userUnload } from 'store/reducers/userReducer';
import { useAppLoader } from './useAppLoader';

const cleanAndJoin = (items: string[]) => compact(items).join(' ');

export const useUser = () => {
  const dispatch = useAppDispatch();
  const { loadAllFiltersToStore } = useAppLoader();

  const user: IUser = useAppSelector(
    ({ user }) => user,
    // Potentially harmful, further inspection needed!
    (oldState, newState) => isEqual(omit(oldState, ['exp', 'iat']), omit(newState, ['exp', 'iat'])),
  );
  const roles = user.auth;

  const rolesAsArray = useMemo(() => {
    return isArray(roles) ? roles.map((item) => item.authority) : [];
  }, [roles]);

  const idleTimeToLogout = user.maxUserInactiveTimeout;
  const fromToken = user.fromToken;

  const loadUserToStore = (user: any) => dispatch(userStore(user));
  const unloadUserFromStore = () => dispatch(userUnload());
  const loadRolesToStore = (roles: any = {}) => dispatch(rolesLoad(roles));

  const fullName = cleanAndJoin([user.last_name, user.first_name]);
  const fullNameWithPrefixes = cleanAndJoin([
    user.prefix,
    user.last_name,
    user.first_name,
    user.middle_name,
    user.suffix,
  ]);

  const hasRole = useCallback((role: string) => includes(rolesAsArray, role), [rolesAsArray]);

  const getUserFilters = async () => {
    await loadAllFiltersToStore();
  };

  return {
    user,
    roles,
    fullName,
    hasRole,
    fromToken,
    darkMode: user.darkMode,
    authType: user.authType,
    rolesAsArray,
    fullNameWithPrefixes,
    loadUserToStore,
    loadRolesToStore,
    idleTimeToLogout,
    unloadUserFromStore,
    getUserFilters,
  };
};

import { useEffect, useState } from 'react';
import { compact, get, keys } from 'lodash';
import { useGridSettings } from 'components/SearchResultsMUI/useGridSettings';

export const useFilterableColumns = (gridKey: string, columns: any[]) => {
  const { getGridColumnsVisibility } = useGridSettings();

  const columnVisibilityModel = getGridColumnsVisibility(gridKey);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  // Filtered columns will be stored here. If the column is hidden, it cannot be filtered by
  const [filterableColumns, setFilterableColumns] = useState(columns);

  // Compute hidden columns not to be shown in filters
  useEffect(() => {
    const hiddenColumns = compact(
      keys(columnVisibilityModel).map((columnName) =>
        get(columnVisibilityModel, columnName) === false ? columnName : undefined,
      ),
    );
    setHiddenColumns(hiddenColumns);
  }, [columnVisibilityModel]);

  useEffect(() => {
    const updatedColumns = columns.map((column) => ({
      ...column,
      filterable:
        column.type !== 'actions' &&
        !hiddenColumns.includes(column.field) &&
        get(column, 'filterable', true),
    }));
    setFilterableColumns(updatedColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenColumns]);

  return filterableColumns;
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Icon, IconButton, Paper } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material/';
import { IButton, IButtonComponent, IButtonForm } from './_types';
import useValidationSchema from './_form';
import { createButton, editButton } from './_api';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormSwitch from 'components/Form/Switch/Switch';
import FormInput from 'components/Form/Input/Input';
import { Line } from 'components/Line/Line';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';

import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';

import { DATE_BTN_CUSTOM_DATE, GridDataType } from 'constants/constants';
import { Box } from '@mui/system';
import SearchFormInner from 'components/SeachForm/SearchFormInner';
import { useForm2 } from 'utils/hooks/useForm2';
import { TourButton } from './TourButton';

const linkBack = '/administration/announcementsAndButtons?tab=buttons';

const ButtonFormComponent: React.FC<IButtonComponent> = ({
  isCreating,
  entity,
  workplaceItems,
  languages,
  defaultValues,
  isSystemButton,
  sources,
}) => {
  const { t } = useTranslation('Buttons');
  const navigate = useNavigate();
  const { title } = useWithEntityTitle(isCreating, entity, t);
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { compactMode } = useAppInfo();
  const { camelToDash } = useForm2();

  const [showSearchForm, setShowSearchForm] = useState<boolean>(false);
  const [searchFieldOpened, setSearchFieldOpened] = useState(false);
  const [hasCustomDate, setHasCustomDate] = useState(false);

  const { ButtonFormSchema, formItems } = useValidationSchema(
    t,
    languages,
    isSystemButton,
    showSearchForm,
  );

  const methods = useForm<IButtonForm>({
    resolver: yupResolver(ButtonFormSchema),
    defaultValues,
  });
  const { handleSubmit, watch, setValue } = methods;
  const icon = watch('icon');
  const watchShowSearchForm: boolean = watch('showSearchForm');
  const dateValue = watch('studyDate');

  useEffect(() => {
    if (dateValue === DATE_BTN_CUSTOM_DATE) {
      setSearchFieldOpened(true);
      setHasCustomDate(true);
    } else {
      setHasCustomDate(false);
      setValue('study.dateFrom', null);
      setValue('study.dateTo', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue]);
  if (showSearchForm !== watchShowSearchForm) {
    setShowSearchForm(watchShowSearchForm);
  }
  useEffect(() => {
    if (icon) {
      // Zkonvertuje všechna velká písmena na malé písmeno s podtržítkem (bez podtržítka na začátku slova)
      // např. ikona: s názvem AddHomeWork bude zkonvertována na add_home_work
      const convertIcon = camelToDash(icon);
      if (convertIcon) {
        setValue('icon', convertIcon);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [icon]);

  const { toggleLoader } = useAppGlobals();

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const showSearchForm = get(values, 'showSearchForm', false);
    let languageJsonText = {};
    (languages || []).forEach((lang: any) => {
      const abbr = get(lang, 'abbreviation');
      const key = `languageJsonText_${abbr}`;
      const value = get(values, key, null);
      languageJsonText = { ...languageJsonText, [abbr]: value };
    });
    const requestJson = showSearchForm
      ? {
          patient: get(values, 'patient', {}),
          study: get(values, 'study', {}),
          modalities: get(values, 'modalities', []),
          studyDate: get(values, 'studyDate', ''),
          sources: get(values, 'sources', []),
          date: get(values, 'studyDate', ''),
          showInactiveGroups: get(values, 'showInactiveGroups', false),
          withoutTags: get(values, 'withoutTags', true),
          tags: get(values, 'tags', null),
        }
      : {};
    const button: IButton = {
      id: get(entity, 'id', 0),
      active: get(entity, 'active', true),
      languageJsonText: isSystemButton ? null : JSON.stringify(languageJsonText),
      requestJson: showSearchForm ? JSON.stringify(requestJson) : '',
      url: showSearchForm ? 'studies' : values.url,
      icon: get(values, 'icon', ''),
      workPlaces: get(values, 'workPlaces'),
      system: get(entity, 'system', false),
    };
    const foo = isCreating ? createButton : editButton;
    const response = await foo(button);
    if (response) {
      addSuccessAlert(t('saved'));
      navigate(linkBack);
    } else {
      addErrorAlert(t('errorSaving'));
    }
    toggleLoader(false);
  });

  const defaultGrid: GridDataType = { xs: 12, md: 6, lg: 3, xl: 2 };

  const renderedSteps = () => {
    return <TourButton />;
  };

  return (
    <>
      <Header title={title} />
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <FormSwitch name={'showSearchForm'} label={t('searchFilter')} />
          {showSearchForm && (
            <Paper style={{ marginBottom: '16px' }}>
              <Papeer>
                <SearchFormInner
                  sources={sources}
                  searchFieldOpened={searchFieldOpened}
                  setSearchFieldOpened={setSearchFieldOpened}
                  hasCustomDate={hasCustomDate}
                  useForOtherForm={true}
                  showResetButton={false}
                />
              </Papeer>
            </Paper>
          )}
          <Paper>
            <Papeer>
              <Grid container={true} spacing={2}>
                {formItems.map((formItem, index) => {
                  const customGrid = get(formItem, 'grid', false)
                    ? (get(formItem, 'grid') as unknown as GridDataType)
                    : defaultGrid;
                  const name = get(formItem, 'name');
                  return (
                    <React.Fragment key={`formItem-${index}`}>
                      {(name === 'icon' || name === 'url') && (
                        <Grid item={true} xs={12} style={{ padding: 0 }} />
                      )}
                      <Grid item={true} {...customGrid}>
                        {name === 'icon' ? (
                          <Box sx={{ position: 'relative' }} data-tour="buttonFormIcon">
                            <Box
                              sx={{
                                position: 'absolute',
                                right: compactMode ? 4 : 6,
                                top: compactMode ? 8 : 12,
                                fontSize: '1.5rem',
                              }}
                            >
                              <Icon baseClassName="material-icons">{icon}</Icon>
                            </Box>
                            <FormInput {...formItem} type="text" />
                          </Box>
                        ) : (
                          <FormInput {...formItem} type="text" />
                        )}
                      </Grid>
                      {name === 'icon' && (
                        <Grid item={true} {...customGrid}>
                          <Grid container={true} spacing={2}>
                            <Grid item={true} xs={6}>
                              <IconButton
                                size="small"
                                color="primary"
                                href="https://mui.com/material-ui/material-icons/"
                                target="_blank"
                                sx={{ mt: compactMode ? 1 : 1.5 }}
                              >
                                <SearchIcon /> {t('searchIcons')}
                              </IconButton>
                            </Grid>
                            <Grid item={true} xs={6} sx={{ mt: compactMode ? 1.2 : 1 }}>
                              <Box>{renderedSteps()}</Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </React.Fragment>
                  );
                })}
                <Grid item={true} xs={12}>
                  <Line />
                </Grid>
                <Grid item={true} xs={12}>
                  <SimpleCheckboxArray
                    name="workPlaces"
                    label={t('workplaces')}
                    items={workplaceItems}
                    defaultValue={get(defaultValues, 'workPlaces', [])}
                    grid={compactMode ? {} : { xs: 12, md: 6, lg: 2, xl: 2 }}
                    setAllCheckboxDisabled={!isCreating}
                  />
                </Grid>
              </Grid>
              <Grid item={true} xs={12}>
                <Line />
              </Grid>
              <EntityButtons linkBack={linkBack} />
            </Papeer>
          </Paper>
        </form>
      </FormProvider>
    </>
  );
};

export default ButtonFormComponent;

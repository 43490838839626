import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = () => {
  const { required } = useValidators();

  const PasswordRecoveryFormSchema = yup.object().shape({
    password: required(yup.string()),
    confirmPassword: required(yup.string()),
  });

  return { PasswordRecoveryFormSchema };
};

export default useValidationSchema;

import { useCallback } from 'react';
import { get, pickBy, difference, find, isArray } from 'lodash';
import { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';

import { binaryToDec } from 'utils/hooks/useApp';
import { getStudyAdditionalInfo } from 'modules/Search/_api';
import { IStudyResultForGrid } from 'modules/Search/_types';
import { IUseGridExtendedInfo } from './_types';
import { setColumnsInSearchGridWithAdditionalInfoBeingLoaded } from 'store/reducers/appReducer';
import { useAppDispatch } from 'store/hooks';
import { useActions } from 'utils/hooks/useActions';

const flagSettings = [
  { name: 'sendInfos', flagValue: 1 },
  { name: 'folderInfos', flagValue: 10 },
  { name: 'hasAttachment', flagValue: 100 },
  { name: 'stationAet', flagValue: 1000 },
  { name: 'tags', flagValue: 10000 },
  { name: 'comments', flagValue: 100000 },
  { name: 'shredding', flagValue: 1000000 },
  { name: 'folderHistory', flagValue: 10000000 },
];

export const useGridExtendedInfo = ({ rows, setRows }: IUseGridExtendedInfo) => {
  const { storeSearchResults } = useActions();
  const dispatch = useAppDispatch();
  const getLastEnabledColumn = useCallback(
    // key - např. studyMUI, preview, mwl,...
    (newModel: GridColumnVisibilityModel, oldModel: GridColumnVisibilityModel) => {
      const newMod = Object.keys(pickBy(newModel, (item: Boolean) => item === false)).map(
        (item: string) => item,
      );
      const oldMod = Object.keys(pickBy(oldModel, (item: Boolean) => item === false)).map(
        (item: string) => item,
      );
      return difference(oldMod, newMod);
    },
    [],
  );

  const getExtendedInfoFlag = useCallback((columns: any) => {
    let flag = 0;
    let columnsWithFlags: string[] = [];
    columns.forEach((column: any) => {
      const flagSetting = find(flagSettings, { name: column });
      if (flagSetting) {
        flag += flagSetting.flagValue;
        columnsWithFlags.push(flagSetting.name);
      }
    });
    return { flag, columnsWithFlags };
  }, []);

  const saveExtendedInfo = useCallback(
    (data: any, fromFolder: boolean) => {
      setRows((rows: IStudyResultForGrid[]) => {
        const extRows = [
          ...rows.map((row: IStudyResultForGrid) => {
            const findStudy = find(
              isArray(data) ? data : [],
              (study) =>
                get(row, 'studyInstanceUid', 1) === get(study, 'studyInstanceUid', 2) &&
                get(row, 'archive.id', 1) === get(study, 'archive.id', 2),
            );
            return {
              ...row,
              studyExtendedInfo: { ...get(row, 'studyExtendedInfo', {}), ...pickBy(findStudy) },
            };
          }),
        ];
        if (!fromFolder) {
          storeSearchResults(extRows);
        }
        return extRows;
      });
    },
    [setRows, storeSearchResults],
  );

  const loadExtendedInfo = useCallback(
    async (
      cols: any,
      rowsFromSearch: IStudyResultForGrid[] | boolean = false,
      fromFolder: boolean = false,
    ) => {
      const rowsToUse = isArray(rowsFromSearch) ? rowsFromSearch : rows;
      const { flag, columnsWithFlags } = getExtendedInfoFlag(cols);
      dispatch(setColumnsInSearchGridWithAdditionalInfoBeingLoaded(columnsWithFlags));
      if (flag > 0) {
        const resp = await getStudyAdditionalInfo({
          extendedInfoFlag: binaryToDec(flag),
          studies: (rowsToUse || []).map((row: any) => ({
            archive: { id: get(row, 'archive.id', null) },
            studyInstanceUid: get(row, 'studyInstanceUid', null),
          })),
        });
        dispatch(setColumnsInSearchGridWithAdditionalInfoBeingLoaded([]));
        saveExtendedInfo(resp, fromFolder);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, getExtendedInfoFlag, saveExtendedInfo],
  );

  // při změně viditelnosti sloupce se spustí funkce pro získání dalších informací
  const getExtendedInfoForColumn = useCallback(
    // key - např. studyMUI, preview, mwl,...
    async (newModel: GridColumnVisibilityModel, oldModel: GridColumnVisibilityModel) => {
      const cols = getLastEnabledColumn(newModel, oldModel);
      loadExtendedInfo(cols);
    },
    [getLastEnabledColumn, loadExtendedInfo],
  );

  return { getExtendedInfoForColumn, loadExtendedInfo };
};

import { get } from 'lodash';
import { IWebsocketNotification } from './_types';
import { api } from 'utils/hooks/useApi';
import { NOTIFICATION_ROW_COUNT } from 'constants/constants';

export const getAllNotificationsByState = (
  state: string,
): Promise<IWebsocketNotification[] | boolean> =>
  api()
    .get(`/portal-api/notification/getAllByState`, { params: { state } })
    .then((response) => {
      const data = get(response, 'data', false);
      return data;
    })
    .catch((error) => false);

export const getAllNotificationsByStateWithLimit = (
  state: string,
  offset: number = 0,
  rowCount: number = NOTIFICATION_ROW_COUNT,
): Promise<IWebsocketNotification[] | boolean> =>
  api()
    .get(`/portal-api/notification/getAllByStateWithLimit`, { params: { state, offset, rowCount } })
    .then((response) => {
      const data = get(response, 'data', false);
      return data;
    })
    .catch((error) => false);

export const getAllNotificationsByStates = (
  states: string[],
): Promise<IWebsocketNotification[] | boolean> =>
  api()
    .post(`/portal-api/notification/getAllByStates`, { states })
    .then((response) => {
      const data = get(response, 'data', false);
      return data;
    })
    .catch((error) => false);

export const getAllNotificationsByStatesWithLimit = (
  states: string[],
  offset: number = 0,
  rowCount: number = NOTIFICATION_ROW_COUNT,
): Promise<IWebsocketNotification[] | boolean> =>
  api()
    .post(`/portal-api/notification/getAllByStatesWithLimit`, { states, offset, rowCount })
    .then((response) => {
      const data = get(response, 'data', false);
      return data;
    })
    .catch((error) => false);

export const getNotification = (id: number): Promise<IWebsocketNotification> =>
  api()
    .get(`/portal-api/notification/get`, { params: { id } })
    .then((response) => {
      const data = get(response, 'data', false);
      return data;
    })
    .catch((error) => false);

export const setNotificationState = (id: number, state: string): Promise<IWebsocketNotification> =>
  api()
    .get(`/portal-api/notification/setState`, { params: { id, state } })
    .then((response) => {
      const data = get(response, 'data', false);
      return data;
    })
    .catch((error) => false);

export const setNotificationsState = (data: any): Promise<any> =>
  api()
    .post(`/portal-api/notification/setState`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => {
      console.error(error);
      throw error; // This will propagate the error and you can handle it where you call this function.
    });

export const getNotificationByRequest = (id: number): Promise<IWebsocketNotification> =>
  api()
    .get(`/portal-api/notification/getByRequest`, { params: { requestId: id } })
    .then((response) => {
      const data = get(response, 'data', false);
      return data;
    })
    .catch((error) => false);

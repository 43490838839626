import { Theme } from '@mui/material';
import { lightBlue } from 'utils/variables';

const styles = {
  drawerBottom: ({ spacing }: Theme) => ({
    padding: spacing(),
    textAlign: 'center',
  }),

  bottomText: {
    color: lightBlue,
    fontSize: 11,
  },
  cktchBottomText: {
    color: 'black',
    fontSize: 11,
  },
  hidden: {
    display: 'none !important',
  },
  bottomLink: {
    color: lightBlue,
  },
  cktchBottomLink: {
    color: 'black',
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  drawerTop: ({ spacing }: Theme) => ({
    marginBottom: spacing(),
  }),
  logo: ({ spacing }: Theme) => ({
    margin: spacing(3),
    height: 60,
    display: 'block',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    justifyContent: 'center',
    textAlign: 'center',
  }),
};

export default styles;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { get, isUndefined } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getUnit, editUnit, createUnit } from './_apiUnits';
import { IUnit, IUnitForm, IUnitFormContent } from './_types';
import useValidationSchema from './_formUnit';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';

import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';

const UnitForm: React.FC<IUnitFormContent> = ({ toggleDialog, id, loadEntity }) => {
  const { t } = useTranslation('Units');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const [entity, fetchEntity] = useState<IUnit>();
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);

  const isCreating = id === 'create';
  const { title } = useWithEntityTitle(isCreating, entity, t);

  const { UnitFormSchema, formItems } = useValidationSchema(t);

  const methods = useForm<IUnitForm>({
    resolver: yupResolver(UnitFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createUnit : editUnit;
    await fn(values).then(
      (response) => {
        addSuccessAlert(t('saved'));
        loadEntity();
        toggleDialog(false);
      },
      (error) => {
        let tError = 'errorSaving';
        if (get(error, 'response.data', '') === 'error.administration.unit.exists') {
          tError = 'errorUnitExists';
        }
        addErrorAlert(t(tError));
      },
    );
    toggleLoader(false);
  });

  const getEntities = async () => {
    toggleLoader();
    try {
      let unit: IUnit = { id: 0, code: '', name: '' };

      if (!isCreating && isUndefined(entity) && id) {
        const entity = await getUnit(id.toString());
        unit = { ...entity };
        if (entity) {
          fetchEntity(entity);
        }
      }

      reset({ ...unit });
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isFetchingEntity && (
        <>
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <Grid container={true} alignItems="flex-end">
                  <Grid item={true} xs={12} md={6}>
                    {formItems.map((field, index) => {
                      return (
                        <React.Fragment key={`formItem-${index}`}>
                          <FormInput {...field} />
                        </React.Fragment>
                      );
                    })}
                    <EntityButtons toggleDialog={toggleDialog} />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
        </>
      )}
    </>
  );
};

export default UnitForm;

import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { get, isEmpty, isNumber, isObject, keys, lowerCase } from 'lodash';
import { AppBar, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import PublishIcon from '@mui/icons-material/Publish';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import PlayIcon from '@mui/icons-material/PlayCircleFilled';

import { cloneTest, getTest, publishTest, removeTest } from './_api';
import { IStudyQuestion } from './_types';
import ConfirmDialog from './ConfirmDialog';
import ExistingGeneralTestTypeDefinitions from './ExistingGeneralTestTypeDefinitions';
import GeneralTestTypeDefinitionsForm from './GeneralTestTypeDefinitionsForm';
import ExistingStudyTestTypeDefinitions from './ExistingStudyTestTypeDefinitions';
import StudyTestTypeDefinitionsForm from './StudyTestTypeDefinitionsForm';

import { Papeer } from 'components/Papeer/Papeer';
import Header from 'components/Header/Header';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import useAlerts from 'components/Alerts/useAlerts';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useTests } from 'utils/hooks/useTests';
import { encryptId } from 'utils/hooks/useApp';
import { useActions } from 'utils/hooks/useActions';
import { joinParams } from 'utils/study';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';

import { TAB_ADD_NEW_DEFINITION, TAB_DEFINITIONS, TAB_EDIT_DEFINITION } from 'constants/constants';
import { Box } from '@mui/system';
import { TourTests } from './TourTests';

const hashedId = true;
const dateFormat = 'dd. MM. yyyy';
const linkBack = '/tests/manageTests';

export const TestDetail: React.FC = () => {
  const { t } = useTranslation('Tests');
  const { confirmationData } = useAppInfo();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const navigate = useNavigate();
  const { storeConfirmationData } = useActions();
  const { id, paramsId } = useEntityInfo(hashedId);

  const { onEntityRemove } = useEntityRemove(removeTest, t, undefined, 'testDeleted');
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [entity, setEntity] = useState<any>({});
  const [testToClone, setTestToClone] = useState<any>(undefined);
  const [testToPublish, setTestToPublish] = useState<any>(undefined);
  const [questions, fetchQuestions] = useState<any>(undefined);
  const [editedDefinition, setEditedDefinition] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<string>(TAB_DEFINITIONS);

  const { isGeneralTestType, isStudyTestType, testIsReady } = useTests(t);

  const { toggleLoader } = useAppGlobals();

  const generalTestType = isGeneralTestType(entity);

  const removeEditingTab = () => setEditedDefinition(null);
  const editDefinition = (definition: IStudyQuestion) => {
    setEditedDefinition(definition);
    setActiveTab(TAB_EDIT_DEFINITION);
  };

  const loadEntity = async () => {
    toggleLoader();
    try {
      if (isNumber(id)) {
        const entity: any = await getTest(id);
        if (entity) {
          setEntity(entity);
        }
        setIsFetchingEntity(false);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const test = useMemo(() => {
    if (!isEmpty(entity)) {
      const booleanRow = (value: boolean) =>
        value ? <CheckIcon sx={{ fontSize: 16 }} /> : <CancelIcon sx={{ fontSize: 16 }} />;
      const test = get(entity, 'id')
        ? {
            name: get(entity, 'name'),
            description: get(entity, 'description'),
            validFrom: format(new Date(get(entity, 'validFrom')), dateFormat),
            validTo: get(entity, 'validTo')
              ? format(new Date(get(entity, 'validTo')), dateFormat)
              : '-',
            maxWrongAnswersAllowed: get(entity, 'maxWrongAnswersAllowed'),
            public: booleanRow(get(entity, 'public')),
            showResults: booleanRow(get(entity, 'showResults')),
            repeatedly: booleanRow(get(entity, 'repeatedly')),
            allowPreview: booleanRow(get(entity, 'allowPreview')),
            state: t(lowerCase(get(entity, 'state'))),
            type: t(lowerCase(get(entity, 'type'))),
            maxTestLength: get(entity, 'maxTestLength'),
            author: `${joinParams([
              get(entity, 'createdBy.firstName'),
              get(entity, 'createdBy.lastName', ''),
            ])}`,
            modification: get(entity, 'modifiedBy.id')
              ? `${format(
                  new Date(get(entity, 'lastChange')),
                  'dd. MM. yyyy, HH:mm',
                )} - ${joinParams([
                  get(entity, 'modifiedBy.firstName'),
                  get(entity, 'modifiedBy.lastName', ''),
                ])}`
              : '-',
          }
        : {};
      return test;
    } else {
      return {};
    }
  }, [entity, t]);

  const aSetting = useMemo(() => {
    if (!isEmpty(entity)) {
      const isGeneralType = isGeneralTestType(entity);
      const isStudyType = isStudyTestType(entity);
      const isPublished = testIsReady(entity);

      return { isGeneralType, isStudyType, isPublished };
    } else {
      return {};
    }
  }, [entity, isGeneralTestType, isStudyTestType, testIsReady]);

  const buttons = useMemo(() => {
    if (!isEmpty(entity)) {
      const isPublished = testIsReady(entity);
      const buttons = [
        {
          disabled: isPublished,
          children: (
            <>
              <EditIcon sx={{ fontSize: 20, mr: 1 }} />
              {t('editTestShort')}
            </>
          ),
          to: { pathname: `/tests/manageTests/${paramsId}`, search: '?backTo=detail' },
        },
        {
          onClick: () => setTestToClone(entity),
          children: (
            <>
              <FilterNoneIcon sx={{ fontSize: 20, mr: 1 }} />
              {t('clone')}
            </>
          ),
        },
        {
          disabled: !get(entity, 'readyToPublish'),
          onClick: () => setTestToPublish(entity),
          children: (
            <>
              <PublishIcon sx={{ fontSize: 20, mr: 1 }} />
              {t('publish')}
            </>
          ),
        },
        {
          disabled: isPublished,
          onClick: () => {
            storeConfirmationData({
              idTest: id,
            });
          },
          children: (
            <>
              <DeleteIcon sx={{ fontSize: 20, mr: 1 }} />
              {t('delete')}
            </>
          ),
        },
        {
          disabled: !get(entity, 'readyToPublish'),
          children: (
            <>
              <PlayIcon sx={{ fontSize: 20, mr: 1 }} />
              {t('trialStart')}
            </>
          ),
          to: {
            pathname: `/tests/startTest/${paramsId}`,
          },
          state: { trial: true },
        },
      ];
      return buttons;
    } else {
      return [];
    }
  }, [entity, id, paramsId, testIsReady, storeConfirmationData, t]);

  const renderedTab = useMemo(() => {
    const loadEntity = async () => {
      toggleLoader();
      try {
        if (isNumber(id)) {
          const entity: any = await getTest(id);
          if (entity) {
            setEntity(entity);
          }
          setIsFetchingEntity(false);
        }
      } catch (e) {
        console.debug(e);
      }
      toggleLoader(false);
    };
    const isGeneralType = get(aSetting, 'isGeneralType', false);
    const isStudyType = get(aSetting, 'isStudyType', false);
    const isPublished = get(aSetting, 'isPublished', false);
    let tab;
    if (get(entity, 'id')) {
      if (activeTab === TAB_DEFINITIONS) {
        if (isGeneralType) {
          tab = (
            <ExistingGeneralTestTypeDefinitions
              id={id}
              editDefinition={editDefinition}
              fetchQuestions={fetchQuestions}
              questions={questions}
              isPublished={isPublished}
            />
          );
        }
        if (isStudyType) {
          tab = (
            <ExistingStudyTestTypeDefinitions
              id={id}
              editDefinition={editDefinition}
              fetchQuestions={fetchQuestions}
              questions={questions}
              isPublished={isPublished}
              readyToPublish={get(entity, 'readyToPublish', false)}
              loadEntity={loadEntity}
            />
          );
        }
      } else if (activeTab === TAB_ADD_NEW_DEFINITION) {
        if (isGeneralType) {
          tab = (
            <GeneralTestTypeDefinitionsForm
              setActiveTab={setActiveTab}
              removeEditingTab={removeEditingTab}
              loadEntity={loadEntity}
              id={id}
            />
          );
        }
        if (isStudyType) {
          tab = (
            <StudyTestTypeDefinitionsForm
              setActiveTab={setActiveTab}
              removeEditingTab={removeEditingTab}
              loadEntity={loadEntity}
              id={id}
            />
          );
        }
      } else if (activeTab === TAB_EDIT_DEFINITION) {
        if (isGeneralType) {
          tab = (
            <GeneralTestTypeDefinitionsForm
              setActiveTab={setActiveTab}
              isEdit={editedDefinition ? true : false}
              editedDefinition={editedDefinition}
              removeEditingTab={removeEditingTab}
              loadEntity={loadEntity}
              id={id}
            />
          );
        }
        if (isStudyType) {
          tab = (
            <StudyTestTypeDefinitionsForm
              setActiveTab={setActiveTab}
              isEdit={editedDefinition ? true : false}
              editedDefinition={editedDefinition}
              removeEditingTab={removeEditingTab}
              loadEntity={loadEntity}
              id={id}
            />
          );
        }
      }
    } else {
      tab = <div />;
    }
    return tab;
  }, [entity, activeTab, editedDefinition, id, aSetting, questions, toggleLoader]);

  const tabs = useMemo(() => {
    if (!isEmpty(entity)) {
      const isPublished = testIsReady(entity);
      const isEditingDefinition = editedDefinition ? true : false;
      const tabs = [{ value: TAB_DEFINITIONS, label: 'definitions' }];
      if (!isPublished) {
        tabs.push({ value: TAB_ADD_NEW_DEFINITION, label: 'addDefinition' });
      }
      if (isEditingDefinition) {
        tabs.push({ value: TAB_EDIT_DEFINITION, label: 'editDefinition' });
      }
      return tabs;
    } else {
      return [];
    }
  }, [entity, testIsReady, editedDefinition]);

  const submitTestToClone = async (id: number) => {
    toggleLoader();
    await cloneTest(id).then(
      async ({ id: newTestId }: any) => {
        addSuccessAlert(t('testCloned'));
        setTestToClone(false);
        navigate(`/tests/redirectToTest/${hashedId ? encryptId(newTestId) : newTestId}`);
      },
      () => {
        addErrorAlert(t('errorCloning'));
      },
    );
    toggleLoader(false);
  };
  const submitTestToPublish = async (id: number) => {
    toggleLoader();
    await publishTest(id).then(
      async (editedTest) => {
        addSuccessAlert(t('testPublished'));
        setEntity(editedTest);
        setTestToPublish(false);
      },
      () => {
        addErrorAlert(t('errorPublishing'));
      },
    );
    toggleLoader(false);
  };

  const clearAction = () => {
    storeConfirmationData(null);
  };
  const cancelAction = () => {
    clearAction();
  };
  const confirmAction = async () => {
    const id = get(confirmationData, 'idTest');
    const isRemoved = await onEntityRemove(id);
    if (isRemoved) {
      navigate(linkBack);
    }
    clearAction();
  };

  const renderedSteps = () => {
    return <TourTests type="detailTest" activeTab={activeTab} generalTestType={generalTestType} />;
  };

  return (
    <>
      {!isFetchingEntity && (
        <>
          {get(entity, 'id') ? (
            <>
              <Header title={t('testDetail')} backUrl={linkBack} TourComponent={renderedSteps()} />
              <Papeer bottomMargin={true}>
                <Typography component="div">
                  <Grid container={true} spacing={2} data-tour="testDetailMainSection">
                    {keys(test).map((key: string) => (
                      <Grid item={true} xs={12} md={4} lg={3} key={key}>
                        <Box component="label" sx={{ fontWeight: 'bold' }}>
                          {t(key)}:
                        </Box>
                        <div>{get(test, key)}</div>
                      </Grid>
                    ))}
                  </Grid>
                  <Box data-tour="testDetailButtons">
                    {buttons.map((button, index) => (
                      <Button
                        key={`button${index}`}
                        size="small"
                        color="inherit"
                        sx={{ mr: 1, mt: 1, border: '1px solid #ccc' }}
                        {...(button.to ? { component: Link } : {})}
                        {...button}
                      />
                    ))}
                  </Box>
                </Typography>
              </Papeer>

              <Box sx={{ width: 'auto' }} data-tour="testDetailDefinitions">
                <AppBar position="static" color="default">
                  <Tabs
                    value={activeTab}
                    onChange={(event, value) => {
                      if (value !== TAB_EDIT_DEFINITION) {
                        removeEditingTab();
                      }
                      setActiveTab(value);
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons={false}
                  >
                    {tabs.map((tab) => (
                      <Tab key={tab.value} value={tab.value} label={t(tab.label)} />
                    ))}
                  </Tabs>
                </AppBar>
                <div>
                  <Typography component="div">
                    <Papeer topMargin={true}>{renderedTab}</Papeer>
                  </Typography>
                </div>
              </Box>
              {isObject(testToPublish) && (
                <ConfirmDialog
                  closeDialog={() => setTestToPublish(false)}
                  entity={testToPublish}
                  action={submitTestToPublish}
                  dialogTitle={t('confirmPublishTitle')}
                />
              )}
              {isObject(testToClone) && (
                <ConfirmDialog
                  closeDialog={() => setTestToClone(false)}
                  entity={testToClone}
                  action={submitTestToClone}
                  dialogTitle={t('confirmCloneTitle')}
                />
              )}
            </>
          ) : (
            <Papeer>
              <Typography variant="h6" style={{ marginBottom: 8 }}>
                {t('testNotFound')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="small"
                component={Link}
                {...({ to: linkBack } as any)}
              >
                {t('backToTests')}
              </Button>
            </Papeer>
          )}
          {confirmationData && confirmationData.idTest && (
            <ConfirmationDialog
              title={t('reallyDelete')}
              open={true}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              cancelAction={cancelAction}
              confirmAction={confirmAction}
            />
          )}
        </>
      )}
    </>
  );
};

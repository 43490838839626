import HelperComponent from 'components/Tour/HelperComponent';
import { getDropDownStep } from 'components/Tour/_dropDownStep';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

interface ITourFilters {
  type: 'main' | 'create' | 'edit';
  canAddFilter?: boolean;
}

export const TourFilters = ({ type, canAddFilter }: ITourFilters) => {
  const { t } = useTranslation('Reactour');

  const allSteps = () => {
    const steps = {
      ...(canAddFilter ? { '[data-tour="addButton"]': t('filters.addButton') } : {}),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
      '.MuiDataGrid-columnHeaders': t('common.headTableWithSortAndFilter'),
      '[data-tour="filtersGrid"]': (
        <>
          {t('common.bodyTable')} {t('folders.bodyTable')}
        </>
      ),
    };
    return steps;
  };

  const allStepsCreate = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="filtersStudyForm"]': t('filters.studyForm'),
      ...getDropDownStep({ isDropDownOpen, t }),
      '[data-tour="filtersAdditionalInfo"]': t('filters.additionalInfo'),
      '[data-tour="saveButton"]': t('filters.save'),
      '[data-tour="backButton"]': t('filters.back'),
    };
    return steps;
  };

  const allStepsEdit = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="filtersEdit"]': t('filters.edit'),
      ...getDropDownStep({ isDropDownOpen, t }),
      '[data-tour="filtersEditAdditionalInfo"]': t('filters.editAdditionalInfo'),
      '[data-tour="saveButton"]': t('filters.save'),
      '[data-tour="backButton"]': t('filters.back'),
    };
    return steps;
  };

  let stepsDefinition;
  if (type === 'main') stepsDefinition = allSteps;
  if (type === 'create') stepsDefinition = allStepsCreate;
  if (type === 'edit') stepsDefinition = allStepsEdit;
  useReactTour({ stepsDefinition });

  return <HelperComponent />;
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { get, isArray, sortBy } from 'lodash';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { getAllFilter, removeFilter, setActiveFilter } from './_api';
import { IWorkplace } from '../Workplaces/_types';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useUser } from 'utils/hooks/useUser';
import { joinParams } from 'utils/study';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useLanguage } from 'utils/hooks/useLanguage';
import { encryptId } from 'utils/hooks/useApp';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import Header from 'components/Header/Header';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { TourFilters } from './TourFilters';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'filtersMUI';

export const Filters: React.FC = () => {
  const { t } = useTranslation('Filters');
  const dispatch = useAppDispatch();
  const { title } = useWithTitle();
  const navigate = useNavigate();
  const { confirmationData } = useAppInfo();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const [filters, setFilters] = useState<any[]>([]);

  const { currentLocale } = useLanguage();

  const { toggleLoader } = useAppGlobals();

  const { hasRole, getUserFilters } = useUser();

  const onCustomEntityDetail = (row: any) => {
    navigate(`/filters/${encryptId(get(row, 'id'))}`);
  };

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };
  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    getUserFilters();
    clearAction();
  };

  const loadEntity = async () => {
    toggleLoader();
    try {
      const filters = await getAllFilter();
      if (filters && isArray(filters)) {
        const canUpdate = hasRole('ROLE_EDIT_FILTER');
        const canDelete = hasRole('ROLE_DELETE_FILTER');
        const disabledRow = !canUpdate;
        setFilters(
          filters.map((item) => {
            return {
              ...item,
              canUpdate,
              canDelete,
              disabledRow,
            };
          }),
        );
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          width: 220,
          renderCell: ({ row }: GridRenderCellParams) => {
            return (
              <>
                <Box sx={{ width: '200px' }}>
                  <FormControlLabel
                    sx={{ maxHeight: 30 }}
                    control={
                      <Switch
                        checked={row.active}
                        onChange={(e, state) => {
                          dispatch(
                            storeConfirmationDataToStore({
                              title: 'Grid:confirmChangeState',
                              id: get(row, 'id'),
                              state,
                              confirmAction: 'confirmActionChangeActive',
                            }),
                          );
                        }}
                      />
                    }
                    label={(row.active ? t('active') : t('inactive')) as string}
                    disabled={get(row, 'disabledRow', false)}
                  />
                </Box>
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t('Grid:edit')}>
                      <EditIcon />
                    </Tooltip>
                  }
                  label={t('Grid:edit')}
                  onClick={() => onCustomEntityDetail(row)}
                  disabled={!get(row, 'canUpdate', true)}
                />
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t('Grid:delete')}>
                      <DeleteIcon />
                    </Tooltip>
                  }
                  label={t('Grid:delete')}
                  onClick={() => {
                    dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                  }}
                  disabled={!get(row, 'canDelete', true)}
                />
              </>
            );
          },
        },
        { field: 'name', headerName: t('name') },
        {
          field: 'workPlaces',
          headerName: t('workPlaces'),
          valueGetter: (value: any, row: any) =>
            isArray(get(row, 'workPlaces', null))
              ? sortBy(
                  get(row, 'workPlaces').map((workPlace: IWorkplace) =>
                    get(
                      get(workPlace, 'text', false) ? JSON.parse(get(workPlace, 'text', '')) : {},
                      currentLocale,
                      workPlace.code,
                    ),
                  ),
                ).join(', ')
              : null,
        },
        {
          field: 'authorName',
          headerName: t('authorName'),
          valueGetter: (value: any, row: any) =>
            `${joinParams([get(row, 'author.lastName'), get(row, 'author.firstName')])}`,
        },
      ],
      220,
    ),
  );

  const onChangeActive = async (id: string, active: boolean) => {
    toggleLoader();
    const changed = await setActiveFilter(id, active);
    if (changed) {
      getUserFilters();
      await loadEntity();
    }
    toggleLoader(false);
  };

  const confirmActionChangeActive = async () => {
    await onChangeActive(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  const { onEntityRemove } = useEntityRemove(removeFilter, t, loadEntity);

  const renderedSteps = () => {
    return <TourFilters type="main" canAddFilter={hasRole('ROLE_CREATE_FILTER')} />;
  };

  return (
    <>
      <Header
        title={title}
        addUrl={hasRole('ROLE_CREATE_FILTER') ? `/filters/create` : ''}
        TourComponent={renderedSteps()}
      />
      <MuiGrid
        gridKey={muiGridKey}
        rows={filters}
        columns={columns}
        dataTour="filtersGrid"
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />

      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={
            confirmationData.confirmAction === 'confirmActionChangeActive'
              ? confirmActionChangeActive
              : confirmAction
          }
        />
      )}
    </>
  );
};

import { ASC, DEFAULT_LOCALE, THEME_MODE_LIGHT } from 'constants/constants';
import { IAppReducerInitialState } from './_types';
import { get, keys, pick, set } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModality } from 'modules/Administration/ModalitiesUnitsStations/_types';

const initialState: IAppReducerInitialState = {
  alerts: [],
  isLoading: false,
  showCancelButton: false,
  validators: {},
  locale: navigator.language || DEFAULT_LOCALE,
  appProfile: null,
  lastPageState: {},
  lists: {},
  trash: undefined,
  gridSettings: { study: {}, studyMUI: {}, preview: {} },
  feConfig: null,
  feConfigFromApi: null,
  passwordPolicyConfig: null,
  wasRedirected: false,
  archivesForFunctions: {},
  isImportUploading: false,
  isImportMdexUploading: false,
  isCstoreUploading: false,
  refetchGrid: '',
  searchResults: [],
  confirmationData: {},
  localGridSettings: {},
  hasMiniMenu: false,
  compactMode: false,
  orderResults: [],
  queueResults: [],
  testResults: [],
  logResults: [],
  findingReportResults: [],
  viewer: undefined,
  structureResults: [],
  vocabularyIdentityResults: undefined,
  vocabularyRowResults: undefined,
  vocabularyKeys: undefined,
  theme: 'orcz',
  mode: THEME_MODE_LIGHT,
  timeline: ASC,
  searchFilter: '',
  searchRequestResults: [],
  patientDocument: null,
  patientResults: [],
  requestSearchResults: [],
  tags: [],
  facilitiesManualLoad: [],
  selectedDocuments: [],
  dataForDocumentDetail: [],
  patientDocuments: [],
  newNotifications: [],
  columnsInSearchGridWithAdditionalInfoBeingLoaded: [],
  mdexDicomPatient: [],
  mdexFiles: [],
  loaderLock: undefined,
  tourControlPropsStore: {},
  use2FA: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsLoading: (
      state,
      action: PayloadAction<{
        isLoading: boolean;
        showCancelButton: boolean;
        lock: string | undefined;
      }>,
    ) => {
      const { isLoading, lock } = action.payload;
      const isDevelopmentMode = process.env.NODE_ENV === 'development';

      // Settings loader
      if (isLoading) {
        // Always set loading
        state.isLoading = isLoading;

        // If lock is set, store it
        if (lock) {
          state.loaderLock = lock;
          if (isDevelopmentMode) {
            console.log(`%cLock started :: ${lock}`, `color:blac; background:lime; padding: 5px;`);
          }
        }
      }

      // Removing loader
      if (!isLoading) {
        // If there is no lock in store, remove loader
        if (!state.loaderLock) {
          state.isLoading = isLoading;
        }

        // Remove lock if lock in store is the same as in action
        if (lock && state.loaderLock === lock) {
          state.isLoading = isLoading;
          state.loaderLock = undefined;
          if (isDevelopmentMode) {
            console.log(`%cLock ended :: ${lock}`, `color:black; background:red; padding: 5px;`);
          }
        }
      }

      state.showCancelButton = action.payload.showCancelButton;
    },
    toggleMiniMenu: (state) => {
      state.hasMiniMenu = !state.hasMiniMenu;
    },
    validatorsLoad: (state, action) => {
      state.validators = action.payload;
    },
    setProfile: (state, action) => {
      state.appProfile = action.payload;
    },
    setListsToStore: (state, action) => {
      state.lists = { ...state.lists, ...action.payload };
    },
    setTrash: (state, action) => {
      state.trash = action.payload;
    },
    setGridSettingsToStore: (state, action) => {
      state.gridSettings = { ...state.gridSettings, ...action.payload };
    },
    setSearchFilter: (state, action) => {
      state.searchFilter = action.payload;
    },
    setAllowedArchivesForFunctions: (state, action) => {
      state.archivesForFunctions = action.payload;
    },
    setViewerToStore: (state, action) => {
      state.viewer = action.payload;
    },
    setWasRedirected: (state, action) => {
      state.wasRedirected = action.payload;
    },
    userUnload: (state) => {
      return {
        ...initialState,
        ...pick(state, [
          'feConfig',
          'feConfigFromApi',
          'passwordPolicyConfig',
          'lastPageState',
          'locale',
        ]),
      };
    },
    storeAddSearchResultsToStore: (state, action) => {
      state.searchResults = [...state.searchResults, ...action.payload];
    },
    storeSearchResultsToStore: (state, action) => {
      state.searchResults = action.payload;
    },
    storeOrderResultsToStore: (state, action) => {
      state.orderResults = action.payload;
    },
    storeQueueResults: (state, action) => {
      state.queueResults = action.payload;
    },
    storeTestResults: (state, action) => {
      state.testResults = action.payload;
    },
    storeLogResults: (state, action) => {
      state.logResults = action.payload;
    },
    storeFindingReportResults: (state, action) => {
      state.findingReportResults = action.payload;
    },
    storeLastPageState: (state, action) => {
      state.lastPageState = action.payload;
    },
    storeStructureResults: (state, action) => {
      state.structureResults = action.payload;
    },
    storeVocabularyIdentityResults: (state, action) => {
      state.vocabularyIdentityResults = action.payload;
    },
    storeVocabularyRowToStore: (state, action) => {
      state.vocabularyRowResults = action.payload;
    },
    storeVocabularyKeysToStore: (state, action) => {
      state.vocabularyKeys = action.payload;
    },
    storeImportUploadingState: (state, action) => {
      state.isImportUploading = action.payload;
    },
    storeImportMdexUploadingState: (state, action) => {
      state.isImportMdexUploading = action.payload;
    },
    setGridRefetch: (state, action) => {
      state.refetchGrid = action.payload;
    },
    storeConfirmationDataToStore: (state, action) => {
      state.confirmationData = action.payload;
    },
    setLocalGridSettingsToStore: (state, action) => {
      const { name, data, reset } = action.payload;
      state.localGridSettings = {
        ...state.localGridSettings,
        [name]: reset ? {} : { ...get(state, `localGridSettings.${name}`, {}), ...data },
      };
    },
    alertRemove: (state, action) => {
      state.alerts = state.alerts.filter((alert) => alert.id !== action.payload);
    },
    alertAdd: (state, action) => {
      state.alerts = [...state.alerts, action.payload];
    },
    setThemeToStore: (state, action) => {
      state.theme = action.payload;
    },
    setModeToStore: (state, action) => {
      state.mode = action.payload;
    },
    setTimelineToStore: (state, action) => {
      state.timeline = action.payload;
    },
    setCompactModeToStore: (state, action) => {
      state.compactMode = action.payload;
    },
    setConfig: (state, action) => {
      state.feConfig = action.payload;
    },
    setConfigFromApi: (state, action) => {
      state.feConfigFromApi = action.payload;
    },
    setPasswordPolicyConfig: (state, action) => {
      state.passwordPolicyConfig = action.payload;
    },
    storeBasketToStore: () => {
      // TODO
    },
    resetBasketInStore: () => {
      // TODO
    },
    storeSelectionToStore: (state, action) => {
      state.selectedDocuments = action.payload;
    },
    storeDocumentsToStore: (state, action) => {
      state.patientDocuments = action.payload;
    },
    storeDataForDocumentDetailToStore: (state, action) => {
      state.dataForDocumentDetail = action.payload;
    },
    storeDocumentToStore: (state, action) => {
      state.patientDocument = action.payload;
    },
    storePatientsToStore: (state, action) => {
      state.patientResults = action.payload;
    },
    storeRequestSearchResultsToStore: (state, action) => {
      state.requestSearchResults = action.payload;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    setNewNotifications: (state, action) => {
      state.newNotifications = action.payload;
    },
    setColumnsInSearchGridWithAdditionalInfoBeingLoaded: (state, action) => {
      state.columnsInSearchGridWithAdditionalInfoBeingLoaded = action.payload;
    },
    loadInitialSettings: (state, action) => {
      const settings = get(action, 'payload.gridSettings', {});
      const settingsObject = { study: {}, studyMUI: {}, preview: {} };
      keys(settings).forEach((key: string) =>
        set(settingsObject, key, JSON.parse(get(settings, key, ''))),
      );
      const modNoShow: string[] = [];
      get(action, 'payload.modalities', []).forEach((modality: IModality) => {
        if (get(modality, 'show', false) === false) {
          modNoShow.push(get(modality, 'name'));
        }
      });

      state.gridSettings = settingsObject;
      state.searchFilter = JSON.parse(get(action, 'payload.defaultArchives.defaultArchives', '{}'));
      state.compactMode =
        'ontouchstart' in document.documentElement // pokud je uživatel na mobilu, tak pustit kompaktní mód
          ? true
          : get(action, 'payload.compactMode', false);
      state.trash = get(action, 'payload.trash', {});
      state.lists = {
        ...state.lists,
        roles: get(action, 'payload.roles', []),
        modalities: get(action, 'payload.modalities', []),
        states: get(action, 'payload.states', []),
        modNoShow,
      };
    },
    storeFacilitiesManualLoad: (state, action) => {
      state.facilitiesManualLoad = action.payload;
    },
    setUse2FA: (state, action) => {
      state.use2FA = action.payload;
    },
    addToStoreFacilitiesManualLoad: (state, action) => {
      state.facilitiesManualLoad = [...state.facilitiesManualLoad, action.payload];
    },
    setMdexDicomPatientToStore: (state, action) => {
      state.mdexDicomPatient = action.payload;
    },
    setMdexFilesToStore: (state, action) => {
      state.mdexFiles = action.payload;
    },
    setTourControlPropsToStore: (state, action) => {
      state.tourControlPropsStore = {
        ...state.tourControlPropsStore,
        ...action.payload,
      };
    },
  },
});

export const {
  setIsLoading,
  toggleMiniMenu,
  validatorsLoad,
  setProfile,
  setListsToStore,
  setTrash,
  setGridSettingsToStore,
  setSearchFilter,
  setAllowedArchivesForFunctions,
  setViewerToStore,
  setWasRedirected,
  userUnload,
  storeAddSearchResultsToStore,
  storeSearchResultsToStore,
  storeOrderResultsToStore,
  storeQueueResults,
  storeTestResults,
  storeLogResults,
  storeFindingReportResults,
  storeStructureResults,
  storeVocabularyIdentityResults,
  storeVocabularyRowToStore,
  storeVocabularyKeysToStore,
  storeImportUploadingState,
  storeImportMdexUploadingState,
  setGridRefetch,
  storeConfirmationDataToStore,
  setLocalGridSettingsToStore,
  alertRemove,
  alertAdd,
  setThemeToStore,
  setModeToStore,
  setTimelineToStore,
  setCompactModeToStore,
  setConfig,
  setConfigFromApi,
  setPasswordPolicyConfig,
  storeBasketToStore,
  resetBasketInStore,
  storeSelectionToStore,
  storeDocumentsToStore,
  storeDataForDocumentDetailToStore,
  storeLastPageState,
  storeDocumentToStore,
  storePatientsToStore,
  storeRequestSearchResultsToStore,
  setTags,
  loadInitialSettings,
  storeFacilitiesManualLoad,
  addToStoreFacilitiesManualLoad,
  setColumnsInSearchGridWithAdditionalInfoBeingLoaded,
  setNewNotifications,
  setMdexDicomPatientToStore,
  setMdexFilesToStore,
  setTourControlPropsToStore,
  setUse2FA,
} = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export default appSlice.reducer;

import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stateIsSame } from 'utils/componentOptimizatons';
import { isArray, isEmpty, get, join, set, find, isString, filter, each, includes } from 'lodash';

import { IStudyDetailTimelineTabs } from './_types';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import {
  CompareArrows as CompareIcon,
  Timeline as FilterIcon,
  Restore as RemoveFilterIcon,
  Cancel as DeleteFilterIcon,
} from '@mui/icons-material/';
import { Box } from '@mui/system';
import { darkTypeInputBackground } from 'utils/variables';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { settingHasValue } from 'utils/products';

const formatDateTimeForTimeLine = 'dd. MM. yyyy';
const timelineDescriptionMaxLength = 12;

const PlainStudyDetailTimelineTabs: React.FC<IStudyDetailTimelineTabs> = ({
  studiesForPatient,
  studyID,
  archiveID,
  handleLifeCycle,
  modalities,
  handleTimelineOrderChange,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { portalProductSettings } = useAppInfo();
  const showNumberOfSeriesAndInstancesInTimeline = isEmpty(portalProductSettings)
    ? false
    : settingHasValue(portalProductSettings, 'showNumberOfSeriesAndInstancesInTimeline', 'true');
  const [activeStep, setActiveStep] = useState<any>(null);
  const [contextMenu, setContextMenu] = useState<any>({ coords: {}, study: null });
  const [timelineFilter, setTimelineFilter] = useState<any>(null);
  const [TimelineMenu, setTimelineMenu] = useState<JSX.Element>();

  const filterStudiesByModalities = (modalitiesToBeFilteredBy: any, studiesForPatient: any) => {
    if (
      isArray(modalitiesToBeFilteredBy) &&
      modalitiesToBeFilteredBy.length &&
      isArray(studiesForPatient)
    ) {
      // Filter studiesForPatient (which contain all studies for timeline)
      const filteredStudies = filter(studiesForPatient, ({ modalitiesInStudy }: any) => {
        let containsAllModalitiesToBeFilteredBy = true;

        // Cycle through all modalities filtered by
        each(modalitiesToBeFilteredBy, (modality) => {
          // Check if modality, that is used in filter, is contained in study
          // If one of the modalities is not in the study, the flag containsAllModalitiesToBeFilteredBy is set to false
          // And the study should not be visible in the timeline anymore
          if (!includes(modalitiesInStudy, modality)) {
            containsAllModalitiesToBeFilteredBy = false;
          }
        });
        return containsAllModalitiesToBeFilteredBy;
      });
      return filteredStudies;
    } else {
      return [];
    }
  };

  const filterStudiesByDescription = (descriptionToBeFilteredBy: any, studiesForPatient: any) => {
    if (isString(descriptionToBeFilteredBy) && isArray(studiesForPatient)) {
      // Filter studiesForPatient (which contain all studies for timeline)
      const filteredStudies = filter(studiesForPatient, (study) =>
        includes(study.studyDescription, descriptionToBeFilteredBy),
      );
      return filteredStudies;
    } else {
      return [];
    }
  };

  let iid = '';
  let mods = {};

  const studiesForPatientArray = studiesForPatient;

  // Filter studies if filter is set
  let filteredStudies = null;
  if (timelineFilter !== null) {
    if (timelineFilter.filter === 'modalities') {
      filteredStudies = filterStudiesByModalities(
        timelineFilter.modalities,
        studiesForPatientArray,
      );
    }
    if (timelineFilter.filter === 'description') {
      filteredStudies = filterStudiesByDescription(
        timelineFilter.description,
        studiesForPatientArray,
      );
    }
  }

  // If there are filtered studies, show them, otherwise show all studies
  const studiesToBeRendered =
    isArray(filteredStudies) && filteredStudies.length ? filteredStudies : studiesForPatientArray;

  if (!isEmpty(studiesToBeRendered) && studiesToBeRendered.length >= 1 && studyID && archiveID) {
    iid = `${studyID}_${archiveID}`;

    if (isArray(modalities)) {
      mods = studiesToBeRendered
        .map((studyForPatient) => {
          const modalitiesInStudy = get(studyForPatient, 'modalitiesInStudy');
          if (isArray(modalitiesInStudy)) {
            return join(modalitiesInStudy, ',');
          }
          return undefined;
        })
        .reduce((result, value, key) => {
          set(result, `${key + 1}`, value);
          return result;
        }, {});
    }

    studiesToBeRendered.forEach((studyForPatient, index) => {
      if (iid === get(studyForPatient, 'iid', '')) {
        if (activeStep !== index) {
          setActiveStep(index);
        }
      }
    });
  }

  if (!isEmpty(studiesToBeRendered) && studiesToBeRendered.length > 1 && studyID && archiveID) {
    iid = `${studyID}_${archiveID}`;

    if (isArray(modalities)) {
      mods = studiesToBeRendered
        .map((studyForPatient) => {
          const modalitiesInStudy = studyForPatient.modalitiesInStudy;
          if (isArray(modalitiesInStudy)) {
            return join(modalitiesInStudy, ',');
          }
          return undefined;
        })
        .reduce((result: any, value: any, key: number) => {
          set(result, `${key + 1}`, value);
          return result;
        }, {});
    }

    studiesToBeRendered.forEach((studyForPatient, index: number) => {
      if (iid === studyForPatient.iid) {
        if (activeStep !== index) {
          setActiveStep(index);
        }
      }
    });
  }

  const TabIcon = (index: number) => {
    const studyForPatient = studiesToBeRendered[index];
    const accNum = studyForPatient.accessionNumber || false;
    const numberOfSeries = studyForPatient.numberOfStudyRelatedSeries || 0;
    const numberOfInstances = studyForPatient.numberOfStudyRelatedInstances || 0;
    const archiveName = `${get(studyForPatient, 'archive.name')} (${get(
      studyForPatient,
      'archive.code',
    )})`;
    const studyDescription = studyForPatient.studyDescription || null;
    const archiveColor = get(studyForPatient, 'archive.color', null);

    const modalityInStudy =
      isArray(modalities) && modalities.length && isArray(get(studyForPatient, 'modalitiesInStudy'))
        ? get(studyForPatient, 'modalitiesInStudy').map((mod: any) => {
            const foundModality = find(modalities, { name: mod }) || {
              name: 'OTHER - ' + mod,
              color: '#000000',
              description: 'Other',
            };
            return (
              <Tooltip key={mod} title={get(foundModality, 'description', '')}>
                <Box
                  component="span"
                  sx={{
                    color: '#ffffff',
                    fontSize: 10,
                    padding: '3px 8px',
                    display: 'inline',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    verticalAlign: 'baseline',
                    borderRadius: '0.25em',
                    fontWeight: 'bold',
                    marginRight: (theme) => theme.spacing(1 / 2),
                    marginBottom: (theme) => theme.spacing(1 / 2),
                    backgroundColor: get(foundModality, 'color'),
                  }}
                >
                  {foundModality.name}
                </Box>
              </Tooltip>
            );
          })
        : '';

    const tooltipText = (
      <>
        {accNum && (
          <div>
            {t('accessionNumber')}: {accNum}
          </div>
        )}
        <div>
          {t('numberOfStudyRelatedSeriesAndInstances')}: {numberOfSeries} / {numberOfInstances}
        </div>
        <div>
          {t('archive.name')}: {archiveName}
        </div>
        {studyDescription !== null ? (
          <div>
            {t('studyDescription')}: {studyDescription}
          </div>
        ) : (
          <></>
        )}
        <div>
          {t('modality')}: {modalityInStudy}
        </div>
      </>
    );
    const modality = String(get(mods, index + 1));
    const containsComma = modality.indexOf(',') > 0;
    const md = containsComma ? `${modality.substring(0, modality.indexOf(','))}*` : modality;
    const timelineIcon = (
      <Box
        data-timeline-icon=""
        component="span"
        sx={{
          background: '#757575',
          color: '#fff',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '0.25em',
          border: '3px solid',
          boxSizing: 'content-box',
          position: 'relative',
          padding: '0px 5px',
          lineHeight: 1.4,
          borderColor: archiveColor || 'transparent',
        }}
      >
        {md}
      </Box>
    );
    return <Tooltip title={tooltipText}>{timelineIcon}</Tooltip>;
  };

  const closeContextMenu = () => {
    setContextMenu({ coords: {}, study: null });
  };

  const removeFilterForTimeline = () => {
    setTimelineFilter(null);
    closeContextMenu();
  };
  const filterTimelineByModality = () => {
    closeContextMenu();
    const modalitiesToBeFilteredBy = get(contextMenu, 'study.modalitiesInStudy');
    setTimelineFilter({ filter: 'modalities', modalities: modalitiesToBeFilteredBy });
  };
  const filterTimelineByDescription = () => {
    closeContextMenu();
    const descriptionToBeFilteredBy = get(contextMenu, 'study.studyDescription');
    setTimelineFilter({ filter: 'description', description: descriptionToBeFilteredBy });
  };

  useEffect(() => {
    const filteredBy = get(timelineFilter, 'filter');

    const MenuFilterItem = ({ title, active, callback }: any) => (
      <MenuItem dense={true} onClick={callback}>
        <ListItemIcon>
          <FilterIcon fontSize="small" color={active ? 'primary' : 'inherit'} />
        </ListItemIcon>
        <ListItemText primary={title} primaryTypographyProps={active ? { color: 'primary' } : {}} />
      </MenuItem>
    );

    const TimelineMenu = (
      <Menu
        keepMounted={true}
        open={contextMenu.coords.mouseY > 0}
        onClose={closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu.coords.mouseY !== null && contextMenu.coords.mouseX !== null
            ? { top: contextMenu.coords.mouseY, left: contextMenu.coords.mouseX }
            : undefined
        }
      >
        <MenuItem
          dense={true}
          onClick={() => {
            closeContextMenu();
            handleTimelineOrderChange();
          }}
        >
          <ListItemIcon>
            <CompareIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('changeTimelineOrder')} />
        </MenuItem>
        {contextMenu.study !== null ? (
          <>
            {isArray(contextMenu.study.modalitiesInStudy) &&
            contextMenu.study.modalitiesInStudy.length ? (
              <MenuFilterItem
                title={t('filterByModality')}
                active={filteredBy === 'modalities'}
                callback={filterTimelineByModality}
              />
            ) : null}
            {isString(contextMenu.study.studyDescription) ? (
              <MenuFilterItem
                title={t('filterByDescription')}
                active={filteredBy === 'description'}
                callback={filterTimelineByDescription}
              />
            ) : null}
          </>
        ) : null}
        {filteredBy ? (
          <MenuItem dense={true} onClick={removeFilterForTimeline}>
            <ListItemIcon>
              <RemoveFilterIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('removeFilterForTimeline')} />
          </MenuItem>
        ) : null}
      </Menu>
    );
    setTimelineMenu(TimelineMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextMenu, timelineFilter]);

  return isEmpty(studiesToBeRendered) ||
    (timelineFilter === null && studiesToBeRendered.length < 2) ? (
    <Box id="timeline" sx={{ mt: 1 }} />
  ) : (
    <Box sx={{ mt: 1, mb: 1, position: 'relative' }}>
      {timelineFilter !== null && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 999,
            left: '50%',
            top: -13,
            transform: 'translate(-50%)',
            fontSize: 11,
            padding: 2,
            borderRadius: 3,
          }}
        >
          {timelineFilter.filter === 'modalities' && isArray(timelineFilter.modalities) && (
            <>
              {t('filteringByModalities')}:{' '}
              {timelineFilter.modalities.map((modality: any) => (
                <span key={modality} style={{ display: 'inline-block', marginRight: 5 }}>
                  {modality}
                </span>
              ))}
            </>
          )}
          {timelineFilter.filter === 'description' && (
            <>
              {t('filteringByDescription')}: {timelineFilter.description}
            </>
          )}
          <IconButton style={{ padding: 1 }} onClick={removeFilterForTimeline}>
            <DeleteFilterIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
      <Tabs
        value={activeStep}
        onChange={(event, value) => {
          setActiveStep(value);
          const studyForPatient = studiesToBeRendered[value];
          handleLifeCycle(
            get(studyForPatient, 'studyInstanceUid', ''),
            get(studyForPatient, 'archive.id') as unknown as string,
            false,
          );
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          background:
            'linear-gradient(180deg, rgba(0,0,0,0) 37px, rgba(192,192,192,1) 38px, rgba(0,0,0,0) 39px)',
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? darkTypeInputBackground : '#fff',
          position: 'relative',
          '&:after': {
            content: '""',
            width: 24,
            top: 0,
            height: '100%',
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? darkTypeInputBackground : '#fff',
            position: 'absolute',
          },
          '&:before': {
            content: '""',
            width: 24,
            top: 0,
            right: 0,
            height: '100%',
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? darkTypeInputBackground : '#fff',
            position: 'absolute',
          },
          '.MuiTabs-indicator': { display: 'none' },
          '.MuiTabs-scrollButtons': { position: 'relative', zIndex: 500 },
          '.MuiTabs-flexContainer': { justifyContent: 'left' }, // space-between
        }}
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setContextMenu({
            coords: {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            },
            study: null,
          });
        }}
      >
        {studiesToBeRendered.map((studyForPatient: any, index: number) => {
          const studyDescription = get(studyForPatient, 'studyDescription');
          const numberOfSeries = get(studyForPatient, 'numberOfStudyRelatedSeries', 0);
          const numberOfInstances = get(studyForPatient, 'numberOfStudyRelatedInstances', 0);
          let timelineDescriptionElem;
          if (studyDescription) {
            if (studyDescription.length > timelineDescriptionMaxLength) {
              timelineDescriptionElem = (
                <Tooltip title={studyDescription}>
                  <div>{studyDescription.substring(0, timelineDescriptionMaxLength)}&hellip;</div>
                </Tooltip>
              );
            } else {
              timelineDescriptionElem = <div>{studyDescription}</div>;
            }
          } else {
            timelineDescriptionElem = <div>&nbsp;</div>;
          }
          return (
            <Tab
              key={`${studyForPatient.iid}_${index}`}
              label={
                <div>
                  <Box>{timelineDescriptionElem}</Box>
                  <Box>{TabIcon(index)}</Box>
                  {showNumberOfSeriesAndInstancesInTimeline ? (
                    <div>
                      {numberOfSeries} / {numberOfInstances}
                    </div>
                  ) : null}
                  {format(
                    new Date(get(studyForPatient, 'dateTime', '')),
                    formatDateTimeForTimeLine,
                  )}
                </div>
              }
              sx={{
                '&:first-child': {
                  marginLeft: '24px',
                },
                '&:last-child': {
                  marginRight: '24px',
                },
                '&.MuiTab-root': {
                  minWidth: 120,
                  transition: 'background 0.2s linear',
                  '& [data-timeline-icon]': {
                    fontSize: 10,
                  },
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.05)',
                  },
                },
                '&.Mui-selected': {
                  backgroundColor: 'rgba(56, 139, 171, 0.2)',
                  '& span': {
                    fontSize: 30,
                  },
                  '& [data-timeline-icon]': {
                    fontSize: 12,
                    backgroundColor: (theme) => theme.palette.primary.main,
                  },
                },
              }}
              onContextMenu={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setContextMenu({
                  coords: {
                    mouseX: event.clientX - 2,
                    mouseY: event.clientY - 4,
                  },
                  study: studyForPatient,
                });
              }}
            />
          );
        })}
      </Tabs>
      {TimelineMenu}
      {/* <Button
        variant="contained"
        color="primary"
        type="button"
        fullWidth={true}
        onClick={handleTimelineOrderChange}
      >
        {t('otočit směr osy')}
      </Button> */}
    </Box>
  );
};

export const StudyDetailTimelineTabs = React.memo(PlainStudyDetailTimelineTabs, stateIsSame);

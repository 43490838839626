import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';

export const distributeStudy = (data: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/study/move`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const createRequestForDistribute = (values: any): Promise<any | boolean> =>
  api().post(`/portal-api/studyRequest/createForDistribute`, values);

export const editRequestForDistribute = (values: any): Promise<any | boolean> =>
  api().post(`/portal-api/studyRequest/editForDistribute`, values);

export const getPatientMatching = (): Promise<any | boolean> =>
  api()
    .get(`/portal-api/study/getPatientMatching`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';
import { find, get } from 'lodash';

const useValidationSchema = (t: TFunction, languages: any[]) => {
  const { required, dateComparison, date, min } = useValidators();

  const language = find(languages, { default: true });

  const AnnouncementFormSchema = yup.object().shape({
    validFrom: date(yup.date(), 'required'),
    validTo: dateComparison(date(yup.date(), 'required'), 'validFrom'),
    [`languageJsonText_${get(language, 'abbreviation')}`]: required(yup.string().trim()),
    importance: required(yup.mixed()),
    workPlaces: min(
      yup.array().transform((ids) => {
        return ids.filter((id: any) => {
          return id === 0 || id;
        });
      }),
      1,
      'selectMin1Workplace',
    ),
  });

  const formItems = [
    {
      type: 'datePicker',
      name: 'validFrom',
      label: t('validFrom'),
      required: true,
    },
    {
      type: 'datePicker',
      name: 'validTo',
      label: t('validTo'),
      required: true,
    },
    ...(languages || []).map((lang: any) => ({
      name: `languageJsonText_${lang.abbreviation}`,
      label: `${t('name')} - ${get(lang, 'name', '')}`,
      required: get(lang, 'default', false),
      multiline: true,
      rows: 4,
      grid: { xs: 12 },
    })),
    {
      type: 'select',
      name: 'importance',
      label: t('importanceLabel'),
      required: true,
    },
  ];

  return { AnnouncementFormSchema, formItems };
};

export default useValidationSchema;

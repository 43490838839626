export const useAllowedType = () => {
  const allowedType = (fileName: any) => {
    let response = {};
    response = { ...response, allowed: false };

    const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);

    if (fileType === 'pdf' || fileType === 'mp4') {
      response = { ...response, allowed: true, contentType: `application/${fileType}` };
    }

    if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
      response = { ...response, allowed: true, contentType: `image/${fileType}` };
    }

    return response;
  };

  return {
    allowedType,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { format } from 'date-fns';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IRequestDetailHistories } from './_types';
import { joinParams } from 'utils/study';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import Header from 'components/Header/Header';

import { findTranslation } from 'utils/grid/translate';
import { useLanguage } from 'utils/hooks/useLanguage';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'requestDetailHistories';

export const RequestDetailHistories: React.FC<IRequestDetailHistories> = ({ title, rows }) => {
  const { t } = useTranslation('Request');
  const { currentLocale } = useLanguage();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'createdWhen',
        headerName: t('createdWhen'),
        type: 'dateTime',
        renderCell: ({ row }: GridRenderCellParams) =>
          format(new Date(get(row, 'createdWhen', '')), 'dd.MM.yyyy HH:mm:ss'),
        valueGetter: ({ value }: any) => value && new Date(value),
      },
      {
        field: 'createdBy',
        headerName: t('author'),
        valueGetter: (value: any, row: any) =>
          joinParams([get(row, 'createdBy.lastName'), get(row, 'createdBy.firstName')]),
      },
      {
        field: 'stateFrom',
        headerName: t('oldState'),
        valueGetter: (value: any, row: any) =>
          findTranslation(
            currentLocale,
            'Request',
            (get(row, 'stateFrom', '') ? get(row, 'stateFrom') : 'empty').toLowerCase(),
          ),
      },
      {
        field: 'stateTo',
        headerName: t('newState'),
        valueGetter: (value: any, row: any) =>
          findTranslation(
            currentLocale,
            'Request',
            (get(row, 'stateTo', '') ? get(row, 'stateTo') : 'empty').toLowerCase(),
          ),
      },
      {
        field: 'comment',
        headerName: t('comment'),
        valueGetter: (value: any, row: any) => get(row, 'comment.text'),
      },
    ]),
  );

  return (
    <>
      <Header title={title} topMargin={true} />
      <MuiGrid
        gridKey={muiGridKey}
        rows={rows}
        columns={columns}
        withoutMaxHight={true}
        hideFooter={true}
        dataTour="request-detail-history"
        initialSortMode={[{ field: 'createdWhen', sort: 'desc' }]}
      />
    </>
  );
};

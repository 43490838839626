import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { filter, get, isArray, isEmpty, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { joinParams } from 'utils/study';
import { IRenderStudy } from './_types';
import { RenderSerie } from './RenderSerie';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { getUrlForOpenViewer } from 'modules/Studies/StudyDetail/_api';
import useAlerts from 'components/Alerts/useAlerts';
import Image from '@mui/icons-material/Image';

export const RenderStudy: React.FC<IRenderStudy> = ({
  study,
  showSeriesCheckboxes,
  showStudiesCheckboxes,
  showInstancesCheckboxes,
  setIsFetching,
  studyIndex,
  operations,
  renderStudy,
  allowOpenSeries,
  actualSize,
}) => {
  const { t } = useTranslation('Studies');
  const { defViewer, compactMode } = useAppInfo();
  const { addErrorAlert } = useAlerts();
  const { setStudyOpened, setStudySelected } = operations;

  const studyIsOpened = study.studyIsOpened || !renderStudy;
  const studyIsSelected = study.studyIsSelected;
  const loadedSeries = study.loadedSeries;
  const listOfSeries: any = study?.listOfSeries;
  const selectedLoadedSeries = filter(loadedSeries, (serie) => serie.serieIsSelected === true);
  // const studyIsIndeterminate =
  //   loadedSeries.length > selectedLoadedSeries.length && selectedLoadedSeries.length > 0;

  const studyIsIndeterminate = loadedSeries?.length
    ? loadedSeries?.length > selectedLoadedSeries?.length && selectedLoadedSeries?.length > 0
    : listOfSeries?.length !== 0 && listOfSeries?.length < study?.numberOfStudyRelatedSeries;

  const label = `${joinParams(
    [
      joinParams([
        get(study, 'patient.name.lastName', ''),
        get(study, 'patient.name.firstName', ''),
      ]),
      t('studyPatientId', {
        patientId: get(study, 'patient.identificationNumber', ''),
      }),
      t('studyDateBirth', {
        dateBirth: get(study, 'patient.dateBirth', false)
          ? format(new Date(study.patient.dateBirth), 'dd.MM.yyyy')
          : '',
      }),
      t('studySeriesImages', {
        series: get(study, 'numberOfStudyRelatedSeries', '?'),
        images: get(study, 'numberOfStudyRelatedInstances', '?'),
      }),
      t('studyDateTime', {
        dateTime: get(study, 'dateTime', false)
          ? format(new Date(study.dateTime), 'dd.MM.yyyy, HH:mm:ss')
          : '',
      }),
      !isEmpty(actualSize)
        ? joinParams(
            [
              t('size'),
              get(actualSize, study.iid) === 'calculationError'
                ? t(get(actualSize, study.iid))
                : get(actualSize, study.iid, '0.0 MB'),
            ],
            ': ',
          )
        : '',
    ],
    ' | ',
  )}`;

  const onViewer = (studyID: string, patientId: string, archiveID: string, seriesUID?: string) => {
    getUrlForOpenViewer(studyID, patientId, archiveID, seriesUID).then(
      (response) =>
        response ? window.open(response, '_blank') : addErrorAlert(t('cannotOpenLink')),
      (error) => addErrorAlert(t('cannotOpenLink')),
    );
  };

  const renderedLabel = (
    <>
      {label}
      {defViewer && (
        <Tooltip title={t('Studies:openInBrowser')} placement="right">
          <IconButton
            color="primary"
            aria-label="Report"
            onClick={() => {
              onViewer(
                get(study, 'studyInstanceUid', ''),
                get(study, 'patient.identificationNumber', ''),
                get(study, 'archive.id', '') as unknown as string,
              );
            }}
            sx={{ ...(compactMode ? { p: 0, mr: 2 } : {}) }}
          >
            <Image />
          </IconButton>
        </Tooltip>
      )}
    </>
  );

  return (
    <Box>
      {renderStudy ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {get(study, 'numberOfStudyRelatedSeries', 0) > 0 ? (
            <IconButton
              size="medium"
              color="inherit"
              aria-label="Series"
              onClick={() => setStudyOpened(study.iid)}
              sx={{ mr: 1, ...(compactMode ? { p: 0, mr: 2 } : {}) }}
            >
              {studyIsOpened ? <KeyboardArrowDown /> : <ChevronRight />}
            </IconButton>
          ) : null}
          {showStudiesCheckboxes ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={studyIsSelected}
                  indeterminate={studyIsIndeterminate}
                  sx={{ ...(compactMode ? { p: 0, mr: 1 } : {}) }}
                />
              }
              label={renderedLabel}
              onChange={(_event, value) => setStudySelected(study.iid)}
              sx={{ mr: 1 }}
            />
          ) : (
            renderedLabel
          )}
        </Box>
      ) : null}
      {studyIsOpened ? (
        <Box sx={{ mt: compactMode ? 0.5 : 1, display: 'flex' }}>
          <Box>
            {isArray(loadedSeries) && loadedSeries.length
              ? sortBy(loadedSeries, 'seriesNumber').map((serie, index) => (
                  <RenderSerie
                    key={serie.uid}
                    serie={serie}
                    study={study}
                    serieIndex={index}
                    studyIndex={studyIndex}
                    showSeriesCheckboxes={showSeriesCheckboxes}
                    showInstancesCheckboxes={showInstancesCheckboxes}
                    allowOpenSeries={allowOpenSeries}
                    operations={operations}
                  />
                ))
              : null}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';

import { useTranslation } from 'react-i18next';

export const TourQueueRequests = () => {
  const { t } = useTranslation('Reactour');

  const allSteps = () => {
    const steps = {
      '[data-tour="requestQueue-status"]': t('requestQueue.status'),
      '[data-tour="requestQueue-searchButton"]': t('requestQueue.searchButton'),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
      '.MuiDataGrid-columnHeaders': t('common.headTableWithSortAndFilter'),
      '[data-tour="queueRequestGrid"]': (
        <>
          {t('common.bodyTable')} {t('requestQueue.grid')}
        </>
      ),
    };
    return steps;
  };
  useReactTour({ stepsDefinition: allSteps });
  return <HelperComponent />;
};

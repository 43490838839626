import { useTranslation } from 'react-i18next';
import { useReactTour } from 'components/Tour/useReactTour';
import HelperComponent from 'components/Tour/HelperComponent';

interface IUseStepsProps {
  hasComments?: boolean;
  type?: 'main' | 'detail';
}

export const TourRequests = ({ hasComments, type }: IUseStepsProps) => {
  const { t } = useTranslation('Reactour');

  const allSteps = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="addButton"]': t('request.addButton'),
      '[data-tour="search-header-searchForm-searchFormFields"]': t('searchList.searchFormFields'),
      ...(isDropDownOpen && {
        '.select-react-tour-class': t('request.operationType'),
      }),
      '[data-tour="request-requestStatus"]': t('request.requestStatus'),
      '[data-tour="request-clear"]': t('request.clear'),
      '[data-tour="request-search"]': t('request.search'),
      '[data-tour="request-grid"]': t('request.grid'),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
    };
    return steps;
  };

  const allStepsDetails = () => {
    const steps = {
      '[data-tour="request-detail-mainSection"]': t('request.detail.mainSection'),
      ...(hasComments && {
        '[data-tour="request-detail-comments"]': t('request.detail.comments'),
      }),
      '[data-tour="request-detail-otherSections"]': t('request.detail.otherSections'),
      '[data-tour="request-detail-history"]': t('request.detail.history'),
    };
    return steps;
  };

  const stepsDefinition = type === 'main' ? allSteps : allStepsDetails;
  useReactTour({ stepsDefinition });
  return <HelperComponent />;
};

import { api } from 'utils/hooks/useApi';

export const setDarkMode = (setDarkMode: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/userSetting/setDarkMode?darkMode=${setDarkMode}`)
    .then((_response) => true)
    .catch((_error) => false);

export const saveDisplayMode = (compactModeEnabled: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/userSetting/setCompactMode`, { params: { compactModeEnabled } })
    .then((response) => true)
    .catch((error) => false);

import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { warning } from 'utils/variables';

export const Page404: React.FC = () => {
  const { t } = useTranslation('Homepage');

  return (
    <Typography
      sx={{
        p: 2,
        border: 1,
        borderRadius: 4,
        backgroundColor: warning.background,
        color: warning.color,
      }}
    >
      {t('pageNotFound')} {t('forMoreInformationPleaseContactAdministrator')}
    </Typography>
  );
};

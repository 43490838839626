import { useState, useEffect } from 'react';
import { get, isArray } from 'lodash';

import { useAppGlobals } from './useAppGlobals';
import { useActions } from './useActions';
import { useAppInfo } from './useAppInfo';
import { getTestStates, getTestTypes } from 'modules/Tests/_api';
import { useTests } from './useTests';
import { TFunction } from 'i18next';

export const useTestLists = (t: TFunction, prependNullableOption: boolean = false) => {
  const { toggleLoader } = useAppGlobals();
  const { setLists } = useActions();
  const { lists } = useAppInfo();
  const { mapTestTypesToSelectField, mapTestStatesToSelectField } = useTests(t);

  const [mappedTypes, setMappedTypes] = useState<any[]>([]);
  const [mappedStates, setMappedStates] = useState<any[]>([]);

  const loadTestLists = async () => {
    toggleLoader();
    let testTypes = get(lists, 'testTypes', null);
    if (!isArray(testTypes)) {
      testTypes = await getTestTypes();
      if (isArray(testTypes)) {
        setLists({ testTypes });
      }
    }
    if (isArray(testTypes)) {
      setMappedTypes(mapTestTypesToSelectField(testTypes, prependNullableOption));
    }

    let testStates = get(lists, 'testStates', null);
    if (!isArray(testStates)) {
      testStates = await getTestStates();
      if (isArray(testStates)) {
        setLists({ testStates });
      }
    }
    if (isArray(testStates)) {
      setMappedStates(mapTestStatesToSelectField(testStates, prependNullableOption));
    }

    toggleLoader(false);
  };

  useEffect(() => {
    loadTestLists();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { mappedTypes, mappedStates };
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { editLanguage, getAllLanguages } from './_apiLanguages';
import { ILanguage } from './_types';
import LanguageForm from './LanguageForm';
import Header from 'components/Header/Header';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'languagesMUI';

const Languages: React.FC = () => {
  const { t } = useTranslation('Languages');
  useWithTitle();
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();

  const [dialogState, setDialogState] = useState<boolean>(false);
  const [rows, setRows] = useState<ILanguage[]>([]);
  const [id, setId] = useState<number>(0);

  const loadEntity = async () => {
    toggleLoader();
    try {
      setId(0);
      const response = await getAllLanguages();
      if (response) {
        setRows(response);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const toggleDialog = (state: boolean = false) => {
    setDialogState(state);
  };

  const onEntityEdit = async (id: number) => {
    setId(id);
    toggleDialog(id > 0 ? true : false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pomid = id ? id : dialogState ? 'create' : null;
  const renderedDialog = (
    <OverflowedDialog
      open={dialogState}
      onClose={() => {
        toggleDialog(false);
        setId(0);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <LanguageForm toggleDialog={toggleDialog} id={pomid} loadEntity={loadEntity} />
    </OverflowedDialog>
  );

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const onChangeDefault = async (id: any) => {
    await editLanguage({ id, default: true });
    loadEntity();
  };

  const confirmActionChangeDefault = () => {
    onChangeDefault(get(confirmationData, 'id'));
    clearAction();
  };

  const onChangeActive = async (id: number, active: boolean) => {
    await editLanguage({ id, active });
    loadEntity();
  };

  const confirmActionChangeActive = () => {
    onChangeActive(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 320,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.default}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeState',
                            id: get(row, 'id'),
                            state,
                            confirmAction: 'confirmActionChangeDefault',
                          }),
                        );
                      }}
                    />
                  }
                  label={row.default ? (t('Grid:default') as string) : <Box sx={{ ml: '50px' }} />}
                  disabled={row.default}
                />
              </Box>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.active}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeState',
                            id: get(row, 'id'),
                            state,
                            confirmAction: 'confirmActionChangeActive',
                          }),
                        );
                      }}
                    />
                  }
                  label={t(row.active ? 'Grid:active' : 'Grid:inactive') as string}
                  disabled={get(row, 'activeFacility', false) || get(row, 'disabledRow', false)}
                />
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onEntityEdit(get(row, 'id'))}
              />
            </>
          );
        },
      },
      { field: 'abbreviation', headerName: t('abbreviation') },
      { field: 'name', headerName: t('name') },
    ]),
  );

  return (
    <>
      <Header title={''} />
      <MuiGrid
        gridKey={muiGridKey}
        rows={rows}
        columns={columns}
        initialSortMode={[{ field: 'abbreviation', sort: 'asc' }]}
      />
      {renderedDialog}
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={
            confirmationData.confirmAction === 'confirmActionChangeDefault'
              ? confirmActionChangeDefault
              : confirmActionChangeActive
          }
        />
      )}
    </>
  );
};

export default Languages;

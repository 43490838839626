import React, { useEffect, useMemo, useState } from 'react';
import {
  each,
  find,
  forIn,
  get,
  head,
  includes,
  isArray,
  isEmpty,
  keys,
  last,
  omit,
  pickBy,
  pull,
  reduce,
  split,
  without,
} from 'lodash';
import { useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import { addMinutes, addHours, addSeconds } from 'date-fns';

import { getTest, executeTest, finishTest } from './_api';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ViewerIcon from '@mui/icons-material/Image';
import Header from 'components/Header/Header';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import WarningIcon from '@mui/icons-material/WarningSharp';
import { Papeer } from 'components/Papeer/Papeer';

import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { joinParams } from 'utils/study';
import styles from './_styles';
import { IField } from './_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { Announcement } from 'components/Announcement/Announcement';
import { GridIconButton } from 'components/Buttons/GridIconButton';
import {
  getUrlForOpenViewer,
  getAllComments,
  getPreviewWithSize,
} from 'modules/Studies/StudyDetail/_api';
import useAlerts from 'components/Alerts/useAlerts';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useTests } from 'utils/hooks/useTests';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { SmallButton } from 'components/Form/SmallButton/SmallButton';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ManualConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { ErrorMessage } from 'components/Form/Messages/ErrorMessage';
import CheckboxForm from 'components/Form/Checkbox/Checkbox';
import InputForm from 'components/Form/Input/Input';
import { Img } from 'components/Image/Img';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { encryptId } from 'utils/hooks/useApp';

const hashedId = true;
const linkBack = '/tests/startTest';
const previewWidth = 150;

const booleanRow = (value: boolean) =>
  value ? <CheckIcon sx={styles.icon} /> : <CancelIcon sx={styles.icon} />;

export const StartedTest: React.FC = () => {
  const { t } = useTranslation('Tests');
  const { toggleLoader } = useAppGlobals();
  const { mamoMonitorConfig, defViewer, compactMode } = useAppInfo();
  const params = useParams();
  const location = useLocation();
  const { addErrorAlert } = useAlerts();
  const navigate = useNavigate();
  const { setPageTitle } = useWithTitle();
  const { isStudyTestType, parseStudyQuestions, padNumber } = useTests(t);
  const { hasRole } = useUser();
  const methods = useForm<any>();

  const [title, setTitle] = useState<any>('');
  const [entity, setEntity] = useState<any>(undefined);
  const [executedTest, setExecutedTest] = useState<any>(undefined);
  const [executedTestTitle, setExecutedTestTitle] = useState<any>('');
  const [zoomTimer, setZoomTimer] = useState<Boolean>(false);
  const [allQuestionsAndAnswers, setAllQuestionsAndAnswers] = useState<any>([]);
  const [testTimeout, setTestTimeout] = useState<Boolean>(false);
  const [commentReveled, setCommentReveled] = useState<Boolean>(false);
  const [wantToFinish, setWantToFinish] = useState<Boolean>(false);
  const [showDetail, setShowDetail] = useState<Boolean>(false);
  const [expandedPanels, setExpandedPanels] = useState<any>([]);
  const [previewsLoading, setPreviewsLoading] = useState<Boolean>(false);
  const [previews, setPreviews] = useState<any>([]);
  const [isStudyExecutedTestType, setIsStudyExecutedTestType] = useState<Boolean>(false);
  const [formError, setFormError] = useState<any>(null);
  const [timestamp, setTimestamp] = useState<any>(Date.now());
  const [openedViewerIds, setOpenedViewerIds] = useState<any>([]);
  const [dialogState, toggleDialog] = useState<boolean>(false);

  const { id } = useEntityInfo(hashedId);
  const isTrial = get(location, 'state.trial', false);
  const testIsUnfinished = get(params, 'testIsUnfinished', false) ? true : false;

  const fieldsForDetail = useMemo(
    () => [
      {
        name: 'name',
        label: t('name'),
        value: get(entity, 'name'),
      },
      {
        name: 'description',
        label: t('description'),
        value: get(entity, 'description'),
      },
      {
        name: 'maxWrongAnswersAllowed',
        label: t('maxWrongAnswersAllowed'),
        value: get(entity, 'maxWrongAnswersAllowed'),
      },
      {
        name: 'repeatedly',
        label: t('repeatedly'),
        value: booleanRow(get(entity, 'repeatedly')),
      },
      {
        name: 'allowPreview',
        label: t('allowPreview'),
        value: booleanRow(get(entity, 'allowPreview', false)),
      },
      {
        name: 'maxTestLength',
        label: t('maxTestLength'),
        value: get(entity, 'maxTestLength'),
      },
      {
        name: 'createdBy',
        label: t('author'),
        value: `${joinParams([
          get(entity, 'createdBy.prefix'),
          get(entity, 'createdBy.lastName'),
          get(entity, 'createdBy.firstName'),
          get(entity, 'createdBy.middleName'),
          get(entity, 'createdBy.suffix'),
        ])}`,
      },
    ],
    [entity, t],
  );

  const questionsCount = get(executedTest, 'questions', []).length;

  const allPanelsExpanded = questionsCount === expandedPanels.length;

  const handlePanelChange = (questionID: any) => {
    setExpandedPanels(
      includes(expandedPanels, questionID)
        ? without(expandedPanels, questionID)
        : [...expandedPanels, questionID],
    );
  };

  const showCommentField = () => {
    setCommentReveled(true);
  };

  const toggleCommentField = () => {
    setCommentReveled((value) => !value);
  };

  const setCommentField = (comment: any) => {
    methods.setValue('comment', comment);
    showCommentField();
  };

  const closeAllResults = () => {
    setExpandedPanels([]);
  };

  const setNewPreviews = (newPreview: any) => {
    setPreviews((prevPreviews: any) => [...prevPreviews, newPreview]);
  };

  const loadPreview = async (archiveID: string, studyID: string, serieId: string) => {
    if (!find(previews, { uid: serieId })) {
      const img = await getPreviewWithSize(archiveID, studyID, serieId, undefined, previewWidth);
      setNewPreviews({ uid: serieId, img });
      return true;
    } else {
      return false;
    }
  };

  const formValues = omit(methods.watch(), 'comment');
  const comment = get(methods.watch(), 'comment');

  const getTargetValues = () => {
    let targetValues: any = {};
    each(keys(pickBy(formValues)), (key) => {
      const [testStepExecutionId, answerId] = split(key, '_');
      targetValues[testStepExecutionId] = targetValues[testStepExecutionId] || [];
      targetValues[testStepExecutionId].push(answerId);
    });
    return targetValues;
  };

  const targetValues = getTargetValues();

  const numberOfUnanswered = questionsCount - keys(targetValues).length;

  const titleUnansweredQuestions =
    numberOfUnanswered > 0 ? t('unansweredQuestions', { numberOfUnanswered, questionsCount }) : '';

  const openAllResults = () => {
    const questions = get(executedTest, 'questions', []);
    if (isArray(questions)) {
      setExpandedPanels(questions.map((QA) => get(QA, 'question.id')));
    } else {
      setExpandedPanels([]);
    }
  };

  const openAllResults2 = async () => {
    openAllResults();
    const questions = get(executedTest, 'questions', []);
    if (get(entity, 'allowPreview')) {
      setPreviewsLoading(true);
      await Promise.all(
        questions.map((question: any) => {
          const study = get(question, 'study');
          const archiveID = get(study, 'archive.id');
          const studyID = get(study, 'studyInstanceUid');
          const series = get(study, 'series', []);
          return loadPreview(archiveID, studyID, get(head(series), 'uid') || '');
        }),
      );
      setPreviewsLoading(false);
    }
  };

  const getCommentsForStudies = async (questions: any) => {
    if (!isArray(questions)) {
      return [];
    }

    try {
      return await Promise.all(
        questions.map((question) =>
          getAllComments({
            product: { id: question.study.archive.id },
            studyUid: question.study.studyInstanceUid,
          }),
        ),
      );
    } catch (e) {
      return [];
    }
  };

  const setSelectedAnswers = (questionID: any, answerID: any, value: boolean) => {
    if (value) {
      forIn(formValues, (val, key) => {
        const [valueId, valueAnswerId] = split(key, '_');
        if (questionID.toString() === valueId && answerID.toString() !== valueAnswerId) {
          methods.setValue(key, false);
        }
      });
    }
  };

  const testFinish = async () => {
    toggleLoader();
    const reducedTarget = reduce(
      targetValues,
      (result: any, value, key) => {
        result.push({ testStepExecutionId: key, answerId: value });
        return result;
      },
      [],
    );

    const testExecutionId = get(executedTest, 'testExecution.id');
    const currentQuestion = {
      answers: reducedTarget,
      testExecutionId,
      ...(comment ? { comment } : {}),
    };

    await finishTest(currentQuestion).then(
      (response: any) => {
        navigate(`/tests/results/${hashedId ? encryptId(testExecutionId) : testExecutionId}`);
      },
      (error: any) => {
        setFormError('testFinishFail');
      },
    );
    toggleLoader(false);
  };

  const executeTestHandler = async () => {
    toggleLoader();
    executeTest(id as number, isTrial).then(
      async (response) => {
        toggleLoader(false);
        try {
          const isStudyType = isStudyTestType(get(response, 'testExecution.test'));
          let parsedResponse = isStudyType
            ? {
              ...response,
              questions: parseStudyQuestions(response.questions),
            }
            : response;

          setExecutedTest(parsedResponse);
          setExecutedTestTitle(
            `${t('currentTest')} ${get(response, 'testExecution.test.name', '')}`,
          );
          setIsStudyExecutedTestType(isStudyTestType(get(response, 'testExecution.test')));
          setAllQuestionsAndAnswers(get(parsedResponse, 'questions', []));

          if (isStudyType && hasRole('ROLE_VIEW_COMMENT_IN_MAMO_TEST')) {
            const comments = await getCommentsForStudies(parsedResponse.questions);
            parsedResponse = {
              ...parsedResponse,
              questions: parsedResponse.questions.map((question: any, index: any) => ({
                ...question,
                comment: get(last(comments[index]), 'text', ''),
              })),
            };
          }

          setAllQuestionsAndAnswers(get(parsedResponse, 'questions', []));

          const oldComment = get(response, 'question.comment');
          if (oldComment) {
            setCommentField(oldComment);
          }

          const title = `${t('currentTest')} ${get(response, 'testExecution.test.name', '')}`;
          setTitle(title);
          setPageTitle(title);
        } catch (e) {
          console.debug(e);
          addErrorAlert(t('errorExecutingTest'));
          navigate(linkBack);
        }
      },
      (error) => {
        addErrorAlert(t('errorExecutingTest'));
        navigate(linkBack);
      },
    );
  };

  const onViewer = async (QA: any) => {
    const study = get(QA, 'study');
    const patientId = get(study, 'patient.identificationNumber', '');
    const archiveID = get(study, 'archive.id');
    const studyID = get(study, 'studyInstanceUid');
    const questionId = get(QA, 'question.id');
    setOpenedViewerIds((items: any) => [...items, questionId]);
    await getUrlForOpenViewer(studyID, patientId, archiveID).then(
      (response) => {
        response ? window.open(response, '_blank') : addErrorAlert(t('Errors:cannotOpenLink'));
        setOpenedViewerIds((items: any) => [...pull(items, questionId)]);
      },
      (error) => {
        addErrorAlert(t('Errors:cannotOpenLink'));
        setOpenedViewerIds((items: any) => [...pull(items, questionId)]);
      },
    );
  };

  const RenderTestDetail = () => (
    <Papeer sx={{ m: 1 }}>
      <Grid container={true} spacing={2}>
        {fieldsForDetail.map((field: IField) => (
          <Grid item={true} xs={6} md={4} lg={3} key={`test_detail${field.name}`}>
            <label style={styles.label}>{field.label}</label>
            <div>{field.value}</div>
          </Grid>
        ))}
      </Grid>
      {(get(mamoMonitorConfig, 'mamoMonitorConfigLink', '') ||
        (defViewer &&
          get(mamoMonitorConfig, 'studyID', '') &&
          get(mamoMonitorConfig, 'archiveID', '') &&
          get(mamoMonitorConfig, 'patientId', ''))) && (
          <Grid container={true} spacing={16}>
            <Grid item={true} xs={12}>
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <Announcement
                  type={'info'}
                  spaced={true}
                  component={'div'}
                  label={
                    get(mamoMonitorConfig, 'mamoMonitorConfigLink', '') && `${t('announcementText')}`
                  }
                >
                  {get(mamoMonitorConfig, 'mamoMonitorConfigLink', '') &&
                    `${t('announcementText')}`}
                  <Typography
                    component="span"
                    color="inherit"
                    noWrap={true}
                    style={{ display: 'inline' }}
                  >
                    {get(mamoMonitorConfig, 'mamoMonitorConfigLink', '') && (
                      <Box component="a" onClick={() => toggleDialog(true)} sx={styles.linkTutorial}>
                        {t('announcementLink')}
                      </Box>
                    )}
                  </Typography>
                  {defViewer &&
                    get(mamoMonitorConfig, 'studyID', '') &&
                    get(mamoMonitorConfig, 'archiveID', '') &&
                    get(mamoMonitorConfig, 'patientId', '') && (
                      <div>
                        <Typography
                          component="span"
                          color="inherit"
                          noWrap={true}
                          style={{ display: 'inline' }}
                        >
                          {t('announcementViewerTitle')}
                          <GridIconButton
                            title={t('openInBrowserTitle')}
                            onClick={() => {
                              const studyID = get(mamoMonitorConfig, 'studyID', '');
                              const archiveID = get(mamoMonitorConfig, 'archiveID', '');
                              const patientId = get(mamoMonitorConfig, 'patientId', '');
                              getUrlForOpenViewer(studyID, patientId, archiveID).then(
                                (response) =>
                                  response
                                    ? window.open(response, '_blank')
                                    : addErrorAlert(t('cannotOpenLink')),
                                (error) => addErrorAlert(t('cannotOpenLink')),
                              );
                            }}
                            CustomIcon={ViewerIcon}
                          // isInline={true}
                          />
                        </Typography>
                      </div>
                    )}
                </Announcement>
              </div>
            </Grid>
          </Grid>
        )}
      {executedTest === undefined && !testIsUnfinished && (
        <Grid container={true} alignItems="flex-end" justifyContent="space-between" spacing={2}>
          <Grid item={true} xs={12}>
            <Box id="search-results" sx={styles.startButton}>
              <Box sx={styles.button}>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={() => executeTestHandler()}
                >
                  {t('startTest')}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Papeer>
  );

  const RenderQuestionsAndAnswers = () => (
    <Typography component="div" key={timestamp}>
      <div>
        {allQuestionsAndAnswers.map((QA: any, index: any) => {
          const questionID = get(QA, 'question.id');
          const isUnanswered = isEmpty(get(targetValues, questionID, []));
          const study = get(QA, 'study');
          const patientId = get(study, 'patient.identificationNumber', '');
          const archiveID = get(study, 'archive.id');
          const studyID = get(study, 'studyInstanceUid');
          const series = get(study, 'series', []);

          return (
            <Accordion
              key={`${questionID}_${index}`}
              expanded={includes(expandedPanels, questionID)}
              onChange={() => {
                handlePanelChange(questionID);
                if (get(entity, 'allowPreview')) {
                  loadPreview(archiveID, studyID, get(head(series), 'uid') || '');
                }
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid
                  container={true}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>
                    <div>
                      <Box component="span" sx={styles.questionOrder}>
                        {index + 1}/{allQuestionsAndAnswers.length}
                      </Box>
                      {!isStudyExecutedTestType && get(QA, 'question.name', '')}
                    </div>
                  </Typography>
                  {isUnanswered ? (
                    <Tooltip key={`isUnanswered_${index}`} title={t('isUnanswered')}>
                      <WarningIcon />
                    </Tooltip>
                  ) : null}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="div" sx={styles.answers}>
                  <Grid container={true} spacing={2}>
                    {isStudyExecutedTestType && (
                      <Grid item={true} xs={12}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => onViewer(QA)}
                            disabled={openedViewerIds.includes(questionID)}
                          >
                            <ViewerIcon /> {t('showInBrowser')}
                          </Button>
                          {openedViewerIds.includes(questionID) && (
                            <div style={{ marginLeft: '10px' }}>
                              {t('redirectingToBrowser')} <CircularProgress size={20} />
                            </div>
                          )}
                        </div>
                      </Grid>
                    )}
                    <Grid item={true} xs={12} sm={4} lg={3}>
                      <Grid container={true} spacing={1}>
                        {!isEmpty(get(QA, 'answers', [])) &&
                          keys(get(QA, 'answers', [])).map((answerID) => (
                            <React.Fragment key={answerID}>
                              <Grid item={true} xs={12}>
                                {isStudyExecutedTestType ? (
                                  <CheckboxForm
                                    name={`${questionID}_${answerID}`}
                                    label={get(QA, `answers.${answerID}`, '')}
                                    onChange={(value: any) =>
                                      setSelectedAnswers(questionID, answerID, value)
                                    }
                                  />
                                ) : (
                                  <CheckboxForm
                                    name={`${questionID}_${answerID}`}
                                    label={get(QA, `answers.${answerID}`, '')}
                                  />
                                )}
                              </Grid>
                            </React.Fragment>
                          ))}
                      </Grid>
                    </Grid>
                    {get(entity, 'allowPreview') && (
                      <Grid item={true} xs={12} sm={4} lg={3}>
                        {isStudyExecutedTestType && (
                          <Img
                            patientId={patientId}
                            productId={archiveID}
                            studyIUID={studyID}
                            seriesIUID={get(head(series), 'uid') || ''}
                            topIndex={0}
                            width={previewWidth}
                            dialogPreview={false}
                            preview={find(previews, { uid: get(head(series), 'uid') })}
                            setNewPreviews={setNewPreviews}
                            canLoadImgs={false}
                          />
                        )}
                      </Grid>
                    )}
                    <Grid item={true} xs={12} sm={4} lg={4}>
                      {isStudyExecutedTestType && hasRole('ROLE_VIEW_COMMENT_IN_MAMO_TEST') ? (
                        get(QA, 'comment') === undefined && get(QA, 'comment') !== '' ? (
                          <div>{t('loadingComment')}</div>
                        ) : (
                          <div>{get(QA, 'comment', '')}</div>
                        )
                      ) : null}
                    </Grid>
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </Typography>
  );

  const RenderedDialog = (
    <OverflowedDialog
      title={t('announcementTextVideo')}
      open={dialogState}
      onClose={() => {
        toggleDialog(false);
      }}
      aria-labelledby="video-dialog-title"
      maxWidth="lg"
      fullWidth={true}
      withScrolling={false}
      toggleDialog={toggleDialog}
    // smallerTitleBottomPadding={true}
    >
      <Typography component="div">
        {t('announcementDownloadLinkText')}{' '}
        <Box
          component="a"
          href={get(mamoMonitorConfig, 'mamoMonitorConfigLink', '')}
          sx={styles.linkTutorial}
        >
          {t('announcementDownloadLink')}
        </Box>
      </Typography>
      <video controls={true} autoPlay={true} muted={true} width="100%">
        <source src={get(mamoMonitorConfig, 'mamoMonitorConfigLink', '')} />
      </video>
    </OverflowedDialog>
  );

  const CountdownRenderer = () => {
    const startedWhen = get(executedTest, 'testExecution.startedWhen');
    const maxTestLength = get(executedTest, 'testExecution.test.maxTestLength');

    if (startedWhen && maxTestLength) {
      const convertedStartedWhen = new Date(startedWhen);
      const [maxHours, maxMinutes, maxSeconds] = maxTestLength.split(':');
      const maxDate = addSeconds(
        addMinutes(addHours(convertedStartedWhen, maxHours), maxMinutes),
        maxSeconds,
      );

      const renderer = ({ hours, minutes, seconds, completed }: any) => {
        if (completed) {
          return <div>{t('timeIsUp')}</div>;
        } else {
          return (
            <span>
              {padNumber(hours)}:{padNumber(minutes)}:{padNumber(seconds)}
            </span>
          );
        }
      };

      const tick = ({ hours, minutes, seconds }: any) => {
        const timeToEndWhileAnimating = get(mamoMonitorConfig, 'timeToEndWhileAnimating');
        if (timeToEndWhileAnimating < 60) {
          if (hours === 0 && minutes === 0 && seconds <= timeToEndWhileAnimating) {
            setZoomTimer(!zoomTimer);
          } else {
            setZoomTimer(false);
          }
        } else {
          const timeToEndWhileAnimatingMin = timeToEndWhileAnimating / 60;
          const timeToEndWhileAnimatingSec = timeToEndWhileAnimating % 60;
          if (timeToEndWhileAnimatingMin < 60) {
            if (
              hours === 0 &&
              ((minutes === timeToEndWhileAnimatingMin && seconds <= timeToEndWhileAnimatingSec) ||
                minutes < timeToEndWhileAnimatingMin)
            ) {
              setZoomTimer(!zoomTimer);
            } else {
              setZoomTimer(false);
            }
          } else {
            const timeToEndWhileAnimatingHour = timeToEndWhileAnimatingMin / 60;
            const timeToEndWhileAnimatingMin2 = timeToEndWhileAnimatingMin % 60;
            if (
              (hours === timeToEndWhileAnimatingHour &&
                minutes === timeToEndWhileAnimatingMin2 &&
                seconds <= timeToEndWhileAnimatingSec) ||
              (hours === timeToEndWhileAnimatingHour && minutes <= timeToEndWhileAnimatingMin2) ||
              hours < timeToEndWhileAnimatingHour
            ) {
              setZoomTimer(!zoomTimer);
            } else {
              setZoomTimer(false);
            }
          }
        }
      };

      const complete = ({ completed }: any) => {
        if (completed) {
          setTestTimeout(completed);
        }
      };

      return (
        <Typography
          component="div"
          sx={styles.time}
          style={{
            transform: zoomTimer ? 'scale(1.3)' : 'scale(1)',
            color: zoomTimer ? '#ed5565' : '',
          }}
        >
          <Countdown
            date={maxDate}
            renderer={renderer}
            zeroPadTime={2}
            onTick={tick}
            onComplete={complete}
          />
        </Typography>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    setTimestamp(Date.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedViewerIds]);

  const loadTest = async (id: number) => {
    const test = await getTest(id);
    if (test) {
      setEntity(test);
      setTitle(t('startingTest'));
      if (testIsUnfinished) {
        executeTestHandler();
      }
    }
  };

  useEffect(() => {
    loadTest(id as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      {executedTest === undefined && !testIsUnfinished && (
        <Typography component="div" /*className={classes.noPrint}*/>
          <Header title={title} backUrl={linkBack} />
          <RenderTestDetail />
        </Typography>
      )}
      {executedTest !== undefined && (
        <Typography component="div">
          <Box
            sx={[
              styles.headerWrapperFixed,
              ...(compactMode ? [styles.headerWrapperFixedForCompactMode] : []),
            ]}
          >
            <Box sx={styles.headerWrapper}>
              <Header title={executedTestTitle} />
              <Box sx={styles.headerRightWrapper}>
                <Box sx={styles.timeWrapper}>
                  <TimelapseIcon />
                  <CountdownRenderer />
                </Box>
                <Box sx={styles.finishButton}>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => setWantToFinish(true)}
                  >
                    {t('finish')}
                  </Button>
                </Box>
                <Box
                  sx={[styles.question, styles.questionClickable]}
                  onClick={() => {
                    setShowDetail((value: Boolean) => !value);
                  }}
                >
                  {showDetail ? (
                    <ArrowUpIcon sx={styles.questionIcon} />
                  ) : (
                    <ArrowDownIcon sx={styles.questionIcon} />
                  )}
                </Box>
              </Box>
            </Box>
            {showDetail && <RenderTestDetail />}
          </Box>
          <Box mt={2}>
            <Typography component="div">
              {isStudyExecutedTestType && (
                <Box sx={styles.errorMessage}>
                  <Announcement label={t('isStudyExecutedTestType')} type="info" spaced={true} />
                </Box>
              )}
              {formError && (
                <Box sx={styles.errorMessage}>
                  <ErrorMessage textError={formError} variant="body2" />
                </Box>
              )}
              <Header
                title={t('yourQuestions')}
                button={
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: '8px',
                      marginTop: '8px',
                    }}
                  >
                    <SmallButton
                      sx={styles.openAllButton}
                      variant="contained"
                      onClick={allPanelsExpanded ? closeAllResults : openAllResults2}
                    >
                      {allPanelsExpanded ? (
                        <ExpandLessIcon sx={styles.openAllButtonIcon} />
                      ) : (
                        <ExpandMoreIcon sx={styles.openAllButtonIcon} />
                      )}{' '}
                      {t(allPanelsExpanded ? 'closeAll' : 'openAll')}
                    </SmallButton>
                    {previewsLoading && (
                      <div style={{ marginLeft: '5px', fontSize: '0.875rem', fontWeight: 400 }}>
                        {t('loadingPreviews')} <CircularProgress size={20} />
                      </div>
                    )}
                  </div>
                }
              />
              <RenderQuestionsAndAnswers />
              <Papeer sx={{ mt: 2, p: compactMode ? 0.5 : 1 }}>
                <Box
                  sx={[styles.question, styles.questionClickable, { fontSize: 16 }]}
                  onClick={() => toggleCommentField()}
                >
                  {commentReveled ? (
                    <ArrowUpIcon sx={styles.questionIcon} />
                  ) : (
                    <ArrowDownIcon sx={styles.questionIcon} />
                  )}{' '}
                  {t('comment')}
                </Box>

                {commentReveled && (
                  <InputForm
                    name="comment"
                    label={t('comment')}
                    fullWidth={true}
                    bottomMargin={true}
                    multiline={true}
                    rows={4}
                  />
                )}
              </Papeer>
            </Typography>

            {/*
          // Zjistil jsem, že to nic nedělá, tak jsem to zakomentoval. Asi by to mělo ukazovat kolik otázek je vyplněno, po domluvě s ToV jsem to zakomentoval. 
          <div className={classes.progressBar}>
            <LinearProgress variant="determinate" value={progress} />
            </div>*/}
          </Box>
        </Typography>
      )}
      {testTimeout && (
        <>
          <ConfirmationDialog
            title={t('timeIsUp')}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            actionCloseShow={false}
            cancelAction={() => {
              console.warn('No cancel action allowed!');
            }}
            confirmAction={testFinish}
          >
            <Typography component="div">{t('timeIsUpDialogText')}</Typography>
          </ConfirmationDialog>
        </>
      )}
      {wantToFinish && (
        <>
          <ManualConfirmationDialog
            title={t('reallyCompleteTheTest')}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={() => setWantToFinish(false)}
            confirmAction={testFinish}
            actionCloseTitle={t('back')}
          >
            {titleUnansweredQuestions ? (
              <Typography component="div">{titleUnansweredQuestions}</Typography>
            ) : null}
          </ManualConfirmationDialog>
        </>
      )}
      {RenderedDialog}
    </FormProvider>
  );
};

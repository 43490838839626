import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = () => {
  const { required, integer, number, positive } = useValidators();

  const TestTypeDefinitionsFormSchema = yup.object().shape({
    step: yup.object({
      label: required(yup.string()),
      position: integer(positive(number(yup.number(), 'positiveWithoutZero'))),
      answers: yup.array().of(
        yup.object().shape({
          text: required(yup.string()),
        }),
      ),
    }),
  });

  return {
    TestTypeDefinitionsFormSchema,
  };
};

export default useValidationSchema;

import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';
import { compact, get } from 'lodash';
import { useValidation } from 'utils/hooks/useValidation';

const useValidationSchema = (
  t: TFunction,
  isCreating: boolean,
  validators: any,
  dictionaryLists: any,
  dictionarySelect: any,
  useListOfPhysicians: any,
  seeRequestingPhysician: boolean,
  seeReferringPhysician: boolean,
  hasRole: any,
) => {
  const {
    required,
    requiredAllowedCharsWithListAllowedChars,
    requiredAllowedChars,
    requiredEnabledNotFutureObject,
    requiredOrNotObject,
  } = useValidators();
  const {
    isEnabled,
    allRequired,
    patientRequired,
    allMin,
    patientMin,
    allAllowedChars,
    patientAllowedChars,
    allMessage,
    patientMessage,
    allMatches,
    patientMatches,
  } = useValidation(t, validators);

  const patientObj = (name: any) => ({
    name,
    isRequired: patientRequired(name),
    minimum: patientMin(name),
    allowedChars: patientAllowedChars(name),
    message: patientMessage(name),
    matches: patientMatches(name),
  });
  const otherObj = (name: any, validatorsName: any, withRepeat: boolean = false) => ({
    name,
    isRequired: allRequired(validatorsName),
    minimum: allMin(validatorsName),
    allowedChars: allAllowedChars(validatorsName),
    message: allMessage(validatorsName),
    matches: allMatches(validatorsName, withRepeat),
  });
  const dateObj = (fieldName: any, name: any, isEnabledName: any) => ({
    fieldName,
    isRequired: allRequired(name),
    isEnabled: isEnabled(isEnabledName),
  });
  const requiredObj = (fieldName: any, name: any) => ({
    fieldName,
    isRequired: allRequired(name),
  });

  const OrderFormSchema = yup.object().shape({
    ...(!isCreating
      ? {
          state: required(yup.string()),
        }
      : {}),
    patient: yup.object({
      name: yup.object({
        lastName: requiredAllowedCharsWithListAllowedChars(yup.string(), patientObj('lastName')),
        firstName: requiredAllowedCharsWithListAllowedChars(yup.string(), patientObj('firstName')),
        middleName: requiredAllowedCharsWithListAllowedChars(
          yup.string(),
          patientObj('middleName'),
        ),
        prefix: requiredAllowedCharsWithListAllowedChars(yup.string(), patientObj('prefix')),
        suffix: requiredAllowedCharsWithListAllowedChars(yup.string(), patientObj('suffix')),
      }),

      identificationNumber: requiredAllowedCharsWithListAllowedChars(
        yup.string(),
        otherObj('identificationNumber', 'patient.identification', true),
      ),

      ...requiredEnabledNotFutureObject(
        yup.date(),
        dateObj('dateBirth2', 'patient.dateBirth', 'patient.dateBirth.futureAllowed'),
        'required',
      ),

      ...requiredOrNotObject(yup.string(), requiredObj('sex', 'patient.sex')),
    }),
    ...requiredOrNotObject(yup.mixed(), requiredObj('healthInsuranceCompany2', 'healthInsurance')),

    ...requiredEnabledNotFutureObject(
      yup.date(),
      dateObj(
        'originalDateRequested',
        'studyOrder.studyOrderDate',
        'studyOrder.studyOrderDate.futureAllowed',
      ),
      'required',
    ),

    ...requiredOrNotObject(
      yup.string(),
      requiredObj('originalTimeRequested', 'studyOrder.studyOrderTime'),
    ),

    diagnosisBasic: requiredAllowedChars(
      yup.string(),
      otherObj('diagnosisBasic', 'studyOrder.diagnosisBasic'),
    ),

    diagnosisOther: requiredAllowedCharsWithListAllowedChars(
      yup.string(),
      otherObj('diagnosisOther', 'studyOrder.diagnosisOther', true),
    ),

    ...requiredOrNotObject(yup.mixed(), requiredObj('expertness2', 'studyOrder.expertness')),

    icp: requiredAllowedCharsWithListAllowedChars(yup.string(), otherObj('icp', 'icp', true)),

    patientDispositions: yup.object({
      ...requiredOrNotObject(yup.string(), requiredObj('size', 'patientDispositions.size')),
      ...requiredOrNotObject(yup.string(), requiredObj('weight', 'patientDispositions.weight')),
    }),

    priority: yup.object({
      ...requiredOrNotObject(yup.string(), requiredObj('id', 'studyOrder.urgency')),
    }),

    ...requiredOrNotObject(
      yup.mixed(),
      requiredObj('requestedProcedure2', 'studyOrder.requestedProcedure'),
    ),

    modality: yup.object({
      ...requiredOrNotObject(yup.string(), requiredObj('id', 'studyOrder.modality')),
    }),

    station: yup.object({
      ...requiredOrNotObject(yup.string(), requiredObj('id', 'studyOrder.station')),
    }),
    unit: yup.object({
      ...requiredOrNotObject(yup.string(), requiredObj('id', 'studyOrder.unit')),
    }),
  });

  const setPhysician = (whatPhysician: string, validators: any) => {
    return [
      useListOfPhysicians
        ? {
            type: 'select2',
            name: `${whatPhysician}2`,
            label: t(whatPhysician),
            options:
              whatPhysician === 'requestingPhysician'
                ? get(dictionarySelect, 'requestingPhysicianSelect')
                : get(dictionarySelect, 'referringPhysicianSelect'),
            grid: { xs: 11, sm: 5, lg: 3 },
          }
        : { type: 'div', label: t(whatPhysician), name: whatPhysician },
      useListOfPhysicians && hasRole('ROLE_DICTIONARY')
        ? {
            type: 'iconButton',
            label: t(whatPhysician),
            name: whatPhysician,
            grid: { xs: 1, sm: 1, lg: 1 },
            tooltip: t('addPhysician'),
          }
        : null,
      {
        name: `${whatPhysician}_name_lastName`,
        label: t(`lastName`),
        required: get(validators, `studyOrder.${whatPhysician}.name.lastName.required`, false),
        inputProps: {
          maxLength: get(validators, `studyOrder.${whatPhysician}.name.lastName.max`, 44),
        },
        disabled: useListOfPhysicians,
      },
      {
        name: `${whatPhysician}_name_firstName`,
        label: t(`firstName`),
        required: get(validators, `studyOrder.${whatPhysician}.name.firstName.required`, false),
        inputProps: {
          maxLength: get(validators, `studyOrder.${whatPhysician}.name.firstName.max`, 44),
        },
        disabled: useListOfPhysicians,
      },
      {
        name: `${whatPhysician}_name_middleName`,
        label: t(`middleName`),
        required: get(validators, `studyOrder.${whatPhysician}.name.middleName.required`, false),
        inputProps: {
          maxLength: get(validators, `studyOrder.${whatPhysician}.name.middleName.max`, 44),
        },
        disabled: useListOfPhysicians,
      },
      {
        name: `${whatPhysician}_name_prefix`,
        label: t(`prefix`),
        grid: useListOfPhysicians ? { xs: 12, sm: 6, lg: 1 } : { xs: 12 },
        required: get(validators, `studyOrder.${whatPhysician}.name.prefix.required`, false),
        inputProps: {
          maxLength: get(validators, `studyOrder.${whatPhysician}.name.prefix.max`, 44),
        },
        disabled: useListOfPhysicians,
      },
      {
        name: `${whatPhysician}_name_suffix`,
        label: t(`suffix`),
        grid: useListOfPhysicians ? { xs: 12, sm: 6, lg: 1 } : { xs: 12 },
        required: get(validators, `studyOrder.${whatPhysician}.name.suffix.required`, false),
        inputProps: {
          maxLength: get(validators, `studyOrder.${whatPhysician}.name.suffix.max`, 44),
        },
        disabled: useListOfPhysicians,
      },
    ];
  };

  const fields = {
    edit: isCreating
      ? null
      : [
          [
            {
              type: 'select',
              name: 'state',
              label: t('state'),
              items: [
                { id: 'NEW', label: 'NEW' },
                { id: 'SCHEDULED', label: 'SCHEDULED' },
                { id: 'ARRIVED', label: 'ARRIVED' },
                { id: 'STARTED', label: 'STARTED' },
                { id: 'COMPLETED', label: 'COMPLETED' },
                { id: 'DISCONTINUED', label: 'DISCONTINUED' },
              ],
              grid: { xs: 12, md: 4, lg: 4 },
              required: true,
            },
          ],
        ],
    patient: [
      [
        {
          name: 'patient.name.lastName',
          label: t('lastName'),
          required: get(validators, 'patient.name.lastName.required', false),
          inputProps: { maxLength: get(validators, 'patient.name.lastName.max', 44) },
        },
        {
          name: 'patient.name.firstName',
          label: t('firstName'),
          required: get(validators, 'patient.name.firstName.required', false),
          inputProps: { maxLength: get(validators, 'patient.name.firstName.max', 44) },
        },
        {
          name: 'patient.name.middleName',
          label: t('middleName'),
          required: get(validators, 'patient.name.middle.required', false),
          inputProps: { maxLength: get(validators, 'patient.name.middleName.max', 44) },
        },
        {
          name: 'patient.name.prefix',
          label: t('prefix'),
          required: get(validators, 'patient.name.prefix.required', false),
          inputProps: { maxLength: get(validators, 'patient.name.prefix.max', 44) },
        },
        {
          name: 'patient.name.suffix',
          label: t('suffix'),
          required: get(validators, 'patient.name.suffix.required', false),
          inputProps: { maxLength: get(validators, 'patient.name.suffix.max', 44) },
        },
      ],
      [
        {
          name: 'patient.identificationNumber',
          label: t('identificationNumber'),
          required: get(validators, 'patient.identification.required', false),
          inputProps: { maxLength: get(validators, 'patient.identification.max', 64) },
        },

        {
          type: 'datePicker',
          name: 'patient.dateBirth2',
          label: t('dateBirth'),
          required: get(validators, 'patient.dateBirth.required', true),
        },
        {
          type: 'select',
          name: 'patient.sex',
          label: t('sex'),
          items: [
            { id: 'F', label: t('female') },
            { id: 'M', label: t('male') },
            { id: 'O', label: t('other') },
          ],
          required: get(validators, 'patient.sex.required', false),
          inputProps: { maxLength: get(validators, 'patient.sex.max', 1) },
        },

        {
          type: 'select2',
          name: 'healthInsuranceCompany2',
          label: t('healthInsuranceCompany'),
          options: get(dictionarySelect, 'healthInsuranceSelect'),
          grid: { xs: 12, md: 4, lg: 4 },
          required: get(validators, 'healthInsuranceCompany.required', false),
        },
      ],
    ],
    order: compact([
      [
        {
          type: 'datePicker',
          name: 'originalDateRequested',
          label: t('originalDateRequested'),
          required: get(validators, 'studyOrder.studyOrderDate.required', false),
        },
        {
          type: 'timePicker',
          name: 'originalTimeRequested',
          label: t('originalTimeRequested'),
          placeholder: 'hh:mm',
          required: get(validators, 'studyOrder.studyOrderTime.required', false),
          inputProps: { maxLength: 5 },
        },
        {
          name: 'diagnosisBasic',
          label: t('diagnosisBasic'),
          required: get(validators, 'studyOrder.diagnosisBasic.required', false),
          inputProps: { maxLength: get(validators, 'studyOrder.diagnosisBasic.max', 5) },
        },
        {
          name: 'diagnosisOther',
          label: t('diagnosisOther'),
          required: get(validators, 'studyOrder.diagnosisOther.required', false),
          inputProps: { maxLength: get(validators, 'studyOrder.diagnosisOther.max', 64) },
        },
        {
          type: 'select2',
          name: 'expertness2',
          label: t('expertness'),
          options: get(dictionarySelect, 'expertnessSelect'),
          grid: { xs: 12, md: 4, lg: 4 },
          required: get(validators, 'studyOrder.expertness.required', false),
        },
        {
          name: 'icp',
          label: t('icp'),
          required: get(validators, 'icp.required', false),
          inputProps: { maxLength: get(validators, 'icp.max', 8) },
        },
        {
          type: 'number',
          name: 'patientDispositions.size',
          label: t('sizeLabel'),
          required: get(validators, 'patientDispositions.size.required', false),
          inputProps: {
            maxLength: get(validators, 'patientDispositions.size.max', 16),
            step: 'any',
            autoComplete: 'off',
            placeholder: t('sizePlaceHolder'),
          },
          tooltip: t('sizeTooltip'),
        },
        {
          type: 'number',
          name: 'patientDispositions.weight',
          label: t('weightLabel'),
          required: get(validators, 'patientDispositions.weight.required', false),
          inputProps: {
            maxLength: get(validators, 'patientDispositions.weight.max', 16),
            step: 'any',
            autoComplete: 'off',
            placeholder: t('weightPlaceHolder'),
          },
          tooltip: t('weightTooltip'),
        },
      ],
      seeRequestingPhysician ? compact(setPhysician('requestingPhysician', validators)) : null,
      seeReferringPhysician ? compact(setPhysician('referringPhysician', validators)) : null,
      [
        {
          type: 'select',
          name: 'priority.id',
          label: t('priority'),
          items: get(dictionaryLists, 'priority', []),
          required: get(validators, 'studyOrder.urgency.required', false),
        },
        {
          type: 'select2',
          name: 'requestedProcedure2',
          label: t('requestedProcedure'),
          options: get(dictionarySelect, 'requestedProcedureSelect'),
          grid: { xs: 12, md: 4, lg: 4 },
          required: get(validators, 'studyOrder.requestedProcedure.required', false),
        },
        {
          type: 'select',
          name: 'modality.id',
          label: t('modality'),
          items: get(dictionaryLists, 'modalities', []),
          required: get(validators, 'studyOrder.modality.required', false),
        },
        {
          type: 'select',
          name: 'station.id',
          label: t('station'),
          items: get(dictionaryLists, 'stations', []),
          required: get(validators, 'studyOrder.station.required', false),
        },
        {
          type: 'select',
          name: 'unit.id',
          label: t('unit'),
          items: get(dictionaryLists, 'units', []),
          required: get(validators, 'studyOrder.unit.required', false),
        },
      ],
    ]),
  };

  return { OrderFormSchema, fields };
};

export default useValidationSchema;

import React, { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Collapse, Grid, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import useValidationSchema from './_formComment';
import { createComment, editComment } from './_api';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import { Box } from '@mui/system';

const StudyDetailCommentForm: React.FC<any> = ({
  setDialogState,
  commentItem,
  getComments,
  study,
  initialComment,
}) => {
  console.log('initialComment:', initialComment);
  const { t } = useTranslation('StudyDetail');
  const { toggleLoader } = useAppGlobals();
  const [formError, setFormError] = useState<any>(null);

  const isCreating = useMemo(() => {
    return !get(commentItem, 'id');
  }, [commentItem]);

  const { CommentFormSchema } = useValidationSchema(isCreating);
  const methods = useForm<any>({
    resolver: yupResolver(CommentFormSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createComment : editComment;
    await fn(values).then(
      async () => {
        await getComments();
        // const study2 = await getStudy(get(study, 'archive.id'), get(study, 'studyInstanceUid'));
        // if (!isEmpty(study2)) {
        //   setStudy(study2);
        // }
        setDialogState(false);
      },
      () => setFormError(t('errorSaving')),
    );
    toggleLoader(false);
  });

  const getEntities = async () => {
    console.log('getEntities - isCreating:', isCreating);
    toggleLoader();
    try {
      const defaultValues = isCreating
        ? {
            productId: parseInt(get(study, 'archive.id'), 10),
            studyUid: get(study, 'studyInstanceUid'),
            ...(initialComment !== null
              ? {
                  comment: { text: initialComment },
                }
              : { comment: { text: '' } }),
          }
        : {
            id: commentItem.id,
            text: commentItem.text,
          };
      reset({ ...defaultValues });
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {commentItem || isCreating ? (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            {isCreating ? (
              <>
                <input {...register('productId')} type="hidden" />
                <input {...register('studyUid')} type="hidden" />
                <input {...register('comment.text')} type="hidden" />
              </>
            ) : (
              <>
                <input {...register('id')} type="hidden" />
              </>
            )}
            <Grid container={true} spacing={0}>
              <Grid item={true} xs={12}>
                <FormInput
                  name={isCreating ? 'comment.text' : 'text'}
                  label={t('text')}
                  required={true}
                  multiline={true}
                  rows={16}
                />
              </Grid>
              <Grid item={true} xs={12} md={12} lg={12} xl={12}>
                <Collapse in={errors ? true : false} timeout="auto">
                  {errors && (
                    <Typography align="center" color="error">
                      {formError}
                    </Typography>
                  )}
                </Collapse>
              </Grid>
              <Grid item={true} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    displayPrint: 'none',
                  }}
                >
                  <Button
                    onClick={() => setDialogState(false)}
                    variant="contained"
                    size="large"
                    sx={{ mr: 1 }}
                  >
                    {t('close')}
                  </Button>
                  <Button variant="contained" color="primary" type="submit" size="large">
                    <SaveIcon sx={{ mr: 1 }} />
                    {t('confirm')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      ) : (
        <Papeer>
          <Typography>{t('commentNotFound')}</Typography>
        </Papeer>
      )}
    </>
  );
};

export default StudyDetailCommentForm;

import * as yup from 'yup';

import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = () => {
  const { required } = useValidators();

  const FilterDialogFormSchema = yup.object().shape({
    name: required(yup.string()),
  });

  return { FilterDialogFormSchema };
};

export default useValidationSchema;

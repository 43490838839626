import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { ITransmissionDetail, IField } from './_types';
import { Papeer } from 'components/Papeer/Papeer';
import { bytesToSize } from 'utils/import';

export const TransmissionDetail: React.FC<ITransmissionDetail> = ({ detailRow }) => {
  const { t } = useTranslation('Transmissions');

  const [fields, setFields] = useState<IField[]>([]);

  useEffect(() => {
    const fields = [
      {
        name: 'transferStart',
        label: t('transferStart'),
        value: `${get(detailRow, 'transferStart', '') ? get(detailRow, 'transferStart') : ''}`,
      },
      {
        name: 'transferEnd',
        label: t('transferEnd'),
        value: `${get(detailRow, 'transferEnd') ? get(detailRow, 'transferEnd') : ''}`,
      },
      {
        name: 'institutionFrom',
        label: t('institutionFrom'),
        value: get(detailRow, 'institutionFrom') ? get(detailRow, 'institutionFrom') : '',
      },
      {
        name: 'institutionTo',
        label: t('institutionTo'),
        value: get(detailRow, 'institutionTo') ? get(detailRow, 'institutionTo') : '',
      },
      {
        name: 'cityFrom',
        label: t('cityFrom'),
        value: get(detailRow, 'cityFrom') ? get(detailRow, 'cityFrom') : '',
      },
      {
        name: 'cityTo',
        label: t('cityTo'),
        value: get(detailRow, 'cityTo') ? get(detailRow, 'cityTo') : '',
      },
      {
        name: 'patientName',
        label: t('patientName'),
        value: get(detailRow, 'patientName')
          ? (get(detailRow, 'patientName') || '').replace('^', ' ')
          : '',
      },
      {
        name: 'patientIdent',
        label: t('patientIdent'),
        value: get(detailRow, 'patientIdent') ? get(detailRow, 'patientIdent') : '',
      },
      {
        name: 'prepareObjectSize',
        label: t('objectSize'),
        value: bytesToSize(get(detailRow, 'objectSize') ? get(detailRow, 'objectSize') : 0),
      },
      {
        name: 'objectCount',
        label: t('objectCount'),
        value: get(detailRow, 'objectCount') ? get(detailRow, 'objectCount') : '',
      },
      {
        name: 'objectIdent',
        label: t('objectIdent'),
        value: get(detailRow, 'objectIdent') ? get(detailRow, 'objectIdent') : '',
      },
    ];
    setFields(fields);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailRow]);

  return (
    <Papeer>
      <Box>
        <Grid container={true} spacing={2}>
          {fields.map((field) => (
            <Grid item={true} xs={12} md={6} lg={3} {...field.grid} key={field.name}>
              <Box component="label" sx={{ fontWeight: 'bold' }}>
                {field.label}
              </Box>
              <Box>{field.value}</Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Papeer>
  );
};

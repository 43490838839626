import { useState } from 'react';
import { AloadNotifications } from '../_actions';
import { NOTIFICATIONS_STATE_NEW } from 'constants/constants';
import { useDispatch } from 'react-redux';
import { INotification } from '../_types';

export const useNotificationsState = () => {
  const dispatch = useDispatch();

  const [notificationToShow, setNotificationToShow] = useState<INotification>();
  const [hiddenNotifications, setHiddenNotifications] = useState<string[]>([]);

  const setNotification = (notifications: INotification) => {
    setNotificationToShow(notifications);
  };

  const setHiddenNotification = (id: string) => {
    if (!hiddenNotifications.includes(id)) {
      setHiddenNotifications((prevState) => [...prevState, id]);
    }
  };

  const loadNotifications = async (stateValue: string) => {
    dispatch(AloadNotifications(stateValue) as any);
  };

  const loadNotificationsFromApi = async () => {
    await loadNotifications(NOTIFICATIONS_STATE_NEW);
  };

  return {
    notificationToShow,
    hiddenNotifications,
    setNotification,
    setHiddenNotification,
    loadNotificationsFromApi,
  };
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { getAll, setActiveExchangeNetwork } from './_apiExchangeNetworks';
import ExchangeNetworkForm from './ExchangeNetworkForm';
import Header from 'components/Header/Header';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'exchangeNetworksMUI';

const ExchangeNetworks: React.FC = () => {
  const { t } = useTranslation('ExchangeNetworks');
  useWithTitle();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();
  const { toggleLoader } = useAppGlobals();

  const [dialogState, setDialogState] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [rows, setRows] = useState<any[]>([]);

  const loadEntity = async () => {
    toggleLoader();
    try {
      setId(0);
      const response = await getAll();
      if (response) {
        setRows(response);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const onChangeActive = async (code: string, active: boolean) => {
    toggleLoader();
    const changed = await setActiveExchangeNetwork(code, active);
    if (changed) {
      loadEntity();
    }
    toggleLoader(false);
  };

  const confirmActionChangeActive = () => {
    onChangeActive(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  const toggleDialog = (state: boolean = false) => {
    setDialogState(state);
  };

  const onEntityEdit = async (id: number) => {
    setId(id);
    toggleDialog(id > 0 ? true : false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pomid = id ? id : dialogState ? 'create' : null;
  const renderedDialog = (
    <OverflowedDialog
      open={dialogState}
      onClose={() => {
        toggleDialog(false);
        setId(0);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <ExchangeNetworkForm toggleDialog={toggleDialog} id={pomid} loadEntity={loadEntity} />
    </OverflowedDialog>
  );

  const clearAction = () => dispatch(storeConfirmationDataToStore(null));

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 180,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.active}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeState',
                            id: get(row, 'id'),
                            state,
                            confirmAction: 'confirmActionChangeActive',
                          }),
                        );
                      }}
                    />
                  }
                  label={t(row.active ? 'Grid:active' : 'Grid:inactive') as string}
                />
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onEntityEdit(get(row, 'id'))}
              />
            </>
          );
        },
      },
      { field: 'name', headerName: t('name') },
      { field: 'description', headerName: t('description') },
      { field: 'ip', headerName: t('ip') },
      { field: 'port', headerName: t('port') },
      { field: 'urlForUpdate', headerName: t('urlForUpdate') },
      {
        field: 'color',
        headerName: t('color'),
        sortable: false,
        filterable: false,
        renderCell: ({ row }: GridRenderCellParams) => {
          const color = row.color;
          return color ? (
            <Tooltip id="tooltip-left" title={color} placement="right">
              <Box sx={{ backgroundColor: color, width: 20, height: 20 }} />
            </Tooltip>
          ) : null;
        },
      },
      {
        field: 'priority',
        headerName: t('priority'),
        valueGetter: (value: any, row: any) => get(row, 'priority', ''),
        renderCell: ({ row }: GridRenderCellParams) => {
          const priority = row.priority;
          return priority === 1
            ? t('Studies:HIGH')
            : priority === 2
            ? t('Studies:MEDIUM')
            : t('Studies:LOW');
        },
      },
    ]),
  );

  return (
    <>
      <Header title="" />
      <MuiGrid
        gridKey={muiGridKey}
        rows={rows}
        columns={columns}
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />
      {renderedDialog}
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmActionChangeActive}
        />
      )}
    </>
  );
};

export default ExchangeNetworks;

import { useTranslation } from 'react-i18next';
import {
  Help,
  Edit,
  List,
  Https,
  Assignment,
  AssignmentInd,
  Folder,
  Search,
  Settings,
  ImportExport,
  LibraryBooks,
  DesktopWindows,
  Info,
  History,
  LocalHospital,
  BookmarkBorder,
  RestoreFromTrash,
  Toll,
} from '@mui/icons-material/';
import { sortBy } from 'lodash';
import { useUser } from './useUser';
import { useAppInfo } from './useAppInfo';
import { IMenuItem } from 'components/Menu/_types';

export const useMenuItems = () => {
  const { t } = useTranslation('Menu');
  const { hasRole } = useUser();
  const { isImportUploading, isImportMdexUploading, isCstoreUploading, isCktch } = useAppInfo();

  const menuItems: IMenuItem[] = [
    {
      label: t('menuStudies'),
      link: 'studies',
      icon: <AssignmentInd />,
      show:
        hasRole('ROLE_VIEW_STUDY') ||
        hasRole('ROLE_SEND_DR_SEJF') ||
        hasRole('ROLE_SEND_EPACS') ||
        hasRole('ROLE_SEND_REDIMED') ||
        hasRole('ROLE_REORDER') ||
        hasRole('ROLE_SPLIT') ||
        hasRole('ROLE_UPDATE_STUDY') ||
        hasRole('ROLE_UPDATE_PATIENT') ||
        hasRole('ROLE_COPY') ||
        hasRole('ROLE_MOVE') ||
        hasRole('ROLE_ARCHIVATION'),
    },
    {
      label: t('exportsList'),
      link: 'exportsList',
      icon: <ImportExport />,
      show:
        hasRole('ROLE_EXPORT_CD') ||
        hasRole('ROLE_EXPORT_DICOM') ||
        hasRole('ROLE_EXPORT_DVD') ||
        hasRole('ROLE_EXPORT_PICTURE') ||
        hasRole('ROLE_EXPORT_VIDEO'),
    },
    {
      label: t('cstore'),
      link: 'cstore',
      icon: <ImportExport />,
      show: hasRole('ROLE_CSTORE_TO_ARCHIVE') || hasRole('ROLE_CSTORE_TO_EXCHANGE_NETWORK'),
    },
    {
      label: t('importMenu'),
      link: 'import',
      icon: <ImportExport />,
      uploadingState: isImportUploading,
      show: hasRole('ROLE_IMPORT_DICOM') || hasRole('ROLE_IMPORT_NONDICOM'),
      submenu: [
        { label: t('dicom'), link: 'dicom', show: hasRole('ROLE_IMPORT_DICOM') },
        {
          label: t('nondicom'),
          link: 'nondicom',
          show: hasRole('ROLE_IMPORT_NONDICOM'),
        },
      ],
    },
    {
      label: t('mdex'),
      link: 'mdex',
      icon: <Toll />,
      uploadingState: isImportMdexUploading,
      show:
        hasRole('ROLE_MDEX_VIEW_ADDRESS_BOOK') ||
        hasRole('ROLE_MDEX_VIEW_INCOMING_DATA') ||
        hasRole('ROLE_MDEX_VIEW_OUTGOING_DATA') ||
        hasRole('ROLE_MDEX_SEND_NON_DICOM') ||
        hasRole('ROLE_MDEX_SEND_DICOM'),
      submenu: [
        {
          label: t('sendData'),
          link: 'sendData',
          show: hasRole('ROLE_MDEX_SEND_NON_DICOM') || hasRole('ROLE_MDEX_SEND_DICOM'),
        },
        {
          label: t('transmissions'),
          link: 'transmissions',
          show: hasRole('ROLE_MDEX_VIEW_INCOMING_DATA') || hasRole('ROLE_MDEX_VIEW_OUTGOING_DATA'),
        },
        {
          label: t('addressBook'),
          link: 'addressBook',
          show: hasRole('ROLE_MDEX_VIEW_ADDRESS_BOOK'),
        },
        /* { label: t('exportsList'), link: 'exportsList', show: true }, */
      ],
    },
    {
      label: t('shreddings.shreddings'),
      link: 'shreddings',
      icon: <RestoreFromTrash />,
      show: hasRole('ROLE_MANAGE_SHREDDING'),
      submenu: [
        {
          label: t('shreddings.proposals'),
          link: 'proposals',
          show: hasRole('ROLE_MANAGE_SHREDDING'),
        },
        {
          label: t('shreddings.histories'),
          link: 'histories',
          show: hasRole('ROLE_MANAGE_SHREDDING'),
        },
      ],
    },
    {
      label: t('signature'),
      link: 'signature',
      icon: <AssignmentInd />,
      show: hasRole('ROLE_LIST_UNSIGNED_STUDIES'),
      submenu: [{ label: t('unsignedStudies'), link: 'unsignedStudies', show: true }],
    },
    {
      label: t('request'),
      link: 'requests',
      icon: <LibraryBooks />,
      show:
        hasRole('ROLE_REQUEST_COPY') ||
        hasRole('ROLE_REQUEST_MOVE') ||
        hasRole('ROLE_REQUEST_MOVE_TO_FOLDER') ||
        hasRole('ROLE_REQUEST_MOVE_TO_TRASH') ||
        hasRole('ROLE_REQUEST_SPLIT') ||
        hasRole('ROLE_REQUEST_UPDATE_STUDY') ||
        hasRole('ROLE_REQUEST_UPDATE_PATIENT') ||
        hasRole('ROLE_REQUEST_SEND_DR_SEJF') ||
        hasRole('ROLE_REQUEST_SEND_EPACS') ||
        hasRole('ROLE_REQUEST_SEND_REDIMED') ||
        hasRole('ROLE_REQUEST_REORDER'),
    },
    {
      label: t('orders'),
      link: 'orders',
      redirectOnClick: true,
      icon: <Assignment />,
      show: hasRole('ROLE_MWL_VIEW'),
      submenu: [],
    },
    {
      label: t('tests'),
      link: 'tests',
      icon: <List />,
      show:
        hasRole('ROLE_MAMO') ||
        hasRole('ROLE_MANAGE_TEST') ||
        hasRole('ROLE_MAMO_RESULTS') ||
        hasRole('ROLE_VIEW_MAMO_STATISTICS'),
      submenu: [
        {
          label: t('manageTests'),
          link: 'manageTests',
          show: hasRole('ROLE_MANAGE_TEST'),
        },
        { label: t('startTest'), link: 'startTest', show: hasRole('ROLE_MAMO') },
        {
          label: t('testResults'),
          link: 'results',
          show: hasRole('ROLE_VIEW_MAMO_STATISTICS'),
        },
        {
          label: t('globalStats'),
          link: 'globalStats',
          show: hasRole('ROLE_VIEW_MAMO_STATISTICS'),
        },
      ],
    },
    isCktch
      ? {
          label: t('clinicalPortal'),
          link: 'clinicPortal',
          icon: <LocalHospital />,
          show:
            hasRole('ROLE_CAN_USE_CLINICAL_PORTAL') ||
            hasRole('ROLE_CAN_SEARCH_BY_TAGS_IN_IP') ||
            hasRole('ROLE_CAN_USAGE_NCP_INTERFACE'),
          submenu: [
            {
              label: t('byPatient'),
              link: 'byPatient',
              show: hasRole('ROLE_CAN_USE_CLINICAL_PORTAL'),
            },
            {
              label: t('byTags'),
              link: 'byTags',
              show: hasRole('ROLE_CAN_SEARCH_BY_TAGS_IN_IP'),
            },
            {
              label: t('byNCPeH'),
              link: 'byNCPeH',
              show: hasRole('ROLE_CAN_USAGE_NCP_INTERFACE'),
            },
          ],
        }
      : {
          label: t('clinicalPortal'),
          link: 'clinicPortal',
          icon: <LocalHospital />,
          show: hasRole('ROLE_CAN_USE_CLINICAL_PORTAL') || hasRole('ROLE_CAN_USAGE_NCP_INTERFACE'),
          submenu: [
            {
              label: t('byPatient'),
              link: 'byPatient',
              show: hasRole('ROLE_CAN_USE_CLINICAL_PORTAL'),
            },
            {
              label: t('byNCPeH'),
              link: 'byNCPeH',
              show: hasRole('ROLE_CAN_USAGE_NCP_INTERFACE'),
            },
          ],
        },
    {
      label: t('folders'),
      link: 'folders',
      redirectOnClick: true,
      icon: <Folder />,
      show: hasRole('ROLE_CAN_OPERATE_WITH_FOLDERS'),
      submenu: [],
    },
    {
      label: t('filters'),
      link: 'filters',
      redirectOnClick: true,
      icon: <Search />,
      show:
        hasRole('ROLE_CREATE_FILTER') ||
        hasRole('ROLE_EDIT_FILTER') ||
        hasRole('ROLE_DELETE_FILTER'),
      submenu: [],
    },
    {
      label: t('queueRequests'),
      link: 'queueRequests',
      redirectOnClick: true,
      icon: <History />,
      show: hasRole('ROLE_SHOW_REALISATOR_QUEUE'),
      submenu: [],
    },
    /* {
    label: t('share'),
    link: 'sharing',
    icon: <CloudUpload />,
    show: false,
    submenu: [
      { label: t('share'), link: 'share', show: true },
      { label: t('ask'), link: 'ask', show: true },
      { label: t('delivered'), link: 'delivered', show: true },
      { label: t('sent'), link: 'sent', show: true },
    ],
  }, */
    {
      label: t('tests'),
      link: 'tests',
      icon: <Edit />,
      show: false,
      submenu: [
        { label: t('startTest'), link: 'start', show: true },
        { label: t('results'), link: 'results', show: true },
        { label: t('manageTest'), link: 'manage', show: true },
        { label: t('stats'), link: 'stats', show: true },
      ],
    },
    {
      label: t('trustworthyArchive'),
      link: 'trustworthy',
      icon: <Https />,
      show: false,
      submenu: [{ label: t('archivedDocuments'), link: 'archived-documents', show: true }],
    },
    {
      label: t('monitoring'),
      link: 'monitoring',
      icon: <DesktopWindows />,
      show: false,
      submenu: [
        { label: t('nasEnterprise'), link: 'nas-enterprise', show: true },
        { label: t('patientManagement'), link: 'patient-management', show: true },
      ],
    },
    {
      label: t('nas-enterprise'),
      link: 'nas-enterprise',
      icon: <List />,
      show: hasRole('ROLE_CEPH_MONITORING'),
      submenu: [
        {
          label: t('dashboards'),
          link: 'dashboards',
          show: true,
        },
        {
          label: t('osd-daemons'),
          link: 'osd-daemons',
          show: true,
        },
      ],
    },
    {
      label: t('reports'),
      link: 'reports',
      icon: <List />,
      show:
        hasRole('ROLE_VIEW_STATISTICS') ||
        hasRole('ROLE_VIEW_AUDIT_LOGS') ||
        hasRole('ROLE_FINDING_STATISTICS'),
      submenu: [
        {
          label: t('statistics'),
          link: 'statistics',
          show: hasRole('ROLE_VIEW_STATISTICS'),
        },
        {
          label: t('studiesByStudyTagsStatistics'),
          link: 'studiesByStudyTagsStatistics',
          show: hasRole('ROLE_VIEW_STATISTICS'),
        },
        // { label: t('daily'), link: 'daily', show: true },
        {
          label: t('auditLogs'),
          link: 'auditLogs',
          show: hasRole('ROLE_VIEW_AUDIT_LOGS'),
        },
        {
          label: t('findingReports'),
          link: 'findingReports',
          show: hasRole('ROLE_FINDING_STATISTICS'),
        },
      ],
    },
    {
      label: t('tags'),
      link: 'tags',
      icon: <BookmarkBorder />,
      show:
        hasRole('ROLE_SCIENTIFIC_ARCHIVE') ||
        hasRole('ROLE_CAN_SEARCH_BY_TAGS_IN_IP') ||
        hasRole('ROLE_TAGS'),
      submenu: [
        {
          label: t('scientificArchive'),
          link: 'archive',
          show: hasRole('ROLE_SCIENTIFIC_ARCHIVE'),
        },
        {
          label: t('documentsByTags'),
          link: 'documentsByTags',
          show: hasRole('ROLE_CAN_SEARCH_BY_TAGS_IN_IP') && !isCktch,
        },
        {
          label: t('administrationTags'),
          link: 'registerGroups',
          show: hasRole('ROLE_TAGS'),
        },
      ],
    },
    {
      label: t('administration'),
      link: 'administration',
      icon: <Settings />,
      uploadingState: isCstoreUploading,
      show:
        hasRole('ROLE_FACILITY') ||
        hasRole('ROLE_EXCHANGE_NETWORK') ||
        hasRole('ROLE_PRODUCT') ||
        hasRole('ROLE_MODALITY') ||
        hasRole('ROLE_USER') ||
        hasRole('ROLE_WORKPLACE') ||
        hasRole('ROLE_NOTIFICATION_ADMIN') ||
        hasRole('ROLE_NOTIFICATION_USER') ||
        hasRole('ROLE_ANNOUNCEMENT') ||
        hasRole('ROLE_BUTTONS') ||
        hasRole('ROLE_UNIT') ||
        hasRole('ROLE_STATION') ||
        hasRole('ROLE_DICTIONARY') ||
        hasRole('ROLE_DECUBITS_MANAGE') ||
        hasRole('ROLE_CAN_MANAGE_CLOUD_PACS_RECIPIENTS') ||
        hasRole('ROLE_LANGUAGE') ||
        hasRole('ROLE_CAN_ADMINISTRATE_FOLDERS') ||
        hasRole('ROLE_TOOLS_RSA') ||
        hasRole('ROLE_ADMINISTRATE_MODALITIES_IN_MARIE') ||
        hasRole('ROLE_ADMINISTRATE_MODALITIES_IN_DPGW') ||
        hasRole('ROLE_CAN_ADMINISTRATE_EXTERNAL_DICTIONARY') ||
        hasRole('ROLE_CAN_ADMINISTRATE_CLINICAL_PORTAL') ||
        hasRole('ROLE_PRIORITY_AND_OPERATION'),
      submenu: sortBy(
        [
          {
            label: t('settings'),
            link: 'settings',
            show:
              hasRole('ROLE_PRODUCT') ||
              hasRole('ROLE_USER') ||
              hasRole('ROLE_WORKPLACE') ||
              hasRole('ROLE_ADMINISTRATE_MODALITIES_IN_MARIE') ||
              hasRole('ROLE_ADMINISTRATE_MODALITIES_IN_DPGW'),
          },
          {
            label: t('facilitiesAndExchangeNetworks'),
            link: 'facilitiesAndExchangeNetworks',
            show: hasRole('ROLE_FACILITY') || hasRole('ROLE_EXCHANGE_NETWORK'),
          },
          { label: t('partnership'), link: 'partnership', show: false },
          { label: t('loggedActivities'), link: 'logged-activities', show: false },
          { label: t('logs'), link: 'logs', show: false },
          {
            label: t('announcementsAndButtons'),
            link: 'announcementsAndButtons',
            show: hasRole('ROLE_ANNOUNCEMENT') || hasRole('ROLE_BUTTONS'),
          },
          {
            label: t('folders'),
            link: 'folders',
            show: hasRole('ROLE_CAN_ADMINISTRATE_FOLDERS'),
          },
          {
            label: t('tools'),
            link: 'tools',
            show: hasRole('ROLE_TOOLS_RSA'),
          },
          {
            label: t('clinicalPortal'),
            link: 'clinicalPortal',
            show: hasRole('ROLE_CAN_ADMINISTRATE_CLINICAL_PORTAL'),
          },
          {
            label: t('generalSettings'),
            link: 'generalSettings',
            show:
              hasRole('ROLE_DICTIONARY') ||
              hasRole('ROLE_LANGUAGE') ||
              hasRole('ROLE_PRIORITY_AND_OPERATION') ||
              hasRole('ROLE_NOTIFICATION_ADMIN') ||
              hasRole('ROLE_NOTIFICATION_USER'),
          },
          {
            label: t('modalitiesUnitsStations'),
            link: 'modalitiesUnitsStations',
            show: hasRole('ROLE_MODALITY') || hasRole('ROLE_UNIT') || hasRole('ROLE_STATION'),
          },
          {
            label: t('frontendSettings'),
            link: 'frontendSettings',
            show: hasRole('ROLE_FE_SETTINGS_UPDATE'),
          },
        ],
        ['label'],
      ),
    },
    {
      label: t('help'),
      link: 'help',
      icon: <Help />,
      show: false,
      submenu: [
        { label: t('online'), link: 'online', show: true },
        { label: t('offline'), link: 'offline', show: true },
        { label: t('local'), link: 'local', show: true },
        { label: t('about'), link: 'about', show: true },
      ],
    },
    {
      label: t('help'),
      link: 'help',
      url: `${window.location.protocol}//${window.location.host}/help`,
      icon: <Help />,
      show: true,
      submenu: [],
    },
    {
      label: t('about'),
      link: 'about',
      redirectOnClick: true,
      icon: <Info />,
      show: true,
      submenu: [],
    },
  ];

  return {
    menuItems,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Tab, Tabs } from '@mui/material';

import { IStudyDetailTabs } from './_types';
import { useUser } from 'utils/hooks/useUser';
import { stateIsSame } from 'utils/componentOptimizatons';
import { useLanguage } from 'utils/hooks/useLanguage';
import OrderWithCommentIcon from '@mui/icons-material/Error';
import {
  TAB_COMMENTS,
  TAB_DOSES,
  TAB_FOLDERS,
  TAB_OPERATIONS,
  TAB_ORDER,
  TAB_REPORT,
  TAB_SERIES,
  TAB_TAGS,
} from 'constants/constants';
import { StudyDetailTabContent } from './StudyDetailTabContent';
import { Box } from '@mui/system';
import { filter, get, isEmpty, sortBy } from 'lodash';

const PlainStudyDetailTabs: React.FC<IStudyDetailTabs> = ({
  study,
  canViewCommentaryMWL,
  handleLifeCycle,
  studyActions,
  activeTab,
  setActiveTab,
  accessionNumber,
  patientId,
  hashIID,
  order2,
  setOrder2,
  allStudyDetailBookmarks,
  seriesWithPreviewUrl,
}) => {
  const { hasRole } = useUser();
  const { t } = useTranslation('StudyDetail');
  const { currentLocale } = useLanguage();

  let tabs = [];
  let firstTab = '';
  if (!isEmpty(allStudyDetailBookmarks) && currentLocale) {
    tabs = filter(
      sortBy(
        allStudyDetailBookmarks.map((tab: any) => {
          const languageJsonText = JSON.parse(tab.name);
          const localeLabel = get(languageJsonText, currentLocale, '');
          return {
            order: tab.order,
            ...(tab.type === 'comments'
              ? {
                  value: TAB_COMMENTS,
                  label: localeLabel || t('comments'),
                  show: hasRole('ROLE_CAN_VIEW_COMMENT_BOOKMARK'),
                }
              : tab.type === 'tags'
              ? {
                  value: TAB_TAGS,
                  label: localeLabel || t('tags'),
                  show: hasRole('ROLE_SCIENTIFIC_ARCHIVE'),
                }
              : tab.type === 'folders'
              ? {
                  value: TAB_FOLDERS,
                  label: localeLabel || t('folders'),
                  show: hasRole('ROLE_CAN_OPERATE_WITH_FOLDERS'),
                }
              : tab.type === 'bookmark_order'
              ? {
                  value: TAB_ORDER,
                  label: localeLabel || t('order'),
                  show: hasRole('ROLE_MWL_IN_DETAIL'),
                }
              : tab.type === 'finding'
              ? {
                  value: TAB_REPORT,
                  label: localeLabel || t('report'),
                  show: hasRole('ROLE_VIEW_REPORT_IN_STUDY_DETAIL'),
                }
              : tab.type === 'doses'
              ? {
                  value: TAB_DOSES,
                  label: localeLabel || t('doses'),
                  show: hasRole('ROLE_DOSE_MONITORING'),
                }
              : tab.type === 'operations'
              ? {
                  value: TAB_OPERATIONS,
                  label: localeLabel || t('operations'),
                  show: hasRole('ROLE_VIEW_OPERATION'),
                }
              : { value: TAB_SERIES, label: localeLabel || t('series.title') }),
          };
        }),
        ['order'],
      ),
      (tab) => tab.show !== false,
    );
    firstTab = isEmpty(tabs) ? '' : tabs[0].value;
  }

  return (
    <Box
      sx={{ background: (theme) => theme.palette.background.paper }}
      data-tour="study-detail-tabs"
    >
      <AppBar position="static" color="default" sx={{ mt: 2 }}>
        <Tabs
          value={activeTab || firstTab}
          onChange={(_event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabs.map((tab) =>
            tab.value === TAB_ORDER && canViewCommentaryMWL ? (
              <Tab
                key={tab.value}
                value={tab.value}
                label={
                  <div>
                    <OrderWithCommentIcon style={{ verticalAlign: 'middle' }} /> {tab.label}
                  </div>
                }
              />
            ) : (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ),
          )}
        </Tabs>
      </AppBar>
      <StudyDetailTabContent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        firstTab={firstTab}
        study={study}
        handleLifeCycle={handleLifeCycle}
        studyActions={studyActions}
        accessionNumber={accessionNumber}
        patientId={patientId}
        hashIID={hashIID}
        order2={order2}
        setOrder2={setOrder2}
        seriesWithPreviewUrl={seriesWithPreviewUrl}
      />
    </Box>
  );
};

export const StudyDetailTabs = React.memo(PlainStudyDetailTabs, stateIsSame);

import React from 'react';
import { isFunction, isString } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';

import { IGridIconButton } from './_types';

export const GridIconButton: React.FC<IGridIconButton> = ({
  title,
  onClick,
  link,
  search,
  CustomIcon,
  disabled,
  rightMargin,
  linkState,
  cursor = 'pointer',
  togglePreviewGrid = 0,
}) => {
  const navigate = useNavigate();
  return (
    <Tooltip title={title} style={{ cursor }}>
      <IconButton
        size="small"
        onClick={() => {
          if (isFunction(onClick)) {
            onClick();
          }
          if (isString(link)) {
            navigate(link, { state: { ...linkState, search: search || '' } });
          }
        }}
        disabled={disabled}
      >
        <CustomIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

import Box from '@mui/material/Box/Box';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RecursiveTagFields } from './RecursiveTagFields';
import { useFormContext } from 'react-hook-form';
import FormInput from 'components/Form/Input/Input';
import { Button, Typography } from '@mui/material';
import { Papeer } from 'components/Papeer/Papeer';
import { getAllGroups } from 'modules/Tags/_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import CheckboxForm from 'components/Form/Checkbox/Checkbox';
import { FormType, IRegisterGroup, IRegisterItem, ITagForm } from './_types';
import { get, isArray, isString } from 'lodash';
import { sortCollectionByItemName } from 'utils/hooks/useLocaleCompare';

const mainSx = { width: { xs: '100%', md: '50%', lg: '20%' } };
const modalSx = { width: { xs: '100%', lg: '35%' } };

export const TagForm: React.FC<ITagForm> = ({
  formType,
  showCommentField = false,
  isModalBoxStyling = false,
  showOnlyActiveTags = false,
  hideSearchAndClearButton = false,
}) => {
  const { t } = useTranslation('SearchForm');
  const [showInactiveTags, setShowInactiveTags] = useState(false);
  const [activeGroups, setActiveGroups] = useState<IRegisterGroup[]>([]);
  const [inactiveGroups, setInactiveGroups] = useState<IRegisterGroup[]>([]);
  const { toggleLoader } = useAppGlobals();
  const [disableSearch, setDisableSearch] = useState<boolean>(true);

  const appliedStyle = isModalBoxStyling ? modalSx : mainSx;

  const { reset, watch } = useFormContext();

  const getSortedGroups = (groups: IRegisterGroup[]) => {
    return (isArray(groups) ? sortCollectionByItemName(groups, 'name') : []).map(
      (group: IRegisterGroup) => ({
        ...group,
        children: sortCollectionByItemName(get(group, 'children', []), 'name').map(
          (child: IRegisterGroup) => ({
            ...child,
          }),
        ),
      }),
    );
  };

  const loadGroups = async () => {
    toggleLoader();
    const groups = await getAllGroups(true);
    setActiveGroups(getSortedGroups(groups));
    toggleLoader(false);
  };

  const loadInactiveGroups = async () => {
    toggleLoader();
    const inactiveGroups = await getAllGroups(false);
    setInactiveGroups(getSortedGroups(inactiveGroups));
    toggleLoader(false);
  };

  useEffect(() => {
    loadGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allValues = watch();

  useEffect(() => {
    const registerItems: IRegisterItem[] = Object.values(allValues.registerItems || {});
    const canSearch =
      registerItems.some((item: IRegisterItem) => item !== null) ||
      (isString(allValues.comment) && allValues.comment.length > 0);
    setDisableSearch(!canSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues]);

  const handleReset = () => {
    reset();
  };

  return (
    <>
      <Papeer sx={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        {/* Comment */}
        {showCommentField && (
          <Box sx={appliedStyle} mb={3} data-tour="tagsArchiveAndIntegrationPlatform-comment">
            <FormInput
              name="comment"
              label={t('comment')}
              InputProps={{ sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
            />
          </Box>
        )}
        {/* Active Tags */}
        <Typography fontWeight="bold">{t('tagsActive')}</Typography>
        <Box data-tour="tagsArchiveAndIntegrationPlatform-activeTags">
          {activeGroups && activeGroups.length > 0 && (
            <RecursiveTagFields
              data={activeGroups}
              appliedStyle={appliedStyle}
              formType={formType}
            />
          )}
        </Box>

        {/* Inactive Tags */}
        {!showOnlyActiveTags && (
          <Box data-tour="tagsArchiveAndIntegrationPlatform-inActiveTags">
            <CheckboxForm
              name="showInactiveGroups"
              label={t('showInactiveGroups')}
              onChange={() => {
                if (!(inactiveGroups.length > 0)) {
                  loadInactiveGroups();
                }
                setShowInactiveTags(!showInactiveTags);
              }}
            />
            {showInactiveTags && (
              <>
                <Typography fontWeight="bold">{t('tagsInactive')}</Typography>
                {inactiveGroups && inactiveGroups.length > 0 && (
                  <RecursiveTagFields data={inactiveGroups} formType={formType} />
                )}
              </>
            )}
          </Box>
        )}

        {/* Buttons */}
        {formType !== FormType.AddTagForm && (
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={disableSearch}
              data-tour="tagsArchiveAndIntegrationPlatform-search"
            >
              {t('search')}
            </Button>
            <Button
              variant="contained"
              color="inherit"
              type="button"
              onClick={handleReset}
              data-tour="tagsArchiveAndIntegrationPlatform-clear"
            >
              {t('reset')}
            </Button>
          </Box>
        )}
      </Papeer>
    </>
  );
};

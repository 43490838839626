import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isUndefined } from 'lodash';
import { Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createPriority, editPriority, getPriority } from './_api';
import useValidationSchema from './_form';
import { IPriority, IPriorityForm } from './_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import FormInput from 'components/Form/Input/Input';
import { Papeer } from 'components/Papeer/Papeer';
import useAlerts from 'components/Alerts/useAlerts';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import { useAppInfo } from 'utils/hooks/useAppInfo';

const PriorityForm: React.FC<IPriorityForm> = ({ toggleDialog, keyValues, id, loadEntity }) => {
  const { t } = useTranslation('PrioritiesAndOperations');
  const { compactMode } = useAppInfo();
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [entity, setEntity] = useState<IPriority>();

  const { PriorityFormSchema } = useValidationSchema(keyValues);

  const methods = useForm<IPriority>({
    resolver: yupResolver(PriorityFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const isCreating = id === 'create';

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createPriority : editPriority;
    const response = await fn(values);
    if (response === true) {
      addSuccessAlert(t('saved'));
      loadEntity();
      toggleDialog(false);
    } else {
      addErrorAlert(t('errorSaving'));
    }
    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      if (!isCreating && isUndefined(entity) && id !== null) {
        const response = await getPriority(id);
        if (!isEmpty(response)) {
          setEntity(response);
          reset(response);
        }
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {entity || isCreating ? (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <input {...register('id')} type="hidden" />
            <Grid container={true} spacing={0}>
              <Grid item={true} xs={12}>
                <FormInput name="name" label={t('name')} type="text" required={true} />
              </Grid>
            </Grid>
            <EntityButtons
              toggleDialog={toggleDialog}
              otherStyles={{ marginTop: compactMode ? 8 : 16 }}
            />
          </form>
        </FormProvider>
      ) : (
        <Papeer>
          <Typography>{t('priorityNotFound')}</Typography>
        </Papeer>
      )}
    </>
  );
};

export default PriorityForm;

import { Box, CircularProgress, Typography } from '@mui/material';
import { isArray } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewButton } from './PreviewButton';
import { RenderStudy } from './RenderStudy';
import { ISeriesViewer, IStudyWithStates } from './_types';

export const SeriesViewer: React.FC<ISeriesViewer> = ({
  showStudiesCheckboxes = false,
  showSeriesCheckboxes = false,
  studies,
  operations,
  renderStudy = true,
  title,
  showInstancesCheckboxes = false,
  allowOpenSeries = false,
  actualSize,
}) => {
  const { t } = useTranslation('Studies');

  const [isFetching, setIsFetching] = useState<boolean>(false);

  return (
    <Box sx={{ position: 'relative' }}>
      <Typography
        variant="subtitle1"
        component="div"
        sx={{
          borderBottom: '1px solid #ddd',
          display: 'flex',
          pb: 1,
          mb: 1,
          alignItems: 'center',
        }}
      >
        {title || t('studyList')}
        <PreviewButton />
      </Typography>
      {isFetching ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255, 255, 255, .6)',
            //  zIndex: 999,
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}

      {isArray(studies) && studies.length
        ? studies.map((study, index) => (
            <RenderStudy
              key={study.iid}
              studyIndex={index}
              study={study as IStudyWithStates}
              showSeriesCheckboxes={showSeriesCheckboxes}
              showStudiesCheckboxes={showStudiesCheckboxes}
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              operations={operations}
              renderStudy={renderStudy}
              showInstancesCheckboxes={showInstancesCheckboxes}
              allowOpenSeries={allowOpenSeries}
              actualSize={actualSize}
            />
          ))
        : null}
    </Box>
  );
};

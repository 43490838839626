import HelperComponent from 'components/Tour/HelperComponent';
import { getDropDownStep } from 'components/Tour/_dropDownStep';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

export const TourProposalsShredding = () => {
  const { t } = useTranslation('Reactour');

  const allSteps = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="proposalSearchForm"]': t('shreddings.proposals.form'),
      ...getDropDownStep({ isDropDownOpen, t }),
      '[data-tour="shreddings-proposals-clear"]': t('shreddings.common.clear'),
      '[data-tour="shreddings-proposals-search"]': t('shreddings.common.search'),
      '[data-tour="common-gridReFetching"]': t('common.gridReFetching'),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
      '[data-tour="shreddings-proposals-grid"]': t('shreddings.proposals.grid'),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });

  return <HelperComponent />;
};
